import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
import { toast } from "react-toastify";
export interface OrderItem {
  id: string;
  type: string;
  attributes: {
    status: string;
    total_fees: number;
    total_items: number;
    total_tax: number;
    status_history: {
      [key: string]: string;
    };
    created_at: string;
    customer: {
      data: {
        id: string;
        type: string;
        attributes: {
          activated: boolean;
          country_code: string | null;
          email: string;
          first_name: string;
          full_phone_number: string;
          last_name: string;
          phone_number: string | null;
          type: string;
          company_name: string;
          created_at: string;
          updated_at: string;
          device_id: string | null;
          deactivated: boolean;
          hourly_pay: number;
          unique_auth_id: string;
          job_type: string;
          job_role: string;
          original_owner: boolean;
          profile_created: boolean;
          owner_onboard: boolean;
          company_address_id: number;
          preferred_locale: string;
          device_tokens: {
            id: number;
            account_id: number;
            device_token: string;
            created_at: string;
            updated_at: string;
          }[];
          company_address: {
            id: string;
            type: string;
            attributes: {
              name: string;
              city: string;
              state: string;
              postal_code: string;
              country: string;
              address: string;
              owner_id: number;
            };
          };
        };
      };
    };
    address: {
      data: null;
    };
    order_items: {
      data: {
        id: string;
        type: string;
        attributes: {
          price: number;
          quantity: number;
          taxable: boolean;
          taxable_value: number;
          other_charges: null;
          catalogue: {
            data: null;
          };
          catalogue_service: {
            data: {
              id: string;
              type: string;
              attributes: {
                id: number;
                SKU: string;
                title: string;
                etoh_shop_category_id: number;
                etoh_shop_sub_category_id: number;
                visibililty_in_catalog: null;
                short_description: string;
                description: string;
                service_type: string;
                price: string;
                sale_price: string;
                price_weekly: string;
                sale_price_weekly: string;
                price_monthly: string;
                sale_price_monthly: string;
                price_yearly: string;
                sale_price_yearly: string;
                is_featured: boolean;
                buyers_additional_details: boolean;
                created_at: string;
                updated_at: string;
                thumbnails: {
                  id: number;
                  filename: string;
                  url: string;
                }[];
              };
            };
          };
        };
      }[];
    };
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    modalBoxCenter: string;
    viewOrderDetailsModal: string;
    status: string;
    primaryButton: string;
    inProgress: string;
    orderPlaced: string;
    shipped: string;
    delivered: string;
    onHold: string;
    secondaryButton:string;
  };
  handleCloseViewDetails: () => void;
  viewDetailsID: string;
  isViewDetails: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  orderDetailsData: OrderItem;
  isLoading: boolean;
  isFillDetails:boolean
  isCartOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ViewOrderDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionData: any;
  userToken: any;
  reorderProductRequestID: string = "";
  getSingleOrderRequestId: string = "";
  etohSupportChatRequestId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      orderDetailsData: {
        id: "",
        type: "",
        attributes: {
          status: "",
          total_fees: 0,
          total_items: 0,
          total_tax: 0,
          status_history: {},
          created_at: "",
          customer: {
            data: {
              id: "",
              type: "",
              attributes: {
                activated: false,
                country_code: null,
                email: "",
                first_name: "",
                full_phone_number: "",
                last_name: "",
                phone_number: null,
                type: "",
                company_name: "",
                created_at: "",
                updated_at: "",
                device_id: null,
                deactivated: false,
                hourly_pay: 0,
                unique_auth_id: "",
                job_type: "",
                job_role: "",
                original_owner: false,
                profile_created: false,
                owner_onboard: false,
                company_address_id: 0,
                preferred_locale: "",
                device_tokens: [],
                company_address: {
                  id: "",
                  type: "",
                  attributes: {
                    name: "",
                    city: "",
                    state: "",
                    postal_code: "",
                    country: "",
                    address: "",
                    owner_id: 0,
                  },
                },
              },
            },
          },
          address: {
            data: null,
          },
          order_items: {
            data: [],
          },
        },
      },
      isLoading: false,
      isFillDetails:false,
      isCartOpen: false,
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorResponseMessage = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const responseJsonMessage = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJsonMessage.error) {
        this.setState({ isLoading: false });
        return;
      } else {
        if (apiRequestCallId === this.getSingleOrderRequestId) {
          this.setState({ isLoading: false });
          if (responseJsonMessage.data) {
            this.setState({ orderDetailsData: responseJsonMessage.data });
          }
        } else if (apiRequestCallId === this.reorderProductRequestID) {
          this.setState({ isLoading: false });
          if (responseJsonMessage.message) {
            this.createToastNotificationSuccesssMessage(responseJsonMessage.message)
            this.setState({ isCartOpen: true });
          }
        } else if(apiRequestCallId === this.etohSupportChatRequestId){
          this.setState({ isLoading: false });
          if (responseJsonMessage.errors) {
            this.createToastNotificationSuccesssMessage(responseJsonMessage.errors)
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(
              getName(MessageEnum.NavigationTargetMessage),
              "LiveChat2"
            );
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
            window.location.href="/live-chat";
            }else{
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(
              getName(MessageEnum.NavigationTargetMessage),
              "LiveChat2"
            );
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
            window.location.href="/live-chat";
          }
        } else {
          this.parseApiCatchErrorResponse(errorResponseMessage);
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  createToastNotificationSuccesssMessage = (toastMesssage: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMesssage}</div>
      </div>
    );
  };
  async componentDidMount() {
    this.getSingleOrder();
    const lang = localStorage.getItem("lang") || "en";
    await i18n.changeLanguage(lang);
  }

  translateText(key: string) {
    return i18n.t(key, { ns: "translation" });
  }
  handleCloseCart = () => {
    this.setState({ isCartOpen: false });
  };
  orderAgain = () => {
    const { viewDetailsID } = this.props;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body = {
      id: viewDetailsID,
    };
    const endpoint = `${configJSON.reorderApiPath}`;

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reorderProductRequestID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSingleOrder = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleOrderRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ordeApiPath}/${this.props.viewDetailsID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  formatDate = (orderDate: string) => {
    const date = new Date(orderDate);
    const month = configJSON.monthsData[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    const formattedDate = `${month} ${day}, ${year} at ${hours % 12 || 12}:${
      minutes < 10 ? "0" : ""
    }${minutes}${ampm}`;

    return formattedDate;
  };
  handleFillDetailsOpen=()=>{
    this.setState({isFillDetails:true})
  }
  handleFillDetailsClose=()=>{
    this.setState({isFillDetails:false})
  }
  createEtohSupportChatEvent = () => {
    const formdata = new FormData();
    formdata.append("chat[chat_type]", "etoh_shop_support");
    formdata.append("chat[name]", "EtOH Support");    
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.etohSupportChatRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createChatPath
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
