import { configureStore } from "@reduxjs/toolkit";
import authReducer from './features/authStatus/authSlice'
import sliceReducer from './features/stateManager/stateSlice'
import sliceRducer2 from './features/stateManager/stateSlice2'
const store = configureStore({
    reducer: {
        authStore: authReducer,
        stateStore: sliceReducer,
        stateStore2: sliceRducer2
    },
    middleware: (getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    }))
})

export type RootState = ReturnType<typeof store.getState>

export default store;