//Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./EquizStyles.web";
import { toast } from "react-toastify";
import {
  simuScoreA,
  simuScoreB,
  simuScoreC,
  simuScoreD,
  simuScoreE,
  simuScoreEmpty,
} from "./assets"; 
import React from "react";

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
  classes: any;
  modalOpen: boolean;
  modalClose: () => void;
  modalClasses: any;
  simulatequizId: string;
  t:(key:string)=>React.ReactNode;
}

export interface S {
  // Customizable Area Start
  quizId: string;
  currentGrade: string | number;
  pastSimulationList: {
    time: string;
    grade: string;
    isDate: boolean;
    isCurrent: boolean;
    id: number
  }[];
  gradeObj :{
    simulationImg: string;
    gradeScoreClass: string;
    gradeScoreText: string;
  };
  lastEscore: string;
  updateEscore: string;
  submittedQuizData: any[];
  questionId: number;
  updatedAns: number;
  showTooltip: boolean;
  isActiveInfoContent: boolean;
  simulateFullScreenMode: boolean;
  isLoading: boolean;
  // Customizable Area End
}
export interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class SimulationModeScoreController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiSimulationScoreId: string = "";
  apiSimulationQuizUpdateId: string = "";
  apiSimulationQueId: string = "";
  userSessionData: any;
  userToken: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      quizId: this.props.simulatequizId,
      currentGrade: "---",
      pastSimulationList: [
        {
          time: "",
          grade: "",
          isDate: false,
          isCurrent: false,
          id: 0,
        }
      ],
      gradeObj: {
        simulationImg: simuScoreEmpty,
        gradeScoreClass: "",
        gradeScoreText: "",
      },
      lastEscore: "",
      updateEscore: "",
      submittedQuizData: [],
      questionId: 0,
      updatedAns: 0,
      showTooltip: false,
      isActiveInfoContent: false,
      simulateFullScreenMode: false,
      isLoading: false,
    };
    
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.quizSimulationList();
    this.quizQueList();
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    }
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiSimulationScoreId) {
        this.setState({isLoading: false})
        if(responseJson && responseJson.success){
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const obj = {};
        const list = [{
		      time: configJSON.simulationTimeTxt,
		      grade: "",
		      isDate: true,
		      isCurrent: true,
          id: 1,
	      }];
        const quizSimulations = responseJson.quiz_simulations;
        quizSimulations.forEach((item:any) => {
          const date = new Date(item.updated_at);
          const dateValue = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
          const meridiem = date.getHours() < 12 ? 'AM' : 'PM'; 
          const time = `${date.getHours()%12 || 12}:${(date.getMinutes()<10?'0':'') + date.getMinutes()} ${meridiem}`  
        if (dateValue in obj){
          list.push({isDate: false, time: time, grade: item.score, isCurrent: false, id: item.id});
        }else{
          list.push({isDate: true, time: dateValue, grade: item.score, isCurrent: false,id: item.id});
          list.push({isDate: false, time: time, grade: item.score, isCurrent: false, id: item.id});
          //@ts-ignore
          obj[dateValue] = [];
        }
      });
      this.setState({
        pastSimulationList: list,
      });
      }else{
        toast.error(responseJson.errors[0], {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }

      if (apiRequestCallId === this.apiSimulationQuizUpdateId) {
        this.setState({isLoading: false});
        if(responseJson && responseJson.success){
          this.setState({ updateEscore: responseJson.quiz_simulation.score });
          toast.success(this.props.t(`${configJSON.simulationSaved}`), {
            position: toast.POSITION.TOP_CENTER,
          });
          this.quizSimulationList();
        }else{
          toast.error(responseJson.errors[0], {
            position: toast.POSITION.TOP_CENTER,
          });
        }  
      }

      if (apiRequestCallId === this.apiSimulationQueId) {
        if(responseJson && responseJson.quiz){
          this.setState({
            submittedQuizData: [...responseJson.quiz.questions.reverse()],
            lastEscore: responseJson.quiz.quiz.data.attributes.score,
            quizId: responseJson.quiz.quiz.data.id,
            questionId: responseJson.quiz.questions[0].submission.question_id,
          });
        }else{
          toast.error(responseJson.errors[0], {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        
      }
    }
  }

  handleOverEffect =()=>{
    this.setState({showTooltip: !this.state.showTooltip});
  }

  quizQueList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSimulationQueId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSimulationQueEndpoint + this.state.quizId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  quizSimulationList = () => {
    this.setState({isLoading: true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSimulationScoreId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSimulationSimulationEndpoint + this.state.quizId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  saveSlideAnswer = (index: number, newValue: any) => {
    const updatedAnswer = [...this.state.submittedQuizData];
    updatedAnswer.map((ans, currIndex) => {
      if (currIndex === index) {
        ans.submission.answer = newValue;
      }
    });
    this.setState({ submittedQuizData: updatedAnswer });
    this.updateGrade(this.state.submittedQuizData)
  };

  updateGrade = (quizList: any) => {
    const currentGrade = this.state.currentGrade;
    if (currentGrade === 0) {
      this.setState({ currentGrade: "--" });
      return;
    }
    let finalScore = 0;

    for (const property in quizList) {
      const maxValue =
        quizList[property].question.max_score_value;
      const minValue =
        quizList[property ].question.min_score_value;
        const answer =  quizList[property].submission.answer

      finalScore =
        (answer - minValue) / (maxValue - minValue) +
        finalScore;
    }

    finalScore = finalScore / (quizList.length);
    finalScore *= 100;
    if (finalScore < 20) {
      return this.setState({ 
        currentGrade: "E",
        gradeObj:{
          simulationImg: simuScoreE,
          gradeScoreClass:"grade-score-E",
          gradeScoreText: "needs improvement"
        },
       });
    }
    if (finalScore < 40) {
      return this.setState({ 
        currentGrade: "D",
        gradeObj:{
          simulationImg: simuScoreD,
          gradeScoreClass:"grade-score-D",
          gradeScoreText: "below average"
        },
      });
    }
    if (finalScore < 60) {
      return this.setState({ 
        currentGrade: "C",
        gradeObj:{
          simulationImg: simuScoreC,
          gradeScoreClass:"grade-score-C",
          gradeScoreText: "average"
        },
      });
    }
    if (finalScore < 80) {
      return this.setState({ 
        currentGrade: "B",
        gradeObj:{
          simulationImg: simuScoreB,
          gradeScoreClass:"grade-score-B",
          gradeScoreText: "good"
        },
      });
    }
    if (finalScore <= 100) {
      return this.setState({ 
        currentGrade: "A",
        gradeObj:{
          simulationImg: simuScoreA,
          gradeScoreClass:"grade-score-A",
          gradeScoreText: "very good"
        },
     });
    }
  };

  updateSimulationQuiz = () => {
    const submission_data = this.state.submittedQuizData.map((resp, index) => {
      return {
        question_id: resp.submission.question_id,
        answer: resp.submission.answer,
      };
    });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSimulationQuizUpdateId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateSimulationQuizEndpoint
    );

    const body = {
      quiz_id: this.state.quizId,
      simulation_submissions: submission_data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showInfoContent = () => {
    this.setState({
      isActiveInfoContent: true,
    });
  }
  hideInfoContent = () => {
    this.setState({
      isActiveInfoContent: false,
    });
  }
  handleSimulateFullScreen = () => {
    this.setState({simulateFullScreenMode : true})
  }
  handleSimulateFullScreenClose = () => {
    this.setState({simulateFullScreenMode : false})
  }
}
//Customizable Area End
