// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./FirstLoginCreatePassword.web";
import { toast } from "react-toastify";
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
  classes: any;
}

interface S {
  password: string;
  isPasswordVisible: boolean;
  confirmPassword: string;
  isConfirmPasswordVisible: boolean;
  error: {
    passwordError: {
      message: string;
      visible: boolean;
    };
    confirmPasswordError: {
      message: string;
      visible: boolean;
    };
  };
  isPasswordValid: boolean;
  showPasswordError: boolean;
  passwordToken: string;
}
interface SS {
  id: any;
}

export default class FirstLoginCreatePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  createPassApiID: string = "";
  welcomeApiID: string = "";
  userSessionData: any;
  userToken: any;
  constructor(props: Props) {
    super(props);
    this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      password: "",
      confirmPassword: "",
      error: {
        passwordError: {
          message: "",
          visible: false,
        },
        confirmPasswordError: {
          message: "",
          visible: false,
        },
      },
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      isPasswordValid: false,
      showPasswordError: false,
      passwordToken: "",
    };
   
    this.userToken = JSON.parse(this.userSessionData);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.error){
        this.createToastNotification("Something went wrong");
      }
      else if (apiRequestCallId === this.createPassApiID) {
        if (responseJson.errors) {
          this.createToastNotification(responseJson.errors[0].token);
        } else {
          this.createToastNotificationSuccess("Password created successfully");
          this.props.navigation.navigate("EmailAccountLogin");
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  createToastNotificationSuccess = (toastMesssage: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMesssage}</div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };
  createToastNotification = (toastMesssage: string) => {
    toast.error(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.password !== this.state.password) {
      this.setState({
        error: {
          ...this.state.error,
          passwordError: {
            message: "",
            visible: true,
          },
        },
      });
    }

    if (prevState.confirmPassword !== this.state.confirmPassword) {
      this.setState({
        error: {
          ...this.state.error,
          confirmPasswordError: {
            message: "",
            visible: false,
          },
        },
        showPasswordError: false,
      });
    }
  }
  getAllUrlParams = (url: string) => {
    let queryString = url ? url.split("?")[1] : window.location.search.slice(1);

    let obj: any = {};

    if (queryString) {
      queryString = queryString.split("#")[0];

      let arr = queryString.split("&");

      arr.forEach((element) => {
        let a = element.split("=");

        let paramName = a[0];
        let paramValue = typeof a[1] === "undefined" ? true : a[1];

        paramName = paramName.toLowerCase();

        if (!obj[paramName]) {
          obj[paramName] = paramValue;
        }
      });
    }

    return obj;
  };
  async componentDidMount() {
    const langS = localStorage.getItem("lang") ?? "en"; 
    await(i18n as any).changeLanguage(langS);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("token")) {
      this.setState({
        passwordToken: this.getAllUrlParams(queryString).token as string,
      });
    }
  }
 
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }

  handlePassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.target.value });
  };
  handleConfirmPassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      confirmPassword: event.target.value,
    });
  };
  togglePasswordVisibility = () => {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
  };

  toggleConfirmPasswordVisibility = () => {
    this.setState({
      isConfirmPasswordVisible: !this.state.isConfirmPasswordVisible,
    });
  };

  handleSubmit = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    let passwordError = this.state.error.passwordError;
    let confPasswordError = this.state.error.confirmPasswordError;
    let errorFlag = false;
    if (this.state.password.trim() === "") {
      passwordError.message = "Password is requried";
      passwordError.visible = true;
      errorFlag = true;
    }

    if (this.state.confirmPassword.trim() === "") {
      confPasswordError.message = "Confirm Password is requried";
      confPasswordError.visible = true;
      errorFlag = true;
    }

    if (!errorFlag) {
      if (!this.state.isPasswordValid) {
        confPasswordError.message = "Password does not meet requirements";
        confPasswordError.visible = true;
        errorFlag = true;
        this.setState({
          showPasswordError: true,
        });
      } else if (this.state.password != this.state.confirmPassword) {
        confPasswordError.message = "Password does not match";
        confPasswordError.visible = true;
        this.setState({
          showPasswordError: true,
        });
        errorFlag = true;
      }
    }

    if (errorFlag) {
      this.setState({
        error: {
          passwordError: passwordError,
          confirmPasswordError: confPasswordError,
        },
      });
      return;
    }
    if (this.state.passwordToken == "") {
      this.createToastNotification("Invalid token");
      return;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: this.state.passwordToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createPassApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPasswordEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          new_password: this.state.password,
          confirm_password: this.state.confirmPassword,
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createPasswordAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  isValidPassword = (isValid: boolean) => {
    this.setState({ isPasswordValid: isValid });
  };
}
// Customizable Area End
