// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Button,
  InputAdornment,
  Chip
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import { close} from "../../blocks/ContactsList/src/assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomInputWeb from "./CustomInput.web";

interface GroupContactsProps {
  classes: any;
  modalOpen: boolean;
  modalClose: () => void;
  searchContactKeyword: string;
  handleSearchContact: (text: string) => void;
  contacts:any;
  title:string;
  subTitle:string;
  onClickEvent:()=>void;
  isSearching:boolean;
  searchedContacts?:any;
  handleSortContact:(e:any)=>void;
  isSorting:boolean;
  sortedContacts:any;
  toggleShowGroups:(e:any)=>void;
  showMoreGroups:any;
}

const AddAnExistingContact = (props: GroupContactsProps) => {
  const {
    classes,
    modalOpen,
    modalClose,
    searchContactKeyword,
    handleSearchContact,
    contacts,
    title,
    subTitle,
    onClickEvent,
    isSearching,
    searchedContacts,
    handleSortContact,
    isSorting,
    sortedContacts,
    toggleShowGroups,
    showMoreGroups
  } = props;

  const [selectedItemIds, setSelectedItemIds] = useState<any[]>([]);
  const [selectedItemData, setSelectedItemData] = useState([]);
  const [contactData, setContactData] = useState(contacts);

  useEffect(() => {
    if (isSearching) {
      setContactData(searchedContacts);
    }else{
      setContactData(contacts);
    }
   },[searchedContacts,contacts,isSearching]);

   useEffect(() => {
    if(isSorting){
      setContactData(sortedContacts);
    }else{
      setContactData(contacts);
    }
   },[contacts,sortedContacts,isSorting]);

  const handleToggleSelection = (itemId:any) => {  
    const updatedSelectedItems = selectedItemIds ? [...selectedItemIds] : [];

    const selectedIndex = updatedSelectedItems.indexOf(itemId);
    setSelectedItemIds(selectedIndex === -1 ? [...updatedSelectedItems, itemId] : updatedSelectedItems.filter(id => id !== itemId));
       
    if (selectedIndex === -1) {
      updatedSelectedItems.push(itemId);
    } else {
      updatedSelectedItems.splice(selectedIndex, 1);
    }
    setSelectedItemIds(updatedSelectedItems);
    
    const selectedContacts = contacts.filter((contact: any) => updatedSelectedItems.includes(contact.id));
    setSelectedItemData(selectedContacts)
    localStorage.setItem("contactIds",JSON.stringify(updatedSelectedItems));
  };

  const handleRemoveAll=()=>{
    setSelectedItemIds([]);
    setSelectedItemData([]);
    localStorage.removeItem("contactIds");
  }

 const itemClass = selectedItemData.length > 0 ? "bg-light-blue" : "";

  useEffect(() => {
    if (selectedItemIds && selectedItemIds.length > 0) {
      const selectedContacts = contacts.filter((contact: any) => selectedItemIds.includes(contact.id));
      setSelectedItemData(selectedContacts);

      const unselectedContacts = contacts.filter((contact: any) => !selectedItemIds.includes(contact.id));
      setContactData(unselectedContacts);      
    } else {
        setContactData(contacts);
    }
   }, [selectedItemIds, contacts]);

  const getMyGroups = (contactDetails:any) => {
    const userGroups = contactDetails.contact_groups || [];
    const groupLength = contactDetails.contact_groups_count;
    const filteredGroups = userGroups.filter((group:any) => group !== null);
    const defGroupLength = 2;
    const displayGroups = showMoreGroups
      ? filteredGroups
      : filteredGroups.slice(0, defGroupLength);
  
    return (
      <>
        {displayGroups.map((group:any) => (
          <Chip
            key={group?.id}
            className={classes.chipButton}
            label={group?.name}
          />
        ))}
        {filteredGroups.length > defGroupLength && (
          <>
            {showMoreGroups ? (
              <Chip
                className={`secondary-chip ${classes.chipButton}`}
                onClick={toggleShowGroups}
                label="Show Less"
                data-test-id="showLessBtn"
              />
            ) : (
              <Chip
              label={`+${groupLength - displayGroups.length} more`}
              className={`more-btn ${classes.chipButton}`}
              data-test-id="showMoreBtn"
              onClick={toggleShowGroups}
              />
            )}
          </>
        )}
      </>
    );
  };
  return (
    <Modal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalBoxCenter}
    >
      <Box className={classes.contactGroupsModal}>
        <Box className="modal-heading">
          <Box className="heading-left">
            <Typography className="modal-title" component="h2">
            {title}
            </Typography>
            <Box component="p" className="sub-txt">
             {subTitle}
            </Box>
          </Box>
          <Box className="heading-right">
            <Link to="#" className="heading-icon" onClick={modalClose}>
              <img src={close} alt="closeGray" />
            </Link>
          </Box>
        </Box>
        <Box className="search-box">
          <CustomInputWeb
            type="select"
            data-test-id="select"
            label=""
            value={searchContactKeyword}
            placeholder="Search contacts"
            onChange={(event: any) => handleSearchContact(event.target.value)}
            errors={""}
            rows={4}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon style={{color:"#94A3B8"}}/>
              </InputAdornment>
            }
          />
        </Box>
        <Box className="assign-contact">
          <Box className="selected-contact-header">
            <Typography className="selected-contact-txt" component="h2">
              Selected Contacts
            </Typography>
            <Button
             className={selectedItemIds.length===0?"remove-contact-disable":"remove-contact-txt"}
             onClick={handleRemoveAll}
             data-test-id="handleRemoveAll"
            >Remove all Contacts</Button>
          </Box>
          
          <Box className="selected-contact">
            <Box className={`select-contact-item ${itemClass}`}>
              {selectedItemData.length > 0 && 
              <>
              {selectedItemData.map((item:any)=>{
              const filteredGroups = item.attributes.contact_groups.filter((group:any )=> group !== null);
              return<Box className="select-contact-item-block" key={item.id}>
              <Box className="avatar">
                <Typography className="short-name">
                {item.attributes.first_name.charAt(0).toUpperCase()}{item.attributes.last_name.charAt(0).toUpperCase()}
                </Typography>
              </Box>
              <Box className="contact-info">
                <Typography className="name">{item.attributes.first_name} {item.attributes.last_name}</Typography>
                <Typography className="email">{item.attributes.email}</Typography>
                <Box className="departments">
                {getMyGroups({
                 contact_groups_count: filteredGroups.length,
                 contact_groups: filteredGroups,
               })}            
          <Box data-test-id="handleToggleSelection1" onClick={() => handleToggleSelection(item.id)}>
            {selectedItemIds && item.id !== null && item.id !== undefined && selectedItemIds.includes(item.id) ? (
            <CheckCircleIcon className="checkedCirlce" />
          ) : (
            <RadioButtonUnchecked className="circleCheck" />
          )}
          </Box>
              </Box>
              </Box>
              </Box>
            })}
            </>
          }
            </Box>
          </Box>
            <Typography className="top-title">Unassigned</Typography>
            <Box className="top-list">
              <Typography className="count-contact"> {contactData?.length} {contactData?.length > 1 ? 'Contacts' : 'Contact'}</Typography>
              <FormControl className="select-control">
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  onChange={(e:any) => handleSortContact(e.target.value)}
                  displayEmpty
                  defaultValue="Sort by"
                  IconComponent={() => (
                    <ExpandMoreIcon style={{color:"#94A3B8"}}/>
                  )}
                  data-test-id="select2"
                >
                  <MenuItem value="Sort by">Sort by</MenuItem>
                  <MenuItem value="A-Z">A-Z</MenuItem>
                  <MenuItem value="Z-A">Z-A</MenuItem>
                </Select>
              </FormControl>
            </Box>
          <Box className="un-assign-contact">
            <div className="line"></div>
            <Box className="un-assign-list">
            {contactData.map((contact:any)=>{
            let filteredGroupsSelected;
            if (Array.isArray(contact.attributes.contact_groups)) {
              filteredGroupsSelected = contact.attributes.contact_groups.filter((group:any )=> group !== null);
            } 
            return <Box className="contact-item" key={contact.id}>
            <Box className="avatar">
              <Typography className="short-name">
                {contact.attributes.first_name.charAt(0).toUpperCase()}{contact.attributes.last_name.charAt(0).toUpperCase()}
                </Typography>
            </Box>
            <Box className="contact-info">
              <Typography className="name"> {contact.attributes.first_name} {contact.attributes.last_name}</Typography>
              <Typography className="email">
               {contact.attributes.email}
              </Typography>
              <Box className="departments">
              {getMyGroups({
                 contact_groups_count: filteredGroupsSelected.length,
                 contact_groups: filteredGroupsSelected,
               })}              
            <Box data-test-id="handleToggleSelection" onClick={() => handleToggleSelection(contact.id)}>
            {selectedItemIds && contact.id !== null && contact.id !== undefined && selectedItemIds.includes(contact.id) ? (
            <CheckCircleIcon className="checkedCirlceContact" />
          ) : (
            <RadioButtonUnchecked className="circleCheck" />
          )}
            </Box>
            </Box>
          </Box>
          </Box>
          })}
            </Box>
          <Button 
            onClick={()=>onClickEvent()}
            data-test-id="editGroupEvent" className="save-group-button">Add an Existing Contact</Button>
          </Box>
          
        </Box>
      </Box>
    </Modal>
  );
};

export default AddAnExistingContact;
// Customizable Area End
