import { toast } from "react-toastify";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
const configJSON = require('../../blocks/email-account-login/src/config')

const firebaseConfig = {
  apiKey: configJSON.firebaseApiKey,
  authDomain: configJSON.firebaseAuthDomain,
  projectId: configJSON.firebaseProjectId,
  storageBucket: configJSON.firebaseStorageBucket,
  messagingSenderId: configJSON.firebaseMessagingSenderId,
  appId: configJSON.firebaseAppId,
  measurementId: configJSON.firebaseMeasurementId,
};

const firebaseApp = initializeApp(firebaseConfig);

const messaging = getMessaging(firebaseApp);

export const requestDeviceToken = async (callback: (token: string) => void) => {
  let currentToken = "";
  try {
    currentToken = await getToken(messaging, { vapidKey: configJSON.vApiKey });
    if (currentToken) {
      callback(currentToken);
    } else {
      toast.error("Can not get device token");
    }
  } catch (_error) {
    console.log("No registration token available. Request permission to generate one.")
    // toast.warning(
    //   "No registration token available. Request permission to generate one."
    // );
  }
  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
