// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import React from "react";
import { Message } from "../../../../framework/src/Message";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./TaskListSidebarStyles.web";
import moment from "moment";
import { normalizeDate } from "../../../../components/src/helpers/Utilities";
import i18n from "../../../../web/src/utilities/i18n";

const baseConfig = require("../../../../framework/src/config");

export const configJSON = require("../config.js");
export interface Props extends WithStyles<typeof customStyles> {
  navigation: any;
  id: string;
}

export interface S {
  projectsTabsValue: number;
  expandedProject: number;
  tasksTabsValue: number;
  projectInnerTabsValue: number;

  //project
  projectList: any[];
  isSidebarDrawerOpened: boolean;

  //task

  taskList: any[];
  todayTask: any[];
  tomorrowTask: any[];
  thisWeekTask: any[];
  thisMonthTask: any[];

  //tasklist
  projectTaskList: any[];
}

export interface SS {
  id: any;
}

export default class TaskListSideBarController extends BlockComponent<
  Props,
  S,
  SS
> {
  userSessionData: any;
  userToken: any;
  projectListApiID: string = "";
  taskFilterApiID: string = "";
  projectTasklistViewApiID: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      projectsTabsValue: 1,
      tasksTabsValue: 0,
      projectInnerTabsValue: 0,
      projectList: [],
      taskList: [],
      todayTask: [],
      tomorrowTask: [],
      thisWeekTask: [],
      thisMonthTask: [],
      projectTaskList: [],
      expandedProject: 0,
      isSidebarDrawerOpened: false,
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
  }

  async componentDidMount(): Promise<void> {
    this.fetchProjects();
    this.getTasks();
    const lang = localStorage.getItem("lang") ?? "en";
    await (i18n as any).changeLanguage(lang);
  }

  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.taskList != prevState.taskList) {
      this.setTaskByTabs();
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          switch (apiRequestCallId) {
            case this.projectListApiID:
              this.setState({
                projectList: this.sortRecords(responseJson.data),
              });
              break;
            case this.taskFilterApiID:
              this.setState({
                taskList: responseJson.data,
              });
              break;
            case this.projectTasklistViewApiID:
              this.setState({
                projectTaskList: responseJson.data,
              });
          }
        }
      }
    }
  }

  setProjectsTabsValue = (obj: any, val: any) => {
    if (val == this.state.projectsTabsValue) return;
    if (val > 1) {
      this.fetchProjectTasklist(val - 1);
    }
    this.setState({
      projectsTabsValue: val,
      expandedProject: val,
      tasksTabsValue: 0,
      projectInnerTabsValue: 0,
      projectTaskList: [],
    });
  };
  setprojectInnerTabsValue = (obj: any, val: any) => {
    if (val == this.state.projectInnerTabsValue) return;
    this.setState({
      projectsTabsValue: 0,
      tasksTabsValue: 0,
      projectInnerTabsValue: val,
    });
  };

  setTasksTabsValue = (obj: any, val: any) => {
    this.setState({
      tasksTabsValue: val,
      projectsTabsValue: 0,
      projectInnerTabsValue: 0,
      expandedProject: 0,
    });
  };

  fetchProjects = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectListApiID = requestMessage.messageId;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectListEndPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTasks = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.taskFilterApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskFilterEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setTaskByTabs = () => {
    const todayDate = this.getTodayFullDate();
    const tomDate = this.getTomorrowFullDate();
    const weekDate = this.getThisWeeksDates();
    const monDate = this.getThisMonthsDates();
    const today = this.state.taskList.filter((task) => {
      if (task.attributes.due_date === todayDate) return task;
    });
    const tomorrow = this.state.taskList.filter((task) => {
      if (task.attributes.due_date === tomDate) return task;
    });
    const thisWeek = this.state.taskList.filter((task) => {
      if (
        moment(task.attributes.due_date).isBetween(
          weekDate.startDate,
          weekDate.endDate
        ) ||
        moment(task.attributes.due_date).isSame(moment(weekDate.startDate)) ||
        moment(task.attributes.due_date).isSame(moment(weekDate.endDate))
      )
        return task;
    });
    const thisMonth = this.state.taskList.filter((task) => {
      if (
        moment(task.attributes.due_date).isBetween(
          monDate.startDate,
          monDate.endDate
        ) ||
        moment(task.attributes.due_date).isSame(moment(monDate.startDate)) ||
        moment(task.attributes.due_date).isSame(moment(monDate.endDate))
      )
        return task;
    });

    this.setState({
      thisMonthTask: thisMonth,
      thisWeekTask: thisWeek,
      tomorrowTask: tomorrow,
      todayTask: today,   
    });
  };
  getThisMonthsDates = () => {
    const startOfMonth = moment().startOf("month");
    const endOfMonth = moment().endOf("month");

    return {
      startDate: startOfMonth.format("YYYY-MM-DD"),
      endDate: endOfMonth.format("YYYY-MM-DD"),
    };
  };
  getThisWeeksDates = () => {
    const startOfWeek = moment().startOf("week");
    const endOfWeek = moment().endOf("week");

    return {
      startDate: startOfWeek.format("YYYY-MM-DD"),
      endDate: endOfWeek.format("YYYY-MM-DD"),
    };
  };
  getTomorrowFullDate = () => normalizeDate(moment().add(1, "day"));
  getTodayFullDate = () => normalizeDate(moment());






  fetchProjectTasklist = (projId: number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
   
    this.projectTasklistViewApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectTaskListViewEndPoint + `?project_id=${projId}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  callOnUpdate = () => {
    this.fetchProjects();
    this.getTasks();
    if (this.state.expandedProject > 1) {
      this.fetchProjectTasklist(this.state.expandedProject - 1);
    }
  };
  sortRecords = (filterData: any) => {    
    filterData = filterData.sort((data1: any, data2: any) => {
      if (data1.attributes.title.toLowerCase() < data2.attributes.title.toLowerCase())
        return -1;
      if (data1.attributes.title.toLowerCase() > data2.attributes.title.toLowerCase())
        return 1;
      return 0;
    });

    filterData = filterData.filter((project:any)=> {
      if (project.attributes.status?.toLowerCase() != "completed") return project;
    })
  return filterData;
};

  redirectTo = (tab:string) => {
    switch(tab){
      case "AllProject":
        this.setProjectsTabsValue(null, 1)
        break;
      case "Project":
        this.setState({
          projectsTabsValue: this.state.expandedProject,
          tasksTabsValue: 0,
          projectInnerTabsValue: 0,
        });
        break;
    }
  }
  
  toggleSidebarDrawer = () => {
    this.setState({
      isSidebarDrawerOpened: true,
    });
  };
  closeSidebarDrawer = () => {
    this.setState({
      isSidebarDrawerOpened: false,
    });
  };
}
// Customizable Area End
