// Customizable Area Start
import React, { useState } from "react";
import {
    Box, Link, Typography, FormControl,
    Select,
    MenuItem
} from "@material-ui/core";
import {
    makeStyles,
} from "@material-ui/core/styles";
import { imgAppIcon1, imgAppIcon2, imgTranslate } from "./assets";
import ModalWeb from "./Modal.web";
import { languages } from "./productData";
import { configJSON } from "../../blocks/forgot-password/src/ForgotPasswordController.web";
import i18n from "../../web/src/utilities/i18n";

interface FooterProps {
    title?: string;
}
const useStyles = makeStyles(theme => ({
    FooterWrapper : {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: '60px',        
        marginTop: '-142px',
        [theme.breakpoints.down("md")] :{
            padding: '32px',
            marginTop: '-83px',
        },
        [theme.breakpoints.down("sm")] :{
            padding: '60px',
            marginTop: '-139px',
        },
        [theme.breakpoints.down("xs")] :{
            padding: '24px',
            marginTop: '-99px',
            flexWrap: 'wrap',
        },
        '& img': {
            maxWidth: '100%',   
        }, 
        '& .bottom-right':{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '15px',
            [theme.breakpoints.down("xs")] :{
                marginTop: '16px',
                marginLeft: 0,
                width: '100%',
                justifyContent: 'flex-start',
            },
        },
        '& .copyright-txt' :{
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '0.0075em',
            color: '#94a3b8',
            fontWeight: '400',
            fontFamily: "Roboto",
            '& span': {
                color: '#2b2b2b',
            }
        },   
        '& .downlaod-app': {
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '0.0075em',
            color: '#2b2b2b',
            fontWeight: '400',
            display: 'flex',
            alignItems: 'center',
            marginRight: '24px',
            fontFamily: "Roboto",
            '& .app-link': {
                marginLeft: '12px',
                '& img': {
                    width: '16px',
                }
            }
        },
        '& .translate-language': {
            display: 'flex',
            alignItems: 'center',
            '& .translate-icon': {
                width: '16px',
                marginRight: '6px',
            },
            '& .translate-control': {
                '& .MuiSelect-select' :{
                    fontSize: '12px',
                    letterSpacing: '0.0075em',
                    color: '#2b2b2b',
                    fontWeight: '400',
                    fontFamily: "Roboto",
                    paddingBottom: '0',
                    paddingTop: '0',
                },
                '& .MuiInput-underline:before': {
                    display: 'none',
                },
                '& .MuiInput-underline:after': {
                    display: 'none',
                }
            }
        }
    },
    PageLink:{
        textDecoration:"none",
        color: '#2b2b2b',
    }
}));
const Footer: React.FC<FooterProps> = ({
    title,
}) => {
    const classes = useStyles();
    const [downloadModal, setdownloadModal] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(1);
    const handleModal = () => {
        setdownloadModal(!downloadModal)
    }
    const handleCloseModal = () => {
        setdownloadModal(false);
    }
    const t =(key:any, variable?: Record<string, any>) => {
        return (i18n as any).t(key, { ns: "translation" , ...variable } )
    }
  const handleLanguageChange = (event:any) => {
    const newSelectedLanguage = event.target.value;
    setSelectedLanguage(newSelectedLanguage);
    const selectedLanguageObject = languages.find(language => language.id === newSelectedLanguage);
    if (selectedLanguageObject) {
      localStorage.setItem('lang', selectedLanguageObject.code);
    }
  };
  
    return (    
       <Box className={`footer-wrapper ${classes.FooterWrapper}`}>
                <Box className='bottom-left'>
                    <Typography className='copyright-txt'>
                        ©{t(`${configJSON.copyRightTxt}`)} <Box component='span'>
                        <a className= {classes.PageLink}  href="https://etoh.digital/" target="_blank">EtOH 2023</a>
                        </Box>
                    </Typography>
                </Box>
                <Box className='bottom-right'>
                    <Box className='downlaod-app' onClick={handleModal}>
                        {t(`${configJSON.downloadAppTxt}`)}
                        <Link href='#' className='app-link'>
                            <img src={imgAppIcon1} alt='app-icon' />
                        </Link> 
                        <Link href='#' className='app-link'>
                            <img src={imgAppIcon2} alt='app-icon' />
                        </Link> 
                    </Box>
                    {downloadModal && <ModalWeb modalOpen={downloadModal} modalClose={handleCloseModal} modalType="downloadApp" modalHeading="Download EtOH Suite on the App Store and Google Play."/>}
                    <Box className='translate-language'>
                        <Box component='span' className='translate-icon'>
                            <img src={imgTranslate} alt='translate' />
                        </Box>
                        <FormControl className='translate-control'>
                            <Select value={selectedLanguage} onChange={handleLanguageChange}>
                                {languages.map((language:any) => (
                                 <MenuItem key={language.id} value={language.id}>{language.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>                                    
            </Box>
    );
};

export default Footer;
// Customizable Area End
