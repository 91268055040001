import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Modal,
  Checkbox,
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme
} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {certification1,certification2} from "../../inventorymanagement/src/assets";
import {checkedIcon} from "../../catalogue/src/assets";

import CertificationModalController, {
  Props,
  configJSON
} from "./CertificationModalController.web";
import { customStyles } from "../../../components/src/AddItemStyles.web";

// Customizable Area End

export class CertificationModal extends CertificationModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, openModal} = this.props;
    return (
      <Box>
        <Modal
          open={openModal}
          onClose={this.closeCertificateModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalDialog}
          data-testId={"modalComponent"}
          BackdropProps={{ className: "backdrop-root" }}
        >
          <Box className={`${classes.modalDialogBox} certification-modal-dialogbox`}>
            <Box className="modal-heading">
              <Typography className="modal-title">
                {this.t(`${configJSON.certificationTitile}`)}
              </Typography>
              <CloseIcon
                className="close-icon"
                onClick={this.closeCertificateModal}
                data-testId="closeIcon"
              />
            </Box>
            <Box className="modal-description">
              <Box className="cardSection" >
              {this.state.certificationData.map(certification => (
                <Grid item key={certification.id} className="cardBoxInner" >
                  <Box className="cardBox">
                    {certification.attributes.image && (
                      <img className="certificateImg" src={certification.attributes.image.image} alt=""/>
                      )}  
                    <span className="cardBoxName">{certification.attributes.name ?? "Test"}</span>
                    <Checkbox data-testId={"certificate-checkbox"}
                      checked={this.state.certificationsSelected.includes(Number(certification.id))}
                      onChange={event => this.handleCertification(event,Number(certification.id))} // Pass certification id
                      icon={
                       <RadioButtonUncheckedIcon />
                      }
                      checkedIcon={
                        <CheckCircleIcon />
                      }
                      className={classes.checked}
                    />
                  </Box>                  
                </Grid>
                 ))}
               
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    )
  }
}

// Customizable Area Start


  export default withStyles(customStyles, { withTheme: true })(CertificationModal);
  // Customizable Area End