import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";

export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    teamModuleWrapper: {
      padding: "32px 24px",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        padding: "17px 24px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "25px 0 0",
      },
      "& .heading-wrapper": {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexWrap: "wrap",
        marginBottom: "48px",
        padding: "0 0 0 7px",
        [theme.breakpoints.down("md")]: {
          marginBottom: "16px",
        },
        [theme.breakpoints.down("xs")]: {
          marginBottom: "24px",
          padding: "0 24px",
        },
        "& .heading-right": {
          marginLeft: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "22%",
          "& .sub-txt": {
            color: "#2B2B2B"
          },
          [theme.breakpoints.down("xs")]: {
            marginLeft: "0",
            width: "100%",
            marginTop: "10px",
            display: "none",
          },
          "& .MuiButton-root": {
            marginRight: "18px",
            marginLeft: "0",
            "&:last-child": {
              marginRight: "0",
            },
          },
          "& .primary-btn": {
            minWidth: "122px",
          },
          "& .secondary-btn": {
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            width: "148px",
            textTransform: "capitalize",
          },
        },
        "& .members-count": {
          fontSize: "14px",
          lineHeight: "20px",
          color: "#2b2b2b",
          letterSpacing: "0.1px",
          fontFamily: "Expletus Sans",
          fontWeight: "500",
          marginRight: "14px",
        },

      },

      "& .no-member": {
        color: "rgba(0,0,0,0.87)",
        fontSize: "24px",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        lineHeight: "32px",
        letterSpacing: "0",
        margin: "50px 0",
        textAlign: "center",
        width: "100%",
        padding: "0 12px",
      },

    },
    image: {
      width: "150px",
      height: "30px",
      position: "relative",
      bottom: "65px",
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "7px",
      //filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "22px",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      width: "auto",
      minWidth: "156px",
      textDecoration: "none",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
      marginLeft: "15px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "0px",
      },
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e5e9f0",
      borderRadius: "7px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      width: "156px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      // marginRight: "16px",
      transition: "all 0.5s ease-in-out",
      letterSpacing: "0",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    wrapper: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      padding: "0px 30px",
      justifyContent: "center",
    },
    innerWrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "column"
    },
    authWrapper: {
      textAlign: "left",
    },
    addIntegrationWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height:"100%",
    },
    heading: {
      fontSize: "24px",
      lineHeight: "32px",
      margin: "0 0 6px",
      color: "#2B2B2B",
      letterSpacing: "0",
      fontFamily: "Expletus Sans",
      [theme.breakpoints.down("md")]: {
        margin: "0 0 8px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "22px",
        lineHeight: "26px",
        margin: "0 0 8px",
        color: "#2B2B2B",
      },
    },
    subTxt: {
      fontSize: "14px",
      lineHeight: "26px",
      // color: "#94A3B8",
      color:"#6a6a6a",
      fontFamily: "Roboto",
      margin: "0",
      letterSpacing: "0.105px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
        lineHeight: "17px",
      },
    },
    googleIcon: {
      height: "50px",
      width: "90px"
    },
    metaIcon: {
      height: "20px",
      width: "90px"
    },
    seninblueIcon: {
      height: "20px",
      width: "90px"
    },
    headingIcon:{
      marginBottom:0,
      fontSize: "24px",
      lineHeight: "32px",
      margin: "0 0 6px",
      color: "#2B2B2B",
      letterSpacing: "0",
      fontFamily: "Expletus Sans",
    }
  });
