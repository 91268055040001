// Customizable Area Start
import React, { useState, useMemo } from "react";
import {
  Box,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  FormControl,
  InputLabel,
  ListSubheader,
  InputAdornment,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import ListItemText from "@material-ui/core/ListItemText";
import SearchIcon from "@material-ui/icons/Search";
import { checkOutline } from "../assets";
import { configJSON } from "../MemberMovetoTeamController";

interface EditMemberProps {
  classes: any;
  modalOpen: boolean;
  modalClose: () => void;
  memberDetails: any;
  errorState: any;
  teamsList: [];
  selectJobOptions: any[];
  reActivateMemHandler: () => void;
  roleChange: (event: any, type: string) => void;
  reActivateChooseTeam: (event: any) => void;
  reActivateMemFunc: (
    key: string,
    value: string | number | null,
    objectToUpdate: string
  ) => void;
  t:any;
}

const ReactivateMember = (props: EditMemberProps) => {
  const {
    classes,
    modalOpen,
    modalClose,
    memberDetails,
    errorState,
    reActivateMemHandler,
    roleChange,
    reActivateChooseTeam,
    reActivateMemFunc,
    teamsList,
    selectJobOptions,
    t
  } = props;

  const [searchText, setSearchText] = useState("");

  const containsText = (text: string, searchText: string) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const teamNames = useMemo(
    () =>
      teamsList.map((item: { attributes: { id: string; title: string } }) => ({
        id: item.attributes.id,
        title: item.attributes.title,
      })),
    [teamsList]
  );

  const displayTeamOptions = useMemo(
    () =>
      teamNames.filter((obj: { title: string }) =>
        containsText(obj.title, searchText)
      ),
    [teamNames, searchText]
  );

  const uniqueJobTypes = useMemo(
    () => [
      ...new Set(
        selectJobOptions.map(
          (item: { attributes: { job_role: string } }) =>
            item.attributes.job_role
        )
      ),
    ],
    [selectJobOptions]
  );

  /*fetch ids of already selected team*/
  const findTeamIDs = (displayTeamOptions: any, value: any) => {
    const ids = [];
    for (let i = 0; i < displayTeamOptions.length; i++) {
      const option = displayTeamOptions[i];

      if (value.includes(option.title)) {
        ids.push(option.id);
      }
    }

    return ids;
  };

  const selectedTeamIds = findTeamIDs(displayTeamOptions, memberDetails.team);

  const getLabel = (value: any) => {
    const titles = [];
    for (let i = 0; i < displayTeamOptions.length; i++) {
      const option = displayTeamOptions[i];
      if (value.includes(option.id)) {
        titles.push(option.title);
      }
    }
    return titles.join(", ");
  };

  return (
    <Modal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalDialog}
    >
      <Box className={classes.modalDialogBox}>
        <Box className="modal-heading">
          <Typography className="modal-title" component="h2">
            {memberDetails.firstName} {memberDetails.lastName}
          </Typography>
          <Link to="#" className="close-icon" onClick={modalClose}>
            <CloseIcon />
          </Link>
        </Box>
        <Box className="modal-description">
          <Box component="p" className="sub-txt">
            {memberDetails.team} - {memberDetails.jobRole} (
            {memberDetails.jobType})
          </Box>
          <Box className="modal-form" component="form">
            <Box className="form-info-wrapper">
              <Box className="form-row">
                <Box className="form-col">
                  <CustomInputWeb
                    type="text"
                    isRequired={true}
                    errors={errorState.errorReMemFirstName}
                    label={t(`${configJSON.firstNameTxt}`)}
                    value={memberDetails.firstName}
                    onChange={(event) =>
                      reActivateMemFunc(
                        "firstName",
                        event.target.value,
                        "reActivateMembers"
                      )
                    }
                  />
                </Box>
              </Box>
              <Box className="form-row">
                <Box className="form-col">
                  <CustomInputWeb
                    type="text"
                    isRequired={true}
                    errors={errorState.errorReMemLastName}
                    label={t(`${configJSON.lastNameTxt}`)}
                    value={memberDetails.lastName}
                    onChange={(event) =>
                      reActivateMemFunc(
                        "lastName",
                        event.target.value,
                        "reActivateMembers"
                      )
                    }
                  />
                </Box>
              </Box>
              <Box className="form-row">
                <Box className="form-col">
                  <CustomInputWeb
                    type="text"
                    isRequired={true}
                    errors={errorState.errorReMemEmail}
                    label={t(`${configJSON.Email}`)}
                    value={memberDetails.email}
                    onChange={(event:any) =>
                      reActivateMemFunc(
                        "email",
                        event.target.value,
                        "reActivateMembers"
                      )
                    }
                  />
                </Box>
              </Box>
              <Box className="form-row">
                <Box className="form-col">
                  {/* multi select team*/}
                  <FormControl
                    variant="outlined"
                    className={`select-outline ${classes.selectOutline}`}
                  >
                    <InputLabel id="select-team-label">Choose Team</InputLabel>
                    <Select
                      labelId="choose-team-mutiple-checkbox-label"
                      id="choose-team-mutiple-checkbox"
                      multiple
                      label={t(`${configJSON.chooseTeam}`)}
                      value={memberDetails.team || []}
                      defaultValue={memberDetails.team}
                      onChange={(event: any) => reActivateChooseTeam(event)}
                      onClose={() => setSearchText("")}
                      renderValue={(selectedTeamIds) =>
                        getLabel(selectedTeamIds)
                      }
                      //renderValue={() => selectedTeamIds}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: {
                          paper: `${classes.dropdownStyle} multiSelect`,
                        },
                      }}
                      displayEmpty
                      data-testid="memberRoleFilter"
                    >
                      <ListSubheader>
                        <CustomInputWeb
                          placeholder={t(`${configJSON.searchTxt}`)}
                          autoFocus={true}
                          onChange={(e) => setSearchText(e.target.value)}
                          fullWidth={true}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                        />
                      </ListSubheader>
                      {displayTeamOptions.map((option: any) => (
                        <MenuItem value={option.id} key={option.id}>
                          <ListItemText primary={option.title} />
                          <Box className="multiselect-check">
                            <img src={checkOutline} alt="sort-group" />
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className="error-select">
                      {errorState.errorReMemChooseTeam &&
                        errorState.errorReMemChooseTeam}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Box>
            </Box>
            <Box className="form-info-wrapper">
              <Typography variant="h2" className="form-heading">
                {t(`${configJSON.txtChooseRole}`)}
              </Typography>
              <FormControl
                component="fieldset"
                error={memberDetails.jobType != ""}
                className={`${classes.radioChoose} `}
              >
                <RadioGroup
                  defaultValue={memberDetails.jobType}
                  name="role"
                  className="radio-group"
                  value={memberDetails.jobType}
                  onChange={(event) =>
                    roleChange(event.target.value, "reActivateMembers")
                  }
                >
                  <FormControlLabel
                    control={<Radio />}
                    value="owner"
                    label={t(`${configJSON.txtOwner}`)}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value="manager"
                    label={t(`${configJSON.txtManager}`)}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value="employee"
                    label={t(`${configJSON.txtEmployee}`)}
                  />
                </RadioGroup>
                {/* <FormHelperText>{memberDetails.jobType}</FormHelperText> */}
              </FormControl>
            </Box>
            <Box className="form-info-wrapper">
              {/* <Box className="form-row">
                <Box className="form-col">
                <CustomInputWeb
                  type="text"
                  isRequired={true}
                  errors={errorState.errorReMemJobRole}
                  label="Job Title"
                  value={memberDetails.jobRole}
                  onChange={(event) =>
                    reActivateMemFunc(
                      "jobRole",
                      event.target.value,
                      "reActivateMembers"
                    )
                  }
                />
                </Box>
              </Box> */}
              <Box className="form-row">
                <Box className="form-col">
                  <FormControl
                    variant="outlined"
                    className={`select-outline ${classes.selectOutline}`}
                  >
                    <InputLabel id="select-team-label">
                      {t(`${configJSON.chooseTitleTxt}`)}
                    </InputLabel>
                    <Select
                      labelId="choose-team-mutiple-checkbox-label"
                      id="choose-team-mutiple-checkbox"
                      label={t(`${configJSON.chooseTitleTxt}`)}
                      value={memberDetails.jobRole}
                      onChange={(event) =>
                        reActivateMemFunc(
                          "jobRole",
                          event.target.value as string | number | null,
                          "reActivateMembers"
                        )
                      }
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: {
                          paper: `${classes.dropdownStyle} multiSelect`,
                        },
                      }}
                      displayEmpty
                      data-testid="memberRoleFilter"
                    >
                      {uniqueJobTypes.map((jobTitle: any) => (
                        <MenuItem key={jobTitle} value={jobTitle}>
                          {jobTitle}
                          {/* <ListItemText primary={jobTitle} /> */}
                          {/* <Box className="multiselect-check">
                            <img src={checkOutline} alt="sort-group" />
                          </Box> */}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className="error-select">
                      {errorState.errorReMemChooseTeam &&
                        errorState.errorReMemChooseTeam}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Box>
              <Box className="form-row">
                <Box className="form-col">
                  <CustomInputWeb
                    type="number"
                    isRequired={true}
                    errors={errorState.errorReMemHourlyPay}
                    label={t(`${configJSON.ownerHourText}`)}
                    value={memberDetails.hourlyPay}
                    onChange={(event) => {
                      const val = event.target.value;
                      if (val) {
                        reActivateMemFunc(
                          "hourlyPay",
                          isNaN(Number(val)) ? null : Number(val),
                          "reActivateMembers"
                        );
                      } else {
                        reActivateMemFunc(
                          "hourlyPay",
                          null,
                          "reActivateMembers"
                        );
                      }
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <p style={{ color: "#94A3B8" }}>$</p>
                      </InputAdornment>
                    }
                    onKeyDown={(e:any) => {    
                      if (['e', 'E', '+', '-'].includes(e.key)) {
                        e.preventDefault();  
                      }
                    }}
                  />
                  <FormHelperText className="helper-text">
                    {t(`${configJSON.calculateTxt}`)}
                  </FormHelperText>
                </Box>
              </Box>
            </Box>
            <Box className="modal-footer">
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                title="Confirm & Pay"
                onClick={reActivateMemHandler}
                data-testid="reActivatebtn"
              >
                {t(`${configJSON.confirmAndPay}`)}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReactivateMember;
// Customizable Area End
