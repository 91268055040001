import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyDw29xYbhEGX8MIDl7uKCZDgIYTLjSzTEE",
  authDomain: "etoh-suite.firebaseapp.com",
  projectId: "etoh-suite",
  storageBucket: "etoh-suite.appspot.com",
  messagingSenderId: "214090409206",
  appId: "1:214090409206:web:52e7708af8f79f848a78d7",
  measurementId: "G-R88VKJ87EF"
};

firebase.initializeApp(firebaseConfig);
export const messaging = firebase.messaging();

 

export const onMessageListener = () =>
  new Promise(resolve => {
    messaging.onMessage(messaging, payload => {
      resolve(payload);
    });
  });