// Customizable Area Start
import { WithStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { customStyles } from "./GlobalHeader.web";
import i18n from "../../../web/src/utilities/i18n";
export const configJSON = require("./config");
import React from 'react';

export interface Props extends WithStyles<typeof customStyles> {
  navigation: any;
  id: string;
  classes: any;
  notifications?: any;
  markNotificationRead?: any;
  readAllNotifications?: any;
  fetchMoreData?: any;
  hasNextPage?: any;
  userRole?: any;
}

export interface S {
  errorMessage: any;
  error: {
    errorFirstnameNotValid: string;
    errorlastNameNotValid: string;
  };
  notifications: any[];
  page: number;
  hasNextPage: boolean;
  headerMenuEl2: any;
  anchorEl: any;
  isCartOpen:boolean;
  anchorQuickChatEl: HTMLElement | null;
}
interface SS {
  id: any;
}

export default class GlobalHeaderWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  notificationListApiCallId: string = "";
  markNotificationAsReadId: string = "";
  markReadAllNotificationsId: string = "";
  userSessionData: any;
  userToken: any;
  totalPage: number = 0;
  maxNotifications: number = 0;
  initials: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    this.userSessionData = sessionStorage.getItem("userData") || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.initials = this.getFirstLetterOfWord(
      this.userToken.data.attributes.first_name
    );
    this.initials += this.getFirstLetterOfWord(
      this.userToken.data.attributes.last_name
    );

    this.totalPage = 0;
    this.maxNotifications = 0;

    this.state = {
      errorMessage: "",
      error: {
        errorFirstnameNotValid: "",
        errorlastNameNotValid: "",
      },
      notifications: [],
      page: 0,
      hasNextPage: true,
      headerMenuEl2: null,
      anchorEl: null,
      isCartOpen:false,
      anchorQuickChatEl:null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount(): Promise<void> {
    this.getNotifications(1);
    const langT = localStorage.getItem("lang") ?? "en";
    await i18n.changeLanguage(langT);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          switch (apiRequestCallId) {
            case this.notificationListApiCallId:
              this.getNotificationListApiCallSuccess(responseJson);
              break;
            case this.markNotificationAsReadId:
              this.markNotificationAsReadIdSuccess(responseJson);
              break;
            case this.markReadAllNotificationsId:
              this.markReadAllNotificationsSuccess(responseJson);
              break;
          }
        } else {
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
  }

  markReadAllNotificationsSuccess(responseJson: any) {
    if (responseJson.errors) {
      return;
    }
    this.getNotifications(1);
  }
  t(key:any) {
    return i18n.t(key, { ns: "translation" } )
  }
  markNotificationAsReadIdSuccess(responseJson: any) {
    if (responseJson.error) {
      return;
    } else {
      this.getNotifications(1);
      // this.props.navigation.navigate("MemberAddedSuccess");
    }
  }

  getFirstLetterOfWord = (value: string) => {
    return value.charAt(0).toUpperCase();
  };

  getNotificationListApiCallSuccess = (responseJson: any) => {
    if (responseJson?.errors) {
      return;
    }

    const results: any[] = responseJson?.data;
    const meta = responseJson?.meta;
    this.maxNotifications = meta.total_items;
    this.totalPage = meta.total_pages;

    this.setState((prevState: any) => ({
    notifications: [...prevState.notifications, ...results],
          page: meta.current_page,
  }));
  }

  fetchMoreData = () => {
    if (this.state.notifications.length >= this.maxNotifications) {
      this.setState({ hasNextPage: false });
      return;
    }

    const newPage = this.state.page + 1;
    if (newPage <= this.totalPage) {
      this.getNotifications(newPage);
      this.setState({ hasNextPage: true });
    } else {
      this.setState({ hasNextPage: false });
    }
  };

  getNotifications = (page: number) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationListApiCallId = requestMessage.messageId;

    //* Adding endpoint
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notifications}?page=${page}&per_page=10`
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    //* Adding Request Method
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  readNotification = (id: number) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markNotificationAsReadId = requestMessage.messageId;

    //* Adding endpoint
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notifications}/${id}`
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    //* Adding Request Method
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  markReadAllNotifications = async () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markReadAllNotificationsId = requestMessage.messageId;

    //* Adding endpoint
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.markReadAllNotification}`
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    //* Adding Request Method
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePopoverOpen = (event: any) => {
    console.log(event.currentTarget);
    this.setState({ anchorEl: event.currentTarget }, () => {
      this.setState({notifications:[]},() => {
        this.getNotifications(1)
      })
    });
  };  

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  handleQuictChatPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorQuickChatEl: event.currentTarget });
  };  

  handleQuickChatPopoverClose = () => {
    this.setState({ anchorQuickChatEl: null });
  };

  headerMenuClose = () => {
    this.setState({ headerMenuEl2: null });
  }; 
  
  headerMenuOpen = (e:any) => {
    this.setState({ headerMenuEl2:  e.currentTarget });
  }

  navigateToHelpCenter = () => {
    this.props.navigation.push("/help-center");
  };
  navigateToProfile = () => {
    this.props.navigation.push("/profile");
  };  
  navigateToSettings = () => {
    this.props.navigation.push("/settings");
  };

  handleLogoutUser = () => {
    const languageKeys = ['lang'];

    Object.keys(localStorage).forEach(key => {
      if (!languageKeys.includes(key)) {
        localStorage.removeItem(key);
      }
    });

    sessionStorage.clear();
    this.props.navigation.push("/login");
  };
  handleOpenCartEvent = () => {
    this.setState({ isCartOpen: true });
  };
  handleCloseCartEvent = () => {
    this.setState({ isCartOpen: false });
  };
}
// Customizable Area End
