// App.js - WEB
import React, { Component } from 'react';
import { Box, createTheme, ThemeProvider } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import Routes from './routes.tsx';
import './index.css';
import { ToastContainer } from 'react-toastify';
import { messaging } from './firebase.js';
import HomeScreen from '../../../packages/components/src/HomeScreen';

const BREAKPOINTS = {
  xs: 0,
  sm: 991,
  md: 1199,
  lg: 1560,
  xl: 1920
};

const breakpointsFull = {
  breakpoints: createBreakpoints({
    values: BREAKPOINTS
  })
};

// Set up the theme
const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#FF66CC'
      }
    }
  },
  breakpointsFull
);
class App extends Component {
  async componentDidMount() {
    this.requestPermission();
  }
  

  async requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      // Generate Token
      const token = await messaging.getToken({
        vapidKey:
          'BIQ6aT0t4eham1Hdjd3JFc2WbPbWkP7qxHsq3MRX35DWbi-z_3VlauOTgOvVmOG0shyJkqoCx6vQiLogtZzpmro'
      });
      localStorage.setItem('device_token', token);
    } else if (permission === 'denied') {
      console.log('You denied for the notification');
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Box>
          <Routes />
          <ToastContainer
            className="toast-notification"
            autoClose={1000}
            hideProgressBar={true}
            closeButton={false}
          />
        </Box>
      </ThemeProvider>
    );
  }
}

export default App;
