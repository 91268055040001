import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Drawer,
  FormHelperText,
  Select,
  FormControl,
  MenuItem,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import { customStyles } from "./SettingStyles.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import { menu,arrowBackIcon } from "./assets";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import CustomSettingSidebar from "../../../components/src/CustomSettingSidebar.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";

// Customizable Area End

import Settinggs5Controller, {
  Props,
  configJSON,
} from "./Settings5Controller.web";
import DeactivateAccountWeb from "./DeactivateAccount.web";


export class Settings5 extends Settinggs5Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, isMobile } = this.props;
    return (
      <Box className={`wrapper ${classes.wrapper} ${classes.settingWrapper}`}>
          <Box className={classes.secondaryHeader}>
            <Box className="container">
              <Box className="image-wrapper" data-test-id="backButton" onClick={this.backButtonClick}>
                  <Link to="#" title="Back" > <em><img src={arrowBackIcon} alt="Back Arrow Icon" /></em>  {this.t(`${configJSON.back}`)}</Link>
              </Box>
            </Box>
          </Box>
          <Box className={`inner-wrapper`}>
            <Box className={classes.settingInnerWrapper}>
              <Box className="main-heading">
                <Box className="back-btn-wrap">
                  <i>
                    <svg
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      focusable="false"
                      className="MuiSvgIcon-root"
                    >
                      <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                    </svg>
                  </i>
                </Box>
                <Typography variant="h1" className="heading">
                 {this.t(`${configJSON.SettingsHeading}`)}
                </Typography>
                <Box
                  data-testId="settingToggleMenu"
                  onClick={this.toggleSettingDrawer}
                  className="setting-menu"
                >
                  <img alt="menu" src={menu} />
                </Box>
              </Box>
              <Box className={classes.drawerWrapper}>
                <Box className={classes.drawer}>         
                  <Drawer                
                    open={isMobile ? this.state.isSettingDrawerOpened : true}
                    variant={isMobile ? "temporary" : "permanent"}
                    classes={{
                      root: classes.drawerRootWrapper,
                      paper: classes.drawerPaper,
                    }}
                  >
                    <CustomSettingSidebar
                      toggleMenu={this.closeSettingDrawer}
                      activeMenu={2}
                      navigation={this.props.navigation}
                      t={this.t}
                    />
                  </Drawer>
                </Box>
                <Box className={classes.drawerContent}>
                  {!this.state.isNextStep ? (
                    <Box
                      className={`${
                        classes.settingBlockOuter
                      } deactive-block mvc-setting-outer `}
                    >
                      <Box className="setting-block-wrapper setting-form-wrapper">
                        <Box component="form">
                          <Box
                            className={`${
                              classes.formInfoWrapper
                            } form-info-wrapper`}
                          >
                            <Typography className="form-title" variant="h2">
                              {this.t(`${configJSON.deactivateAccTxt}`)}
                            </Typography>
                            <Box className="form-label" component="p">
                              {this.t(`${configJSON.deactivatingMsg}`)}
                            </Box>
                            <Box className="form-row">
                              <Box className="form-col">
                                <FormControl
                                  variant="outlined"
                                  className={`form-control ${
                                    classes.selectOutline
                                  }`}
                                  error ={this.state.reasonError!==""}                            
                                >
                                  <InputLabel id="select-label">
                                    {this.t(`${configJSON.ChooseReason}`)}
                                  </InputLabel>
                                  <Select
                                    labelId="choose-reason"
                                    id="choose-reason"
                                    label={this.t(`${configJSON.ChooseReason}`)}
                                    IconComponent={ExpandMoreIcon}
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                      },
                                      transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                      },
                                      getContentAnchorEl: null,
                                      classes: {
                                        paper: classes.dropdownStyle,
                                      },
                                    }}
                                    value={this.state.chooseReason}
                                    onChange={this.handleSelectChange}
                                    name="chooseReason"
                                    data-test-id="chooseReasonSelect"
                                  >
                                    {this.deactivateReasons.map((name:string, index:number)=>{
                                      return(
                                        <MenuItem key={name} value={name}>
                                        {this.t(`${name}`)}
                                      </MenuItem>
                                      )
                                    })}
                                  </Select>
                                  {this.state.reasonError !== "" ? (
                                        <FormHelperText className="error-select">
                                          {this.state.reasonError}
                                        </FormHelperText>
                                      ): (
                                        ""
                                      )}
                                </FormControl>
                              </Box>
                            </Box>
                            <Box className="form-label" component="p">
                              {this.t(`${configJSON.feedbackTxt}`)}
                            </Box>
                            <Box className="form-row">
                              <Box className="form-col">
                                <CustomInputWeb
                                  type="text"
                                  label={this.t(`${configJSON.deactivateTextFeild}`)}
                                  isMultiline
                                  rows={6}
                                  value={this.state.feedback}
                                  name="feedback"
                                  onChange={this.handleChange}
                                  errors={this.state.feedbackError}
                                  data-test-id="feedbackInput"
                                />
                                <FormHelperText className="helper-text">
                                 {this.t(`${configJSON.txtDeleteNote}`)}
                                </FormHelperText>
                              </Box>
                            </Box>
                          </Box>
                          <Box className="button-wrapper half-btn">
                            <Button
                              className={`secondary-btn ${
                                classes.secondaryButton
                              }`}
                              title="Cancel"
                            >
                              {this.t(`${configJSON.txtCancel}`)}
                            </Button>
                            <Button
                              className={`primary-btn ${classes.primaryButton}`}
                              title="Continue"
                              onClick={this.continueNextStep}
                              data-test-id="continueNextStep"
                            >
                              {this.t(`${configJSON.continue}`)}
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      className={`${
                        classes.settingBlockOuter
                      } mvc-setting-outer `}
                    >
                      <Box className="setting-block-wrapper setting-form-wrapper">
                        <Box component="form">
                          <Box
                            className={`${
                              classes.formInfoWrapper
                            } form-info-wrapper`}
                          >
                            <Typography className="form-title verify-title" variant="h2">
                              {this.t(`${configJSON.verifyPass}`)}
                            </Typography>
                            <Box className="sub-txt" component="p">
                             {this.t(`${configJSON.identityTxt}`)}
                            </Box>
                            <Box className="form-row">
                              <Box className="form-col">
                                <CustomInputWeb
                                  label={this.t(`${configJSON.txtEnterPassword}`)}
                                  name="password"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      {this.state.isPasswordVisible ? (
                                        <VisibilityOffIcon
                                          className="visibility-icon"
                                          onClick={this.togglePasswordVisibility}
                                        />
                                      ) : (
                                        <VisibilityIcon
                                          className="visibility-icon"
                                          onClick={this.togglePasswordVisibility}
                                          data-testid="togglePwd"
                                        />
                                      )}
                                    </InputAdornment>
                                  }
                                  type={
                                    this.state.isPasswordVisible ? "text" : "password"
                                  }
                                  value={this.state.password}
                                  onChange={this.handleChange}
                                  errors={this.state.passwordError}
                                  data-test-id="passwordField"
                                />
                              </Box>
                            </Box>
                            <Box className="description-box">
                              <Box className="label">{this.t(`${configJSON.note}`)}:</Box>
                              {this.t(`${configJSON.reactivateDis}`)}
                            </Box>
                          </Box>
                          <Box className="button-wrapper half-btn">
                            <Button
                              className={`secondary-btn ${
                                classes.secondaryButton
                              }`}
                              title="Cancel"
                              onClick={this.backToFirstStep}
                              data-test-id="backToFirstStep"
                            >
                              {this.t(`${configJSON.txtCancel}`)}
                            </Button>
                            <Button
                              className={`primary-btn deactive-btn ${
                                classes.primaryButton
                              }`}
                              title="Deactivate Account"
                              onClick={this.deactiveAccount}
                              data-test-id="deactivateAccountBtn"
                            >
                              {this.t(`${configJSON.deactivateAccMenu}`)}
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default withStyles(customStyles, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(Settings5)
);
// Customizable Area End
