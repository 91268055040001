// Customizable Area Start
import React from "react";
import { Box, Typography, Button, InputAdornment } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ForgotPasswordController , {configJSON} from "./ForgotPasswordController.web";
import Footer from "../../../components/src/Footer.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import AuthLeft from "../../../components/src/AuthLeft.web";
import { customStyles } from "./ForgotPassword.web";
import CustomHavingTrobleModal from "../../../components/src/CustomHavingTrobleModal.web";

class ResetPassword extends ForgotPasswordController {
  render() {
    const { classes } = this.props;
    return (
      <>
        <Box className={`${classes.authMainWrapper} `}>
          <Box className={`wrapper ${classes.wrapper}`}>
            <Box className={classes.innerWrapper}>
              <Box className={classes.authWrapper}>
                <Box className="auth-inner-block">
                  <AuthLeft />
                  <Box className="auth-right">
                    <Box className="right-inner">
                      <Box className="back-logout-wrap">
                        <Box className="back-btn-wrap" />
                        <Box className="logout-wrap">
                          <Button
                            className={classes.logoutButton}
                            onClick={this.handleOpen}
                          >
                            {this.t(`${configJSON.webLinkContactSupport}`)}
                          </Button>
                        </Box>
                      </Box>
                      <Box className="right-inner-content">
                        <Typography className="heading-forgot" variant="h1">
                          {this.t(`${configJSON.createNewPass}`)}
                        </Typography>
                        <Box component="p" className="sub-txt">
                          {this.t(`${configJSON.createPassSubTxt}`)}
                        </Box>
                        <Box component="form">
                          <CustomInputWeb
                            errors={this.state.passwordError}
                            label={this.t(`${configJSON.confirmPassTxt}`)}
                            endAdornment={
                              <InputAdornment position="end">
                                {this.state.isPasswordVisible ? (
                                  <VisibilityOffIcon
                                    className="visibility-icon"
                                    onClick={this.togglePasswordVisibility}
                                  />
                                ) : (
                                  <VisibilityIcon
                                    className="visibility-icon"
                                    onClick={this.togglePasswordVisibility}
                                    data-testid="resetTogglePwd"
                                  />
                                )}
                              </InputAdornment>
                            }
                            type={
                              this.state.isPasswordVisible ? "text" : "password"
                            }
                            value={this.state.password}
                            onChange={this.handlePassChange}
                            showRequirements={true}
                            onPassChange={this.isValidPassword}
                          />

                          <Button
                            className={classes.primaryButton}
                            data-testid="resetPassBtn"
                            onClick={this.handleReset}
                            type="submit"
                          >
                            {this.t(`${configJSON.confirmNewPasswordTxt}`)}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Footer />
        </Box>
        <CustomHavingTrobleModal
          isOpen={this.state.open}
          handleClose={this.handleClose}
          t={this.t}
        />
      </>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(ResetPassword);

// Customizable Area End
