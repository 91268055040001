// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ConfirmActionModal from "../../blocks/email-account-registration/src/teams-web/ConfirmActionModal.web";
import { configJSON } from "../../blocks/Settings5/src/Settings5Controller.web";

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    background: "#4ba3b7",
    border: "1px solid #4ba3b7",
    borderRadius: "7px",
    filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#ffffff",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    padding: "12px 10px",
    minHeight: "48px",
    marginBottom: "0",
    transition: "all 0.5s ease-in-out",
    textTransform: "capitalize",
    width: "auto",
    minWidth: "156px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px 6px",
      minHeight: "46px",
    },
    "&:hover": {
      background: "#4ba3b7",
      color: "#ffffff",
    },
  },
  secondaryButton: {
    background: "transparent",
    border: "1px solid #e8ecf2",
    borderRadius: "7px",
    filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#2b2b2b",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    textTransform: "unset",
    maxWidth: "100%",
    padding: "12px 10px",
    minHeight: "48px",
    marginBottom: "0",
    transition: "all 0.5s ease-in-out",
    width: "auto",
    minWidth: "94px",
    "&:hover": {
      background: "transparent",
      color: "#2b2b2b",
    },
  },
  sidebarNav: {
    padding: "0",
    "& .MuiListItem-root": {
      padding: "0",
      marginBottom: "24px",
      "&:last-child": {
        marginBottom: "0",
      },
      "&.active": {
        "& .sidebar-link": {
          background: "#F2F8FC",
        },
      },
      "& .sidebar-link": {
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "19px",
        color: "#2B2B2B",
        fontFamily: "Expletus Sans",
        padding: "8px 20px",
        background: "transparent",
        borderRadius: "6px",
        textDecoration: "none",
        [theme.breakpoints.down("xs")]: {
          padding: "16px 20px",
          width: "100%",
        },
      },
      "& .MuiListItemText-root": {
        margin: "0",
      },
      "& .MuiTypography-root": {
        font: "inherit",
        letterSpacing: "0",
      },
    },
  },
}));

interface Props{
  toggleMenu:any;
  activeMenu:any;
  navigation:any;
  t:any;
}
function CustomSettingSidebar(props: Props) {
  const classes = useStyles();
  const { t } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const modalClose = () => {
    setIsOpen(false);
  };

  const logOutUser = () => {
    sessionStorage.clear();
    localStorage.clear();
    props.navigation.navigate("EmailAccountLogin")
  };
  return (
    <>
      <Box className="drawer-inner">
        <Box className="sidebar-heading">
          <Box className="back-btn-wrap" onClick={props.toggleMenu}>
            <i>
              <svg
                className="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
              </svg>
            </i>
          </Box>
          <Typography className="heading" variant="h1">
            {t(`${configJSON.SettingsHeading}`)}
          </Typography>
        </Box>
        <List component="nav" className={classes.sidebarNav}>
          <ListItem className={props.activeMenu == 1 ? "active" : ""}>
            <Link className="sidebar-link" to="/languageSupport">
              {t(`${configJSON.changeLanguage}`)}
            </Link>
          </ListItem>
          <ListItem className={props.activeMenu == 2 ? "active" : ""}>
            <Link className="sidebar-link" to="/settings">
              {t(`${configJSON.deactivateAccMenu}`)}
            </Link>
          </ListItem>
          <ListItem>
            <Link
              className="sidebar-link"
              to="#"
              onClick={() => setIsOpen(true)}
            >
              {t(`${configJSON.txtSignOut}`)}
            </Link>
          </ListItem>
        </List>
      </Box>
      <ConfirmActionModal
        isOpen={isOpen}
        handleClose={modalClose}
        modalConfirmAction={logOutUser}
        //deleteTeamId={this.state.deleteTeamId}
        modalMessage={t(`${configJSON.txtAreYouSureSignOut}`)}
        confirmBtnTxt={t(`${configJSON.txtSignOut}`)}
        modalHeading= {t(`${configJSON.txtSignOut}`)}
        cancelBtnTxt={t(`${configJSON.txtCancel}`)}
      />
    </>
  );
}

export default CustomSettingSidebar;
// Customizable Area End
