import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import i18n from "../../../web/src/utilities/i18n";
import { toast } from "react-toastify";
import React from "react";
import { AddToCart , UserToken } from "../../../components/src/ReusableEnums";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  isCartOpen: boolean;
  handleCloseCartEvent: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  cartData: any;
  isLoading: boolean;
  selectedItems: any;
  isCart: boolean;
  oneTimePrice: string;
  subscriptionPrice: string;
  totalPrice: string;
  oneTimeItemCount: number;
  subscriptionItemCount: number;
  stripeApiKey: any;
  stripeProductsData: any;
  customQuantity: any;
  sessionID: string;
  stripeSuccessProductsData:any;
  orderPlacedID:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShoppingCartOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCartDetailsRequestId: string = "";
  userSessionData: any;
  userToken: UserToken;
  deleteCartItemRequestId: string = "";
  updateQuantityRequestId: string = "";
  makePaymentRequestId: string = "";
  createOrderRequestId: string = "";
  paymentStatusApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      cartData: [],
      isLoading: false,
      selectedItems: [],
      isCart: false,
      oneTimePrice: "",
      subscriptionPrice: "",
      totalPrice: "",
      oneTimeItemCount: 0,
      subscriptionItemCount: 0,
      stripeApiKey: null,
      stripeProductsData: [],
      customQuantity: "1",
      sessionID: "",
      stripeSuccessProductsData:[],
      orderPlacedID:"",
      // Customizable Area End
    };

    // Customizable Area Start
    this.userSessionData = sessionStorage.getItem("userData") || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.error) {
        this.setState({ isLoading: false });
        return;
      } else {
        switch (apiRequestCallId) {
          case this.getAllCartDetailsRequestId:
            let subscriptionCount = 0;
            let oneTimeServiceCount = 0;

            responseJson.add_to_carts.data.forEach((item: AddToCart) => {
              const serviceType =
                item.attributes.bx_block_catalogue_service.data.attributes
                  .service_type;
              if (serviceType === "Subscription") {
                subscriptionCount++;
              } else if (serviceType === "One Time") {
                oneTimeServiceCount++;
              }
            });
            const stripeProductsData = {
              products: responseJson.add_to_carts.data.map((item: AddToCart) => ({
                name: item.attributes.bx_block_catalogue_service.data.attributes
                  .title,
                imgdata:
                  item.attributes.bx_block_catalogue_service.data.attributes
                    .thumbnails[0].url,
                price: parseFloat(item.attributes.selected_sale_price),
                quantity: item.attributes.quantity,
              })),
            };

            const stripeSuccessProductsData = {
              items: responseJson.add_to_carts.data.map((item: AddToCart) => ({                
                service_id: item.attributes.bx_block_catalogue_service.data.attributes.id,               
                price: item.attributes.selected_sale_price,
                quantity: item.attributes.quantity,
                duration: item.attributes.bx_block_catalogue_service.data.attributes
                .service_type ==="Subscription" ? item.attributes.duration : null,
              })),
            };

            this.setState({
              isLoading: false,
              cartData: responseJson.add_to_carts.data,
              oneTimePrice: responseJson.item,
              subscriptionPrice: responseJson.total_subscription_service,
              totalPrice: responseJson.total_price,
              oneTimeItemCount: oneTimeServiceCount,
              subscriptionItemCount: subscriptionCount,
              stripeProductsData,
              stripeSuccessProductsData
            });
            break;
          case this.deleteCartItemRequestId:
            this.setState({ isLoading: false });
            this.createToastNotificationSuccess(responseJson.message);
            this.setState({ selectedItems: [] });
            this.getAllCartData();
            break;
          case this.updateQuantityRequestId:
            this.setState({ isLoading: false });
            this.getAllCartData();
            break;
          case this.makePaymentRequestId:
            this.setState({
              isLoading: false,
              sessionID: responseJson.session_data.id,
            });
            if (responseJson.session_data.url) {
              window.location.href = responseJson.session_data.url; 
              sessionStorage.setItem('stripeProductsData', JSON.stringify({
                session_id: responseJson.session_data.id,
                total_price: this.state.totalPrice,
                items: this.state.stripeSuccessProductsData.items,
              })); 
            }
            sessionStorage.setItem("session_id",responseJson.session_data.id);                   
            break;            
            case this.paymentStatusApiCallId:
              this.setState({ isLoading: false });
              if(responseJson.payment_status==="paid"){
                this.createOrderAfterPayment();
              }
            break;
            case this.createOrderRequestId:
              this.setState({ isLoading: false });
              this.setState({orderPlacedID:responseJson.data.id})
              this.createToastNotificationSuccess("Order created successfully");
              sessionStorage.removeItem('session_id');
            break;
          default:
            this.parseApiCatchErrorResponse(this.translationShopEvent(errorResponse));
            break;
        }
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getAllCartData();
    if (window.location.pathname==="/payment-success") {
      this.checkPaymentSuccess();
    }
    const langT = localStorage.getItem("lang") ?? "en";
    await (i18n).changeLanguage(langT);
  }
  translationShopEvent(shopkey: string) {
    return i18n.t(shopkey, { ns: "translation" });
  }
  createToastNotificationSuccess = (toastMessage: string) => {
    toast.success(this.translationShopEvent(`${toastMessage}`), {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  getAllCartData = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCartDetailsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToCartApiId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteCartItems = (deleteID: string) => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      cart_id: deleteID,
      isCart: this.state.isCart,
    };

    this.deleteCartItemRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteCart}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateQuantityData = (productId: string, quantity: number) => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateQuantityRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addToCartApiId}/${productId}?quantity=${quantity}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleViewShop = () => {
    window.location.href = "/etoh-shop";
  };

  makePayment = async () => {
    const body = this.state.stripeProductsData;
    const headers = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.makePaymentRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.paymentApiPath
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleCustomQuantityChange = (itemId: string, event: { target: { value: string } }) => {
    const value = event.target.value.trim();
    if (value === "" || (parseFloat(value) > 0 && !value.includes("-"))) {
      this.setState((prevState) => ({
        customQuantity: {
          ...prevState.customQuantity,
          [itemId]: value,
        },
      }));
    }
  };
  checkPaymentSuccess = async () => {
    const sessionID = sessionStorage.getItem("session_id");  
    const headers = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.paymentStatusApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.paymentStatus}?session_id=${sessionID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

     this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createOrderAfterPayment = async () => {
    const sessionID = sessionStorage.getItem('session_id');
    const body =  {
      session_id: sessionID,
      total_price: this.state.totalPrice,
      items: this.state.stripeSuccessProductsData.items,
    }
    const headers = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createOrderRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createOrderApiPath
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleFillDetailsOpen = () => {
  window.location.href = `/order-history?openPopup=true&id=${this.state.orderPlacedID}`;
  }
  // Customizable Area End
}
