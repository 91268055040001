Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings5";
exports.labelBodyText = "Settings5 Body";
exports.methodPut = "PUT";

exports.btnExampleTitle = "CLICK ME";
// -- by rahulsingh start
exports.baseURL = require("../../../framework/src/config");
exports.methodDELETE = "DELETE"
exports.signOutEndPoint = "/account_block/log_out"
exports.txtSettings = "Settings"
exports.txtDeleteMyAccount = "Delete my account"
exports.txtSignOut = "Sign Out"
exports.txtAreYouSureSignOut = "Are you sure you want to sign out?"

// DeleteAccount.tsx start
exports.txtDeleteMyAccountReason1 = "I no longer want to use EtOH Suite"
exports.txtDeleteMyAccountReason2 = "I'm no longer part of this company"
exports.txtDeleteMyAccountReason3 = "I was erroneously added to EtOH Suite"
exports.txtDeleteMyAccountReason4 = "Other"
// DeleteAccountFeedback.tsx start
exports.txtDeleteMyAccountSubTitle = "Can you explain a bit more about why you are deleting your profile? We are constantly looking to improve EtOH Suite and welcome any and all of your feedback. Thank you!"
exports.txtPlaceHolderFeedback = "Tell us your feedback"
exports.txtDeleteNote = "Please note your account will be deleted after 14 days."
exports.txtTypePassword = "Type Password"
exports.txtEnterPassword = "Enter Password"
exports.txtCancel = "Cancel"
exports.txtConfirm = "Confirm"
// DeleteAccountFeedback.tsx end
// -- by rahulsingh end
exports.SettingsHeading = "Settings";
exports.deactivateAccTxt = "Deactivate My Account";
exports.deactivateAccMenu = "Deactivate Account";
exports.deactivateBtn = "Deactivate";
exports.deactivateTextFeild = "Tell us your feedback";
exports.deactivateSubTxt = "Can you explain a bit more about why you are deactivate your account? We are constantly looking to improve EtOH Suite and welcome any and all of your feedback. Thank you!";
exports.confirmDeactivateTxt = "Deactivating your account will stop subscription payments and you will no longer be able to log into the application. All of your data will be saved. If you need to reactivate your account, you must contact the Owner of your organisation."
exports.confirmDeactavateTitle = "Are you sure you want to deactivate your account?";
exports.deactivateAccountApiEndpoint = "account_block/request_account_deactivation";
exports.deactivateMessage = "Account deactivate successfully.";
exports.enterPassword = "Please Enter Password";
exports.deactivatingMsg = "Please choose the reason for deactivating your account.";
exports.ChooseReason="Choose Reason";
exports.feedbackTxt ="Let us know what went wrong. We are constantly looking to improve EtOH Suite and welcome all feedback. Thank you!"
exports.continue="Continue";
exports.verifyPass="Verify Password";
exports.identityTxt="Completing Your Identity";
exports.reactivateDis="You will no longer be able to log into thenapplication. If you need to reactivate your account, you must contact the Owner of your organisation.";
exports.note="Note";
exports.changeLanguage="Change Language";
exports.back="Back"
// Customizable Area End