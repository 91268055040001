// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./DirectEmailsStyles.web";
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
import { toast } from "react-toastify";
import { directEmailCount } from "../../../components/src/ReusableFunctions";
import {sendOutline} from "./assets"

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
  classes: any;
  isMobile?: boolean;
}

interface S {
  tabsValue: any;
  popoveAnchorEl: any;
  isFileValidType: boolean;
  isCancel: boolean;
  isPause: false;
  isLoading: boolean;
  filesUploaded: File[];
  membersList: any;
  fileDrop: boolean;
  isSaveDraft: boolean;
  isMenuDrawerOpened: boolean;
  fromEmailAddress: string;
  emailSubject: string;
  composeEmailContent: string;
  chooseProductsModal: boolean;
  selectedValues: any;
  ccEmailValues: any;
  bccEmailValues: any;
  allDirectEmails: any;
  createEmailError: {
    fromEmailError: string;
    emailSubjectError: string;
    composeEmailError: string;
  };
  emailcount: string;
  composeEmailList: any;
  isViewingEmail: boolean;
  selectedProductIds: any;
  totalproductAmount: number | null;
  selectedProductsData: [];
  viewingAttachedProducts: boolean;
  brevoAPIKey: string;
  brevoAPIKeyError: string;
  isConnectingBrevoAccount: boolean;
  viewEmailDetail: any;
  isBrevoVerified: boolean;
  getFromEmailList: any;
  searchEmailTxt: string;
  isSearching: boolean;
  hasAttachments: boolean;
  emailFilterDate: any;
  sortByValue:string;
  isSorting: boolean;
  selectedContact:string;
  isEmailDraft: boolean;
}
interface SS {
  id: any;
}

export default class DirectEmailsHomePageController extends BlockComponent<
  Props,
  S,
  SS
> {
  welcomeTitle: string;
  welcomeHyperTitle: string;
  welcomeButtonText: string;
  getAllDirectEmailsAPIRequestId: string = "";
  createEmailAPIRequestId: string = "";
  getAllContactsAPIRequestId: string = "";
  validateBrevoAPIRequestId: string = "";
  getSingleEmailAPIRequestId: string = "";
  getBrevoCheckStatusRequestId: string = "";
  getFromEmailAPIRequestId: string = "";
  userSessionData: any;
  userToken: any;
  fileRef: React.RefObject<HTMLInputElement>;
  formRef: React.RefObject<HTMLFormElement>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.welcomeTitle = configJSON.welcomeTitle;
    this.welcomeHyperTitle = configJSON.welcomeHyperTitle;
    this.welcomeButtonText = configJSON.welcomeButtonText;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.fileRef = React.createRef();
    this.formRef = React.createRef();

    this.state = {
      tabsValue: "",
      popoveAnchorEl: null,
      isFileValidType: true,
      isCancel: false,
      isPause: false,
      isLoading: false,
      filesUploaded: [],
      membersList: [],
      fileDrop: false,
      isSaveDraft: false,
      isMenuDrawerOpened: false,
      fromEmailAddress: "",
      emailSubject: "",
      composeEmailContent: "",
      chooseProductsModal: false,
      selectedValues: [],
      ccEmailValues:[],
      bccEmailValues:[],
      allDirectEmails: [],
      createEmailError: {
        fromEmailError: "",
        emailSubjectError: "",
        composeEmailError: "",
      },
      emailcount: "",
      composeEmailList: [],
      isViewingEmail: false,
      selectedProductIds: [],
      totalproductAmount: 0,
      selectedProductsData: [],
      viewingAttachedProducts: false,
      brevoAPIKey: "",
      isConnectingBrevoAccount: false,
      brevoAPIKeyError: "",
      viewEmailDetail: [],
      isBrevoVerified: false,
      getFromEmailList: [],
      searchEmailTxt: "",
      isSearching: false,
      hasAttachments: false,
      emailFilterDate: [],
      sortByValue:'',
      isSorting: false,
      selectedContact: '',
      isEmailDraft: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    //this.isBrevoVerified = this.userToken.data.attributes.brevo_verified;
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorResponseAPI = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (errorResponseAPI || !responseJson || !apiRequestCallId) {
        this.setState({ isLoading: false });
        return;
      }
      switch (apiRequestCallId) {
        case this.getAllDirectEmailsAPIRequestId:
          this.setState({ isLoading: false });
          this.setState({ allDirectEmails: responseJson.data });
          break;
        case this.createEmailAPIRequestId:
          this.setState({ isLoading: false });
          this.getDirectEmails();
          this.popoverHandleClose();
          this.composeEmailSuccess("Email sent",sendOutline);
          this.setState({
              fromEmailAddress : "",
              emailSubject : "",
              composeEmailContent: '',
              selectedProductIds: [],
              filesUploaded: [],
              selectedValues: [],
          })
          break;
        case this.getAllContactsAPIRequestId:
          this.handleComposeList(responseJson.data);
          break;
        case this.validateBrevoAPIRequestId:
          this.setState({ isLoading: false });
          if (responseJson.message.response === "success") {
            this.checkBrevoStatus();
            this.composeEmailSuccess("Account validated succesfully");
            this.getDirectEmails();
          } else {
            this.errorToastMessage(responseJson.message);
          }
          break;
        case this.getSingleEmailAPIRequestId:
          this.setState({ isLoading: false });
          this.setState({ viewEmailDetail: responseJson.data.attributes });
          break;
        case this.getBrevoCheckStatusRequestId:
          this.setState({
            isBrevoVerified: responseJson.data.attributes.brevo_verified,
          });
          break;
        case this.getFromEmailAPIRequestId:
          this.setState({ getFromEmailList: responseJson.data.senders });
      }
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.emailSubject !== this.state.emailSubject) {
      this.setState({
        createEmailError: {
          ...this.state.createEmailError,
          emailSubjectError: "",
        },
      });
    }
    if (prevState.composeEmailContent !== this.state.composeEmailContent) {
      this.setState({
        createEmailError: {
          ...this.state.createEmailError,
          composeEmailError: "",
        },
      });
    }
    
  }

  async componentDidMount() {
    this.getDirectEmails();
    this.fetchToContactLists();
    this.checkBrevoStatus();
    this.getFromEmailAddressList();
    let url =
      configJSON.baseConfig.baseURL + configJSON.getDirectEmailsEndPoint;
    const countofEmails = await directEmailCount(
      url,
      this.userToken.meta.token
    );
    this.setState({ emailcount: countofEmails }, () => localStorage.setItem('emailsCount',this.state.emailcount));
    const langT = localStorage.getItem("lang") || "en";
    await (i18n as any).changeLanguage(langT);
  }
  t(key: any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation", ...variables });
  }
  handleClose = () => {
    if (this.formRef.current != null) {
      this.formRef.current.reset();
    }
    this.setState({
      isSaveDraft: false,
    });
  };

  popoverHandleClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
      this.setState({ popoveAnchorEl: event.currentTarget });
    }
  };

  popoverHandleClose = () => {
    this.setState({ popoveAnchorEl: null });
  };

  dragEnter = () => {
    this.setState({
      fileDrop: true,
    });
  };
  dragLeave = () => {
    this.setState({
      fileDrop: false,
    });
  };
  onBrowse = () => {
    if (this.fileRef.current !== null) {
      this.fileRef.current.click();
    }
  };
  handleOnDrop = (e: React.ChangeEvent<HTMLInputElement>) => {
    let fileObject: FileList;
    if (e.target.files != null) {
      fileObject = e.target.files;
      const arrayLength = this.state.filesUploaded.length;
      //if(arrayLength<6){
      this.setState({
        filesUploaded: [
          ...this.state.filesUploaded,
          ...Object.values(fileObject),
        ],
      });
      //}
    }
  };

  saveDraft = () => {
    this.setState({ isSaveDraft: true });
  };

  removeFileUPload = (index: any) => {
    const updatedArray = [
      ...this.state.filesUploaded.slice(0, index),
      ...this.state.filesUploaded.slice(index + 1),
    ];
    this.setState({ filesUploaded: updatedArray });
  };

  toggleMenuDrawer = () => {
    this.setState({
      isMenuDrawerOpened: true,
    });
  };
  closeMenuDrawer = () => {
    this.setState({
      isMenuDrawerOpened: false,
    });
  };
  openChooseProductsModal = () => {
    this.setState({ chooseProductsModal: true });
  };
  closeChooseProductsModal = () => {
    this.setState({ chooseProductsModal: false });
  };

  selectedEmailProducts = (
    productsSelected: string[],
    totalAmount: number | null,
    selectedProductsData: []
  ) => {
    this.setState(
      {
        selectedProductIds: productsSelected,
        selectedProductsData: selectedProductsData,
        totalproductAmount: totalAmount,
      },
      () => {
        this.closeChooseProductsModal();
      }
    );
  };
  openAttachedProducts = () => {
    this.setState({
      viewingAttachedProducts: true,
    });
  };

  saveDraftEmail = () => {
    this.setState({
      isEmailDraft: true,
      popoveAnchorEl: null,
    });
  }

  maximizeEmailForm = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
    this.setState({
      isEmailDraft: false,
      popoveAnchorEl: event.currentTarget
    });
  }
  }

  closeFloatEmailForm = () => {
    this.setState({
      isEmailDraft: false,
    })
  }

  handleEmailEscEvent = (event:any) => {
    event.stopPropagation();
    if (event.key === "Escape") {
      this.saveDraftEmail();
    }
  }
  closeAttachedProducts = () => {
    this.setState({
      viewingAttachedProducts: false,
    });
  };
  handleToEmailChange = (name: any, value: any) => {
    this.setState({ selectedValues: value });
  };
  handleCCEmailChange = (event: any, value: any) => {
    this.setState({ ccEmailValues: value });
  };
  handleBCCEmailChange = (event: any, value: any) => {
    this.setState({ bccEmailValues: value });
  }
  handleComposeEmailInput = (name: any, value: string) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };
  handleFromAddress = (event: { target: { value: any } }) => {
    if (event.target.value != undefined) {
      this.setState({ fromEmailAddress: event.target.value });
    }
  };
  viewEmailDetail = (mailId: string) => {
    this.setState({ isViewingEmail: true });
    this.getSingleEmaildetail(mailId);
  };
  closeViewDetailMail = () => {
    this.setState({ isViewingEmail: false });
  };
  handleEmailSearch = (searchValue: string) => {
    this.setState(
      {
        searchEmailTxt: searchValue,
        isSearching: searchValue !== "",
      },
      () => this.getDirectEmails()
    );
  };
  toggleHasAttachments = (event: any) => {
    const { checked } = event.target;
    this.setState({ hasAttachments: checked, isSearching: checked }, () =>
      this.getDirectEmails()
    );
  };
  handleEmailFilterDates = (date: any) => {
    this.setState({ emailFilterDate: date }
    );
  };
  handleFilterContacts = (event: {target : {value: string}}) => {
    this.setState({ selectedContact: event.target.value }, () => this.getDirectEmails());
  }
  clearAction = () => {
    this.setState({ emailFilterDate: [] },() => this.getDirectEmails())
  }
  sortEmailsType = (event: any) => {   
    this.setState({
      sortByValue : event.target.value,
      isSorting: true,
    }, () => this.getDirectEmails() )
  }
  getDirectEmails = () => {
    const { searchEmailTxt, hasAttachments, emailFilterDate, isSorting, sortByValue, selectedContact } =
      this.state;
    const header = {
      token: this.userToken.meta.token,
    };
    let url = "";
    let baseURL = configJSON.getDirectEmailsEndPoint;
    if(isSorting && sortByValue === 'Oldest to newest'){
      url += `sort=id&sort_direction=asc&`
    }else {
      url += `sort=id&sort_direction=desc&`
    }

    if (searchEmailTxt !== "") {
      url += `search_key=${searchEmailTxt}&`;
    }
    if (hasAttachments) {
      url += `has_attachments=${hasAttachments}&`;
    }
    if(emailFilterDate.length == 2){
      url += `start_date=${emailFilterDate[0].format("DD-MM-YYYY")}&`;
      url += `end_date=${emailFilterDate[1].format("DD-MM-YYYY")}&`;
    } 
    
    if (selectedContact !== "") {
      url += `to[]=${selectedContact}&`;
    }

    let endPoint = baseURL + url;

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDirectEmailsAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createDirectEmails = () => {
    let errorFlag = false;

    const {
      fromEmailAddress,
      emailSubject,
      composeEmailContent,
      selectedProductIds,
      filesUploaded,
      selectedValues,
      ccEmailValues,
      bccEmailValues,
    } = this.state;
    console.log(selectedValues)
    console.log(ccEmailValues)
    let fromEmailError = this.state.createEmailError.fromEmailError;
    let emailSubjectError = this.state.createEmailError.emailSubjectError;
    let composeEmailError =  this.state.createEmailError.composeEmailError
    if (fromEmailAddress === "") {
      fromEmailError = "Please enter from email address";
      errorFlag = true;
    }
    if (emailSubject.trim() === "") {
      emailSubjectError = "Please enter email subject";
      errorFlag = true;
    }
    if(composeEmailContent.trim() === ""){
      composeEmailError = "Please enter email description";
      errorFlag = true;
    }

    if (errorFlag) {
      this.setState({
        createEmailError: {
          ...this.state.createEmailError,
          fromEmailError: fromEmailError,
          emailSubjectError: emailSubjectError,
          composeEmailError: composeEmailError,
        },
      });
      return true;
    }
    const formdata = new FormData();
    selectedValues.forEach((selectedValue: any) => {
      formdata.append(`direct_mail[to][][email]`, selectedValue.email);
      formdata.append(`direct_mail[to][][name]`, selectedValue.first_name);
    });
    if(ccEmailValues.length > 0){
      ccEmailValues.forEach((selectedValue: any) => {
        formdata.append(`direct_mail[cc][][email]`, selectedValue.email);
        formdata.append(`direct_mail[cc][][name]`, selectedValue.first_name);
      });
    }
    if(bccEmailValues.length > 0){
      bccEmailValues.forEach((selectedValue: any) => {
        formdata.append(`direct_mail[bcc][][email]`, selectedValue.email);
        formdata.append(`direct_mail[bcc][][name]`, selectedValue.first_name);
      });
    }
    // formdata.append("direct_mail[to][][email]", selectedValues[0].email);
    // formdata.append("direct_mail[to][][name]", selectedValues[0].first_name);
    formdata.append("direct_mail[sender][email]", fromEmailAddress);
    formdata.append("direct_mail[sender][name]", "test");
    formdata.append("direct_mail[subject]", emailSubject);
    formdata.append("direct_mail[html_content]", composeEmailContent);
    if (selectedProductIds.length > 0) {
      selectedProductIds.forEach((productId: any) => {
        formdata.append("direct_mail[product_ids][][id]", productId.toString());
      });
    }

    formdata.append("direct_mail[status]", "sent");
    if (filesUploaded.length > 0) {
      filesUploaded.forEach((file) => {
        formdata.append("direct_mail[mail_attachments][]", file, file.name);
      });
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createEmailAPIRequestId = requestMessage.messageId;
    const header = {
      token: this.userToken.meta.token,
    };

    this.createEmailAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createDirectEmailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePOST
    );

    //
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchToContactLists = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllContactsAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchAllContacts
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );
    //* Making Network Request

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleComposeList = (composeList: any) => {
    const composeEmailList = composeList.map((contact: any) => {
      return {
        first_name: contact.attributes.first_name.trim(),
        last_name: contact.attributes.last_name.trim(),
        email: contact.attributes.email.trim(),
      };
    });
    this.setState({
      composeEmailList: composeEmailList,
    });
  };
  handleConnectBrevoAccount = () => {
    this.setState({ isConnectingBrevoAccount: true });
  };
  closeBrevoInput = () => {
    this.setState({ isConnectingBrevoAccount: false });
  };
  handleBrevoAPIKeyInput = (apiKeyVal: string) => {
    this.setState({ brevoAPIKey: apiKeyVal });
  };
  composeEmailSuccess = (toastMessage: string, toastIcon?: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMessage}</div>  
        <a href="#" className="toast-link">
          undo
        </a>    
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        icon: toastIcon ? () => <img src={toastIcon} alt="icon" /> : undefined,
       
      }
    );
  };
  errorToastMessage = (errorMessage: string) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  validateBrevoAccount = () => {
    let errorFlag = false;

    let brevoAPIKeyEmpty = this.state.brevoAPIKeyError;
    if (this.state.brevoAPIKey.trim() === "") {
      brevoAPIKeyEmpty = "Please enter brevo api key";
      errorFlag = true;
    }

    if (errorFlag) {
      this.setState({
        brevoAPIKeyError: brevoAPIKeyEmpty,
      });
      return true;
    }
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body = {
      api_key: this.state.brevoAPIKey,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.validateBrevoAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.validateAccountBrevo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePOST
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSingleEmaildetail = (mailId: string) => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleEmailAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewSingleMail + `${mailId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  checkBrevoStatus = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBrevoCheckStatusRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getFromEmailAddressList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFromEmailAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSenderList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );
    //* Making Network Request
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
// Customizable Area End
