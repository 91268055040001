import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


export const configJSON = require('./config')
// const globalJSON = require('../../../../framework/src/config')

export type NavigationRouteParamsType = {
    serviceCaller: string,
    connectCallback: () => void
}

const ERROR_MODAL_DURATION:number = 1500    //* set in milliseconds (ms)
interface S {
    isMetaConnected: boolean,
    isGoogleConnected: boolean,
    isSendinBlueConnected: boolean,
    showErrorModal: boolean,
    errorMessage: string
}

interface SS {}

export interface Props {
    navigation?: any;
    id?: string;
    classes?: any;
    backToStartIntegration?:any
  }

export default class AddIntegrationController extends BlockComponent<Props, S, SS> {

    constructor(props: Props)
    {
        super(props)
        this.receive = this.receive.bind(this)
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)]
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)

        this.state = {
            isMetaConnected: false,
            isGoogleConnected: false,
            isSendinBlueConnected: false,
            showErrorModal: false,
            errorMessage: ''
        }
    }

}

