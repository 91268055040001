// Customizable Area Start
import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  Modal,
  List,
  ListItem,
  ListSubheader,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import CustomInputWeb from "./CustomInput.web";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { showLettersFromName } from "./ReusableFunctions";
import { favouriteProduct, filterSmIcon } from "../../blocks/inventorymanagement/src/assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { configJSON } from "../../blocks/inventorymanagement/src/InventoryManagementController.web";

interface ChooseContactInventory {
  classes: any;
  contactLists: any;
  modalOpen: boolean;
  modalClose: () => void;
  openFilterModal: () => void;
  openFavouriteModal: () => void;
  selectedAction: (contactId: string) => void;
  selectedContacts: any;
  isFavouriteProductCreated?:any;
  backToInventory?:any;
  favouriteProductModalClose?:() => void;
  t?:any;
}
const ContactListInventory = (props: ChooseContactInventory) => {
  const {
    classes,
    contactLists,
    modalOpen,
    openFilterModal,
    openFavouriteModal,
    selectedAction,
    modalClose,
    selectedContacts,
    isFavouriteProductCreated,
    backToInventory,
    favouriteProductModalClose,
    t
  } = props;
  
  const disableFavourite = selectedContacts.length > 0 ? false : true
  const [sortOrder, setSortOrder] = useState('A-Z'); 
  const [searchQuery, setSearchQuery] = useState('');
 
  const filterAndSortContacts = (contacts:any, query:string, order:string) => {
    return contacts
      .filter((contact:any) => {
        const fullName = `${contact.attributes.first_name.toLowerCase()} ${contact.attributes.last_name.toLowerCase()}`;
        return fullName.includes(query.toLowerCase());
      })
      .sort((a:any, b:any) => {
        const nameA = a.attributes.first_name.toLowerCase();
        const nameB = b.attributes.first_name.toLowerCase();
  
        if (nameA < nameB) return order === 'A-Z' ? -1 : 1;
        if (nameA > nameB) return order === 'A-Z' ? 1 : -1;
        return 0;
      });
  };

const handleSearchChange = (event:any) => setSearchQuery(event.target.value);
const handleSortChange = (event:any) => setSortOrder(event.target.value);

const filteredAndSortedContacts = filterAndSortContacts(contactLists, searchQuery, sortOrder);

  return (
    <>
    <Modal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{ className: "backdrop-root" }}
      className={classes.modalDialog}
    >
      <Box className={`${classes.rightModalDialogBox}`}>
        <Box className="modal-dialoginner">
          <Box className="modal-heading">
            <Typography className="modal-title" component="h2">
              {t(`${configJSON.chooseContactTxt}`)}
            </Typography>
            <Link to="#" className="close-icon" onClick={modalClose}>
              <CloseIcon />
            </Link>
          </Box>
          <Box className="modal-description">
            <Box component="p" className="sub-txt">              
              {t(`${configJSON.selectContactTxt}`)}
            </Box>
            <Box className="modal-form" component="form">
              <Box className="form-info-wrapper">
                <List className="assignee-multiSelect-list">
                  <ListSubheader className="search-filter">
                    <CustomInputWeb
                      placeholder="Start typing to search..."
                      autoFocus={true}
                      value={searchQuery}
                      onChange={handleSearchChange}
                      name="search"
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />
                    <Box
                      className="filter-group"
                      data-testid="filterTeamHandler"
                      onClick={openFilterModal}
                    >
                      <img src={filterSmIcon} alt="filter-group" />
                    </Box>
                  </ListSubheader>
                  <ListSubheader className="multiselect-membres">
                    <Box className="total-member">
                      {filteredAndSortedContacts.length}  {filteredAndSortedContacts.length >1 ?  t(`${configJSON.contactsText}`): t(`${configJSON.contactText}`) } 
                    </Box>
                    <FormControl className="select-control sortby-control">
                      <Select
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                          anchorOrigin: {
                            horizontal: "left",
                            vertical: "bottom",
                          },
                          transformOrigin: {
                            horizontal: "left",
                            vertical: "top",
                          },
                          classes: { paper: classes.dropdownStyle },
                          getContentAnchorEl: null,
                        }}
                        displayEmpty
                        defaultValue="A-Z"
                        value={sortOrder}
                        onChange={handleSortChange}
                        data-testid="sortTeams"
                        renderValue={(value:any) => {
                          return <Box>{value}</Box>;
                        }}
                      >
                        <MenuItem value="A-Z">A-Z</MenuItem>
                        <MenuItem value="Z-A">Z-A</MenuItem>
                      </Select>
                    </FormControl>
                  </ListSubheader>
                  {filteredAndSortedContacts?.map((contact: any) => {
                    const fullName =
                      contact.attributes.first_name +
                      " " +
                      contact.attributes.last_name;

                    return (
                      <ListItem>
                        <Box className="assignee-detail">
                          <Box className="assignee-left">
                            <Box className="assignee-profile">
                              {showLettersFromName(fullName)}
                            </Box>
                            <Box className="assignee-info">
                              <Box className="assignee-name">
                                {contact.attributes.first_name}{" "}
                                {contact.attributes.last_name}
                              </Box>
                              <Box className="assignee-email">
                                {contact.attributes.email}
                              </Box>
                            </Box>
                          </Box>
                          <Box className="assignee-right">
                            <Checkbox
                              tabIndex={-1}
                              className="checklist-check"
                              onClick={() => {
                                selectedAction(contact.id);
                              }}
                              disableRipple
                            />
                          </Box>
                        </Box>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Box>
          </Box>
          <Box className="button-wrapper full-btn">
            <Button
              className={`primary-btn ${classes.primaryButton}`}
              title="Apply Filters"
              onClick={openFavouriteModal}
              disabled={disableFavourite}
            >
              Add as Favorite
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
    {
      isFavouriteProductCreated && 
      <Modal
        open={isFavouriteProductCreated}
        onClose={favouriteProductModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop
        className={classes.modalDialog}
      >
        <Box
          className={`${classes.rightModalDialogBox} favourite-product-dialog`}
        >
          <Box className="modal-dialoginner">
            <Box className="modal-heading">
              <Link
                to="#"
                className="close-icon"
                onClick={favouriteProductModalClose}
                data-test-id="favouriteProductModalClose"
              >
                <CloseIcon />
              </Link>
            </Box>
            <Box className="modal-description">
              <Box className="favourite-product-wrapper">
                <Box className="project-img">
                  <img src={favouriteProduct} alt="favouriteProduct" />
                </Box>
                <Typography className="modal-title" component="h2">
                  {t(`${configJSON.inventoryHead}`)}
                </Typography>
                <Box component="p" className="sub-txt">
                  {t(`${configJSON.inventoryText}`)}
                </Box>
                <Box className="button-wrapper full-btn">
                  <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    title="Back to Inventory"
                    onClick={backToInventory}
                    data-test-id="backToInventory"
                  >
                    {t(`${configJSON.backInvetoryButton}`)}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    }
    </>
  );
};
export default ContactListInventory;
// Customizable Area End
