//Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./EquizStyles.web";
export const configJSON = require("./config");
export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
  classes: any;
  grade: string;
  quizTitle: string
  reTakeBtn?: () => void;
  dashboardBtnHandler?:() => void;
  scoreDescriptionType? : string;
  isLoadingState?:  boolean;
  t?:any;
}

export interface S {
  // Customizable Area Start
  grade: string;
  reTakeQuizModal: boolean;
  // Customizable Area End
}
export interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class EquizScoreControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];
    this.state = {
      grade: this.props.grade || "A",
      reTakeQuizModal: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleRetakeQuizModal = () => {
    this.setState({ reTakeQuizModal: true });
  };

  handleCloseReTakeQuizModal = () => {
    this.setState({ reTakeQuizModal: false });
  };

  retakeQuizBtnHandler = () => {
    this.props.navigation.navigate("ReTakeQuizWeb");
    
  };
  

 
  
}
//Customizable Area End
