import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Button,
  MenuItem,
  FormControl,
  Select,
  InputLabel, Modal,
  FormHelperText
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme
} from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from "@material-ui/icons/Close";
import CustomInput from "../../../components/src/CustomInput.web";
import { fileIcon, tickIcon } from "../../catalogue/src/assets";
import { ImageDataType } from "./InventoryTypes";

import TrophyController, {
  Props,
  configJSON
} from "./TrophyController.web";
import { customStyles } from "../../../components/src/AddItemStyles.web";



// Customizable Area End

export class Trophy extends TrophyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, closeTrophy, openTrophyModal } = this.props;
    return (
      <Box>
        <Modal
          open={openTrophyModal}
          onClose={closeTrophy}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalDialog}
          data-testId={"trophymodalComponent"}
          BackdropProps={{ className: "backdrop-root" }} 
        >
          <Box className={`${classes.modalDialogBox} trophy-modal-dialogbox`}>
            <Box className="modal-heading">
              <Typography className="modal-title">
                {this.t(`${configJSON.trophyModalTitle}`)}
              </Typography>
              <CloseIcon
                className="close-icon"
                onClick={closeTrophy}
                data-testId="modal-closeIcon"
              />
            </Box>
            <Box className="modal-description">
              <Grid item md={12} lg={12} className={classes.rowSpacing}>
                <FormControl className="select-outer" variant="outlined" error={this.state.error.trophyName !== ""}>
                  <InputLabel>
                    {this.t(`${configJSON.trophynameLabel}`)}
                  </InputLabel>
                  <Select label={this.t(`${configJSON.trophynameLabel}`)}
                    value={this.state.trophyName}
                    onChange={(event) => this.handleTrophyName(event)}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} />
                    )}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                    }}
                    data-testId={"trophyNameFeild"}
                  >
                    {this.state.trophyNamesData.map((trophydata) => {
                      return <MenuItem value={trophydata.name}
                        key={trophydata.id} data-testId={`trohpyName-${trophydata.id}`}>
                        {trophydata.name}</MenuItem>
                    })}
                  </Select>
                  {this.state.error.trophyName !== "" ? (
                    <FormHelperText className="error-select">
                      {this.state.error.trophyName}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
              <Grid item md={12} lg={12} >
                <CustomInput
                  value={this.state.trophyYear}
                  onChange={(event) => this.handleTrophyYear(event)}
                  placeholder={this.t(`${configJSON.trophyYearPlacehoder}`)}
                  data-testId={"torphyYearFeild"}
                  errors={this.state.error.trophyYear}
                />
              </Grid>
              <Grid item md={12} lg={12} className={classes.rowSpacing}>
                <FormControl className="select-outer" variant="outlined" error={this.state.error.trophyResult !== ""}>
                  <InputLabel>
                    {this.t(`${configJSON.resultLabel}`)}
                  </InputLabel>
                  <Select
                    label={this.t(`${configJSON.resultLabel}`)}
                    data-testId={"trophyResultFeild"}
                    value={this.state.trophyResult}
                    onChange={(event) => this.handleTrophyResult(event)}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} />
                    )}
                    MenuProps={{
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                  >
                    {/* {this.state.trophyResultsData.map((result) => {
                      return <MenuItem value={result.name}
                        key={result.id} data-testId={`result-${result.id}`}>
                        {result.name}</MenuItem>
                    })} */}
                    {this.state.trophyResultsData
                      .sort((a, b) => a.name.localeCompare(b.name)) // Sort the trophyResultsData alphabetically by name
                      .map((result) => (
                        <MenuItem value={result.name} key={result.id} data-testId={`result-${result.id}`}>
                          {result.name}
                        </MenuItem>
                      ))
                    }

                  </Select>
                  {this.state.error.trophyResult !== "" ? (
                    <FormHelperText className="error-select">
                      {this.state.error.trophyResult}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
              <Grid item md={12} lg={12}>
                <CustomInput
                  value={this.state.trophComment}
                  onChange={(event) => this.handleTrophyComment(event)}
                  placeholder={this.t(`${configJSON.trophycommentPlaceholder}`)}
                  data-testId={"torphyCommentFeild"}
                  isMultiline={true}
                  rows={5}
                />
              </Grid>
              <Typography className={classes.uploadText}>
                {this.t(`${configJSON.uploadTitle}`)}
              </Typography>
              <input hidden ref={this.inputRef} type="file" multiple
                onChange={this.uploadFile} data-testId={"trophy-img-upload"}
              />
              <Box className={classes.browseBox} onClick={this.onClickBrowse} data-testId={"browse-text"}>
                <img src={fileIcon} className="pinImg" />
                <Typography className="upload-text">
                  {this.t(`${configJSON.uploadText1}`)} {" "}
                  <span className="span-text">{this.t(`${configJSON.browseText}`)}</span>
                  {" "}{this.t(`${configJSON.uploadText2}`)}
                </Typography>
              </Box>
              {this.state.trophyimagePreview.map((img: ImageDataType) => {
                return (
                  <Box key={img.url} className={classes.uploadFileBox}>
                    <img src={tickIcon} />
                    <Typography className="image-text" data-testId={`image-${img.name}`}>{img.name}</Typography>
                    <CloseIcon onClick={() => this.delTrophyImage(img.name)}
                      className="file-close-icon"
                      data-testId="closeIcon-img"
                    />
                  </Box>
                )
              })
              }
              <Box className="btn-box">
                <Button className="primary-btn" data-testId={"add-trophy"}
                  onClick={this.addTrophy}>
                  {this.t(`${configJSON.trophyButton}`)}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    )
  }
  // Customizable Area End
}

// Customizable Area Start


export default withStyles(customStyles, { withTheme: true })(Trophy);
// Customizable Area End