Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.putMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LiveChat2";
exports.labelBodyText = "LiveChat2 Body";
exports.getChatPath="/bx_block_chat/chats";
exports.sendChatRoomMsg ="/bx_block_chat/chat_messages";
exports.subscribedUserList="/bx_block_chat/subscribed_users";
exports.createChatForExternalAndInternalUser="/bx_block_chat/chats/create_chat_for_users";
exports.externalAndInternalMessage="/bx_block_chat/create_chat_message_for_users";
exports.markAsRead="/bx_block_chat/read_all";
exports.inviteMemberPath="/bx_block_chat/chats/invite_member";
exports.btnExampleTitle = "CLICK ME";
exports.chatConversationsTxt="Conversations";
exports.chatConversationTxt="Conversation";
exports.noChatsFoundMsg="No chats found";
exports.chatSubtext="Chat on the Move, Stay in the Groove: Teamwork Anywhere";
exports.searchPlaceholder="Start typing to search...";
exports.sortByTxt="Sort by";
exports.welcomeTxt="Welcome!";
exports.aDashZTxt="A-Z";
exports.zDashATxt="Z-A";
exports.newChatBtn="New Chat";
exports.defaultText="No Conversations Started Yet";
exports.subText = "Start a new conversation today!";
exports.lodingTxt="Loading...";
exports.typeMessage="Type Message...";
exports.defaultHeading="No Chats Yet";
exports.membersTxt="Members";
exports.extenalChatHeadding="Please enter the member email address.";
exports.externalChatSubText="Chat with an External Member";
exports.startChatTxt="Start Chat";
exports.inviteMemberTxt="Invite Member";
exports.emailAddressTxt="Email Address";
exports.extenalChat="External Chat";
exports.supportChat="Support";
exports.emailErrorMsg="Email should be valid";
exports.etohSupportChatAdded="EtOH support chat added succesfully";
exports.etohSupportAlreadyCreated="Chat for Etoh Shop Support already created";
exports.emailEmptyErrorMsg="Email should be blank";
exports.emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+(?:\.[A-Z]{2,4}){1,2}$/i;
exports.createExternalChatApi="/bx_block_chat/chats/varify_user_for_chat";
exports.errorMessageForUserNotFoud="Member profile not found";
exports.memberTxt="Member";
exports.recentText="Recent";
exports.unreadText="Unread";
exports.loadingTxt="Loading...";
exports.externalChatSubText="Chat with an External Member"
exports.etohSupportSubText="For technical issues, EtOH Shop queries, and say hello!"
exports.newChatSubTxt="Select a teammate, external user, or our support team to chat with"
exports.subTxtDefault="You have no live chats yet. Start a new conversation with a teammate, external member, or our support team today!";
exports.memberCriteriaTxt="Member Criteria";
exports.subTxtForInvitaion="We appreciate you growing our EtOH Suite network! Please confirm your invite meets one of the following criteria:";
exports.ownerTxt="A business owner";
exports.employeeTxt="An employee of a business using EtOH Suite";
exports.errorMessage="Not owner or an employee";
exports.ethoChatSubMsg="Need Technical or Sales Help? We're Here."
// Customizable Area End