// Customizable Area Start
import React from "react";
import { Box, CardMedia, Typography, Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBack from "@material-ui/icons/ArrowBack";
import {
  equizResult,
  openSimulation,
  deleteIcon,
  scoreB,
  scoreA,
  scoreC,
  scoreD,
  scoreE,
} from "./assets";
import QuizList from "./components/QuizList.web";

interface EQuizResultsProps {
  classes: any;
  modalOpen: boolean;
  modalClose: () => void;
  openDeleteModal: (questionId: string) => void;
  fullDetails: any;
  shareCodeModal: () => void;
  openSimulateScreen: (questionId: string) => void;
  openFullScreenMode: () => void;
  openRetakeQuiz: (quizId:string, questionTitle:string) => void;
  t:(key:string) => React.ReactNode;
}

const EQuizResults = (props: EQuizResultsProps) => {
  const {
    classes,
    modalOpen,
    modalClose,
    openDeleteModal,
    fullDetails,
    shareCodeModal,
    openSimulateScreen,
    openFullScreenMode,
    openRetakeQuiz,
    t
  } = props;
  console.log(fullDetails);
  const { quiz, questions } = fullDetails;
  const title = quiz.data.attributes.title;
  const caption = quiz.data.attributes.caption;
  const id = quiz.data.attributes.id;
  const grade = quiz.data.attributes.score;
  let gradeImg;
  if (grade === "A") {
    gradeImg = scoreA;
  } else if (grade === "B") {
    gradeImg = scoreB;
  } else if (grade === "C") {
    gradeImg = scoreC;
  } else if (grade === "D") {
    gradeImg = scoreD;
  } else if (grade === "E") {
    gradeImg = scoreE;
  }

  const questionsData = questions.reverse().map((question: any) => {
    return question;
  });
  
  return (
    <Modal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalBoxCenter}
    >
      <Box className={classes.modalQuizResults}>
        <Box className="quizresults-inner">
          <Box className="mobile-heading">
            <Link to="#" className="close-icon" onClick={modalClose}>
              <ArrowBack className="arrow-back" />
            </Link>
            <Button
              variant="text"
              className="link-share-score"
              onClick={shareCodeModal}
            >
             {t("Want to share your score?")}
            </Button>
          </Box>
          <Box className="quiz-result-heading">
            <CardMedia
              component="img"
              style={{
                width: "20px",
                height: "20px",
                objectFit: "contain",
                marginRight: "24px",
              }}
              image={deleteIcon}
              title="Delete-icon"
              onClick={() => openDeleteModal(id)}
            />
            <CardMedia
              component="img"
              style={{
                width: "16px",
                height: "16px",
                objectFit: "contain",
                marginRight: "24px",
              }}
              image={openSimulation}
              title="Full Screen"
              onClick={openFullScreenMode}
            />
            <Link to="#" className="close-icon" onClick={modalClose}>
              <CloseIcon className="arrow-back" />
            </Link>
          </Box>
          <Box className="quiz-result-content">
            <Box className="left-box">
              <Box className="left-box-header">
                <Box>
                  <Typography className="header-title">{title}</Typography>
                  <Typography className="header-sub-title">
                    {caption}
                  </Typography>
                </Box>
                <CardMedia
                  component="img"
                  image={gradeImg}
                  title=""
                  className="grade-img"
                />
              </Box>

              <Box className="quiz-list-wrapper">
                <QuizList disable={true} quizData={questionsData} />
              </Box>
              <Box className="button-wrapper">
                <Button
                  className={`btn-try-simulation-mode`}
                  data-test-id="btn-try-simulation-mode"
                  onClick={() => openSimulateScreen(id)}
                >
                  {t("Try Simulation Mode")}
                </Button>
                <Button
                  className={`primary-btn ${classes.secondaryButton}`}
                  data-testid="btn-retake-equiz"
                  onClick={() => openRetakeQuiz(id, title)}
                >
                  {t("Retake E-Quiz")}
                </Button>
              </Box>
            </Box>
            <Box className="right-box">
              <Box className="right-box-content">
                <CardMedia
                  component="img"
                  image={equizResult}
                  title=""
                  className="img-right-box"
                />
                <Typography className="recomendation-title">
                 {t("Our Recommendation")}
                </Typography>
                <Typography className="recomendation-content">
                  To improve your {title} E-Score, consider purchasing our
                  <a href="https://tools.etoh.fr/" target="_blank"  className="hightlight-txt">economic consultation services</a>
                  from the EtOH Shop. For any questions, don’t hesitate to reach
                  out to EtOH over <a href="https://tools.etoh.fr/" target="_blank"  className="hightlight-txt">chat</a>.
                </Typography>
              </Box>
              <Button
                className={`modal-btn share-score-btn ${classes.primaryButton}`}
                onClick={shareCodeModal}
              >
               {t("Want to share your score?")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default EQuizResults;
// Customizable Area End
