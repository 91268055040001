import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box, Typography } from "@material-ui/core";
import { defaultView } from "../../blocks/ordermanagement/src/assets";
import { configJSON } from "../../blocks/ordermanagement/src/OrderHistoryController.web";

const useStyles = makeStyles({
  table: {
    "& .header": {
      fontSize: "12px !important",
      color: "#94A3B8 !important",
      fontFamily: "Roboto !important",
      fontWeight: "400 !important",
      borderTop: "1px solid #E8ECF2 !important",
      whiteSpace: "nowrap !important",
      paddingRight: "20px !important",
    },
    "& .MuiTableHead-root": {
      fontSize: "12px",
      color: "#94A3B8",
      fontFamily: "Roboto",
      fontWeight: "400",
      borderTop: "1px solid #E8ECF2",
      whiteSpace: "nowrap",
      paddingRight: "20px",
    },
    "& .MuiTableCell-root": {
      borderBottom: "1px solid #E8ECF2",
      padding: "24px 9px",
      fontSize: "14px",
      color: "#2B2B2B",
      fontFamily: "Expletus Sans",
      fontWeight: "500",
    },
    "& .error-container":{
      position:"absolute",
      left:"50%",
      top:"90%",  
      "& .error-message": {
        display: "flex",        
        justifyContent: "center",
        color: "#2B2B2B",
        fontFamily: "Expletus Sans",
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "0.1px",
        height: "20px",
        verticalAlign: "center",
        marginRight: "16px",
        textAlign: "center",
        whiteSpace: "nowrap",
        marginLeft: "16px",
      },
    },
    "& .item-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .image": {
        width: "56px",
        height: "56px",
      },
      "& .id-info": {
        margin: "20px",
      },
      "& .order-id": {
        fontSize: "10px",
        color: "#94A3B8",
        fontFamily: "Roboto",
        paddingBottom: "0",
        fontWeight: "400",
        whiteSpace: "nowrap",
      },
      "& .order-item-quantity": {
        fontSize: "14px",
        color: "#2B2B2B",
        fontFamily: "Expletus Sans",
        paddingBottom: "0",
        fontWeight: "500",
        marginTop: "5px",
      },
    },
    "& .link": {
      fontSize: "14px",
      color: "#4BA3B7",
      fontFamily: "Expletus Sans",
      paddingBottom: "0",
      fontWeight: "500",
      whiteSpace: "nowrap",
      borderBottom: "1px solid #4BA3B7",
      width: "70px",
      cursor: "pointer",
      lineHeight: "14px",
    },
    "& .link-2": {
      fontSize: "14px",
      color: "#4BA3B7",
      fontFamily: "Expletus Sans",
      paddingBottom: "0",
      fontWeight: "500",
      whiteSpace: "nowrap",
      borderBottom: "1px solid #4BA3B7",
      cursor: "pointer",
      width: "110px",
      lineHeight: "14px",
    },
    "& .status-item": {
      display: "flex",
      alignItems: "center",
      "& .status-name": {
        margin: "10px",
        whiteSpace: "nowrap",
      },
    },
  },
  status: {
    height: "12px",
    width: "12px",
    background: "#94A3B8",
    borderRadius: "100%",
  },
  inProgress: {
    background: "#F6A318",
  },
  orderPlaced: {
    background: "#2B2B2B",
  },
  shipped: {
    background: "#12AEF1",
  },
  delivered: {
    background: "#00BA88",
  },
  onHold: {
    background: "#C7263E",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
});

export default function OrderMangementTable(props: any) {
  const classes = useStyles();
  const { data, handleOpenViewDetails, handleOpenFillDetails, t } = props;
  const formatDate = (ordersDate: any) => {
    const createdDate = new Date(ordersDate);
    const month = configJSON.monthsData[createdDate.getMonth()];
    const hours = createdDate.getHours();
    const minutes = createdDate.getMinutes();
    const day = createdDate.getDate();
    const year = createdDate.getFullYear();
    const ampm = hours >= 12 ? "pm" : "am";

    const formatedDate = `${month} ${day}, ${year} at ${hours % 12 || 12}:${
      minutes < 10 ? "0" : ""
    }${minutes}${ampm}`;

    return formatedDate;
  };

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead className="MuiTableHead-root">
          <TableRow>
            <TableCell className="header">{t("Item")}</TableCell>
            <TableCell className="header">{t("Order Status")}</TableCell>
            <TableCell className="header">{t("Total Price Paid")}</TableCell>
            <TableCell className="header">{t("Date Ordered")}</TableCell>
            <TableCell className="header">{t("Actions")}</TableCell>
            <TableCell className="header" />
          </TableRow>
        </TableHead>
        {data.length === 0 ? (
          <TableBody className="error-container">
            <Box className="error-message">
              <Typography>{t(`${"No order present"}`)}</Typography>
            </Box>
          </TableBody>
        ) : (
          <TableBody>
            {data.map((order: any) => {              
              return (
                <TableRow key={order.id}>
                  <TableCell>
                    <Box className="item-container">
                      <img
                        className="image"
                        src={
                          order.attributes.order_items.data[0]?.attributes
                            .catalogue_service.data.attributes.thumbnails
                            .length > 0
                            ? order.attributes.order_items.data[0]?.attributes
                                .catalogue_service.data.attributes.thumbnails[0]
                                .url
                            : defaultView
                        }
                        alt="imgae"
                      />
                      <Box className="id-info">
                        <Typography className="order-id">
                          {t("Order ID")}:&nbsp;{order.id}
                        </Typography>
                        <Typography className="order-item-quantity">
                          {order.attributes.total_items > 1
                            ? `${order.attributes.total_items} ${t("Items")}`
                            : `${order.attributes.total_items} ${t("Item")}`}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className="status-item">
                      <Box
                        className={`${classes.status} ${
                          order.attributes.status === "In progress"
                            ? classes.inProgress
                            : order.attributes.status === "Order placed"
                            ? classes.orderPlaced
                            : order.attributes.status === "Shipped"
                            ? classes.shipped
                            : order.attributes.status === "Delivered"
                            ? classes.delivered
                            : order.attributes.status === "On hold"
                            ? classes.onHold
                            : ""
                        }`}
                      />
                      <Box className="status-name">
                        {order.attributes.status}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>€{order.attributes.total_fees}</TableCell>
                  {/* <TableCell>{order.attributes.address.data}</TableCell> */}
                  <TableCell>
                    {formatDate(order.attributes.created_at)}
                  </TableCell>
                  <TableCell
                    style={{ cursor: "pointer" }}
                    className={classes.noWrap}
                    onClick={() => handleOpenViewDetails(order.id)}
                  >
                    {t("View Details")}
                  </TableCell>
                  {order.attributes.status === "Order placed" && (
                    <TableCell className={`${classes.noWrap}`}>
                      <Typography
                        className="link"
                        onClick={() => handleOpenFillDetails(order.id)}
                      >
                        {t("Fill Details")}
                      </Typography>
                    </TableCell>
                  )}
                  {order.attributes.status === "On hold" && (
                    <TableCell className={`${classes.noWrap}`}>
                      <Typography className="link-2">
                        {t("Contact Support")}
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}
