import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme
} from "@material-ui/core/styles";
import CustomInput from "../../../components/src/CustomInput.web";

import ItemMoreDetailsController, {
  Props,
  configJSON
} from "./ItemMoreDetailsController.web";
// import { customStyles } from "../../../components/src/Inventory/ItemMoreDetailsStyles.web";
import { customStyles } from "../../../components/src/AddItemStyles.web";

// Customizable Area End

export class ItemMoreDetails extends ItemMoreDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, seletedItem} = this.props;
    return (
        <Box className={classes.basicInfoSection}>
            <Box className="heading-box">
                <Box>
                    <Typography className={classes.heading} variant="h1">
                   {this.t(`${configJSON.moreDetailsTitle}`)}
                    </Typography>
                    <Typography className={classes.subtitleText}>
                    {this.t(`${configJSON.detailsSubtext}`)}
                    </Typography>
                </Box>
                <Box className="item-box" data-testId="itemText">
                    {seletedItem}
                </Box>
            </Box>
            <Grid className={classes.formSection} alignItems="center" justifyContent="center">
                <Grid item md={12} lg={12} sm={12} xs={12}>
                    <CustomInput
                        value={this.state.hop}
                        onChange={(event)=>this.handleHop(event)}
                        placeholder={this.t(`${configJSON.hopPleaceholder}`)}
                        data-testId={'hopField'}
                        errors={this.state.error.hop}
                    />
                </Grid>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                    <CustomInput
                        value={this.state.mashing}
                        onChange={(event)=>this.handleMashing(event)}
                        placeholder={this.t(`${configJSON.mashingPlaceholder}`)}
                        data-testId={'mashingField'}
                        errors={this.state.error.mashing}
                    />
                </Grid>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                    <CustomInput
                        value={this.state.strength}
                        onChange={(event)=>this.handleStrength(event)}
                        placeholder={this.t(`${configJSON.strengthPlaceholder}`)}
                        data-testId={'strengthField'}
                        errors={this.state.error.strength}
                    />
                </Grid>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                    <CustomInput
                        value={this.state.fermentation}
                        onChange={(event)=>this.handleFermentation(event)}
                        placeholder={this.t(`${configJSON.fermentationPlaceholder}`)}
                        data-testId={'fermentationField'}
                        errors={this.state.error.fermentation}
                    />
                </Grid>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                <CustomInput
                    isMultiline={true}
                    rows={5}
                    value={this.state.ingredients}
                    onChange={(event)=>this.handleIngredients(event)}
                    placeholder={this.t(`${configJSON.ingredientPlaceholder}`)}
                    data-testId={'ingredientField'}
                    errors={this.state.error.ingredients}
                />
                </Grid>
            </Grid>
            <Box className={classes.formButtons}>
              <Button className="backBtn" onClick={this.backToDetailsStep}
              data-testId={"back-btn"}
              >
                  {this.t(`${configJSON.backButton}`)}
              </Button>
              <Button className="next-step-btn" onClick={this.handleNextStep}
              data-testId={"nextstep-btn"}
              >
                  {this.t(`${configJSON.nextButton}`)}
              </Button>
            </Box>
        </Box>
      )
    }
    // Customizable Area End
}

// Customizable Area Start


export default withStyles(customStyles, { withTheme: true })(ItemMoreDetails);
// Customizable Area End