Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"

exports.modalTitle = "Manage Inventory";
exports.modalSubText = "Show categories for your company to use in the inventory.";
exports.catergoryName1 = "Products";
exports.catergoryName2 = "Tools";
exports.catergoryName3 = "Assets";
exports.catergoryName4 = "Consumables";
exports.wineText = "Wine";
exports.beerText = "Beer";
exports.spiritText = "Spirit";
exports.otherText = "Other Products";
exports.machineText = "Machinery";
exports.hardwareText = "Hardware";
exports.landText = "Land";
exports.vesselsText = "Vessels";
exports.FertilizersText = "Fertilizers";
exports.PhytoProductsText = "Phyto-Products";
exports.ChemicalsText = "Chemicals";
exports.ownerPermission="bx_block_inventory_management/set_permitted_inventory_items";
exports.showInventory="bx_block_inventory_management/view_categories";
exports.patchApiMethod = "PATCH";
// Customizable Area End