import { createSlice } from "@reduxjs/toolkit";
export type InitialStateType = {
    isMemberMoved: boolean
}
const initialState:InitialStateType = {
    isMemberMoved: false
}
const stateSlice = createSlice({
    name: 'stateManager',
    initialState,
    reducers: {
        updateIsMemberMoved: (state, action) => {
            state.isMemberMoved = action.payload
        },
    }
})
export const { updateIsMemberMoved } = stateSlice.actions
export default stateSlice.reducer