import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardArrowDown } from '@material-ui/icons';
import { getImageForSubCategory ,extractItemDetails } from "./ReusableFunctions";

interface ProductItemsModalProps {
  item:any
}
const ProductItemsModal = ( props:ProductItemsModalProps) => {
  const {item} = props;
  console.log(item)
  const {
    targetName,
    brandName,
    materialName,
    colorName,
    style,
    appellation,
    grapeVariety,
    vintage,
    itemName,
  } = extractItemDetails(item);
  

  return (
    <Box className="product-col col4" key={item.id}>
      <Box className="product-inner">
        <Box className="product-img">
          {getImageForSubCategory(item.attributes.sub_category.id, item.attributes)}
        </Box>
        <Box className="product-details">
          <Box className="product-info">
            <Box className="year">2024</Box>
            <Box className="rating">{vintage} ml</Box>
          </Box>
          <Box className="product-name">{itemName}</Box>
          <Box className="product-desc">
            {brandName}
            {style}
            {targetName !== "" && brandName !== "" ? `, ${targetName}` : targetName}
            {materialName !== "" && brandName !== "" ? `, ${materialName}` : materialName}
            {colorName !== "" && (brandName !== "" || style !== "") ? `, ${colorName}` : colorName}
            {appellation}
            {grapeVariety !== "" && appellation !== "" ? `, ${grapeVariety}` : grapeVariety}
          </Box>
          <Typography className="product-desc">Retail Price <KeyboardArrowDown className="arrow-icon"/></Typography>
          {/* <Typography className="price-txt">
            ${(selected_price.amount).toFixed(2)}
          </Typography> */}
        </Box>
      </Box>
    </Box>
  );
};

export default ProductItemsModal;
