// Customizable Area Start
import React from "react";
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import { calendarBlack } from "./assets";
import { calendar2 } from "../../blocks/TaskList/src/assets";

export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    createTaskWrapper: {
      "& .task-actions": {
        position: "fixed",
        right: "0",
        bottom: "27px",
        background: "#ffffff",
        maxWidth: "calc(100% - 367px)",
        padding: "0 32px",
        [theme.breakpoints.down("md")]: {
          maxWidth: "calc(100% - 320px)",
        },
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
        },
        "& .MuiButton-root": {
          marginBottom: "5px",
        },
        "& .secondary-btn": {
          boxShadow: "0px 0px 18px 2px rgba(0, 0, 0, 0.10)",
          marginRight: "16px",
          minWidth: "auto",
          color: "rgba(43, 43, 43, 0.87)",
          "&:hover": {
            border: "1px solid rgba(43, 43, 43, 0.87)",
          },
          "& .draft-name": {
            display: "flex",
            alignItems: "center",
          },
          "& .draft-img": {
            marginRight: "6px",
          },
          "& .close": {
            margin: "0 0 0 5px",
            cursor: "pointer",
          },
        },
      },
      "& .primary-btn": {
        boxShadow: "0px 0px 24px 0px rgba(5, 111, 135, 0.47)",
        minWidth: "136px",
      },
    },
    modalFilterDialogBox: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: 680,
      backgroundColor: "#ffffff",
      borderRadius: "0",
      padding: "0",
      height: "100%",
      maxWidth: "100%",
      overflowY: "auto",
      [theme.breakpoints.down("sm")]: {
        width: 570 ,
      },
      [theme.breakpoints.down("xs")]: {
        width: "90% ",
      },
      "& .primary-btn2":{
        marginTop:"45px !important",
        width:"100% !important",
       },
       "& .MuiSwitch-switchBase": {
        color: "#fafafa ",
        [theme.breakpoints.down("xs")]: {
          color: "#ffffff ",
        },
        "&.Mui-checked": {
          color: "#3691a6",
          [theme.breakpoints.down("xs")]: {
            transform: "translateX(12px) ",
          },
          "&+ .MuiSwitch-track": {
            backgroundColor: "#3691a6 ",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.2))",
        [theme.breakpoints.down("xs")]: {
          height: "8px",
          width: "8px",
        },
      },
      "& .MuiSwitch-colorSecondary.Mui-checked":{
        color:'#FFF' 
      },
      "&.view-modal-dialog": {       
        "& .view-more-dropdown":{       
          "& .dropdown-menu-item":{
            display: 'flex',
            padding: '12px 0px',
            justifyContent: 'space-between',
            alignSelf: 'stretch',
            alignItems: 'center',
          },
          "& .MuiFormControl-root":{
            marginLeft:"20px"
            // width:"280px",
          },
        
          "& .dropdown-menu-item-column":{
            display: 'flex',
            padding: '8px 16px',
            justifyContent: 'space-between',
            alignSelf: 'stretch',
            alignItems: 'center',
          },
          "& .MuiSvgIcon-root":{
            color:"#E8ECF2 "
          },         
          "& .MuiTouchRipple-root": {
            display: "none ",
          },      
          "& .dropdown-menu-item-text":{
            color: '#2B2B2B',
            fontFamily: 'Expletus Sans',
            fontSize: '14px',
            lineHeight: 'normal',
            fontStyle: 'normal',
            fontWeight: 500,
          }
        },              
        "& .modal-content":{  
          padding:"60px", 
          "& .form-row": {
            display: "flex",
            flexWrap: "wrap",
            margin: "0 -8px",
            "& .form-col": {
              padding: "0 8px",
              flex: "0 0 100%",
              maxWidth: "100%",
              "&.col6": {
                flex: "0 0 50%",
                maxWidth: "50%",
                [theme.breakpoints.down("xs")]: {
                  flex: "0 0 100%",
                  maxWidth: "100%",
                },
              },
              "& .form-control": {
                marginBottom: "16px",
              },
              "& .autocomplete-wrapper": {
                marginBottom: "16px",
              },
            },
          },       
          "& .select-outer": {
            width: "100%",
            marginBottom: "16px",
            "& .error-select":{
              color:'#C7263E ',  
            },
            "& svg":{
             fill:'#94A3B8 ',  
           },
            "& .Mui-error":{
              "& .MuiOutlinedInput-notchedOutline":{
                borderColor:'#C7263E',  
              }
            },       
            "& .MuiInputLabel-formControl": {
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              letterSpacing: "0.1px",
              fontWeight: 500,
              lineHeight: "19px",
              width: "calc(100% - 46px)",
              color: "#94A3B8",
              display: "flex",
              "&.MuiFormLabel-filled": {
                width: "auto",
                color : "rgba(43,43,43,0.87)",
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.0075em",
                fontFamily: "Roboto",
                fontWeight: "400",
              }
            },
            "& .MuiSelect-select": {
              letterSpacing: "0",
              color: "#000000",
              fontSize: "14px",
              opacity: "0.671",
              fontWeight: "500",
              justifyContent:'space-between',
              fontFamily: "Expletus Sans",
              display:'flex',
              "&:focus": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2 ",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px ",
                },
              },
            },
            "& .MuiFormLabel-root": {
              "&.Mui-focused": {
                color : "rgba(43,43,43,0.87)",
                width: "auto",
                fontSize: "12px",
                letterSpacing: "0.0075em",
                fontWeight: "400",
                lineHeight: "16px",
                fontFamily: "Roboto",
              },
              "& .MuiInputLabel-asterisk": {
                marginLeft: "auto",
                lineHeight: "23px",
                fontSize: "18px",
                color: "#e86577",
              }
            }
          },         
          "& .desc":{
            marginBottom:"60px"
          },
          "& .filter-headding":{
            color: '#2B2B2B',
            fontSize: '16px',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            lineHeight: '24px',
            fontWeight: 500,
            letterSpacing: '0.25px',
            marginBottom:"10px",
            marginTop:"8px",
            textTransform:"capitalize"
          },
          "& .modal-wrapper":{
            justifyContent:"space-between",
            display:"flex",
              alignItems:"center", 
              "& .reset-cross":{
                display:"flex",
                "& .reset-txt":{
                  color: 'var(--brand-color-primary-100, #4BA3B7)',
                  fontSize: '14px',
                  fontFamily: 'Expletus Sans',
                  fontStyle: 'normal',
                  textAlign: 'right',                
                  fontWeight: 500,
                  marginRight:"16px",
                  lineHeight: 'normal',
                  cursor:"pointer",
                },
                "& .close-icon":{
                  cursor:"pointer",
                  "& .MuiSvgIcon-root": {
                    fontSize: "16px",
                    height: "24px",
                    width: "24px",
                    color: "#94A3B8",
                  },
                },
               },            
            "& .modal-headding":{
              fontFamily: 'Expletus Sans',
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '34px',
              fontWeight: 400,
              fontStyle: 'normal',
              letterSpacing: '0.25px',
              lineHeight: 'normal',
            },
          },
        }         
      },    
    },
    modalFilterDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502) ",
      },
    },
    chipButton: {
      background: "#ECF1F4",
      borderRadius: "6px",
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "12px",
      color: "#2B2B2B",
      padding: "7px 6px",
      cursor: "pointer",
      marginRight: "8px",
      marginBottom: "8px",
      height: "26px",
      "&.more-btn": {
        marginRight: "0",
      },
      "& .MuiChip-avatar": {
        width: "12px",
        height: "12px",
        marginLeft: "0",
        marginRight: "5px",
      },
      "& .MuiChip-label": {
        padding: "0",
      },
    },
    timestandDropbox: {
      zIndex: 1300,
      borderRadius: "12px",
      padding: "24px",
      minWidth: "230px",
      background: "#fff",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      position: "absolute",
      bottom: "100%",
      "& .timestand-inner": {
        "& .timestand-heading": {
          fontSize: "16px",
          lineHeight: "24px",
          color: "#2B2B2B",
          fontWeight: 400,
          fontFamily: "Expletus Sans",
          marginBottom: "16px",
        },
        "& .time": {
          display: "flex",
          alignItems: "center",
          "& .MuiFormControl-root": {
            maxWidth: "33px",
          },
          "& .MuiInputBase-input": {
            fontSize: "14px",
            lineHeight: "18px",
            color: "#2b2b2b",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
            padding: "0",
            "&::-webkit-input-placeholder": {
              opacity: "1",
              color: "rgba(43, 43, 43, 0.87)",
            },
            "&:-ms-input-placeholder": {
              opacity: "1",
              color: "rgba(43, 43, 43, 0.87)",
            },
            "&::placeholder": {
              opacity: "1",
              color: "rgba(43, 43, 43, 0.87)",
            },
          },
          "& .colon-txt": {
            fontSize: "14px",
            lineHeight: "18px",
            color: "#2b2b2b",
            fontWeight: 500,
            fontFamily: "Expletus Sans",
            margin: "0 10px 0 5px",
          },
        },
      },
      "& .timestand-actions": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingTop: "16px",
        marginTop: "16px",
        borderTop: "1px solid #E8ECF2",
        "& .secondary-link": {
          fontSize: "14px",
          lineHeight: "18px",
          color: "#94A3B8",
          fontWeight: 500,
          fontFamily: "Expletus Sans",
          textDecoration: "none",
          marginRight: "24px",
          cursor: "pointer",
          "&:last-child": {
            marginRight: "0",
          },
        },
        "& .primary-link": {
          color: "#4BA3B7",
          cursor: "pointer",
        },
      },
    },
    createTaskBox: {
      borderRadius: "12px",
      background: "#FFF",
      boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
      padding: "0",
      overflow: "hidden",
      maxWidth: "591px",
      width: "100%",
      marginLeft: "auto",
      maxHeight: "unset",
      display: "inline-block",
      top: "auto !important",
      bottom: "95px",
      right: "32px",
      position: "fixed",
      [theme.breakpoints.down("xs")]: {
        top: "0 !important",
        bottom: "0",
        right: "0",
        left: "0 !important",
        maxWidth: "100%",
        overflow: "auto",
      },
      "& .auth-back": {
        padding: "16px 24px",
        color: "rgba(43,43,43,0.67)",
      },
      "& .assignee-list": {
        display: "flex",
        alignItems: "center",
        // marginLeft: "6px",
        "& .assignee-bubble": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#ECF1F4",
          width: "28.328px",
          height: "28.328px",
          borderRadius: "100%",
          border: "2.364px solid #FFF",
          color: "rgba(43, 43, 43, 0.87)",
          fontSize: "9.586px",
          fontFamily: "Roboto",
          fontWeight: 600,
          lineHeight: "13.42px",
          letterSpacing: "-0.244px",
          position: "relative",
          marginLeft: "-4.7268px",
          "&:first-child": {
            marginLeft: "0",
          },
        },
      },
      "& .task-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "40px 40px 14px",
        marginBottom: "0",
        [theme.breakpoints.down("xs")]: {
          padding: "24px 24px 12px",
        },
        "& .sub-txt": {
          letterSpacing: "0.09px",
          fontWeight: 400,
          color: "#94A3B8",
          fontFamily: "Roboto",
          lineHeight: "16px",
          fontSize: "12px",
          [theme.breakpoints.down("xs")]: {
            color: "#2B2B2B",
          },
        },
        "& .modal-title": {
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          lineHeight: "24px",
          fontSize: "18px",
          marginBottom: "4px",
          letterSpacing: "0",
          color: "#2B2B2B",
          [theme.breakpoints.down("xs")]: {
            lineHeight: "26px",
            fontSize: "22px",
            marginBottom: "8px",
          },
        },

        "& .heading-right": {
          display: "flex",
          marginLeft: "10px",
          "& .heading-icon": {
            marginRight: "24px",
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
      },
      "& .task-description": { 
        padding: "10px 40px",
        overflowY: "auto",
        maxHeight: "calc(100vh - 300px)",
        [theme.breakpoints.down("xs")]: {
          overflowY: "unset",
          maxHeight: "unset",
          padding: "12px 24px 8px",
        },
        "& .rmdp-container": {
          "& .rmdp-input": {
            paddingLeft: "16px",
            backgroundImage: `url(${calendar2}) !important`,
            backgroundRepeat: "no-repeat",
            "&::-webkit-input-placeholder": {
              color: "#94A3B8",
            },
            "&:-ms-input-placeholder": {
              color: "#94A3B8",
            },
            "&::placeholder": {
              color: "#94A3B8",
            },
          },
        },
      },
      "& .form-heading": {
        color: "#2B2B2B",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "19px",
        fontFamily: "Expletus Sans",
        letterSpacing: "0",
        marginBottom: "16px",
        display: "flex",
        justifyContent: "space-between",
        "& .close-icon": {
          marginLeft: "10px",
          "& .MuiSvgIcon-root": {
            fontSize: "16px",
            width: "16px",
            height: "16px",
            color: "#94A3B8",
          },
        },
      },
      "& .form-info-wrapper": {
        marginBottom: "16px",
        "& .expenditure-title": {
          color: "#000",
          fontFamily: "Expletus Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "24px",
          marginBottom: "16px",
        },
        "& .wrap-option": {
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          marginBottom: "24px",
  
          "& .btn-add-option": {
            textTransform: "none",
            display: "flex",
            flexDirection: "row",
            padding: " 6px 12px",
            alignItems: "flex-start",
            gap: "8px",
            borderRadius: "15px",
            border: "1px solid var(--stroke, #E8ECF2)",
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
  
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
  
            marginRight: "12px",
            marginBottom: "12px",
          },
        },
        "& .favorite-box": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderRadius: "8px",
          padding: "24px",
          border: "1px solid #E8ECF2",
          marginTop:"20px",
          "& .category-left-box": {
            "& .left-title": {
              color: "var(--task-completed-color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              marginBottom: "10px",
            },
            "& .left-sub-txt": {
              color: "var(--text-secondary, #94A3B8)",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "16px",
              letterSpacing: "0.09px",
            },
            "& .total-amount-txt": {
              color: "var(--text-secondary, #94A3B8)",
              texAlign: "right",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            },
          },
          "& .category-right-box": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // marginTop: "24px",
            
            "& .amount-txt": {
              color: "var(--text-secondary, #2B2B2B)",
              texAlign: "right",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              marginLeft: "6px",
            },
            "& .arrow-btn": {
              // minWidth: "24px",
              "& .btn-icon": {
                height: "24px",
                width: "24px",
                color:"#94A3B8"
              },
            },
          },
        },
        "&:last-child": {
          marginBottom: "0",
        },
        "& .form-row": {
          "&:last-child": {
            "& .form-control": {
              [theme.breakpoints.down("xs")]: {
                marginBottom: "0",
              },
            },
          },
        },
      },
      "& .form-row": {
        display: "flex",
        flexWrap: "wrap",
        margin: "0 -8px",
        "& .form-col": {
          padding: "0 8px",
          flex: "0 0 100%",
          maxWidth: "100%",
          "&.col6": {
            flex: "0 0 50%",
            maxWidth: "50%",
            [theme.breakpoints.down("xs")]: {
              flex: "0 0 100%",
              maxWidth: "100%",
            },
          },
          "& .form-control": {
            marginBottom: "16px",
          },
          "& .autocomplete-wrapper": {
            marginBottom: "16px",
          },
        },
      },
      "& .attachlist-ul": {
        padding: "0",
        "& .attachlist-li": {
          paddingLeft: "0",
          paddingTop: "15px",
          paddingBottom: "15px",
          borderBottom: "1px solid #E8ECF2",
          background: "transparent",
          "&:last-child": {
            borderBottom: "0",
          },
          "& .attachlist-label": {
            color: "#2B2B2B",
            fontSize: "14px",
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            margin: "0",
            "& .MuiTypography-root": {
              font: "inherit",
              letterSpacing: "inherit",
            },
          },
          "& .attachlist-icon": {
            minWidth: "unset",
            marginRight: "15px",
          },
        },
      },
      "& .upload-list-row": {
        display: "flex",
        flexWrap: "wrap",
        margin: "16px -8px 0",
        "& .upload-col": {
          padding: "0 8px",
          marginBottom: "16px",
          "&.col3": {
            flex: "0 0 25%",
            [theme.breakpoints.down("xs")]: {
              flex: "0 0 33.33%",
            },
          },
        },
        "& .upload-inner": {
          borderRadius: "6px",
          border: "2px dashed #e5e5e5",
          padding: "15px 16px",
          position: "relative",
          width:"108px",
          "& .upload-img": {
            textAlign: "center",
            width: "76px",
            height: "76px",
            maxWidth: "100%",
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        },
        "& .upload-details": {
          "& .img-name": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "8px",
            fontWeight: 400,
            lineHeight: "12px",
            marginTop: "6px",
            textAlign: "center",
            width: "108px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        "& .close-icon": {
          color: "#94A3B8",
          position: "absolute",
          right: "8px",
          top: "8px",
          width: "20px",
          height: "20px",
          fontSize: "20px",
          "& .MuiSvgIcon-root": {
            width: "inherit",
            height: "inherit",
            color: "inherit",
            fontSize: "inherit",
          },
        },
      },
      "& .inventory-wrapper": {
        marginBottom: "12px",
        "& .links-wrapper": {
          display: "flex",
          flexWrap: "wrap",
        },
        "& .inventory-link": {
          padding: "5px 12px",
          borderRadius: "15px",
          border: "1px solid #E8ECF2",
          color: "rgba(43, 43, 43, 0.87)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0.09px",
          textDecoration: "none",
          marginRight: "12px",
          marginBottom: "12px",
        },
      },
      "& .createtask-bottom": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "14px 40px 40px",
        [theme.breakpoints.down("xs")]: {
          padding: "8px 24px 24px",
          flexWrap: "wrap",
        },
        "& .button-wrapper": {
          "&.full-btn": {
            width: "100%",
            "& .MuiButton-root": {
              width: "100%",
            },
          },
        },
        "& .createtask-left": {
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "24px",
          },
        },
        "& .createtask-right": {
          [theme.breakpoints.down("xs")]: {
            width: "100%",
          },
          "& .MuiButton-root": {
            [theme.breakpoints.down("xs")]: {
              width: "100%",
            },
          },
        },
        "& .outerbutton-wrapper": {
          marginRight: "8px",
          position: "relative",
          "& .outer-link": {
            minWidth: "48px",
            height: "48px",
            borderRadius: "48px",
            border: "1px dashed #E8ECF2",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "none",
            padding: "10px",
            position: "relative",
            cursor: "pointer",
            "& .priority": {
              color: "#94A3B8",
              "&.active-priority": {
                color: "#2B2B2B",
              },
            },
            "& .time": {
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
              lineHeight: "19px",
              // marginLeft: "5px",
            },
            "& .assignee-bubble": {
              color: "rgba(43, 43, 43, 0.87)",
              fontFamily: "Expletus Sans",
              fontSize: "11.331px",
              fontWeight: 500,
              lineHeight: "15.863px",
              letterSpacing: "-0.289px",
            },
            "& .cancel-link": {
              position: "absolute",
              right: "-2px",
              top: "-2px",
              "& .MuiSvgIcon-root": {
                width: "20px",
                height: "20px",
                fontSize: "20px",
                color: "#2B2B2B",
              },
            },
          },
        },
        "& .timestand-dropbox": {
          zIndex: "1300",
        },
        "& .primary-btn": {
          minWidth: "165px",
        },
      },
      "& .select-outer": {
        width: "100%",
        marginBottom: "24px",
        "& .MuiInputLabel-formControl": {
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "19px",
          letterSpacing: "0.1px",
          color: "#94A3B8",
          display: "flex",
          width: "calc(100% - 46px)",
          "&.MuiFormLabel-filled": {
            width: "auto",
            color: "rgba(43,43,43,0.87)",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: 400,
            lineHeight: "16px",
            letterSpacing: "0.0075em",
          },
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0",
          color: "#000000",
          opacity: "1",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          display: "block",
          alignItems: "center",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
            },
          },
        },
        "& .MuiFormLabel-root": {
          "&.Mui-focused": {
            width: "auto",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#e86577",
            fontSize: "18px",
            lineHeight: "23px",
            marginLeft: "auto",
          },
        },
      },
      "& .hide-sm": {
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
      "& .show-sm": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          display: "block",
        },
      },
    },
    fileUploadWrapper: {
      border: "2px dashed #e5e5e5",
      padding: "20px 24px",
      borderRadius: "12px",
      textAlign: "center",
      transition: "all 0.5s ease-in-out",
      cursor: "pointer",
      "&:hover": {
        // filter: "drop-shadow(0px 16px 10px rgba(0,0,0,0.05))",
        // backgroundColor: "#fafcfe",
        // borderColor: "transparent",
      },
      "& .upload-icon": {
        marginBottom: "16px",
      },
      "& .secondary-btn2": {
        color: "#4BA3B7",
        borderRadius: "6px",
        borderColor: "#4BA3B7",
        minWidth: "130px",
        minHeight: "36px",
        padding: "8px 4px",
        "&:hover": {
          color: "#4BA3B7",
        },
      },
      "& .file-heading": {
        fontSize: "16px",
        lineHeight: "21px",
        margin: "0 0 7px",
        color: "rgba(0,0,0,0.87)",
        letterSpacing: "0.15px",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
      },
      "& .file-subtxt": {
        fontSize: "12px",
        lineHeight: "16px",
        color: "#2B2B2B",
        letterSpacing: "0.09px",
        fontFamily: "Roboto",
        fontWeight: 400,
        marginBottom: "16px",
        "& .browse-link": {
          color: "#4ba3b7",
          cursor: "pointer",
          margin: "0 5px",
          fontWeight: 700,
        },
      },
    },
    fileDropDrag: {
      filter: "drop-shadow(0px 16px 10px rgba(0,0,0,0.05))",
      backgroundColor: "#fafcfe",
      borderColor: "transparent",
    },
    fileUploadOutlineWrapper: {
      border: "1px dashed #e5e5e5",
      padding: "24px",
      borderRadius: "12px",
      textAlign: "center",
      transition: "all 0.5s ease-in-out",
      cursor: "pointer",
      "&:hover": {
        filter: "drop-shadow(0px 16px 10px rgba(0,0,0,0.05))",
        backgroundColor: "#fafcfe",
        borderColor: "transparent",
      },
      "& .upload-icon": {
        marginBottom: "16px",
      },
      "& .secondary-btn": {
        color: "#4BA3B7",
        borderRadius: "6px",
        borderColor: "#4BA3B7",
        minWidth: "130px",
        minHeight: "36px",
        padding: "8px 4px",
        "&:hover": {
          color: "#4BA3B7",
        },
      },
      "& .file-subtxt": {
        fontFamily: "Roboto",
        marginBottom: "16px",
        color: "#2B2B2B",
        letterSpacing: "0.09px",
        lineHeight: "16px",
        fontWeight: 400,
        fontSize: "12px",
        "& .browse-link": {
          fontWeight: 700,
          margin: "0 5px",
          cursor: "pointer",
          color: "#4ba3b7",
        },
      },
      "& .file-heading": {
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        margin: "0 0 7px",
        color: "rgba(0,0,0,0.87)",
        letterSpacing: "0.15px",
        lineHeight: "21px",
        fontSize: "16px",
      },
    },
    // fileDropDrag: {
    //   borderColor: "transparent",
    //   filter: "drop-shadow(0px 16px 10px rgba(0,0,0,0.05))",
    //   backgroundColor: "#fafcfe",
    // },
    secondaryButton: {
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
      textDecoration: "none",
      border: "1px solid #e8ecf2",
      textTransform: "unset",
      borderRadius: "7px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontFamily: "Expletus Sans",
      textAlign: "center",
      fontWeight: 500,
      maxWidth: "100%",
      minWidth: "198px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      background: "transparent",
    },
    primaryButton: {
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
      textTransform: "capitalize",
      borderRadius: "6px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#f2f8fc",
      fontWeight: 500,
      minHeight: "48px",
      fontFamily: "Expletus Sans",
      textAlign: "center",
      maxWidth: "100%",
      minWidth: "170px",
      padding: "12px 10px",
      marginBottom: "0",
      border: "1px solid #4ba3b7",
      transition: "all 0.5s ease-in-out",
      background: "#4ba3b7",
    },
    TopTaskSelected: {
      background:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2B2B2B",
      display: "none",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      padding: "30px 32px",
      position: "fixed",
      top: "0",
      left: "0",
      right: "0",
      [theme.breakpoints.down("xs")]: {
        padding: "24px",
      },
      "&.show-topbar": {
        display: "flex",
      },
      "& .taskselect-left": {
        display: "flex",
        alignItems: "center",
        "& .auth-back": {
          color: "#fff",
          marginRight: "24px",
        },
      },
      "& .tasklist-count": {
        marginLeft: "0",
        "& .checklist-check": {
          padding: "0",
          width: "20px",
          height: "20px",
          marginRight: "8px",
        },
        "& .MuiFormControlLabel-label": {
          color: "#FFF",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "19px",
          letterSpacing: "0",
        },
      },
      "& .heading-links": {
        display: "flex",
        alignItems: "center",
        "& .heading-link": {
          marginRight: "24px",
          "& .icon": {
            display: "inline-block",
          },
          "&:last-child": {
            marginRight: "0",
          },
        },
        "& .datepicker-wrapper": {
          "& .rmdp-error-msg": {
            display: "none",
          },
        },
      },
      "& .tasklist-close": {
        display: "flex",
        alignItems: "center",
        color: "#FFF",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "19px",
        fontFamily: "Expletus Sans",
        textDecoration: "none",
        "& .MuiSvgIcon-root": {
          fontSize: "20px",
          marginRight: "8px",
        },
      },
      "& .hide-sm": {
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
      "& .show-sm": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          display: "block",
        },
      },
    },
    dropDropdown: {
      "&.changepriority-dropdown": {
        "& .MuiMenu-paper": {
          marginTop: "0",
        },
      },
      "& .MuiMenu-paper": {
        minWidth: "164px",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        marginTop: "12px",
        "& .MuiMenuItem-root": {
          fontSize: "14px",
          lineHeight: "20px",
          letterSpacing: "0",
          color: "#2b2b2b",
          padding: "16px 24px",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          display: "flex",
          justifyContent: "flex-start",
          // borderBottom: "1px solid #ECF1F4",
          "& .priority-name": {
            marginRight: "10px",
          },
          "& .priority-icon": {
            marginLeft: "auto",
          },
          "&.hide-sm": {
            [theme.breakpoints.down("xs")]: {
              display: "none",
            },
          },
          "&.show-sm": {
            display: "none",
            [theme.breakpoints.down("xs")]: {
              display: "block",
            },
          },
        },
        "& .MuiList-padding": {
          paddingTop: "8px",
          paddingBottom: "8px",
        },
      },
    },
    dropdownStyle: {
      marginTop: "21px",
      maxWidth: "190px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "26px",
      },
      "&.modal-dropdown": {
        marginTop: "0",
      },
      "& .status-item": {
        display: "flex",
        "& .status-name": {
          marginRight: "10px",
          textTransform: "capitalize",
        },
        "& .status": {
          width: "12px",
          height: "12px",
          background: "#94A3B8",
          borderRadius: "100%",
          marginLeft: "auto",
          "&.open": {
            background: "#4BA3B7",
          },
          "&.cancelled": {
            background: "#F7E223",
          },
          "&.sky-blue": {
            background: "#12AEF1",
          },
          "&.orange": {
            background: "#F6A318",
          },
          "&.yellow": {
            background: "#F7E223",
          },
          "&.green": {
            background: "#00BA88",
          },
          "&.black": {
            background: "#2B2B2B",
          },
        },
      },
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        "&.assignee-multiSelect": {
          maxWidth: "400px",
        },
        "&.project-lead-multiSelect": {
          maxWidth: "360px",
        },
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "24px",
            paddingRight: "24px",
            position: "static",
            "&.multiselect-subheader": {
              marginBottom: "5px",
              borderBottom: "1px solid #ECF1F4",
              alignItems: "center",
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              lineHeight: "19px",
              justifyContent: "space-between",
              paddingTop: "15px",
              paddingBottom: "15px",
              display: "flex",
              "& .tasklist-link": {
                fontWeight: "500",
                textDecoration: "none",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                color: "#C7263E",
                lineHeight: "19px",
                whiteSpace: "nowrap",
                marginLeft: "10px",
              },
            },
            "& .form-control": {
              marginBottom: "20px",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
        "& .MuiMenuItem-root": {
          padding: "14px 24px",
          color: "#2B2B2B",
          "& .priority-name": {
            margin: "0",
            "& .priority-status": {
              marginLeft: "auto",
            },
            "& .MuiTypography-root": {
              display: "flex",
            },
          },
          "& .multiselect-squareCheck": {
            flex: "0 0 20px",
            marginLeft: "5px",
            maxWidth: "20px",
            "& .selected-img": {
              display: "none",
            },
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "& .assignee-detail": {
              "& .assignee-right": {
                "& .assinee-selected": {
                  "& .selected-img": {
                    display: "block",
                  },
                  "& .normal-img": {
                    display: "none",
                  },
                },
              },
            },
            "& .multiselect-check": {
              display: "block",
            },
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-squareCheck": {
              "& .selected-img": {
                display: "block",
              },
              "& .normal-img": {
                display: "none",
              },
            },
          },
          "& .multiselect-check": {
            maxWidth: "20px",
            flex: "0 0 20px",
            marginLeft: "5px",
            display: "none",
          },
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        whiteSpace: "normal",
        "& .select-icon": {
          marginRight: "6px",
        },
        "& .MuiTypography-root": {
          font: "inherit",
          letterSpacing: "0",
        },
      },
      "& .MuiList-padding": {
        paddingBottom: "8px",
        paddingTop: "4px",
      },
      "& .assignee-detail": {
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        display: "flex",
        "& .assignee-right": {
          marginLeft: "10px",
          "& .assinee-unselected": {
            height: "20px",
            width: "20px",
            "& .normal-img": {
              display: "block",
            },
            "& .selected-img": {
              display: "none",
            },
          },
          "& .assinee-selected": {
            height: "20px",
            width: "20px",
            "& .normal-img": {
              display: "none",
            },
            "& .selected-img": {
              display: "block",
            },
          },
        },
        "& .assignee-left": {
          alignItems: "center",
          display: "flex",
          "& .assignee-info": {
            marginLeft: "10px",
          },
          "& .assignee-profile": {
            letterSpacing: "-0.395px",
            lineHeight: "21.679px",
            display: "flex",
            justifyContent: "center",
            background: "#ECF1F4",
            borderRadius: "100%",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Roboto",
            height: "39px",
            alignItems: "center",
            fontSize: "15.485px",
            fontWeight: 600,
            minWidth: "39px",
            textTransform: "capitalize",
          },
          "& .assignee-name": {
            fontWeight: 500,
            color: "#2b2b2b",
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            textTransform: "capitalize",
          },
          "& .assignee-email": {
            fontWeight: 500,
            color: "#94A3B8",
            lineHeight: "16px",
            fontFamily: "Expletus Sans",
            fontSize: "12px",
          },
        },
      },
    },
    teamAutocompleteDropbox: {
      padding: "0",
      "& .tasklist-li": {
        width: "100%",
        justifyContent: "space-between",
        borderBottom: "0",
        background: "transparent",
        position: "relative",
        display: "flex",
        padding: "16px 24px",
        "& .tasklist-icon": {
          minWidth: "unset",
          "& .checklist-check": {
            padding: "0",
          },
        },
        "& .tasklist-left": {
          fontWeight: 500,
          lineHeight: "19px",
          fontFamily: "Expletus Sans",
          color: "#2B2B2B",
          fontSize: "14px",
        },
      },
      "& .MuiAutocomplete-paper": {
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
        filter: "none",
        background: "#ffffff",
        borderRadius: "12px",
      },
      "& .MuiAutocomplete-listbox": {
        padding: "0",
        "& .MuiAutocomplete-option": {
          background: "transparent",
          padding: "0",
        },
      },
    },
    tasklistInnerRoot: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      // "& .tasklist-content": {
      "& .progress-wrap": {
        width: "100%",
        height: "4px",
        background: "#ECF1F4",
        borderRadius: "6px",
        "& .progress-inner": {
          background: "#94A3B8",
          height: "inherit",
          borderRadius: "inherit",
          maxWidth: "100%",
          "&.inprogress": {
            background: "#4BA3B7",
          },
          "&.completed": {
            background: "#00BA88",
          },
          "&.red": {
            background: "#C7263E",
          },
        },
        "& .current-progress": {
          background: "#4BA3B7",
          height: "inherit",
          borderRadius: "16px",
          maxWidth: "100%",
          borderLeft: "4px solid #fff",
          marginLeft: "auto",
        },
      },
      "& .tasklist-top": {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        alignItems: "center",
        padding: "11px 32px",
        borderBottom: "1px solid #E8ECF2",
        "& .top-left": {
          "& .search-wrapper": {
            [theme.breakpoints.down("xs")]: {
              width: "100%",
            },
            "& .MuiSvgIcon-root": {
              color: "rgba(43, 43, 43, 0.67)",
              fontSize: "20px",
            },
            "& .MuiInputAdornment-positionStart": {
              marginRight: "12px",
            },
            "& .MuiInput-underline": {
              "&:before, &:after": {
                display: "none",
              },
            },
            "& .MuiInput-root": {
              maxWidth: "100%",
              width: "413px",
              [theme.breakpoints.down("xs")]: {
                width: "100%",
              },
              [theme.breakpoints.down("md")]: {
                width: "308px",
              },
              [theme.breakpoints.down("sm")]: {
                width: "234px",
              },
            },
            "& .MuiInputBase-input": {
              fontFamily: "Expletus Sans",
              color: "#000000",
              lineHeight: "20px",
              letterSpacing: "0.1px",
              fontSize: "14px",
              fontWeight: "500",
              "&::placeholder": {
                opacity: "0.369",
                color: "rgba(43, 43, 43, 0.87)",
              },
              "&::-webkit-input-placeholder": {
                opacity: "0.369",
                color: "rgba(43, 43, 43, 0.87)",
              },
              "&:-ms-input-placeholder": {
                opacity: "0.369",
                color: "rgba(43, 43, 43, 0.87)",
              },
            },
          },
        },
        "& .top-right": {
          marginLeft: "15px",
          "& .filter-list": {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            "& .rmdp-clear": {
              color: "rgba(43, 43, 43, 0.67)",
              fontSize: "20px",
            },
            "& .select-control": {
              marginRight: "24px",
              paddingRight: "0",
              "&:last-child": {
                marginRight: "0",
              },
              "&.border-hr": {
                marginRight: "24px",
                paddingRight: "24px",
                borderRight: "1px solid #E8ECF2",
              },
            },
            "& .filter-date-outer": {
              display: "flex",
              marginRight: "24px",
              "& .multiple-clear": {
                color: "rgba(43, 43, 43, 0.67)",
                fontSize: "20px",
                cursor: "pointer",
              },
            },
            "& .datepicker-wrapper": {
              marginRight: "0",
              "& .rmdp-container": {
                // width: "90px",
                "& .rmdp-input": {
                  border: "0",
                  padding: "0 0 0 24px",
                  minHeight: "unset",
                  backgroundPosition: "left center",
                  backgroundImage: `url(${calendarBlack})`,
                  opacity: "0.67",
                  "&::-webkit-input-placeholder": {
                    color: "#2B2B2B",
                  },
                  "&:-ms-input-placeholder": {
                    color: "#2B2B2B",
                  },
                  "&::placeholder": {
                    color: "#2B2B2B",
                  },
                },
              },
            },
          },
        },
        "& .select-control": {
          "&.multi-select": {
            flexDirection: "row-reverse",
            display: "flex",
            "&.assignee-select, &.project-lead-select": {
              "& .multiple-clear": {
                top: "4px",
              },
            },
            "& .MuiSelect-select": {
              alignItems: "center",
              display: "flex",
              paddingRight: "0",
              "& .select-icon": {
                marginRight: "6px",
              },
              "& .assignee-list": {
                marginLeft: "6px",
                alignItems: "center",
                display: "flex",
                "& .assignee-bubble": {
                  background: "#ECF1F4",
                  letterSpacing: "-0.244px",
                  border: "2px solid #FFF",
                  height: "24px",
                  display: "flex",
                  position: "relative",
                  fontSize: "9.586px",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Roboto",
                  fontWeight: 600,
                  lineHeight: "13.42px",
                  marginLeft: "-4px",
                  borderRadius: "100%",
                  color: "rgba(43, 43, 43, 0.87)",
                  width: "24px",
                  "&:first-child": {
                    marginLeft: 0,
                  },
                },
              },
            },
            "& .multiselect-label": {
              display: "none",
            },
            "& .MuiInput-formControl": {
              marginTop: "0",
            },

            "& .multiple-clear": {
              position: "relative",
              fontSize: "15px",
              right: "0",
              top: "2px",
              zIndex: 1,
              color: "#94A3B8",
              marginLeft: "5px",
              cursor: "pointer",
            },
          },
          "&.sortby-control": {
            "& .select-icon": {
              top: "3px",
            },
            "& .MuiSelect-select": {
              paddingRight: "0",
            },
          },
          "& .select-icon": {
            position: "relative",
            marginRight: "6px",
            lineHeight: "normal",
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0",
            color: "#2B2B2B",
            opacity: "0.671",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            paddingBottom: "0",
            paddingTop: "0",
            "&:focus": {
              backgroundColor: "transparent",
            },
            "& .clear-icon": {
              width: "50px",
              height: "50px",
              background: "red",
            },
          },
          "& .MuiInput-underline:before": {
            display: "none",
          },
          "& .MuiInput-underline:after": {
            display: "none",
          },
        },
      },
      "& .project-detail-top": {
        padding: "12px 32px",
        borderBottom: "1px solid #E8ECF2",
        "& .project-row": {
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          marginBottom: "16px",
          "&:last-child": {
            marginBottom: "0",
          },
        },
        "& .project-right": {
          marginLeft: "10px",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        },
        "& .task-title": {
          // display: "flex",
          // justifyContent: "space-between",
          "& .title": {
            color: "#2B2B2B",
            fontSize: "16px",
            lineHeight: "24px",
            fontFamily: "Expletus Sans",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
            "& .select-control": {
              marginLeft: "6px",
            },
            "& .edit-link": {
              marginLeft: "6px",
            },
            "& .priority-status": {
              color: "#C7263E",
              marginRight: "3px",
            },
          },
          "& .title-right": {
            marginLeft: "10px",
          },
        },
        "& .task-desc": {
          marginTop: "4px",
          display: "flex",
          justifyContent: "space-between",
          color: "rgba(43, 43, 43, 0.67)",
          fontSize: "12px",
          lineHeight: "17px",
          fontFamily: "Roboto",
          fontWeight: 400,
          "& .desc-left": {
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          },
          "& .task-about": {
            paddingLeft: "14px",
            marginLeft: "8px",
            position: "relative",
            "&:before": {
              content: '""',
              width: "6px",
              height: "6px",
              borderRadius: "100%",
              background: "#E0E0E0",
              display: "inline-block",
              position: "absolute",
              left: "0",
              top: "5px",
            },
          },
        },
        "& .lead-label": {
          color: "#2B2B2B",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "17px",
          opacity: "0.67",
          marginBottom: "15px",
        },
        "& .lead-name": {
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "18px",
        },
        "& .project-total-list": {
          display: "flex",
          padding: "0",
          "& .MuiListItem-root": {
            display: "flex",
            flexDirection: "column",
            padding: "0",
            marginRight: "48px",
            textAlign: "right",
            width: "auto",
            alignItems: "flex-end",
            "&:last-child": {
              marginRight: "0",
            },
            "& .label": {
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "17px",
              opacity: "0.67",
              marginBottom: "12px",
            },
            "& .details": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "18px",
              display: "flex",
              alignItems: "center",
              textWrap: "nowrap",
              justifyContent: "flex-end",
              "& span": {
                fontSize: "10px",
                marginLeft: "5px",
              },
            },
          },
        },
        "& .select-control": {
          "&.status-control": {
            "& .MuiSelect-icon": {
              width: "14px",
              height: "14px",
              right: "6px",
              color: "#94A3B8",
              top: "calc(50% - 6px)",
            },
            "& .MuiSelect-select": {
              padding: "4px 22px 4px 6px",
              background: "#F2F8FC",
              borderRadius: "9px",
              color: "#2B2B2B",
              fontSize: "10px",
              fontFamily: "Roboto",
              fontWeight: 400,
              lineHeight: "12px",
              letterSpacing: "0",
              display: "flex",
              alignItems: "center",
            },
          },
          "& .select-icon": {
            position: "relative",
            marginRight: "6px",
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0.1px",
            color: "rgba(43, 43, 43, 0.87)",
            opacity: "0.671",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            paddingBottom: "0",
            paddingTop: "0",
            "&:focus": {
              backgroundColor: "transparent",
            },
            "& .clear-icon": {
              width: "50px",
              height: "50px",
              background: "red",
            },
            "& .status-name": {
              opacity: "0.67",
            },
            "& .status": {
              width: "8px",
              height: "8px",
              background: "#94A3B8",
              borderRadius: "100%",
              marginLeft: "6px",
              "&.blue": {
                background: "#12AEF1",
              },
              "&.yellow": {
                background: "#F7E223",
              },
              "&.green": {
                background: "#00BA88",
              },
              "&.red": {
                background: "#C7263E",
              },
            },
          },
          "& .MuiInput-underline:before": {
            display: "none",
          },
          "& .MuiInput-underline:after": {
            display: "none",
          },
        },
        "& .task-progress-block": {
          "& .progress-info": {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginBottom: "6px",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Roboto",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "17px",
            textTransform: "uppercase",
            letterSpacing: "0.125px",
            // "& .total-value": {
            //   color: "#94A3B8",
            // },
          },
          "& .progress-wrap": {
            width: "215px",
            maxWidth: "100%",
          },
        },
        "& .border-hr": {
          marginRight: "16px",
          paddingRight: "16px",
          borderRight: "1px solid #E8ECF2",
        },
        "& .heading-links": {
          display: "flex",
          alignItems: "center",
          "& .heading-link": {
            marginRight: "16px",
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
        "& .action-button": {
          "& .MuiButton-root": {
            padding: "8px 10px",
            minWidth: "115px",
            minHeight: "36px",
          },
        },
      },
      "& .tasklist-top-details": {
        display: "flex",
        padding: "11px 32px",
        flexWrap: "wrap",
        alignItems: "center",
        borderBottom: "1px solid #E8ECF2",
        justifyContent: "space-between",
        "& .top-right": {
          marginLeft: "15px",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        },
        "& .task-title": {
          "& .title": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            marginBottom: "4px",
            display: "flex",
            alignItems: "center",
            "& .edit-link": {
              marginLeft: "10px",
              "& .MuiSvgIcon-root": {
                fontSize: "16px",
                color: "#94A3B8",
              },
            },
          },
        },
        "& .task-desc": {
          color: "rgba(43, 43, 43, 0.67)",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "17px",
          "& .desc-left": {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            marginRight: "10px",
          },
          "& .task-about": {
            position: "relative",
            marginLeft: "8px",
            paddingLeft: "14px",
            "&:before": {
              top: "5px",
              left: "0",
              width: "6px",
              height: "6px",
              content: '""',
              display: "inline-block",
              position: "absolute",
              background: "#E8ECF2",
              borderRadius: "100%",
            },
          },
        },
        "& .task-progress-wrapper": {
          color: "rgba(43, 43, 43, 0.87)",
          fontFamily: "Roboto",
          fontSize: "10px",
          fontWeight: "400",
          lineHeight: "17px",
          letterSpacing: "0.125px",
          "& .prec-wrap": {
            marginBottom: "6px",
          },
          "& .progress-wrap": {
            width: "215px",
            maxWidth: "100%",
          },
        },
        "& .top-right-links": {
          marginLeft: "16px",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          "& .top-links": {
            marginRight: "16px",
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
      },
      "& .tasklist-inner": {
        padding: "32px",
        flex: "1",
        height: "100%",
        overflowY: "auto",
        [theme.breakpoints.down("xs")]: {
          padding: "24px",
        },
        "& .tasklist-row": {
          display: "flex",
          // flexWrap: "wrap",
          overflowY: "auto",
          margin: "0 -12px",
          height: "100%",
          [theme.breakpoints.down("xs")]: {
            flexWrap: "wrap",
          },
          "& .tasklist-col": {
            // flex: "0 0 33.33%",
            // maxWidth: "33.33%",
            width: "33.33%",
            minWidth: "487px",
            padding: "0 12px",
            [theme.breakpoints.down("xs")]: {
              width: "100%",
              minWidth: "100%",
              maxWidth: "100%",
            },
            "& .accordion-block": {
              borderRadius: "24px",
              border: "2px dashed #E8ECF2",
              boxShadow: "none",
              [theme.breakpoints.down("xs")]: {
                border: "0",
                marginBottom: "24px",
              },
              "& .accordion-heading": {
                padding: "24px 22px",
                minHeight: "67px",
                [theme.breakpoints.down("xs")]: {
                  padding: "0",
                  minHeight: "unset",
                  marginBottom: "24px",
                },
                "& .MuiAccordionSummary-content": {
                  margin: "0",
                  justifyContent: "space-between",
                },
                "& .MuiAccordionSummary-expandIcon": {
                  color: "#94A3B8",
                  padding: "0",
                  margin: "0",
                  display: "none",
                  [theme.breakpoints.down("xs")]: {
                    display: "block",
                    marginLeft: "16px",
                  },
                },
                "& .tasklist-status": {
                  color: "#2B2B2B",
                  fontSize: "14px",
                  lineHeight: "19px",
                  fontFamily: "Expletus Sans",
                  fontWeight: 500,
                  position: "relative",
                  "&.open": {
                    "&:before": {
                      background: "#12AEF1",
                    },
                  },
                  "&.progress": {
                    "&:before": {
                      background: "#00BA88",
                    },
                  },
                  "&.in-progress": {
                    "&:before": {
                      background: "#F6A318",
                    },
                  },
                  "&.completed1": {
                    "&:before": {
                      background: "#94A3B8",
                    },
                  },                   
                  "&.completed": {
                    "&:before": {
                      background: "rgba(43, 43, 43, 0.87)",
                    },
                  },
                  "&.cancelled": {
                    "&:before": {
                      background: "#F7E223",
                    },
                  },
                  "&.offtrack": {
                    "&:before": {
                      background: "#C7263E",
                    },
                  },
                  "&:before": {
                    content: '""',
                    width: "8px",
                    height: "8px",
                    borderRadius: "100%",
                    background: "#2B2B2B",
                    display: "inline-block",
                    marginRight: "8px",
                  },
                },
                "& .tasklist-link": {
                  color: "#4BA3B7",
                  fontSize: "14px",
                  lineHeight: "19px",
                  fontFamily: "Expletus Sans",
                  fontWeight: 500,
                  marginLeft: "10px",
                  textDecoration: "none",
                  whiteSpace: "nowrap",
                },
              },
              "& .accordion-details": {
                padding: "0 22px 24px",
                flexWrap: "wrap",
                [theme.breakpoints.down("xs")]: {
                  padding: "0",
                },
                "& .task-card": {
                  width: "100%",
                  borderRadius: "12px",
                  border: "1px solid #E8ECF2",
                  marginBottom: "24px",
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "transparent",
                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
                  },
                  "&:last-child": {
                    marginBottom: "0",
                  },
                  "&.task-selected": {
                    border: "1px solid #2B2B2B",
                  },
                  "& .task-subtitle": {
                    color: "rgba(43, 43, 43, 0.67)",
                    fontSize: "12px",
                    lineHeight: "17px",
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    marginBottom: "4px",
                  },
                  "& .task-title": {
                    // display: "flex",
                    // justifyContent: "space-between",
                    "& .title": {
                      color: "#2B2B2B",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontFamily: "Expletus Sans",
                      fontWeight: 400,
                      "& .priority-status": {
                        color: "#C7263E",
                        marginRight: "3px",
                      },
                    },
                    "& .check-complete": {
                      marginLeft: "10px",
                    },
                    "& .title-right": {
                      marginLeft: "10px",
                    },
                  },
                  "& .task-desc": {
                    marginTop: "4px",
                    display: "flex",
                    justifyContent: "space-between",
                    color: "rgba(43, 43, 43, 0.67)",
                    fontSize: "12px",
                    lineHeight: "17px",
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    "& .desc-left": {
                      marginRight: "10px",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    },
                    "& .task-about": {
                      paddingLeft: "14px",
                      marginLeft: "8px",
                      position: "relative",
                      "&:before": {
                        content: '""',
                        width: "6px",
                        height: "6px",
                        borderRadius: "100%",
                        background: "#E8ECF2",
                        display: "inline-block",
                        position: "absolute",
                        left: "0",
                        top: "5px",
                      },
                    },
                  },
                  "& .tasklist-bottom": {
                    display: "none",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    borderTop: "1px solid #E8ECF2",
                    paddingTop: "16px",
                    marginTop: "16px",
                    "&.task-active": {
                      display: "flex",
                    },
                    "& .left-col": {
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    },
                    "& .right-col": {
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      alignItems: "center",
                    },
                    "& .tasklist-info": {
                      marginRight: "16px",
                      "&:last-child": {
                        marginRight: "0",
                      },
                    },
                  },
                  "& .card-content": {
                    padding: "24px",
                    cursor: "pointer",
                    "&:hover": {
                      "& .tasklist-bottom": {
                        display: "flex",
                      },
                    },
                  },
                  "& .subtask-block": {
                    marginTop: "16px",
                    "& .subtask-info": {
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      alignItems: "center",
                      "& .subtask-left": {
                        marginRight: "10px",
                        display: "flex",
                        flexWrap: "wrap",
                      },
                      "& .subtask-btn": {
                        borderRadius: "12px",
                        border: "1px solid #E8ECF2",
                        textDecoration: "none",
                        padding: "6px 12px",
                        color: "#2B2B2B",
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.09px",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                      },
                      "& .subtask-count": {
                        borderRadius: "12px",
                        border: "1px solid #E8ECF2",
                        padding: "6px 12px",
                        marginLeft: "8px",
                        color: "#2B2B2B",
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.09px",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        cursor: "pointer",
                        "&.subtask-active": {
                          background:
                            "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2B2B2B",
                          color: "#FFFFFF",
                          borderColor: "#2B2B2B",
                        },
                        "& .subtasklist-icon": {
                          marginRight: "6px",
                        },
                      },
                    },
                    "& .subtask-list": {
                      borderTop: "1px solid #E8ECF2",
                      paddingTop: "16px",
                      marginTop: "16px",
                      "& .subtask-heading": {
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "16px",
                        "& .task-count": {
                          color: "#2B2B2B",
                          fontSize: "12px",
                          lineHeight: "16px",
                          letterSpacing: "0.09px",
                          fontFamily: "Roboto",
                          fontWeight: 400,
                        },
                        "& .tasklist-link": {
                          color: "#4BA3B7",
                          fontSize: "12px",
                          lineHeight: "16px",
                          letterSpacing: "0.09px",
                          fontFamily: "Roboto",
                          fontWeight: 400,
                          textDecoration: "none",
                        },
                      },
                      "& .subtask-details": {
                        "& .tasklist-ul": {
                          padding: "0",
                          borderLeft: "1px solid #E8ECF2",
                          "& .tasklist-li": {
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0",
                            marginBottom: "16px",
                            background: "transparent",
                            position: "relative",
                            "&:before": {
                              width: "17px",
                              height: "1px",
                              content: "''",
                              background: "#E8ECF2",
                            },
                            "& .tasklist-label": {
                              color: "#2B2B2B",
                              fontSize: "14px",
                              lineHeight: "19px",
                              fontFamily: "Expletus Sans",
                              fontWeight: 500,
                              padding: "8px 20px",
                              margin: "0",
                            },
                            "& .tasklist-icon": {
                              minWidth: "unset",
                            },
                          },
                        },
                      },
                    },
                  },
                  "& .assignee-list": {
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "6px",
                    "& .assignee-bubble": {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#FFFFFF",
                      width: "24px",
                      height: "24px",
                      borderRadius: "100%",
                      border: "2px solid #ECF1F4",
                      color: "rgba(43, 43, 43, 0.87)",
                      fontSize: "9.586px",
                      fontFamily: "Roboto",
                      fontWeight: 600,
                      lineHeight: "13.42px",
                      letterSpacing: "-0.244px",
                      position: "relative",
                      marginLeft: "-4px",
                      "&:first-child": {
                        marginLeft: "0",
                      },
                    },
                  },
                  "& .progress-wrap": {
                    width: "100%",
                    height: "4px",
                    background: "#ECF1F4",
                    borderRadius: "6px",
                    "& .progress-inner": {
                      background: "#94A3B8",
                      height: "inherit",
                      borderRadius: "inherit",
                      maxWidth: "100%",
                      "&.inprogress": {
                        background: "#4BA3B7",
                      },
                      "&.completed": {
                        background: "#00BA88",
                      },
                      "&.red": {
                        background: "#C7263E",
                      },
                    },
                  },
                  "& .task-progress-wrapper": {
                    display: "flex",
                    alignItems: "center",
                    color: "#2B2B2B",
                    fontFamily: "Roboto",
                    fontSize: "10px",
                    fontWeight: "400",
                    lineHeight: "17px",
                    letterSpacing: "0.125px",
                    marginTop: "16px",
                    "& .progress-wrap": {
                      margin: "0 8px",
                      flex: "1",
                    },
                  },
                },
              },
            },
          },
        },
      },
      // },
      "& .checklist-check": {
        padding: "0",
        width: "20px",
        "& .MuiTouchRipple-root": {
          display: "none",
        },
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&.Mui-checked": {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    modalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      padding: "0",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("md")]: {
        width: 520,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      "&.view-modal-dialog": {
        width: 640,
        [theme.breakpoints.down("md")]: {
          width: 520,
        },
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        "& .modal-heading": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #E8ECF2",
          padding: "22px 40px",
          marginBottom: "0",
          [theme.breakpoints.down("xs")]: {
            padding: "24px",
          },
          "& .heading-links": {
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("xs")]: {
              justifyContent: "space-between",
              width: "100%",
            },
            "& .heading-link": {
              marginRight: "24px",
              "&:last-child": {
                marginRight: "0",
              },
              "& .auth-back": {
                color: "#2b2b2b",
                display: "inline-block",
                "& i": {
                  display: "inline-block",
                },
              },
            },
          },
          "& .close-icon": {
            color: "#94A3B8",
            lineHeight: "0",
          },
        },
        "& .main-content-txt":{
          display:"flex",
          margin:"21px 20px -16px 32px",
         "& .content-sub-txt":{
           fontSize: "12px",
           lineHeight: "normal",
           color: "#797878",
           fontFamily: "Roboto",
           fontWeight: 400,
           letterSpacing: "0.4px",
           marginTop: "4px",
           marginBottom:"24px",
           textTransform: "capitalize",         
           "&.content-dots": {
             color: "#797878",
             marginLeft:"12px",
             "&:before": {
               content: '""',
               width: "8px",
               height: "8px",
               borderRadius: "100%",
               background: "#ECF1F4",
               display: "inline-block",
               marginRight: "8px",
             },
           },            
         },
       },
        "& .modal-title": {
          fontSize: "22px",
          lineHeight: "26px",
          color: "#2B2B2B",
          letterSpacing: "0",
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          marginBottom: "15px",
          "& .priority-status": {
            color: "#C7263E",
            marginRight: "5px",
          },
        },
        "& .modal-description": {
          padding: "24px 40px",
          paddingTop:"0px",
          [theme.breakpoints.down("md")]: {
            padding: "24px 32px",
            paddingTop:"0px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "16px 24px",
            paddingTop:"0px",
          },
        },
      },
      "&.all-assignee-dialogbox": {
        "& .modal-dialoginner": {
          padding: "84px 60px",
          [theme.breakpoints.down("md")]: {
            padding: "80px 32px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "24px",
          },
        },
        "& .modal-heading": {
          marginBottom: "36px",
          "& .filter-heading": {
            "& .auth-back": {
              marginRight: "7px",
            },
            "& .modal-title": {
              fontSize: "14px",
              lineHeight: "19px",
            },
          },
        },
        "& .modal-description": {
          "& .heading-block": {
            marginBottom: "25px",
            "& .primary-txt": {
              fontSize: "22px",
              lineHeight: "26px",
              marginBottom: "12px",
            },
          },
          "& .tasklist-member": {
            "& .tasklist-li": {
              display: "flex",
              padding: "8px 24px 5px",
              [theme.breakpoints.down("xs")]: {
                margin: "0 -24px",
                width: "auto",
              },
              "& .tasklist-left": {
                display: "flex",
                alignItems: "flex-start",
                "& .chip-wrapper": {
                  marginTop: "3px",
                  "& .MuiChip-root": {
                    marginBottom: "2px",
                    height: "24px",
                  },
                },
              },
            },
          },
        },
      },
      "&.timesheet-assignee-dialogbox": {
        "& .modal-description": {
          "& .heading-block": {
            marginBottom: "24px",
          },
        },
      },
      "&.filter-dialogbox": {
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
          width: "100%",
        },
        "& .modal-heading": {
          marginBottom: "44px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "26px",
          },
          "& .filter-heading": {
            display: "flex",
            alignItems: "center",
            "& .auth-back": {
              cursor: "pointer",
              marginRight: "8px",
            },
            "& .modal-title ": {
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "24px",
              fontFamily: "Expletus Sans",
            },
          },
          "& .filter-link": {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "19px",
            color: "#4BA3B7",
            fontFamily: "Expletus Sans",
            textDecoration: "none",
          },
        },
      },
      "&.project-created-dialog": {
        "& .modal-heading": {
          marginBottom: "134px",
          [theme.breakpoints.down("md")]: {
            marginBottom: "100px",
          },
          [theme.breakpoints.down("xs")]: {
            marginBottom: "42px",
          },
          "& .close-icon": {
            marginLeft: "auto",
          },
        },
        "& .modal-footer": {
          marginTop: "auto",
          "& .MuiButton-root": {
            "&:last-child": {
              marginBottom: "0",
            },
          },
          "& .secondary-btn": {
            [theme.breakpoints.down("xs")]: {
              borderColor: "#4BA3B7",
              color: "#4BA3B7",
            },
          },
        },
      },
      "&.project-template-dialog": {
        "& .modal-description": {
          marginBottom: "68px",
        },
      },
      "&.create-tasklist-dialog": {
        "& .modal-heading": {
          "& .modal-title": {
            fontSize: "24px",
            lineHeight: "28px",
            letterSpacing: "unset",
            [theme.breakpoints.down("md")]: {
              fontSize: "22px",
              lineHeight: "26px",
            },
          },
        },
        "& .primary-btn": {
          fontSize: "16px",
          lineHeight: "21px",
        },
      },
      "&.create-timesheet-dialog": {
        "& .modal-heading": {
          "& .modal-title": {
            fontSize: "24px",
            lineHeight: "28px",
            letterSpacing: "unset",
            [theme.breakpoints.down("md")]: {
              fontSize: "22px",
              lineHeight: "26px",
            },
          },
        },
        "& .primary-btn": {
          fontSize: "16px",
          lineHeight: "21px",
        },
      },
      "&.project-dialogbox": {
        "& .modal-heading": {
          "& .modal-title": {
            fontSize: "24px",
            lineHeight: "28px",
            letterSpacing: "unset",
            [theme.breakpoints.down("md")]: {
              fontSize: "22px",
              lineHeight: "26px",
            },
          },
        },
      },
      "&.move-tasklist-dialog": {
        "& .modal-form": {
          marginTop: "56px",
          [theme.breakpoints.down("md")]: {
            marginTop: "32px",
          },
        },
        "& .form-info-wrapper": {
          margin: "0 0 24px",
        },
        "& .modal-footer": {
          "& .MuiButton-root": {
            marginBottom: "0",
          },
        },
      },
      "& .hide-sm": {
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
      "& .show-sm": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          display: "block",
        },
      },
      "& .progress-wrap": {
        borderRadius: "6px",
        background: "#ECF1F4",
        height: "4px",
        width: "100%",
        "& .current-progress": {
          marginLeft: "auto",
          borderRadius: "16px",
          maxWidth: "100%",
          height: "inherit",
          borderLeft: "4px solid #fff",
          background: "#4BA3B7",
        },
        "& .progress-inner": {
          maxWidth: "100%",
          borderRadius: "inherit",
          height: "inherit",
          background: "#94A3B8",
          "&.red": {
            background: "#C7263E",
          },
          "&.completed": {
            background: "#00BA88",
          },
          "&.inprogress": {
            background: "#4BA3B7",
          },
        },
      },
      "& .team-multiselect": {
        "& .MuiAutocomplete-inputRoot": {
          paddingLeft: "16px",
        },
        "& .MuiAutocomplete-input": {
          "&:first-child": {
            padding: "0",
          },
        },
        "& .MuiFormLabel-root": {
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.105px",
        },
        "& .MuiAutocomplete-endAdornment": {
          "& .MuiIconButton-root": {
            fontSize: "20px",
            color: "#94A3B8",
            right: "15px",
            "& .MuiSvgIcon-root": {
              fontSize: "20px",
            },
          },
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#E8ECF2",
            },
          },
        },
        "& .MuiChip-root": {
          backgroundColor: "#ECF1F4",
          borderRadius: "6px",
          padding: "6px 8px",
          height: "auto",
          color: "#4F4F4F",
          fontFamily: "Roboto",
          fontSize: "13px",
          fontWeight: 400,
          lineHeight: "17px",
          margin: "0 5px 5px 0",
          "& .MuiChip-label": {
            paddingLeft: "0",
          },
          "& .MuiChip-deleteIcon": {
            width: "17px",
            height: "17px",
            margin: "0",
          },
        },
      },
      "& .modal-dialoginner": {
        padding: "15px 60px",
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        [theme.breakpoints.down("md")]: {
          padding: "15px 32px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "24px",
        },
        "& .MuiCheckbox-root": {
          color: "#94A3B8",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
        "& .MuiRadio-root": {
          color: "#94A3B8",
          padding: "0",
          "&:hover": {
            backgroundColor: "rgba(54, 145, 166, 0.04)",
          },
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
        },
      },
      "& .modal-heading": {
        marginBottom: "11px",
        display: "flex",
        justifyContent: "space-between",
        paddingTop:"30px",
        "& .modal-title ": {
          fontWeight: 400,
          fontSize: "22px",
          lineHeight: "26px",
          fontFamily: "Expletus Sans",
          color: "#2b2b2b",
        },
        "& .heading-left": {
          alignItems: "center",
          display: "flex",
          "& .auth-back": {
            opacity: "0.67",
            marginRight: "8px",
            color: "#2B2B2B",
            cursor: "pointer",
          },
          "& .modal-title ": {
            color: "#2b2b2b",
            fontSize: "14px",
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
          },
        },
        "& .close-icon": {
          lineHeight: "0",
          color: "#94A3B8",
        },
      },
      "& .modal-description": {
        "& .select-outer": {
          width: "100%",
          marginBottom: "24px",
          "& .MuiInputLabel-formControl": {
            fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "19px",
            letterSpacing: "0.1px",
            color: "#94A3B8",
            display: "flex",
            width: "calc(100% - 46px)",
            "&.MuiFormLabel-filled": {
              width: "auto",
              color: "rgba(43,43,43,0.87)",
              fontSize: "12px",
              fontFamily: "Roboto",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0.0075em",
            },
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0",
            color: "#000000",
            opacity: "1",
            fontWeight: "400",
            fontFamily: "Roboto",
            display: "flex",
            alignItems: "center",
            paddingRight: "45px",
            "&:focus": {
              backgroundColor: "transparent",
            },
            "& .status-name": {
              color: "#2B2B2B",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "26px",
              letterSpacing: "0.105px",
            },
            "& .status": {
              width: "12px",
              height: "12px",
              background: "#94A3B8",
              borderRadius: "100%",
              marginLeft: "10px",
              "&.open": {
                background: "#4BA3B7",
              },
              "&.cancelled": {
                background: "#F7E223",
              },
            },
          },
          "& .MuiSvgIcon-root": {
            fontSize: "20px",
            color: "#94A3B8",
            right: "24px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            },
          },
          "& .MuiFormLabel-root": {
            "&.Mui-focused": {
              width: "auto",
            },
            "& .MuiInputLabel-asterisk": {
              color: "#e86577",
              fontSize: "18px",
              lineHeight: "23px",
              marginLeft: "auto",
            },
          },
        },
        "& .datepicker-wrapper": {
          "& .rmdp-container": {
            "& .rmdp-input": {
              paddingLeft: "16px",
              letterSpacing: "0.105px",
              "&::-webkit-input-placeholder": {
                color: "#94A3B8",
              },
              "&:-ms-input-placeholder": {
                color: "#94A3B8",
              },
              "&::placeholder": {
                color: "#94A3B8",
              },
            },
          },
        },
        "& .task-subtitle": {
          color: "rgba(43, 43, 43, 0.67)",
          fontFamily: "Roboto",
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: 400,
          letterSpacing: "0.09px",
          marginBottom: "8px",
        },
        "& .task-progress-block": {
          marginBottom: "15px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "16px",
          },
          "& .progress-info": {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginBottom: "14px",
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: "14px",
            textTransform: "uppercase",
            "& .total-value": {
              color: "#94A3B8",
              marginTop:"15px",
            },
          },
        },
        "& .taskview-list": {
          marginBottom: "8px",
          padding: "0",
          "& .MuiListItem-root": {
            padding: "16px 0",
            borderBottom: "1px solid #E8ECF2",
            display: "flex",
            alignItems: "center",
            "&:last-child": {
              borderBottom: "0",
            },
            "&.date-item-root": {
              alignItems: "flex-start",
              [theme.breakpoints.down("xs")]: {
                justifyContent: "space-between",
              },
              "& .list-label": {
                [theme.breakpoints.down("xs")]: {
                  width: "auto",
                },
              },
              "& .list-description": {
                [theme.breakpoints.down("xs")]: {
                  width: "auto",
                  paddingRight: "7px",
                },
              },
            },
            "& .list-description": {
              letterSpacing: "0.105px",
              color: "#2B2B2B",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "26px",
              paddingLeft: "15px",
              fontFamily: "Roboto",
              width: "67%",
              [theme.breakpoints.down("xs")]: {
                width: "43.4%",
              },
              "& .brand-names":{ 
                display:"flex",
                "& .brand-list":{
                  marginRight:"10px"
                },
                "& .more-btn":{
                 position:"absolute",
                 right:"70px",
                 top:"30px"
                },
              },
              "& .list-subtitle": {
                letterSpacing: "normal",
                color: "#94A3B8",
                lineHeight: "14px",
                fontSize: "10px",
              },
              "& .assignee-list": {
                alignItems: "center",
                display: "flex",
                "& .assignee-bubble": {
                  marginLeft: "-4px",
                  color: "rgba(43, 43, 43, 0.87)",
                  letterSpacing: "-0.244px",
                  justifyContent: "center",
                  background: "#ECF1F4",
                  width: "24px",
                  height: "24px",
                  borderRadius: "100%",
                  border: "2px solid #FFF",
                  fontSize: "9.586px",
                  fontFamily: "Roboto",
                  fontWeight: 600,
                  lineHeight: "13.42px",
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  "&:first-child": {
                    marginLeft: "0",
                  },
                  "&.more-bubble": {
                    cursor: "pointer",
                  },
                },
              },
              "& .date-info": {
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
                "&:last-child": {
                  marginBottom: "0",
                },
                "& .label": {
                  color: "#94A3B8",
                  // minWidth: "67px",
                  textAlign: "right",
                },
                "& .value": {
                  marginLeft: "8px",
                  display: "flex",
                  alignItems: "center",
                  "& .icon": {
                    marginLeft: "8px",
                  },
                },
              },
              "& .untracked-hours":{
                color:"#4BA3B7",
                textDecoration: "none",
                "& .value-container":{
                  display: "flex",
                  alignItems: "center",
                },
                "& .icon":{
                  marginLeft: "8px",
                }
              }
            },
            "& .list-description1": {
              letterSpacing: "0.105px",
              color: "#2B2B2B",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "26px",             
              fontFamily: "Roboto",
              width: "67%",
              [theme.breakpoints.down("xs")]: {
                width: "43.4%",
              },
              "& .brand-names":{ 
                display:"flex",
                "& .brand-list":{
                  marginRight:"10px"
                },
                "& .more-btn":{
                 position:"absolute",
                 right:"70px",
                 top:"30px"
                },
              },
              "& .list-subtitle": {
                letterSpacing: "normal",
                color: "#94A3B8",
                lineHeight: "14px",
                fontSize: "10px",
              },
              "& .assignee-list": {
                alignItems: "center",
                display: "flex",
                "& .assignee-bubble": {
                  marginLeft: "-4px",
                  color: "rgba(43, 43, 43, 0.87)",
                  letterSpacing: "-0.244px",
                  justifyContent: "center",
                  background: "#ECF1F4",
                  width: "24px",
                  height: "24px",
                  borderRadius: "100%",
                  border: "2px solid #FFF",
                  fontSize: "9.586px",
                  fontFamily: "Roboto",
                  fontWeight: 600,
                  lineHeight: "13.42px",
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  "&:first-child": {
                    marginLeft: "0",
                  },
                  "&.more-bubble": {
                    cursor: "pointer",
                  },
                },
              },
              "& .date-info": {
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
                // marginLeft:"20px",
                "&:last-child": {
                  marginBottom: "0",
                },
                "& .label": {
                  color: "#94A3B8",
                  minWidth: "60px",
                  textAlign: "right",
                },
                "& .value": {
                  marginLeft: "8px",
                  display: "flex",
                  alignItems: "center",
                  "& .icon": {
                    marginLeft: "8px",
                  },
                },
              },
              "& .date-info-1": {
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
                marginLeft: "163px",
                [theme.breakpoints.down("xs")]: {
                  marginLeft: "50px",
                },
                width: "100%",
                "&:last-child": {
                  marginBottom: "0",
                },
                "& .label": {
                  color: "#94A3B8",
                  minWidth: "60px",
                  textAlign: "right",                 
                },
                "& .value": {
                  marginLeft: "8px",
                  display: "flex",
                  alignItems: "center",
                  "& .icon": {
                    marginLeft: "8px",
                  },
                },
              },
              "& .untracked-hours":{
                color:"#4BA3B7",
                textDecoration: "none",
                "& .value-container":{
                  display: "flex",
                  alignItems: "center",
                },
                "& .icon":{
                  marginLeft: "8px",
                }
              }
            },
            "& .list-label": {
              letterSpacing: "0.105px",
              fontWeight: 400,
              lineHeight: "26px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "12px",
              width: "33%",
              [theme.breakpoints.down("xs")]: {
                width: "56.6%",
              },
            },
            "& .list-label-1": {
              letterSpacing: "0.105px",
              fontWeight: 400,
              lineHeight: "26px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "12px",            
            },
          },
          "& .accordion-block": {
            boxShadow: "none",
            borderRadius: "12px",           
            width:"100%", 
            "& .employee-txt":{
              display: "flex",
              justifyContent:"space-between",
              width: "65%",
              "& .employee-value":{
                color: "#2B2B2B",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
              },
              "& .list-actual": {
                display:"flex",
                alignItems:"center",
                letterSpacing: "0.105px",
                color: "#2B2B2B",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "26px",
                paddingLeft: "15px",
                fontFamily: "Roboto",
                          
                "& .list-subtitle": {
                  letterSpacing: "normal",
                  color: "#94A3B8",
                  lineHeight: "14px",
                  fontSize: "10px",
                },
                "& .list-value":{
                  color: "#2B2B2B",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: 400,
                  marginLeft:"10px",
                 }             
              },              
            },     
            "&:before": {
              display: "none",
            },
            "& .accordion-heading": {
              minHeight: "unset",
              padding: "0",
              alignItems: "flex-start",
              flexDirection: "row-reverse",
              marginLeft:"-5px",
              "& .heading": {
                width: "100%",
                color: "#2B2B2B",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                lineHeight: "19px",
                fontWeight: 500,
                marginBottom: "6px",
                [theme.breakpoints.down("xs")]: {
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 400,
                  marginBottom: "8px",
                },
              },
              "& .sub-txt": {
                marginBottom: "0",
                color: "#94A3B8",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0.09px",
              },
              "& .MuiAccordionSummary-content": {
                margin: "0",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "flex-start",
              },
              "& .MuiIconButton-root": {
                padding: "0",
                margin: "0",
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  color: "#94A3B8",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              },
            },
            "& .accordion-details": {
              padding: "0",
              marginTop: "24px", 
              "& .inventory-table": {
                width: "100%",
                margin: "20px 0",
                backgroundColor: "#fff",
                textAlign: "inherit",
                "& .inventeory-items":{
                    marginBottom:"6px",
                  "& .employee-value-head":{
                    color: "#2B2B2B",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 400,
                    display: "flex",
                    alignItems: "center",
                    marginTop:"12px",
                    "& .image":{
                      marginLeft:"6px"
                    }
                  },               
                  "& .employee-value":{
                    color: "#2B2B2B",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 400,
                  },
                }

            },             
              "& .labour-cost":{
               display: "flex",
               justifyContent:"space-between",
               width: "92%",
               "& .list-value":{
                color: "#2B2B2B",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 600,
               }
              },            
              "& table": {
                "& tr": {
                  "& td": {
                    "&.MuiTableCell-root": {
                      color: "#2B2B2B",
                      fontFamily: "Roboto",
                      fontSize: "13px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      padding: "6px 8px 24px",
                      border: "0",
                      "&:first-child": {
                        paddingLeft: "0",
                        width: "43.5%",
                      },
                      "&:nth-child(2)": {
                        textAlign: "center",
                      },
                      "&:last-child": {
                        paddingRight: "0",
                        textAlign: "right",
                      },
                    },
                    "& .open-icon": {
                      display: "inline-block",
                      marginLeft: "3px",
                      position: "relative",
                      top: "2px",
                      "& .MuiSvgIcon-root": {
                        color: "#94A3B8",
                        fontSize: "14px",
                      },
                    },
                  },
                  "& th": {
                    border: "0",
                  },
                  "& .table-heading": {
                    color: "#94A3B8",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 600,
                    lineHeight: "16px",
                    letterSpacing: "0.09px",
                    padding: "0",
                    "&.last": {
                      padding: "15px 0 0",
                    },
                  },
                  "&.last-row": {
                    "& td": {
                      "&.MuiTableCell-root": {
                        padding: "24px 0 0",
                      },
                    },
                  },
                },
              },
            },
          },
        },
        "& .tasklist-tabwrapper": {
          marginBottom: "45px",
          "& .not-found":{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "19px",
            whiteSpace: "nowrap",
            marginLeft: "10px",
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            marginTop:"20px",
          },
          [theme.breakpoints.down("xs")]: {
            marginBottom: "22px",
          },
          "&:last-child": {
            marginBottom: "0",
          },
          "& .assignee-tabs": {
            "& .MuiTabs-root": {
              marginBottom: "0",
              [theme.breakpoints.down("xs")]: {
                margin: "0 -24px",
              },
            },
          },
          "& .MuiTabs-root": {
            borderBottom: "1px solid #E8ECF2",
            marginBottom: "17px",
            "& .MuiTab-root": {
              color: "#2B2B2B",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 500,
              fontFamily: "Expletus Sans",
              textTransform: "capitalize",
              opacity: "0.67",
              minWidth: "unset",
              padding: "16px 24px",
              letterSpacing: "0",
              "& .MuiTouchRipple-root": {
                display: "none",
              },
              "&.Mui-selected": {
                opacity: "1",
              },
            },
          },
          "& .tab-heading": {
            marginBottom: "24px",
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
            "& .sub-txt": {
              letterSpacing: "0",
              marginBottom: "0",
              fontWeight: 400,
              lineHeight: "17px",
              fontSize: "13px",
              fontFamily: "Roboto",
              color: "#94A3B8",
              [theme.breakpoints.down("xs")]: {
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.09px",
              },
            },
            "& .primary-txt": {
              fontFamily: "Expletus Sans",
              marginBottom: "5px",
              lineHeight: "21px",
              fontWeight: 500,
              fontSize: "16px",
              letterSpacing: "0",
              color: "#2B2B2B",
              [theme.breakpoints.down("xs")]: {
                fontSize: "14px",
                lineHeight: "19px",
              },
            },
            "& .heading-right": {
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
              "& .heading-link": {
                marginRight: "24px",
                "&:last-child": {
                  marginRight: "0",
                },
              },
            },
          },
          "& .attachments-list-default-view":{
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            "& .attacments-icon":{
              width:"68px",
              marginBottom:"40px"
            },
            "& .no-attachment-text":{
               fontFamily: "Expletus Sans",
               lineHeight: "16px",
               fontSize: "16px",
               fontWeight: 400,
               color: "#2B2B2B",
               marginBottom:"20px",
            },
            "& .no-attachment-sub-text":{
             fontFamily: "Roboto",
             lineHeight: "16px",
             fontSize: "14px",
             fontWeight: 400,
             color: "#94A3B8",
             marginBottom:"25px",
          },
          "& .secondary-btn": {
            minWidth: "327px",
            color: "#4BA3B7",
            borderColor: "#4BA3B7",
            minHeight: "48px",
            "&:hover": {
              color: "#4BA3B7",
              borderColor: "#4BA3B7",
            },       
         },
         },
          "& .attachments-list": {
            "& .list-secondary": {
              right: "0",
              "& .MuiIconButton-root": {
                margin: "0",
                padding: "0",
                color: "#828282",
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                },
              },
            },
            "& .list-item": {
              paddingLeft: "0",
              paddingBottom: "16px",
              paddingTop: "16px",
              borderBottom: "1px solid #E8ECF2",
              "& .list-img": {
                marginRight: "8px",
                width: "28px",
              },
              "& .list-name": {
                fontFamily: "Expletus Sans",
                lineHeight: "16px",
                fontSize: "12px",
                fontWeight: 500,
                color: "#2B2B2B",
              },
            },
            "& .subheader-title": {
              borderBottom: "1px solid #E8ECF2",
              paddingBottom: "16px",
              justifyContent: "space-between",
              padding: "0",
              position: "static",
              display: "flex",
              "& .title": {
                opacity: "0.67",
                lineHeight: "16px",
                fontFamily: "Expletus Sans",
                fontSize: "12px",
                fontWeight: 500,
                color: "#2B2B2B",
              },
              "& .tasklist-link": {
                fontFamily: "Expletus Sans",
                textDecoration: "none",
                fontSize: "14px",
                opacity: "0.67",
                fontWeight: 500,
                lineHeight: "19px",
                color: "#4BA3B7",
              },
            },
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#4BA3B7",
          },
          "& .description-box": {
            fontFamily: "Roboto",
            fontWeight: 400,
            letterSpacing: "0.105px",
            padding: "16px",
            color: "#2B2B2B",
            fontSize: "14px",
            background: "#F2F8FC",
            lineHeight: "26px",
            borderRadius: "12px",
          },
        },
        "& .heading-block": {
          marginBottom: "46px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "26px",
          },
          "& .primary-txt": {
            marginBottom: "6px",
            fontSize: "24px",
            fontFamily: "Expletus Sans",
            fontWeight: 400,
            color: "rgba(43, 43, 43, 0.87)",
            lineHeight: "32px",
          },
          "& .sub-txt": {
            marginBottom: "0",
            lineHeight: "26px",
          },
        },
        "& .activitylist-ul": {
          padding: "0",
          "& .activity-li": {
            position: "relative",
            background: "transparent",
            padding: "0",
            marginBottom: "32px",
            justifyContent: "space-between",
            display: "flex",
            "& .activity-icon": {
              marginRight: "16px",
              width: "32px",
              height: "32px",
              minWidth: "unset",
            },
            "& .activity-details": {
              margin: "0",
              "& .activity-info": {
                letterSpacing: "0.09px",
                marginBottom: "2px",
                lineHeight: "16px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#2B2B2B",
                fontSize: "12px",
                "& .name": {
                  marginRight: "5px",
                  color: "#4BA3B7",
                },
              },
              "& .activity-date": {
                letterSpacing: "0.125px",
                fontWeight: 400,
                lineHeight: "17px",
                fontFamily: "Roboto",
                fontSize: "10px",
                color: "#94A3B8",
              },
            },
            "&:last-child": {
              marginBottom: "0",
              "&:before": {
                display: "none",
              },
            },
            "&:before": {
              left: "16px",
              height: "100%",
              borderRight: "1px solid #E8ECF2",
              position: "absolute",
              top: "100%",
              content: "''",
            },
          },
        },
        "& .tasklist-member": {
          padding: "0",
          "& .tasklist-li": {
            padding: "26px 24px 18px",
            position: "relative",
            justifyContent: "space-between",
            background: "transparent",
            display: "flex",
            borderBottom: "1px solid #ECF1F4",
            cursor: "pointer",
            [theme.breakpoints.down("xs")]: {
              padding: "26px 0 18px",
            },
            "& .tasklist-left": {
              display: "flex",
              alignItems: "center",
              "& .assignee-info": {
                marginLeft: "14px",
              },
              "& .assignee-profile": {
                letterSpacing: "-0.395px",
                lineHeight: "21.679px",
                borderRadius: "100%",
                height: "38px",
                alignItems: "center",
                fontFamily: "Roboto",
                justifyContent: "center",
                background: "#ECF1F4",
                display: "flex",
                color: "rgba(43, 43, 43, 0.87)",
                fontSize: "15.485px",
                fontWeight: 600,
                minWidth: "38px",
                textTransform: "capitalize",
              },
              "& .assignee-name": {
                marginBottom: "2px",
                lineHeight: "19px",
                fontWeight: 500,
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                color: "#2b2b2b",
                textTransform: "capitalize",
              },
              "& .assignee-email": {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
              },
            },
            "& .tasklist-icon": {
              minWidth: "unset",
              marginLeft: "10px",
            },
            "& .tasklist-hours": {
              textAlign: "right",
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: "16px",
              letterSpacing: "0.09px",
              "& .hours": {
                display: "block",
                color: "#94A3B8",
                fontWeight: 400,
                marginTop: "3px",
              },
            },
          },
          "& .tasklist-total": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            padding: "16px 24px",
            borderBottom: "1px solid #ECF1F4",
            position: "static",
            opacity: "0.67",
            [theme.breakpoints.down("xs")]: {
              width: "auto",
              margin: "0 -24px",
            },
            "& .total-member": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19px",
            },
            "& .select-control": {
              "&.sortby-control": {
                "& .MuiSelect-select": {
                  paddingRight: "26px",
                },
                "& .MuiSelect-icon": {
                  fontSize: "20px",
                  width: "20px",
                  height: "20px",
                  color: "#2B2B2B",
                  top: "calc(50% - 11px)",
                },
              },
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
              },
            },
          },
        },
        "& .template-list": {
          padding: "0",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "0",
            paddingRight: "0",
            position: "static",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#225980",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-adornedStart": {
                paddingLeft: "16px",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "17px 16px 16px",
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "7px",
                },
              },
            },
          },
          "& .multiselect-membres": {
            display: "flex",
            alignItems: "center",
            width: "auto",
            maxWidth: "unset",
            justifyContent: "space-between",
            padding: "16px 0",
            margin: "0",
            borderBottom: "1px solid #ECF1F4",
            [theme.breakpoints.down("xs")]: {
              padding: "15px 0",
              margin: "0",
            },
            "& .total-member": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19px",
              opacity: "0.67",
            },
            "& .select-control": {
              opacity: "0.67",
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
                paddingRight: "26px",
              },
              "& .MuiSelect-icon": {
                top: "calc(50% - 11px)",
                color: "#2b2b2b",
                fontSize: "20px",
              },
            },
          },
          "& .template-li": {
            display: "flex",
            justifyContent: "space-between",
            background: "transparent",
            position: "relative",
            padding: "21px 0",
            borderBottom: "1px solid #ECF1F4",
            cursor: "pointer",
            "&.Mui-selected": {
              backgroundColor: "#F2F8FC",
              "&:hover": {
                backgroundColor: "#F2F8FC",
              },
            },
            "& .template-left": {
              "& .name": {
                fontSize: "14px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                marginBottom: "4px",
              },
              "& .sub-txt": {
                fontSize: "12px",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                letterSpacing: "0",
                marginBottom: "0",
              },
            },
            "& .template-icon": {
              minWidth: "unset",
              marginLeft: "10px",            
              "& .MuiCheckbox-root": {
                padding: "0",
              },
              "& .MuiIconButton-colorSecondary": {
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            },
          },
        },
        "& .move-tasklist-list": {
          padding: "0",
          "& .search-wrapper":{
            position:"relative",
            "& .filter-image":{
             cursor:"pointer",
            },
          "& .search-box":{
            width:"85%",
            "& .MuiOutlinedInput-root":{
              borderRadius: "12px",
              marginLeft: "-4px",
            },
            "& .MuiOutlinedInput-input":{
              marginLeft: "6px",
            },
          },
          "& .sort-by":{
            position: 'absolute',
            right: 0,
            top: '0px',
            borderRadius: '12px',
            border: '1px solid var(--Stroke, #E8ECF2)',
            padding: '16px',
          }
          },
          "& .line":{
            borderBottom: "1px solid #E8ECF2",
            marginTop: "20px",
            width:"100%"
          },
          "& .multiselect-membres": {
            width: "auto",
            justifyContent: "space-between",
            border: "0",
            alignItems: "center",
            maxWidth: "unset",
            display: "flex",
            padding: "0",
            margin: "0 0 4px",
            "& .total-member": {
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0.09px",
            },
            "& .select-control": {
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
                paddingRight: "26px",
              },
              "& .MuiSelect-icon": {
                top: "calc(50% - 11px)",
                color: "#2b2b2b",
                fontSize: "20px",
              },
            },
          },
          "& .template-li": {
            display: "flex",
            justifyContent: "space-between",
            background: "transparent",
            position: "relative",
            padding: "20px 0",
            borderBottom: "1px solid #ECF1F4",
            cursor: "pointer",
            "& .template-left": {
              "& .sub-txt": {
                fontWeight: 500,
                marginBottom: "0",
                lineHeight: "16px",
                color: "#94A3B8",
                fontFamily: "Expletus Sans",
                letterSpacing: "0",
                fontSize: "12px",
              },
              "& .name": {
                fontFamily: "Expletus Sans",
                marginBottom: "4px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontSize: "14px",
                fontWeight: 500,
              },             
              "& .line":{
                borderBottom: "1px solid #E8ECF2",
                marginTop: "20px",
              },
              "& .deal-name": {
                fontFamily: "Expletus Sans",
                marginBottom: "4px",
                marginTop: "6px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontSize: "14px",
                fontWeight: 600,
              },
              "& .deal-owner":{
                color: "#94A3B8",
                fontFamily:"Roboto",
                fontSize: "10px",
                textTransform: "uppercase",
                fontWeight: 500,
                textAlign: "start",
                paddingRight:"10px",
                paddingTop:"15px"
              },
              "& .deal-owner-name":{
                color: "#2B2B2BDE",
                fontFamily:"Roboto",
                fontSize: "12px",
                fontWeight: 400,
                textAlign: "start",
                paddingRight:"10px",
                paddingTop:"8px",
              },
              "& .deal-container":{
                display:"flex",
                alignItems:"center",
                "& .status-item": {
                  display: "flex",
                  alignItems:"center",
                  "& .status-name": {
                    marginRight: "10px",
                    textTransform: "capitalize",
                    fontFamily: "Roboto",
                  marginBottom: "4px",
                  lineHeight: "19px",
                  color: "#2b2b2b",
                  fontSize: "10px",
                  fontWeight: 400,
                  },
                  "& .status": {
                    width: "10px",
                    height: "10px",
                    background: "#94A3B8",
                    borderRadius: "100%",
                    marginLeft: "auto",
                    marginBottom:"4px",
                    "&.open": {
                      background: "#4BA3B7",
                    },
                    "&.cancelled": {
                      background: "#F7E223",
                    },
                    "&.sky-blue": {
                      background: "#12AEF1",
                    },
                    "&.orange": {
                      background: "#F6A318",
                    },
                    "&.yellow": {
                      background: "#F7E223",
                    },
                    "&.black": {
                      background: "#2B2B2B",
                    },
                    "&.green": {
                      background: "#00BA88",
                    },
                  },
                },
                "& .deal-close-date":{
                  fontFamily: "Roboto",
                  marginBottom: "4px",
                  lineHeight: "19px",
                  color: "#2b2b2b",
                  fontSize: "10px",
                  fontWeight: 400,
                  "&.content-dots": {
                    color: "#797878",
                    marginLeft:"12px",
                    "&:before": {
                      content: '""',
                      width: "8px",
                      height: "8px",
                      borderRadius: "100%",
                      background: "#ECF1F4",
                      display: "inline-block",
                      marginRight: "8px",
                    },
                  }, 
                },
              },
             
            },
            "& .template-icon-deal": {
              marginLeft: "10px",
              minWidth: "unset",
              display:"flex",
              alignItems:"center",
              position: "absolute",
              right: 0,
              "& .price-text":{
                fontFamily: "Expletus Sans",
                marginRight: "4px",
                lineHeight: "19px",
                color: "#2b2b2b",
                fontSize: "14px",
                fontWeight: 600,
               },
              "& .MuiCheckbox-root": {
                padding: "0",
              },
              "& .MuiIconButton-colorSecondary": {
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            },           
            "& .template-icon": {
              marginLeft: "10px",
              minWidth: "unset",              
              "& .MuiCheckbox-root": {
                padding: "0",
              },
              "& .MuiIconButton-colorSecondary": {
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            },
          },
          "& .MuiListSubheader-gutters": {
            position: "static",
            paddingRight: "0",
            paddingLeft: "0",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiOutlinedInput-input": {
                padding: "17px 16px 16px",
                letterSpacing: "0",
                lineHeight: "17px",
                fontSize: "13px",
                "&:-ms-input-placeholder": {
                  color: "#94A3B8",
                },
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "7px",
                },
                "&::-webkit-input-placeholder": {
                  color: "#94A3B8",
                },
                "&::placeholder": {
                  color: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-adornedStart": {
                paddingLeft: "16px",
              },
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  fill: "#225980",
                },
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
              },
            },
          },
        },
        "& .assignee-multiSelect-list": {
          padding: "0",          
          "&.selected-list": {
            "& .MuiListItem-root": {
              background: "#F2F8FC",
            },
          },
          "& .MuiListSubheader-gutters": {
            position: "static",
            paddingRight: "0",
            paddingLeft: "0",
            "& .form-control": {
              marginBottom: "12px",
              "& .MuiOutlinedInput-input": {
                padding: "16px",
                lineHeight: "17px",
                letterSpacing: "0",
                fontSize: "13px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
              "& .MuiInputAdornment-positionStart": {
                marginRight: "16px",
              },
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  fill: "#225980",
                },
              },
            },
            "&.multiselect-subheader": {
              color: "#2B2B2B",
              marginBottom: "0",
              borderBottom: "1px solid #ECF1F4",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              lineHeight: "19px",
              display: "flex",
              padding: "19px 24px",
              [theme.breakpoints.down("xs")]: {
                margin: "0 -24px",
                width: "auto",
              },
              "& .tasklist-link": {
                textDecoration: "none",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "19px",
                whiteSpace: "nowrap",
                marginLeft: "10px",
                color: "#C7263E",
                fontFamily: "Expletus Sans",
              },
            },
            "&.multiselect-subtxt": {
              borderBottom: "1px solid #ECF1F4",
              textTransform: "uppercase",
              fontSize: "10px",
              fontWeight: 400,
              lineHeight: "normal",
              fontFamily: "Roboto",
              padding: "10px 20px",
              color: "#94A3B8",
              [theme.breakpoints.down("xs")]: {
                margin: "0 -24px",
                width: "auto",
              },
            },
          },
          "& .multiselect-membres": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            padding: "16px 24px",
            borderBottom: "1px solid #ECF1F4",
            margin: "0",
            [theme.breakpoints.down("xs")]: {
              width: "auto",
              margin: "0 -24px",
            },
            "& .total-member": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "19px",
            },
            "& .select-control": {
              "&.sortby-control": {
                "& .MuiSelect-select": {
                  paddingRight: "26px",
                },
                "& .MuiSelect-icon": {
                  top: "calc(50% - 11px)",
                  color: "#2b2b2b",
                  fontSize: "20px",
                },
              },
              "& .MuiSelect-select": {
                color: "#2b2b2b",
                opacity: "1",
                fontSize: "12px",
                letterSpacing: "0",
              },
            },
          },
          "& .MuiListItem-root": {
            borderBottom: "1px solid #ECF1F4",
            cursor: "pointer",
            padding: "8px 24px 6px",
            [theme.breakpoints.down("xs")]: {
              margin: "0 -24px",
              width: "auto",
            },
          },
          "& .assignee-detail": {
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            display: "flex",
            "& .assignee-left": {
              display: "flex",
              flex: "1",
              "& .assignee-info": {
                marginLeft: "10px",
              },
              "& .assignee-email": {
                fontWeight: 500,
                lineHeight: "16px",
                fontFamily: "Expletus Sans",
                color: "#94A3B8",
                fontSize: "12px",
                marginBottom:"6px",
              },
              "& .assignee-name": {
                marginBottom: "2px",
                lineHeight: "19px",
                fontWeight: 500,
                color: "#2b2b2b",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                textTransform: "capitalize",
              },
              "& .assignee-profile": {
                letterSpacing: "-0.395px",
                fontWeight: 600,
                display: "flex",
                color: "rgba(43, 43, 43, 0.87)",
                justifyContent: "center",
                background: "#ECF1F4",
                alignItems: "center",
                borderRadius: "100%",
                fontFamily: "Roboto",
                height: "39px",
                fontSize: "15.485px",
                lineHeight: "21.679px",
                minWidth: "39px",
                textTransform: "uppercase",
              },
              "& .chip-wrapper": {
                marginTop: "3px",
                "& .MuiChip-root": {
                  height: "24px",
                  marginBottom: "2px",
                },
              },
            },
            "& .assignee-right": {
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              "& .hours-details": {
                color: "#000",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 600,
                lineHeight: "16px",
                letterSpacing: "0.09px",
                marginRight: "16px",
                textAlign: "right",
                [theme.breakpoints.down("md")]: {
                  marginRight: "8px",
                },
                "& .gray-txt": {
                  color: "#94A3B8",
                  fontWeight: 400,
                  marginTop: "4px",
                },
              },
              "& .assinee-selected": {
                width: "20px",
                height: "20px",
                "& .normal-img": {
                  display: "none",
                },
                "& .selected-img": {
                  display: "block",
                },
              },
              "& .assinee-unselected": {
                width: "20px",
                height: "20px",
                "& .normal-img": {
                  display: "block",
                },
                "& .selected-img": {
                  display: "none",
                },
              },
            },
          },
        },
        "& .multiselect-membres": {
          justifyContent: "space-between",
          margin: "0 -40px",
          alignItems: "center",
          width: "auto",
          maxWidth: "unset",
          display: "flex",
          padding: "15px 60px",
          borderBottom: "1px solid #ECF1F4",
          [theme.breakpoints.down("xs")]: {
            margin: "0",
            padding: "15px 0",
          },
          "& .select-control": {
            "& .down-arrow": {
              marginLeft: "6px",
              fontSize: "20px",
            },
            "& .MuiSelect-select": {
              paddingRight: "0",
              letterSpacing: "0",
              opacity: "1",
              fontSize: "12px",
              color: "#2b2b2b",
            },
          },
          "& .total-member": {
            fontWeight: 500,
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            fontSize: "12px",
            color: "#2B2B2B",
          },
        },
        "& .project-create-wrapper": {
          textAlign: "center",
          marginBottom: "75px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "35px",
          },
          "& .project-img": {
            marginBottom: "40px",
            [theme.breakpoints.down("xs")]: {
              maxWidth: "289px",
              margin: "0 auto 32px",
            },
          },
          "& .modal-title": {
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Expletus Sans",
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 400,
            marginBottom: "7px",
            [theme.breakpoints.down("md")]: {
              fontSize: "22px",
              lineHeight: "26px",
            },
            [theme.breakpoints.down("xs")]: {
              fontSize: "18px",
              lineHeight: "24px",
              marginBottom: "12px",
            },
          },
          "& .sub-txt": {
            letterSpacing: "0.25px",
            [theme.breakpoints.down("xs")]: {
              color: "#7A7A7A",
              lineHeight: "19px",
              letterSpacing: "0.42px",
            },
          },
        },
        "& .no-task-block": {
          textAlign: "center",
          padding: "31px 0 95px",
          [theme.breakpoints.down("xs")]: {
            padding: "15px 0 18px",
          },
          "& .task-img": {
            marginBottom: "16px",
          },
          "& .heading": {
            color: "#2B2B2B",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            marginBottom: "8px",
          },
          "& .sub-txt": {
            color: "#94A3B8",
            letterSpacing: "0.105px",
            marginBottom: "0",
          },
          "& .button-wrapper": {
            marginTop: "32px",
            "& .MuiButton-root": {
              maxWidth: "327px",
              color: "#4BA3B7",
              borderColor: "#4BA3B7",
              minHeight: "48px",
              "&:hover": {
                color: "#4BA3B7",
                borderColor: "#4BA3B7",
              },
            },
          },
        },
        "& .tasklist-task-wrap": {
          marginBottom: "16px",
          "&:first-child": {
            "& .task-heading": {
              paddingTop: "0",
            },
          },
          "&:last-child": {
            marginBottom: "0",
          },
          "& .task-heading": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 0",
            "& .heading": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
            },
            "& .total-task": {
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0.09px",
            },
          },
          "& .tasklist-task-ul": {
            padding: "0",
            width: "100%",
            "& .tasklist-task-ul": {
              paddingLeft: "32px",
            },
            "& .tasklist-task-li": {
              alignItems: "flex-start",
              background: "transparent",
              flexDirection: "column",
              padding: "0",
            },
            "& .tasklist-inner": {
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
              cursor: "pointer",
              padding: "16px 0",
              borderBottom: "1px solid #E8ECF2",
            },
            "& .tasklist-icon": {
              minWidth: "unset",
              marginRight: "16px",
              "& .MuiIconButton-root": {
                padding: "0",
              },
            },
            "& .tasklist-label": {
              margin: "0",
              flex: "1",
            },
          },
          "& .task-title": {
            display: "flex",
            justifyContent: "space-between",
            "& .title-dash": {
              color: "#2B2B2B",
              fontSize: "14px",
              lineHeight: "19px",
              fontFamily: "Expletus Sans",
              fontWeight: 400,  
              textDecoration: "line-through"           
            },
            "& .title": {
              color: "#2B2B2B",
              fontSize: "14px",
              lineHeight: "19px",
              fontFamily: "Expletus Sans",
              fontWeight: 400,
              "& .priority-status": {
                color: "#C7263E",
                marginRight: "3px",
              },
            },
            "& .title-right": {
              marginLeft: "10px",
              "& .controller-link": {
                width: "22px",
                cursor:"pointer",
              },
            },
          },
          "& .task-desc": {
            display: "flex",
            justifyContent: "space-between",
            color: "#94A3B8",
            fontSize: "12px",
            lineHeight: "16px",
            fontFamily: "Roboto",
            fontWeight: 400,
            letterSpacing: "0.09px",
            marginTop: "3px",
            "& .desc-left": {
              marginRight: "10px",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            },
            "& .task-about": {
              paddingLeft: "14px",
              marginLeft: "8px",
              position: "relative",
              "&:before": {
                content: '""',
                width: "6px",
                height: "6px",
                borderRadius: "100%",
                background: "#E8ECF2",
                display: "inline-block",
                position: "absolute",
                left: "0",
                top: "5px",
              },
            },
            "& .timing": {
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontSize: "10px",
              fontWeight: 400,
              lineHeight: "17px",
              letterSpacing: "0.125px",
            },
          },
        },
        "& .budget-wrapper": {
          "& .budget-main": {
            "& .MuiSlider-root": {
              height: 23,
              borderRadius: "24px",
              padding: "0",
              "& .MuiSlider-rail": {
                background: "#00BA88",
                opacity: "0.17",
                height: "inherit",
                borderRadius: "inherit",
              },
              "& .MuiSlider-track": {
                border: "none",
                background: "#00BA88",
                height: "inherit",
                borderRadius: "inherit",
              },
              "& .MuiSlider-thumb": {
                border: "0",
                height: "23px",
                marginTop: "0",
                borderRadius: "0",
                backgroundColor: "transparent",
                "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                  boxShadow: "inherit",
                },
                "&:before": {
                  display: "none",
                },
              },
              "& .MuiSlider-valueLabel": {
                // display: "none",
              },
            },
            "& .slider-wrap": {
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              padding: "15px 16px",
              marginBottom: "24px",
              "&.overflow-count": {
                "& .legends-dots": {
                  background: "#F5DADE",
                  "&.actual": {
                    background: "#C7263E",
                  },
                },
                "& .progress-wrap": {
                  background: "#C7263E",
                  "& .progress-inner": {
                    background: "#F5DADE",
                  },
                  "& .progress-label": {
                    color: "#2B2B2B",
                  },
                },
              },
              "& .slider-heading": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "13px",
              },
              "& .slider-label": {
                color: "#94A3B8",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "19px",
                fontFamily: "Expletus Sans",
              },
              "& .legends-wrapper": {
                display: "flex",
                alignItems: "center",
                color: "#2B2B2B",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0.09px",
              },
              "& .slider-legends": {
                marginRight: "8px",
                display: "flex",
                alignItems: "center",
                "&:last-child": {
                  marginRight: "0",
                },
              },
              "& .legends-dots": {
                width: "11px",
                height: "11px",
                background: "#D4F3EB",
                borderRadius: "100%",
                marginLeft: "8px",
                "&.actual": {
                  background: "#00BA88",
                },
              },
              "& .slider-inner": {
                display: "flex",
                alignItems: "center",
                "& .max-value": {
                  marginLeft: "12px",
                  color: "#2B2B2B",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  letterSpacing: "0.09px",
                },
                "& .MuiSlider-valueLabel": {
                  transform: "none",
                  transition: "none",
                  top: "0",
                  bottom: "0",
                  "& > span": {
                    width: "auto",
                    height: "23px",
                    transform: "none",
                    borderRadius: "0",
                    background: "transparent",
                    "& > span": {
                      color: "#2B2B2B",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      letterSpacing: "0.09px",
                      transform: "none",
                    },
                  },
                },
              },
            },
            "& .progress-wrap": {
              width: "100%",
              height: "22px",
              background: "rgba(0, 186, 136, 0.17)",
              borderRadius: "24px",
              position: "relative",
              "& .progress-inner": {
                background: "#00BA88",
                height: "inherit",
                borderRadius: "inherit",
                maxWidth: "100%",
                borderRight: "3px solid #fff",
              },
              "& .progress-label": {
                textAlign: "center",
                color: "#fff",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "23px",
                letterSpacing: "0.09px",
                height: "inherit",
                maxWidth: "100%",
                position: "absolute",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
              },
            },
            "& .accordion-block": {
              boxShadow: "none",
              borderRadius: "12px",
              border: "1px solid #E8ECF2",
              minHeight: "unset",
              padding: "16px 15px",
              "&:before": {
                display: "none",
              },
              "& .accordion-heading": {
                minHeight: "unset",
                padding: "0",
                alignItems: "flex-start",
                "& .heading": {
                  width: "100%",
                  color: "#2B2B2B",
                  fontFamily: "Expletus Sans",
                  fontSize: "14px",
                  lineHeight: "19px",
                  fontWeight: 500,
                  marginBottom: "6px",
                  [theme.breakpoints.down("xs")]: {
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    marginBottom: "8px",
                  },
                },
                "& .sub-txt": {
                  marginBottom: "0",
                  color: "#94A3B8",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  letterSpacing: "0.09px",
                },
                "& .MuiAccordionSummary-content": {
                  margin: "0",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                },
                "& .MuiIconButton-root": {
                  padding: "0",
                  margin: "0",
                  "& .MuiSvgIcon-root": {
                    fontSize: "20px",
                    color: "#94A3B8",
                  },
                  "& .MuiTouchRipple-root": {
                    display: "none",
                  },
                },
              },
              "& .accordion-details": {
                padding: "0",
                marginTop: "24px",
                "& table": {
                  "& tr": {
                    "& td": {
                      "&.MuiTableCell-root": {
                        color: "#2B2B2B",
                        fontFamily: "Roboto",
                        fontSize: "13px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        padding: "6px 8px 24px",
                        border: "0",
                        "&:first-child": {
                          paddingLeft: "0",
                          width: "43.5%",
                        },
                        "&:nth-child(2)": {
                          textAlign: "center",
                        },
                        "&:last-child": {
                          paddingRight: "0",
                          textAlign: "right",
                        },
                      },
                      "& .open-icon": {
                        display: "inline-block",
                        marginLeft: "3px",
                        position: "relative",
                        top: "2px",
                        "& .MuiSvgIcon-root": {
                          color: "#94A3B8",
                          fontSize: "14px",
                        },
                      },
                    },
                    "& th": {
                      border: "0",
                    },
                    "& .table-heading": {
                      color: "#94A3B8",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "16px",
                      letterSpacing: "0.09px",
                      padding: "0",
                      "&.last": {
                        padding: "15px 0 0",
                      },
                    },
                    "&.last-row": {
                      "& td": {
                        "&.MuiTableCell-root": {
                          padding: "24px 0 0",
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          "& .budget-footer": {
            borderTop: "1px dashed rgba(148, 163, 184, 0.37)",
            paddingTop: "20px",
            marginTop: "25px",
            textAlign: "right",
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "16px",
            lineHeight: "22px",
            fontWeight: 400,
            "& .price": {
              marginLeft: "8px",
            },
          },
        },
        "& .button-wrapper": {
          marginTop: "48px",
          [theme.breakpoints.down("xs")]: {
            marginTop: "32px",
          },
          "&.full-btn": {
            "& .MuiButton-root": {
              width: "100%",
            },
          },
        },
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        color: "#94A3B8",
        letterSpacing: "0.105px",
        fontWeight: 400,
        fontFamily: "Roboto",
        marginTop: "0",
        marginBottom: "24px",
        [theme.breakpoints.down("xs")]: {
          color: "rgba(43, 43, 43, 0.67)",
          fontSize: "12px",
          lineHeight: "17px",
        },
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .form-info-wrapper": {
        margin: "0 0 28px",
        "&:last-child": {
          margin: "0",
        },
        "& .select-modal-field": {
          "& .MuiInputAdornment-positionEnd": {
            "& .MuiSvgIcon-root": {
              fontSize: "20px",
              color: "#94A3B8",
            },
          },
        },
        "& .form-row": {
          display: "flex",
          flexWrap: "wrap",
          margin: "0 -8px",
          "& .form-col": {
            padding: "0 8px",
            flex: "0 0 100%",
            maxWidth: "100%",
            "&.col6": {
              flex: "0 0 50%",
              maxWidth: "50%",
            },
            "& .form-control": {
              marginBottom: "24px",
            },
          },
        },
      },
      "& .profile-team": {
        "& .team-label": {
          fontSize: "10px",
          fontFamily: "Roboto",
          lineHeight: "12px",
          textTransform: "uppercase",
          color: "#94A3B8",
          marginBottom: "8px",
          fontWeight: 400,
        },
      },
      "& .bottom-link": {
        "& .modal-link": {
          marginLeft: "3px",
        },
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        textAlign: "center",
        color: "#2B2B2B",
      },
      "& .modal-form": {
        marginTop: "0",
      },
      "& .current-team": {
        color: "#94A3B8",
        padding: "17px 16px",
        fontSize: "14px",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        border: "1px solid #e8ecf2",
        borderRadius: "6px",
      },
      "& .form-heading": {
        letterSpacing: "0",
        lineHeight: "18px",
        color: "#2B2B2B",
        fontFamily: "Roboto",
        fontSize: "16px",
        margin: "0 0 16px",
        fontWeight: 500,
      },
      "& .select-control": {
        "&.multi-select": {
          "&.full-outlined": {
            width: "100%",
            marginBottom: "24px",
            "& .multiselect-label": {
              display: "block",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "18px",
              color: "#94A3B8",
              fontFamily: "Roboto",
              letterSpacing: "0.105px",
            },
            "& .MuiSelect-select": {
              fontFamily: "Roboto",
              fontWeight: 400,
              color: "#2B2B2B",
              padding: "0 40px 0 16px",
              lineHeight: "20px",
              letterSpacing: "0.105px",
              minHeight: "56px",
              "& .assignee-list": {
                marginLeft: "0",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
              },
            },
            "& .MuiInputLabel-outlined.Mui-focused, & .MuiFormLabel-filled": {
              fontSize: "12px",
              lineHeight: "16px",
              color: "rgba(43,43,43,0.87)",
              width: "auto",
              "& ~.MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#e8ecf2",
                },
              },
              "&.Mui-error": {
                color: "rgba(43,43,43,0.87)",
              },
            },
            "& .multiple-clear": {
              position: "absolute",
              fontSize: "15px",
              zIndex: 1,
              color: "#94A3B8",
              top: "50%",
              right: "27px",
              transform: "translateY(-50%)",
            },
          },
          "& .multiple-clear": {
            position: "absolute",
            fontSize: "15px",
            right: "18px",
            top: "3px",
            zIndex: 1,
            color: "#94A3B8",
          },
          "& .MuiInput-formControl": {
            marginTop: "0",
          },
          "& .MuiSelect-select": {
            alignItems: "center",
            display: "flex",
            paddingRight: "40px",
            "& .select-icon": {
              marginRight: "6px",
            },
            "& .assignee-list": {
              marginLeft: "6px",
              alignItems: "center",
              display: "flex",
              "& .assignee-bubble": {
                letterSpacing: "-0.244px",
                fontSize: "9.586px",
                fontWeight: 600,
                border: "2px solid #FFF",
                height: "24px",
                display: "flex",
                color: "rgba(43, 43, 43, 0.87)",
                background: "#ECF1F4",
                alignItems: "center",
                fontFamily: "Roboto",
                position: "relative",
                lineHeight: "13.42px",
                marginLeft: "-4px",
                borderRadius: "100%",
                width: "24px",
                justifyContent: "center",
                "&:first-child": {
                  marginLeft: 0,
                },
              },
            },
          },
          "& .multiselect-label": {
            display: "none",
          },
        },
        "&.status-control": {         
          "&.completed": {
            "& .MuiSelect-select": {
              background: "rgba(148, 163, 184, 0.07)",
              color: "#94A3B8",
            },
            "& .MuiSelect-icon": {
              color: "#94A3B8",
            },
          },
          "&.cancelled": {
            "& .MuiSelect-select": {
              background: "rgba(247, 226, 35, 0.07)",
              color: "#F7E223",
            },
            "& .MuiSelect-icon": {
              color: "#F7E223",
            },
          },
          "& .on-track": {
            "& .MuiSelect-select": {
              background: "rgba(0, 186, 136, 0.07)",
              color: "#00BA88",
            },
            "& .MuiSelect-icon": {
              color: "#00BA88",
            },
          },
          "& .MuiSelect-icon": {
            width: "16px",
            height: "16px",
            right: "12px",
            color: "#12AEF1",
            top: "calc(50% - 8px)",
          },
          "& .MuiSelect-select": {
            // color: "#12AEF1",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            lineHeight: "16px",
            letterSpacing: "0.09px",
            opacity: "1",
            borderRadius: "12px",
            // background: "rgba(18, 174, 241, 0.07)",
            padding: "6px 32px 6px 12px",
            marginRight:"-34px",
          },
         "& .in-progress-status" :{
          "& .MuiSelect-select": {
            color: "#F6A318",
            backgroundColor: "#F6A31812"
          },
          "& .MuiSelect-icon": {
            color: "#F6A318",
          },            
        },
        "& .open-status" :{
          "& .MuiSelect-select": {
            color: "#12AEF1",
            background: "rgba(18, 174, 241, 0.07)",
          },
          "& .MuiSelect-icon": {
            color: "#12AEF1",
          },            
        },
         "& .completed-status" :{
          "& .MuiSelect-select": {
            color: "rgb(43 43 43 / 87%)",
            backgroundColor: "#3b3a3812"
          },
          "& .MuiSelect-icon": {
            color: "rgb(43 43 43 / 87%)",
          },            
        },
        "& .off-track-status" :{
          "& .MuiSelect-select": {
            color: "#C7263E",
            backgroundColor: "#F4EBEC"
          },
          "& .MuiSelect-icon": {
            color: "#C7263E",
          },            
        }, 
        "& .completed-project-status":{
          "& .MuiSelect-select": {
            color: "rgba(43, 43, 43, 0.87)",
            backgroundColor: "#3b3a3812"
          },
          "& .MuiSelect-icon": {
            color: "rgba(43, 43, 43, 0.87)",
          },  
        },   
        "& .cancelled-status" :{
          "& .MuiSelect-select": {
            color: "#F7E223",
            backgroundColor: "#FDFADB"
          },
          "& .MuiSelect-icon": {
            color: "#F7E223",
          },            
        },        
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
        "& .MuiSelect-select": {
          fontFamily: "Expletus Sans",
          paddingTop: "0",
          paddingBottom: "0",
          color: "rgba(43, 43, 43, 0.87)",
          opacity: "0.671",
          fontWeight: "500",
          letterSpacing: "0.1px",
          fontSize: "14px",
          "& .clear-icon": {
            width: "50px",
            height: "50px",
            background: "red",
          },
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .select-icon": {
          marginRight: "6px",
          position: "relative",
        },
      },
      "& .primary-btn": {
        width: "100%",
        marginBottom: "17px",
        lineHeight: "19px",
        fontSize: "14px",
        minHeight: "56px",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
        },
      },
      "& .modal-footer": {
        marginTop: "auto",
        "& .half-btn": {
          justifyContent: "space-between",
          flexWrap: "wrap",
          display: "flex",
          width: "100%",
          "& .MuiButton-root": {
            marginRight: "10px",
            marginBottom: "0",
            width: "auto",
            "&.secondary-btn": {
              minWidth: "140px",
            },
            "&.primary-btn": {
              padding: "12px 38px",
            },
            "&:last-child": {
              marginRight: "0",
            },
          },
        },
      },
      "& .secondary-btn": {
        marginBottom: "17px",
        lineHeight: "19px",
        fontSize: "14px",
        minHeight: "56px",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          minHeight: "48px",
        },
      },
    },
    modalDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
    },
    modalLink: {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0",
      color: "#4ba3b7",
      fontWeight: 500,
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
    },
    timetrackerWrapper: {
      "& .title-wrapper":{
        display: "flex",
        justifyContent: "space-between",
        textAlign: "end",
      
      },
      "& .task-desc":{
        "& .tracker-value":{
          color: "#2B2B2B",
          display: "flex",
          fontSize: "10px",
          marginTop: "2px",
          alignItems: "center",
          fontFamily: "Roboto",
          fontWeight: 400,
          lineHeight: "17px",
          letterSpacing: "0.125px"
        },
      },
      "& .progress-bar-wrapper":{
        display:"flex",
        alignItems:"center",
        "& .progress-bar":{
          width: "100%",
          height: "4px",
          backgroundColor: "#ECF1F4",
          borderRadius: "32px",
          overflow: "hidden",
          
          "& .progress":{
            height: "100%",
            //backgroundColor: "#4BA3B7",
            borderRadius: "32px",
            flex:"1",
            "&.in-progress":{
              backgroundImage: "linear-gradient(#4BA3B7, #225980)",
            },
            "&.completed":{
              backgroundColor: "red",
            },
          },
         },
         "& .progress-value":{
          flex:"0 0 40px",
          maxWidth:"40px",
          textAlign:"right",
          marginLeft:"5px",
          fontFamily: "Roboto",
          fontWeight: "400",
          lineHeight: "17px",
          letterSpacing: "0.125px",
          fontSize: "10px",
        }
       },
       "& .progress-wrap": {
        width: "100%",
        height: "4px",
        background: "#ECF1F4",
        borderRadius: "6px",
        display: "flex",
        justifyContent:"flex-start",
        position:"relative",
        "&:hover": {
          height: "20px",
          background: "#4BA3B7",
          opacity: "0.37",
          "& .current-tracker":{
            display: 'block',
          },
        },
        "& .progress-inner": {
          background: "#94A3B8",
          height: "inherit",
          borderRadius: "inherit",
          maxWidth: "100%",
          
          
          "&.inprogress": {
            background: "#4BA3B7",
          },
          "&.completed": {
            background: "#00BA88",
          },
          "&.red": {
            background: "#C7263E",
          },
        },
        "& .current-progress": {
          background: "#4BA3B7",
          height: "inherit",
          borderRadius: "16px",
          maxWidth: "100%",
          borderLeft: "4px solid #fff",
          marginLeft: "0",
          "& .current-tracker":{
            display:'none',
            height: '20px',
            width: '50px',
            background: '#4BA3B7',
            borderRadius: '40px',
            "& .MuiTypography-root":{
            textAlign: "center",
            color: "white",
            fontFamily: "'Roboto'",
            fontSize: "10px",
            fontWeight: 600,
            padding: "2px"
            },
          },
        },
      },
       "& .left-section-wrapper":{
        display: "flex",
        justifyContent:"space-between",
        alignItems:"center",
        "& .modal-title":{
          marginBottom: 0,
        },
       },
       "& .right-section-wrapper":{
        display: "flex",
        justifyContent:"space-between",
        "& .progress-value":{
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: '10px',
          lineHeight: '11px',
          textTransform: 'uppercase',
        }
       },
       "& .progress-bar-bottom-mg":{
          marginTop:'10px',
       }
      },
      productListWrapper: {
        display: "flex",
        flexDirection: "column",
        background:"#fff",
        overflow: 'auto',
        width:"100%",
        height:"100vh",
  
        "& .product-top-header": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          height: "84px",
          padding: "12px 24px",
          borderBottom: "1px solid #E8ECF2",
          "& .back-icon": {
            cursor:"pointer"
          },
  
          "& .top-left": {},
          "& .top-right": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
  
            "& .calendar-icon": {
              height: "24x",
              width: "24px",
              marginRight: "12px",
            },
            "& .selected-txt": {
              color: "var(--task-completed-color, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              whiteSpace: "nowrap",
            },
            "& .add-to-contact-btn": {
              textTransform: "none",
              borderRadius: "6px",
              background: "var(--brand-color-disable, #92C7D3)",
              width: "147px",
              height: "48px",
              padding: "8px 14px",
              color: "#ffffff",
              marginLeft: "32px",
            },
            "& .add-to-contact-btn-active": {
              textTransform: "none",
              borderRadius: "6px",
              background: "var(--brand-color-disable, #4BA3B7)",
              width: "147px",
              height: "48px",
              padding: "8px 14px",
              color: "#ffffff",
              marginLeft: "32px",
            },
          },
        },
        "& .main-wrapper": {
          padding:"0 90px",
          [theme.breakpoints.down("xs")]: {
            padding:"0 30px",
          },
          "& .top-main-wrapper": {
            display: "flex",
            flexDirection: "column",
            "& .title-section": {
              display: "flex",
              flexDirection: "column",
              padding: "67px 0px 0",
              "& .choose-item-txt": {
                color: "var(--brand-color-brand-color-dark, #2B2B2B)",
                fontFamily: "Expletus Sans",
                fontSize: "22px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "26px",
              },
              "& .choose-item-sub-txt": {
                color: "#505050",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.25px",
                marginTop: "16px",
              },
            },
            "& .filter-section": {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "16px",
              borderBottom: "1px solid #E8ECF2",
              "& .left-box": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                // paddingLeft: "184px",
                paddingRight: "20px",
                // width: "580px",
                // borderRight: "1px solid #E8ECF2",
  
                "& .filter-txt": {
                  color: "#505050",
                  fontFamily: "Expletus Sans",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                  letterSpacing: "0.25px",
                },
                "& .clear-all-btn": {
                  color: "var(--brand-color-primary-100, #4BA3B7)",
                  textAlign: "right",
                  fontFamily: "Expletus Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  textTransform: "none",
                  whiteSpace: "nowrap",
                },
              },
              "& .right-box": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                // width: "calc(100vw - 580px)",
                paddingRight: "12px",
  
                "& .check-box": {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
  
                  "& .chk-select-all": {
                    color: "#94A3B8",
                    width: "24px",
                    height: "24px",
                    marginRight: "6px",
                  },
                  "& .selected-txt": {
                    color: "rgba(0, 0, 0, 0.60)",
                    fontFamily: "Expletus Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                    letterSpacing: "0.1px",
                    whiteSpace: "nowrap",
                  },
                },
                "& .search-sort-box": {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  "& .form-control": {
                    marginBottom: 0,
                    "& .MuiTextField-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      height: "48px",
                      marginRight: "68px",
                    },
                  },
                  "& .top-right": {
                    "& .select-control": {                    
                      "& .MuiInput-underline:before": {
                        display: "none",
                      },
                      "& .MuiInput-underline:after": {
                        display: "none",
                      },
                      "& .MuiInputBase-root": {
                        color:"rgb(109 106 106 / 87%)",
                        fontFamily: "Expletus Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "24px",
                        "& fieldset": {
                          border: "none",
                        },
                      },
                    },
                  },
                },
                "& .select-control": {
                  "&.multi-select": {
                    "& .MuiInput-formControl": {
                      marginTop: "0",
                    },
                    "& .multiselect-label": {
                      display: "none",
                    },
                    "& .MuiSelect-select": {
                      paddingRight: "40px",
                    },
                    "& .multiple-clear": {
                      position: "absolute",
                      right: "18px",
                      fontSize: "15px",
                      zIndex: 1,
                      top: "3px",
                      color: "#94A3B8",
                    },
                  },
                  "& .MuiSelect-select": {
                    letterSpacing: "0.1px",
                    fontSize: "14px",
                    color: "#2b2b2b",
                    opacity: "0.671",
                    fontFamily: "Expletus Sans",
                    paddingBottom: "0",
                    fontWeight: "500",
                    paddingTop: "0",
                    paddingRight: "2px",
                    "&:focus": {
                      backgroundColor: "transparent",
                    },
                    "& .clear-icon": {
                      height: "50px",
                      background: "red",
                      width: "50px",
                    },
                    "& .MuiBox-root": {
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      "& .select-icon": {
                        marginRight: "6px",
                      },
                    },
                  },
                  "& .MuiInput-underline:after": {
                    display: "none",
                  },
                  "& .MuiInput-underline:before": {
                    display: "none",
                  },
                },
              },
            },
          },
          "& .main-content-wrapper": {
            display: "flex",
            flexDirection: "row",
            "& .left-wrapper": {
              display: "flex",
              flexDirection: "column",
              // width: "580px",
              // height: "50vh",           
  
              "& .section-header": {
                display: "block",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottom: "1px solid #E8ECF2",
                marginBottom:"30px",
                  "& .select-control": {
                    "& .MuiSelect-select": {
                      paddingRight: "0",
                      "& .down-arrow": {
                        color: "rgba(43, 43, 43, 0.67)",
                      },
                    },
                  },
                  "& .MuiAccordion-root": {
                    borderRadius: "0",
                    boxShadow: "none",
                    // margin: "0 0 10px",
                    "&:before": {
                      display: "none",
                    },
                    "& .MuiAccordionSummary-root": {
                      marginBottom: "28px",
                      padding: "0",
                      minHeight: "unset",
                      [theme.breakpoints.down("sm")]: {
                        // marginBottom: "16px",
                      },
                      "& .MuiAccordionSummary-content": {
                        margin: 0,
                      },
                      "& .MuiIconButton-root": {
                        marginRight: "0",
                        padding: "0",
                        "& .MuiSvgIcon-root": {
                          width: "20px",
                          height: "20px",
                          fontSize: "20px",
                          color: "#94A3B8",
                        },
                      },
                    },
                    "& .MuiAccordionDetails-root": {
                      flexDirection: "column",
                      padding: "0",
                      display: "flex",
                    },
                    "& .form-control":{
                      margin: "-16px 0px 8px 0",
                    }
                  },               
                "& .section-title": {
                  color: "var(--gray, rgba(43, 43, 43, 0.87))",
                  fontFamily: "Expletus Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                },
                "& .hide-content-section-btn": {
                  margin: 0,
                  minWidth: 24,
                },
              },
              "& .form-control": {
                "& .MuiTextField-root": {
                  "& fieldset": { border: "none" },
                  borderBottom: "1px solid #E8ECF2",
                  height: "48px",
                },
              },
              "& .wrap-items": {
                "& .product-item": {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  // marginBottom: "8px",
  
                  "& .chk-item": {
                    color: "#94A3B8",
                    marginRight: "8px",
                  },
                  "& .item-txt": {
                    color: " #2B2B2B",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px" /* 171.429% */,
                    letterSpacing: "0.25px",
                  },
                },
              },
              "& .wrap-expand": {
                paddingLeft: "18px",
                "& .expand-list-btn": {
                  textTransform: "none",
                  color: "var(--brand-color-primary-100, #4BA3B7)",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "0.25px",
                },
              },
            },
            "& .right-wrapper": {
              display: "flex",
              flexDirection: "row",
              columns: 3,
              width: "calc(100vw - 580px)",
              height: "50vh",
              flex: 1,
              padding: "10px 0px 0px 0px",
              "& .grid-container":{            
              "& .left-side-info":{
                paddingTop:"35px",
                borderRight: "1px solid #E8ECF2",
                "& .none":{
                  display:"none"
                }
              },
              "& .right-side-info":{
                paddingLeft:"35px",
                paddingTop:"40px",
              }
              },
  
              "& .product-list-item": {
                display: "flex",
                flexDirection: "column",
                flex: 1,
                border: "1px solid #E8ECF2",
                borderRadius: "12px",
                padding: "16px",
                minHeight: "203px",
  
                "& .item-top": {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  "& .check-section":{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& .instock-quantity":{
                      color: 'var(--Gray, rgba(43, 43, 43, 0.87))',
                      fontFamily: 'Roboto',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '16px',
                      letterSpacing: '0.09px',
                      background: '#F2F8FC',
                      borderRadius: '6px',
                      padding: '2px 8px',
                      whiteSpace: "nowrap",
                    },
                  },
                  "& .left-top": {
                    display: "flex",
                    flexDirection: "column",
                    
                    "& .product-img": {
                      height: "68px",
                      width: "68px",
                      marginBottom: "25px",
                    },
                
                    "& .wrap-under-img": {
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      "& .dot-img": {
                        width: "6px",
                        height: "6px",
                        margin: "0 6px",
                      },
                      "& .star-icon": {
                        width: "12px",
                        height: "11.4px",
                        marginLeft: "6px",
                      },
                    },
                  },
  
                  "& .right-top-chk": {
                    color: "#94A3B8",
                  },
                },
                "& .item-bottom-info": {
                  marginTop: "25px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
  
                  "& .wrap-name": {
                    flexDirection: "column",
                    display: "flex",
                    "& .dot-section":{
                     display:"flex",
                     alignItems:"center",
                     "& .product-info":{
                       fontFamily: "Roboto",
                       color: "rgba(0, 0, 0, 0.38)",
                      fontSize: "12px",
                      fontWeight: "400",
                      fontStyle: "normal",
                      letterSpacing: "0.4px",
                      lineHeight: "normal",
                     },
                     "& .product-dot-info":{
                      color: "rgba(0, 0, 0, 0.38)",
                      fontSize: "12px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      letterSpacing: "0.4px",
                      marginLeft:"6px",
                      "&:before": {
                        content: '""',
                        width: "8px",
                        borderRadius: "100%",
                        height: "8px",
                        display: "inline-block",
                        background: "#ECF1F4",
                        marginRight: "8px",
                      },
                     }
                    },
                    "& .product-name-txt": {
                      fontFamily: "Roboto",
                      color: "var(--gray, rgba(43, 43, 43, 0.87))",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      margin:"6px 0"
                    },
                    "& .product-additional-info": {
                      color: "rgba(0, 0, 0, 0.38)",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      letterSpacing: "0.4px",
                    },
                  },
                  "& .wrap-price": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    "& .price-btn": {
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      textTransform: "none",
                      marginTop:"20px",
                      "& .retail-price-txt": {
                        color: "rgba(0, 0, 0, 0.38)",
                        fontFamily: "Roboto",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                        letterSpacing: "0.4px",
                        whiteSpace:"nowrap",
                        display: "flex",            
                        alignItems: "center",
                      },
                      "& .icon-down": {
                        width: "24px",
                        height: "24px",
                        color: "#94A3B8",
                      },
                    },
                    "& .price-txt": {
                      color: "var(--gray, rgba(43, 43, 43, 0.87))",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px" /* 150% */,
                      letterSpacing: "0.15px,",
                      marginRight:"8px",
                    },
                  },
                },
              },
            },
          },
        },
      },
      productsFormControl : {
        marginBottom: "4px",
        display:"block",
        [theme.breakpoints.down("sm")]:{
          marginBottom: "2px",
        },
        "& .MuiSelect-select": {
          fontSize: "12px",
          letterSpacing: "0.4px",
          lineHeight: "16px",
          color: "rgba(0, 0, 0, 0.38)",
          fontFamily: "Roboto",
          fontWeight: 400,
          paddingBottom: "0",
          paddingTop: "0",
          [theme.breakpoints.down("sm")]:{
            lineHeight: "14px",
            fontSize: "10px",
            color: "#94A3B8",
            letterSpacing: "0",
          },
          "&:focus": {
            backgroundColor: "transparent"
          },        
        },
        "& .MuiSelect-icon": {
          width: "20px",
          color: "rgba(0, 0, 0, 0.38)",
        }
      },
      productItemsSection: {
        border: "1px solid #E8ECF2",      
        borderRadius: "12px",
        boxShadow: "none",
        background: "#FFF",
        height: "100%",
        padding: "24px",
        [theme.breakpoints.down("sm")]: {
          padding: "35px 16px 16px",
        },     
        "&.inventory-checkbox":{
          "& .info-box-right":{
            display:"flex",
            marginLeft: "10px",
            alignItems:"center",
            [theme.breakpoints.down("sm")]: {
              position: "absolute",
              right: "0",
              left: "0",
              marginLeft: "0",
              top: "-19px",
              justifyContent: "space-between",
            },
            "& .in-stock-badge":{
              padding:"2px 8px",
              marginRight:"12px",
              backgroundColor:"#F2F8FC",
              textTransform:"capitalize",
              borderRadius:"6px",
              color:"rgba(43, 43, 43, 0.87)",
              lineHeight: "16px",
              letterSpacing: "0.09px",
              fontSize:"12px",
              fontFamily: "Roboto",
              [theme.breakpoints.down("sm")]: {
                borderRadius: "31px",
                fontFamily: "Expletus Sans",
                background: "#F2F8FC",
                fontSize: "10px",
                lineHeight: "14px",
                fontWeight: "600",
                marginRight: "0",
                letterSpacing: "0",
                minWidth: "20px",
                padding: "3px",
                display: "flex",
                height: "20px",
                justifyContent: "center",
                alignItems: "center",
              },
              "& .badge-name": {
                [theme.breakpoints.down("sm")]: {
                  display: "none",
                }
              }
            },
            "& .MuiCheckbox-root":{
              padding:"0",
              marginTop:"0",
              marginLeft: "auto",
            }
          },
        },
        "& .MuiCardContent-root": {
          borderRadius: "12px",
          padding: "40px",
          position: "relative",
          border: "1px solid #E8ECF2",
          [theme.breakpoints.down("md")]: {
            padding: "26px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "0",
            border: "none",
            alignItems: "center",
            display: "flex",
          },
          "&:hover": {
            boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
            border: "1px solid #12AEF1",
            cursor: "pointer",
            [theme.breakpoints.down("xs")]: {
              boxShadow: "unset",
              border: "none",
            },
            "& .button-wrapper": {
              display: "block",
              [theme.breakpoints.down("xs")]: {
                display: "none",
              },
            },
          },
          "& .MuiButton-root": {
            marginBottom: "8px",
            marginRight: 0,
            boxShadow: "none",
            borderRadius: "62px",
            width: "100%",
            marginLeft: 0,
            height: "48px",
            "&:last-child": {
              marginBottom: 0,
            },
          },
        },
        "& .itemDesc": {
          justifyContent: "space-between",
          display: "flex",
          fontSize: "12px",
          color: "rgba(0, 0, 0, 0.38)",
          lineHeight: "16px",
          fontFamily: "Roboto",
          letterSpacing: "0.4px",
          fontWeight: 400,
          [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
            color: "#94A3B8",
            letterSpacing: "0.125px",
            lineHeight: "17px",
          },
          "& .desc-left": {
            marginRight: "10px",
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            "& .unit": {
              fontSize: '12px',
            fontFamily: "Roboto",
            lineHeight:'24px',
            letterSpacing: "0.15px",
            color:'#2B2B2B',
            fontWeight: 400,
            },
          },
          "& .task-about": {
            paddingLeft: "14px",
            position: "relative",
            marginLeft: "8px",
            "& .dot": {
              content: '""',
              height: "6px",
              width: "6px",
              borderRadius: "100%",
              display: "inline-block",
              background: "#E8ECF2",
              position: "absolute",
              top: "5px",
              left: "0",
            },
          },
          "& .timing": {
            fontFamily: "Roboto",
            color: "#2B2B2B",
            fontSize: "10px",
            lineHeight: "17px",
            fontWeight: 400,
            letterSpacing: "0.125px",
          },
        },
        "& .ratingBox":{
          alignItems: 'center',
          display: 'flex',
          marginBottom: "5px",
          "& .ratingText":{
            color: "rgba(0, 0, 0, 0.38)",
            lineHeight: "16px",
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: "Roboto",
            marginRight: "6px",
            letterSpacing: "0.4px",
            [theme.breakpoints.down("sm")]: {
              color: "#94A3B8",
              lineHeight: "17px",
              fontSize: "10px",
              letterSpacing: "0.125px",
            },
          },
          "& .ratingImages":{
            width: "6px",
            marginRight: "6px",
            height: "6px",
            [theme.breakpoints.down("sm")]: {
              height: "4px",
              width: "4px",
            }
          },
          "& .starImg":{
            width: "12px",
            [theme.breakpoints.down("sm")]: {
              width: "10px",
            }
          },
        },        
        "& .infoBox":{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: "24px",
          [theme.breakpoints.down("sm")]:{
            marginBottom: "27px",
            position: "relative",
            flexDirection: "column",
          },         
          "&:last-child": {
            marginBottom: "0",
          },
          "& .infoBoxLeft": {
            [theme.breakpoints.down("sm")]:{
              width: "100%",
              marginBottom: "11px",
            }, 
            "& .unit": {
              fontSize: '12px',
            fontFamily: "Roboto",
            lineHeight:'24px',
            letterSpacing: "0.15px",
            color:'#2B2B2B',
            fontWeight: 400,
            },
          },
          "& .infoBoxRight": {
            marginLeft: "8px",
            textAlign: "right",
            [theme.breakpoints.down("sm")]:{
              textAlign: "left",
              marginLeft: "0",
            },
          },
          "& .categoryImg":{
            height: '68px',
            objectFit:"contain",
            width: "100%",
            maxWidth: "68px",
            [theme.breakpoints.down("sm")]:{
              height: '56px',
              maxWidth: "56px",
              margin: "0 auto",
            },
          },
          "& .infoText":{
            color:'rgba(0, 0, 0, 0.38)',
            fontSize: '12px',
            fontWeight: 400,
            letterSpacing: "0.4px",
            fontFamily: "Roboto",
            lineHeight: "16px",
            [theme.breakpoints.down("sm")]:{
              lineHeight:'17px',
              fontSize: '10px',
              letterSpacing: "0.125px",
              color:'#94A3B8',
            },
          },
          "& .itemTitle":{
            fontSize: '16px',
            fontFamily: "Roboto",
            lineHeight:'24px',
            letterSpacing: "0.15px",
            color:'rgba(43, 43, 43, 0.87)',
            marginBottom: "4px",
            fontWeight: 500,
            [theme.breakpoints.down("sm")]:{
              lineHeight:'19px',
              fontSize: '14px',
              fontFamily: "Expletus Sans",
              letterSpacing: "0",
              color:'#2B2B2B',
              fontWeight: 400,
            },
          },
          "& .itemHeading": {           
            color:'rgba(0, 0, 0, 0.38)',
            fontSize: '12px',
            fontWeight: 400,
            letterSpacing: "0.4px",
            lineHeight: "16px",
            marginBottom: "4px",
            fontFamily: "Roboto",
            [theme.breakpoints.down("sm")]:{
              lineHeight:'14px',
              fontSize: '10px',
              letterSpacing: "0",
              color:'#94A3B8',
            },
          },
          "& .itemPrice":{
            lineHeight:'24px',
            fontSize: '16px',
            fontFamily: "Roboto",
            color:'rgba(43, 43, 43, 0.87)',
            letterSpacing: "0.15px",
            fontWeight: 400,
            [theme.breakpoints.down("sm")]:{
              fontSize: "14px",
              letterSpacing: "0",
              lineHeight: "19px",
              fontFamily: "Expletus Sans",
              color: "#2B2B2B",
              fontWeight: 500,
            },
          },
        },
        "& .titleBox":{
          marginBottom: "5px",
        },                     
      },
      productListPopoverStyle: {
        display: "inline-flex",
        height: "auto",
        width: "500px",
        marginLeft:"-160px",
        flexDirection: "column",
        flexShrink: 0,
        padding: "20px",
        borderRadius: "24px",
        background: "var(--white, #FFF)",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
        marginTop:"68px",
        maxHeight: "calc(100% - 90px)",
        "& .view-selected-item-dropdown-list":{
          "& .headding":{
            display:"flex",
            justifyContent:"space-between",
            alignItems:"center",
            "& .headding-txt":{
              color: 'var(--Task-Completed-Color, #2B2B2B)',
              fontFamily: 'Expletus Sans',
              fontSize: '22px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '26px',
            }
          },
          "& .sub-headding-txt":{
            color: '#2B2B2B',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '17px',
            marginTop:"8px",
          },
          "& .product-headding": {
            color: "#94A3B8",
            fontFamily: "Roboto",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px",
            letterSpacing: "0.15px,",
            textTransform:"uppercase",
            borderBottom: "1px solid #E8ECF2",
            margin: "25px 0",
            paddingBottom:"10px"
          },
            "& .product-container":{
              padding:"24px 16px",
              borderBottom: "1px solid #E8ECF2",
              display:"flex",
              justifyContent:"space-between",
              alignItems:"center",
              "& .product-image-container":{
                display:"flex",
                justifyContent:"space-between",
                alignItems:"center",
                width:"170px",
              },
             "& .product-img":{
              width:"56px",
              height:"56px",
             },
             "& .delete-content":{
              "& .delete-img":{
                width:"24px",
                height:"24px",
              },
             },
             "& .product-content":{
              "& .product-name": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "24px" /* 150% */,
                letterSpacing: "0.15px,",
              },
              "& .product-item": {
                color: "#94A3B8",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "0.4px",
                margin:"6px 0",
              },
             },
             "& .product-add-remove":{
              display:"flex",
              justifyContent:"space-between",
              alignItems:"center",
              marginTop:"-32px",
              "& .secondary-btn": {
                width:"32px",
                height:"32px"
              },
              "& .product-count":{
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "24px" /* 150% */,
                letterSpacing: "0.15px,",
                margin:"0 8px",
              }
             },
             "& .wrap-price": {
              display: "flex",
              // flexDirection: "column",
              alignItems: "flex-end",
              "& .price-btn": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                textTransform: "none",
                "& .select-icon":{
                  width:"20px"
                },
                "& .retail-price-txt": {
                  color: "#94A3B8",
                  fontFamily: "Roboto",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "0.4px",
                  whiteSpace:"nowrap",
                  display: "flex",            
                  alignItems: "center",
                },
                "& .icon-down": {
                  width: "24px",
                  height: "24px",
                  color: "#94A3B8",
                },
              },
              "& .price-txt": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "24px" /* 150% */,
                letterSpacing: "0.15px,",
              },
            },
            }
        }
      },
      productListViewModal: {
        height: "100vh",
        width: "680px",
        backgroundColor: "#ffffff",
        overflow: "auto",
        position: "relative",
        padding:" 45px 60px",
        "& .form-row": {
          display: "flex",
          flexWrap: "wrap",
          margin: "0 -8px",
          "& .form-col": {
            padding: "0 8px",
            flex: "0 0 100%",
            maxWidth: "100%",
            "&.col6": {
              flex: "0 0 50%",
              maxWidth: "50%",
              [theme.breakpoints.down("xs")]: {
                flex: "0 0 100%",
                maxWidth: "100%",
              },
            },
            "& .form-control": {
              marginBottom: "16px",
            },
            "& .autocomplete-wrapper": {
              marginBottom: "16px",
            },
          },
        },       
        "& .select-outer": {
          width: "100%",
          marginBottom: "16px",
          "& .error-select":{
            color:'#C7263E ',  
          },
          "& svg":{
           fill:'#94A3B8 ',  
         },
          "& .Mui-error":{
            "& .MuiOutlinedInput-notchedOutline":{
              borderColor:'#C7263E',  
            }
          },       
          "& .MuiInputLabel-formControl": {
            fontSize: "14px",
            fontFamily: "Expletus Sans",
            letterSpacing: "0.1px",
            fontWeight: 500,
            lineHeight: "19px",
            width: "calc(100% - 46px)",
            color: "#94A3B8",
            display: "flex",
            "&.MuiFormLabel-filled": {
              width: "auto",
              color : "rgba(43,43,43,0.87)",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.0075em",
              fontFamily: "Roboto",
              fontWeight: "400",
            }
          },
          "& .MuiSelect-select": {
            letterSpacing: "0",
            color: "#000000",
            fontSize: "14px",
            opacity: "0.671",
            fontWeight: "400",
            justifyContent:'space-between',
            fontFamily: "Roboto",
            display:'flex',
            height:"27px",
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2 ",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px ",
              },
            },
          },
          "& .MuiFormLabel-root": {
            "&.Mui-focused": {
              color : "rgba(43,43,43,0.87)",
              width: "auto",
              fontSize: "12px",
              letterSpacing: "0.0075em",
              fontWeight: "400",
              lineHeight: "16px",
              fontFamily: "Roboto",
            },
            "& .MuiInputLabel-asterisk": {
              marginLeft: "auto",
              lineHeight: "23px",
              fontSize: "18px",
              color: "#e86577",
            }
          }
        },  
        "& .form-control": {
          marginBottom: "20px",
          "& .MuiInputAdornment-root": {
            "& .MuiSvgIcon-root": {
              fill: "#94A3B8",
              fontSize: "20px",
            },
          },
          "& .MuiOutlinedInput-input": {
            fontSize: "13px",
            lineHeight: "17px",
            letterSpacing: "0",
            padding: "16px",
            "&.MuiOutlinedInput-inputAdornedStart": {
              paddingLeft: "0",
            },
          },
        },
        "& .quantity-container":{
          margin:"6px 0 40 0",
          padding:"20px",
          borderRadius:"6px",
          border: "1px solid #E8ECF2",
          "& .sub-text": {
            color: "var(--text-secondary, #94A3B8)",
            fontFamily: "Roboto",
            fontSize: "14px",
            lineHeight: "26px",
            fontStyle: "normal",
            letterSpacing: "0.105px",
            fontWeight: "400",
          },
        },
        "& .product-headding": {
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontSize: "10px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "24px",
          letterSpacing: "0.15px,",
          textTransform:"uppercase",
          borderBottom: "1px solid #E8ECF2",
          margin: "25px 0",
          paddingBottom:"10px"
        },
        "& .primary-btn":{
         width:"100%",
        },       
        "& .modal-heading": {
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "row",
          marginTop: "20px",
          justifyContent: "space-between",
          marginBottom: "48px",        
          "& .title-txt": {
            color: 'rgba(43, 43, 43, 0.87)',
            fontSize: '24px',
            fontFamily: 'Expletus Sans',
            fontStyle: 'normal',
            lineHeight: 'normal',         
            fontWeight: 400,
          },
          "& .heading-left": {
            "& .modal-title": {
              color: "var(--gray, rgba(43, 43, 43, 0.87))",
              fontFamily: "Expletus Sans",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "30px",
            },
            "& .sub-txt": {
              color: "var(--text-secondary, #94A3B8)",
              fontFamily: "Roboto",
              fontSize: "14px",
              lineHeight: "26px",
              fontStyle: "normal",
              letterSpacing: "0.105px",
              fontWeight: "400",
            },
          },
          "& .heading-right": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "& .heading-icon":{
              marginLeft: "12px",
              "& .pencil-icon": {
                width: "24px",
                height: "24px",
                color: "#94A3B8",
              },
              "& .close-icon": {
                width: "24px",
                height: "24px",
                color: "#94A3B8",
              },
            },
          },
        },
        "& .relative-width-remove-border":{ 
          borderBottom: "none",
          display:"flex",
          position:"relative",
          flexDirection: "column",
          "& .product-card":{
            display:"flex",
            justifyContent:"space-between",
            alignItems: "center",
            // borderBottom: "1px solid #E8ECF2",
            marginBottom:"24px",
            width:"200px",
            "& .product-img":{
              width:"56px",
              height:"56px",
             },
             "& .card-content":{
               "& .headding":{
                color: 'var(--Brand-Color-Brand-Color-Dark, #2B2B2B)',
                fontFamily: 'Expletus Sans',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal'
               },            
               "& .retail-content":{
                display:"flex",
                marginTop:"8px",
                marginBottom:"16px",
                flexDirection:"column",
                "& .item-quantity":{
                  color: 'var(--Gray, rgba(43, 43, 43, 0.87))',
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  marginRight:"4px",
                },
                "& .retail-price-txt":{
                  color: 'var(--text-Secondary, #94A3B8)',
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  display: "flex",            
                  alignItems: "center",
                },
               },
             },
             "& .price-txt":{
              position:"absolute",
              right:"0",
              top:"0px",
              "& .price":{
                color: 'var(--Brand-Color-Brand-Color-Dark, #2B2B2B)',
                fontFamily: 'Expletus Sans',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal'
              },
             },
          }
        },
        "& .relative-width":{
          display:"flex",
          borderBottom: "1px solid #E8ECF2",
          position:"relative",
          flexDirection: "column",
          "& .product-card":{
            display:"flex",
            justifyContent:"space-between",
            alignItems: "center",
            // borderBottom: "1px solid #E8ECF2",
            marginBottom:"24px",
            width:"200px",
            "& .product-img":{
              width:"56px",
              height:"56px",
             },
             "& .card-content":{
               "& .headding":{
                color: 'var(--Brand-Color-Brand-Color-Dark, #2B2B2B)',
                fontFamily: 'Expletus Sans',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal'
               },            
               "& .retail-content":{
                display:"flex",
                marginTop:"8px",
                marginBottom:"16px",
                flexDirection:"column",
                "& .item-quantity":{
                  color: 'var(--Gray, rgba(43, 43, 43, 0.87))',
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  marginRight:"4px",
                },
                "& .retail-price-txt":{
                  color: 'var(--text-Secondary, #94A3B8)',
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  display: "flex",            
                  alignItems: "center",
                },
               },
             },
             "& .price-txt":{
              position:"absolute",
              right:"0",
              top:"0px",
              "& .price":{
                color: 'var(--Brand-Color-Brand-Color-Dark, #2B2B2B)',
                fontFamily: 'Expletus Sans',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal'
              },
             },
          }
        }
      },
      modalBoxCenter: {
        display: "flex",
        // alignItems: "flex-end",
        justifyContent: "flex-end",
      },
      selectFormControl : {
        marginRight: "25px",
        "&:last-child": {
          marginRight: "0",
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          color: "rgba(43, 43, 43, 0.87)",
          opacity: "0.671",
          fontWeight: "500",
          fontFamily: "Expletus Sans !important",
          paddingBottom: "0",
          paddingTop: "0",
          "&:focus": {
            backgroundColor: "transparent"
          },        
        },
        "& .MuiSelect-icon": {
          color: "rgba(43, 43, 43, 0.87)",
          opacity: "0.671",
        }
      },
      radioChoose: {
        width: "100%",
        padding: "15px 5px",
        borderBottom: "1px solid #E8ECF2",
        marginBottom:"20px",
        "&.error-show": {
          "& .MuiRadio-colorSecondary ~ span": {
            border: "1px solid #C7263E",
            color: "#C7263E"
          }
        },
        "& .MuiFormControlLabel-root": {
          marginRight: "0",
          marginLeft: "-7px",
        },
        "& .MuiFormControlLabel-label": {
          fontSize: "14px",
          lineHeight: "20px",
          color: "rgba(43, 43, 43, 0.87)",
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          letterSpacing: "0.1px",
        },
        "& .MuiRadio-root": {
          "&.Mui-checked": {
            color: "#4ba3b7",
          },
          "& .MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
          }        
        },
        "& .MuiFormHelperText-root": {
          fontSize: "12px",
          letterSpacing: "0.0075em",
          color: "#00BA88",
          fontFamily: "Roboto",
          fontWeight: "400",
          marginLeft: "14px",
          marginRight: "14px",
          "&.Mui-error": {
            color: "#C7263E"
          },
          "&.Mui-default": {
            color: "#2B2B2B"
          }
        }
      },
  });
// Customizable Area End
