import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Button,
  InputAdornment,
  FormHelperText,
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme
} from "@material-ui/core/styles";
import CustomInput from "../../../components/src/CustomInput.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";

import ToolsAdditionalInfoController, {
  Props,
  configJSON
} from "./ToolsAdditionalInfoController.web";
// import { customStyles } from "../../../components/src/Inventory/ToolsAdditionalInfoStyles.web";
import { customStyles } from "../../../components/src/AddItemStyles.web";
// Customizable Area End

export class ToolsAdditionalInfo extends ToolsAdditionalInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, seletedItem} = this.props;
    return (
        <Box className={classes.basicInfoSection}>
        <Box className="heading-box">
          <Box>
            <Typography className={classes.heading} variant="h1">
              {this.t(`${configJSON.additionalinfoTitle}`)}
            </Typography>
            <Typography className={classes.subtitleText}>
             {this.t(`${configJSON.additionalinfoTitleSubtext}`)} {seletedItem.toLowerCase()}.
            </Typography>
          </Box>
          <Box className="item-box" data-testId="itemText">
            {seletedItem}
          </Box>
        </Box>
        <Grid className={classes.formSection} container alignItems="center" justifyContent="center">
          {seletedItem === configJSON.hardwareText ? 
          <>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <CustomInput
              value={this.state.description}
              onChange={(event) => this.handleDescription(event)}
              placeholder={this.t(`${configJSON.descriptionPlaceholder}`)}
              data-testId={'toolsDescription'}
              isMultiline={true}
              rows={5}
            />
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <Grid item md={12} lg={12} sm={12} xs={12}>
              <CustomInput
                value={this.state.stockQunatity}
                onChange={ this.handleOnChange}
                placeholder={this.t(`${configJSON.stockQuanPlaceholder}`)}
                data-testId={'stockFeild'}
                errors={this.state.error.stockQunatity}
                name="stockQunatity"
              />
            </Grid>
            <Grid item md={12} lg={12} sm={12} xs={12}>
              <CustomInput
                placeholder={this.t(`${configJSON.serialPlaceholder}`)}
                value={this.state.serialNo}
                onChange={(event) => this.handleSerialNo(event)}
                data-testId={'serialNo-field'}
                errors={this.state.errorSerialNo}
              />
            </Grid>
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12} className={classes.rowSpacing}>
            <CustomDatePicker data-testId="revisionDate"
              currentDate={this.state.revisionDate}
              onChange={this.handleDate}
              onOpenPickNewDate={false}
              placeholder={this.t(`${configJSON.revisionPlaceholder}`)}
              minDate={new Date()}
            />
          </Grid>
          </>
          :
          <>
            <Grid item md={12} lg={12} sm={12} xs={12} className={classes.withHelperText}>
              <CustomInput
                value={this.state.machineCost}
                name="machineCost"
                onChange={this.handleOnChange}
                placeholder={this.t(`${configJSON.hoursPlaceholder}`)}
                data-testId={'machine-cost'}
                startAdornment={
                    <InputAdornment position="start">
                      {configJSON.dollarSymbol}
                    </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end" className={classes.endText}>
                   {this.t(`${configJSON.perHourText}`)}
                  </InputAdornment>
                }
              />
              <FormHelperText className={classes.helperText}>
                {this.t(`${configJSON.helperText}`)}
              </FormHelperText>
            </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <CustomInput data-testId={'stockFeild'}
              value={this.state.stockQunatity}
              onChange={this.handleOnChange}
              placeholder={this.t(`${configJSON.stockQuanPlaceholder}`)}
              errors={this.state.error.stockQunatity}
              name="stockQunatity"
            />
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <CustomInput
              value={this.state.serialNo}
              onChange={(event) => this.handleSerialNo(event)}
              placeholder={this.t(`${configJSON.serialPlaceholder}`)}
              data-testId={'serialNo-field'}
              errors={this.state.errorSerialNo}
            />
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12} className={classes.rowSpacing}>
            <CustomDatePicker
              currentDate={this.state.revisionDate}
              onChange={this.handleDate}
              onOpenPickNewDate={false}
              placeholder={this.t(`${configJSON.revisionPlaceholder}`)}
              data-testId="revisionDate"
              minDate={new Date()}
            />
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12} className={classes.dropdownSpacing}>
            <CustomInput
              value={this.state.description}
              onChange={(event) => this.handleDescription(event)}
              placeholder={this.t(`${configJSON.descriptionPlaceholder}`)}
              data-testId={'toolsDescription'}
              isMultiline={true}
              rows={5}
            />
          </Grid>
          </>
        }      
        </Grid>
        <Box className={classes.formButtons}>
          <Button className="backBtn" onClick={this.goBackToPreviousStep}
            data-testId={"back-btn"}
          >
            {this.t(`${configJSON.backButton}`)}
          </Button>
          <Button className="next-step-btn" 
            onClick={this.createCatalogueItems}
            data-testId={"next-btn"}
          >
            {this.t(`${configJSON.nextButton}`)}
          </Button>
        </Box>
      </Box>
      )
    }
    // Customizable Area End
}

// Customizable Area Start

export default withStyles(customStyles, { withTheme: true })(ToolsAdditionalInfo);
// Customizable Area End