import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
import {
  createCommonToastError,
  createCommonToastNotification,
} from "../../../components/src/ReusableFunctions";
import { checkBoxFilled } from "./assets";
const baseConfig = require("../../../framework/src/config");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  assigneeTabsValue: number;
  sortAssignee: string;
  openHoursDetail: boolean;
  showOnlyTeams: boolean;
  setPage: number;
  setRowsPerPage: number;
  isDeleteActive: boolean;
  isLoading: boolean;
  viewTimesheetDetails: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class DownloadTimesheetController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  deleteSingleTimesheetAPI: string = "";
  getSingleTimesheetAPI: string = "";
  downloadTimesheetAPI: string = "";
  userSessionData: any;
  userToken: any;
  timesheetId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.timesheetId = this.getLastPartOfURL();
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      openHoursDetail: false,
      assigneeTabsValue: 0,
      sortAssignee: "",
      showOnlyTeams: false,
      setPage: 0,
      setRowsPerPage: 2,
      isDeleteActive: false,
      isLoading: false,
      viewTimesheetDetails: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorResponses = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     

      if (errorResponses || !responseJson || !apiRequestCallId) {
        return;
      }
      if(apiRequestCallId === this.getSingleTimesheetAPI ){
        this.handleViewTimesheetResponse(responseJson)
        
      }else if(apiRequestCallId === this.deleteSingleTimesheetAPI) {
        if (responseJson.success) {
          createCommonToastNotification("Timesheet deleted", checkBoxFilled);
          this.navigateToTimesheet();
        }

      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const lang = localStorage.getItem("lang") || "en";
    await (i18n as any).changeLanguage(lang);
    this.getTimesheetDetails(this.timesheetId);
  }

  t(key: any) {
    return (i18n as any).t(key, { ns: "translation" });
  }

  getLastPartOfURL = () => {
    let url = window.location.pathname;
    let parts = url.split("/");
    let lastPart = parts[parts.length - 1];
    return lastPart;
  };
  setAssigneeTabsValue = (obj: any, val: any) => {
    this.setState({
      assigneeTabsValue: val,
    });
  };
  openHoursModal = () => {
    this.setState({
      openHoursDetail: true,
    });
  };
  closeHoursModal = () => {
    this.setState({
      openHoursDetail: false,
    });
  };
  showTeamDetails = () => {
    this.setState({
      showOnlyTeams: true,
    });
  };

  deleteTimesheetAction = () => {
    this.setState({
      isDeleteActive: true,
    });
  };
  closeTimesheetAction = () => {
    this.setState({
      isDeleteActive: false,
    });
  };
  timesheetDeleteAPICAll = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteTimesheet + "/" + this.timesheetId
    );
    this.deleteSingleTimesheetAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  navigateToTimesheet = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TimesheetManagement"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  handleChangePage = (event: unknown, newPage: number) => {
    this.setState({
      setPage: newPage,
    });
  };
  getTimesheetDetails = (timesheetID: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTimesheetSingle + "/" + timesheetID
    );
    this.getSingleTimesheetAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({
      isLoading: true,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  downloadTimesheet = async () => {
    let filename = `Mytimesheet.csv`;
    let url =
      baseConfig.baseURL +
      configJSON.downloadTimesheet +
      `?timesheet_id=${this.timesheetId}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": configJSON.validationApiContentType,
        token: this.userToken.meta.token,
      },
    })
      .then(function (response) {
        return response.blob();
      })
      .then(function (blob) {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        createCommonToastNotification("Timesheet downloaded", checkBoxFilled);
      })
      .catch((error) => {
        createCommonToastError("error");
      });
  };
  handleViewTimesheetResponse = (responseJson: any) => {
    if (responseJson.errors) {
      createCommonToastError(responseJson.errors);
    } else {
      this.setState({
        viewTimesheetDetails: responseJson.data,
      });
      this.setState({ isLoading: false });
    }

  }
  // Customizable Area End
}
