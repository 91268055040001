// Customizable Area Start
import React from "react";
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    ModalDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
      backdropFilter:"blur(3px)",
    },
    modalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      padding: "80px 60px 50px",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("md")]: {
        width: 548,
        padding: "45px 60px"
      },
      [theme.breakpoints.down("sm")]: {
        width: 520,
        padding: "50px 40px"
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "90%",
        padding: "30px 24px"
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "7px",
      },
      "& .modal-title": {
        fontWeight: 400,
        fontSize: "24px",
        lineHeight: "32px",
        fontFamily: "Expletus Sans",
        color: "rgba(43, 43, 43, 0.87)",
        [theme.breakpoints.down("xs")]: {
          fontSize: "22px",
          lineHeight: "26px",
          color: "#2b2b2b",
        },
      },
      "& .close-icon": {
        color: "#94A3B8",
        lineHeight: "0",
        cursor: "pointer",
      },
      "& .modal-description": {
        "& .sub-txt": {
          fontSize: "14px",
          lineHeight: "24px",
          color: "#2b2b2b",
          fontWeight: 400,
          fontFamily: "Roboto",
          margin: "0 0 23px",
          letterSpacing: "0.25px",
          [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
            lineHeight: "17px",
            color: "#2b2b2b",
          },
        }
      },
      "& .categoriesInfo": {
        border: "1px solid #E8ECF2",
        borderRadius: "12px",
        marginBottom: "24px",
        padding: "16px 40px 16px 36px",
        [theme.breakpoints.down("sm")]: {
          padding: "16px 19px 8px 24px",
          marginBottom: "16px",
        },
        "& .left-content": {
          display: "flex",
          alignContent: "center",
          "& .MuiListItemIcon-root": {
            minWidth: "20px",
          },
        },
        "& .subCategoryList": {
          padding: "0",
          marginLeft: "32px",   
          [theme.breakpoints.down("xs")]: {
            marginLeft: "29px",   
          },       
          "& .subCategoryBox": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "17px 0 16px",
            borderTop: "1px solid #E8ECF2",
            [theme.breakpoints.down("xs")]: {
              padding: "23px 0 20px",
            },
            "& .subCategoryImg": {
              height: "16px"
            },
            "& .subCategoryText": {
              fontWeight: 500,
              fontSize: "14px",
              color: "rgba(43, 43, 43, 0.87)",
              fontFamily: "Expletus Sans",
              lineHeight: "20px",
              letterSpacing: "0.1px",
              marginLeft: "12px",
            },
          },
        },
        "& .categoryBox": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px 0 17px",
          [theme.breakpoints.down("xs")]: {
            padding: "9px 0 18px",
          },
          "& .categoryImg": {
            height: "20px"
          },
          "& .categoryText": {
            fontSize: "16px",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Expletus Sans",
            lineHeight: "24px",
            fontWeight: 400,
            letterSpacing: "0.15px",
            marginLeft: "12px",
          },
        },        
      },
      "& .switchStyle": {
        "& .MuiSwitch-track": {
          backgroundColor: "#94A3B8",
          opacity: 1,
        },
        "&.MuiSwitch-root": {
          [theme.breakpoints.down("xs")]: {
            width: "33px",
            height: "20px",
            padding: "4px",
          },
          "& .MuiIconButton-root": {
            [theme.breakpoints.down("xs")]: {
              padding: "6px",
            },
          },
        },
        "& .MuiSwitch-switchBase": {
          color: "#fafafa",
          [theme.breakpoints.down("xs")]: {
            color: "#ffffff",
          },
          "&.Mui-checked": {
            color: "#4BA3B7",
            [theme.breakpoints.down("xs")]: {
              transform: "translateX(12px)",
              color: "#ffffff",
            },
            "& + .MuiSwitch-track": {
              backgroundColor: "rgba(75,163,183,0.38)",
              [theme.breakpoints.down("xs")]: {
                backgroundColor: "#4BA3B7",
              },
            }
          }
        },
        "& .MuiSwitch-thumb": {
          boxShadow: "none",
          filter: "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.20)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14))",
          [theme.breakpoints.down("xs")]: {
            width: "8px",
            height: "8px",
          },
        }
      }
    }
  });