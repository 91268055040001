// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Radio,
  RadioGroup,
  Popover,
  TextField,
  Chip,
  Box,
  Tabs,
  Tab,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  Input,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  MenuItem,
  Modal,
  Button,
  Menu,
} from "@material-ui/core/";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProjectsController, { configJSON } from "./ProjectsController.web";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import CustomDatePicker from "../../../../components/src/CustomDatePicker.web";
import FreeSoloCreateOption from "../../../../components/src/FreeSoloCreateOption.web";
import { customStyles } from "../../../../components/src/TaskListStyles.web";
import { Link } from "react-router-dom";
import TaskListFilter from "./TaskListFilter.web";
import { FileDrop } from "react-file-drop";
import {
  accountPlus,
  checkBoxFilled,
  checkBoxEmpty,
  timerSand,
  closeGray,
  uploadImg,
} from "../assets";
import moment from "moment";
import { TabPanel,assigneeA11yProps  } from "./TaskList.web";
import ProjectView from "./ProjectView.web";
import WebLoader from "../../../../components/src/WebLoader.web";

class Projects extends ProjectsController {
  // constructor(props: Props) {
  //   super(props);
  // }


  getCommonCard = (proj:any,handleModalOpen:any) => {
    const attributes = proj.attributes;
    return (
      <Card key={proj.id} className={`task-card`}>
          <CardContent
            className="card-content"
            data-test-id={`hoverAction-${proj.id}`}
          >
            {/* <Box className="task-subtitle">{attributes.category}</Box> */}
            <Box className="task-title">
              <Box
                className="title"
                data-testid={`project-${proj.id}`}
                // onClick={() => this.handle_modalOpen(proj.id)}
                onClick={()=>handleModalOpen(proj.id)}
              >
                {attributes.title}
              </Box>
              {/* <Box className="title-right">
            <Box className="controller-link">
              <img src={playRound} alt="playRound" />
              <img src={pauseRound} alt="pauseRound" />
            </Box>
          </Box> */}
            </Box>
            <Box className="task-desc">
              <Box className="desc-left">
                <Box className="date" component="span">
                  {moment(attributes.start_date).format(
                    "MMM DD , YYYY"
                  )}
                </Box>
                <Box className="task-about">
                  {attributes.category?.category}
                </Box>
              </Box>
              {/* <Box className="desc-right">
            <Box className="timing" component="span">50:00</Box>
          </Box> */}
            </Box>
            <Box className="task-progress-wrapper">
              <Box className="prec-value">0%</Box>
              <Box className="progress-wrap">
                <Box
                  className="progress-inner"
                  style={{ width: "0%" }}
                ></Box>
              </Box>
              <Box className="total-value">18 / 25</Box>
            </Box>
            {/* <Box className="task-progress-wrapper">
              <Box className="prec-value">0%</Box>
              <Box className="progress-wrap">
                <Box className="progress-inner inprogress" style={{ width: "10%" }}></Box>
              </Box>
              <Box className="total-value">18 / 25</Box>
            </Box>
            <Box className="task-progress-wrapper">
              <Box className="prec-value">0%</Box>
              <Box className="progress-wrap">
                <Box className="progress-inner red" style={{ width: "10%" }}></Box>
              </Box>
              <Box className="total-value">18 / 25</Box>
            </Box> */}
            {/* <Box className="subtask-block">
              <Box className="subtask-info">
                <Box className="subtask-left">
                  <Link to="" onClick={(e:any)=>this.taskPopoverOpen(e,proj.id)} className="subtask-btn">
                    + Create Task
                  </Link>
                </Box>
                <Box className="subtask-right">
                  <Box className="assignee-list">
                    <Box className="assignee-bubble">BS</Box>
                    <Box className="assignee-bubble">LA</Box>
                    <Box className="assignee-bubble">+2</Box>
                  </Box>
                </Box>
              </Box>
            </Box> */}
          </CardContent>
      </Card>
    );
  };

  getTabData = (classes: any) => {

    return (
      <Box className="tasklist-row">
        <Box className="tasklist-col">
          <Accordion className="accordion-block" expanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="open-content"
              id="open-header"
              className="accordion-heading"
            >
              <Box className="tasklist-status open">
                {this.t(`${configJSON.txtStatusOpen}`)} ({this.getOpenProjects().length})
              </Box>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
              {this.getOpenProjects().map((proj:any) => {
                return this.getCommonCard(proj,this.handle_modalOpen);
              })}
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="tasklist-col">
          <Accordion className="accordion-block" expanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="progress-content"
              id="progress-header"
              className="accordion-heading"
            >
              <Box className="tasklist-status progress">
              {this.t(`${configJSON.onTrackTxt}`)} ({this.getonTrackProjects().length})
              </Box>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
            {this.getonTrackProjects().map((proj:any) => {
                return this.getCommonCard(proj,this.handle_modalOpen);
              })}
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="tasklist-col">
          <Accordion className="accordion-block" expanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="completed-content"
              id="completed-header"
              className="accordion-heading"
            >
              <Box className="tasklist-status offtrack">
              {this.t(`${configJSON.offTrackTxt}`)} ({this.getoffTrackProjects().length})
              </Box>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
            {this.getoffTrackProjects().map((proj:any) => {
                return this.getCommonCard(proj,this.handle_modalOpen); 
              })}
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="tasklist-col">
          <Accordion className="accordion-block" expanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="cancelled-content"
              id="cancelled-header"
              className="accordion-heading"
            >
              <Box className="tasklist-status completed">
              {this.t(`${configJSON.txtStatusCompleted}`)} ({this.getCompletedProjects().length})
              </Box>
            </AccordionSummary>
            <AccordionDetails className="accordion-details">
            {this.getCompletedProjects().map((proj:any) => {
               return this.getCommonCard(proj,this.handle_modalOpen);
              })}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    );
  };

  sortByDesign = (classes:any,onChange:any)=>{
    return (
      <FormControl className="select-control sortby-control">
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            classes: { paper: classes.dropdownStyle },
          }}
          onChange={onChange}
          displayEmpty
          defaultValue={this.t(`${configJSON.txtSortBy}`)}
          data-testid="sortTeams"
          renderValue={(value: any) => {
            return <Box>{value}</Box>;
          }}
          IconComponent={ExpandMoreIcon}
        >
          <MenuItem value="A-Z">{this.t(`${configJSON.aTozTxt}`)}</MenuItem>
          <MenuItem value="Z-A">{this.t(`${configJSON.zToaTxt}`)}</MenuItem>
        </Select>
      </FormControl>
    )
  }
  assigneeListHeader=(classes:any,selAssignee:any[])=>{
    return(
      <ListSubheader className="multiselect-membres">
          <Box className="total-member">
            {
              this.displayAssigneeOptions().filter(
                (x: any) =>
                  !selAssignee.includes(x.id)
              ).length
            }&nbsp;
            {this.t(`${configJSON.membersTxt}`)}
          </Box>
         {this.sortByDesign(classes,this.sortByAssigneeHandler)}
    </ListSubheader>
    )
  }

  changeAssigneeModal = (classes:any) => {
    return (
      <Modal
            open={this.state.isActiveChangesAssignee}
            onClose={this.assigneeChangeModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.modalDialog}
          >
            <Box className={classes.modalDialogBox}>
              <Box className="modal-dialoginner">
                <Box className="modal-heading">
                  <Typography className="modal-title" component="h2">
                    {this.state.selectedAssignees.length} {this.t(`${configJSON.txtMemberSelected}`)}
                  </Typography>
                  <Link
                    to="#"
                    className="close-icon"
                    onClick={this.assigneeChangeModalClose}
                    data-test-id="assigneeModalClose"
                  >
                    <CloseIcon />
                  </Link>
                </Box>
                <Box className="modal-description">
                  <Box  component="p" className="sub-txt">                    
                    {this.t(`${configJSON.txtAssigneeDescription}`)}
                  </Box>
                  <Box className="modal-form" component="form">
                    <List className="assignee-multiSelect-list selected-list">
                      <ListSubheader>
                        <CustomInputWeb
                          autoFocus={true}
                          placeholder={this.t(`${configJSON.txtSearchAssignees}`)}
                          fullWidth={true}
                          onChange={this.handleOnChange}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                          name="assigneeSearchText"
                          data-test-id="aassigneeSearch"
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                      <ListSubheader className="multiselect-subheader">
                      {this.t(`${configJSON.txtAssignees}`)}
                        <Link
                          to="#"
                          onClick={this.clearSelectedAssignees}
                          className="tasklist-link"
                          data-test-id="clearAssignees"
                        >
                         {this.t(`${configJSON.txtRemoveAllAssignees}`)}
                        </Link>
                      </ListSubheader>
                      <ListSubheader className="multiselect-subtxt">
                       {this.t(`${configJSON.membersTxt}`)}
                      </ListSubheader>
                      {/* <ListSubheader className="multiselect-subtxt">
                      Teams
                    </ListSubheader>    */}
                      {this.displayAssigneeOptions()
                        .filter((x: any) =>
                          this.state.selectedAssignees.includes(x.id)
                        )
                        .map((option: any) => (
                          <ListItem
                            key={option.id}
                            value={option.id}
                            onClick={() => this.changeAssigneeHandler(option.id)}
                            data-test-id={`changeAssignee-${option.id}`}
                          >
                            <Box className="assignee-detail">
                              <Box className="assignee-left">
                                <Box className="assignee-profile">
                                  {" "}
                                  {option.initials}
                                </Box>
                                <Box className="assignee-info">
                                  <Box className="assignee-name">
                                    {option.title}
                                  </Box>
                                  <Box className="assignee-email">
                                    {option.email}
                                  </Box>
                                </Box>
                              </Box>
                              <Box className="assignee-right">
                                <Box className="assinee-selected">
                                  <img
                                    className="normal-img"
                                    src={checkBoxEmpty}
                                    alt="checkBoxEmpty"
                                  />
                                  <img
                                    className="selected-img"
                                    src={checkBoxFilled}
                                    alt="checkBoxFilled"
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </ListItem>
                        ))}
                      <ListSubheader className="multiselect-subheader">
                        {this.t(`${configJSON.txtUnassigned}`)}
                      </ListSubheader>
                    </List>
                    <Box className="tasklist-tabwrapper">
                      <Box className="assignee-tabs">
                        <Tabs
                          aria-label="simple tabs example"
                          value={this.state.assigneeTabsValue}
                          onChange={this.setAssigneeTabsValue}
                          data-test-id="updateAssigneeTabs"
                        >
                          <Tab label={this.t(`${configJSON.teamsTxt}`)} {...assigneeA11yProps(0)} />
                          <Tab label={this.t(`${configJSON.membersTxt}`)} {...assigneeA11yProps(1)} />
                        </Tabs>
                      </Box>

                      <TabPanel value={this.state.assigneeTabsValue} index={0}>
                        <List className="assignee-multiSelect-list">
                          {this.assigneeListHeader(classes,this.state.selectedAssignees)}
                        </List>
                      </TabPanel>
                      <TabPanel value={this.state.assigneeTabsValue} index={1}>
                        <List className="assignee-multiSelect-list">
                          {this.assigneeListHeader(classes,this.state.selectedAssignees)}
                          {this.sortAssignees(this.displayAssigneeOptions())
                            .filter(
                              (x: any) =>
                                !this.state.selectedAssignees.includes(x.id)
                            )
                            .map((assigneeOption: any) => (
                              <ListItem
                                key={assigneeOption.id}
                                value={assigneeOption.id}
                                onClick={() => this.changeAssigneeHandler(assigneeOption.id)}
                                data-test-id={`changeAssignee-${assigneeOption.id}`}
                              >
                                <Box className="assignee-detail">
                                  <Box className="assignee-left">
                                    <Box className="assignee-profile">
                                      {assigneeOption.initials}
                                    </Box>
                                    <Box className="assignee-info">
                                      <Box className="assignee-name">
                                        {assigneeOption.title}
                                      </Box>
                                      <Box className="assignee-email">
                                        {assigneeOption.email}
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box className="assignee-right">
                                    <Box className="assinee-unselected">
                                      <img
                                        className="normal-img"
                                        src={checkBoxEmpty}
                                        alt="checkBoxEmpty"
                                      />
                                      <img
                                        alt="checkBoxFilled"
                                        src={checkBoxFilled}
                                        className="selected-img"
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </ListItem>
                            ))}
                        </List>
                      </TabPanel>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
      </Modal>
    )
    
  }




  projectTemplateModal=(classes:any)=>{
    return(
      <Modal
      open={this.state.isActiveProjectTemplate}
      onClose={this.projectTemplateModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalDialog}
      >
        <Box
          className={`${classes.modalDialogBox} project-template-dialog`}
        >
          <Box className="modal-dialoginner">
            <Box className="modal-heading">
              <Typography className="modal-title" component="h2">
                {/* {this.state.selectedAssignees.length} Member Selected */}
                {this.t(`${configJSON.chooseProjectTxt}`)}
              </Typography>
              <Link
                to="#"
                className="close-icon"
                onClick={this.projectTemplateModalClose}
              >
                <CloseIcon />
              </Link>
            </Box>
            <Box className="modal-description">
              <Box component="p" className="sub-txt">
                {this.t(`${configJSON.selectedProjectTxt}`)}
              </Box>
              <Box className="modal-form" component="form">
                <Box className="form-info-wrapper">
                  <RadioGroup
                    aria-label="templatelist"
                    name="templatelist1"
                  >
                    <List className="template-list">
                      <ListSubheader>
                        <CustomInputWeb
                          placeholder={this.t(`${configJSON.searchTypeTxt}`)}
                          autoFocus={true}
                          name="search"
                          //onChange={this.handleOnChange}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                          // onKeyDown={(e) => {
                          //   if (e.key !== "Escape") {
                          //     e.stopPropagation();
                          //   }
                          // }}
                        />
                      </ListSubheader>
                      <ListSubheader className="multiselect-membres">
                        <Box className="total-member">7 {this.t(`${configJSON.templatesTxt}`)}</Box>
                        {this.sortByDesign(classes,this.sortByAssigneeHandler)}
                      </ListSubheader>
                      {[0, 1, 2, 3].map((value) => {
                        return (
                          <ListItem
                            className="template-li"
                            key={value}
                            role={undefined}
                            onClick={this.handleTemplateGroupList(value)}
                          >
                            <Box
                              className="template-left"
                              //id={labelId} primary={`Line item ${value + 1}`}
                            >
                              <Box className="name">{this.t(`${configJSON.newWineLaunch}`)}</Box>
                              <Box className="sub-txt">
                                Created on Aug 30, 2022 11:42PM
                              </Box>
                            </Box>
                            <ListItemIcon className="template-icon">
                              {/* <Checkbox
                            className="checklist-check"
                            icon={
                              <RadioButtonUncheckedIcon/>
                            }
                            checkedIcon={
                              <RadioButtonCheckedIcon />
                            }
                            checked={this.state.chooseGroupChecked.indexOf(value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          /> */}
                              <Radio
                                checked={
                                  this.state.chooseTemplateChecked === value
                                }
                                tabIndex={-1}
                              />
                            </ListItemIcon>
                          </ListItem>
                        );
                      })}
                    </List>
                  </RadioGroup>
                </Box>
              </Box>
            </Box>
            <Box className="modal-footer">
              <Button className={`primary-btn ${classes.primaryButton}`}>
                {this.t(`${configJSON.createProjectTxt}`)}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    )
  }

  render() {
    const { classes } = this.props;

    const taskPopOverOpen = Boolean(this.state.taskPopoveAnchorEl);
    const taskPopoverId = taskPopOverOpen ? "simple-popover" : undefined;

    const tasklistPopOverOpen = Boolean(this.state.tasklistPopoveAnchorEl);
    const tasklistPopoverId = tasklistPopOverOpen
      ? "simple-popover"
      : undefined;

    return (
      <>
      {this.state.isLoading && <WebLoader />}
        <Box className={classes.tasklistInnerRoot}>
          <Box className="tasklist-top">
            <Box className="top-left">
              <Box className="search-wrapper">
                <Input
                  placeholder={this.t(`${configJSON.searchTypeTxt}`)}
                  type="search"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  name="searchProject"
                  value={this.state.searchProject}
                  onChange={this.handleOnChange}
                  data-test-id="searchProject"
                />
              </Box>
            </Box>
            <Box className="top-right">
              <TaskListFilter
                isAllProject={true}
                selectAssigneeOptions={this.state.assigneeList}
                selectPriorityOptions={this.priorityOption}
                classes={classes}
                onSelectedOptions={this.filterChangeHandler}
                clearAction={this.clearSelectionHandler}
                selectedAssignee={this.state.selectedAssigneeVal}
                selectedPriority={this.state.selectedPriorityVal}
                selectedStatus={this.state.selectedStatusVal}
                selectedSortBy={this.state.selectedSortByVal}
                dueDateRangeFilter={this.dueDateRangeFilter}
                selectedDueDate={this.state.selectedDueDateVal}
                selectedDeadlineDate={this.state.selectedDeadlineDateVal}
                deadlineDateRangeFilter={this.deadlineDateRangeFilter}
                selectedCategory={this.state.selectedCategoryVal}
                selectCategoryOptions={this.state.categoryList}
                selectedProjectLead={this.state.selectedProjectLeadVal}
                t={this.t} 
                getFilterdData={this.getProjectFilters}  
                data-test-id="task-filter"           
              />
            </Box>
          </Box>
          <Box className="tasklist-inner">{this.getTabData(classes)}</Box>
        </Box>

        <ProjectView 
            isOpen={this.state.modalOpen}
            // isCreateOpen={this.state.}
            projectClose={this.projectViewClose} 
            projectId={this.state.projectId}
            tasklistPopoverOpen={this.tasklistPopoverOpen}
            taskPopoverOpen={this.taskPopoverOpen}
            createToastNotification={this.createToastNotification}
            fetchAllProjects={this.fetchAllProjects}
            popoverHandleClose={this.popoverHandleClose}
            projectList={this.state.projectList}
            updateData={this.props.updateData}
          />


        {this.changeAssigneeModal(classes)}

        {this.projectTemplateModal(classes)}

        {/* create task block start */}
        <Popover
          id={taskPopoverId}
          open={taskPopOverOpen}
          anchorEl={this.state.taskPopoveAnchorEl}
          onClose={this.taskPopoverHandleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          classes={{ paper: `${classes.createTaskBox}` }}
        >
          <Box
            onClick={this.taskPopoverHandleClose}
            className="auth-back show-sm"
          >
            <i>
              <svg
                className="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
              </svg>
            </i>
          </Box>  
          <Box className="task-heading">
            <Box className="heading-left">
              <Typography className="modal-title" component="h2">
                {this.t(`${configJSON.txtCreateTask}`)}
              </Typography>
              <Box component="p" className="sub-txt">
               {this.t(`${configJSON.txtLetCreate}`)}                
              </Box>
            </Box>
            <Box className="heading-right">
              <Link
                to="#"
                className="heading-icon hide-sm"
                onClick={this.taskPopoverHandleClose}
              >
                <img alt="closeGray" src={closeGray}  />
              </Link>
            </Box>
          </Box>
          <Box className="task-description">
            <Box className="task-form" component="form">
              <Box className="form-info-wrapper">
                <Box className="form-row">
                  <Box className="form-col">
                    <CustomInputWeb
                      errors={this.state.error.title}
                      data-test-id="crateTaskTitle"
                      label={this.t(`${configJSON.txtTitle}`)}
                      isRequired={true}
                      name="title"
                      value={this.state.title}
                      onChange={this.handleOnChange}
                      type="text"
                    />
                  </Box>
                </Box>
                <Box className="form-row">
                  <Box className="form-col col6">
                    <FormControl className="select-outer" variant="outlined">
                      <InputLabel id="project">{this.t(`${configJSON.projectTxt}`)}</InputLabel>
                      <Select
                        labelId="project"
                        id="project"
                        value={this.state.taskObject.project}
                        onChange={this.createTaskProjectChange}
                        name="project"
                        label={this.t(`${configJSON.projectTxt}`)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: {
                            paper: `${classes.dropdownStyle} modal-dropdown`,
                          },
                        }}
                        data-test-id="modalChangeStatus"
                        IconComponent={ExpandMoreIcon}
                      >
                        {this.state.projectList.map((proj) => {
                          return (
                            <MenuItem value={Number(proj.id)} key={proj.id}>
                              {proj.attributes.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="form-col col6">
                    {/* <CustomInputWeb
                    label="Choose Tasklist"
                    value={this.state.title}
                    // onChange={this.handleOnChange}
                    // errors={this.state.error.title}
                    data-test-id="crateTaskTitle"
                  /> */}
                    <FreeSoloCreateOption
                      options={this.state.projectTaskList.map((val) => {
                        return {
                          id: val.id,
                          title: val.attributes.name,
                        };
                      })}
                      addEditCallback={this.addTaskList}
                      addLinkTitle={this.t(`${configJSON.addNewTaskListTxt}`)}
                      noOptionTitle={this.t(`${configJSON.noTaskListFoundTxt}`)}
                      label={this.t(`${configJSON.labelTitleText}`)}
                      id="job-id"
                      onChange={this.taskListChangeHandler}
                      value={this.state.taskObject.tasklist.title}
                      // errors={this.state.editError.jobTitle}
                      errorMsgNoRecord={this.t(`${configJSON.notCreatedTasklistTxt}`)}
                      hideOtherOptions={true}
                    />
                  </Box>
                </Box>
                <Box className="form-row">
                  <Box className="form-col col6">
                    <Box className="form-control">
                      <CustomDatePicker
                        placeholder={this.t(`${configJSON.txtDueDate}`)}
                        currentDate={this.state.due_date}
                        onChange={this.dueDateChange}
                        onOpenPickNewDate={false}
                        data-test-id="crateTaskDueDate"
                        required={true}
                        error={this.state.error.due_date}
                        showPastWarning={true}
                        inPortal={true}
                      />
                    </Box>
                  </Box>
                  <Box className="form-col col6">
                    <Box className="form-control">
                      <CustomDatePicker
                        placeholder={this.t(`${configJSON.txtDeadline}`)}
                        currentDate={this.state.deadline_date}
                        // datePickerRef={this.datePickerRef}
                        onChange={this.deadlineChange}
                        onOpenPickNewDate={false}
                        data-test-id="crateTaskDeadline"
                        showPastWarning={true}
                        inPortal={true}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className="form-row">
                  <Box className="form-col">
                    <CustomInputWeb
                      type="text"
                      label={this.t(`${configJSON.txtDescription}`)}
                      isMultiline
                      rows={5}
                      value={this.state.description}
                      name="description"
                      onChange={this.handleOnChange}
                      data-test-id="crateTaskDesc"
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="form-info-wrapper">
                <Box className="inventory-wrapper">
                  <Box className="links-wrapper">
                    <Link to="#" className="inventory-link">
                      + {this.t(`${configJSON.addInventoryTxt}`)}                      
                    </Link>
                    <Link to="#" className="inventory-link">
                      + {this.t(`${configJSON.expendituresTxt}`)}
                    </Link>
                  </Box>
                </Box>
              </Box>
              <Box className="form-info-wrapper">
                <FileDrop >
                  <Box
                    className={`${classes.fileUploadOutlineWrapper}`}
                    onClick={this.onBrowse}
                  >
                    <form ref={this.formRef}>
                      <input
                        name="upload-photo"
                        accept=".csv"
                        type="file"
                        hidden
                        ref={this.fileRef}
                        data-testid="fileInput"
                      />
                    </form>
                    <Box className="file-upload-block">
                      <Box component="p" className="file-subtxt">
                        {this.t(`${configJSON.clickHereTxt}`)}
                        <Box component="span" className="browse-link">
                          {this.t(`${configJSON.browseTxt}`)}
                        </Box>                       
                        {this.t(`${configJSON.yourFilesTxt}`)}
                      </Box>
                      <Box className="upload-icon">
                        <img src={uploadImg} alt="uploadImg" />
                      </Box>
                      <Button
                        className={`secondary-btn ${classes.secondaryButton}`}
                      >
                        {this.t(`${configJSON.addAttachmentTxt}`)}
                      </Button>
                    </Box>
                  </Box>
                </FileDrop>
                {/* <Box className="form-heading">
                  <Box className="form-title">Add Expenditures</Box>
                  <Link to="#" className="close-icon">
                    <CloseIcon />
                  </Link>
                </Box>
                <Box className="form-row">
                  <Box className="form-col">
                    <CustomInputWeb
                      type="number"
                      label="Hourly Pay"
                      // value={
                      //   this.state.editMember
                      //     .hourlyPay || ""
                      // }
                      // errors={
                      //   this.state.editError.hourlyPay
                      // }
                      placeholder="Expenditures"
                      //onChange={this.handleEditChange}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  </Box>
                </Box> */}
              </Box>
            </Box>
          </Box>
          <Box className="createtask-bottom">
            <Box className="createtask-left">
              <Box className="outerbutton-wrapper">
                <Link
                  to="#"
                  className="outer-link dropmenu-btn"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={this.dropMenuClick}
                  data-test-id="crateTaskPriority"
                >
                  <Box
                    className={`priority ${
                      this.state.priority == 1 || this.state.priority == 2
                        ? "active-priority"
                        : ""
                    }`}
                  >
                    {this.state.priority == 1 ? "!!" : "!!!"}
                  </Box>
                </Link>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.createTaskanchorEl}
                  keepMounted
                  open={Boolean(this.state.createTaskanchorEl)}
                  onClose={() => this.dropMenuClose(this.state.priority)}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  className={classes.dropDropdown}
                >
                  <MenuItem
                    onClick={() => this.dropMenuClose(2)}
                    data-test-id="CreateTaskPriorityEmergency"
                  >
                    <Box className="priority-name">{this.t(`${configJSON.txtEmergency}`)}</Box>
                    <Box className="priority-icon">!!!</Box>
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.dropMenuClose(1)}
                    data-test-id="CreateTaskPriorityCritical"
                  >
                    <Box className="priority-name">{this.t(`${configJSON.txtCritical}`)}</Box>
                    <Box className="priority-icon">!!</Box>
                  </MenuItem>
                  <MenuItem
                    onClick={() => this.dropMenuClose(0)}
                    data-test-id="CreateTaskPriorityNone"
                  >
                    <Box className="priority-name">{this.t(`${configJSON.txtNone}`)}</Box>
                  </MenuItem>
                </Menu>
              </Box>
              <Box className="outerbutton-wrapper">
                <Link
                  to="#"
                  className="outer-link"
                  onClick={this.toggleHoursModal}
                  data-test-id="CreateTaskHours"
                >
                  <img src={timerSand} alt="timerSand" />
                  <Box className="time">{this.state.taskHours}</Box>
                </Link>
                {this.state.showHoursModal && (
                  <Box className={classes.timestandDropbox} >
                    <Box className="timestand-inner">
                      <Box className="timestand-heading">{this.t(`${configJSON.txtTaskHours}`)}</Box>
                      <Box component="form">
                        <Box className="time">
                          <TextField
                            InputProps={{
                              disableUnderline: true,
                            }}
                            inputProps={{
                              step: 1,
                              min: 0,
                              max: 999,
                            }}
                            placeholder="00"
                            id="standard-basic"
                            name="hours"
                            label=""
                            value={this.state.hours}
                            onChange={this.hoursChange}
                            type="number"
                            data-test-id="CreateTaskHoursInput"
                          />
                          <Box component="span" className="colon-txt">
                            :
                          </Box>
                          <TextField
                            InputProps={{
                              disableUnderline: true,
                            }}
                            placeholder="00"
                            id="standard-basic2"
                            label=""
                            name="minutes"
                            type="number"
                            value={this.state.minutes}
                            onChange={this.minutesChange}
                            data-test-id="CreateTaskMinutesInput"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="timestand-actions">
                      <Link
                        to="#"
                        className="secondary-link"
                        onClick={this.toggleHoursModal}
                        data-test-id="CreateTaskHoursCancel"
                      >
                        {this.t(`${configJSON.txtCancel}`)}
                      </Link>
                      <Link
                        to="#"
                        className="primary-link"
                        onClick={this.setHours}
                        data-test-id="CreateTaskHoursSave"
                      >
                        {this.t(`${configJSON.txtSave}`)}
                      </Link>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box className="outerbutton-wrapper">
                <Link
                  to="#"
                  className="outer-link"
                  onClick={this.assigneeHandler}
                  data-test-id="createTaskassignee"
                >
                  <img src={accountPlus} alt="accountPlus" />
                  <Box className="assignee-list">
                    {this.displayAssigneeOptions()
                      .filter((x: any) => this.state.assignees.includes(x.id))
                      .slice(0, 3)
                      .map((option: any) => {
                        return (
                          <Box className="assignee-bubble" key={option.id}>
                            {option.initials}
                          </Box>
                        );
                      })}
                    {this.state.assignees.length > 3 && (
                      <Box className="assignee-bubble">
                        +{this.state.assignees.length - 3}
                      </Box>
                    )}
                  </Box>
                </Link>
              </Box>
            </Box>
            <Box className="createtask-right">
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                onClick={this.addTask}
                data-test-id="saveTaskBtn"
              >
                {this.t(`${configJSON.txtCreateTask}`)}
              </Button>
            </Box>
          </Box>
        </Popover>
        {/* create task block end */}

        {/* creating tasklist popover start */}
        <Popover
          id={tasklistPopoverId}
          open={tasklistPopOverOpen}
          anchorEl={this.state.tasklistPopoveAnchorEl}
          onClose={this.tasklistPopoverHandleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          classes={{ paper: `${classes.createTaskBox}` }}
        >
          <Box
            onClick={this.tasklistPopoverHandleClose}
            className="auth-back show-sm"
          >
            <i>
              <svg
                className="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
              </svg>
            </i>
          </Box>   
          <Box className="task-heading">
            <Box className="heading-left">
              <Typography className="modal-title" component="h2">
                {this.t(`${configJSON.craeteTaskListTxt}`)}
              </Typography>
              <Box component="p" className="sub-txt">                
                {this.t(`${configJSON.createTasklistSubTxt}`)}
              </Box>
            </Box>
            <Box className="heading-right">
              <Link
                to="#"
                className="heading-icon hide-sm"
                onClick={this.tasklistPopoverHandleClose}
              >
                <img src={closeGray} alt="closeGray" />
              </Link>
            </Box>
          </Box>
          <Box className="task-description">
            <Box className="task-form" component="form">
              <Box className="form-info-wrapper">
                <Box className="form-row">
                  <Box className="form-col">
                    <FormControl className="select-outer" variant="outlined">
                      <InputLabel id="project">{this.t(`${configJSON.projectTxt}`)}</InputLabel>
                      <Select
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                          getContentAnchorEl: null,
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          classes: {
                            paper: `${classes.dropdownStyle} modal-dropdown`,
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                        data-test-id="modalChangeStatus"
                        id="project"
                        label={this.t(`${configJSON.projectTxt}`)}
                        value={this.state.taskListObject.project}
                        onChange={this.handleProjectChange}
                        name="project"
                        labelId="project"                        
                      >
                        {this.state.projectList.map((project) => {
                          return (
                            <MenuItem value={project.id} key={project.id}>
                              {project.attributes.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box className="form-row">
                  <Box className="form-col">
                    <CustomInputWeb
                      type="text"
                      label={this.t(`${configJSON.tasklistNameTxt}`)}
                      value={this.state.taskListObject.name}
                      name="addNote"
                      onChange={this.handleTaskListNameChange}
                      errors={this.state.error.taskListName}
                      isRequired={true}
                      data-test-id="tasklistName"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="createtask-bottom">
            <Box className="button-wrapper full-btn">
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                onClick={this.createTaskList}
                data-test-id="saveTasklistbtn"
                title="Create TaskList"
              >
                {this.t(`${configJSON.craeteTaskListTxt}`)}
              </Button>
            </Box>
          </Box>
        </Popover>
        {/* creating tasklist popover end */}

      </>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(Projects);
// Customizable Area End

