// Customizable Area Start
import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { close, new_page } from "../assets";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import { withStyles } from "@material-ui/core/styles";
import CreateGroupController,{ Props, configJSON } from "./CreateGroupController.web";
import { customStyles } from "../ContactsListStyles.web";
import GroupContacts from "./GroupContacts.web";

class CreateGroup extends CreateGroupController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes , handleCloseDialog } = this.props;  
    const retrievedArrayLength = JSON.parse(localStorage.getItem("contactIds") ?? '[]').length;

    let message;
    if (retrievedArrayLength === 0) {
      message = this.t(`${configJSON.chooseContact}`);
    } else {
      const contactText = retrievedArrayLength !== 1 ? this.t(`${configJSON.contactsSelected}`) : this.t(`${configJSON.contactSelected}`);
      message = contactText.replace('{{count}}', retrievedArrayLength);
    }
    return (
      <>
      <Box className={classes.createContactBox}>
      <Box className="contact-heading">
        <Box className="heading-left">
          <Typography className="modal-title" component="h2">
            {this.t(`${configJSON.createGroup}`)}
          </Typography>
          <Box component="p" className="sub-txt">
            {this.t(`${configJSON.letsCreateGroupTxt}`)}
          </Box>
        </Box>
        <Box className="heading-right">         
          <Link to="#" className="heading-icon" onClick={handleCloseDialog} >
            <img src={close} alt="closeGray" />
          </Link>
        </Box>
      </Box>

      <Box className="info-group">
        <Box className="group-form" component="form">
          <Box className="form-info-wrapper">
            <Box className="form-row">
              <Box className="form-col">
                <CustomInputWeb
                  type="text"
                  label={this.t(`${configJSON.groupName}`)}
                  value={this.state.groupNameInput}
                  name="Group Name"
                  onChange={(event: any) =>
                    this.handleChangeGroupName(event.target.value)
                  }
                  isRequired={true}
                  errors={this.state.error.groupNameInput}
                  rows={4}
                  data-test-id="groupname"
                />
              </Box>
            </Box>
            <Box className="form-row">
              <Button className="choose-contact" data-test-id="handleOpenGroupContact" onClick={this.handleOpenGroupContact} >
                <Typography className="count-selected">
                 {message}                           
                </Typography>
                <KeyboardArrowDownIcon style={{color:"#94a3b8"}}/>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Button className="create-contact-btn" data-test-id="create-group-btn" onClick={this.createGroupEvent} >+ {this.t(`${configJSON.createGroup}`)}</Button>
      </Box>
      <GroupContacts
            classes={classes}
            modalOpen = {this.state.showGroupsContact}
            modalClose={this.handleCloseGroupContact}
            searchContactKeyword={this.state.searchContactKeyword}
            handleSearchContact={this.handleSearchContact}
            contacts={this.state.contactListData}
            isSearching={this.state.isContactSearching}
            isSorting={this.state.isContactSorting}
            handleSortContact={this.handleSortContact}
            searchedContacts={this.state.searchedContacts}
            editGroupEvent={this.createGroupEvent}
            sortedContacts={this.state.sortedContacts}
            subTitle={this.t(`${configJSON.chooseContactInGroup}`)}
            t={this.t}
            data-test-id="groupContacts"
            />
      </>
    );
  }
}

export default withStyles(customStyles, { withTheme: true })(CreateGroup);

// Customizable Area End
