//Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./EquizStyles.web";
import i18n from "../../../web/src/utilities/i18n";

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
  classes: any;
}

export interface S {
  // Customizable Area Start
  quizId: string;
  submittedQuizData: any[];
  reTakeQuizTitle: string;
  reTakeQuizCaption: string;
  quizGrade: string;
  isQuizSubmitted: boolean;
  isLoading: boolean;
  // Customizable Area End
}
export interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class ReTakeQuizControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiReTakeQuizList: string = "";
  submittReTakeQuizAPI: string = "";
  resultQuizFromRequestId: string = "";
  userSessionData: any;
  userToken: any;
  getreTakeQuizId: any;
  reTakeQuizId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      quizId: "",
      reTakeQuizTitle: "",
      reTakeQuizCaption: "",
      submittedQuizData: [],
      quizGrade: "",
      isQuizSubmitted: false,
      isLoading: false,
    };

   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.getreTakeQuizId = localStorage.getItem("retakeQuizId");
    this.reTakeQuizId = JSON.parse(this.getreTakeQuizId);
    if (this.reTakeQuizId === null) {
      this.props.navigation.navigate("EquizDashboardWeb");
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.quizQueList();
    const lang = localStorage.getItem("lang") ?? "en";
    await i18n.changeLanguage(lang);
  }
  t(key:any) {
    return i18n.t(key, { ns: "translation" } )
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiReTakeQuizList) {
        this.setState({isLoading: false})
        if (responseJson && responseJson.success) {
          this.setState({
            reTakeQuizTitle: responseJson.quiz.quiz.data.attributes.title,
            reTakeQuizCaption: responseJson.quiz.quiz.data.attributes.caption,
            submittedQuizData: responseJson.quiz.questions.reverse(),
          });
        }
      } else if (apiRequestCallId === this.submittReTakeQuizAPI) {
        this.setState({isLoading: false})
        if (responseJson.success) {
          this.resultApi();
        }
      } else if (apiRequestCallId === this.resultQuizFromRequestId) {
        this.setState({isLoading: false})
        this.setState({ isQuizSubmitted: true, quizGrade: responseJson.grade });
      }
    }
  }

  handleBackArrow = () => {
    this.props.navigation.navigate("EquizDashboardWeb");
  };
  reTakeBtnHandler = () => {
    this.setState({isQuizSubmitted : false})
  }
  handleDashboardClick = ()    => {
    this.props.navigation.navigate("EquizDashboardWeb");
    localStorage.removeItem("retakeQuizId");
  }

  quizQueList = () => {
    this.setState({isLoading : true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiReTakeQuizList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getQuizDetailsEndPoint + "?quiz_id=" + this.reTakeQuizId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  saveSlideAnswer = (index: number, newValue: any) => {
    const updatedAnswer = [...this.state.submittedQuizData];
    updatedAnswer.map((ans, currIndex) => {
      if (currIndex === index) {
        ans.submission.answer = newValue;
      }
    });
    this.setState({ submittedQuizData: updatedAnswer });
  };

  handleRetakeQuizSubmission = () => {
    const quizSubmittedData = this.state.submittedQuizData;

    const submissions = quizSubmittedData.map((item) => {
      return {
        account_id: item.submission.account_id,
        id: item.submission.id,
        question_id: item.submission.question_id,
        answer: item.submission.answer,
      };
    });

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body = {
      quiz_id: this.reTakeQuizId,
      submissions: submissions,
    };
    
    

    let endPoint = configJSON.reTakeQuizEndPoint;

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submittReTakeQuizAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    

    //* Making Network Request

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  resultApi = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    let endPoint =
      configJSON.quizResultEndPoint + "?quiz_id=" + this.reTakeQuizId;

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resultQuizFromRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Get"
    );
    this.setState({isLoading: true})

    //* Making Network Request

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleRetakeEditQuiz = () => {
    //this.props.navigation.navigate("EnteringQuiz");
    this.props.navigation.navigate("EnteringQuiz",{":quizId":this.reTakeQuizId});
  }
}
//Customizable Area End
