import React from "react";
import { Box, Typography, Button } from "@material-ui/core";

interface NoTeamsFoundProps {
  classes: any;
  emptyImage: string;
  createTeamHeading: string;
  createTeamSubText: string;
  finishBtn: string;
  creatTeamHandler: () => void;
  createTeamBtn: string;
}

const NoTeamsFound = (props: NoTeamsFoundProps) => {
  const {
    classes,
    emptyImage,
    createTeamHeading,
    createTeamSubText,
    finishBtn,
    creatTeamHandler,
    createTeamBtn,
  } = props;

  return (
    <Box className={classes.emptyDataBox}>
      <Box className="emptydata-inner">
        <Box className="emptydata-row">
          <Box className="emptydata-img">
            <img src={emptyImage} alt="emptydata" />
          </Box>
          <Box className="emptydata-content">
            <Typography className="heading" variant="h2">
              {createTeamHeading}
            </Typography>
            <Box component="p" className="sub-txt">
              {createTeamSubText}
            </Box>
            <Box className="button-wrapper">
              <Button className={classes.secondaryButton} title={finishBtn}>
                {finishBtn}
              </Button>
              <Button
                className={classes.primaryButton}
                title={createTeamBtn}
                onClick={creatTeamHandler}
                data-testid="create-teams-btn"
              >
                {createTeamBtn}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NoTeamsFound;
