import React from "react";
import AuthWrapper from "./common/AuthWrapper";
import { Typography, Box, TextField, Button } from "@material-ui/core";
import EmailAccountLoginController from "./EmailAccountLoginController.web";
import { styles } from "./EmailAccountLoginBlock.web";
import { withStyles } from "@material-ui/core/styles";
import Slide from '@material-ui/core/Slide';
import CheckIcon from '@material-ui/icons/Check';
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ClearIcon from '@material-ui/icons/Clear';
import { Link } from "react-router-dom";

class RecoverPassword extends EmailAccountLoginController {
    render() {
        const { classes } = this.props;
        const isPasswordReset = this.state.forgotPasswordStatus.isPasswordReset;
        const hasToken = this.state.forgotPasswordStatus.reset_password_token;

        return (
            <AuthWrapper backButton>
                <Box mt={9} className={classes.innerContainer}>
                    {!isPasswordReset && (
                        <Slide in direction="left" mountOnEnter timeout={800}>
                            <Box>
                                <Typography variant="h3">Revover Password</Typography>
                                {!this.state.forgotPasswordStatus.reset_password_token &&
                                    <Typography>Enter the Email you used to Sign Up to receive password reset instructions</Typography>}
                                {this.state.forgotPasswordStatus.reset_password_token &&
                                    <Typography>Enter new Password and confirm it</Typography>
                                }
                            </Box>
                        </Slide>
                    )}

                    <Slide in direction="up" mountOnEnter timeout={800}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            className={classes.textBoxs}
                            mt={7} mb={10}
                            component="form"
                            onSubmit={this.handleRecoverPassword}
                        >

                            {!isPasswordReset && !hasToken &&
                                <TextField
                                    placeholder="Email"
                                    type="email"
                                    variant="outlined"
                                    value={this.state.email}
                                    onChange={(event) => this.setState({ email: event.target.value })}
                                    className={this.state.error.emailError.visible ? classes.error_field : ""}
                                    helperText={this.state.error.emailError.message}
                                    autoFocus
                                />
                            }

                            {!isPasswordReset && hasToken &&
                                <Box className={classes.passwordField}>
                                    <TextField
                                        placeholder="Password"
                                        variant="outlined"
                                        type={this.state.isPasswordVisible ? "text" : "password"}
                                        className={`${this.state.error.passwordError.visible ? classes.error_field : ""}`}
                                        value={this.state.password}
                                        autoComplete="off"
                                        onChange={(event) => this.setState({ password: event.target.value })}
                                        helperText={this.state.error.passwordError.message}
                                        fullWidth
                                        autoFocus
                                    />
                                    <Box onClick={this.togglePasswordVisibility} className={`${this.state.error.passwordError.visible ? classes.error_svg : ""}`}>
                                        {this.state.isPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </Box>
                                </Box>
                            }

                            {!isPasswordReset && hasToken &&
                                <Box>
                                    <Box className={classes.validationOptions}>
                                        {this.isContainsUpperCase() ? <CheckIcon /> : <ClearIcon className="fail" />}
                                        <Typography>At least one Capital</Typography>
                                    </Box>
                                    <Box className={classes.validationOptions}>
                                        {this.isContainsLowerCase() ? <CheckIcon /> : <ClearIcon className="fail" />}
                                        <Typography>At least one lowercase</Typography>
                                    </Box>
                                    <Box className={classes.validationOptions}>
                                        {this.isContainsNumber() ? <CheckIcon /> : <ClearIcon className="fail" />}
                                        <Typography>At least one Number</Typography>
                                    </Box>
                                    <Box className={classes.validationOptions}>
                                        {this.state.password.trim().length > 7 ? <CheckIcon /> : <ClearIcon className="fail" />}
                                        <Typography>mininum password length should be 8 characters</Typography>
                                    </Box>
                                </Box>
                            }


                            {isPasswordReset && (
                                <Box className={classes.successMsg}>
                                    <Typography variant="h3">Done</Typography>
                                    <Typography>New Password Sucessfully Set.</Typography>

                                    <Box mt={10}>
                                        <Link to='/login'>
                                            <Button variant="contained" color="primary" type="submit">
                                                Login
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            )}

                            {!isPasswordReset && !hasToken &&
                                <Box mt={3}>
                                    <Button variant="contained" color="primary" type="submit">
                                        Send Recover Password
                                    </Button>
                                </Box>
                            }

                            {!isPasswordReset && hasToken &&
                                <Box mt={3}>
                                    <Button variant="contained" color="primary" type="submit">
                                        Confirm New Password
                                    </Button>
                                </Box>
                            }

                        </Box>
                    </Slide>
                </Box>
            </AuthWrapper>
        );
    }
}

export default withStyles(styles, { withTheme: true })((RecoverPassword));