import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ResponseDataType } from "./InventoryTypes";
import StorageProvider from "../../../framework/src/StorageProvider";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  seletedItem: string;
  stepNumber: number;
  backBtn: ()=>void;
  nextBtn: ()=>void;
  // Customizable Area End
}

interface S {
// Customizable Area Start
appellationType: string | unknown;
appellation: string | unknown;
country: string | unknown;
region: string | unknown;
subRegion: string | unknown;
style: string;
countryData: string[];
regionData:string[];
subRegionData: string[];
appellationData:{
  id: number,
  name: string,
}[];
appellationTypeData:{
    id: number,
    name: string,
  }[];
// Customizable Area End
}
  
interface SS {
id: any;
// Customizable Area Start
// Customizable Area End
}
  
// Customizable Area Start

// Customizable Area End
export default class LocationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppellationApiCallId: string = "";
  getAppellationTypeApiCallId: string = "";
  getCountryApiCallId:string = "";
  getRegionApiCallId:string = "";
  getSubRegionApiCallId:string = "";
  userSessionData: any;
  userToken: {meta:{token:string}};
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      // Customizable Area Start
      appellationType: "",
      appellation: "",
      country: "",
      region: "",
      subRegion: "",
      style: "",
      appellationTypeData: [],
      appellationData: [],
      countryData: [],
      regionData: [],
      subRegionData: [],

      // Customizable Area End
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
 
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestCallId && responseJson){
      switch (apiRequestCallId){
        case this.getAppellationApiCallId:
            this.appellationResponse(responseJson);
            break;
        case this.getAppellationTypeApiCallId:
            this.appellationTypeResponse(responseJson);
            break;
        case this.getCountryApiCallId:
          this.countryResponse(responseJson);
          break;
        case this.getRegionApiCallId:
          this.regionDataResponse(responseJson.regions);
          break;
        case this.getSubRegionApiCallId:
          this.subRegDataResponse(responseJson.sub_regions)
          break;
        default:
        break;
      }
    }
    // Customizable Area End
  }

  countryResponse =(response:{country_names:string[]})=>{
    this.setState({countryData: response.country_names});
  }
  regionDataResponse=(response:string[])=>{
    this.setState({regionData: response});
  }
  subRegDataResponse =(response:string[])=>{
    this.setState({subRegionData: response});
  }
  appellationResponse=(response:ResponseDataType)=>{
    this.setState({appellationData: response.options});
  }
  appellationTypeResponse=(response:ResponseDataType)=>{
    this.setState({appellationTypeData: response.options});
  }

handleAppelationType = (event: React.ChangeEvent<{ value: unknown; }>) => {
this.setState({ appellationType: event.target.value })
}
handleAppelation = (event: React.ChangeEvent<{ value: unknown; }>) => {
    this.setState({ appellation: event.target.value })
}
handleCountry = (event: React.ChangeEvent<{ value: unknown; }>) => {
    this.setState({ country: event.target.value })
}
handleRegion = (event: React.ChangeEvent<{ value: unknown; }>) => {
    this.setState({ region: event.target.value })
}
handleSubRegion = (event: React.ChangeEvent<{ value: unknown; }>) => {
    this.setState({ subRegion: event.target.value })
}
handleStyle = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ style: event.target.value })
}

backToBasicInfo = () => {
  this.setLocationData();
  this.props.backBtn();
}
handleLocationData=()=>{
  this.setLocationData();
  this.props.nextBtn()
}

setLocationData = () => {
  const locationData = {
    "appellation": this.state.appellation,
    "appellation_type": this.state.appellationType,
    "country": this.state.country,
    "region":this.state.region,
    "subregion": this.state.subRegion,
    "style": this.state.style, 
  };
  StorageProvider.set("itemLocationdata", JSON.stringify(locationData));
}

  getCountryData = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getRegionData = (countryName:string) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRegionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryRegionsAPIEndpoint + countryName
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSubRegionData = (countryName:string, regionName:string) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubRegionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.regionSubregionAPIEndpoint + `${countryName}&region=${regionName}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAppellationData = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAppellationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsDropdownAPIEndPoint + "appellation"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  getAppellationTypeData= () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAppellationTypeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsDropdownAPIEndPoint + "appellation_type"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.country != this.state.country){
      this.getRegionData(String(this.state.country))
    } 
    if(prevState.region != this.state.region){
      this.getSubRegionData(String(this.state.country), String(this.state.region))
    }
  }

  async componentDidMount(): Promise<void> {
    const locationData = await StorageProvider.get("itemLocationdata");
    if(locationData){
      const locationJSON = await JSON.parse(locationData)
      this.setState({
        appellation: locationJSON?.appellation ?? "",
        appellationType: locationJSON?.appellation_type ?? "",
        country: locationJSON?.country ?? "",
        region: locationJSON?.region ?? "",
        subRegion: locationJSON?.subregion ?? "",
        style: locationJSON?.style ?? ""
      })
    }
    this.getCountryData()
    this.getAppellationData()
    this.getAppellationTypeData()
    const lang = localStorage.getItem("lang") || "en"; 
    await (i18n as any).changeLanguage(lang);
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  // Customizable Area End
}
