import React from "react";

import {
    Box,
    Typography,
    Button,
    TextField,
} from "@material-ui/core";
import {
    createStyles,
    withStyles,
    Theme as AugmentedTheme,
} from "@material-ui/core/styles";

import { GoogleIcon } from "./assets";
import EmailAccountLoginController from "./EmailAccountLoginController.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import AuthWrapper from "./common/AuthWrapper";
import { Link } from "react-router-dom";
import Slide from '@material-ui/core/Slide';
// @ts-ignore
import { withNavigation } from "../../../components/src/withNavigation.js";

export const styles = (theme: AugmentedTheme) =>
    createStyles({
        textBoxs: {
            width: "100%",

            "& > div": {
                "&:first-child": {
                    marginBottom: "1.5rem",
                },
            },

            "& input": {
                padding: "1rem",
                background: theme.palette.common.white,
                color: theme.palette.common.black,
                fontSize: "1rem",
                borderRadius: theme.shape.borderRadius + "rem",

                "&:placeholder": {
                    fontSize: "1rem",
                },
            },

            "& fieldset": {
                border: "none",
            },
        },
        passwordField: {
            background: theme.palette.common.white,
            borderRadius: theme.shape.borderRadius + "rem",
            position: "relative",

            "& svg": {
                color: "#959595",
                cursor: "pointer",
                transition: "all .3s ease",
                fontSize: "1.3rem",
                position: "absolute",
                right: ".7rem",
                top: "1rem",

                "&:hover": {
                    color: "#000",
                },
            },
        },
        alignRight: {
            alignSelf: "flex-end",
            margin: "1rem 0",
            fontWeight: 600,
            cursor: "pointer",
        },
        signUp: {
            "& p": {
                cursor: "pointer",
                margin: ".5rem 0 0",
                "&:last-child": {
                    marginLeft: ".3rem",
                },
            },
        },
        gIcon: {
            marginRight: ".5rem",
        },
        innerContainer: {
            "& h3": {
                margin: 0,
            },
            "& p": {
                margin: ".5rem 0 0"
            }
        },
        error_field: {
            border: `2.5px solid ${theme.palette.error.main}`,
            borderRadius: theme.shape.borderRadius + "rem",
            background: theme.palette.error.main,

            "& input": {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            },

            "& p": {
                background: theme.palette.error.main,
                margin: 0,
                color: theme.palette.common.white,
                padding: ".1rem 1rem"
            },
        },
        error_svg: {
            "& svg": {
                color: theme.palette.error.main,
            }
        },
        validationOptions: {
            display: "flex",
            alignItems: "center",

            "& p": {
                margin: 0,
                marginLeft: ".5rem",
                fontSize: ".9rem",
            },

            "& svg": {
                color: theme.palette.primary.main,
            },

            "& .fail": {
                color: theme.palette.error.main,
            }
        },
        successMsg: {
            textAlign: "center",

            "& p": {
                fontSize: "1rem",
            }
        }
    });

class EmailAccountLoginBlock extends EmailAccountLoginController {
    // Customizable Area Start

    async componentDidMount() {
        // console.log("mount....", sessionStorage.getItem("userData"));
        // if (JSON.parse(sessionStorage.getItem("userData") as string)?.meta?.token) {
        //     this.props.navigation('/dashboard');
        // }
        // this.props.navigation('/dashboard');
    }
    // Customizable Area End

    render() {
        const { classes } = this.props;

        return (

            <AuthWrapper>

                <Slide direction="left" mountOnEnter timeout={800} in>
                    <Typography variant="h3">Login</Typography>
                </Slide>

                <Box
                    display="flex"
                    flexDirection="column"
                    className={classes.textBoxs}
                    component="form"
                >
                    <TextField
                        placeholder="Email"
                        type="email"
                        variant="outlined"
                        value={this.state.email}
                        onChange={(event) => this.setState({ email: event.target.value })}
                        className={this.state.error.emailError.visible ? classes.error_field : ""}
                        helperText={this.state.error.emailError.message}
                        autoFocus
                    />

                    <Box className={classes.passwordField}>
                        <TextField
                            placeholder="Password"
                            variant="outlined"
                            type={this.state.isPasswordVisible ? "text" : "password"}
                            className={`${this.state.error.passwordError.visible ? classes.error_field : ""}`}
                            value={this.state.password}
                            autoComplete="off"
                            onChange={(event) => this.setState({ password: event.target.value })}
                            helperText={this.state.error.passwordError.message}
                            fullWidth
                        />
                        <Box onClick={this.togglePasswordVisibility} className={`${this.state.error.passwordError.visible ? classes.error_svg : ""}`}>
                            {this.state.isPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </Box>
                    </Box>

                    <Link to='/recover-password' className={classes.alignRight}>
                        <Typography
                            color="primary"
                            variant="h6"
                        >
                            Recover Password
                        </Typography>
                    </Link>
                </Box>

                <Button onClick={this.handleLogin} variant="contained" color="primary">
                    Login
                </Button>
                <Typography align="center">or</Typography>
                <Button variant="contained" color="primary">
                    <GoogleIcon className={classes.gIcon} />
                    Login with Google
                </Button>

                <Box className={classes.signUp} display="flex" justifyContent="center">
                    <Typography>Don't have an account? </Typography>
                    <Link to='/register'>
                        <Typography color="primary">Sign Up</Typography>
                    </Link>
                </Box>

            </AuthWrapper >
        );
    }
}

export default withStyles(styles, { withTheme: true })((withNavigation(EmailAccountLoginBlock)));
