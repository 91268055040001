import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  CardMedia,
  Button,
  Checkbox,
  InputAdornment,
  Modal,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Popover,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  checked,
  clipboardCheckOutline,
  close,
  deleteGray,
  productExample,
} from "./assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import { checkedIcon } from "../../catalogue/src/assets";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "../../../components/src/LeadManagmentHelper.web";
import WebLoader from "../../../components/src/WebLoader.web";
import Section from "../../../components/src/FavProductLeftSection.web";
import { getImageForSubCategory } from "../../../components/src/ReusableFunctions";
import { colorData } from "../../../components/src/productData";
interface SectionProps {
  name: string;
  title: string;
  searchValue: string;
  itemList: any[]; // Update this to match the type of your 'itemList' items
  expanded:any;
}
// Customizable Area End

import AssociatedProductsController, {
  Props,
  configJSON,
  // Customizable Area Start
  // Customizable Area End
} from "./AssociatedProductsController.web";

export class AssociatedProductsWeb extends AssociatedProductsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, closeProductsModal } = this.props;
    const {
      choosenProducts,
      allProductsData,
      openItemInfo,
      choosenProductsData,
      productQuantities,
      expanded,
      itemList,
      isLoading,
      totalAmount,
    } = this.state;
    
    
    return (
      <>
      {isLoading && <WebLoader/>}
     
      <Modal
        open={this.props.openProductsModal}
        onClose={this.props.closeProductsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.productsWrapper}>
          <Box className="product-top-header">
            <ArrowBack onClick={closeProductsModal} />
            <Box className="top-right">
              <CardMedia
                component="img"
                src={clipboardCheckOutline}
                className="calendar-icon"
              />
              <Box className="filter-more">
                <Typography
                  className="selected-txt"
                  onClick={this.openItemsInfoDetail}
                  data-test-id="open-summary-info"
                >
                  {choosenProducts.length}{" "}
                  {choosenProducts.length > 1 ? `${this.t((`${configJSON.associatedItemTxt}`))}` : `${this.t((`${configJSON.associatedItemsTxtSingle}`))}`} {this.t((`${configJSON.associatedSelectedTxt}`))}                  
                </Typography>
                <Popover
                  open={Boolean(openItemInfo)}
                  anchorEl={openItemInfo}
                  onClose={this.closeItemsInfoDetail}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  classes={{ paper: `${classes.productListPopoverStyle}` }}
                >
                  <Box className="view-selected-item-dropdown-list">
                    <Box className="headding">
                      <Typography className="headding-txt">
                        {choosenProductsData.length}{" "}
                        {choosenProducts.length > 1 ? `${this.t((`${configJSON.associatedItemTxt}`))}` : `${this.t((`${configJSON.associatedItemsTxtSingle}`))}`} {this.t((`${configJSON.associatedSelectedTxt}`))}
                      </Typography>
                      <Box
                        data-test-id="closeItemsInfoDetail"
                        onClick={this.closeItemsInfoDetail}
                      >
                        <img src={close} alt="close-icon" />
                      </Box>
                    </Box>
                    <Typography className="sub-headding-txt">
                      {this.t((`${configJSON.associatedCardSubTxt}`))}
                    </Typography>
                    {choosenProductsData.map((product: any) => {
                      return (
                        <Box className="product-container" key={product.id}>
                           
                          <img
                            className="product-img"
                            src={productExample}
                            alt="productExample"
                          />
                          <Box className="product-content">
                            <Typography className="product-name">
                              Petite cave
                            </Typography>
                            <Typography className="product-item">
                              Sauvignon Blanc
                            </Typography>
                            <Box className="wrap-price">
                              <Box className="price-btn">
                                <Typography className="retail-price-txt">
                                  Retail Price
                                </Typography>
                                <KeyboardArrowDown className="icon-down" />
                              </Box>
                            </Box>
                            <Box className="delete-content">
                              <Typography className="price-txt">
                                {" "}
                                $
                                {(
                                  (product?.attributes?.prices[0]?.amount || 0) *
                                  (productQuantities[product.id] || 1)
                                ).toFixed(2)}
                              </Typography>
                              <Box
                                onClick={() =>
                                  this.removeChosenProductItems(product.id)
                                }
                                data-test-id={`remove-chosen-product-${product.id}`}
                              >
                                <img
                                  className="delete-img"
                                  src={deleteGray}
                                  alt="delete"
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Box className="product-add-remove">
                            <Button
                              variant="contained"
                              className={`secondary-btn ${classes.secondaryButton}`}
                              data-test-id={`remove-item-click-btn-${product.id}`}
                              onClick={() =>
                                this.handleRemoveButtonClickItems(product.id)
                              }
                            >
                              -
                            </Button>
                            <Typography className="product-count">
                              {productQuantities[product.id] || 1}
                            </Typography>
                            <Button
                              variant="contained"
                              className={`secondary-btn ${classes.secondaryButton}`}
                              data-test-id={`add-item-click-btn-${product.id}`}
                              onClick={() => this.handleAddButtonClickItems(product.id)}
                            >
                              +
                            </Button>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Popover>
              </Box>
              <Button
                onClick={() => {this.props.selectedDealProducts(choosenProducts, totalAmount,choosenProductsData)}}
                disabled={choosenProducts.length === 0}
                className={
                  choosenProducts.length > 0
                    ? "add-to-contact-btn-active"
                    : "add-to-contact-btn"
                }
                data-test-id="onSelectProducts"
              >
                {this.props.topButtonTxt}
              </Button>
            </Box>
          </Box>
          <Box className="main-wrapper">
            <Box className="top-main-wrapper">
              <Box className="title-section">
                <Typography className="choose-item-txt">
                {this.props.headingTxt}
                </Typography>
                <Typography className="choose-item-sub-txt">
                {this.props.headingSubTxt}
                </Typography>
              </Box>
              <Box className="filter-section">
                <Grid container className="filter-section">
                  <Grid item md={4} lg={4} sm={4} xs={4}>
                    <Box className="left-box">
                      <Typography className="filter-txt">{this.t(`${configJSON.associatedFilters}`)}</Typography>
                      <Button
                        className="clear-all-btn"
                        data-test-id="clearAll"
                        //onClick={clearAll}
                      >
                        {this.t(`${configJSON.associatedClear}`)}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item md={8} lg={8} sm={8} xs={8}>
                    <Box className="right-box">
                      <Box className="check-box">
                        <Checkbox
                          checked={
                            choosenProducts.length > 0
                              ? true
                              : this.state.selectAll
                          }
                          onChange={(e: any) => this.handleSelectAllItems(e)}
                          checkedIcon={<img src={checkedIcon} alt="checkbox" />}
                          data-test-id="select-all-items-checkbox"
                        />
                        <Typography className="selected-txt">
                          {choosenProducts.length}{" "}
                          {choosenProducts.length > 1 ? `${this.t(`${configJSON.associatedProductsTxt}`)}` : `${this.t(`${configJSON.associatedProductsTxtSingle}`)}`}{" "}
                        </Typography>
                      </Box>
                      <Box className="search-sort-box">
                        <CustomInputWeb
                          type="search"
                          //value={overallProductSearch}
                          name="overallProductSearch"
                          label=""
                          placeholder={`${this.t((`${configJSON.searchTxt}`))}`}
                          // onChange={handleAllInputChange}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon
                                style={{ color: "rgb(131 128 128)" }}
                              />
                            </InputAdornment>
                          }
                          data-test-id="search-box"
                        />
                        <FormControl className="select-control sortby-control">
                          <Select
                           displayEmpty
                           inputProps={{ IconComponent: () => null }}
                            MenuProps={{
                              anchorOrigin: {
                                horizontal: "left",
                                vertical: "bottom",
                              },
                              transformOrigin: {
                                horizontal: "left",
                                vertical: "top",
                              },
                              classes: { paper: classes.dropdownStyle },
                              getContentAnchorEl: null,
                            }}
                            defaultValue="Sort by"
                           
                            renderValue={(value) => {
                              return (
                                <Box>
                                  {value}
                                  <ArrowDropDownIcon />
                                </Box>
                              );
                            }}
                            data-test-id="change-sort-products"
                          >
                            <MenuItem value="">{this.t((`${configJSON.sortByTxt}`))}</MenuItem>
                            <MenuItem value="A-Z">A-Z</MenuItem>
                            <MenuItem value="Z-A">Z-A</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className="main-content-wrapper">
              <Box className="right-wrapper">
                <Grid container spacing={3} className="grid-container">
                  <Grid
                    item
                    md={4}
                    lg={4}
                    sm={4}
                    xs={4}
                    className="left-side-info"
                  >
                    <Box className="left-wrapper">
                      <Section
                          title={this.t((`${configJSON.producerTxt}`))}
                          name="searchProducerDeals"
                          expanded={expanded}
                          handleExpandToggle={this.viewMoreResults}
                          searchValue={this.state.searchProducerDeals}
                          handleSearch={this.handleAllSearchesDeals}
                          selectedCheckBox={this.state.producerDeals}
                          itemList={itemList}
                          handleChecked={(id) => this.handleCheckedSelectionDeals(id, "producerDeals")}
                          t={this.t}
                          data-test-id="producer-section-id"
                        />
                        <Section
                          title="Brand"
                          name="searchBrandDeals"
                          searchValue={this.state.searchBrandDeals}
                          handleSearch={this.handleAllSearchesDeals}
                          selectedCheckBox={this.state.brandDeals}
                          itemList={itemList}
                          handleChecked={(id) =>
                            this.handleCheckedSelectionDeals(id, "brandDeals")
                          }
                          expanded={expanded}
                          handleExpandToggle={this.viewMoreResults}
                          data-test-id="producer-section-id"
                          t={this.t}
                        />
                        <Section
                          title="Color"
                          name="searchColorDeals"
                          searchValue={this.state.searchColorDeals}
                          handleSearch={this.handleAllSearchesDeals}
                          selectedCheckBox={this.state.colorDeals}
                          itemList={colorData}
                          handleChecked={(id) =>
                            this.handleCheckedSelectionDeals(id, "colorDeals")
                          }
                          expanded={expanded}
                          handleExpandToggle={this.viewMoreResults}
                          data-test-id="producer-section-id"
                          t={this.t}
                        />
                        <Section
                          title="Age/Vintage"
                          name="searchVintageDeals"
                          searchValue={this.state.searchVintageDeals}
                          handleSearch={this.handleAllSearchesDeals}
                          selectedCheckBox={this.state.vintageDeals}
                          itemList={colorData}
                          handleChecked={(id) =>
                            this.handleCheckedSelectionDeals(id, "vintageDeals")
                          }
                          expanded={expanded}
                          handleExpandToggle={this.viewMoreResults}
                          data-test-id="producer-section-id"
                          t={this.t}
                        />

                        <Section
                          title="Appellation Type"
                          name="searchAppellation"
                          searchValue={this.state.searchAppellation}
                          handleSearch={this.handleAllSearchesDeals}
                          selectedCheckBox={this.state.appelation}
                          itemList={colorData}
                          handleChecked={(id) =>
                            this.handleCheckedSelectionDeals(id, "appelation")
                          }
                          expanded={expanded}
                          handleExpandToggle={this.viewMoreResults}
                          data-test-id="producer-section-id"
                          t={this.t}
                        />

                        <Section
                          title="Country"
                          name="searchCountry"
                          searchValue={this.state.searchCountry}
                          handleSearch={this.handleAllSearchesDeals}
                          selectedCheckBox={this.state.country}
                          itemList={colorData}
                          handleChecked={(id) =>
                            this.handleCheckedSelectionDeals(id, "country")
                          }
                          expanded={expanded}
                          handleExpandToggle={this.viewMoreResults}
                          data-test-id="producer-section-id"
                          t={this.t}
                        />

                        <Section
                          title="Region"
                          name="searchRegion"
                          searchValue={this.state.searchRegion}
                          handleSearch={this.handleAllSearchesDeals}
                          selectedCheckBox={this.state.region}
                          itemList={colorData}
                          handleChecked={(id) =>
                            this.handleCheckedSelectionDeals(id, "region")
                          }
                          expanded={expanded}
                          handleExpandToggle={this.viewMoreResults}
                          data-test-id="producer-section-id"
                          t={this.t}
                        />
                        <Section
                          title="Sub Region"
                          name="searchSubRegion"
                          searchValue={this.state.searchSubRegion}
                          handleSearch={this.handleAllSearchesDeals}
                          selectedCheckBox={this.state.subRegion}
                          itemList={colorData}
                          handleChecked={(id) =>
                            this.handleCheckedSelectionDeals(id, "subRegion")
                          }
                          expanded={expanded}
                          handleExpandToggle={this.viewMoreResults}
                          data-test-id="producer-section-id"
                          t={this.t}
                        />
                    </Box>
                  </Grid>
                  <Grid item md={8} lg={8} sm={8} xs={8}>
                    <Grid container spacing={3} className="right-side-info">
                      {allProductsData.map((product: any) => {
                        const { stock_qty } = product.attributes;
                        return (
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={12}
                            xs={12}
                            key={product.id}
                          >
                            <Box className="product-list-item">
                              <Box className="item-top">
                                <Box className="image-content">
                                {getImageForSubCategory(product.attributes.sub_category.id, product.attributes)}
                                </Box>
                                <Box className="check-section">
                                  <Typography className="instock-quantity">
                                    In Stock: {stock_qty || 0}
                                  </Typography>
                                  <Checkbox
                                    className="right-top-chk"
                                    checked={choosenProducts.includes(
                                      product.id
                                    )}
                                    onChange={() =>
                                      this.handleSelectedItem(product.id)
                                    }
                                    checkedIcon={
                                      <img src={checkedIcon} alt="checkbox" />
                                    }
                                    data-test-id={`selected-item-${product.id}`}
                                  />
                                </Box>
                              </Box>
                              <Box className="item-bottom-info">
                                <Box className="wrap-name">
                                  <Box className="dot-section">
                                    <Typography className="product-info">
                                      Brand
                                    </Typography>
                                    <Typography className="product-dot-info">
                                      Volume
                                    </Typography>
                                  </Box>
                                  <Typography className="product-name-txt">
                                    {product.attributes.name}
                                  </Typography>
                                  <Typography className="product-additional-info">
                                    Appellation, Grape Variety
                                  </Typography>
                                </Box>
                                <Box className="wrap-price">
                                  <Box className="price-btn">
                                    <Typography className="retail-price-txt">
                                      Retail Price
                                    </Typography>
                                    <KeyboardArrowDown className="icon-down" />
                                  </Box>
                                  <Typography className="price-txt">
                                    $
                                    {(
                                      product?.attributes?.prices[0]?.amount ||
                                      0
                                    ).toFixed(2)}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(
  AssociatedProductsWeb
);
// Customizable Area End
