import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  isItemChecked: boolean;
  handleItemCheckbox: any;
  item: any;
  showPriceAs?: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  isLoading: boolean;
  showPriceAs: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End
export default class ProductItemController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionData: any;
  userToken: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      // Customizable Area Start

      isLoading: true,
      showPriceAs: 1,
      // Customizable Area End,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);

    // Customizable Area End
  }

  changePrice = (event: any) => {
    this.setState({ showPriceAs: event.target.value });
  };
  async componentDidMount(): Promise<void> {
    const lang = localStorage.getItem("lang") || "en";
    await (i18n as any).changeLanguage(lang);
  }

  t(key: any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation", ...variables });
  }
  // Customizable Area End
}
