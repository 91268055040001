import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
import { DateObject } from "react-multi-date-picker";
import { createCommonToastNotification } from "../../../components/src/ReusableFunctions";
import { checkBoxFilled } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  popoveAnchorEl: any;
  showTimesheetForm: boolean;
  chooseDateRange: DateObject[];
  projectLists: any[];
  taskLists: any[];
  allTaskLists: any[];
  teamLists: any[];
  membersLists: [];
  searchedProjects: any[];
  selectedMembers: any[];
  selectedTeam: any[];
  selectedOptions: any;
  saveSelectedTaskList: any;
  selectedTaskOptions: any;
  searchProjectTerm: string;
  isSearching: boolean;
  floatTimeSheet: boolean;
  isActiveChooseMembers: boolean;
  membersSearchText: string;
  teamSearchText: string;
  assigneeTabsValue: number;
  sortMember: string;
  deleteModal: boolean;
  isTimeSheetGenerated: boolean;
  isLoading: boolean;
  allTimesheetData: any;
  deleteTimesheetId: string;
  generateTimesheetError: {
    timesheetDateError: string;
    timesheetMembersError: string;
  };
  sortByValue: string;
  isSorting: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class TimesheetManagementControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  allProjectsListAPI: string = "";
  fetchTaskListAPI: string = "";
  getAllTeamsAPI: string = "";
  getAllMembersAPI: string = "";
  fetchTasksAPI: string = "";
  generateTimesheetAPI: string = "";
  getAllTimesheetsAPI: string = "";
  deleteTimesheetAPI: string = "";
  userSessionData: any;
  userToken: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      popoveAnchorEl: null,
      showTimesheetForm: false,
      chooseDateRange: [],
      projectLists: [],
      taskLists: [],
      allTaskLists: [],
      teamLists: [],
      membersLists: [],
      searchedProjects: [],
      selectedOptions: [],
      saveSelectedTaskList: [],
      selectedTaskOptions: [],
      selectedMembers: [],
      selectedTeam: [],
      searchProjectTerm: "",
      isSearching: false,
      floatTimeSheet: false,
      isActiveChooseMembers: false,
      membersSearchText: "",
      teamSearchText: "",
      assigneeTabsValue: 0,
      sortMember: "",
      deleteModal: false,
      isTimeSheetGenerated: false,
      isLoading: false,
      allTimesheetData: [],
      deleteTimesheetId: "",
      generateTimesheetError: {
        timesheetDateError: "",
        timesheetMembersError: "",
      },
      sortByValue: '',
      isSorting: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse || !responseJson || !apiRequestCallId) {
        return;
      }
      switch (apiRequestCallId) {
        case this.getAllTimesheetsAPI:
          this.setState({ isLoading: false });
          this.setState({ allTimesheetData: responseJson.data });
          break;
        case this.allProjectsListAPI:
          const allProjects = responseJson.data.map((project: any) => {
            return {
              title: project.attributes.title,
              id: project.id,
            };
          });
          this.setState({
            projectLists: allProjects,
            isLoading: false
          });

          break;

        case this.getAllTeamsAPI:
          this.setState({
            teamLists: responseJson.data,
            isLoading: false
          });
          break;

        case this.getAllMembersAPI:
          this.setState({
            membersLists: responseJson.members.data,
            isLoading: false
          });
          break;
        case this.fetchTaskListAPI:
          const allTaskLists = responseJson.data.map((tasklist: any) => {
            return {
              title: tasklist.attributes.name,
              id: tasklist.id,
            };
          });
          this.setState({
            taskLists: allTaskLists,
          });
          break;
        case this.fetchTasksAPI:
          const allTasks = responseJson.data.map((task: any) => {
            return {
              title: task.attributes.title,
              id: task.id,
            };
          });

          this.setState({
            allTaskLists: allTasks,
          });
          break;
        case this.generateTimesheetAPI:
          this.setState({
            isLoading: false,
            isTimeSheetGenerated: false,
            showTimesheetForm: false,
          });
          this.emptyTimesheetForm();
          createCommonToastNotification(configJSON.timeSheetSuccess, checkBoxFilled);
          this.getAllTimesheets();
          break;
        case this.deleteTimesheetAPI:
          if (responseJson.success) {
            this.getAllTimesheets();
            this.setState({
              isLoading: false,
              deleteModal: false,
            });
            createCommonToastNotification(configJSON.timeSheetDeleted, checkBoxFilled);
          }
          break;

        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const lang = localStorage.getItem("lang") || "en";
    await (i18n as any).changeLanguage(lang);
    this.getAllTimesheets();
  }

  t(key: any) {
    return (i18n as any).t(key, { ns: "translation" });
  }
  createTimesheetForm = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
      this.setState({
        popoveAnchorEl: event.currentTarget,
        showTimesheetForm: true,
      }, () => this.fetchAllDetails());
    }
    
  };
  fetchAllDetails = () => {
    this.getAllProjects();
      this.getAllTeams();
      this.getAllMembers();
  }
  openFloatCreateTimesheet = () => {
    this.setState({
      floatTimeSheet: true,
      showTimesheetForm: false,
    });
  };
  closeFloatCreateTimesheet = () => {
    this.setState({
      floatTimeSheet: false,
    });
  };
  maximizeTimesheetForm = () => {
    this.setState({
      floatTimeSheet: false,
      showTimesheetForm: true,
    });
  };
  timesheetFormClose = () => {
    this.setState({ popoveAnchorEl: null });
  };
  choosetimesheetDates = (date: any) => {
    this.setState({ chooseDateRange: date });
  };
  openMembersModal = () => {
    this.setState({ isActiveChooseMembers: true });
  };
  closeMembersModal = () => {
    this.setState({ isActiveChooseMembers: false });
  };
  getAllTimesheets = () => {
    const {isSorting, sortByValue} = this.state;
    let sortKey = "";
    if(sortByValue === 'Newest to oldest'){
      sortKey = 'new_to_old'
    }
    else if(sortByValue === 'Oldest to newest'){
      sortKey = 'old_to_new'
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    let endPoint = configJSON.getallTimesheets;

    if(isSorting){
      endPoint += `?sort_by=${sortKey}`
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    this.getAllTimesheetsAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      isLoading : true
    })

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllTeams = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeamsEndPoint
    );
    this.getAllTeamsAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllMembers = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMembersEndPoint
    );
    this.getAllMembersAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllProjects = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectListEndPoint
    );
    this.allProjectsListAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchTasksandTaskLists = () => {
    //this.fetchTasklistByProjects()
    this.fetchSelectedTasks();
  };
  fetchTasklistByProjects = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchTaskListByProject + `=${this.state.selectedOptions}`
    );
    this.fetchTaskListAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchSelectedTasks = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    let url = "sortby=priority&";

    let baseURL = configJSON.fetchTasks;
    if (this.state.selectedOptions !== "") {
      url += `account_block_project_id=[${this.state.selectedOptions}]`;
    }

   
    let endPoint = baseURL + url;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    this.fetchTasksAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleProjectsSearch = (searchValue: any) => {
    this.setState({ searchProjectTerm: searchValue, isSearching: true }, () => {
      this.getSearchResults();
    });
  };
  getSearchResults = () => {
    let projectlist = this.state.projectLists;
    if (this.state.searchProjectTerm !== "") {
      projectlist = projectlist.filter((project) => {
        if (
          project.title
            .toLowerCase()
            .search(this.state.searchProjectTerm.toLowerCase()) !== -1
        )
          return project;
      });
    }
    this.setState({ searchedProjects: projectlist });
  };
  saveSelectedOption = (event: any) => {
    const { value } = event.target;
    this.setState(
      {
        selectedOptions: value,
      },
      () => this.fetchTasksandTaskLists()
    );
  };
  saveSelectedTaskList = (event: any) => {
    const { value } = event.target;
    this.setState(
      {
        saveSelectedTaskList: value,
      },
      () => this.fetchSelectedTasks()
    );
  };
  selectedTaskOptions = (event: any) => {
    const { value } = event.target;
    this.setState({
      selectedTaskOptions: value,
    });
  };
  getLabel = (value: any, def: string) => {
    const selectedItemCount = value.filter(
      (item: any) => item !== undefined
    ).length;
    let message;
    if (selectedItemCount === 0) {
      message = this.t("Choose Contact");
    } else {
      message = `${selectedItemCount} ${this.t(def)}${
        selectedItemCount !== 1 ? "s" : ""
      } ${this.t("Selected")}`;
    }

    if (selectedItemCount >= 1 && this.state.selectedOptions) {
      return <>{message}</>;
    }
  };
  containsText = (text: string, searchText: string) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  handleSearchMembers = (event: { target: { value: string } }) => {
    const searchTxt = event.target.value;
    if (this.state.assigneeTabsValue === 0) {
      this.setState({
        teamSearchText: searchTxt,
      });
    } else {
      this.setState({
        membersSearchText: searchTxt,
      });
    }
  };
  displayMemberOptions = () => {
    const assigneeNames = this.state.membersLists.map(
      (item: {
        id: number;
        attributes: {
          first_name: string;
          last_name: string;
          email: string;
        };
      }) => ({
        id: item.id,
        title: `${item.attributes.first_name} ${item.attributes.last_name}`,
        email: item.attributes.email,
        initials: `${item.attributes.first_name.charAt(
          0
        )}${item.attributes.last_name.charAt(0)}`,
      })
    );
    return assigneeNames.filter((obj: { title: string }) =>
      this.containsText(obj.title, this.state.membersSearchText)
    );
  };
  displayTeamOptions = () => {
    const teamName = this.state.teamLists.map(
      (item: {
        id: number;
        attributes: {
          title: string;
        };
      }) => ({
        id: item.id,
        title: `${item.attributes.title}`,
        initials: `${item.attributes.title.charAt(0)}`,
      })
    );
    return teamName.filter((obj: { title: string }) =>
      this.containsText(obj.title, this.state.teamSearchText)
    );
  };
  setAssigneeTabsValue = (obj: any, val: any) => {
    this.setState((prevState) => ({
      assigneeTabsValue: val,
      teamSearchText:
        prevState.teamSearchText !== "" ? "" : prevState.teamSearchText,
      membersSearchText:
        prevState.membersSearchText !== "" ? "" : prevState.membersSearchText,
    }));
  };
  openDeleteModal = (timesheetId: string) => {
    this.setState({
      deleteModal: true,
      deleteTimesheetId: timesheetId,
    });
  };
  closetimesheetGeneratedModal = () => {
    this.setState({
      isTimeSheetGenerated: false,
    });
  };
  generateTimesheetAPIRequest = () => {
    const {
      chooseDateRange,
      selectedOptions,
      selectedTaskOptions,
      selectedMembers,
      selectedTeam,
    } = this.state;
    let errorFlag = false;
    let timesheetDateRange =
      this.state.generateTimesheetError.timesheetDateError;
    let selectedMembersError =
      this.state.generateTimesheetError.timesheetMembersError;
    if (chooseDateRange.length === 0) {
      timesheetDateRange = "Please choose date range";
      errorFlag = true;
    }

    if (selectedMembers.length === 0) {
      selectedMembersError = "Please choose any team or member";
      errorFlag = true;
    }
    if (errorFlag) {
      this.setState({
        generateTimesheetError: {
          timesheetDateError: timesheetDateRange,
          timesheetMembersError: selectedMembersError,
        },
      });
      return true;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const body = {
      start_date: chooseDateRange[0].format("YYYY-MM-DD"),
      end_date: chooseDateRange[1].format("YYYY-MM-DD"),
      "selected_team_ids": selectedTeam,
      selected_project_ids: selectedOptions,
      selected_member_ids: selectedMembers,
      // "tasklist_ids": [2],
      selected_task_ids: selectedTaskOptions,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generateTimesheet
    );
    this.generateTimesheetAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  closeDeleteModal = () => {
    this.setState({
      deleteModal: false,
    });
  };
  deleteSelectedTimesheet = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteTimesheet + "/" + this.state.deleteTimesheetId
    );
    this.deleteTimesheetAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleMembersClick = (userId: string) => {
    let changeMembers = this.state.selectedMembers;
    if (changeMembers.includes(userId)) {
      changeMembers = changeMembers.filter((x: any) => x !== userId);
    } else {
      changeMembers.push(userId);
    }
    this.setState({
      selectedMembers: changeMembers,
    });
  };
  handleTeamsClick = (teamId: string) => {
    let changeTeams = this.state.selectedTeam;
    if (changeTeams.includes(teamId)) {
      changeTeams = changeTeams.filter((x: any) => x !== teamId);
    } else {
      changeTeams.push(teamId);
    }
    this.setState({
      selectedTeam: changeTeams,
    });
  };
  clearSelectedMembers = () => {
    if (this.state.assigneeTabsValue === 0) {
      this.setState({ selectedTeam: [] });
    } else {
      this.setState({ selectedMembers: [] });
    }
  };
  changeSortByMembers = (e: any) => {
    this.setState({ sortMember: e.target.value });
  };
  sortMemberRecords = (filterData: any) => {
    const { sortMember } = this.state;
    if (sortMember != "") {
      let leftIndex: number;
      let rightIndex: number;
      leftIndex = sortMember == "Z-A" ? 1 : -1;
      rightIndex = sortMember == "Z-A" ? -1 : 1;
      filterData = filterData.sort((member1: any, member2: any) => {
        if (member1.title.toLowerCase() < member2.title.toLowerCase())
          return leftIndex;
        if (member1.title.toLowerCase() > member2.title.toLowerCase())
          return rightIndex;
        return 0;
      });
    }

    return filterData;
  };
  handleTimesheetescevent = (event: any) => {
    event.stopPropagation();
    if (event.key === "Escape") {
      this.openFloatCreateTimesheet();
    }
  };
  emptyTimesheetForm = () => {
    this.setState({
      chooseDateRange: [],
      selectedTaskOptions: [],
      selectedMembers: [],
      selectedOptions: [],
      saveSelectedTaskList: [],
    });
  };
  sortTimesheets = (event: any) => {
    this.setState({
      sortByValue : event.target.value,
      isSorting: true,
    }, () => this.getAllTimesheets() )
  }
  // Customizable Area End
}
