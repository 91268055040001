import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import moment from "moment";
import { getStorageData, isEmpty } from "../../../framework/src/Utilities";
import type { memberListData as SingleAssigneeType } from "./types";
import { InfoType } from "../../../components/src/InfoAlert/InfoAlertController";
import { memberListData, PriorityType } from "./types";
import { number } from "yup";

// export const sortByData = [
//   {
//       id: 0,
//       value: 'Priority'
//   },
//   {
//       id: 1,
//       value: 'Deadline'
//   }
// ]

// export const mockTaskListData = [
//   {
//     id: '0',
//     title: 'Red Wine Photoshoot. Red Wine Photoshoot. Red Wine Photoshoot. Red Wine Photoshoot. ',
//   },
//   {
//     id: '1',
//     title: 'White Wine Photoshoot'
//   }
// ]
// Customizable Area End
const globalJSON = require('../../../components/src/globalConfig/config')
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  searchTerm: string;
  filteredData: any;
  sortValue: number | null;
  selectedTasks: string[]

  taskTitle: any;
  taskDueDate: any;
  taskDeadline: any;
  taskDescription: any;
  taskTitleError: boolean;
  taskDueDateError: boolean;
  taskDeadlineError: boolean;
  taskDescriptionError: boolean;
  taskRangeError: boolean;
  taskHourError: boolean;
  taskAssigneeError: boolean;

  showDueDateCalendar: boolean;
  showDeadlineCalendar: boolean;
  isLoading: boolean;

  showCalendarRange: boolean;
  showTaskHours: boolean;
  taskHours: any;
  taskMinutes: any;
  timeSelected: any;

  selectTaskRange: any;

  isInfoAlertVisible: boolean;
  infoType: InfoType;
  infoMessage: string;

  showAssigneeModal: boolean;
  memberDetails: Array<memberListData>;
  tempMemberList: Array<memberListData>;
  tempMemberIdList: Array<any>;
  hourlyRateList: Array<any>;
  userId: any;
userHourlyPay: any;
  actualUserDetails: any;
  editHoursY: number;
  // details: any;
  // selectedDate: string;
  // timeSlots: object[];
  // selectedTime: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateTaskController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  userProfileRequestId: string = ''
  memberListRequestId: string = ''
  createTaskFromRequestId: string = ''
  RBSheet1: { open: Function, close: Function } = { open: () => {}, close: () => {} };
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      searchTerm: '',
      filteredData: [],
      sortValue: null,
      selectedTasks: [],

      taskTitle: "",
      taskDueDate: null,
      taskDeadline: null,
      taskDescription: "",
      taskTitleError: false,
      taskDueDateError: false,
      taskDeadlineError: false,
      taskDescriptionError: false,
      taskRangeError: false,
      taskHourError: false,
      taskAssigneeError: false,

      showDueDateCalendar: false,
      showDeadlineCalendar: false,
      isLoading: false,

      showCalendarRange: false,
      showTaskHours: false,
      taskHours: "",
      taskMinutes: "",
      timeSelected: "",

      selectTaskRange: 0,

      isInfoAlertVisible: false,
      infoType: 'success',
      infoMessage: '',
      showAssigneeModal: false,
      memberDetails: [],
      tempMemberList: [],
      tempMemberIdList: [],
      hourlyRateList: [],
      userId: "",
      userHourlyPay: "",
      actualUserDetails: {},
      editHoursY: 0,
      // details: {},
      // selectedDate: moment().format("YYYY-MM-DD"),
      // timeSlots: [],
      // selectedTime: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message): Promise<void> {
    runEngine.debugLog("Message Recived", message);

    // if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
    //   let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    //   this.showAlert(
    //     "Change Value",
    //     "From: " + this.state.txtSavedValue + " To: " + value
    //   );

    //   this.setState({ 
    //     txtSavedValue: value,
    //   });
    // }

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) == message.id){
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage))

      this.setState({isLoading: false})
      console.log("ResponseData",{apiRequestCallId, responseJson, errorResponse})
      
      
      if (apiRequestCallId && responseJson)
      {        
        // for getting members to assign task
        if (apiRequestCallId == this.memberListRequestId){
          console.log("Success")
          console.log("MemberListData: ", responseJson.members.data)
          console.log("MemberName", responseJson.members.data[0]?.attributes?.first_name);
          
          let tempData: SingleAssigneeType[] = responseJson.members.data
          tempData.push(this.state.actualUserDetails)
          console.log("member details: ", tempData);
          
          this.setState({
            // filter out deactivated members right here
            memberDetails: tempData.filter(assignee => !assignee.attributes.deactivated)
          })

        // for getting user id to assign task
        }else if(apiRequestCallId == this.userProfileRequestId){
          console.log("Success")
          console.log("UserProfileData: ", responseJson.data)
          console.log("UserId", `${[responseJson.data?.id]}`);
          
          
          this.setState({
            userId: responseJson.data?.id,
            actualUserDetails: responseJson.data,
            userHourlyPay: responseJson.data?.attributes?.hourly_pay
          })
          this.fetchMemberList()
        // create a task
        }else if (apiRequestCallId == this.createTaskFromRequestId){
          console.log("Success")
          console.log("TaskCreatedData: ", responseJson)
          
          this.setState({
            taskTitle: "",
            taskDueDate: "",
            taskDeadline: "",
            taskDescription: "",
            selectTaskRange: 0,
            taskHours: "",
            taskMinutes: "",
            timeSelected: "",
            tempMemberList: [],
            tempMemberIdList: [],

            isInfoAlertVisible: true,
            infoType: 'success',
            infoMessage: 'Task created successfully',
          })

          setTimeout(() => {
            this.props.navigation.goBack()
          }, 2000);
        } 
      }
    }
    // Customizable Area End
  }

  calendarProps = {
    minDate: moment(),
    onSelectDate: (selectedDate: string) => this.onSelectDate(selectedDate)
  };

  onSelectDate = (selectedDateStr: string) => {
  };
  onTaskHoursSavePress = () => {
    {
      (this.state.taskHours === "" || this.state.taskHours === "00") && (this.state.taskMinutes === "" || this.state.taskMinutes === "00")?
      this.setState({timeSelected: ""}) 
      : this.state.taskHours === "" || this.state.taskHours === "00" ?
      this.setState({timeSelected: "00:"+this.state.taskMinutes}) 
      : this.state.taskMinutes === "" || this.state.taskMinutes === "00" ?
      this.setState({timeSelected: this.state.taskHours+":00"}) 
      : this.setState({timeSelected: this.state.taskHours + ":" + this.state.taskMinutes})
    }
    this.setState({ showTaskHours: false, taskHourError: false })
  }
  // web events
  // setInputValue = (text: string) => {
  //   this.setState({ txtInputValue: text });
  // };

  getPriorityMark = (mark: number) => {
    return ['!!!', '!!', '!!!'][mark]
  }

  fetchMemberList = async() =>{
    console.log('-- enter fetchMemberList --')

    let token = await getStorageData('token')
    // const token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTEwNCwiZXhwIjoxNzEyMTI0ODM4LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.6Upbv1mpQNBzKLMfMP1i5fFzfk5UUedlh2iu3gwomUahIQAAZlJmFFXrB-qz2uOr-TaDk59dX-uEp-J32gksPA";
    const header = {
        "Accept": "*/*",
        "token": token
    };
    
    let endPoint = globalJSON.baseURL + configJSON.memberListEndPoint

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    )

    this.memberListRequestId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    )
    
    //* Adding header

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    )
    
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET               
    )
      
    console.log("requestMessage: ", requestMessage); 
    //* Making Network Request

    runEngine.sendMessage(requestMessage.id, requestMessage)

    console.log('-- exit fetchMemberList')
  }

  fetchUserProfile = async() =>{

    let token = await getStorageData('token')
    const header = {
        "Accept": "*/*",
        "token": token
    };
    
    let endPoint = globalJSON.baseURL + configJSON.userProfileEndPoint;

    console.log('#end-point :', endPoint)

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    )

    this.userProfileRequestId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    )
    
    //* Adding header

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    )
    
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET               
    )
      
    console.log("requestMessage: ", requestMessage); 
    //* Making Network Request

    runEngine.sendMessage(requestMessage.id, requestMessage)

  }

  createTaskApi = async(assignees: any) => {
    let labour_cost = 0;

    for (let i=0; i<this.state.hourlyRateList.length; i++) {
        labour_cost += this.state.hourlyRateList[i] * this.state.taskHours/this.state.tempMemberIdList.length;      
    }

    //* createTask Request

    this.setState({isLoading: true})
    
    let token = await getStorageData('token')

    const header = {
      "Content-Type": "application/json",
      "token": token
    }

    const body = {
      "data":{
        "title":this.state.taskTitle,
        "due_date":this.state.taskDueDate, 
        "deadline_date":this.state.taskDeadline ?? moment(this.state.taskDueDate).add(14, 'day').format('YYYY-MM-DD'),
        "description": this.state.taskDescription, 
        "labour_cost": this.state.tempMemberIdList.length? labour_cost : this.state.userHourlyPay*this.state.taskHours,
        "hours": !isEmpty(this.state.timeSelected) ? this.state.timeSelected : "02:00",
        "priority": this.state.selectTaskRange,
        "status":0,
        "assignees": assignees
      }
    }

    let endPoint = globalJSON.baseURL + configJSON.createTaskEndPoint;

    const requestMessage:Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.createTaskFromRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createTaskApiMethod                      
    )

    console.log(requestMessage)

    //* Making Network Request

    runEngine.sendMessage(requestMessage.id, requestMessage)

    //? Making Network Request end
    
  }

  addItemToTempList = (item: any) => {
    let tempMemberArray = [...this.state.tempMemberList]
    tempMemberArray.push(item);
    this.setState({tempMemberList: tempMemberArray})

    let tempMemberIdArray = [...this.state.tempMemberIdList]
    tempMemberIdArray.push(item.id);
    this.setState({tempMemberIdList: tempMemberIdArray})

    let tempHoulyRateArray = [...this.state.hourlyRateList]
    tempHoulyRateArray.push(item.attributes?.hourly_pay);
    this.setState({hourlyRateList: tempHoulyRateArray})

    this.setState({taskAssigneeError: false,
      memberDetails: this.state.memberDetails.filter((member) =>{ 
        return member !== item
        
      })
    })
  }

  onCreateTaskPress = () => {
    if((this.state.taskTitle === "") || isEmpty(this.state.taskTitle.trim())){
      this.setState({taskTitleError: true})
      return
    }
    if(this.state.taskDueDate === null){
      this.setState({taskDueDateError: true})
      return
    }
    // }else if(this.state.taskDeadline === null){
      //   this.setState({taskDeadlineError: true})
      // }else if(this.state.taskDescription === ""){
        //   this.setState({taskDescriptionError: true})
    if(this.state.selectTaskRange === ""){
      this.setState({taskRangeError: true})
      return
    }
    // if(this.state.taskHours === ""){
    //   this.setState({taskHourError: true})
    //   return
    // }
    // if(this.state.tempMemberIdList.length === 0){
      //   this.setState({taskAssigneeError: true})
      //   return
      // }
      
    // if(this.state.tempMemberIdList.length === 0){
    //   this.setState({
    //     isLoading: true
    //   })
    //   this.fetchUserProfile()
    //   return
    // }
    this.createTaskApi(this.state.tempMemberIdList.length ? this.state.tempMemberIdList : [this.state.userId])
  }
  // Customizable Area End
}
