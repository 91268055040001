// Customizable Area Start
import React from "react";
import Box from "@material-ui/core/Box";
import { Typography,Modal,Button,FormControl ,Select,MenuItem} from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  close,
  pencil
} from "../../blocks/ContactsList/src/assets";
import { configJSON } from "../../blocks/ContactsList/src/GroupListController.web";
import { getImageForSubCategory } from "./ReusableFunctions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
interface CustomInputProps {
  modalOpen: any;
  modalClose: any;
  classes: any;
  selectedProductsData: any;
  openEdit?:any;
  t:any;
}

const ProductInventoryViewModal = (props: CustomInputProps) => {
  const { modalOpen, classes, modalClose, selectedProductsData,openEdit , t } = props;
  console.log("product selected",selectedProductsData)
  return (
    <Modal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalBoxCenter}
    >
      <Box className={classes.productListViewModal}>
        <Box className="modal-heading">
          <Box className="heading-left">
            <Typography className="modal-title" component="h2">
             {selectedProductsData.length}&nbsp;
             {selectedProductsData.length>1? t("Items"): t("Item")}
            </Typography>
            <Box component="p" className="sub-txt">
              {t(`The following items have been added to this task.`)}
            </Box>
          </Box>
          <Box className="heading-right">            
            <Link to="#" className="heading-icon" onClick={modalClose}>
              <img src={close} alt="close-icon" />
            </Link>
          </Box>
        </Box>
        <Typography className="product-headding">
          {t(`Products`)}
         </Typography>
        {selectedProductsData.map((product:any)=>{
           console.log("product selected", product.attributes.category)
         return<Box className={selectedProductsData.length === 1 ? "relative-width-remove-border":"relative-width"} key={product.id}>
         <Box className="product-card" >
          {getImageForSubCategory(product.attributes.sub_category.id, product.attributes)}
         <Box className="card-content">
           <Typography className="headding">{product.attributes.name}</Typography>
           <Box className="retail-content">
           <FormControl className={classes.productsFormControl}>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  IconComponent={(props) => <ExpandMoreIcon {...props} />}
                  displayEmpty
                  defaultValue={t(`${configJSON.ShowAsOption1}`)}
                  data-test-id="PriceDropdown"
                  disableUnderline
                  // value={showPriceAs}
                  // onChange={changePrice}
                >
                  <MenuItem value="Retail Price">
                    {t(`${configJSON.ShowAsOption1}`)}
                  </MenuItem>
                  <MenuItem value="Wholesale Price">
                    {t(`${configJSON.ShowAsOption2}`)}
                  </MenuItem>
                  <MenuItem value="Normal Price">
                    {t(`${configJSON.ShowAsOption3}`)}
                  </MenuItem>
                </Select>
              </FormControl>
              <Typography className="itemPrice">
                ${(product?.attributes?.prices[0]?.amount || 0).toFixed(2)}
              </Typography>
           </Box>
         </Box>
         <Box className="price-txt">
          <DeleteOutlineIcon/>   
         </Box>
       </Box>
       </Box>
        })}

        <Typography className="product-headding">
          {t(`Tools`)}
         </Typography>
        {selectedProductsData.map((product:any)=>{
         return<Box className={selectedProductsData.length === 1 ? "relative-width-remove-border":"relative-width"} key={product.id}>
         <Box className="product-card" >
          {getImageForSubCategory(product.attributes.sub_category.id, product.attributes)}
         <Box className="card-content">
           <Typography className="headding">{product.attributes.name}</Typography>
           <Box className="retail-content">
           <FormControl className={classes.productsFormControl}>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  IconComponent={(props) => <ExpandMoreIcon {...props} />}
                  displayEmpty
                  defaultValue={t(`${configJSON.ShowAsOption1}`)}
                  data-test-id="PriceDropdown"
                  disableUnderline
                  // value={showPriceAs}
                  // onChange={changePrice}
                >
                  <MenuItem value="Retail Price">
                    {t(`${configJSON.ShowAsOption1}`)}
                  </MenuItem>
                  <MenuItem value="Wholesale Price">
                    {t(`${configJSON.ShowAsOption2}`)}
                  </MenuItem>
                  <MenuItem value="Normal Price">
                    {t(`${configJSON.ShowAsOption3}`)}
                  </MenuItem>
                </Select>
              </FormControl>
              <Typography className="itemPrice">
                ${(product?.attributes?.prices[0]?.amount || 0).toFixed(2)}
              </Typography>
           </Box>
         </Box>
         <Box className="price-txt">
          <DeleteOutlineIcon/>   
         </Box>
       </Box>
       </Box>
        })}

       <Typography className="product-headding">
          {t(`Assets`)}
         </Typography>
        {selectedProductsData.map((product:any)=>{
         return<Box className={selectedProductsData.length === 1 ? "relative-width-remove-border":"relative-width"} key={product.id}>
         <Box className="product-card" >
          {getImageForSubCategory(product.attributes.sub_category.id, product.attributes)}
         <Box className="card-content">
           <Typography className="headding">{product.attributes.name}</Typography>
           <Box className="retail-content">
           <FormControl className={classes.productsFormControl}>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  IconComponent={(props) => <ExpandMoreIcon {...props} />}
                  displayEmpty
                  defaultValue={t(`${configJSON.ShowAsOption1}`)}
                  data-test-id="PriceDropdown"
                  disableUnderline
                  // value={showPriceAs}
                  // onChange={changePrice}
                >
                  <MenuItem value="Retail Price">
                    {t(`${configJSON.ShowAsOption1}`)}
                  </MenuItem>
                  <MenuItem value="Wholesale Price">
                    {t(`${configJSON.ShowAsOption2}`)}
                  </MenuItem>
                  <MenuItem value="Normal Price">
                    {t(`${configJSON.ShowAsOption3}`)}
                  </MenuItem>
                </Select>
              </FormControl>
              <Typography className="itemPrice">
                ${(product?.attributes?.prices[0]?.amount || 0).toFixed(2)}
              </Typography>
           </Box>
         </Box>
         <Box className="price-txt">
          <DeleteOutlineIcon/>   
         </Box>
       </Box>
       </Box>
        })}

       <Typography className="product-headding">
          {t(`Consumables`)}
         </Typography>
        {selectedProductsData.map((product:any)=>{        
         return<Box className={selectedProductsData.length === 1 ? "relative-width-remove-border":"relative-width"} key={product.id}>
         <Box className="product-card" >
          {getImageForSubCategory(product.attributes.sub_category.id, product.attributes)}
         <Box className="card-content">
           <Typography className="headding">{product.attributes.name}</Typography>
           <Box className="retail-content">
           <FormControl className={classes.productsFormControl}>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  IconComponent={(props) => <ExpandMoreIcon {...props} />}
                  displayEmpty
                  defaultValue={t(`${configJSON.ShowAsOption1}`)}
                  data-test-id="PriceDropdown"
                  disableUnderline
                  // value={showPriceAs}
                  // onChange={changePrice}
                >
                  <MenuItem value="Retail Price">
                    {t(`${configJSON.ShowAsOption1}`)}
                  </MenuItem>
                  <MenuItem value="Wholesale Price">
                    {t(`${configJSON.ShowAsOption2}`)}
                  </MenuItem>
                  <MenuItem value="Normal Price">
                    {t(`${configJSON.ShowAsOption3}`)}
                  </MenuItem>
                </Select>
              </FormControl>
              <Typography className="itemPrice">
                ${(product?.attributes?.prices[0]?.amount || 0).toFixed(2)}
              </Typography>
           </Box>
         </Box>
         <Box className="price-txt">
          <DeleteOutlineIcon/>   
         </Box>
       </Box>
       </Box>
        })}     
       <Button className={`primary-btn ${classes.primaryButton}`} onClick={openEdit}>
               + {t(`Add Another Item`)}
         </Button>      
         </Box>
    </Modal>
  );
};

export default ProductInventoryViewModal;
// Customizable Area End
