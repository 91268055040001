// Customizable Area Start
import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { configJSON } from "../MemberMovetoTeamController";

interface RemoveMemberProps {
  modalOpen: boolean;
  modalClose: () => void;
  removeMemDetails: { firstName: string; lastName: string; memID: string };
  teamName: string;
  removeMemAction: () => void;
  selectedMem: number[];
  t:any;
}
const useStyles = makeStyles((theme) => ({
  modalDialogBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 580,
    backgroundColor: "#ffffff",
    padding: "48px",
    borderRadius: "24px",
    // [theme.breakpoints.down("md")]: {
    //   padding: "35px 35px 20px",
    //   width: 520,
    // },
    // [theme.breakpoints.down("sm")]: {
    //   padding: "25px 25px 20px",
    //   maxWidth: "90%",
    // },
    [theme.breakpoints.down("xs")]: {
      padding: "24px",
      maxWidth: "327px",
      width: "90%",
      borderRadius: "12px",
      backgroundColor: "#F2F8FC",
    },
    "& .modal-heading": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "22px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "23px",
      },
    },
    "& .modal-title": {
      fontSize: "24px",
      lineHeight: "30px",
      color: "#000000",
      letterSpacing: "0",
      fontFamily: "Expletus Sans",
      fontWeight: 400,
      // [theme.breakpoints.down("md")]: {
      //   fontSize: "20px",
      //   lineHeight: "24px",
      // },
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
        lineHeight: "24px",
      },
    },
    "& .modal-desc": {
      fontSize: "14px",
      letterSpacing: "0.0075em",
      lineHeight: "26px",
      color: "#2b2b2b",
      fontWeight: 400,
      fontFamily: "Roboto",
      [theme.breakpoints.down("xs")]: {
        fontSize: "13px",
        lineHeight: "17px",
      },
      "& .title-dark": {
        fontWeight: "700",
      },
    },
    "& .close-icon": {
      color: "rgba(43, 43, 43, 0.87)",
      lineHeight: "0",
    },
    "& .modal-footer": {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "32px",
      [theme.breakpoints.down("xs")]: {
        marginTop: "24px",
      },
      "& .primary-btn, & .secondary-btn ": {
        width: "auto",
        minWidth: "157px",
        [theme.breakpoints.down("xs")]: {
          minWidth: "70px",
          padding: "4px 6px",
        },
      },
      "& .MuiButton-root": {
        marginRight: "24px",
        "&:last-child": {
          marginRight: "0",
        },
      },
    },
    "& .primary-btn": {
      minHeight: "48px",
      fontSize: "14px",
      lineHeight: "19px",
      marginBottom: "0",
      [theme.breakpoints.down("xs")]: {
        minHeight: "30px",
      },
    },
    "& .secondary-btn": {
      minHeight: "48px",
      fontSize: "14px",
      lineHeight: "19px",
      marginBottom: "0",
      [theme.breakpoints.down("xs")]: {
        minHeight: "30px",
        border: "0",
        color: "#4BA3B7",
      },
    },
  },
  havingTroubleModal: {
    flex: "0 0 51%",
    maxWidth: "51%",
    padding: "0 15px",
    "& .auth-logo": {
      maxWidth: "162px",
      marginBottom: "90px",
    },
    "& .auth-inner": {
      paddingTop: "129px",
      "& .auth-left-row": {
        display: "flex",
        alignItems: "flex-end",
        flexWrap: "wrap",
        margin: "0 -10px",
        "& .col": {
          padding: "0 10px",
        },
        "& .col-left": {
          flex: "0 0 54%",
          maxWidth: "54%",
        },
        "& .col-right": {
          flex: "0 0 46%",
          maxWidth: "46%",
          paddingRight: "206px",
          "@media (max-width: 1536px)": {
            paddingRight: "95px",
          },
        },
      },
    },
    "& .auth-desc": {
      fontSize: "24px",
      lineHeight: "28px",
      color: "#000000",
      fontWeight: 400,
      marginBottom: "19px",
    },
    "& .auth-name": {
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0",
      color: "#94a3b8",
      fontWeight: 400,
    },
    "& .auth-img": {
      margin: "61px auto 0",
      maxWidth: "380px",
      width: "100%",
      height: "630px",
      overflow: "hidden",
      position: "relative",
      marginBottom: "-50px",
    },
    "& .copyright-txt": {
      fontSize: "12px",
      letterSpacing: "0",
      color: "#94a3b8",
      fontWeight: 400,
      fontFamily: "Expletus Sans",
      textAlign: "right",
      "& span": {
        color: "#2b2b2b",
      },
    },
  },
  modalDialog: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(57,57,57,0.502)",
    },
  },
  primaryButton: {
    background: "#4ba3b7",
    border: "1px solid #4ba3b7",
    borderRadius: "6px",
    filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
    fontSize: "16px",
    lineHeight: "21px",
    color: "#f2f8fc",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    width: "100%",
    padding: "12px 10px",
    minHeight: "56px",
    marginBottom: "30px",
    transition: "all 0.5s ease-in-out",
    textTransform: "capitalize",
    "&:hover": {
      background: "#4ba3b7",
      color: "#ffffff",
    },
    "&.Mui-disabled": {
      background: "#92C7D3",
      color: "#F2F8FC",
      borderColor: "#92C7D3",
    },
  },
  secondaryButton: {
    background: "transparent",
    border: "1px solid #e8ecf2",
    borderRadius: "7px",
    filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
    fontSize: "19px",
    lineHeight: "23px",
    color: "#2b2b2b",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    textTransform: "unset",
    width: "100%",
    padding: "12px 10px",
    minHeight: "56px",
    marginBottom: "30px",
    transition: "all 0.5s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
      padding: "8px 6px",
      minHeight: "46px",
    },
    "&:hover": {
      background: "transparent",
      color: "#2b2b2b",
    },
  },
}));
const RemoveMemberTeam = (props: RemoveMemberProps) => {
  const {
    modalOpen,
    modalClose,
    removeMemDetails,
    teamName,
    removeMemAction,
    selectedMem,
    t
  } = props;
  const classes = useStyles();
  return (
    <Box className={classes.havingTroubleModal}>
      <Modal
        open={modalOpen}
        onClose={modalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
      >
        <Box className={classes.modalDialogBox}>
          <Box className="modal-heading">
            <Typography className="modal-title" component="h2">
              {t(`${configJSON.removeMemberTxt}`)}
            </Typography>
            <Link to="#" className="close-icon" onClick={modalClose}>
              <CloseOutlinedIcon />
            </Link>
          </Box>
          <Box className="modal-description">
            <Typography className="modal-desc">
              {selectedMem.length > 1 ? (
                <>
                {t(configJSON.removeMembersConfirmationTxt, { teamName: teamName })}               
                </>
              ) : (
                <>
                  {t(`${configJSON.removeConfirmationStart}`)}&nbsp;
                  <span className="title-dark">
                    {`${removeMemDetails.firstName} ${removeMemDetails.lastName}`}
                  </span>
                  &nbsp;{t(configJSON.removeConfirmationEnd, { teamName: teamName })}
                </>
              )}
            </Typography>

            <Box className="modal-footer">
              <Button
                className={`secondary-btn ${classes.secondaryButton}`}
                title="Cancel"
                onClick={modalClose}
              >
               {t(`${configJSON.txtCancel}`)}
              </Button>
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                title="Knowledge Base"
                onClick={removeMemAction}
              >
               {t(`${configJSON.txtRemove}`)}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default RemoveMemberTeam;
// Customizable Area End
