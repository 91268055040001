// Customizable Area Start
import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import {
  useStyles,
  createData,
  formatDate,
  getComparator,
  stableSort,
  TeamMemberHead,
  MembersData,
} from "./CustomTable.web";
import { deleteOutline } from "../assets";

type Order = "asc" | "desc";

export const configJSON = require("../config");

export default function UnAssignedMembersTable(props: any) {
  const {
    renderUnAssignedMembers,
    hideActionColumn,
    editAction,
    moveMemberAction,
    getSelectedRecords,
    multipleDelete,
    userRole
  } = props;
  let rows: any;
  rows = renderUnAssignedMembers.map((member: any) => {
    return createData(
      member.id,
      member.attributes.original_owner,
      member.attributes.first_name,
      member.attributes.last_name,
      member.attributes.email,
      member.attributes.team,
      member.attributes.job_role,
      member.attributes.job_type,
      member.attributes.created_at,
      member.attributes.hourly_pay,
      member.attributes.deactivated,
      member.attributes.country,
      member.attributes.state,
      member.attributes.postal_code,
      member.attributes.city,
      member.attributes.company_place_address,
      member.attributes.company_name
    );
  });

  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof MembersData>("name");
  const [selected, setSelected] = React.useState<any[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(configJSON.RowsPerPage);
  console.log(getSelectedRecords)

  useEffect(() => {
    if (
      getSelectedRecords !== null &&
      typeof getSelectedRecords === "function"
    ) {
      getSelectedRecords(selected);
    }
  }, [selected]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => (n.memId ? n.memId : n.name));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (name: any) => {
    const selectedIndex = selected.find((x: any) => x == name);
   
    let newSelected: any[] = [];

    if (selectedIndex == null) {
      newSelected = [...selected, name];
    } else {
      newSelected = [...selected.filter((x: any) => x != name)];
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPageUnassigned = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Box>
        <Box className={classes.paginationToolbar}>
          <Box className="total-member">
            <Box className="member-count">
              {!hideActionColumn && (
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < rows.length
                  }
                  checked={rows.length > 0 && selected.length === rows.length}
                  onChange={handleSelectAllClick}
                  data-test-id="handle-select-all"
                />
              )}
              {selected.length > 0 ? selected.length : rows.length} Members
              {selected.length > 0 && "   Selected"}
              {selected.length > 0 && (
                <Box className="delete-icon" onClick={multipleDelete}>
                  {/* <PermMediaIcon /> */}
                  <img src={deleteOutline} alt="deleteOutline" />
                </Box>
              )}
            </Box>
          </Box>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPageUnassigned}
            className={classes.paginationTable}
          />
        </Box>
        <TableContainer>
          <Table
            className={`view-memberList ${classes.table}`}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <TeamMemberHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              tableType="members"
              membersType={true}
              hideActionColumn={hideActionColumn}
              userRole={userRole}
              moduleType={"teams"}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((unassignedrow, index) => {
                  const isItemSelected = isSelected(
                    unassignedrow.memId ? unassignedrow.memId : unassignedrow.name
                  );
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const deActivateClass = `${unassignedrow.deactivated &&
                    "disable-member-row"}`;
                    const unAsssignedFields = {
                      memId: unassignedrow.memId,
                      isOriginalOwner: unassignedrow.isOriginalOwner,
                      firstName: unassignedrow.name,
                      email: unassignedrow.email,
                      team: unassignedrow.team,
                      lastName: unassignedrow.lastname,
                      jobType: unassignedrow.job_type,
                      jobRole: unassignedrow.job_role,
                      hourly_pay: unassignedrow.hourly_pay,
                      isDeactivated: unassignedrow.deactivated,
                      joinDate: formatDate(unassignedrow.joinDate),
                      businessInfo: {
                        country: unassignedrow.country,
                        state: unassignedrow.state,
                        postal_code: unassignedrow.postal_code,
                        city: unassignedrow.city,
                        company_place_address: unassignedrow.company_place_address,
                        company_name: unassignedrow.company_name,
                      },
                    }

                  return (
                    <TableRow
                      hover
                      onClick={() =>
                        handleClick(unassignedrow.memId ? unassignedrow.memId : unassignedrow.name)
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={unassignedrow.memId}
                      selected={isItemSelected}
                      className={deActivateClass}
                      data-test-id="unAssignedListcheckbox"
                    >
                      <TableCell padding="checkbox">
                        <Checkbox />
                      </TableCell>
                      <TableCell
                        className="name-cell"
                        component="th"
                        id="myid"
                        scope="row"
                        padding="none"
                      >
                        <Box className="name-col">
                          {unassignedrow.name} {unassignedrow.lastname}
                        </Box>
                      </TableCell>
                      <TableCell className="email-cell">{unassignedrow.email}</TableCell>
                      <TableCell className="team-cell">
                        {formatDate(unassignedrow.joinDate)}
                      </TableCell>
                      <TableCell className="action-link">
                        {userRole === 'owner' && 
                          <Link
                          to="#"
                          onClick={() =>
                            editAction(
                              unAsssignedFields
                            )
                          }
                          title="Edit"
                          className="table-link"
                          data-testid="editAction-unassigned"
                        >
                          Edit
                        </Link>
                        }
                        
                        <Link
                          to="#"
                          onClick={() => moveMemberAction(unassignedrow.memId)}
                          title="Deactivate"
                          className="table-link"
                          data-testid="moveAction-unassigned"
                        >
                          Move to
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
// Customizable Area End