export const onBoardingOne = require("../assets/Onboarding_One.png");
export const onBoardingTwo = require("../assets/Onboarding_Two.png");
export const onboardingThree = require("../assets/Onboarding_Three.png");
export const onboardingFour = require("../assets/Onboarding_Four.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const onBoardBackground = require("../assets/EtOH_background.png");
export const EtOHLogo = require("../assets/EtOH-suite-logo.png");
export const languageIcon = require("../assets/languageIcon.png");
export const appIcon1 = require("../assets/appImg1.png");
export const appIcon2 = require("../assets/appImg2.png");
export const mvpImg1 = require("../assets/mvp-img1.png");
export const mvpImg2 = require("../assets/mvp-img2.png");
export const mvpImg3 = require("../assets/mvp-img3.png");

