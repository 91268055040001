import React from "react";
// Customizable Area Start
import { Box, Typography, Modal, Switch, List, ListItem, ListItemIcon } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme
} from "@material-ui/core/styles";
import {
  beerImg,
  tractorImg,
  vesselImg,
  landImg,
  productsImg,
  toolsImg,
  wineImg,
  nailImg,
  assetsImg,
  comsumable,
  fuelImg,
  fuelIcon,
  testtubeImg,
  otherProducts,
  spiritImg
} from "./assets";
import CloseIcon from "@material-ui/icons/Close";
import { customStyles } from "../../../components/src/Inventory/CategoriessubcategoriesStyles.web";

import CategoriesSubcategoriesController, {
  Props,
  configJSON
} from "./CategoriesSubcategoriesController.web";

// Customizable Area End

export class Categoriessubcategories extends CategoriesSubcategoriesController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  render() {
    const { classes, openModal, closeModal } = this.props;
    return (
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.ModalDialog}
        data-testId="categoryModal"
      >
        <Box className={classes.modalDialogBox}>
          <Box className="modal-heading">
            <Typography className="modal-title" component="h2">
              {this.t(`${configJSON.modalTitle}`)}
            </Typography>
            <CloseIcon
              className="close-icon"
              onClick={closeModal}
              data-testId="closeIcon"
            />
          </Box>
          <Box className="modal-description">
            <Box component="p" className="sub-txt">
              {this.t(`${configJSON.modalSubText}`)}
            </Box>
            <Box className="categoriesInfo">
              <Box className="categoryBox">
                <Box className="left-content">
                  <ListItemIcon>
                    <img
                      src={productsImg}
                      alt="product img"
                      className="categoryImg"
                    />
                  </ListItemIcon>
                  <Typography className="categoryText">
                   {this.t(`${configJSON.catergoryName1}`)}
                  </Typography>
                </Box>                
                <Switch
                  data-test-id="categoryProduct"
                  name="Product"
                  checked={this.state.switchCategory.Product}
                  onChange={this.handleCategory}
                  className="switchStyle"
                />
              </Box> 
              <List className="subCategoryList">
                <ListItem className="subCategoryBox">
                  <Box className="left-content">
                    <ListItemIcon>
                      <img
                        src={wineImg}
                        alt="product img"
                        className="subCategoryImg"
                      />
                    </ListItemIcon>
                    <Typography className="subCategoryText">
                      {this.t(`${configJSON.wineText}`)}
                    </Typography>
                  </Box>
                  <Switch
                    name="Wine"
                    data-test-id="subcategoryWine"
                    checked={this.state.switchSubCategory.Wine}
                    onChange={this.handleSubCategory}
                    className="switchStyle"
                  />
                </ListItem>
                <ListItem className="subCategoryBox">
                  <Box className="left-content">
                    <ListItemIcon>
                      <img
                        src={beerImg}
                        alt="product img"
                        className="subCategoryImg"
                      />
                    </ListItemIcon>
                    <Typography className="subCategoryText">
                      {this.t(`${configJSON.beerText}`)}
                    </Typography>
                  </Box>
                  <Switch
                  data-test-id="subcategoryBeer"
                    checked={this.state.switchSubCategory.Beer}
                    onChange={this.handleSubCategory}
                    className="switchStyle"
                    name="Beer"
                  />
                </ListItem>              
                <ListItem className="subCategoryBox">
                  <Box className="left-content">
                    <ListItemIcon>
                      <img
                        src={spiritImg}
                        alt="product img"
                        className="subCategoryImg"
                      />
                    </ListItemIcon>
                    <Typography className="subCategoryText">
                      {this.t(`${configJSON.spiritText}`)}
                    </Typography>
                  </Box>
                  <Switch
                    data-testId="selectSubcategory"
                    checked={this.state.switchSubCategory.Spirit}
                    onChange={this.handleSubCategory}
                    className="switchStyle"
                    name="Spirit"
                  />
                </ListItem>              
                <ListItem className="subCategoryBox">
                  <Box className="left-content">
                    <ListItemIcon>
                      <img
                        src={otherProducts}
                        alt="product img"
                        className="subCategoryImg"
                    />
                    </ListItemIcon>
                    <Typography className="subCategoryText">
                      {this.t(`${configJSON.otherText}`)}
                    </Typography>
                  </Box>
                  <Switch
                    checked={this.state.switchSubCategory.OtherProduct}
                    onChange={this.handleSubCategory}
                    className="switchStyle"
                    name="OtherProduct"
                  />
                </ListItem>
              </List>                         
            </Box>
            {/* second category */}
            <Box className="categoriesInfo">
              <Box className="categoryBox">
                <Box className="left-content">
                  <ListItemIcon>
                    <img src={toolsImg} alt="product img" className="categoryImg" />
                  </ListItemIcon>
                  <Typography className="categoryText">
                    {this.t(`${configJSON.catergoryName2}`)}
                  </Typography>
                </Box>
                <Switch
                  data-test-id="categoryTool"
                  checked={this.state.switchCategory.Tool}
                  onChange={this.handleCategory}
                  className="switchStyle"
                  name="Tool"
                />
              </Box>  
              <List className="subCategoryList">
                <ListItem className="subCategoryBox">
                  <Box className="left-content">
                    <ListItemIcon>
                      <img
                        src={tractorImg}
                        alt="product img"
                        className="subCategoryImg"
                      />
                    </ListItemIcon>
                    <Typography className="subCategoryText">
                      {this.t(`${configJSON.machineText}`)}
                    </Typography>
                  </Box>
                  <Switch
                    data-test-id="subcategoryMachinery"
                    checked={this.state.switchSubCategory.Machinery}
                    onChange={this.handleSubCategory}
                    className="switchStyle"
                    name="Machinery"
                  />
                </ListItem>              
                <ListItem className="subCategoryBox">
                  <Box className="left-content">
                    <ListItemIcon>
                      <img
                        src={nailImg}
                        alt="product img"
                        className="subCategoryImg"
                      />
                    </ListItemIcon>
                    <Typography className="subCategoryText">
                     {this.t(`${configJSON.hardwareText}`)}
                    </Typography>
                  </Box>              
                  <Switch
                    checked={this.state.switchSubCategory.Hardware}
                    onChange={this.handleSubCategory}
                    className="switchStyle"
                    name="Hardware"
                    data-test-id="subcategoryhardware"
                  />
                </ListItem>
              </List>                         
            </Box>
            {/* third category */}
            <Box className="categoriesInfo">
              <Box className="categoryBox">
                <Box className="left-content">
                  <ListItemIcon>
                    <img
                      src={assetsImg}
                      alt="product img"
                      className="categoryImg"
                    />
                  </ListItemIcon>
                  <Typography className="categoryText">
                    {this.t(`${configJSON.catergoryName3}`)}
                  </Typography>
                </Box>
                <Switch
                  data-test-id="categoryAsset"
                  checked={this.state.switchCategory.Asset}
                  onChange={this.handleCategory}
                  className="switchStyle"
                  name="Asset"
                />
              </Box>
              <List className="subCategoryList">
                <ListItem className="subCategoryBox">
                  <Box className="left-content">
                    <ListItemIcon>
                      <img
                        src={landImg}
                        alt="product img"
                        className="subCategoryImg"
                      />
                    </ListItemIcon>
                    <Typography className="subCategoryText">
                      {this.t(`${configJSON.landText}`)}
                    </Typography>
                  </Box>
                  <Switch
                    data-test-id="subcategoryLand"
                    checked={this.state.switchSubCategory.Land}
                    onChange={this.handleSubCategory}
                    className="switchStyle"
                    name="Land"
                  />
                </ListItem>              
                <ListItem className="subCategoryBox">
                  <Box className="left-content">
                    <ListItemIcon>
                      <img
                        src={vesselImg}
                        alt="product img"
                        className="subCategoryImg"
                      />
                    </ListItemIcon>
                    <Typography className="subCategoryText">
                      {this.t(`${configJSON.vesselsText}`)}
                    </Typography>
                  </Box>
                  <Switch
                    data-test-id="subcategoryVessel"
                    checked={this.state.switchSubCategory.Vessel}
                    onChange={this.handleSubCategory}
                    className="switchStyle"
                    name="Vessel"
                  />
                </ListItem>
              </List>
            </Box>
            {/* fourth category */}
            <Box className="categoriesInfo">
              <Box className="categoryBox">
                <Box className="left-content">
                  <ListItemIcon>
                    <img
                      src={comsumable}
                      alt="product img"
                      className="categoryImg"
                    />
                  </ListItemIcon>
                  <Typography className="categoryText">
                    {this.t(`${configJSON.catergoryName4}`)}
                  </Typography>
                </Box>
                <Switch
                 data-test-id="categoryConsumable"
                  checked={this.state.switchCategory.Consumable}
                  onChange={this.handleCategory}
                  className="switchStyle"
                  name="Consumable"
                />
              </Box>     
              <List className="subCategoryList">    
                <ListItem className="subCategoryBox">
                  <Box className="left-content">
                    <ListItemIcon>
                      <img
                        src={fuelIcon}
                        alt="product img"
                        className="subCategoryImg"
                      />
                    </ListItemIcon>
                    <Typography className="subCategoryText">
                      {this.t(`${configJSON.FertilizersText}`)}
                    </Typography>
                  </Box>
                  <Switch
                    data-test-id="subcategoryFertilizer"
                    checked={this.state.switchSubCategory.Fertilizer}
                    onChange={this.handleSubCategory}
                    className="switchStyle"
                    name="Fertilizer"
                  />
                </ListItem>              
                <ListItem className="subCategoryBox">
                  <Box className="left-content">
                    <ListItemIcon>
                      <img
                        src={fuelImg}
                        alt="product img"
                        className="subCategoryImg"
                      />
                    </ListItemIcon>
                    <Typography className="subCategoryText">
                      {this.t(`${configJSON.PhytoProductsText}`)}
                    </Typography>
                  </Box>
                  <Switch
                    checked={this.state.switchSubCategory.PhytoProduct}
                    onChange={this.handleSubCategory}
                    className="switchStyle"
                    name="PhytoProduct"
                    data-test-id="subcategoryPhytoProduct"
                  />
                </ListItem>              
                <ListItem className="subCategoryBox">
                  <Box className="left-content">
                    <ListItemIcon>
                      <img
                        src={testtubeImg}
                        alt="product img"
                        className="subCategoryImg"
                      />
                    </ListItemIcon>
                    <Typography className="subCategoryText">
                      {this.t(`${configJSON.ChemicalsText}`)}
                    </Typography>
                  </Box>
                  <Switch
                    checked={this.state.switchSubCategory.Chemical}
                    onChange={this.handleSubCategory}
                    className="switchStyle"
                    name="Chemical"
                    data-test-id="subcategoryChemical"
                  />
                </ListItem>
              </List>     
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  }
  // Customizable Area Start
}

// Customizable Area Start

// Customizable Area End

export default withStyles(customStyles, { withTheme: true })(
  Categoriessubcategories
);
// Customizable Area End
