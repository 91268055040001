// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { producerData } from "../../../components/src/productData";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  openProductsModal: boolean;
  selectedDealProducts: (
    choosenProducts: string[],
    totalAmount: number | null,
    choosenProductsData: [],
    productQuantities:any
  ) => void;
  closeProductsModal: () => void;
  classes?: any;
  buttonTxt:any;
  addAsFavProduct?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  choosenProducts: any;
  openItemInfo: any;
  allProductsData: any;
  choosenProductsData: any;
  totalAmount: number | null;
  productQuantities: any;
  selectAll: boolean;
  itemList: any;
  isLoading: boolean;
  expanded: boolean;
  searchProducer: any;
  searchBrand: any;
  searchColor: any;
  searchVintage: any;
  searchCountry: any;
  searchRegion: any;
  searchSubRegion: any;
  searchAppellation: any;
  producer: any;
  brand: any;
  color: any;
  vintage: any;
  country: any;
  region: any;
  subRegion: any;
  appellation: any;
  brandListData: any;
  overallProductSearch: any;
  checkedItems:string[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionStorageData: any;
  getAllProductsAPIRequestId: string = "";
  userToken: any;
  brandListRequestApiID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      openItemInfo: false,
      allProductsData: [],
      choosenProducts: [],
      productQuantities: {},
      choosenProductsData: [],
      selectAll: false,
      totalAmount: null,
      expanded: false,
      itemList: producerData,
      isLoading: false,
      searchProducer: "",
      searchBrand: "",
      searchColor: "",
      searchVintage: "",
      searchCountry: "",
      searchRegion: "",
      searchSubRegion: "",
      searchAppellation: "",
      producer: "",
      brand: "",
      color: "",
      vintage: "",
      country: "",
      region: "",
      subRegion: "",
      appellation: "",
      brandListData: [],
      overallProductSearch: "",
      checkedItems: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.userSessionStorageData = sessionStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionStorageData);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.error) {       
        this.setState({ isLoading: false });
        return;
      }else{
        switch (apiRequestCallId) {        
          case this.getAllProductsAPIRequestId:
            this.setState({ isLoading: false }); 
            this.setState({ allProductsData: responseJson.data });    
            break;
          case this.brandListRequestApiID:
            this.setState({ isLoading: false }); 
            this.setState({ brandListData: responseJson.data });   
            break;          
          default:
            this.parseApiCatchErrorResponse(errorResponse);
            break;
        }
      }
    } 
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const lang = localStorage.getItem("lang") ?? "en";
    await i18n.changeLanguage(lang);
    this.getAllProducts();
    this.getBrands();
  }
  t(key: any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation", ...variables });
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    const { choosenProducts, productQuantities } = this.state;
    const { allProductsData } = this.state;

    if (
      prevState.allProductsData !== allProductsData ||
      prevState.choosenProducts !== choosenProducts
    ) {
      const filteredProducts = allProductsData.filter((product: any) =>
        choosenProducts.includes(product.id)
      );
      this.setState({ choosenProductsData: filteredProducts });
    }

    if (
      prevState.choosenProductsData !== this.state.choosenProductsData ||
      prevState.productQuantities !== productQuantities
    ) {
      const updatedResponse = this.calculateUpdatedResponse(
        this.state.choosenProductsData,
        productQuantities
      );
      const totalAmount = this.calculateTotalAmountEvent(updatedResponse);
      this.setState({ totalAmount });
    }
  }
  handleToggleEvent = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };
  openItemsInfoDetailModal = () => {
    this.setState({ openItemInfo: true });
  };
  closeItemsInfoDetailModal = () => {
    this.setState({ openItemInfo: false });
  };
  handleSelectedItemEvent = (event:React.ChangeEvent<HTMLInputElement>,productId: string) => {
    this.setState((prevState) => {
      let updatedchoosenProducts;
      const { choosenProducts } = prevState;
      if (choosenProducts.includes(productId)) {
        updatedchoosenProducts = choosenProducts.filter(
          (id: any) => id !== productId
        );
      } else {
        updatedchoosenProducts = [...choosenProducts, productId];
      }
      return { choosenProducts: updatedchoosenProducts };
    });
  };
  handleSelectAllItemsEvent = (event: any) => {
    const { allProductsData } = this.state;
    const allProductIds = allProductsData.map((product: any) => product.id);
    this.setState({
      choosenProducts: event.target.checked ? allProductIds : [],
      selectAll: event.target.checked,
    });
  };
  calculateUpdatedResponse = (
    originalResponse: any,
    productQuantities: any
  ) => {
    return originalResponse.map((productItem: any) => {
      const productId = productItem.id;
      const pquantity = productQuantities[productId] || 1;

      if (productItem.attributes.prices && productItem.attributes.prices.length > 0) {
        const price = productItem.attributes.prices[0];
        const totalPrice = (price.amount || 1) * pquantity;

        return {
          ...productItem,
          attributes: {
            ...productItem.attributes,
            prices: [
              {
                ...price,
                amount: totalPrice,
              },
            ],
          },
        };
      }

      return productItem;
    });
  };
  calculateTotalAmountEvent = (products: any) => {
    let totalAmount = 0;

    products.forEach((product: any) => {
      if (product.attributes.prices && product.attributes.prices.length > 0) {
        const price = product.attributes.prices[0];
        totalAmount += price.amount || 0;
      }
    });

    return totalAmount;
  };
  handleAddButtonClickItemsEvent = (productId: string) => {
    this.setState((prevState) => {
      const { productQuantities } = prevState;
      const currentQuantity = productQuantities[productId] || 1;
      const updatedQuantities = {
        ...productQuantities,
        [productId]: currentQuantity + 1,
      };
      return { productQuantities: updatedQuantities };
    });
  };

  handleRemoveButtonClickItemsEvent = (productId: string) => {
    this.setState((prevState) => {
      const { productQuantities } = prevState;
      const currentQuantity = productQuantities[productId] || 1;
      const updatedQuantities = {
        ...productQuantities,
        [productId]: Math.max(0, currentQuantity - 1),
      };
      return { productQuantities: updatedQuantities };
    });
  };
  removeChosenProductItemsEvent = (productIdToRemove: any) => {
    this.setState((prevState) => {
      const updatedProductQuantities = { ...prevState.productQuantities };
      updatedProductQuantities[productIdToRemove] = 1;

      return {
          choosenProducts: prevState.choosenProducts.filter((id:any) => id !== productIdToRemove),
          productQuantities: updatedProductQuantities
      };
   });
  };
  handleAllInputChangeEvent = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  handleCheckedSelection = (id: any, name: any) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: id,
    }));
  };

  getAllProducts = () => {
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllProductsAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favProductsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  clearAll = () => {
    this.setState({
      searchProducer: "",
      searchBrand: "",
      searchColor: "",
      searchVintage: "",
      searchCountry: "",
      searchRegion: "",
      searchSubRegion: "",
      searchAppellation: "",
      producer: "",
      brand: "",
      color: "",
      vintage: "",
      country: "",
      region: "",
      subRegion: "",
      appellation: "",
    });
  };
  getBrands = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.brandEndPoint
    );
    this.brandListRequestApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
// Customizable Area End
