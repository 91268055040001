import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, inviteMemSuccess } from "./assets";
import { Alert, Keyboard, PermissionsAndroid } from "react-native";
import { AsyncStorage } from "react-native";
//import moment from "moment";
//import * as ImagePicker from 'react-native-image-picker';
//import ImagePicker from 'react-native-image-crop-picker';
import { CommonActions } from '@react-navigation/native';
import Scale from "../../../components/src/Scale";
import { isEmpty } from "../../../framework/src/Utilities";
import Image from "react-native"
import Icon from "react-native-vector-icons/AntDesign";
import store from "../../../components/src/Redux/store";
import { updateIsMemberMoved } from "../../../components/src/Redux/features/stateManager/stateSlice";
// Customizable Area End

// export const configJSON = require("../config");
export type TeamTitleTextInputType = {
  id: number;
  team_title: string;
  showError: boolean;
}

const TeamTitleInitialState:TeamTitleTextInputType = {
    id: 0,
    team_title: '',
    showError: false
} as const

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  route: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  teamListData:any;
  memName:string;
  memId:number;
  teamId:number;
  teamTitle:string;
  memCount:number;
  isSelected:boolean;
  selectedId:number;
  memMovedtoTeamData:any;
  showDialogBox:boolean;
  showInfoModal:boolean;
  onPressContinueBtn:boolean;
  showCreateTeamModal:boolean;
  CreateTeamsTextInput:TeamTitleTextInputType[];
  isCreatingTeams: boolean;
  teamTitleNotValid:any;
  showTeamCreatedModal:boolean;
  showFailedTeamsModal:boolean;
  errorMessage:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class MemberMovetoTeamController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  teamListApiCallId: any;
  memMovetoTeamApiCallId: string = "";
  createTeamApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      teamListData:[],
      memName:this.props.route.params.memName,
      memId:this.props.route.params.memid,
      teamId:this.props.route.params.teamid,
      teamTitle:this.props.route.params.teamName,
      memCount:this.props.route.params.members,
      isSelected:false,
      selectedId:0,
      memMovedtoTeamData:[],
      showDialogBox:false,
      showInfoModal:false,
      onPressContinueBtn:false,
      showCreateTeamModal:false,
      CreateTeamsTextInput:[
        {
          id:0,
          team_title: '',
          showError: false
        }
      ],
      isCreatingTeams: false,
      teamTitleNotValid:"",
      showTeamCreatedModal:false,
      showFailedTeamsModal:false,
      errorMessage:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // console.log("response for show details claim", responseJson?.meta?.token);
      var errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.teamListApiCallId) {
        console.log("TeamsList APIResponse", responseJson);
         if (!responseJson.errors) { 
           console.log("CreatedTeamsList API Response", responseJson);
           this.setState({
             teamListData:responseJson.data,
           })
          const newTeamListData = this.state.teamListData.filter((member:any) => {
              console.log("FilterAPI=>",member);
              if(this.state.teamId != member.attributes.id){
                console.log("After Filter API=>",member);
                return member
              }
           })
           this.setState({
            teamListData:newTeamListData
          })
          console.log("createdTeamsListData==>>>",this.state.teamListData);
           
         }
         else {

           //Check Error Response
           
           //this.parseApiErrorResponse(responseJson);
         }

        // this.parseApiCatchErrorResponse(errorReponse);
       }
       if (apiRequestCallId === this.createTeamApiCallId) {
        this.setState({ isCreatingTeams: false, showCreateTeamModal: false,errorMessage:"" })
        console.log("in IF block");
        console.log("Create Teams Response===>", responseJson);
        if (!responseJson.errors) { 
          console.log("Line 347");
         // this.createdTeamList()
         this.setState({ CreateTeamsTextInput: [ TeamTitleInitialState ] })
          this.setState({errorMessage:responseJson?.failed_teams})
             this.teamListAPICall()
             { responseJson.created_teams.length != 0 &&
             this.setState({showTeamCreatedModal:true,})
             }

          {this.state.errorMessage != undefined && this.state.errorMessage != "" &&
          this.setState({showDialogBox:true})
          
      }
        }
        else {
          console.log("ERROR");
          this.setState({errorMessage:responseJson.errors[0].token})
          this.setState({showDialogBox:true})
        }

       // this.parseApiCatchErrorResponse(errorReponse);
      }
       else if (apiRequestCallId === this.memMovetoTeamApiCallId) {
        console.log("updateTeamData API Response==>>", responseJson);
         if (!responseJson.errors) { 
           console.log("updateTeamData API Response", responseJson);
           this.setState({
            memMovedtoTeamData:responseJson
           })
           store.dispatch(updateIsMemberMoved(true))
           this.props.navigation.goBack()
         }
         else {

         }

       }
      
    }

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    //this.teamListAPICall()
  }
 
  async teamListAPICall(){
    console.log("CreateTeam");
    
      let token = await getStorageData('token')
         const header = {
           'Content-Type': "application/json",
           token: token           
         };
         const requestMessage = new Message(
           getName(MessageEnum.RestAPIRequestMessage)
         );
        console.log("requestMessage",requestMessage);
         requestMessage
         this.teamListApiCallId = requestMessage.messageId;
         requestMessage.addData(
           getName(MessageEnum.RestAPIResponceEndPointMessage),
           // configJSON.accountsAPiEndPoint,
           'https://etohapp-123437-ruby.b123437.dev.eastus.az.svc.builder.cafe/account_block/teams'
           );
         requestMessage.addData(
           getName(MessageEnum.RestAPIRequestHeaderMessage),
           JSON.stringify(header)
         );
         requestMessage.addData(
           getName(MessageEnum.RestAPIRequestMethodMessage),
           'GET'
         );
         // this.setState({ showLoader: true });
         runEngine.sendMessage(requestMessage.id, requestMessage);
         return true;
         
  }
  async memMovetoTeam() {
    if(this.state.isSelected === false){
       this.setState({errorMessage:'Please select team',showDialogBox:true})
    }else if(this.state.isSelected === true){
    let token = await getStorageData('token')
       const header = {
         'Content-Type': "application/json",
         token: token
       };
       const httpBody = {
            choose_team:this.state.selectedId,
             id:this.state.memId
       };
       console.log("HTTPBODY",httpBody)
  
       const requestMessage = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
       );
      console.log("requestMessage",requestMessage);
       requestMessage
       this.memMovetoTeamApiCallId = requestMessage.messageId;
       requestMessage.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         // configJSON.accountsAPiEndPoint,
         'https://etohapp-123437-ruby.b123437.dev.eastus.az.svc.builder.cafe/account_block/update_member_team'
         );
       requestMessage.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
       );
       requestMessage.addData(
         getName(MessageEnum.RestAPIRequestBodyMessage),
         JSON.stringify(httpBody)
       );
       requestMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         'PATCH'
       );
       // this.setState({ showLoader: true });
       runEngine.sendMessage(requestMessage.id, requestMessage);
       return true;
    }
        
  }
  createTeamsFromTeamData = ():string[] => {
    // getting list of teams from each member and converting them to (array of array of teams)
    let teams: string[] | string[][] = this.state.CreateTeamsTextInput.map(teamTitle => teamTitle.team_title.split(','))
        
    // reducing above result
    teams = teams.reduce(function(acc, val) {
        return acc.concat(val);
    }, []);

    // trimming, removing empty strings and duplicate teams
    teams = [...new Set((teams.map(team => team.trim())).filter(Boolean))]

    return teams
  }

  getCreateTeamModalHeight = () => {
    if (this.state.CreateTeamsTextInput.length < 3)
      return Scale(300 + 86*(this.state.CreateTeamsTextInput.length-1))
    return Scale(450)
  }
 
  addAnotherTeam = () => {
    if (this.checkIfTeamTitlesEmpty())  return

    let team:TeamTitleTextInputType[] = this.state.CreateTeamsTextInput.slice()

    team.push({
      id: team.length,
      team_title: '',
      showError: false,
    })

    this.setState({
      CreateTeamsTextInput: team
    })
  }

  deleteTeamFromList = (key: number):void => {
    let team:TeamTitleTextInputType[] = [...this.state.CreateTeamsTextInput]

    team = team.filter(teamTitle => teamTitle.id != key)

    for (let i = 0; i < team.length; i++) {
      team[i].id = i ;
    }

    this.setState({ CreateTeamsTextInput: team })
  }

  changeTeamTitle = (title:string, key:number):void => {
    let team:TeamTitleTextInputType[] = this.state.CreateTeamsTextInput.slice()
    team = team.map(teamTitle => {
      if (teamTitle.id === key)
        return {
          ...teamTitle,
          team_title: title,
          showError: false
        }
      else
        return teamTitle
    })
    this.setState({ CreateTeamsTextInput: team })
  }

  emptyCreateTeams = ():void => {
    Keyboard.dismiss();
    this.teamListAPICall()
    this.setState({
      CreateTeamsTextInput: [
        TeamTitleInitialState
      ]
    })
  }

  trimTeamTitle = ():TeamTitleTextInputType[] => {
    let data:TeamTitleTextInputType[] = this.state.CreateTeamsTextInput.map(teamTitle => {
      return {
        ...teamTitle,
        team_title: teamTitle.team_title.trim()
      }
    })
    return data
  }

  checkIfTeamTitlesEmpty = ():boolean => {
    let result:boolean = false
    let data:TeamTitleTextInputType[] = [...this.state.CreateTeamsTextInput]
    data = this.trimTeamTitle()
    for (let i = 0; i < data.length; i++)
    {
      if (isEmpty(data[i].team_title) )
      {
        result = true
        data[i].showError = true
      }
    }
    this.setState({ CreateTeamsTextInput: data })
    return result
  }

  createTeams = async() => {
    if (this.checkIfTeamTitlesEmpty()) return

    let teams:string[] = this.createTeamsFromTeamData()
    
    this.setState({ isCreatingTeams: true })

    //* Addteams Request

    let token = await getStorageData('token')

    const header = {
        "Content-Type": "application/json",
        "token": token
    }

    const body = {
        "data": {
            "title": teams
        }
    }

    let endPoint = configJSON.baseURL + configJSON.createTeamsEndPoint

    console.log('#end-point :', endPoint)

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    )

    this.createTeamApiCallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    )
    
    //* Adding header

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    )

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
    )
    
    //* Adding Request Method

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getMethodPost
    )
    console.log("requestMessage: ", requestMessage);
        
    //* Making Network Request

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }


  // Customizable Area Start
  // Customizable Area End
}
