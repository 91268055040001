Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET"
exports.httpPostMethod = "POST"
exports.httpPutMethod = "PUT"
exports.httpDeleteMethod = "DELETE"

exports.ordersApiContentType = "application/json";
exports.ordersAPiEndPoint = "order_management/orders";
exports.rateAPiEndPoint = "catalogue/reviews";
exports.singleDetailsApi="/bx_block_catalogue/services"
exports.backText="Back";
exports.cartTxt="Cart";
exports.addToCartTxt="Add to cart";
exports.priceTxt="Price";
exports.descriptionTxt="Description";
exports.addToCartApiId="/bx_block_shopping_cart/add_to_carts";
exports.removeFromCartTxt="Remove from Cart";
exports.deleteCartApi="/bx_block_shopping_cart/delete_cart_item";
exports.createChatPath="/bx_block_chat/chats";
exports.orderHistoryTxt="Order History";
exports.defaultHeading="No order history";
exports.buttonDefault="Browse EtOH Shop";
exports.subTxtDefault="After purchasing products or services from the EtOH shop, you can view their status here.";
exports.subTitleForTable ="Find all of your EtOH Shop orders here.";
exports.filtersTxt="Filters";
exports.clearAllTxt="Clear all";
exports.serachTxt="Start typing to search...";
exports.sortByTxt="Sort by";
exports.aDashZTxt="A-Z";
exports.zDashATxt="Z-A";
exports.browseShopTxt="Browse Shop";
exports.statusesTxt="Statuses";
exports.orderedTxt="Date Ordered";
exports.productTypeTxt="Product Type";
exports.statuses = [
  { id: 1, name: "Order Placed" },
  { id: 2, name: "In Progress" },
  // { id: 3, name: "Shipped" },
  { id: 4, name: "Delivered" },
  { id: 5, name: "On Hold" },
  { id: 6, name: "Cancelled" }
];
exports.dates = [
  { id: 1, name: "Past 30 Days" },
  { id: 2, name: "Past 3 Months" },
  { id: 3, name: "Past 6 Months" },
  { id: 4, name: "Past Year" },
  { id: 5, name: "Any Time" }
];
exports.orderType = [
  { id: 1, name: "Subscription" },
  { id: 2, name: "One-Time Purchase" }, 
];
exports.monthsData = [ "Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
exports.recentlyOrderedTxt="Recently Ordered";
exports.recentStantusChangeTxt="Recent Status Change";
exports.orderDetailsHeading="Order Details";
exports.fillDetailsHeading="Fill Details";
exports.viewFieldDetailsTxt="View Filled Details";
exports.nextItemTxt="Next Item";
exports.fillDetailsSubTxt="Please fill in the details below.";
exports.fillDetailsSubTxt1="Below are the products which need more details.";
exports.searchTxt="Search Products";
exports.youOrdertxt="Your Order";
exports.addAttachmentsTxt ="Add Attachments";
exports.ordeApiPath="/bx_block_shopping_cart/orders";
exports.fillOrderDetailsApiPath="/bx_block_shopping_cart/product_form_questions";
exports.aditionalFormPath="/bx_block_shopping_cart/submit_answers"
exports.ordersTxt="Orders";
exports.orderTxt="Order";
exports.reorderApiPath="/bx_block_shopping_cart/reorder_items";
exports.dummyData=[
  {
    id: "1",
    type: "order",
    attributes: {
      status: "Order Placed",
      total_fees: 1500,
      total_items: 8,
      total_tax: null,
      status_history: {
        in_progress: "2024-01-15",
        order_placed: "Mar 27, 2022 at 10:00am",
      },

      address: {
        data: "123 Main St, Cityville, USA",
      },
      order_items: {
        data: [
          { id: 1, name: "Product A", price: 200, quantity: 2 },
          { id: 2, name: "Product B", price: 150, quantity: 3 },
          { id: 3, name: "Product C", price: 100, quantity: 3 },
        ],
      },
    },
  },
  {
    id: "2",
    type: "order",
    attributes: {
      status: "In Progress",
      total_fees: 2000,
      total_items: 12,
      total_tax: null,
      status_history: {
        in_progress: "2024-01-20",
        order_placed: "Mar 28, 2022 at 3:15pm",
      },

      address: {
        data: "456 Elm St, Townsville, USA",
      },
      order_items: {
        data: [
          { id: 1, name: "Product X", price: 300, quantity: 4 },
          { id: 2, name: "Product Y", price: 250, quantity: 5 },
          { id: 3, name: "Product Z", price: 150, quantity: 3 },
        ],
      },
    },
  },
  {
    id: "3",
    type: "order",
    attributes: {
      status: "Shipped",
      total_fees: 1800,
      total_items: 10,
      total_tax: null,
      status_history: {
        in_progress: "2024-02-01",
        order_placed: "Mar 29, 2022 at 9:30am",
      },

      address: {
        data: "789 Oak St, Villageton, USA",
      },
      order_items: {
        data: [
          { id: 1, name: "Product P", price: 400, quantity: 2 },
          { id: 2, name: "Product Q", price: 350, quantity: 3 },
          { id: 3, name: "Product R", price: 200, quantity: 5 },
        ],
      },
    },
  },
  {
    id: "4",
    type: "order",
    attributes: {
      status: "Delivered",
      total_fees: 2200,
      total_items: 15,
      total_tax: null,
      status_history: {
        in_progress: "2024-02-10",
        order_placed: "Mar 30, 2022 at 11:45am",
      },

      address: {
        data: "101 Pine St, Forestville, USA",
      },
      order_items: {
        data: [
          { id: 1, name: "Product M", price: 250, quantity: 5 },
          { id: 2, name: "Product N", price: 300, quantity: 3 },
          { id: 3, name: "Product O", price: 200, quantity: 7 },
        ],
      },
    },
  },
  {
    id: "5",
    type: "order",
    attributes: {
      status: "On Hold",
      total_fees: 1200,
      total_items: 6,
      total_tax: null,
      status_history: {
        in_progress: "2024-02-20",
        order_placed: "Mar 31, 2022 at 1:30pm",
      },

      address: {
        data: "246 Maple St, Grovetown, USA",
      },
      order_items: {
        data: [
          { id: 1, name: "Product D", price: 180, quantity: 3 },
          { id: 2, name: "Product E", price: 150, quantity: 2 },
          { id: 3, name: "Product F", price: 120, quantity: 1 },
        ],
      },
    },
  },
  {
    id: "6",
    type: "order",
    attributes: {
      status: "Cancelled",
      total_fees: 1000,
      total_items: 5,
      total_tax: null,
      status_history: {
        in_progress: "2024-03-01",
        order_placed: "Apr 1, 2022 at 9:00am",
      },

      address: {
        data: "246 Maple St, Grovetown, USA",
      },
      order_items: {
        data: [],
      },
    },
  },
]
exports.itemTxt="Item";
exports.itemsTxt="Items";
exports.skipProductTxt="Skip Product";
exports.totalPricePaidTxt="Total Price Paid";
exports.etohSupportText=" If you have questions or want to cancel your order, please contact";
exports.Support = "Support"
// Customizable Area End