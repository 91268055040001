import React from "react";
// Customizable Area Start
import {
  Box,
  FormControl,
  Select,
  Typography,
  Checkbox,
  Card,
  MenuItem,

} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import {
  withStyles
} from "@material-ui/core/styles";
import { customStyles } from "../../../components/src/Inventory/inventoryItemStyles.web";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InventoryItemController, {Props} from "./InventoryItemController.web";
export const configJSON = require("./config");
import {
  checkedIcon, wineItem, ovalIcon, starIcon
} from "../../catalogue/src/assets";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { otherProductImg,vesselIcon,landIcon,phytoIcon,chemicalIcon ,fertilizerIcon,machineryIcon,hardwareIcon,wineIcon,beerIcon, spiritIcon} from "../src/assets"
// Customizable Area End


interface ItemDetails {
  category: number;
  subCategory: number;
  targetName: string;
  brandName: string;
  materialName: string;
  colorName: string;
  style: string;
  appellation: string;
  grapeVariety: string;
  vintage: string;
  volume: string;
  area: string;
  unitOfMeasurement: string;
  quantity_unit: string;
  stock_qty:number;
  itemName:string;
  prices:any[];
  address:string;
}

export class InventoryItem extends InventoryItemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  extractItemDetails(item: any): ItemDetails {
    const attributes = item.attributes;

    const category= attributes.category.static_id;
    const subCategory = attributes.sub_category.static_id;

    return {
      category: category,
      subCategory: subCategory,
      targetName: attributes.target?.name ?? "",
      brandName: this.getBrandName(category,attributes),
      materialName: attributes.meta_data?.material ?? "",
      colorName: this.getColorName(category,subCategory,attributes),
      style: this.getStyleName(category,subCategory,attributes),
      appellation: this.getAppelation(category,subCategory,attributes),
      grapeVariety: subCategory==configJSON.WineStaticId ? attributes.meta_data?.grape_variety ?? "": "",
      vintage: attributes.meta_data?.vintage ?? "",
      volume: attributes.meta_data?.volume ?? "",
      area: attributes.meta_data?.area ?? "",
      unitOfMeasurement: attributes.meta_data?.unit_of_measurement ?? "",
      quantity_unit: attributes.meta_data?.quantity_unit ?? "",
      stock_qty:attributes.stock_qty ?? 0,
      itemName:attributes.name?.trim(),
      prices:attributes.prices ?? [],
      address:this.getAddress(subCategory,attributes)
    };
  }

  getAddress(subCategory: number, attributes:any) : string{
    return subCategory === configJSON.LandStaticId ?attributes.meta_data.google_maps_place :""
  }
  getBrandName(category: number, attributes:any) : string {
    return category === configJSON.ProductsStaticId ? "" :attributes.brand?.name ?? ""
  }
  getColorName(category: number,subCategory:number, attributes:any) : string {
    return (subCategory == configJSON.BeerStaticId || subCategory==configJSON.SpritStaticId || category==configJSON.ToolsStaticId) ? attributes.meta_data?.color ?? "": ""
  }

  getStyleName(category: number,subCategory:number, attributes:any) : string {    
    return (subCategory == configJSON.BeerStaticId || subCategory==configJSON.SpritStaticId) ? attributes.location?.style ?? "":""
  }

  getAppelation(category: number,subCategory:number, attributes:any) : string {
    return !(subCategory == configJSON.BeerStaticId || subCategory==configJSON.SpritStaticId) ? attributes.location?.appellation ?? "":""
  }

  shouldShowStyleColor(category: number, subCategory: number): boolean {
    return category === configJSON.ProductsStaticId && (subCategory === configJSON.BeerStaticId || subCategory === configJSON.SpritStaticId);
  }

  shouldShowBrandOnTop(category: number, subCategory: number,brandName:string): string {
    return category === configJSON.ProductsStaticId && subCategory === configJSON.OtherProductStaticId ? brandName: "" ;
  }

  shouldShowVolume(subCategory: number): boolean {
    return subCategory === configJSON.VesselStaticId;
  }

  getImageForSubCategory(subCategory: number,attributes:any): JSX.Element {
    if (attributes.images?.length > 0) {
      return <img src={attributes.images[0].url} className="categoryImg" />;
    }
    switch (subCategory) {
      case configJSON.OtherProductStaticId:
        return <img src={otherProductImg} className="categoryImg" />;
      case configJSON.LandStaticId:
        return <img src={landIcon} className="categoryImg" />;
      case configJSON.VesselStaticId:
        return <img src={vesselIcon} className="categoryImg" />;
      case configJSON.CheimcalStaticId:
        return <img src={chemicalIcon} className="categoryImg" />;
      case configJSON.PhytoProductStaticId:
        return <img src={phytoIcon} className="categoryImg" />;
      case configJSON.FertilizerStaticId:
        return <img src={fertilizerIcon} className="categoryImg" />;
      case configJSON.MachineryStaticId:
        return <img src={machineryIcon} className="categoryImg" />;
      case configJSON.HardwareStaticId:
        return <img src={hardwareIcon} className="categoryImg" />;
      case configJSON.WineStaticId:
        return <img src={wineIcon} className="categoryImg" />;
      case configJSON.BeerStaticId:
        return <img src={beerIcon} className="categoryImg" />;
      case configJSON.SpritStaticId:
        return <img src={spiritIcon} className="categoryImg" />;
      default:
        return <img src={spiritIcon} className="categoryImg" />;
    }
  }

  itemTitleBox = (category:number,subCategory:number,brandName:string,vintage:string,volume:any) => {
    const showBrandOnTop = this.shouldShowBrandOnTop(category, subCategory,brandName);
    return (
    <Box className="itemDesc titleBox">
      <Box className="desc-left">
        <Box className="date" component="span">
          {vintage} {showBrandOnTop}
        </Box>
        {category === configJSON.ProductsStaticId && volume && (
          <Box className="task-about">
            {(vintage || (showBrandOnTop)) && <span className="dot"></span>}
            {volume}
          </Box>
        )}
      </Box>
    </Box>
  )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes,isItemChecked,item,handleItemCheckbox,viewNavigate} = this.props;
    const {
      category,
      subCategory,
      targetName,
      brandName,
      materialName,
      colorName,
      style,
      appellation,
      grapeVariety,
      vintage,
      volume,
      area,
      unitOfMeasurement,
      stock_qty,
      itemName,
      prices,
      address
    } = this.extractItemDetails(item);

    const showVolume = this.shouldShowVolume(subCategory);
    const checkedItem = isItemChecked ? 'dark-border' : '';

    return (
      <Card className={`${classes.viewItemsSection} inventory-checkbox ${checkedItem}`} 
      onClick={(event) => viewNavigate(event, item.id)}
      >
        <Box className="infoBox">
          {this.getImageForSubCategory(subCategory,item.attributes)}
          <Box className="info-box-right">
            <Typography className="in-stock-badge">
              <span className="badge-name">{this.t(`${configJSON.stockText}`)}: </span>
              <span className="counter">{stock_qty}</span>
            </Typography>
            <Checkbox
              checked={isItemChecked}
              onChange={(event) => handleItemCheckbox(event, item.id)}
              className={classes.checkedItem}
              data-test-id={`itemCheckbox-${item.id}`}
              icon={<CheckBoxOutlineBlankIcon />}
              checkedIcon={<CheckBoxIcon />}
            />
          </Box>
        </Box>
        {this.itemTitleBox(category,subCategory,brandName,vintage,volume)}
        <Box className="infoBox">
          <Box className="infoBoxLeft">
            <Typography className="itemTitle">{itemName}</Typography>
            <Typography className={`infoText ${address != "" ? "addressText" : ""}`}>
              {brandName}
              {style}
              {targetName !== "" && brandName !== "" ? `, ${targetName}` : targetName}
              {materialName !== "" && brandName !== "" ? `, ${materialName}` : materialName}
              {colorName !== "" && (brandName !== "" || style !== "") ? `, ${colorName}` : colorName}
              {appellation}
              {grapeVariety !== "" && appellation !== "" ? `, ${grapeVariety}` : grapeVariety}
              {address}
            </Typography>
          </Box>
          {category === configJSON.ProductsStaticId && (
            <Box className="infoBoxRight">
              <FormControl className={classes.itemsFormControl}>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  IconComponent={(props) => <ExpandMoreIcon {...props} />}
                  displayEmpty
                  defaultValue=""
                  data-test-id="PriceDropdown"
                  disableUnderline
                  value={this.state.showPriceAs}
                  onChange={this.changePrice}
                >
                  {prices.map((price:any)=>{
                        return (
                          <MenuItem key={price.id} value={price.id}>{price.title}</MenuItem>
                        )
                      })}
                </Select>
              </FormControl>
              <Typography className="itemPrice">{configJSON.dollarSymbol}{this.state.priceToShow}</Typography>
            </Box>
          )}
          {category === configJSON.AssetsStaticId && (
            <Box className="infoBoxRight">
              <Typography className="itemHeading">{showVolume ?  "Volume" :"Total Area"}</Typography>
              <Typography className="itemPrice">{showVolume ? volume : area}{unitOfMeasurement}</Typography>
            </Box>
          )}
        </Box>
      </Card>
    );
  }


    // Customizable Area End
}

// Customizable Area Start


export default withStyles(customStyles, { withTheme: true })(InventoryItem);
// Customizable Area End