import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  isItemChecked:boolean;
  handleItemCheckbox:any;
  item:any;
  viewNavigate:any;
  showPriceAs?:string;

  // Customizable Area End
}

interface S {
// Customizable Area Start

isLoading:boolean;
showPriceAs:number;
priceToShow:number;
// Customizable Area End
}
  
interface SS {
id: any;
// Customizable Area Start
// Customizable Area End
}
  
// Customizable Area Start

// Customizable Area End
export default class InventoryItemController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionData: any;
  userToken: any;
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    let prices = this.props.item.attributes.prices ?? [];

    this.state = {
      // Customizable Area Start
     
      isLoading:true,
      showPriceAs:prices.length>0?prices[0].id:0,
      priceToShow:prices.length>0?prices[0].amount:0,
      // Customizable Area End,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
   
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
   
  
    // Customizable Area End
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevProps.showPriceAs !== this.props.showPriceAs) {
      let prices = this.props.item.attributes.prices ?? [];
      if(prices.length > 0 ){
        let showPriceas = this.props.showPriceAs;
        if(showPriceas!=""){
          let price = prices.find((price:any)=>price.title == showPriceas);
          if(price){
            this.setState({showPriceAs:price.id,priceToShow:price.amount})
          }
        }
        
      }      
    }
  }

  changePrice = (event:any) => {
    let prices = this.props.item.attributes.prices ?? [];
    let price = prices.find((price:any)=>price.id == event.target.value);
    this.setState({showPriceAs:event.target.value,priceToShow:price.amount})
  }

  async componentDidMount(): Promise<void> {
    const lang = localStorage.getItem("lang") || "en"; 
    await (i18n as any).changeLanguage(lang);
  }

  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  // Customizable Area End
}
