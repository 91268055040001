import React from "react";

import { Box, Typography } from "@material-ui/core";
import { Theme as AugmentedTheme, makeStyles, createStyles } from "@material-ui/core/styles";

import { Logo, EnglishLangIcon, FrenchLangIcon } from "../assets";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: AugmentedTheme) => createStyles({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        color: "#fff",

        "& button": {
            color: "#fff",
            width: "100%",
            padding: ".7rem 0",
            textTransform: "capitalize",
            fontSize: ".8rem",
            borderRadius: ".4rem",
        },

        "& p": {
            fontSize: ".7rem",
            margin: "1.3rem 0",
        },

        "& a": {
            textDecoration: "none"
        },
    },

    error_field: {
        border: `2.5px solid ${theme.palette.error.main}`,
        borderRadius: ".4rem",
        background: theme.palette.error.main,

        "& input": {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },

        "& p": {
            background: theme.palette.error.main,
            margin: 0,
            color: theme.palette.common.white,
            padding: ".1rem 1rem"
        },

        "& svg": {
            color: theme.palette.error.main,
        }
    },

    logo: {
        width: "12rem",
        height: "3rem",
    },
    container: {
        backgroundColor: theme.palette.background.default,
        backdropFilter: "blur(10px)",
        width: "33rem",
        height: "42rem",
        borderRadius: "1.5rem",
        display: "flex",
        flexDirection: "column",
        padding: "3.5rem 2.5rem 1rem",

        "& h3": {
            alignSelf: "flex-start",
            margin: "2rem 0",
            color: theme.palette.common.white,
            fontWeight: "bold",
        },
    },
    languages: {
        margin: "auto 0 2rem",
        alignSelf: "flex-start",

        "& > div": {
            marginRight: "1.5rem",
            cursor: "pointer",
        },

        "& svg": {
            width: "1rem",
            marginRight: ".3rem",
        },

        "& p": {
            margin: 0,
        },
    },
    backIcon: {
        position: "absolute",
        top: "4.4rem",
        left: "2.5rem",
        fontSize: "1.5rem",
        cursor: "pointer",
    }
}));

interface AuthWrapper {
    backButton?: boolean;
}

const AuthWrapper: React.FC<AuthWrapper> = ({ children, backButton }) => {
    // Customizable Area Start
    const styles = useStyles();
    // const navigate = useNavigate();
    // Customizable Area End

    const handleBack = () => {
    //    navigate(-1);
    }

    return (
        <Box className="backgroundImg">
            <Box className={styles.root}>
                <Box className={styles.container}>
                    <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                        {backButton && <ArrowBackIosIcon className={styles.backIcon} onClick={handleBack} />}
                        <Logo className={styles.logo} />
                    </Box>

                    {children}

                    <Box display="flex" className={styles.languages}>
                        <Box display="flex">
                            <EnglishLangIcon />
                            <Typography>English</Typography>
                        </Box>
                        <Box display="flex">
                            <FrenchLangIcon />
                            <Typography>French</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default AuthWrapper;
