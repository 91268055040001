// Customizable Area Start
import React from "react";
import { Box, Typography, Button, CardMedia } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import { imgBackArrow } from "./assets";
import MobileStepIndicator from "./components/MobileStepIndicator.web";

interface CreateQuizProps {
  classes: any;
  modalOpen: boolean;
  modalClose: () => void;
  saveQuizDetails: (
    key: string,
    value: string | number | null,
    objectToUpdate: string
  ) => void;
  quizTitle: string;
  quizCaption: string;
  createQuiz: () => void;
  errors: any;
  t:(key:string) => any;
}

const CreateQuiz = (props: CreateQuizProps) => {
  const {
    classes,
    modalOpen,
    modalClose,
    saveQuizDetails,
    quizTitle,
    quizCaption,
    createQuiz,
    errors,
    t
  } = props;

  return (
    <Modal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalDialog}
    >
      <Box className={classes.modalDialogBox}>
        <MobileStepIndicator classes={classes}  step="1" />
        <Box className="modal-heading">
          <Typography className="modal-title" component="h2">
            {t("Basic info")}
          </Typography>
          <Link to="#" className="close-icon" onClick={modalClose}>
            <CloseIcon />
          </Link>
        </Box>
        <Box className="modal-description">
          <Box component="p" className="sub-txt">
            {t("Please fill in the below details to create your E-Quiz.")}
          </Box>
          <Box className="modal-form" component="form">
            <CustomInputWeb
              type="text"
              isRequired={true}
              errors={errors.errorquizTitle}
              label={t("E-Quiz Title")}
              value={quizTitle}
              onChange={(event) =>
                saveQuizDetails("quizTitle", event.target.value, "createQuiz")
              }
            />
            <CustomInputWeb
              type="text"
              isRequired={true}
              isMultiline={true}
              errors={errors.errorquizCaption}
              //success={allStates.emailSuccessMsg}
              value={quizCaption}
              label={t("Enter a caption (e.g. a question or purpose of E-quiz)")}
              onChange={(event) =>
                saveQuizDetails("quizCaption", event.target.value, "createQuiz")
              }
            />
            <Box className="modal-footer">
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                title="Continue"
                onClick={createQuiz}
                data-test-id="create-quiz-sbmt"
              >
               {t("Continue")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateQuiz;
// Customizable Area End
