export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const dropIc = require("../assets/dropIc.png");
export const checkEmpty = require("../assets/checkEmpty.png");
export const checked = require("../assets/checked.png");
export const imgArrowBack = require("../assets/arrow_back.png");
export const imgCreateTaskCalendar = require("../assets/createTask_calendar.png");
export const imgCreateTaskCalendarRange = require("../assets/createTask_calendar_range.png");
export const imgCreateTaskCriticalPriority = require("../assets/createTask_critical_priority.png");
export const imgCreateTaskNonePriority = require("../assets/createTask_none_priority.png");
export const imgCreateTaskAttach = require("../assets/createTask_attach.png");
export const imgCreateTaskTimer = require("../assets/createTask_timer.png");
export const imgCreateTaskAdd = require("../assets/createTask_add.png");
export const imgCreateTaskCritical = require("../assets/createTask_critical.png");
export const imgCreateTaskEmergency = require("../assets/createTask_emergency.png");
export const imgCreateTaskSearch = require("../assets/createTask_search.png");
export const imgCreateTaskFilter = require("../assets/createTask_filter.png");
export const imgCreateTaskRadioFilled = require("../assets/createTask_radio_filled.png");
export const subtaskListBlack = require("../assets/subtask-list-black.png");
export const subtaskListWhite = require("../assets/subtask-list-white.png");
export const accountPlus = require("../assets/account-plus-outline.svg");
export const accountPlusBlack = require("../assets/account-plus-outline-black.svg");
export const checkboxMarkedCircle = require("../assets/checkbox-marked-circle.png");
export const calendar = require("../assets/calendar-range.svg");
export const checkBold = require("../assets/check-bold.png");
export const dotsHorizontal = require("../assets/dots-horizontal.svg");
export const checkBoxEmpty = require("../assets/check-empty.png");
export const checkBoxFilled = require("../assets/check-filled.png");
export const check = require("../assets/check.png");
export const checkGreen = require("../assets/check-green.png");
export const sortVariant = require("../assets/sort-variant.svg");
export const accountOutline = require("../assets/account-outline.svg");
export const accountOutlineProject = require("../assets/Vector.svg");
export const calendarRange = require("../assets/calendar-range.png");
export const accountGray = require("../assets/account-gray.png");
export const editGray = require("../assets/edit-gray.png");
export const copyGray = require("../assets/content-copy.svg");
export const copyDuplicate =require("../assets/copy_icon.svg");
export const calendarGray = require("../assets/calendar-range.svg");
export const chevronDown = require("../assets/chevron-down.png");
export const attachmentImg1 = require("../assets/attachment-img1.png");
export const download = require("../assets/file-download.png");
export const plusCircle = require("../assets/plus-circle.png");
export const editCircle = require("../assets/edit-circle.png");
export const checkCircle = require("../assets/check-circle.png");
export const arrowULeft = require("../assets/arrow-u-left.png");
export const accountPlusWhite = require("../assets/account-plus-white.png");
export const calendarWhite = require("../assets/calendar-white.png");
export const deleteWhite = require("../assets/delete-white.png");
export const priorityWhite = require("../assets/priority-white.png");
export const checkboxMarkedWhite = require("../assets/checkbox-marked-white.png");
export const minimizeGray = require("../assets/minimize-gray.png");
export const closeGray = require("../assets/close-gray.png");
export const attachGray = require("../assets/attach.png");
export const timerSand = require("../assets/timer-sand.svg");
export const fileTree = require("../assets/file-tree.png");
export const checkEmptySquare = require("../assets/check-empty-square.png");
export const calendarBlack = require("../assets/calendar-black.png");
export const uploadImg = require("../assets/upload-icon.png");
export const productImg = require("../assets/product-img.png");
export const musicPlayer = require("../assets/music-player.svg");
export const volumeHigh = require("../assets/volume-high.svg");
export const projectCreate = require("../assets/project-create.png");
export const playRound = require("../assets/play-round.svg");
export const pauseRound = require("../assets/pause-round.svg");
export const priorityGray = require("../assets/priority-gray.svg");
export const contentSave = require("../assets/ballot-outline.svg");
export const fileTreeBlue = require("../assets/file-tree-blue.svg");
export const formatTreeBlue = require("../assets/format-tree-blue.svg");
export const familyTreeBlue = require("../assets/family-tree-blue.svg");
export const deleteIcon = require("../assets/delete-outline.png");
export const artboardIcon = require("../assets/artboard.png");
export const formatListIcon = require("../assets/format-list-text.svg");
export const checkboxMarkedCircleGray = require("../assets/checkbox-marked-circle-plus-outline.svg");
export const shapeIcon = require("../assets/shape-outline.svg");
export const shieldAccountIcon = require("../assets/Vector.svg");
export const vectorBack = require("../assets/vector-back.png");
export const menu = require("../assets/menu.png");
export const dotVerticle = require("../assets/dots-verticle.png");
export const dotVerticleWhite = require("../assets/dots-verticle-white.png");
export const arrowBackWhite = require("../assets/arrow_back_white.png");
export const moveIcon = require("../assets/move-icon.png");
export const copyIcon = require("../assets/copy-icon.png");
export const MoveTaskIcon = require("../assets/Vector.png");
export const sortby = require("../assets/tune-vertical.svg");
export const clipboardCheckOutline = require("../assets/clipboard_check_outline.png");
export const deleteGray= require("../assets/delete-gray.png");
export const checkedIcon = require("../assets/checkbox_outline_blank.png");
export const timerUntracked = require("../assets/timer-untracked.svg");
export const attacmentsIcon = require("../assets/attacmentsIcon.png");
export const projectSvg = require("../assets/VectorProject.svg");
export const calanderPng = require("../assets/calendar-range.png");
export const checkboxMarkedCircleGray2 = require("../assets/checkbox-marked-circle-plus-outline copy.svg");
export const checkedSvg =require("../assets/Vector-Check.svg"); 
export const radioCheck =require("../assets/RadiobuttonItem.svg"); 
export const calendar2 = require("../assets/calendar-range2.svg");
export const backCheckIcon = require("../assets/RadiobuttonItem-checkIcon.svg");
export const calendarViewIcon = require("../assets/CaalendarViewIcon.svg");
export const inventoryIcon = require("../assets/open-in-new.svg");
export const mp3Default = require("../assets/Mp3Default.svg");
export const mp4Default = require("../assets/Mp4Default.svg");
export const grayCheck = require("../assets/gray-check.svg");
export const greenCheck = require("../assets/green-check.svg");
