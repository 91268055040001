// Customizable Area Start
import React, { useState, useMemo } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import { ListSubheader, InputAdornment, Box } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  accountPlus,
  checkBoxFilled,
  checkBoxEmpty,
  sortVariant,
  accountOutline,
  projectSvg,
  formatListIcon,
  checkboxMarkedCircleGray,
  shapeIcon,
  shieldAccountIcon,
  accountOutlineProject,
} from "../assets";
import { Link } from "react-router-dom";
import CustomDatePicker from "../../../../components/src/CustomDatePicker.web";
import { configJSON } from "./ProjectsController.web";
import i18n from "../../../../web/src/utilities/i18n";

const blankArr: any = [];

const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function TaskListFilter(props: any) {
  const {
    classes,
    selectAssigneeOptions,
    selectProjectOptions,
    selectCategoryOptions,
    selectTaskListOptions,
    onSelectedOptions,
    selectedAssignee,
    selectedPriority,
    selectedProject,
    selectedTaskList,
    selectedSortBy,
    selectedDueDate,
    dueDateRangeFilter,
    clearAction,
    isAllProject,
    isOneProject,
    isTaskList,
    selectedDeadlineDate,
    deadlineDateRangeFilter,
    selectedStatus,
    selectedCategory,
    selectedProjectLead,
    getFilterdData
  } = props;
  const t = (key: any, variables?: Record<string, any>) => {
    return (i18n as any).t(key, { ns: "translation", ...variables });
  };
  const priorityOptions: any[] = [
    {
      id: 2,
      // title: "Normal",
      title: "Emergency",
      status: "!!!",
    },
    {
      id: 1,
      title: "Critical",
      status: "!!",
    },
    {
      id: 0,
      title: "None",
      status: "",
    },
  ];

  const statusOptions: any[] = [
    {
      id: 0,
      title: "Open",
    },
    {
      id: 1,
      title: "On Track",
    },
    {
      id: 2,
      title: "Off Track",
    },
    {
      id: 3,
      title: "Completed",
    },
  ];

  const assigneeNames = useMemo(
    () =>
      selectAssigneeOptions.map(
        (item: {
          id: number;
          attributes: { first_name: string; last_name: string; email: string };
        }) => ({
          id: item.id,
          title: `${item.attributes.first_name} ${item.attributes.last_name}`,
          email: item.attributes.email,
          initials: `${item.attributes.first_name.charAt(
            0
          )}${item.attributes.last_name.charAt(0)}`,
        })
      ),
    [selectAssigneeOptions]
  );

  const [searchText, setSearchText] = useState("");
  const [searchPriorityText, setPriorityText] = useState("");
  const [searchProjectText, setProjectText] = useState("");
  const [searchCategoryText, setCategoryText] = useState("");
  const [searchPLText, setPLText] = useState("");
  const [searchTaskListText, setTaskListText] = useState("");

  const displayAssigneeOptions = useMemo(
    () =>
      assigneeNames.filter((obj: { title: string }) =>
        containsText(obj.title, searchText)
      ),
    [assigneeNames, searchText]
  );

  const displayPLOptions = useMemo(
    () =>
      assigneeNames.filter((obj: { title: string }) =>
        containsText(obj.title, searchPLText)
      ),
    [assigneeNames, searchPLText]
  );
  const displayPriorityOptions = useMemo(
    () =>
      priorityOptions.filter((obj: any) =>
        containsText(obj.title, searchPriorityText)
      ),
    [priorityOptions, searchPriorityText]
  );

  const displayProjectOptions = useMemo(
    () =>
      selectProjectOptions
        ? selectProjectOptions
            .map((item: { id: number; attributes: { title: string } }) => ({
              id: item.id,
              title: item.attributes.title,
            }))
            .filter((obj: any) => containsText(obj.title, searchProjectText))
        : [],
    [selectProjectOptions, searchProjectText]
  );

  const displayCategoryOptions = useMemo(
    () =>
      selectCategoryOptions
        ? selectCategoryOptions
            .map((item: { id: number; attributes: { category: string } }) => ({
              id: item.id,
              title: item.attributes.category,
            }))
            .filter((obj: any) => containsText(obj.title, searchCategoryText))
        : [],
    [selectCategoryOptions, searchCategoryText]
  );

  const displayTaskListOptions = useMemo(
    () =>
      selectTaskListOptions
        ? selectTaskListOptions
            .map((item: { id: number; attributes: { name: string } }) => ({
              id: item.id,
              title: item.attributes.name,
            }))
            .filter((obj: any) => containsText(obj.title, searchTaskListText))
        : [],
    [selectTaskListOptions, searchTaskListText]
  );

  const getLabel = (value: any, def: string, icon: any) => {
    const selectedItemCount = value.length;
    if (selectedItemCount === 0) {
      return (
        <>
          {icon} {t(`${def}`)}
        </>
      );
    } else if (selectedItemCount === 1) {
      let result: any = null;
      switch (def.toLowerCase()) {
        case "priority":
          result = displayPriorityOptions.find(
            (obj: any) => obj.id === value[0]
          );
          break;
        case "project":
          result = displayProjectOptions.find(
            (obj: any) => obj.id === value[0]
          );
          break;
        case "status":
          result = statusOptions.find((obj: any) => obj.id === value[0]);
          break;
        case "category":
          result = displayCategoryOptions.find(
            (obj: any) => obj.id === value[0]
          );
          break;
        case "tasklist":
          result = displayTaskListOptions.find(
            (obj: any) => obj.id === value[0]
          );
          break;
      }

      return (
        <>
          {icon} {result.title}
        </>
      );
    } else if (selectedItemCount > 1) {
      return (
        <>
          {icon} {selectedItemCount} items selected
        </>
      );
    }
  };
  const getAssigneeLabel = (
    options: any,
    def: string,
    value: any,
    icon: any
  ) => {
    const selectedItemCount = value.length;
    if (selectedItemCount === 0) {
      return (
        <>
          {icon} {t(`${def}`)}
        </>
      );
    } else {
      const selected = options
        .filter((x: any) => value.includes(x.id))
        .slice(0, 4)
        .map((option: any) => {
          return (
            // <Box key={option.id} value={option.id}>
            <Box className="assignee-bubble" key={option.id}>
              {option.initials}
            </Box>
            // </Box>
          );
        });

      return (
        <>
          {icon} {def}{" "}
          <Box className="assignee-list">
            {selected}{" "}
            {selectedItemCount > 4 && (
              <Box className="assignee-bubble">+{selectedItemCount - 4}</Box>
            )}
          </Box>
        </>
      );
    }
  };

  const mediumScreen = useMediaQuery("(max-width:599px)");

  const assigneeItem=(option:any)=>{
    return(
          <MenuItem key={option.id} value={option.id}>
              <Box className="assignee-detail">
                <Box className="assignee-left">
                  <Box className="assignee-profile">{option.initials}</Box>
                  <Box className="assignee-info">
                    <Box className="assignee-name">{option.title}</Box>
                    <Box className="assignee-email">{option.email}</Box>
                  </Box>
                </Box>
                <Box className="assignee-right">
                  <Box className="assinee-unselected">
                    <img
                      className="normal-img"
                      src={checkBoxEmpty}
                      alt="checkBoxEmpty"
                    />
                    <img
                      className="selected-img"
                      src={checkBoxFilled}
                      alt="checkBoxFilled"
                    />
                  </Box>
                </Box>
              </Box>
          </MenuItem>
          )
  }

  const dueDateFilter = () => {
  return  (isAllProject || isOneProject || isTaskList) ? (
      <Box className="filter-date-outer">
        <CustomDatePicker
          placeholder={isAllProject ? t(configJSON.startDateTxt) : t(configJSON.dueDate)}
          range={true}
          value={selectedDueDate}
          currentDate=""
          onChange={dueDateRangeFilter}
          onOpenPickNewDate={false}
          data-test-id="dueDateFilter"
          getFilterdData={getFilterdData}
        />
        {selectedDueDate.length > 0 && (
          <ClearIcon
            onClick={() => clearAction("selectedDueDateVal")}
            className="multiple-clear"
            data-test-id="clearDueDateFilter"
          />
        )}
      </Box>):<></>
  }
  const statusFilter = () => {
    return isAllProject ? (
        <FormControl
          className="select-control multi-select"
          variant={mediumScreen ? "outlined" : "standard"}
        >
          {selectedStatus?.length > 1 && (
            <ClearIcon
              onClick={() => clearAction("selectedStatusVal")}
              className="multiple-clear"
              data-test-id="clearStatusFilter"
            />
          )}
          <InputLabel
            className="multiselect-label"
            id="priority-mutiple-checkbox"
          >
            <Box className="select-icon" component="span">
              <img
                src={checkboxMarkedCircleGray}
                alt="checkboxMarkedCircleGray"
              />
            </Box>
            {t(`${configJSON.txtStatus}`)}
          </InputLabel>
          <Select
            labelId="priority-mutiple-checkbox-label"
            id="priority-mutiple-checkbox"
            data-testid="statusFilter"
            multiple
            value={selectedStatus}
            defaultValue={blankArr}
            onChange={(event, type) =>
              onSelectedOptions(event, "selectedStatusVal")
            }
            // onClose={() => setPriorityText("")}
            renderValue={() =>
              getLabel(
                selectedStatus ?? [],
                "Status",
                <>
                  <Box className="select-icon" component="span">
                    <img
                      src={checkboxMarkedCircleGray}
                      alt="checkboxMarkedCircleGray"
                    />
                  </Box>
                </>
              )
            }
            inputProps={{ IconComponent: () => null }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: { paper: `${classes.dropdownStyle} multiSelect` },
            }}
            displayEmpty
          >
            {statusOptions.map((status: any) => (
              <MenuItem key={status.id} value={status.id}>
                <ListItemText className="priority-name">
                  {t(`${status.title}`)}
                </ListItemText>
                <Box className="multiselect-squareCheck">
                  <img
                    className="normal-img"
                    src={checkBoxEmpty}
                    alt="checkBoxEmpty"
                  />
                  <img
                    className="selected-img"
                    src={checkBoxFilled}
                    alt="checkBoxFilled"
                  />
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ): <></>;
  }

  const projectFilter = () => {
    return (!isAllProject && !isOneProject && !isTaskList) ? 
      <FormControl
        className="select-control multi-select"
        variant={mediumScreen ? "outlined" : "standard"}
      >
        {selectedProject?.length > 1 && (
          <ClearIcon
            onClick={() => clearAction("selectedProjectVal")}
            className="multiple-clear"
            data-test-id="clearPriorityFilter"
          />
        )}
        <InputLabel
          className="multiselect-label"
          id="priority-mutiple-checkbox"
        >
          <Box className="select-icon" component="span">
            <img src={projectSvg} alt="artboardIcon" />
          </Box>          
          {t(`${configJSON.projectTxt}`)}
        </InputLabel>
        <Select
          labelId="priority-mutiple-checkbox-label"
          id="priority-mutiple-checkbox"
          data-testid="projectFilter"
          multiple
          value={selectedProject}
          defaultValue={blankArr}
          onChange={(event, type) =>
            onSelectedOptions(event, "selectedProjectVal")
          }
          onClose={() => setProjectText("")}
          renderValue={() =>
            getLabel(
              selectedProject ?? [],
              "Project",
              <>
                <Box className="select-icon" component="span">
                  <img src={projectSvg} alt="artboardIcon" />
                </Box>
              </>
            )
          }
          inputProps={{ IconComponent: () => null }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            classes: { paper: `${classes.dropdownStyle} multiSelect` },
          }}
          displayEmpty
        >
          <ListSubheader>
            <CustomInputWeb
              placeholder={t(`${configJSON.serchHereTxt}`)}
              fullWidth={true}
              autoFocus={true}
              onChange={(e) => setProjectText(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {displayProjectOptions.map((project: any) => (
            <MenuItem key={project.id} value={project.id}>
              <ListItemText className="priority-name">
                {project.title}
              </ListItemText>
              <Box className="multiselect-squareCheck">
                <img
                  className="normal-img"
                  src={checkBoxEmpty}
                  alt="checkBoxEmpty"
                />
                <img
                  className="selected-img"
                  src={checkBoxFilled}
                  alt="checkBoxFilled"
                />
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      : <></>
  }

  const taskListFilter = () => {
    return (isOneProject || (!isAllProject && !isTaskList)) ?
      <FormControl
        className="select-control multi-select"
        variant={mediumScreen ? "outlined" : "standard"}
      >
        {selectedTaskList?.length > 1 && (
          <ClearIcon
            onClick={() => clearAction("selectedTaskListVal")}
            className="multiple-clear"
            data-test-id="clearPriorityFilter"
          />
        )}
        <InputLabel
          className="multiselect-label"
          id="priority-mutiple-checkbox"
        >
          <Box className="select-icon" component="span">
            <img src={formatListIcon} alt="formatListIcon" />
          </Box>          
          {t(`${configJSON.labelTitleText}`)}
        </InputLabel>
        <Select
          labelId="priority-mutiple-checkbox-label"
          id="priority-mutiple-checkbox"
          data-testid="taskListFilter"
          multiple
          value={selectedTaskList}
          defaultValue={blankArr}
          onChange={(event, type) =>
            onSelectedOptions(event, "selectedTaskListVal")
          }
          onClose={() => setTaskListText("")}
          renderValue={() =>
            getLabel(
              selectedTaskList ?? [],
              "Tasklist",
              <>
                <Box className="select-icon" component="span">
                  <img src={formatListIcon} alt="formatListIcon" />
                </Box>
              </>
            )
          }
          inputProps={{ IconComponent: () => null }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            classes: { paper: `${classes.dropdownStyle} multiSelect` },
          }}
          displayEmpty
        >
          <ListSubheader>
            <CustomInputWeb
              placeholder={t(`${configJSON.serchHereTxt}`)}
              fullWidth={true}
              autoFocus={true}
              onChange={(e) => setTaskListText(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {displayTaskListOptions.map((taskList: any) => (
            <MenuItem key={taskList.id} value={taskList.id}>
              <ListItemText className="priority-name">
                {taskList.title}
              </ListItemText>
              <Box className="multiselect-squareCheck">
                <img
                  className="normal-img"
                  src={checkBoxEmpty}
                  alt="checkBoxEmpty"
                />
                <img
                  className="selected-img"
                  src={checkBoxFilled}
                  alt="checkBoxFilled"
                />
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    : <></>
  }

  const priorityFilter = () => {
    return !isAllProject ?
      <FormControl
        className="select-control multi-select"
        variant={mediumScreen ? "outlined" : "standard"}
      >
        {selectedPriority.length > 1 && (
          <ClearIcon
            onClick={() => clearAction("selectedPriorityVal")}
            className="multiple-clear"
            data-test-id="clearPriorityFilter"
          />
        )}
        <InputLabel
          className="multiselect-label"
          id="priority-mutiple-checkbox"
        >
          <Box className="select-icon" component="span">
            !!!
          </Box>
          {t(`${configJSON.txtPriority}`)}
        </InputLabel>
        <Select
          labelId="priority-mutiple-checkbox-label"
          id="priority-mutiple-checkbox"
          data-testid="priorityFilter"
          multiple
          value={selectedPriority}
          defaultValue={blankArr}
          onChange={(event, type) =>
            onSelectedOptions(event, "selectedPriorityVal")
          }
          onClose={() => setPriorityText("")}
          renderValue={() =>
            getLabel(
              selectedPriority,
              "Priority",
              <>
                <Box className="select-icon" component="span">
                  !!!
                </Box>
              </>
            )
          }
          inputProps={{ IconComponent: () => null }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            classes: { paper: `${classes.dropdownStyle} multiSelect` },
          }}
          displayEmpty
        >
          {displayPriorityOptions.map((priority: any) => (
            <MenuItem key={priority.id} value={priority.id}>
              <ListItemText className="priority-name">
                {priority.title}
                <Box className="priority-status" component="span">
                  {priority.status}
                </Box>
              </ListItemText>
              <Box className="multiselect-squareCheck">
                <img
                  className="normal-img"
                  src={checkBoxEmpty}
                  alt="checkBoxEmpty"
                />
                <img
                  className="selected-img"
                  src={checkBoxFilled}
                  alt="checkBoxFilled"
                />
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>: <></>
  }
  return (
    <Box className="filter-list">
     
      {dueDateFilter()}
      
      <Box className="filter-date-outer">
        <CustomDatePicker
          placeholder={`${isAllProject ? t(configJSON.endDateTxt) : t(configJSON.txtDeadline)}`}
          range={true}
          value={selectedDeadlineDate}
          currentDate=""
          onChange={deadlineDateRangeFilter}
          onOpenPickNewDate={false}
          data-test-id="deadLineDateFilter"
          getFilterdData={getFilterdData}
        />
        {selectedDeadlineDate?.length > 0 && (
          <ClearIcon
            onClick={() => clearAction("selectedDeadlineDateVal")}
            className="multiple-clear"
            data-test-id="clearDeadlineDateFilter"
          />
        )}
      </Box>

      {statusFilter()}

      {isAllProject && (
        <FormControl
          className="select-control multi-select"
          variant={mediumScreen ? "outlined" : "standard"}
        >
          {selectedCategory?.length > 1 && (
            <ClearIcon
              onClick={() => clearAction("selectedCategoryVal")}
              className="multiple-clear"
              data-test-id="clearCategoryFilter"
            />
          )}
          <InputLabel
            className="multiselect-label"
            id="priority-mutiple-checkbox"
          >
            <Box className="select-icon" component="span">
              <img src={shapeIcon} alt="shapeIcon" />
            </Box>
            {t(`${configJSON.categoryTxt}`)}
          </InputLabel>
          <Select
            labelId="priority-mutiple-checkbox-label"
            id="priority-mutiple-checkbox"
            data-testid="categoryFilter"
            multiple
            value={selectedCategory}
            defaultValue={blankArr}
            onChange={(event, type) =>
              onSelectedOptions(event, "selectedCategoryVal")
            }
            onClose={() => setCategoryText("")}
            renderValue={() =>
              getLabel(
                selectedCategory ?? [],
                "Category",
                <>
                  <Box className="select-icon" component="span">
                    <img src={shapeIcon} alt="shapeIcon" />
                  </Box>
                </>
              )
            }
            inputProps={{ IconComponent: () => null }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: { paper: `${classes.dropdownStyle} multiSelect` },
            }}
            displayEmpty
          >
            <ListSubheader>
              <CustomInputWeb
                placeholder={t(`${configJSON.serchHereTxt}`)}
                fullWidth={true}
                autoFocus={true}
                onChange={(e) => setCategoryText(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
                data-test-id="categoryFilterSearch"
              />
            </ListSubheader>
            {displayCategoryOptions.map((category: any) => (
              <MenuItem key={category.id} value={category.id}>
                <ListItemText className="priority-name">
                  {category.title}
                </ListItemText>
                <Box className="multiselect-squareCheck">
                  <img
                    className="normal-img"
                    src={checkBoxEmpty}
                    alt="checkBoxEmpty"
                  />
                  <img
                    className="selected-img"
                    src={checkBoxFilled}
                    alt="checkBoxFilled"
                  />
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {priorityFilter()}
      
      {projectFilter()}

      {taskListFilter()}

      <FormControl
        className="select-control multi-select assignee-select"
        variant={mediumScreen ? "outlined" : "standard"}
      >
        {selectedAssignee.length > 1 && (
          <ClearIcon
            onClick={() => clearAction("selectedAssigneeVal")}
            className="multiple-clear"
            data-test-id="clearAssigneeFiletr"
          />
        )}
        <InputLabel className="multiselect-label" id="team-multiple-checkbox">
          <img src={accountPlus} alt="accountPlus" />
          {t(`${configJSON.assigneeTxt}`)}
        </InputLabel>
        <Select
          labelId="team-multiple-checkbox-label"
          id="team-multiple-checkbox"
          data-testid="assigneeFilter"
          multiple
          value={selectedAssignee}
          defaultValue={blankArr}
          onChange={(event, type) =>
            onSelectedOptions(event, "selectedAssigneeVal")
          }
          onClose={() => setSearchText("")}
          inputProps={{ IconComponent: () => null }}
          renderValue={() =>
            getAssigneeLabel(
              displayAssigneeOptions,
              "Assignee",
              selectedAssignee,
              <>
                <Box className="select-icon">
                  <img src={accountOutline} alt="accountOutline" />
                </Box>
              </>
            )
          }
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            classes: {
              paper: `${classes.dropdownStyle} multiSelect assignee-multiSelect`,
            },
          }}
          displayEmpty
        >
          <ListSubheader>
            <CustomInputWeb
              placeholder={t(`${configJSON.serchHereTxt}`)}
              fullWidth={true}
              autoFocus={true}
              onChange={(e) => setSearchText(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          <ListSubheader className="multiselect-subheader">
          {t(`${configJSON.txtAssignees}`)}
            <Link
              to="#"
              className="tasklist-link"
              onClick={(e) => {
                clearAction("selectedAssigneeVal");
                e.stopPropagation();
              }}
            >
              {t(`${configJSON.txtRemoveAllAssignees}`)}
            </Link>
          </ListSubheader>
          {displayAssigneeOptions
            .filter((x: any) => selectedAssignee.includes(x.id))
            .map((assigneeOption: any) => (
              <MenuItem value={assigneeOption.id} key={assigneeOption.id}>
                <Box className="assignee-detail">
                  <Box className="assignee-left">
                    <Box className="assignee-profile"> {assigneeOption.initials}</Box>
                    <Box className="assignee-info">
                      <Box className="assignee-name">{assigneeOption.title}</Box>
                      <Box className="assignee-email">{assigneeOption.email}</Box>
                    </Box>
                  </Box>
                  <Box className="assignee-right">
                    <Box className="assinee-selected">
                      <img
                        className="normal-img"
                        src={checkBoxEmpty}
                        alt="checkBoxEmpty"
                      />
                      <img
                        className="selected-img"
                        src={checkBoxFilled}
                        alt="checkBoxFilled"
                      />
                    </Box>
                  </Box>
                </Box>
              </MenuItem>
            ))}
          <ListSubheader className="multiselect-subheader">
          {t(`${configJSON.txtUnassigned}`)}
          </ListSubheader>
          {displayAssigneeOptions
            .filter((x: any) => !selectedAssignee.includes(x.id))
            .map((option: any) => (
              assigneeItem(option)
            ))}
        </Select>
      </FormControl>

      {isAllProject && (
        <FormControl
          className="select-control multi-select project-lead-select"
          variant={mediumScreen ? "outlined" : "standard"}
        >
          {selectedProjectLead?.length > 1 && (
            <ClearIcon
              onClick={() => clearAction("selectedProjectLeadVal")}
              className="multiple-clear"
              data-test-id="clearPriorityFilter"
            />
          )}
          <InputLabel
            className="multiselect-label"
            id="priority-mutiple-checkbox"
          >
            <Box className="select-icon" component="span">
              <img src={shieldAccountIcon} alt="shieldAccountIcon" />
            </Box>
            {t(`${configJSON.projectLeadTxt}`)}
          </InputLabel>
          <Select
            labelId="priority-mutiple-checkbox-label"
            id="priority-mutiple-checkbox"
            data-testid="projectLeadFilter"
            multiple
            value={selectedProjectLead}
            defaultValue={blankArr}
            onChange={(event, type) =>
              onSelectedOptions(event, "selectedProjectLeadVal")
            }
            onClose={() => setPLText("")}
            renderValue={() =>
              getAssigneeLabel(
                displayPLOptions,
                "Project Lead",
                selectedProjectLead,
                <>
                  <Box className="select-icon">
                    <img src={accountOutlineProject} alt="accountOutline" />
                  </Box>
                </>
              )
            }
            inputProps={{ IconComponent: () => null }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: { paper: `${classes.dropdownStyle} multiSelect project-lead-multiSelect` },
            }}
            displayEmpty
          >
            <ListSubheader>
              <CustomInputWeb
                placeholder={t(`${configJSON.serchHereTxt}`)}
                fullWidth={true}
                autoFocus={true}
                onChange={(e) => setPLText(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>           
            {displayPLOptions.map((projectLead:{id:string;initials:string;title:string;email:string}) => (
              <MenuItem value={projectLead.id} key={projectLead.id}>
                <Box className="assignee-detail">
                  <Box className="assignee-left">
                    <Box className="assignee-profile"> {projectLead.initials}</Box>
                    <Box className="assignee-info">
                      <Box className="assignee-name">{projectLead.title}</Box>
                      <Box className="assignee-email">{projectLead.email}</Box>
                    </Box>
                  </Box>
                  <Box className="multiselect-squareCheck">
                  <img
                    src={checkBoxEmpty}
                    className="normal-img"
                    alt="checkBoxEmpty"
                  />
                  <img
                    src={checkBoxFilled}
                    className="selected-img"
                    alt="checkBoxFilled"
                  />
                </Box>                 
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
          {
            isAllProject ?  <FormControl className="select-control sortby-control">
            <Select
              MenuProps={{
                anchorOrigin: {
                  horizontal: "left",
                  vertical: "bottom",
                },
                transformOrigin: {
                  horizontal: "left",
                  vertical: "top",
                },
                classes: { paper: classes.dropdownStyle },
                getContentAnchorEl: null,
              }}
              onChange={(event, type) =>
                onSelectedOptions(event, "selectedSortByVal")
              }
              defaultValue={t(`${configJSON.txtSortBy}`)}
              displayEmpty
              data-testid="sortTasks"
              inputProps={{ IconComponent: () => null }}
              value={selectedSortBy}
              renderValue={(value) => {
                return (
                  <Box>
                    <img
                      src={sortVariant}
                      className="select-icon"
                      alt="sortVariant"                 
                    />
                    {value == "" ? `${t(configJSON.txtSortBy)}` : value}
                  </Box>
                );
              }}
            >

            <MenuItem value={t(`${configJSON.SortOption4}`)}>{t(`${configJSON.SortOption4}`)}</MenuItem>
            <MenuItem value={t(`${configJSON.SortOption5}`)}>{t(`${configJSON.SortOption5}`)}</MenuItem>
            <MenuItem value={t(`${configJSON.endDateTxt}`)}>{t(`${configJSON.endDateTxt}`)}</MenuItem>
            <MenuItem value={t(`${configJSON.categoryTxt}`)}>{t(`${configJSON.categoryTxt}`)}</MenuItem> 
            </Select>
          </FormControl>: 
          <FormControl className="select-control sortby-control">
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                    }}
                    onChange={(event, type) =>
                      onSelectedOptions(event, "selectedSortByVal")
                    }
                    displayEmpty
                    defaultValue={t(`${configJSON.txtSortBy}`)}
                    data-testid="sortTasks"
                    value={selectedSortBy}
                    inputProps={{ IconComponent: () => null }}
                    renderValue={(value) => {
                      return (
                        <Box>
                          <img
                            className="select-icon"
                            src={sortVariant}
                            alt="sortVariant"                 
                          />
                          {value == "" ? `${t(configJSON.txtSortBy)}` : value}
                        </Box>
                      );
                    }}
                  >
                <MenuItem value={t(`${configJSON.txtDueDate}`)}>{t(`${configJSON.txtDueDate}`)}</MenuItem>
                  <MenuItem value={t(`${configJSON.timeTrackedTxt}`)}>{t(`${configJSON.timeTrackedTxt}`)}</MenuItem>
                  <MenuItem value={t(`${configJSON.txtPriority}`)}>{t(`${configJSON.txtPriority}`)}</MenuItem>
                  </Select>
                </FormControl>
          }     
    </Box>
  );
}
// Customizable Area End
