// Customizable Area Start
import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

interface RemoveContactProps {
  modalOpen: boolean;
  modalClose: () => void;
  delContactDetails: { firstName: string; lastName: string; id: string; groupName: string; };
  deleteContactAction: () => void;
  headding:string;
  cancleBtntxt:string;
  removeBtntxt:string;
}
const useStyles = makeStyles((theme) => ({
  modalDialogBox: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#ffffff",
    padding: "48px",
    width: 580,
    borderRadius: "24px",
    [theme.breakpoints.down("xs")]: {
      padding: "24px",
      maxWidth: "327px",
      width: "90%",
      borderRadius: "12px",
      backgroundColor: "#F2F8FC",
    },
    "& .modal-heading": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "22px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "23px",
      },
    },
    "& .modal-title": {
      lineHeight: "30px",
      fontSize: "24px",
      letterSpacing: "0",
      color: "#000000",
      fontFamily: "Expletus Sans",
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
        lineHeight: "24px",
      },
    },
    "& .modal-desc": {
      fontSize: "14px",
      lineHeight: "26px",
      letterSpacing: "0.0075em",
      fontWeight: 400,
      color: "#2b2b2b",
      fontFamily: "Roboto",
      [theme.breakpoints.down("xs")]: {
        fontSize: "13px",
        lineHeight: "17px",
      },
      "& .title-dark": {
        fontWeight: "700",
      },
    },
    "& .close-icon": {
      color: "rgba(43, 43, 43, 0.87)",
      lineHeight: "0",
    },
    "& .modal-footer": {
      justifyContent: "flex-end",
      display: "flex",
      marginTop: "32px",
      [theme.breakpoints.down("xs")]: {
        marginTop: "24px",
      },
      "& .primary-btn, & .secondary-btn ": {
        width: "auto",
        minWidth: "157px",
        [theme.breakpoints.down("xs")]: {
          minWidth: "70px",
          padding: "4px 6px",
        },
      },
      "& .MuiButton-root": {
        marginRight: "24px",
        "&:last-child": {
          marginRight: "0",
        },
      },
    },
    "& .primary-btn": {
      minHeight: "48px",
      lineHeight: "19px",
      fontSize: "14px",
      marginBottom: "0",
      [theme.breakpoints.down("xs")]: {
        minHeight: "30px",
      },
    },
    "& .secondary-btn": {
      minHeight: "48px",
      lineHeight: "19px",
      fontSize: "14px",
      marginBottom: "0",
      [theme.breakpoints.down("xs")]: {
        minHeight: "30px",
        border: "0",
        color: "#4BA3B7",
      },
    },
  },
  havingTroubleModal: {
    flex: "0 0 51%",
    maxWidth: "51%",
    padding: "0 15px",
    "& .auth-logo": {
      marginBottom: "90px",
      maxWidth: "162px",
    },
    "& .auth-inner": {
      paddingTop: "129px",
      "& .auth-left-row": {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-end",
        margin: "0 -10px",
        "& .col": {
          padding: "0 10px",
        },
        "& .col-left": {
          flex: "0 0 54%",
          maxWidth: "54%",
        },
        "& .col-right": {
          flex: "0 0 46%",
          paddingRight: "206px",
          maxWidth: "46%",
          "@media (max-width: 1536px)": {
            paddingRight: "95px",
          },
        },
      },
    },
    "& .auth-desc": {
      fontSize: "24px",
      color: "#000000",
      lineHeight: "28px",
      fontWeight: 400,
      marginBottom: "19px",
    },
    "& .auth-name": {
      fontSize: "14px",
      letterSpacing: "0",
      lineHeight: "24px",
      color: "#94a3b8",
      fontWeight: 400,
    },
    "& .auth-img": {
      margin: "61px auto 0",
      width: "100%",
      maxWidth: "380px",
      height: "630px",
      overflow: "hidden",
      position: "relative",
      marginBottom: "-50px",
    },
    "& .copyright-txt": {
      fontSize: "12px",
      letterSpacing: "0",
      fontWeight: 400,
      color: "#94a3b8",
      textAlign: "right",
      fontFamily: "Expletus Sans",
      "& span": {
        color: "#2b2b2b",
      },
    },
  },
  modalDialog: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(57,57,57,0.502)",
    },
  },
  primaryButton: {
    background: "#4ba3b7",
    border: "1px solid #4ba3b7",
    borderRadius: "6px",
    filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
    fontSize: "16px",
    lineHeight: "21px",
    color: "#f2f8fc",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    width: "100%",
    padding: "12px 10px",
    minHeight: "56px",
    marginBottom: "30px",
    transition: "all 0.5s ease-in-out",
    textTransform: "capitalize",
    "&:hover": {
      background: "#4ba3b7",
      color: "#ffffff",
    },
    "&.Mui-disabled": {
      background: "#92C7D3",
      color: "#F2F8FC",
      borderColor: "#92C7D3",
    },
  },
  secondaryButton: {
    background: "transparent",
    border: "1px solid #e8ecf2",
    borderRadius: "7px",
    filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
    fontSize: "19px",
    lineHeight: "23px",
    color: "#2b2b2b",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    textTransform: "unset",
    width: "100%",
    padding: "12px 10px",
    minHeight: "56px",
    marginBottom: "30px",
    transition: "all 0.5s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "20px",
      padding: "8px 6px",
      minHeight: "46px",
    },
    "&:hover": {
      background: "transparent",
      color: "#2b2b2b",
    },
  },
}));
const RemoveContact = (props: RemoveContactProps) => {
  const {
    modalOpen,
    modalClose,
    delContactDetails,
    deleteContactAction,
    headding,
    cancleBtntxt,
    removeBtntxt
  } = props;
  const classes = useStyles();
  return (
    <Box className={classes.havingTroubleModal}>
      <Modal
        open={modalOpen}
        onClose={modalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
      >
        <Box className={classes.modalDialogBox}>
          <Box className="modal-heading">
            <Typography className="modal-title" component="h2">
             {headding}
            </Typography>
            <Link to="#" className="close-icon" onClick={modalClose}>
              <CloseOutlinedIcon />
            </Link>
          </Box>
          <Box className="modal-description">
            <Typography className="modal-desc">
                <>
                  {`Are you sure you want to remove `}
                  <span className="title-dark">
                    {`${delContactDetails.firstName} ${delContactDetails.lastName}`}
                  </span>
                  {` from `}
                  <span className="title-dark">
                  {delContactDetails.groupName}?                
                  </span>
                </>
            </Typography>

            <Box className="modal-footer">
              <Button
                className={`secondary-btn ${classes.secondaryButton}`}
                title="Cancel"
                onClick={modalClose}
              >
                {cancleBtntxt}
              </Button>
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                title="Knowledge Base"
                onClick={deleteContactAction}
              >
                {removeBtntxt}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default RemoveContact;
// Customizable Area End