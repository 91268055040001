//Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "../../email-account-registration/src/teams-web/TeamsStyles.web";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { toast } from "react-toastify";
import i18n from "../../../web/src/utilities/i18n";

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles>{
  navigation?: any;
  id?: string;
  classes: any;
  isMobile?: boolean;
}

export interface S {
  // Customizable Area Start
  isSettingDrawerOpened: boolean;
  isNextStep: boolean;
  chooseReason: string;
  feedback: string;
  password:string;
  reasonError:string;
  feedbackError:string;
  isPasswordVisible: boolean;
  passwordError:string;
  // Customizable Area End
}
export interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class Settinggs5Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  apiDeactivateAccountId: string = "";
  userSessionData: any;
  userToken: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      isSettingDrawerOpened: false,
      isNextStep: false,
      chooseReason: "",
      feedback: "",
      password:"",
      reasonError:"",
      feedbackError:"",
      isPasswordVisible: false,
      passwordError:"",
    };

   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const lang = localStorage.getItem("lang") ?? "en"; 
    await i18n.changeLanguage(lang);
  }

  t(key:any) {
    return i18n.t(key, { ns: "translation" } )
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.apiDeactivateAccountId) {
        if (responseJson?.message) {
          this.createToastNotification(configJSON.deactivateMessage)
          sessionStorage.clear();
          localStorage.clear();
          this.props.navigation.navigate("EmailAccountLogin")
        } else {
          const errors = responseJson.errors;
          if(errors[0].message){
            this.createToastNotification(errors[0].message,true)
          }
          else{
            this.createToastNotification("Something wen't wrong",true)
          }
          
        }
      }
    }
  }



   deactivateReasons =["I no longer want to use EtOH Suite"
  ,"I'm no longer part of this company",
  "I was erroneously added to EtOH Suite",
  "Other"]

  createToastNotification = (toastMesssage: string,error:boolean = false) => {
    error?
    toast.error(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
      // icon: () => <img src={warningIcon} alt="emptydata" />,
    }):toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMesssage}</div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        // icon: () => <img src={checkedIcon} alt="emptydata" />,
      }
    );
  };
  

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof S;
    const value = event.target.value;
    this.setState(({ [name]: value } as unknown) as Pick<S, keyof S>);

    switch(name){
      case "feedback":
        this.setState({feedbackError:""});
        break;
      case "password":
        this.setState({passwordError:""});
        break;
    }
  };
  
  handleSelectChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    
    this.setState({chooseReason:event.target.value as string,reasonError:""});

  };

  deactivateAccount = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeactivateAccountId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPut
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deactivateAccountApiEndpoint + `?delete_reason=${this.state.chooseReason}&feedback=${this.state.feedback}&password=${this.state.password}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deactiveAccount = () => {
    if(this.state.password.trim() == ""){
      this.setState({passwordError:configJSON.enterPassword})
      return;
    }
    this.deactivateAccount();
  };

  toggleSettingDrawer = () => {
    this.setState({
      isSettingDrawerOpened: true,
    });
  };

  closeSettingDrawer = () => {
    this.setState({
      isSettingDrawerOpened: false,
    });
  };

  continueNextStep = () => {
    let errorFlag = false;
    const {chooseReason,feedback} = this.state;

    if(chooseReason.trim() == ""){
      this.setState({reasonError:"Please Choose Reason"});
      errorFlag=true;
    }

    if(feedback.trim() == ""){
      this.setState({feedbackError:"Please Enter Feedback"});
      errorFlag=true;
    }
    if(errorFlag) return
    this.setState({ isNextStep: true });
  };

  backToFirstStep = () => {
    this.setState({ isNextStep: false,password:"",passwordError:"",isPasswordVisible:false });
  };

  togglePasswordVisibility = () => {
    this.setState({isPasswordVisible: !this.state.isPasswordVisible})
  }

  backButtonClick=()=>{
    this.props.navigation.navigate("EquizDashboardWeb")
  }
  // Customizable Area End

 
  

}
//Customizable Area End
