import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useTheme } from '@material-ui/styles';

// const theme = useTheme();

const withMediaQuery = (args: string) => (Component: any) => (props: any) => {
  const mediaQuery = useMediaQuery(args);
  return <Component isMobile={mediaQuery} {...props} />;
};

export default withMediaQuery;