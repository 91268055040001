// Customizable Area Start
import React from "react";
import Box from "@material-ui/core/Box";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StarIcon from "@material-ui/icons/Star";
import Slider from "react-slick";
import { imgSlider1, imgSlider2, imgSlider3 } from "./assets";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

const testimonials = [
  {
    id: 1,
    testimonial:
      "A must-have for any modern winemaker looking to stay organized.",
    author: "Michael Johnson",
    stars: 5,
    sliderImg : imgSlider1,
  },
  {
    id: 2,
    testimonial:
      "The app has improved our team's productivity by leaps and bounds.",
    author: "Elizabeth Anderson",
    stars: 5,
    sliderImg : imgSlider2,
  },
  {
    id: 3,
    testimonial: "Easy to use and streamlines all our business processes.",
    author: "James Brown",
    stars: 5,
    sliderImg : imgSlider3,
  },
  {
    id: 4,
    testimonial: "Saves us time and money with its efficient management tools.",
    author: "Sarah Clark",
    stars: 5,
    sliderImg : imgSlider1,
  },
  {
    id: 5,
    testimonial:
      "The app's real-time reporting capabilities have revolutionized the way we do business.",
    author: "John Mosby",
    stars: 5,
    sliderImg : imgSlider3,
  },
  {
    id: 6,
    testimonial:
      "Excellent customer support and regular updates keep the app relevant and effective.",
    author: "Susan Mitchell",
    stars: 5,
    sliderImg : imgSlider1,
  },
  {
    id: 7,
    testimonial:
      "Integration with other tools has made our workflows seamless.",
    author: "David Baker",
    stars: 5,
    sliderImg : imgSlider3,
  },
  {
    id: 8,
    testimonial:
      "We can now make informed decisions with the app's comprehensive data analysis.",
    author: "Jennifer Lee",
    stars: 5,
    sliderImg : imgSlider1,
  },
  {
    id: 9,
    testimonial:
      "The app has allowed us to track our progress and growth accurately.",
    author: "Robert Davis",
    stars: 5,
    sliderImg : imgSlider2,
  },
  {
    id: 10,
    testimonial:
      "Highly recommend this app to any business looking to streamline their operations.",
    author: "Karen Johnson",
    stars: 5,
    sliderImg : imgSlider3,
  },
];
const CustomSlider = (props:any) => {
  const { t } = props;
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 10000,
    nextArrow: (
      <Box className="slider-next">
        <KeyboardBackspaceIcon />
      </Box>
    ),
    prevArrow: (
      <Box className="slider-prev">
        <KeyboardBackspaceIcon />
      </Box>
    ),
  };
  return (
    <>
      {testimonials.length > 0 && 
      <Slider {...settings} className="carosel-slider">
        {testimonials.map((testimonial) => {
          return (
            <Box className="slider-item" key={testimonial.id}>
          <Box className="slider-img">
            <img src={testimonial.sliderImg} alt="sliderImg1" />
          </Box>
          <Box className="slider-caption">
            <Box className="slider-quotes">
              {t(`${testimonial.testimonial}`)}
            </Box>
            <Box className="slider-bottom">
              <Box className="slider-author">- {testimonial.author}</Box>
              <Box className="slider-control">
                <Box className="start-icon">
                {Array.from({ length: testimonial.stars }).map((_, starIndex) => (
                        <StarIcon key={starIndex} style={{ fontSize: "20px" }} />
                      ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
          )

        })}
      </Slider>
      }
    </>
  );
};

export default CustomSlider;
// Customizable Area End
