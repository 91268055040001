// Customizable Area Start
import React from "react";
import Box from "@material-ui/core/Box";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { bgImage, downloadPdfImg, lineActive, screenshot } from "../../blocks/TicketSystem/src/assets";
import {Typography} from "@material-ui/core";
const testimonials = [
  {
    id: 1,
    testimonial:
      "A must-have for any modern winemaker looking to stay organized.",
    fileName: "",
    stars: 5,
    sliderImg : bgImage,
  },
  {
    id: 2,
    testimonial:
      "The app has improved our team's productivity by leaps and bounds.",
    fileName: "",
    stars: 5,
    sliderImg : screenshot,
  },
  {
    id: 3,
    testimonial: "Easy to use and streamlines all our business processes.",
    fileName: "system_error_file.pdf",
    stars: 5,
    sliderImg : downloadPdfImg,
  }, 
];
const ImageSlider = () => {
  let settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: false,
    autoplaySpeed: 10000,
    customPaging: (i:any) => (
      <img src={lineActive} alt="dots"  />
    ),
  };
  return (
    <>
      {testimonials.length > 0 && 
      <Slider {...settings} className="carosel-slider">
        {testimonials.map((testimonial) => {
          return (
          <Box className="slider-item" key={testimonial.id}>
          <Box className="slider-img">
            <img src={testimonial.sliderImg} alt="sliderImg1" />
          <Typography className="file-name">{testimonial?.fileName}</Typography>
          <Box>
          </Box>
          </Box>
        </Box>
        )

        })}
      </Slider>
      }
    </>
  );
};

export default ImageSlider;
// Customizable Area End
