export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const noEmailCampaigns = require("../assets/no-email-campaigns.png");
export const minimizeGray = require("../assets/minimize-gray.png");
export const closeGray = require("../assets/close-gray.png");
export const stepperArrow = require("../assets/stepper-arrow.svg");
export const expandForm = require("../assets/expand-form.svg");
export const sldierImg1 = require("../assets/slider-image1.jpg");
export const sldierImg2 = require("../assets/slider-image2.jpg");
export const helpCircle = require("../assets/help-circle.svg")
export const attachmentIcon = require("../assets/attachment.svg");
export const clockOutline   = require("../assets/clock-outline.png");
export const userIcon   = require("../assets/account-multiple-outline.svg");
export const sunIcon   = require("../assets/cursor-default-click-outline.svg");
export const emailIcon   = require("../assets/email-open-outline.svg");
export const downThumbIcon   = require("../assets/thumb-down-outline.svg");
export const CalenderIcon   = require("../assets/calendar-today-outline.svg");
export const previewText = require("../assets/preview-text-email.png");
export const previewTemplateCampaign = require("../assets/template-preview.png");
export const senderList = require("../assets/sender-list.svg");
export const contactList = require("../assets/contact-list-filter.svg");
export const artBoard = require("../assets/artboard.svg");
export const formatList = require("../assets/format-list-text.svg");
export const calendarRange = require("../assets/calendar-range.svg");
export const viewColumn = require("../assets/view-column.svg");
export const sortBy = require("../assets/sort_variant.svg");
export const arrowDown =require("../assets/chevron-down.png");
export const connectBrevo = require("../assets/connect-account.png");
export const previewTxt =  require("../assets/preview-text-email-2x.jpeg")
export const checkGreen =  require("../assets/check-green.png")