// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import React from "react";

import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./TeamsStyles.web";

export type TeamTitleTextInputType = {
  id: number;
  showError: boolean;
};

export interface Props extends WithStyles<typeof customStyles> {
  navigation: any;
  id: string;
}

export interface S {}

export interface SS {
  id: any;
}

export default class InviteMembersController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  returnTeam = () => {
    this.props.navigation.navigate("AccountCreation");
  };
  addAnotherMember = () => {
    this.props.navigation.navigate("InviteMembers");
  };
}
// Customizable Area End
