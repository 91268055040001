// Customizable Area Start
import React, { useState, useMemo } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import { ListSubheader, InputAdornment, Box } from "@material-ui/core"; 
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import { checkBlack } from "./assets";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const blankArr: any = [];

const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function GroupContactsSort(props: any) {
  const {
    classes,
    selectGroupOptions,
    selectOptions,
    onSelectedOptions,
    selectedGroup,
    selectedCompany,
    selectedTitle,
    clearAction,
  } = props;

  const teamNames = useMemo(
    () =>
      selectGroupOptions.map(
        (item: { attributes: { id: string; title: string } }) => ({
          id: item.attributes.id,
          title: item.attributes.title,
        })
      ),
    [selectGroupOptions]
  );

  const uniqueCompanyName = useMemo(
    () => [
      ...new Set(
        selectOptions.map(
          (item: { attributes: { company_name: string } }) =>
            item.attributes.company_name
        )
      ),
    ],
    [selectOptions]
  );

  const uniqueJobTypes = useMemo(
    () => [
      ...new Set(
        selectOptions.map(
          (item: { attributes: { business_category: string } }) =>
            item.attributes.business_category
        )
      ),
    ],
    [selectOptions]
  );

  const [searchText, setSearchText] = useState("");
  const [searchCompanyNameText, setCompanyNameText] = useState("");
  const [searchBusinessText, setBusinessText] = useState("");

  const displayTeamOptions = useMemo(
    () =>
      teamNames.filter((obj: { title: string }) =>
        containsText(obj.title, searchText)
      ),
    [teamNames, searchText]
  );

  const displayCompanyNameOptions = useMemo(
    () =>
      uniqueCompanyName.filter((obj: any) => containsText(obj, searchCompanyNameText)),
    [uniqueCompanyName, searchCompanyNameText]
  );

  const displayBusinessOptions = useMemo(
    () =>
      uniqueJobTypes.filter((obj: any) => containsText(obj, searchBusinessText)),
    [uniqueJobTypes, searchBusinessText]
  );
 

  const getLabel = (value: string[], def: string = "") => {
    const selectedItemCount = value.length;

    const hasUndefinedOrNull = value.some(item => item === undefined || item === null);
   
    if (selectedItemCount === 0) {
      return def || "Choose Option";
    } else if (selectedItemCount === 1 && def === "Group") {
      const result = displayTeamOptions.find((obj: any) => obj.id === value[0]);
      const myres = result ? result.title : "Title Not Found";
      if(myres === "Title Not Found"){
        return;
      }
      return   result.title;
    } else if (selectedItemCount === 1 && def === "Company Name") {
     return value;
    } else if (selectedItemCount === 1) {
      return value;
    } else if (selectedItemCount > 1 && def === "Group") {
      if(hasUndefinedOrNull){
        return <>{selectedItemCount - 1 } groups selected</>;
      }
      return <>{selectedItemCount} groups selected</>;
    }
    else if (selectedItemCount > 1) {
      if(hasUndefinedOrNull){
        return <>{selectedItemCount - 1 } items selected</>;
      }
      return <>{selectedItemCount} items selected</>;
    }
  };

  const mediumScreen = useMediaQuery('(max-width:599px)');

  return (
    <Box className="top-middle">
      <Box className="table-selection">
        <FormControl className="select-control multi-select" variant={mediumScreen ? 'outlined' : 'standard'}>
          {selectedGroup.length > 1 && (
            <ClearIcon onClick={()=>clearAction( "selectedGroupVal")} className="multiple-clear" data-test-id="multiple-clear"/>
          )}
          <InputLabel className="multiselect-label" id="team-multiple-checkbox">Group</InputLabel>
          <Select
            labelId="team-multiple-checkbox-label"
            id="team-multiple-checkbox"
            multiple
            value={selectedGroup}
            defaultValue={blankArr}
            onChange={(event, type) =>
              onSelectedOptions(event, "selectedGroupVal")
            }
            onClose={() => setSearchText("")}
            renderValue={() => getLabel(selectedGroup, "Group")}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: { paper: `${classes.dropdownStyle} multiSelect` },
            }}
            displayEmpty
            data-testid="memberTeamFilter"
          >
            <ListSubheader>
              <CustomInputWeb
                placeholder="Search here"
                fullWidth={true}
                autoFocus={true}
                onChange={(e) => setSearchText(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
                data-test-id="search-group"
              />
            </ListSubheader>

            {displayTeamOptions.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                <ListItemText primary={option.title} />
                <Box className="multiselect-check">
                  <img src={checkBlack} alt="sort-group" />
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>


        <FormControl className="select-control multi-select" variant={mediumScreen ? 'outlined' : 'standard'}>
          {selectedCompany.length > 1 && (
            <ClearIcon onClick={()=>clearAction( "selectedCompanyVal")} className="multiple-clear" data-test-id="multiple-clear1" />
          )}
          <InputLabel className="multiselect-label" id="jobrole-mutiple-checkbox">Company Name</InputLabel>
          <Select
            labelId="jobrole-mutiple-checkbox-label"
            id="jobrole-mutiple-checkbox"
            multiple
            value={selectedCompany}
            defaultValue={blankArr}
            onChange={(event, type) =>
              onSelectedOptions(event, "selectedCompanyVal")
            }
            onClose={() => setCompanyNameText("")}
            renderValue={(selected) => getLabel(selectedCompany, "Company Name")}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: { paper: `${classes.dropdownStyle} multiSelect` },
            }}
            displayEmpty
            data-testid="memberRoleFilter"
          >
            <ListSubheader>
              <CustomInputWeb
                placeholder="Search here"
                fullWidth={true}
                autoFocus={true}
                onChange={(e) => setCompanyNameText(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayCompanyNameOptions.map((jobRole: any) => (
              <MenuItem key={jobRole} value={jobRole}>
                <ListItemText primary={jobRole} />
                <Box className="multiselect-check">
                  <img src={checkBlack} alt="sort-group" />
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="select-control multi-select" variant={mediumScreen ? 'outlined' : 'standard'}>
          {selectedTitle.length > 1 && (
            <ClearIcon onClick={()=>clearAction( "selectedTitleVal")} className="multiple-clear" data-test-id="multiple-clear2" />
          )}
          <InputLabel className="multiselect-label" id="jobtitle-multiple-checkbox">Business Category</InputLabel>
          <Select
            labelId="jobtitle-multiple-checkbox-label"
            id="jobtitle-multiple-checkbox"
            multiple
            value={selectedTitle}
            defaultValue={blankArr}
            onChange={(event, type) =>
              onSelectedOptions(event, "selectedTitleVal")
            }
            onClose={() => setBusinessText("")}
            renderValue={(selected) => getLabel(selectedTitle, "Business Category")}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: { paper: `${classes.dropdownStyle} multiSelect` },
            }}
            displayEmpty
            data-testid="memberTitleFilter"
          >
            <ListSubheader>
              <CustomInputWeb
                placeholder="Search here"
                fullWidth={true}
                autoFocus={true}
                onChange={(e) => setBusinessText(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayBusinessOptions.map((jobTitle: any) => (
              <MenuItem key={jobTitle} value={jobTitle}>
                <ListItemText primary={jobTitle} />
                <Box className="multiselect-check">
                  <img src={checkBlack} alt="sort-group" />
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl> 
      </Box>
    </Box>
  );
}
// Customizable Area End