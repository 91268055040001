import React from "react";
// Customizable Area Start
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Checkbox,
  Modal,
} from "@material-ui/core/";
import moment from "moment";
import {
  imgCreateTaskFilter,
  download,
  plusCircle,
  editCircle,
  checkCircle,
  arrowULeft,
  checkBoxEmpty,
  checkBoxFilled,
} from "./assets";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import WebLoader from "../../../components/src/WebLoader.web";
// Customizable Area End

import ActivityLogControllerWeb, {
  Props,
  configJSON
  // Customizable Area Start
  // Customizable Area End
} from "./ActivityLogController.web";

export class ActivityLog extends ActivityLogControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {classes} = this.props;
    return (
      <>
      {this.state.isLoading && <WebLoader/>}
        <Box className="tab-heading">
          <Box className="heading-left">
            <Box className="primary-txt">
              {this.t(`${configJSON.activityLogsTxt}`)}
            </Box>
            <Box className="sub-txt">
              {this.t(`${configJSON.activityLogSubTxt}`)}
            </Box>
          </Box>
          <Box className="heading-right">
            <Link
              to="#"
              className="heading-link"
              onClick={this.filterActivityHandler}
              data-test-id="activity-filter-modal"
            >
              <img src={imgCreateTaskFilter} alt="imgCreateTaskFilter" />
            </Link>
            <Link
              to="#"
              className="heading-link"
              onClick={this.downloadProjectsLog}
              data-test-id="download-activity-logs"
            >
              <img src={download} alt="download" />
            </Link>
          </Box>
        </Box>
        <List className="activitylist-ul">
          {this.state.activityLogListing.map((actLog: any) => {
            let imgIcon = <img src={plusCircle} alt="plusCircle" />;
            switch (actLog.attributes.action_type) {
              case "edit":
                imgIcon = <img src={editCircle} alt="editCircle" />;
                break;
              case "undone":
                imgIcon = <img src={arrowULeft} alt="arrowULeft" />;
                break;
              case "create":
                imgIcon = <img src={plusCircle} alt="plusCircle" />;
                break;
              case "complete":
                imgIcon = <img src={checkCircle} alt="checkCircle" />;
                break;
            }
            return (
              <ListItem className="activity-li" key={actLog.attributes.name}>
                <ListItemIcon className="activity-icon">{imgIcon}</ListItemIcon>
                <ListItemText className="activity-details">
                  <Box className="activity-info">
                    <Box component="span" className="name">
                      {actLog.attributes.name}
                    </Box>{" "}
                    {actLog.attributes.action}
                  </Box>
                  <Box className="activity-date">
                    {moment(actLog.attributes.timestamp).format("MMM DD  YYYY")}{" "}
                    at {moment(actLog.attributes.timestamp).format("h:mm a")}
                  </Box>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
        {this.state.isActiveFilter && 
          <Modal
          hideBackdrop
          className={classes.modalDialog}
          aria-describedby="modal-modal-description"
          onClose={this.getProjectsLogWithFilter}
          aria-labelledby="modal-modal-title"
          open={this.state.isActiveFilter}
        >
          <Box className={`filter-dialogbox ${classes.modalDialogBox}`}>
            <Box className="modal-dialoginner">
              <Box className="modal-heading">
                <Box className="filter-heading">
                  <Box
                    className="auth-back"
                    onClick={this.getProjectsLogWithFilter}
                    data-test-id="close-activity-log-filter"
                  >
                    <i>
                      <svg
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        focusable="false"
                        className="MuiSvgIcon-root"
                      >
                        <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                      </svg>
                    </i>
                  </Box>
                  <Typography className="modal-title" component="h2">
                  {this.props.activityTitle}
                  </Typography>
                </Box>
                <Box
                  data-test-id="activityLogClose"
                  onClick={this.getProjectsLogWithFilter}
                  className="close-icon"
                >
                  <CloseIcon />
                </Box>
              </Box>
              <Box className="modal-description">
                <Box className="heading-block">
                  <Box className="primary-txt">{this.t(`${configJSON.filterTxt}`)}</Box>
                  <Box className="sub-txt" component="p">
                    {this.t(`${configJSON.aductActivityTxt}`)}
                  </Box>
                </Box>
                <Box component="form" className="modal-form" >
                  <Box className="form-info-wrapper">
                    <Typography variant="h2" className="form-heading">                        
                      {this.t(`${configJSON.dateRangeTxt}`)}
                    </Typography>
                    <Box className="form-control">
                      <CustomDatePicker
                        value={this.state.logDates}
                        placeholder={this.t(`${configJSON.txtChooseDate}`)}
                        range={true}
                        currentDate=""
                        onChange={this.dateRangeFilter}
                        // datePickerRef={this.datePickerRef}
                        data-test-id="logDateFilter"
                        onOpenPickNewDate={false}
                      />
                    </Box>
                  </Box>
                  <Box className="form-info-wrapper">
                    <Typography  variant="h2" className="form-heading">
                    {this.t(`${configJSON.membersTxt}`)}
                    </Typography>
                    <List className="tasklist-member">
                      {this.displayAssigneeOptions().map((assignee) => {
                        const labelId = `checkbox-list-label-${assignee.id}`;
                        return (
                          <ListItem
                            className="tasklist-li"
                            data-test-id={`logAssignee-${assignee.id}`}
                            role={undefined}
                            key={assignee.id}
                            onClick={() => this.selectAssignee(assignee.id)}

                          >
                            <Box
                              className="tasklist-left"
                            >
                              <Box className="assignee-profile">
                                {assignee.initials}
                              </Box>
                              <Box className="assignee-info">
                                <Box className="assignee-name">
                                  {assignee.title}
                                </Box>
                                <Box className="assignee-email">
                                  {assignee.email}
                                </Box>
                              </Box>
                            </Box>
                            <ListItemIcon className="tasklist-icon">
                              <Checkbox
                                tabIndex={-1}
                                icon={
                                  <img
                                  src={checkBoxEmpty}
                                  alt="checkBoxEmpty"
                                  />
                                }
                                className="checklist-check"
                                checkedIcon={
                                  <img
                                    src={checkBoxFilled}
                                    alt="checkBoxFilled"
                                  />
                                }
                                checked={
                                  this.state.logAssignees.indexOf(
                                    assignee.id
                                  ) !== -1
                                }
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                disableRipple
                              />
                            </ListItemIcon>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
        }
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default ActivityLog;
// Customizable Area End
