// Customizable Area Start
import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import OnboardingWelcomeController, {
  Props,
} from "./OnboardingWelcomeController.web";

import AppHeader from "../../../components/src/AppHeader.web";
import { amico } from "./assets";

export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    overrides: {
      MuiButton: {
        primaryButton: {},
      },
    },
    membersuccessWrapper: {
      "& .inner-wrapper": {
        margin: "auto",
        width: "100%",
      },
    },
    memberAddedWrapper: {
      padding: "60px",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        padding: "40px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "24px 60px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "24px",
      },
      "& .member-added-row": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "997px",
        margin: "0 auto",
        [theme.breakpoints.down("md")]: {
          maxWidth: "850px",
        },
        [theme.breakpoints.down("xs")]: {
          flexWrap: "wrap",
          justifyContent: "center",
          textAlign: "center",
          maxWidth: "320px",
        },
        "& .member-added-img": {
          maxWidth: "456px",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            maxWidth: "325px",
          },
          [theme.breakpoints.down("sm")]: {
            maxWidth: "290px",
          },
          [theme.breakpoints.down("xs")]: {
            maxWidth: "235px",
          },
        },
      },
      "& .member-added-content": {
        marginLeft: "168px",
        flex: "1",
        [theme.breakpoints.down("md")]: {
          marginLeft: "100px",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "50px",
        },
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          marginTop: "20px",
        },
      },
      "& .heading": {
        fontSize: "24px",
        lineHeight: "32px",
        margin: "0 0 8px",
        color: "rgba(43, 43, 43, 0.87)",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        [theme.breakpoints.down("md")]: {
          fontSize: "20px",
          lineHeight: "24px",
          margin: "0 0 15px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "18px",
          lineHeight: "24px",
          margin: "0 0 10px",
        },
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        margin: "0 0 48px",
        color: "#505050",
        fontFamily: "Roboto",
        fontWeight: 400,
        letterSpacing: "0.25px",
        [theme.breakpoints.down("md")]: {
          margin: "0 0 30px",
        },
      },
      "& .team-button-wrapper": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        "& .MuiButton-root": {
          minHeight: "56px",
          width: "100%",
        },
      },
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "7px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      width: "auto",
      minWidth: "150px",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    wrapper: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
  });

class OnboardingWelcome extends OnboardingWelcomeController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
        <Box>
          <Box
            className={`wrapper ${classes.wrapper} ${classes.membersuccessWrapper}`}
          >
            <AppHeader />
            <Box className={`inner-wrapper ${classes.innerWrapper}`}>
              <Box className={classes.memberAddedWrapper}>
                <Box className="member-added-inner">
                  <Box className="member-added-row">
                    <Box className="member-added-img">
                      <img src={amico} alt="amico" />
                    </Box>
                    <Box className="member-added-content">
                      <Typography className="heading" variant="h1">
                        {this.t(`${this.welcomeTitle}`)}
                      </Typography>
                      <Box component="p" className="sub-txt">
                        {this.t(`${this.welcomeHyperTitle}`)}
                      </Box>
                      <Box className="team-button-wrapper">
                        <Button
                          className={`primary-btn ${classes.primaryButton}`}
                          onClick={this.returnTeam}
                          data-testid="returnTeam"
                        >
                          {this.t(`${this.welcomeButtonText}`)}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <Footer /> */}
        </Box>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(OnboardingWelcome);
// Customizable Area End
