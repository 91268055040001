import React from "react";

import {
  // Customizable Area Start
  Box,
  Drawer,
  Input,
  InputAdornment,
  Typography,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  RadioGroup,
  Radio,
  Popover,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CfEtohApi6Controller, {
  Props,
  configJSON,
} from "./CfEtohApi6Controller.web";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import WebLoader from "../../../components/src/WebLoader.web";
import { withStyles } from "@material-ui/core/styles";
import { etohShopStyle } from "../../../components/src/EtohShopStyleHelper.web";
import SearchIcon from "@material-ui/icons/Search";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { menu, defaultServiceImage, sortVariant } from "./assets";
import BackArrow from "@material-ui/icons/ArrowBack";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import { CustomEthoShopSidebar } from "./CustomEtohShopSidebar.web";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
// Customizable Area End

export class CfEtohApi6 extends CfEtohApi6Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, isMobile } = this.props;

    const { productsData, subCategoryName, anchorElDropdown, subscriptionData } =
      this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.isLoading && <WebLoader />}
        <div style={{position: "fixed", width: "100%", zIndex: 2}}>
          <GlobalHeaderWeb />
        </div>
        <Box className={classes.mainEtohShopWrapper}>
          <Box className="mobile-heading">
            <Box className="back-btn-wrap">
              <BackArrow
                style={{ color: "#2B2B2B" }}
                onClick={this.handleBackBtn}
                data-test-id="handleBackBtn"
              />
            </Box>
            <Typography className="heading" variant="h1">
              {this.t(`${configJSON.cardsTxt}`)}
            </Typography>
            <Box
              className="marketing-menu"
              data-test-id="toggleMenuDrawer"
              onClick={this.toggleMenuDrawer}
            >
              <img src={menu} alt="menu" />
            </Box>
          </Box>
          <Box className="page-wrapper">
            <Box className={classes.etohShopdrawer}>
              <Drawer
                classes={{
                  root: classes.campaignRootWrapper,
                  paper: classes.etohShopPaper,
                }}
                variant={isMobile ? "temporary" : "permanent"}
                open={isMobile ? this.state.isMenuDrawerOpened : true}
              >
                <CustomEthoShopSidebar
                  classes={classes}
                  toggleMenu={this.closeMenuDrawer}
                  getProductsById={this.getAllEtohProducts}
                  data-test-id="closeMenuDrawer"
                />
              </Drawer>
            </Box>
            <Box className="etohShop-top">
              <Box className="top-left">
                <Typography className="total-services">
                  {productsData.length} {this.t(`${subCategoryName}`)}
                </Typography>
                <div className="line"></div>
                <Box className="search-wrapper">
                  <Input
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "rgb(142, 140, 140)" }} />
                      </InputAdornment>
                    }
                    onChange={this.handleSearchInputChange}
                    type="search"
                    value={this.state.searchValue}
                    placeholder={this.t(`${configJSON.searchTypeTxt}`)}
                    data-test-id="search-input-text"
                  />
                </Box>
              </Box>
              <Box className="top-right">
                <FormControl
                  component="fieldset"
                  className="oneTimeFormControl"
                >
                  <RadioGroup
                    aria-label="paymentType"
                    name="paymentType"
                    className="oneTimeFormControl"
                    data-test-id="handleCheckboxChange"
                    value={this.state.filterValue}
                    onChange={this.handleRadioChange}
                  >
                    <FormControlLabel
                      value="One Time"
                      control={
                        <Radio
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label={this.t(`${configJSON.oneTimeTxt}`)}
                    />
                    <FormControlLabel
                      value="Subscription"
                      control={
                        <Radio
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label={this.t(`${configJSON.subscriptionTxt}`)}
                    />
                  </RadioGroup>
                </FormControl>
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  data-test-id="clear-btn"
                  onClick={this.clearFilter}
                >
                  {this.t(`Clear`)}
                </Button>
                <div className="line"></div>
                <FormControl className="select-control sortby-control">
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                    }}
                    onChange={this.handleSortByChange}
                    displayEmpty
                    defaultValue={this.t(`${configJSON.sortByTxt}`)}
                    inputProps={{ IconComponent: () => null }}
                    renderValue={(value) => {
                      return (
                        <Box>
                          <img
                            className="select-icon"
                            src={sortVariant}
                            alt="sortVariant"
                          />
                          {this.t(`${value}`)}
                        </Box>
                      );
                    }}
                    data-test-id="sort-by-dropdown"
                  >
                    <MenuItem value="Featured">{this.t("Featured")}</MenuItem>
                    <MenuItem value="Newly Added">
                      {this.t("Newly Added")}
                    </MenuItem>
                    <MenuItem value="A-Z">{this.t("A to Z")}</MenuItem>
                    <MenuItem value="Z-A">{this.t("Z to A")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Grid container spacing={3} className="right-side-info">
                {productsData.length > 0 ? (
                  productsData.map((product: any) => (
                    <Grid key={product.id} item lg={4} md={4} sm={6} xs={12}>
                      <Box className="product-list-item">
                        <Box className="item-top">
                          <Box
                            className="image-content"
                            data-test-id={`viewNavigate-${product.id}`}
                            onClick={(event: any) =>
                              this.viewNavigate(event, product.id)
                            }
                          >
                            <img
                              src={
                                product.attributes.thumbnails !== null
                                  ? product.attributes.thumbnails[0].url 
                                  : defaultServiceImage
                              }
                              className="product-img"
                              alt="product-img"
                            ></img>
                          </Box>
                          <Box className="content-section">
                            <Typography className="price-txt">
                              {product.attributes.title}
                            </Typography>
                            <Typography className="description-txt">
                              {product.attributes.short_description}
                            </Typography>
                            <Box className="bottom-content">
                              <Typography className="price-txt">
                                {product.attributes.service_type ===
                                "Subscription" ? (
                                  <>
                                      <Typography className="price-txt">
                                        {this.state.selectedSubscriptions[product.attributes.id]?.price
                                          ? this.state.selectedSubscriptions[product.attributes.id].price
                                          : product.attributes.converted_price_weekly}
                                      </Typography>
                                      <Typography className="cart-price-dash-text">
                                        {product.attributes.converted_sale_price_weekly}
                                      </Typography>
                                      <Typography className="cart-weekly-text">
                                        {this.state.selectedSubscriptions[product.attributes.id]?.name
                                          ? this.state.selectedSubscriptions[product.attributes.id].name
                                          : "Weekly"}
                                      </Typography>
                                    <KeyboardArrowDown
                                      onClick={(e: any) =>
                                        this.handleOpenMenu(
                                          e,
                                          product.attributes.id
                                        )
                                      }
                                      data-test-id="handleOpenMenu"
                                      style={{
                                        color: "#94A3B8",
                                        width: "20px",
                                        height: "20px",
                                        cursor:"pointer"
                                      }}
                                    />
                                    <Popover
                                      open={Boolean(anchorElDropdown)}
                                      anchorEl={anchorElDropdown}
                                      onClose={this.handleCloseMenu}
                                      data-test-id="handleCloseMenu"
                                      anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      transformOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}                                     
                                      classes={{
                                        paper: `${classes.popoverStyle}`,
                                      }}
                                    >
                                      <Box>
                                        {subscriptionData.map((price: any) => (
                                          <MenuItem
                                            value={price.value}
                                            key={price.value}
                                            onClick={() => this.handleSubcriptionChange(price.value, price)}
                                            data-test-id="handleSubscription"
                                          >
                                            {price.price} {price.name}
                                          </MenuItem>
                                        ))}
                                      </Box>
                                    </Popover>
                                  </>
                                ) : (
                                  <>
                                    <Typography className="price-txt">
                                      {product.attributes.converted_price}
                                    </Typography>
                                    <Typography className="cart-price-dash-text">
                                      {product.attributes.converted_sale_price}
                                    </Typography>
                                  </>
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Box className="error-message">
                    <Typography>
                      {this.t(`${configJSON.noServicesFoundTxt}`)}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(etohShopStyle, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(CfEtohApi6)
);
// Customizable Area End
