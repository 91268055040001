import React from "react";
import { Box, FormControl, Select, MenuItem } from "@material-ui/core";

interface FilterDropDownProps {
  classes: any;
  title: string;
  options: any;
  value?:any;
  name?:any;
  onSelectValue: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const FilterDropDown = (props: FilterDropDownProps) => {
  const { classes, onSelectValue, title, options, value ,name } = props;
  const allowedNames = new Set(["filterBusinessCategories", "filterGroups"]);
  return (
    <Box className={"top-right"}>
      <FormControl className="select-control">
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            classes: { paper: classes.dropdownStyle },
          }}
          displayEmpty
          defaultValue=""
          value={value}
          name={name}
          onChange={onSelectValue}
          data-test-id={"dropdown-"}
        >
          <MenuItem value="">{title}</MenuItem>
          {options &&
            options.length > 0 &&
            options.map((option: any) => {
              return <MenuItem key={option.id} value={allowedNames.has(name) ? option.id : option.name}
              >{option.name}</MenuItem>;
            })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FilterDropDown;
