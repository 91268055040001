import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Popover,
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import {
  withStyles
} from "@material-ui/core/styles";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import { wineImage, trophyImg, trophyImg2, trophyImg3, certification1, certification2 } from "./assets";

import ViewItemController, {
  Props,
  configJSON
} from "./ViewItemController.web";
import { customStyles } from "../../../components/src/Inventory/ViewItemStyles.web";
import moment, { isDate } from "moment";
import WebLoader from "../../../components/src/WebLoader.web";
import GoogleMapComponent  from "../../../components/src/MapEmbed.web";
import { Link } from "react-router-dom";
import ContactListInventory from "../../../components/src/ChooseContactsInventory.web";
// Customizable Area End

export class ViewItem extends ViewItemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getToolsBasicInfo=()=>{
    let itemData = this.state.itemData.attributes;
    itemData = this.replaceNullsWithDash(itemData);
    return(
      <Accordion className={this.props.classes.accordionBlock} defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="budget-content"
        id="budget-header"
        className="accordion-heading"
      >
        <Typography className="title">
          {this.t(`${configJSON.itemInfoTitle}`)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="accordion-details">
        <Box className="infoDetailsBox">
          <Box className="infoDetailcol">
            <Typography className="featureText">
              {this.t(`${configJSON.brandTxt}`)}
            </Typography>
            <Typography className="featureValue">
            {itemData.brand.name}
            </Typography>
          </Box>
          <Box className="infoDetailcol">
            <Typography className="featureText">
            {this.t(`${configJSON.colorPlaceHolder}`)}
            </Typography>
            <Typography className="featureValue">
            {itemData.meta_data.color}
            </Typography>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
    )
  }

  toolsAdditionalInfo=()=>{
    let itemData = this.state.itemData.attributes;
    itemData = this.replaceNullsWithDash(itemData);
    let revdate = itemData.meta_data && itemData.meta_data.revision_date;
    
    if(revdate != configJSON.nullValueDisplay){
      let revisionDate = moment(revdate)
      if(revisionDate.isValid()){
        revdate = revisionDate.format("DD/MM/YYYY")
      }
    }
    return(
    <Accordion className={this.props.classes.accordionBlock} defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="budget-content"
        id="budget-header"
        className="accordion-heading"
      >
        <Typography className="title">
          {this.t(`${configJSON.additionalinfoTitle}`)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="accordion-details">
        {itemData.sub_category.static_id === configJSON.MachineryStaticId?
          <>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
              {this.t(`${configJSON.hoursPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {configJSON.dollarSymbol} {itemData.meta_data.hourly_cost} {configJSON.hourSymbol}
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
              {this.t(`${configJSON.serialText}`)}
              </Typography>
              <Typography className="featureValue">
              {itemData.meta_data.serial_no}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.revisionPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {revdate}
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
              {this.t(`${configJSON.descriptionPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
              {itemData.meta_data.description}
              </Typography>
            </Box>
          </Box>
          </>
          :
          <>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
              {this.t(`${configJSON.descriptionPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
              {itemData.meta_data.description}
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.brandTxt}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.brand.name}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
              {this.t(`${configJSON.colorPlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
              {itemData.meta_data.color}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
              {this.t(`${configJSON.serialText}`)}
              </Typography>
              <Typography className="featureValue">
              {itemData.meta_data.serial_no}
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.revisionPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {revdate}
              </Typography>
            </Box>
          </Box>
          </>
        }
      </AccordionDetails>
    </Accordion>
    )
  }
getMapComponent=(subCategoryStaticId:any)=>{
  let mapPlaceName = this.state.itemData.attributes.meta_data.google_maps_place;
  return(  
    subCategoryStaticId === configJSON.LandStaticId && this.state.mapLatLng!=null &&
    <Box className="content-item">
     <Typography className="table-heading">
      {this.t(`${configJSON.googleMapTxt}`)}
     </Typography>
     <Typography className="main-text">
     <GoogleMapComponent isView={true} latlng={this.state.mapLatLng} mapPlace={mapPlaceName} />
     </Typography>
   </Box>
  )
}

  getAdditionalContent=()=>{
    let itemData = this.state.itemData.attributes;
    itemData = this.replaceNullsWithDash(itemData);
    let date = itemData.meta_data?.expiration_date;
    let subCategoryStaticId = itemData.sub_category.static_id;
    
    if(date != configJSON.nullValueDisplay){
      let expDate = moment(date)
      if(expDate.isValid()){
        date = expDate.format("DD/MM/YYYY")
      }
    }
    
    let cost = itemData.meta_data?.cost;
    if(cost!==configJSON.nullValueDisplay){
      cost = "$"+itemData.meta_data?.cost+" per "+itemData.meta_data?.quantity_unit;
    }
    return(
    <Accordion className="accordion-block" defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="budget-content"
        id="budget-header"
        className="accordion-heading"
      >
        <Box className="heading">{this.t(`${configJSON.additionalinfoTitle}`)}</Box>
      </AccordionSummary>
      <AccordionDetails className="accordion-details">
        <Box className="content-main">
          <Box className="content-grid">
            {[configJSON.CheimcalStaticId,configJSON.PhytoProductStaticId].includes(subCategoryStaticId) &&
            <Box className="content-item">
            <Typography className="table-heading">
              {this.t(`${configJSON.riskTxt}`)}
            </Typography>
            <Typography className="main-text">
              {itemData.meta_data?.risk}
            </Typography>
          </Box>
            }
            {subCategoryStaticId === configJSON.FertilizerStaticId && 
            <>
            <Box className="content-item three-col">
              <Typography className="table-heading">                
                {this.t(`${configJSON.nContentTxtSmall}`)}
              </Typography>
              <Typography className="main-text">{itemData.meta_data?.n_content}</Typography>
            </Box>
            <Box className="content-item three-col">
              <Typography className="table-heading">
              {this.t(`${configJSON.pContentTxtSmall}`)}
              </Typography>
              <Typography className="main-text">{itemData.meta_data?.p_content}</Typography>
            </Box>
            <Box className="content-item three-col">
              <Typography className="table-heading">
              {this.t(`${configJSON.kContentTxtSmall}`)}
              </Typography>
              <Typography className="main-text">{itemData.meta_data?.k_content}</Typography>
            </Box>
            </>
            }
          {this.getMapComponent(subCategoryStaticId)}
            <Box className="content-item">
              <Typography className="table-heading">
                {this.t(`${configJSON.descriptionPlaceholder}`)}
              </Typography>
              <Typography className="main-text">
              {itemData?.description}
              </Typography>
            </Box>
           {subCategoryStaticId !== configJSON.LandStaticId && 
           <>
            <Box className="content-item three-col">
              <Typography className="table-heading">
              {this.t(`${configJSON.brandTextSmall}`)}
              </Typography>
              <Typography className="main-text">{itemData.brand?.name ?? configJSON.nullValueDisplay}</Typography>
            </Box>

            {subCategoryStaticId === configJSON.VesselStaticId && 
              <> 
              <Box className="content-item three-col">
                  <Typography className="table-heading">
                  {this.t(`${configJSON.materialTxtSmall}`)}
                  </Typography>
                  <Typography className="main-text">{itemData.meta_data?.material ?? configJSON.nullValueDisplay}</Typography>
              </Box>
              <Box className="content-item three-col">
                <Typography className="table-heading">
                {this.t(`${configJSON.specificationsTxtSmall}`)}
                </Typography>
                <Typography className="main-text">{itemData.meta_data?.specification ?? configJSON.nullValueDisplay}</Typography>
              </Box>
              <Box className="content-item three-col">
                <Typography className="table-heading">
                {this.t(`${configJSON.serialNumberTxtSmall}`)}
                </Typography>
                <Typography className="main-text">{itemData.meta_data?.serial_no}</Typography>
              </Box>
              </>
            }

            {subCategoryStaticId === configJSON.CheimcalStaticId && <Box className="content-item three-col">
              <Typography className="table-heading">                
                {this.t(`${configJSON.colorTxtSmall}`)}
              </Typography>
              <Typography className="main-text">{itemData.meta_data?.color ?? configJSON.nullValueDisplay}</Typography>
            </Box>}
            {subCategoryStaticId !== configJSON.VesselStaticId && <Box className="content-item three-col">
              <Typography className="table-heading">
                {this.t(`${configJSON.lotNumberTxtSmall}`)}
              </Typography>
              <Typography className="main-text">{itemData.meta_data?.lot_no}</Typography>
            </Box>}
            <Box className="content-item three-col">
              <Typography className="table-heading">
                {this.t(`${configJSON.expirationDateTxt}`)}
              </Typography>
              <Typography className="main-text">{date}</Typography>
            </Box>
            {subCategoryStaticId !== configJSON.VesselStaticId  && <Box className="content-item three-col">
              <Typography className="table-heading">                
                {this.t(`${configJSON.costQuantityTxt}`)}
              </Typography>
              <Typography className="main-text">{cost}</Typography>
            </Box>}
            </>
          }
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
    )
  }
  getBasicContent=(itemData:any)=>{
    
    let volume = itemData?.meta_data.volume
    if(volume!==configJSON.nullValueDisplay){
      volume += ' ' + itemData.meta_data.unit_of_measurement;
    }
    let subCategoryStaticId = itemData.sub_category.static_id;
    return(
      <Accordion className="accordion-block" defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="budget-content"
          id="budget-header"
          className="accordion-heading"
        >
          <Box className="heading">{this.t(`${configJSON.itemInfoTitle}`)}</Box>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <Box className="content-main">
            <Box className="content-grid">
              {subCategoryStaticId === configJSON.LandStaticId ?
              <Box className="content-item two-col">
              <Typography className="table-heading">
                {this.t(`${configJSON.totalAreaTxtSmall}`)}
              </Typography>
              <Typography className="main-text">
                {itemData?.meta_data.area +itemData.meta_data.unit_of_measurement}
                
              </Typography>
            </Box>
              :<>
              {subCategoryStaticId === configJSON.VesselStaticId  ? 
                <Box className="content-item two-col"><Typography className="table-heading">                  
                  {this.t(`${configJSON.valumeTxtSmall}`)}
                </Typography>
                <Typography className="main-text">
                  {volume}
                </Typography>
                </Box>
                :
                <Box className="content-item two-col">
                  <Typography className="table-heading">
                    {this.t(`${configJSON.maxDosageTxtt}`)}
                  </Typography>
                  <Typography className="main-text">
                    {itemData?.meta_data.max_dosage ? itemData?.meta_data.max_dosage + ' ' + itemData.meta_data.quantity_unit + "/" +itemData.meta_data.area_unit: configJSON.nullValueDisplay}
                    
                  </Typography>
                </Box>
              }
              </> }
              {[configJSON.CheimcalStaticId,configJSON.PhytoProductStaticId].includes(subCategoryStaticId) && <Box className="content-item two-col">
                <Typography className="table-heading">
                {this.t(`${configJSON.targetTxt}`)}
                </Typography>
                <Typography className="main-text">
                  {itemData.target?.name ?? configJSON.nullValueDisplay}
                </Typography>
              </Box>}
            </Box>
          </Box>
        </AccordionDetails>
    </Accordion>
    )
  }

  getProductBasicInfo = (itemData:any) => {
    const subCategoryStaticId = itemData.sub_category.static_id;
    switch(subCategoryStaticId){
      case configJSON.WineStaticId:
        return this.getWineBasicInfo(itemData);
      case configJSON.BeerStaticId:
        return this.getBeerBasicInfo(itemData);
      case configJSON.SpritStaticId:
        return this.getSpiritBasicInfo(itemData);
      case configJSON.OtherProductStaticId:
        return this.getOtherBasicInfo(itemData);
    }

  }

  getWineBasicInfo = (itemData:any) => {
    const { classes } = this.props;
    return (
      <Accordion className={classes.accordionBlock} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="budget-content"
          id="budget-header"
          className="accordion-heading"
        >
          <Typography className="title">{this.t(`${configJSON.itemInfoTitle}`)}</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">{this.t(`${configJSON.procedureTxt}`)}</Typography>
              <Typography className="featureValue">
                {itemData.meta_data.producer}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.brandTxt}`)}
              </Typography>
              <Typography className="featureValue">{itemData.meta_data.brand}</Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">{this.t(`${configJSON.quantityPlaceHolder}`)}</Typography>
              <Typography className="featureValue">{itemData.meta_data.quantity}</Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.varietyPlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
                {this.t(`${configJSON.wineVarity}`)}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.varietyperPlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.grape_variety_percent}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.complementPlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.name_complement}
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.colorPlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.color}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.ageAndVintage}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.age}/{itemData.meta_data.vintage}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.dosagePlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.dosage}
              </Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    )
  }

  getBeerBasicInfo = (itemData:any) => {
    const { classes } = this.props;
    return (
      <Accordion className={classes.accordionBlock} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
          aria-controls="budget-content"
          id="budget-header"
          className="accordion-heading"
        >
          <Typography className="title">{this.t(`${configJSON.itemInfoTitle}`)}</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.procedureTxt}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.producer}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.rawMaterialPlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.raw_material}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.brandTxt}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.brand}
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.rawMaterialPerPlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.raw_material_percent}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.complementPlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.name_complement}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">{this.t(`${configJSON.colorPlaceHolder}`)}</Typography>
              <Typography className="featureValue">{itemData.meta_data.color}</Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography 
              className="featureText">
                {this.t(`${configJSON.ageAndVintage}`)}
              </Typography>
              <Typography 
              className="featureValue">
                  {itemData.meta_data.age}/{itemData.meta_data.vintage}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText"
              >
                {this.t(`${configJSON.dosagePlaceHolder}`)}
              </Typography>
              <Typography className="featureValue"
              >
                {itemData.meta_data.dosage}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.quantityPlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.quantity}
              </Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    )
  }

  getSpiritBasicInfo = (itemData:any) => {
    const { classes } = this.props;
    return (
      <Accordion className={classes.accordionBlock} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
          aria-controls="budget-content"
          id="budget-header" className="accordion-heading" 
        >
          <Typography className="title">
            {this.t(`${configJSON.itemInfoTitle}`)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.procedureTxt}`)}
              </Typography>
              <Typography className="featureValue">{itemData.meta_data.producer}</Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.brandTxt}`)}</Typography>
              <Typography className="featureValue">
                {itemData.meta_data.brand}</Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.quantityPlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.quantity}
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.categoryText}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.category}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.styleText}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.style}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.complementPlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">{itemData.meta_data.name_complement} </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.rawMaterialPlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.raw_material}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">{this.t(`${configJSON.rawMaterialPerPlaceHolder} `)}</Typography>
              <Typography className="featureValue">{itemData.meta_data.raw_material_percent} </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.colorPlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.color}
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.ageAndVintage}`)}
              </Typography>
              <Typography className="featureValue">  {itemData.meta_data.age}/{itemData.meta_data.vintage}</Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.dosagePlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.dosage}
              </Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    )
  }

  getOtherBasicInfo = (itemData:any) => {
    const { classes } = this.props;
    return (
      <Accordion className={classes.accordionBlock} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
          aria-controls="budget-content"
          className="accordion-heading"
          id="budget-header"
        >
          <Typography 
          className="title">
            {this.t(`${configJSON.itemInfoTitle}`)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.procedureTxt}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.producer}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.brandTxt}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.brand}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.complementPlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.name_complement}
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.rawMaterialPlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.raw_material}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.rawMaterialPerPlaceHolder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.raw_material_percent}
              </Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    )
  }

  getLocationInfo = (itemData:any) => {
    const { classes } = this.props;
    return (itemData.sub_category.static_id == configJSON.BeerStaticId ? 
      <Accordion className={classes.accordionBlock} defaultExpanded>
        <AccordionSummary className="accordion-heading"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="budget-content"
          id="budget-header">
          <Typography 
          className="title">
            {this.t(`${configJSON.locationTitle}`)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.styleText}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.location.style}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.countryLabel}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.location.country}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.regionLabel}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.location.region}
              </Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    :
      <Accordion className={classes.accordionBlock} defaultExpanded>
      <AccordionSummary className="accordion-heading"
        expandIcon={<ExpandMoreIcon />}
        aria-controls="budget-content"
        id="budget-header"
      >
        <Typography 
        className="title">
          {this.t(`${configJSON.locationTitle}`)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="accordion-details">
        <Box className="infoDetailsBox">
          <Box className="infoDetailcol">
            <Typography className="featureText">
              {this.t(`${configJSON.appellationTypeLabel}`)}
            </Typography>
            <Typography className="featureValue">
              {itemData.location.appellation_type}
            </Typography>
          </Box>
          <Box className="infoDetailcol">
            <Typography className="featureText">
              {this.t(`${configJSON.appellationLabel}`)}
            </Typography>
            <Typography className="featureValue">
              {itemData.location.appellation}
            </Typography>
          </Box>
          <Box className="infoDetailcol">
            <Typography className="featureText">
              {this.t(`${configJSON.countryLabel}`)}
            </Typography>
            <Typography className="featureValue">
              {itemData.location.country}
            </Typography>
          </Box>
        </Box>
        <Box className="infoDetailsBox">
          <Box className="infoDetailcol">
            <Typography className="featureText">
              {this.t(`${configJSON.regionLabel}`)}
            </Typography>
            <Typography className="featureValue">
              {itemData.location.region}
            </Typography>
          </Box>
          <Box className="infoDetailcol">
            <Typography className="featureText">
              {this.t(`${configJSON.subRegionLabel}`)}
            </Typography>
            <Typography className="featureValue">
              {itemData.location.sub_region}
            </Typography>
          </Box>
        </Box>
      </AccordionDetails>
      </Accordion>
    )
  }

  getTrophyDetails = () => {
    const trophyDetails = this.state.itemData.attributes.trophies[0];
    return (
    <Box className="trophy-details-row">
            <Box className="trophy-img">
             {this.state.currentTrophy.image && <img src={this.state.currentTrophy.image || trophyDetails.image } alt="trophy-image" />}  
            </Box>
            <Box className="trophy-details">
              <Box className="infoDetailsBox" >
              <Box className="infoDetailcol col-full">
                <Typography className="featureValue">
                  {this.state.currentTrophy.name || trophyDetails?.name}
                </Typography>
                <Typography className="featureDesc">
                {this.state.currentTrophy.comment  || trophyDetails?.comment}
                </Typography>
              </Box>
              </Box>
              <Box className="infoDetailsBox" >
              <Box className="infoDetailcol">
                <Typography className="featureText">
                  {this.t(`${configJSON.trophyYearPlacehoder}`)}
                </Typography>
                <Typography className="featureValue">
                {this.state.currentTrophy.year  || trophyDetails?.year}
                </Typography>
              </Box>
              <Box className="infoDetailcol">
                <Typography className="featureText">
                  {this.t(`${configJSON.resultLabel}`)}
                </Typography>
                <Typography className="featureValue">
                {this.state.currentTrophy.result || trophyDetails?.result}
                </Typography>
              </Box>
            </Box>
          </Box>                                              
    </Box>
    )
  }

  getProductDetails = (itemData:any) => {
    const { classes } = this.props;
    return (
      <Accordion className={classes.accordionBlock} defaultExpanded>
        <AccordionSummary id="budget-header"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="budget-content"
          className="accordion-heading">
          <Typography className="title">{this.t(`${configJSON.detailsTitle}`)}</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol col-full">
              <Typography className="featureText">{this.t(`${configJSON.descPlaceholder}`)}</Typography>
              <Typography className="featureValue">
              {itemData.meta_data.short_description}
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.agingPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.aging_potential}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.alcoholPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.alcohol}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.priceTaxTitle}`)}
              </Typography>
              <Typography className="featureValue">
              {itemData.price}
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.volumePlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.container_with_volume}
              </Typography>
            </Box>
          </Box>
        <Box className="infoDetailsBox">
            <Box className="infoDetailcol col-sm2">
              <Typography className="featureText">
                {this.t(`${configJSON.prodProcessPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.production_process}
              </Typography>
            </Box>
            <Box className="infoDetailcol col-sm2">
              <Typography className="featureText">
                {this.t(`${configJSON.agingProcessPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.aging_process}
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol col-full">
              <Typography className="featureText">
                {this.t(`${configJSON.certificationPlaceholder}`)}
              </Typography>
              {itemData.certificates==configJSON.nullValueDisplay ?
                  <Typography className="featureValue">
                  {configJSON.emptyValue}
                </Typography>
                :
                <Box className="certification-lists">                 
                  {itemData.certificates.map((item:any)=>
                    <Box className="certification-icon">                           
                        <img src={item.image} alt="Image" key={item.id} className="certificateImg"/>
                    </Box>)}
                </Box>
              }            
              
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol col-sm2">
              <Typography className="featureText">
                {this.t(`${configJSON.skuPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.sku}
              </Typography>
            </Box>
            <Box className="infoDetailcol col-sm2">
              <Typography className="featureText">
                {this.t(`${configJSON.GtinPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.gtin}
              </Typography>
            </Box>
          </Box>
          <Typography className={classes.trophyTitle}>
            {this.t(`${configJSON.trophyModalTitle}`)}
          </Typography>
          {this.getTrophyDetails()}
          <Box className="trophy-list-wrapper">
          {itemData.trophies.map((trophy:any)=>{
            const isSelected = this.state.currentTrophy.id == trophy.id
            return (
              <Box className="trophy-list-col" onClick={()=>this.changeCurrentTrophy(trophy)}>
                <Box className={`trophy-list-inner ${isSelected ? "active":""}`}>
                  <Box className="trophy-img">
                    <img src={trophy.image} alt="tropgy-img" /> 
                  </Box>
                </Box>
              </Box>
            )
          })}
          </Box>
          {/* <Box className="trophy-list-wrapper">
            <Box className="trophy-list-col">
              <Box className="trophy-list-inner active">
                <Box className="trophy-img">
                  <img src={trophyImg} alt="tropgy-img" /> 
                </Box>
              </Box>
            </Box>
            <Box className="trophy-list-col">
              <Box className="trophy-list-inner">
                <Box className="trophy-img">
                  <img src={trophyImg2} alt="tropgy-img2" /> 
                </Box>
              </Box>
            </Box>
            <Box className="trophy-list-col">
              <Box className="trophy-list-inner">
                <Box className="trophy-img">
                  <img src={trophyImg3} alt="tropgy-img3" /> 
                </Box>
              </Box>
            </Box>
          </Box> */}
          {itemData.sub_category.static_id === configJSON.OtherProductStaticId && 
            <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.commentPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.comment}
              </Typography>
            </Box>
          </Box>
          }
          {itemData.sub_category.static_id === configJSON.BeerStaticId && 
          <>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {configJSON.ibuPlaceholder}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.ibu}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.srmPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.srm}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.ogPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.og}
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.fgPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.fg}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.ebcPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.ebc}
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.hopPleaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.hop}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.mashingPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.mashing}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.strengthPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.strength}
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.fermentationPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.fermentation}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.ingredientPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.meta_data.ingredients}
              </Typography>
            </Box>
          </Box>
          </>
          }
          {/* Allergens */}
          {((itemData.sub_category.static_id === configJSON.SpritStaticId) || (itemData.sub_category.static_id === configJSON.WineStaticId)) && 
            <Box className="infoDetailsBox">
              <Box className="infoDetailcol col-full">
                <Typography className="featureText">
                  {this.t(`${configJSON.allergensTitle}`)}
                </Typography>
                <Typography className="featureValue">
                {itemData.meta_data.allergens}
                </Typography>
              </Box>
            </Box>
          }
        </AccordionDetails>
      </Accordion>
    )
  }

  getProductNotes = (itemData:any) => {
    const { classes } = this.props;
    return (
      itemData.sub_category.static_id === configJSON.OtherProductStaticId ? null :
      <Accordion className={classes.accordionBlock} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="budget-content"
          id="budget-header"
          className="accordion-heading"
        >
          <Typography className="title">
            {this.t(`${configJSON.tastingTitle}`)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.openingTimePlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.tasting_notes.opening_time}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.tempTitle}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.tasting_notes.temperature==configJSON.nullValueDisplay ? configJSON.nullValueDisplay: itemData.tasting_notes.temperature  + "°C"} 
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol col-full">
              <Typography className="featureText">
                {this.t(`${configJSON.commentPlaceholder}`)}
              </Typography>
              <Typography className="featureValue">
              {itemData.tasting_notes.comment}
              </Typography>
            </Box>
          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.eyeLabel}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.tasting_notes.eye}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.noseLabel}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.tasting_notes.nose}
              </Typography>
            </Box>
            {itemData.sub_category.static_id === configJSON.BeerStaticId ? <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.flavoursLabel}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.tasting_notes.flavours}
              </Typography>
            </Box>: <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.aromaLabel}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.tasting_notes.main_aromas}
              </Typography>
            </Box>}

          </Box>
          <Box className="infoDetailsBox">
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.mouthLabel}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.tasting_notes.mouth}
              </Typography>
            </Box>
            <Box className="infoDetailcol">
              <Typography className="featureText">
                {this.t(`${configJSON.foodLabel}`)}
              </Typography>
              <Typography className="featureValue">
                {itemData.tasting_notes.food_pairing}
              </Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    )
  }

  getProductAllergens = (itemData:any) => {
    const { classes } = this.props;
    return (
      ((itemData.sub_category.static_id === configJSON.BeerStaticId) || (itemData.sub_category.static_id === configJSON.OtherProductStaticId)) && 
        <Accordion className={classes.accordionBlock}>
          <AccordionSummary className="accordion-heading"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="budget-content"
            id="budget-header"
          >
            <Typography className="title">{configJSON.allergensTitle}</Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <Box className="infoDetailsBox">
              <Box className="infoDetailcol">
                <Typography className="featureText">
                  {this.t(`${configJSON.allergensTitle}`)}
                </Typography>
                <Typography className="featureValue">
                {itemData.meta_data.allergens}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
    )
  }

  getContentByCategory = (categoryStaticId:any,itemData:any) => {
    switch (categoryStaticId) {
      case configJSON.AssetsStaticId:
      case configJSON.ConsumablesStaticId:
        return (<>
        {this.getBasicContent(itemData)}
        {this.getAdditionalContent()}
        </>)
      case configJSON.ProductsStaticId:
        return (
          <>
            {this.getProductBasicInfo(itemData)}
            {/* Location */}
            {this.getLocationInfo(itemData)}
            {/* Details */}
            {this.getProductDetails(itemData)}
            {/* Tasting Notes */}
            {this.getProductNotes(itemData)}
            {/* Allergens */}
            {this.getProductAllergens(itemData)}
        </>)
      case configJSON.ToolsStaticId:
        return (
          <>
            {/* basic info */}
            {itemData.sub_category.static_id === configJSON.MachineryStaticId && this.getToolsBasicInfo()}
            {/* Additional info */}
            {this.toolsAdditionalInfo()}
          </>
        )
    }
  }

  getItemContent = () => {
    const { classes } = this.props;
    let itemData = this.state.itemData.attributes;
    itemData = this.replaceNullsWithDash(itemData);
    let categoryStaticId = itemData.category.static_id;
    return (
      <Box className={classes.chemicalProductOuter}>
        <Box className="chemical-product-main">
          <Box className="chemical-image-block">
            <Box className="image-wrapper">
              {itemData.images!==configJSON.nullValueDisplay &&
              itemData.images.map((item:any)=>
                <img src={item.url} alt="Image" key={item.id} />
              ) 
              }                  
            </Box>
          </Box>
          <Box className="chemical-content-block">
            <Typography className={classes.heading} variant="h1">
              {itemData.name}
            </Typography>
            {categoryStaticId == configJSON.ProductsStaticId && 
              <Typography className={classes.priceTitle}>
              {configJSON.dollarSymbol}{itemData?.prices[0]?.amount}
            </Typography>
            }
            <Box className={classes.stockBox}>
              <Typography className="stockText">
                {this.t(`${configJSON.stockText}`)}
              </Typography>
              <div className="valueBox MuiBox-root" ref={this.popoverRef}>
                <Typography
                  className="valueText"
                  data-testId={"stock-text"}
                >
                  {itemData?.stock_qty == configJSON.nullValueDisplay ? "0" : itemData?.stock_qty} {configJSON.availableText}
                </Typography>
                <EditOutlinedIcon
                  className="editIcon"
                  data-testId={"edit-stock"}
                  onClick={this.handlePopover}
                />
              </div>
            </Box>
            {categoryStaticId == configJSON.ProductsStaticId &&
            <>
            <Box className={classes.buttonBox}>
              <Button data-test-id="add-fav-btn" onClick={this.handleIsFavContact} className={`${classes.primaryButton} primary-btn`}>{this.t(`${configJSON.addFavButton}`)}</Button>
              <Button data-test-id="add-contact-btn" className={`${classes.secondaryButton} secondary-btn`} onClick={this.openSendToContactModal}>{this.t(`${configJSON.sendButton}`)}</Button>
              <Button 
               data-test-id="add-btn"
                onClick={()=>{
                  this.addProductsAsFavourites();
                  this.handleSelectedLists(this.state.itemData.id);
                  this.favouriteProductCreatedOpen();
                  this.filterActivityHandler();
                 }} style={{display:"none"}}></Button>
             </Box>
            {this.state.isfavContact && 
                <ContactListInventory
                classes={classes}
                contactLists={this.state.contactLists}
                modalOpen={this.state.isfavContact}
                modalClose={this.handleIsFavContactClose}
                openFilterModal={this.filterActivityHandler}
                openFavouriteModal={this.addProductsAsFavourites}
                selectedAction={this.handleSelectedLists}
                selectedContacts={[this.state.itemData]}
                isFavouriteProductCreated={this.state.isFavouriteProductCreated}
                favouriteProductModalClose={this.favouriteProductModalClose}
                backToInventory={this.backToInventory}
                t={this.t}
                data-test-id="choose-contact-modal"
              />
            }
            </>
            }
            
            {this.getContentByCategory(categoryStaticId,itemData)}                
          </Box>
        </Box>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes} = this.props;
    const popOverOpen = Boolean(this.state.anchorEl);
    const popoverId = popOverOpen ? "simple-popover" : undefined;
    return (  
      this.state.isLoading ? <WebLoader /> : 
      <Box>
        <Box className={`${classes.headerSection} `}>
          <Typography
            className="backStyle"
            onClick={this.backToInventory}
            data-testId={"back-section"}
          >
            <KeyboardBackspaceIcon className="backIcon" />
            {this.t(`${configJSON.backButton}`)}
          </Typography>
          <Button className="editBtn"  data-test-id="edit-section" onClick={this.editClick}>{configJSON.editText}</Button>
        </Box>
        {this.getItemContent()}
      
        
        <Popover
          id={popoverId}
          open={popOverOpen}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopoverClose}
            anchorOrigin={{
              vertical: 23,
              horizontal: -8,
            }}
          classes={{ paper: `${classes.stockPopover}` }}
          data-testId={"stock-popover"}
        >
          <Box className={classes.popupBody}>
            <Box className="stock-feild">
              <CustomInputWeb
                type="text"
                value={this.state.updateStock}
                onChange={this.handleStock}
                placeholder={this.t(`${configJSON.startTypingStockTxt}`)}
                data-testId={"stockInput"}
                customClass="stock-qty"
                refTest={this.inputRef}
              />
            </Box>
            <Box className="button-box">
            <Link
                to="#"
                className="add-link secondary-link"
                onClick={this.handlePopoverClose}
                data-testId={"stock-cancel"}
              >
                {this.t(`${configJSON.cancelButton}`)}
            </Link>
            <Link
                to="#"
                className="add-link"
                onClick={this.updateStock}
                data-testId={"update-btn"}
              >
                {this.t(`${configJSON.updateButton}`)}
              </Link>
            </Box>
          </Box>
        </Popover>
      </Box>
      )
    }
    // Customizable Area End
}

// Customizable Area Start


export default withStyles(customStyles, { withTheme: true })(ViewItem);
// Customizable Area End