Object.defineProperty(exports, "__esModule", {
    value: true,
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "bx_block_login/logins";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.lableNewPassword = "New Password";
exports.lableConfirmPassword = "Confirm Password";
exports.labelHeader = "Log in";
exports.headerCreatePass = "Create a new password";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "Don't have an account?";
exports.labelOr = "Or";
exports.labelTitle = "Log in";
exports.labelSubHeader = "We found your email address in our system";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.forgotPasswordApiEndpoint =
  "bx_block_forgot_password/passwords/forgot_password";
exports.resetPasswordApiEndpoint =
  "bx_block_forgot_password/passwords/reset_password";
exports.emailValidateEndpoint = "account_block/email_validate";
exports.createPasswordEndpoint = "account_block/create_password_role";
exports.emailChangeEndPoint = "account_block/confirm_updated_email";
exports.createPasswordAPiMethod = "POST";
//Web
exports.webLoginText = "Log in";
exports.webWelcomeText = "Welcome!";
exports.webSubtext1 = "Continue with Google or enter your details";
exports.webSubtext2 = "We found your email address in our system";
exports.webLabelEmail = "Email";
exports.webLabelPassword = "Password";
exports.webLinkForgotPassword = "Forgot Password?";
exports.webBtnContinue = "Continue";
exports.webBtnSignUp = "Sign up";
exports.webBtnLogin = "Login";
exports.webBtnContinueWithGoogle = "Continue with Google";
exports.webTxtOr = "or";
exports.webEmailRequiredMsg = "Email should not be blank";
exports.webPwdRequiredMsg = "Password should not be blank";
exports.webInvalidEmail = "Invalid email";

exports.title1 = "Your whole business in";
exports.subTitle1 = 'your pocket!"';
exports.title2 = "The Treasure Hunt";
exports.title3 = "The Treasure Hunt";
exports.subtitle1 =
  "A food processor, or even one of those small bowls that fit on a stick blender, is a real treasure. No, that's not an overstatement.";

exports.baseURL = require("../../../framework/src/config");
exports.createPasswordEndPoint = "create_password_role";
exports.getMethodPost = "POST";

exports.firebaseApiKey = 'AIzaSyDw29xYbhEGX8MIDl7uKCZDgIYTLjSzTEE';
exports.firebaseAuthDomain = 'etoh-suite.firebaseapp.com';
exports.firebaseProjectId = 'etoh-suite';
exports.firebaseStorageBucket = 'etoh-suite.appspot.com';
exports.firebaseMessagingSenderId = '214090409206';
exports.firebaseAppId = '1:214090409206:web:52e7708af8f79f848a78d7';
exports.firebaseMeasurementId = 'G-R88VKJ87EF';

exports.vApiKey = "BNfUfI3pPrO5BzDqlsbNUiLTFJUdNml5GCmM5y4Yp_mX-DTM7p3TKSP3f1_0-YTZAA1AilaYkaTvt5L8bVrhBmk";
exports.emailConfirmationTxt="Email Confirmation";
exports.emailVarificationFailMsg= "Your email could not be verified. Please try again";
exports.emailVarificationSuccessMsg= "The email has been succesfully verified.";
exports.copyRightTxt="Copyright";
exports.downloadAppTxt="Download App";
exports.createNewPasswordTxt="Create Password";
exports.enterPasswordConfirmTxt="Enter a new password and confirm it";
exports.confirmPassRequired="Confirm Password is requried";
exports.notMeetRequirements = "Password does not meet requirements";
exports.invalidToken = "Invalid token";
exports.somethingWentWrong="Something went wrong";
// Customizable Area End
