// Customizable Area Start
import React from "react";
import { Box, Button, CardMedia, Typography } from "@material-ui/core";
import { imgBackArrow } from "../assets";

interface MobileStepIndicatorProps {
  classes: any;
  step: string;
}

const MobileStepIndicator = (props: MobileStepIndicatorProps) => {
  const { classes, step } = props;

  return (
    <Box className={classes.mobileStepIndicator}>
      <Button className="btn-top-back">
        <CardMedia
          component="img"
          style={{
            width: "20px",
            height: "20px",
            objectFit: "contain",
          }}
          image={imgBackArrow}
        />
      </Button>
      <Box className="step-indicator">
        <Box className="step-indicator-title">
          <Typography className="text-take-equiz">Take E-Quiz</Typography>
        </Box>
        <Typography className="step-name">{`${step}/4`}</Typography>
      </Box>
    </Box>
  );
};

export default MobileStepIndicator;
// Customizable Area End
