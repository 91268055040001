import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Link,
  Button,
} from '@material-ui/core';
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import {
  EtOHLogo,
  appIcon1,
  appIcon2,
  languageIcon,
  mvpImg1,
  mvpImg2,
  mvpImg3,
  onBoardBackground,
} from "./assets";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ModalWeb from "../../../components/src/Modal.web";

// Customizable Area End

import OnboardingguideController, {
  Props,
  configJSON
} from "./OnboardingguideController.web";


 // Customizable Area Start
export class Onboardingguide extends OnboardingguideController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  
  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.mainContainer}>
        <Box style={webStyle.logoBox}>
        <img src={EtOHLogo} alt="logo" style={webStyle.logoImg}/>
        </Box>
        <Box className={classes.tutorialBox}>
          <Box>
            <Carousel showArrows={false} showStatus={false} showThumbs={false}
            className={classes.carosel}
            onChange={(event)=> this.handleSlider(event)}
            selectedItem={this.state.slideNum}
            data-testId={'tutorialCarousel'}
            >
              <Box>
                <img src={mvpImg1} alt="tutorial img"  style={webStyle.sliderImg} />
                <Typography style={webStyle.sliderTitle}>
                    {configJSON.sliderTitle1}
                  </Typography>
                  <Typography style={webStyle.descriptionText}>
                  {configJSON.sliderSubTitle1}
                  </Typography>
                  <Box style={webStyle.nextBtnBox}> 
                  <Button 
                  style={webStyle.nextBtn}
                  onClick={this.nextSlide}
                  >
                   {configJSON.nextBtnText}
                  </Button>
                  </Box>
              </Box>
              <Box>
                <img src={mvpImg2} alt="tutorial img" style={webStyle.sliderImg} />
                <Typography style={webStyle.sliderTitle}>
                    {configJSON.sliderTitle2}
                  </Typography>
                  <Typography style={webStyle.descriptionText}>
                  {configJSON.sliderSubTitle2}
                  </Typography>
                <Box style={webStyle.btnBox}>
                <Button 
                  style={webStyle.prevBtn}
                  onClick={this.prevSlide}
                  data-testId={"prevStepBtn"}
                  >
                    {configJSON.prevBtnText}
                  </Button>
                  <Button 
                  style={webStyle.nextBtn}
                  onClick={this.nextSlide}
                  data-testId={"nextStepBtn"}
                  >
                    {configJSON.nextBtnText}
                  </Button>
                </Box>   
              </Box>
              <Box>
                <img src={mvpImg3} alt="tutorial img"  style={webStyle.sliderImg} />
                <Typography style={webStyle.sliderTitle}>
                  {configJSON.sliderTitle3}
                  </Typography>
                  <Typography style={webStyle.descriptionText}>
                  {configJSON.sliderSubTitle3}
                  </Typography>
                <Box style={webStyle.btnBox}>
                <Button 
                  style={webStyle.prevBtn}
                  onClick={this.prevSlide}
                  >
                    {configJSON.prevBtnText}
                  </Button>
                  <Button 
                  style={webStyle.nextBtn}
                  onClick={this.nextSlide}
                  >
                    {configJSON.nextBtnText}
                  </Button>
                </Box>
              </Box>
            </Carousel>
          </Box>
        </Box>
        <Box style={webStyle.footerContainer}>
          <Box style={webStyle.footerBox}>
              <Typography style={webStyle.copyrightText}>
                {configJSON.copyright} {" "} 
                <Box component='span'>
                  <a style={webStyle.pagelink} href={configJSON.EtOHSiteUrl} target="_blank">{configJSON.EtOHSite}</a>
                </Box>
              </Typography>
              {this.state.isDowloadModal && 
              <ModalWeb modalOpen={this.state.isDowloadModal} 
                modalClose={this.handleModal} modalType={configJSON.modalTypeText} 
                modalHeading={configJSON.downloadModalHeading}/>
              }
              <Box>
                <Box style={webStyle.downloadAppBox}> 
                  <Box style={webStyle.downloadBox} onClick={this.handleModal} data-testId="downloadPopup">
                    <Typography style={webStyle.downloadText}>{configJSON.downloadText}</Typography>
                    <Link href='#' className='app-link'>
                        <img src={appIcon1} alt='app-icon' style={webStyle.iconImg}/>
                    </Link> 
                    <Link href='#' className='app-link'>
                        <img src={appIcon2} alt='app-icon' style={webStyle.iconImg}/>
                    </Link> 
                  </Box>
                  
                  <Box component='span' style={webStyle.translateImgBox}>
                    <img src={languageIcon} alt='translate' style={webStyle.translateImg} />
                  </Box>
                  <LanguageFeild>
                      <Select
                          defaultValue={10}
                      >
                          <MenuItem value={10}>{configJSON.language1}</MenuItem>
                          <MenuItem value={20}>{configJSON.language2}</MenuItem>
                      </Select>
                  </LanguageFeild>
                </Box>
              </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}
// Customizable Area End

// Customizable Area Start
export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    mainContainer: {
      width:'100%', 
      height:'200vh',
      backgroundImage: `url("${onBoardBackground}")`, 
      backgroundSize:'cover',
      [theme.breakpoints.down("sm")]:{
        height:'100vh',
      }, 
    },
    carosel:{
      "& .dot.selected":{
      backgroundColor: 'black',
      width: '0.8vw',
      height: '0.8vw',
      },
      '& .dot':{
        width: '0.2vw',
        height: '0.2vw',
      }
    },
    tutorialBox:{
      width:'57.5vw',
      borderRadius: '24px',
      backgroundColor:'#fff',
      zIndex: 1,
      padding: '2vw',
      position: 'relative' as 'relative',
      left: '21.2vw',
      [theme.breakpoints.between("md", "lg")]:{
        width:'50vw',
        left: '25vw',
        padding: '1.5vw',
      },
    },
  });
const webStyle = {
  logoBox:{
    padding:'4.2vw',
  },
  logoImg:{
    width:'9.2vw', 
    height:"1.9vw",
  },
  sliderImg:{
    width:'53.3vw',
    height:'500px',
  },
  sliderTitle:{
    marginTop:'2vw',
    fontFamily: 'Expletus Sans',
    color:'#2B2B2B', 
    fontSize:'1.2vw', 
    textAlign:'center' as 'center',
  },
  descriptionText:{
    fontFamily: 'Expletus Sans',
    color:'#2B2B2B', 
    fontSize:'0.7vw',
    fontWeight:500,
    textAlign:'center' as 'center', 
    marginBottom:'52px'
  },
  nextBtnBox:{
    display:'flex', 
    justifyContent:'end'
  },
  btnBox:{
    display:'flex', 
    alignItems:'center' as 'center', 
    justifyContent:'space-between'
  },
  downloadAppBox:{
    display:'flex', 
    alignItems:'center' as 'center', 
    justifyContent:'space-between',
    padding:'2vw 3vw'
  },
  footerContainer:{
    position:'relative' as 'relative', 
    bottom: '-3vw',
    width:"100%",
  },
  footerBox:{
    display:'flex',
    justifyContent: 'space-between',
    alignItems:'center', 
  },
  copyrightText:{
    color:'#fff', 
    fontSize:'0.7vw', 
    lineHeight:'0.8vw', 
    padding:'2vw 3vw'
  },
  pagelink:{
    color:'#fff', 
    textDecoration:"none",
  },
  downloadBox:{
    display:'flex',
    alignItem:'center'as 'center',
    justifyContent:'space-between',
  },
  downloadText:{
    color:'#fff', 
    fontSize:'0.7vw', 
    lineHeight:'0.8vw', 
    marginRight:'.2vw'
  },
  translateImgBox:{
    marginRight:'5px',
  },
  iconImg:{
    width:'0.8vw', 
    height:'0.8vw',
    marginRight: '.5rem'
  },
  translateImg:{
    width:'0.8vw', 
    height:'0.7vw',
  },
  nextBtn:{
    fontFamily: 'Expletus Sans',
    fontSize:'0.8vw',
    backgroundColor:'#4BA3B7',
    color:'#fff',
    textTransform:'capitalize' as 'capitalize',
    borderRadius:'6px',
  },
  prevBtn:{
    fontFamily: 'Expletus Sans',
    fontSize:'0.8vw',
    backgroundColor:'#fff',
    color:'#4BA3B7',
    textTransform:'capitalize' as 'capitalize',
    border:'1px solid #ECF1F4',
    borderRadius:'6px',
  }
};

const LanguageFeild = withStyles({
  root: {
    '& .MuiSelect-select' :{
      fontSize: '0.7vw',
      letterSpacing: '0.0075em',
      color: '#fff',
      fontWeight: '400',
      fontFamily: "Roboto",
      paddingBottom: '0',
      paddingTop: '0',
  },
  '& .MuiInput-underline:before': {
      display: 'none',
  },
  '& .MuiInput-underline:after': {
      display: 'none',
  },
  '& .MuiSelect-icon':{
    color:'#fff',
  },
  },
})(FormControl);
export default withStyles(customStyles, { withTheme: true })(Onboardingguide);
// Customizable Area End
