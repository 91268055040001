Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.methodTypeGET = "GET";
exports.methodTypePOST = "POST";
exports.methodTypePATCH = "PATCH";
exports.methodTypeDELETE= "DELETE";
exports.methodTypePUT= "PUT";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LeadManagement";
exports.labelBodyText = "LeadManagement Body";
exports.taskListView ="/account_block/tasklist_view";
exports.dealOwnersEndpoint = "/account_block/member_list";
exports.viewDealsEndpoint ="/bx_block_contactslist/deals?sort_by=DATE_CREATED";
exports.createDealsEndpoint = "/bx_block_contactslist/deals?";
exports.viewSingleDeal = "bx_block_contactslist/show_deal?";
exports.duplicateDeal = "/bx_block_contactslist/duplicate_deal?id=";
exports.updateDealStatus = "/bx_block_contactslist/change_status?";
exports.createDealEndPoint = "/bx_block_contactslist/deals?";
exports.deleteDealsEndPoint = "/bx_block_contactslist/delete_deal?id="
exports.viewContactsPath = "bx_block_contactslist/contacts"
exports.editDealEndPoint = "/bx_block_contactslist/edit_deal?id=";
exports.searchDealEndPoint = "/bx_block_contactslist/search_sort_filter_deal?";
exports.productsEndPoint = "/bx_block_contactslist/product_list";
exports.viewDealActivity  ="/account_block/activitylogs_deal?deal_id="
exports.dealsActivityLogFilter="/account_block/log_filter";
exports.downloadActivityLogCsv="/account_block/download_activitylogs.csv";

exports.btnExampleTitle = "CLICK ME";
exports.stageTxt="Stage";
exports.amountTxt="Amount";
exports.dealOwnerTxt="Deal Owner";
exports.contactTxt="Contact";
exports.contactTxtNew="Contact(s)";
exports.expectedDateCloseTxt="Expected Close Date";
exports.descriptionTxt="Description";
exports.associatedProductTxt="Associated Products";
exports.activityLogsTxt="Activity Log";
exports.activityLogSubTxt="Currently shows 30 days logs.";
exports.filterTxt="Filters";
exports.aductActivityTxt ="Adjust activity log filters";
exports.dateRangeTxt="Date Range";
exports.txtChooseDate = "Choose Date"
exports.attacmentsTxt="Attachments ({{count}})";
exports.tasksTxt="Tasks";
exports.taskTxt="Task";
exports.taskListsTxt="Tasklists";
exports.noTaskListTxt="No Tasklists";
exports.noTaskSubTxt="No Tasklists have been created here";
exports.craeteTasklistTxt="Create Tasklists";
exports.sortByTxt="Sort by";
exports.dealsTxt="Deals";
exports.dealsTxtSingle="Deal";
exports.searchTxt="Start typing to search...";
exports.closeByDateTxt="Close by Date";
exports.moreTxt="More";
exports.nameTxt="Name";
exports.dealAmountTxt="Deal Amount";
exports.attachmentTxt="Attachment";
exports.actionsTxt="Actions";
exports.editTxt="Edit";
exports.duplicateDealTxt="Duplicate Deal";
exports.createTaskTxt="Create Task";
exports.deleteDealTxt="Delete Deal";
exports.noDealCreatedTxt="No deal created";
exports.createDealMsgTxt="Please create a deal to see here.";
exports.newDealTxt="New Deal";
exports.createDealTxt="Create Deal";
exports.createDealSubTxt="Enter all of the details of this deal.";
exports.dealNameTxt="Deal Name";
exports.dealNameHEreTxt="Deal name here";
exports.changeStageTxt="Change Stage";
exports.cancelTxt="Cancel";
exports.updateTxt="Update";
exports.chooseDealSubTxt="Choose deal owner you want to add to this deal.";
exports.selectedOwnerTxt="Selected Owner";
exports.unassignedTxt="Unassigned";
exports.membersTxt="Members";
exports.membersSingleTxt="Member";
exports.chooseContactTxt="Choose Contact";
exports.chooseContactSubTxt="Choose contacts you want to add to this deal.";
exports.createContactTxt="Create Contact";
exports.searchContactsTxt="Search Contacts";
exports.selectedContactsTxt="Selected Contacts";
exports.removeAllContactsTxt="Remove all Contacts";
exports.dealTxt="deal";
exports.editDealTxt="Edit Deal";
exports.editDealSubTxt="Update the details of this deal";
exports.noteTxt="Note";
exports.updateDealTxt="Update Deal";
exports.attachmentHeadingTxt="Attachments";
exports.clickHereToAttachTxt="Click here to";
exports.browseTxt="browse";
exports.yourFilesTxt="your files";
exports.deleteMsgTxt="Are you sure you want to delete this";
exports.deleteTxt="Delete";
exports.totalAmmount="Total Amount";
exports.associatedProductsTxt="Associated Product(s)";
exports.associatedHeading = "Associated Products";
exports.associatedSubHeading = "Please choose products associated with this deal.";
exports.associatedFilters = "Filters";
exports.associatedClear = "Clear all";
exports.associatedProductsTxt = "Products";
exports.associatedProductsTxtSingle = "Product";
exports.associatedItemTxt = "Items";
exports.associatedItemsTxtSingle = "Item";
exports.associatedSelectedTxt = "Selected";
exports.associatedCardSubTxt = "The following items will be added the email.";
exports.addToDealstxt = "Add to Deals";
exports.fileAttachmentTxt = "Attachment";
exports.contactTxt = "Contacts";
exports.contactTxtSingle = "Contact";
exports.producerTxt = "Producer";
exports.hasTasks = "Has a Tasks"; 
exports.hasAttachment = "Has an Attachment"; 
exports.hasAssociatedProduct = "Has an Associated Product";
exports.onBeforeCloseDate= "On or before";
exports.onAfterCloseDate= "On or after";
exports.selectCloseDate= "Select Date";                                              
// Customizable Area End