export const screenshot = require("../assets/Screenshot.png");
export const close = require("../assets/close.svg");
export const sortby = require("../assets/tune-vertical.svg");
export const attach = require("../assets/attach.png");
export const bgImage = require("../assets/bg-image.png");
export const pencil = require("../assets/Shape.svg");
export const uploadImg = require("../assets/file-upload.png");
export const frame = require("../assets/rafiki.png");
export const warningIcon = require("../assets/warning-red.png");
export const success = require("../assets/Group 7794.png");
export const bitmap1 = require("../assets/Bitmap.png");
export const bitmap2 = require("../assets/Bitmap (1).png");
export const bitmap3 = require("../assets/Bitmap (3).png");
export const ellipse = require("../assets/Ellipse.png");
export const mobile = require("../assets/Group 7676.png");
export const googlePlay = require("../assets/google-play 2.png");
export const appStore = require("../assets/app-store 1.png");
export const download = require("../assets/download.png");
export const downloadPdfImg = require("../assets/Vector.png");
export const lineActive = require("../assets/Rectangle.png");
export const lineInActive = require("../assets/RectangleInactive.png");