// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputAdornment,
  Button,
  Popover
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import { keyboardBack } from "../assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { configJSON } from "../ContactsListController.web";
import CreateGroup from "./CreateGroup.web";

interface GroupContactsProps {
  classes: any;
  modalOpen: boolean;
  modalClose: () => void;
  searchGroup: string;
  handleSearchGroup: (text: string) => void;
  groups?:any;
  addToGroup?:() => void;
  contsctDetails?:any;
  type?:any;
  t:any;
  openGroup:(event:any)=>void;
  closeGroup:()=>void;
  openGroupState:boolean;
}
const AddGroupToContact = (props: GroupContactsProps) => {
  const { t, classes, modalOpen, modalClose, searchGroup, handleSearchGroup ,groups, addToGroup,contsctDetails,type,openGroup,closeGroup,openGroupState} =
  props;
 
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [sortBy, setSortBy] = useState("");
    const handleToggleSelection = (itemId:any) => {
      localStorage.setItem("addGroupId", itemId);
      setSelectedItemId(itemId === selectedItemId ? null : itemId);
    };

    useEffect(() => {
      const contactGroups = type==="viewGroup"?contsctDetails?.attributes?.contact_groups:contsctDetails?.contact_groups;     
      const filterGroups = (groups:any) => {
        return groups?.filter((group:any) => {
          const groupId = group.id.toString();
          return !contactGroups?.some((contactGroup:any) => contactGroup?.id?.toString() === groupId);
        });
      };      
      const filteredGroups = filterGroups(groups);
      setData(filteredGroups)
      },[groups,contsctDetails]);

      const name = `${contsctDetails?.first_name}` + " " +`${contsctDetails?.last_name}`;
      const name2 = `${contsctDetails?.attributes?.first_name}` + " " +`${contsctDetails?.attributes?.last_name}`;
   
      useEffect(() => {
        const filtered = data?.filter((item:any) =>
          item.attributes.name.toLowerCase().includes(searchGroup.toLowerCase())
        );
        setFilteredData(filtered);
      }, [searchGroup, data]);

      const handleSortByChange = (event: any) => {
        const selectedValue = event.target.value as string;
        setSortBy(selectedValue);
        let sortedData = [...filteredData];
        if (selectedValue === "1") {
          sortedData.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name));
        } else if (selectedValue === "2") {
          sortedData.sort((a, b) => b.attributes.name.localeCompare(a.attributes.name));
        }
        setFilteredData(sortedData);
      };
  return (
    <Modal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalBoxCenter}
    >
      <Box className={classes.contactGroupsModal}>
        <Box className="modal-heading">
          <Box className="gr-heading-left" onClick={modalClose}>
            <img src={keyboardBack} alt="keyboardback"/>
            <Typography className="nav-text">{type==="viewGroup"?name2:name}</Typography>
          </Box>
          <Box className="heading-right">
            <Link to="#" className="heading-icon" onClick={openGroup}>
              <AddIcon className="plus-icon" />
            </Link>            
              <Popover
                open={openGroupState}
                onClose={closeGroup}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                classes={{ paper: `${classes.createContactBox}` }}
              >
                <CreateGroup
                classes={classes}
                handleCloseDialog={closeGroup}
                data-test-id="createGroup"
                navigation={undefined} 
                id="add-to-group"                 
                 />
              </Popover>
            <Link to="#" className="heading-icon" onClick={modalClose}>
              <CloseIcon className="close-icon" />
            </Link>
          </Box>
        </Box>
        <Box className="modal-title-box">
          <Typography className="title-txt">{t(`${configJSON.chooseGroup}`)}</Typography>
          <Typography className="sub-title-txt">
           {t(`${configJSON.chooseGroupSubTextNew}`)}
          </Typography>
        </Box>
        <Box className="search-box add-gr-to-contact-search">
          <CustomInputWeb
            type="select"
            label=""
            value={searchGroup}
            placeholder={t(`${configJSON.searchTxt}`)}
            onChange={(event: any) => handleSearchGroup(event.target.value)}
            errors={""}
            rows={4}
            endAdornment={
              <InputAdornment position="end" style={{color:"#94A3B8"}}>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Box>
        <Box className="assign-contact">
            <Box className="top-list">
              <Typography className="count-contact">{data?.length} {data?.length>1?t(`${configJSON.groupsTxt}`):t(`${configJSON.group}`)}</Typography>
              <FormControl className="select-control">
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  displayEmpty
                  defaultValue=""              
                  data-test-id="sortbyfield"    
                  value={sortBy}
                  onChange={handleSortByChange}        
                  IconComponent={() => (
                    <ExpandMoreIcon  fontSize="small" style={{color:"rgb(144 149 157)"}} />
                  )}
                >
                  <MenuItem value="">{t(`${configJSON.sortByTxt}`)}</MenuItem>
                  <MenuItem value="1">{t(`${configJSON.aToZTxt}`)}</MenuItem>
                  <MenuItem value="2">{t(`${configJSON.zToATxt}`)}</MenuItem>
                </Select>
              </FormControl>
            </Box> 
            <div className="line"></div>
          <Box className="un-assign-contact">
            {filteredData?.map((item:any) =>{
               return <Box className="un-assign-list" style={{paddingBottom:"0"}} key={item.id}>
                <Box className="select-contact-item">
                <Box className="contact-info">
                  <Typography className="name">{item.attributes.name}</Typography>  
                  <Typography className="email">
                  {item.attributes.contacts.length} {item.attributes.contacts.length>1?t(`${configJSON.contacts}`):t(`${configJSON.contact}`)}
                  </Typography>                
                 </Box>
                 <Box className="checkedCirlce" data-test-id="handleToggleSelection" onClick={() => handleToggleSelection(item.id)}>
                  {selectedItemId === item.id ? (
                    <CheckCircleIcon className="checkedCircle" />
                  ) : (
                    <RadioButtonUnchecked className="circleCheck" style={{ color: "#E8ECF2",}}/>
                  )}
                </Box>
                
                </Box>
                </Box>              
                })}
            <Button className={!selectedItemId ?`bottom-button-disabled ${classes.primaryButton}`:`bottom-button ${classes.primaryButton}`}  disabled={!selectedItemId} onClick={addToGroup}>{t(`${configJSON.addToGroupTxt}`)}</Button>
          </Box>
        </Box>
      </Box>
    </Modal>    
  );
};

export default AddGroupToContact;
// Customizable Area End
