Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LanguageSupport";
exports.labelBodyText = "LanguageSupport Body";

exports.btnExampleTitle = "CLICK ME";
exports.langHeading = "Settings";
exports.langBack = "Back";
exports.currentLangtxt = "Current Language";
exports.translation = "bx_block_language_options/locales";
exports.methodGET = "GET";
exports.updateTranslations="account_block/accounts/update_preferred_locale"
exports.methodPATCH = "PATCH";
exports.backTxt="Back";
exports.updateTxt="Update";
// Customizable Area End