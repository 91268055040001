// Customizable Area Start
import React from "react";
import { Box, Typography, Button, InputAdornment } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme
} from "@material-ui/core/styles";
import OwnerOnboardingController, {
  Props, configJSON
} from "./OwnerOnboardingController.web";
import AuthLeft from "../../../components/src/AuthLeft.web";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import Footer from "../../../components/src/Footer.web";
import AppHeader from "../../../components/src/AppHeader.web";
import FreeSoloCreateOption from "../../../components/src/FreeSoloCreateOption.web";
import ConfirmActionModal from "./teams-web/ConfirmActionModal.web";

export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    overrides: {
      MuiButton: {
        primaryButton: {}
      }
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "6px",
      // filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "16px",
      lineHeight: "21px",
      color: "#f2f8fc",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      width: "100%",
      padding: "12px 10px",
      minHeight: "56px",
      marginBottom: "30px",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",

      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff"
      }
    },
    authWrapper: {
      textAlign: "left",
      "& img": {
        maxWidth: "100%"
      },
      "& .auth-inner-block": {
        flexWrap: "wrap",
        display: "flex",
        "& .auth-right": {
          display: "flex",
          flex: "1",
          padding: "64px 63px 142px",
          flexDirection: "column",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            padding: "64px 63px 83px"
          },
          [theme.breakpoints.down("sm")]: {
            padding: "60px 60px 139px"
          },
          [theme.breakpoints.down("xs")]: {
            padding: "24px 24px 99px"
          },
          "& .right-inner-content": {
            margin: "auto",
            width: "100%",
            maxWidth: "512px"
          },
          "& .right-inner": {
            flexDirection: "column",
            display: "flex",
            height: "100%"
          }
        }
      },
      "& .heading": {
        fontSize: "24px",
        lineHeight: "30px",
        margin: "0 0 12px",
        color: "rgba(0,0,0,0.87)",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        margin: "0 0 55px",
        color: "#505050",
        fontFamily: "Roboto",
        letterSpacing: "0.25px",
        fontWeight: 400,
        [theme.breakpoints.down("xs")]: {
          margin: "0 0 31px"
        }
      },
      "& .page-link": {
        textAlign: "right",
        marginBottom: "16px"
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#505050",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        "& .form-link": {
          marginLeft: "10px"
        }
      }
    },
    wrapper: {
      minHeight: "100vh",
      paddingBottom: "142px",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "105px"
      }
    },
    authMainWrapper: {
      "& .wrapper": {
        paddingBottom: "0"
      },
      "& .header-wrapper": {
        display: "none",
        [theme.breakpoints.down("sm")]: {
          display: "flex"
        }
      },
      "& .footer-wrapper": {
        marginLeft: "576px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "384px"
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "0"
        }
      }
    },
    innerWrapper: {
      margin: "auto",
      width: "100%"
    }
  });

class OwnerOnboardingBlock extends OwnerOnboardingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
        <Box className={`${classes.authMainWrapper}`}>
          <Box className={`wrapper ${classes.wrapper}`}>
            <AppHeader />
            <Box className={classes.innerWrapper}>
              <Box className={classes.authWrapper}>
                <Box className="auth-inner-block">
                  <AuthLeft />
                  <Box className="auth-right">
                    <Box className="right-inner">
                      <Box className="right-inner-content">
                        <Typography className="heading" variant="h1">
                          {this.t(`${this.labelHeader}`)}
                        </Typography>
                        <Box component="p" className="sub-txt">
                          {this.t(`${this.ownerLabelHeaderHelperText}`)}
                        </Box>
                        <Box component="form">
                          <FreeSoloCreateOption
                            options={this.state.teams.map(val => {
                              return {
                                id: val.id,
                                title: val.attributes.title
                              };
                            })}
                            addEditCallback={this.addEditTeam}
                            deleteCallback={this.deleteTeam}
                            addLinkTitle={this.t(`${this.ownerCreateTeamNameText}`)}
                            noOptionTitle={this.t(`${this.ownerTeamNameHyperText}`)}
                            label={this.t(`${this.ownerTeamNameText}`)}
                            id="team-id"
                            onChange={this.teamChangeHandler}
                            value={this.state.team}
                            errors={this.state.error.teamError.message}
                            errorMsgNoRecord={this.ownerTeamNameErrorText}
                          />
                          {this.state.isDeletingTeam && (
                            <ConfirmActionModal
                              isOpen={this.state.isDeletingTeam}
                              handleClose={this.handleClose}
                              modalConfirmAction={this.deleteTeamFromList}
                              deleteTeamId={this.state.deleteTeamId}
                              modalMessage={this.t(`${configJSON.modalTeamConfirmation}`)}
                              confirmBtnTxt={this.t(`${configJSON.labelDeleteModalBtnTxt}`)}
                              modalHeading={this.t(`${configJSON.deleteTeamTxt}`)}
                              data-testid="confirmDeleteTeam"
                            />
                          )}

                          <FreeSoloCreateOption
                            options={this.state.jobTitles.map(val => {
                              return {
                                id: val.id,
                                title: val.attributes.title
                              };
                            })}
                            addEditCallback={this.addJobTitle}
                            addLinkTitle={this.t(`${this.ownerJobTitleHelperText}`)}
                            noOptionTitle={this.t(`${configJSON.noJobTitleFound}`)}
                            label={this.ownerJobTitleText}
                            id="job-id"
                            onChange={this.titleChangeHandler}
                            value={this.state.jobTitle}
                            errors={this.state.error.jobTitleError.message}
                            deleteCallback={this.deleteJobTitle}
                            errorMsgNoRecord={this.t(`${configJSON.noJobTitleCreated}`)}
                          />
                          {this.state.isDeleting && (
                            <ConfirmActionModal
                              isOpen={this.state.isDeleting}
                              handleClose={this.handleClose}
                              modalConfirmAction={this.deleteJobTitleFromList}
                              deleteTeamId={this.state.deleteJobTitleId}
                              modalMessage={this.t(`${configJSON.deleteJobTitleConfirmationTxt}`)}
                              confirmBtnTxt={this.t(`${configJSON.labelDeleteModalBtnTxt}`)}
                              modalHeading={this.t(`${configJSON.jobTitleDeletTxt}`)}
                              data-testid="confirmDeleteTitle"
                            />
                          )}

                          <CustomInputWeb
                            isRequired={true}
                            errors={this.state.error.hoursError.message}
                            label={this.t(`${this.ownerHourText}`)}
                            value={this.state.hours || ""}
                            inputID="txtInputHours"
                            type="number"
                            helperTextClass="info-text"
                            success={this.ownerHourHyperText}
                            onChange={event => {
                              const val = event.target.value;
                              if (val) {
                                this.setState({
                                  hours: isNaN(Number(val)) ? null : Number(val)
                                });
                              } else {
                                this.setState({ hours: null });
                              }
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            onKeyDown={e => {
                              if (["e", "E", "+", "-"].includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                          <Button
                            className={classes.primaryButton}
                            onClick={this.validateForm}
                            data-testid="ownerSubmit"
                          >
                            {this.t(`${this.ownerButtonText}`)}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Footer />
        </Box>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(
  OwnerOnboardingBlock
);
// Customizable Area End
