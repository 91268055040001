export const noInvetoryImg = require("../assets/amico.png");
export const checkedIcon = require("../assets/checkbox_outline_blank.png");
export const checkBoxIcon = require("../assets/ic_checkbox.png");
export const filterIcon = require("../assets/tune-vertical.png");
export const settingIcon = require("../assets/setting-icon.png");
export const exportIcon = require("../assets/export-icon.png");
export const filterImg = require("../assets/mobile-filter-img.png");
export const shortImg = require("../assets/mobile-short-img.png");
export const wineItem = require("../assets/wine-item.png");
export const beerItem = require("../assets/beer-item.png");
export const alcholItem = require("../assets/alchol-item.png");
export const ovalIcon = require("../assets/Oval.png");
export const starIcon = require("../assets/star.png");
export const detailsIcon = require("../assets/detailsIcon.png");
export const FileIcon = require("../assets/fileIcon.png");
export const calendarIcon = require("../assets/calendarIcon.png");
export const whiteWine = require("../assets/white.png");
export const redWine = require("../assets/red.png");
export const roseWine = require("../assets/rose.png");
export const fileIcon = require("../assets/paperclip.png");
export const tickIcon = require("../assets/green-tick.png");
export const category = require("../assets/category.png");
export const notes = require("../assets/notes.png");
export const allergens = require("../assets/allergens.png");