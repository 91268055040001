// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Modal,
  Button,
  List,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  beerImg,
  tractorImg,
  vesselImg,
  landImg,
  wineImg,
  nailImg,
  fuelImg,
  fuelIcon,
  testtubeImg,
  otherProducts,
  spiritImg,
} from "../../blocks/categoriessubcategories/src/assets";
import { configJSON } from "../../blocks/categoriessubcategories/src/CategoriesSubcategoriesController.web";
import CloseIcon from "@material-ui/icons/Close";
interface ExportInventoryModalProps {
  modalOpen: boolean;
  closeModal: () => void;
  t: any;
  modalTitle: string;
  modalDescription: string;
  downloadHandler: (categoryName:string) => void;
  downloadBtnText: string;
}

const useStyles = makeStyles((theme) => ({
  ModalDialog: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(57,57,57,0.502)",
    },
  },
  modalDialogBox: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: 680,
    backgroundColor: "#ffffff",
    padding: "80px 60px 50px",
    borderRadius: "0",
    overflowY: "auto",
    height: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
      width: 548,
      padding: "45px 60px",
    },
    [theme.breakpoints.down("sm")]: {
      width: 520,
      padding: "50px 40px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
      padding: "30px 24px",
    },
    "& .modal-heading": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "7px",
    },
    "& .modal-title": {
      fontWeight: 400,
      fontSize: "22px",
      lineHeight: "26px",
      fontFamily: "Expletus Sans",
      color: "rgba(43, 43, 43, 0.87)",
      [theme.breakpoints.down("xs")]: {
        fontSize: "22px",
        lineHeight: "26px",
        color: "#2b2b2b",
      },
    },
    "& .close-icon": {
      color: "#94A3B8",
      lineHeight: "0",
      cursor: "pointer",
    },
    "& .modal-description": {
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        color: "#94A3B8",
        fontWeight: 400,
        fontFamily: "Roboto",
        margin: "0 0 23px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px",
          lineHeight: "17px",
          color: "#94A3B8",
        },
      },
    },
    "& .categoriesInfo": {
      marginBottom: "24px",
      padding: "0",
      [theme.breakpoints.down("sm")]: {
        padding: "0",
        marginBottom: "16px",
      },
      "& .left-content": {
        display: "flex",
        alignContent: "center",
        "& .MuiListItemIcon-root": {
          minWidth: "20px",
        },
      },
      "& .subCategoryList": {
        padding: "0",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "29px",
        },
        "& .subCategoryBox": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #E8ECF2",
          padding: '18px 15px',
          borderRadius: '8px',
          marginBottom: '10px',
          [theme.breakpoints.down("xs")]: {
            padding: "23px 0 20px",
          },
          "& .subCategoryImg": {
            height: "16px",
          },
          "& .subCategoryText": {
            fontWeight: 500,
            fontSize: "14px",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Expletus Sans",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            marginLeft: "12px",
          },
        },
      },
      "& .categoryBox": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px 0 17px",
        [theme.breakpoints.down("xs")]: {
          padding: "9px 0 18px",
        },
        "& .categoryImg": {
          height: "20px",
        },
        "& .categoryText": {
          fontSize: "16px",
          color: "rgba(43, 43, 43, 0.87)",
          fontFamily: "Expletus Sans",
          lineHeight: "24px",
          fontWeight: 400,
          letterSpacing: "0.15px",
          marginLeft: "12px",
        },
      },
    },
    "& .export-download-btn": {
      fontSize: '14px',
      fontFamily: 'Expletus Sans',
      fontWeight:500,
      lineHeight: '18.75px',
      color:"#2B2B2B",
      textTransform: 'capitalize',
    },
  },
}));
//Allowed file_type inputs: ["wine", "beer", "chemical", "fertilizer", "hardware", "land", "machinery", "other_product", "phyto_product", "spirit", "vessel"]

const allInventoryCategories = [
  {
    category: configJSON.wineText,
    image: wineImg,
    key_name: "wine",
  },
  {
    category: configJSON.beerText,
    image: beerImg,
    key_name: "beer",
  },
  {
    category: configJSON.spiritText,
    image: spiritImg,
    key_name: "spirit",
  },
  {
    category: configJSON.otherText,
    image: otherProducts,
    key_name: "other_product",
  },
  {
    category: configJSON.machineText,
    image: tractorImg,
    key_name: "machinery",
  },
  {
    category: configJSON.hardwareText,
    image: nailImg,
    key_name: "hardware",
  },
  {
    category: configJSON.landText,
    image: landImg,
    key_name: "land",
  },
  {
    category: configJSON.vesselsText,
    image: vesselImg,
    key_name: "vessel",
  },
  {
    category: configJSON.FertilizersText,
    image: fuelIcon,
    key_name: "fertilizer",
  },
  {
    category: configJSON.PhytoProductsText,
    image: fuelImg,
    key_name: "phyto_product",
  },
  {
    category: configJSON.ChemicalsText,
    image: testtubeImg,
    key_name: "chemical",
  },
];
const ExportInventoryModal = (props: ExportInventoryModalProps) => {
  const { modalOpen, closeModal, t, modalTitle, modalDescription, downloadHandler, downloadBtnText } = props;
  const classes = useStyles();
  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.ModalDialog}
      data-testId="categoryModal"
    >
      <Box className={classes.modalDialogBox}>
        <Box className="modal-heading">
          <Typography className="modal-title" component="h2">
            {t(`${modalTitle}`)}
          </Typography>
          <CloseIcon
            className="close-icon"
            onClick={closeModal}
            data-testId="closeIcon"
          />
        </Box>
        <Box className="modal-description">
          <Box component="p" className="sub-txt">
            {t(`${modalDescription}`)}
          </Box>
          <Box className="categoriesInfo">
            <List className="subCategoryList">
              {allInventoryCategories.map((category, index) => {
                return (
                  <ListItem className="subCategoryBox" key={index}>
                    <Box className="left-content">
                      <ListItemIcon>
                        <img
                          src={category.image}
                          alt="product img"
                          className="subCategoryImg"
                        />
                      </ListItemIcon>
                      <Typography className="subCategoryText">
                        {t(`${category.category}`)}
                      </Typography>
                    </Box>
                    <Button
                      data-test-id="categoryProduct"
                      name={downloadBtnText}
                      className="export-download-btn"
                      onClick={() => downloadHandler(category.key_name)}
                    >
                      {downloadBtnText}
                    </Button>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default ExportInventoryModal;
// Customizable Area End
