// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./ContactsListStyles.web";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { toast } from "react-toastify";
import { warningIcon } from "./assets";
import { Box, CardMedia } from "@material-ui/core";
import i18n from "../../../web/src/utilities/i18n";

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
  isShowEditContact: boolean;
  handleOpenEditContact: (id?:any) => void;
  hancleCloseEditContact: () => void;
  viewSingleDetails?: any;
}

interface S {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country1:string;
  country2:string;
  companyName:string;
  vatNumber:string;
  postalCode:string;
  postalCode2:string;
  city1:string;
  city2:string;
  state1:string;
  state2:string;
  address1:string;
  address2:string;
  address11:string,
  address12:string,
  checked:boolean;
  error: {
    firstName: string;
    lastName: string;
    newGroup:string;
    email:string;
    phone:string;
    errorbusinessCategory:string;
    vatNumber:string;
  };
  businessCategoryData:any;
  searchTerm:string;
  isSearching:boolean;
  selectedOptions:any;
  isNewGroup:boolean;
  searchedGroup:any;
  groups:any
  newGroup:string;
  countryList:any;
  cityList:any;
  stateList:any;
  businessCategorys: any[];
  businessCategory: any;
  deleteBCategoryId: any;
  isDeleting: boolean;
  deleteTitle:string;
}
interface SS {
  id: any;
}

export default class EditContactController extends BlockComponent<
  Props,
  S,
  SS
> {
  userSessionData: any;
  userToken: any;
  editContactApiId: string = "";
  getContactlistDataApiID :string = "";
  getBusinessCategoryID: string = "";
  getSearchGroupRequestId:string="";
  createGroupRequestID:string="";
  getGroupsApiId: string = "";
  countryListDataApiCallId:string="";
  cityListDataApiCallId:string="";
  stateListDataApiCallId: string = "";
  currentBusinessCategory: { id: number };
  showDialogCategory: any;
  setErrorCategory: any;
  updateBussinesCategoryRequestID: string = "";
  createBussinesCategoryRequestID: string = "";
  getBusinesCategoryRequestID: string = "";
  deleteBCategoryApiCallID: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess),getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      firstName: this.props.viewSingleDetails?.attributes?.first_name,
      lastName: this.props.viewSingleDetails?.attributes?.last_name,
      email: this.props?.viewSingleDetails?.attributes?.email,
      phone: this.props.viewSingleDetails?.attributes?.phone,
      country1:this.props.viewSingleDetails?.attributes?.billing_address?.data?.attributes?.country ,
      country2:this.props.viewSingleDetails?.attributes?.shipping_address?.data?.attributes?.country,
      companyName:this.props.viewSingleDetails?.attributes?.business_info?.data?.attributes?.company_name,
      vatNumber:this.props.viewSingleDetails?.attributes?.business_info?.data?.attributes?.VAT_number,
      postalCode:this.props.viewSingleDetails?.attributes?.billing_address?.data?.attributes?.pin_code,
      postalCode2:this.props.viewSingleDetails?.attributes?.shipping_address?.data?.attributes?.pin_code,
      city1:this.props.viewSingleDetails?.attributes?.billing_address?.data?.attributes?.city,
      city2:this.props.viewSingleDetails?.attributes?.shipping_address?.data?.attributes.city,
      state1:this.props.viewSingleDetails?.attributes?.billing_address?.data?.attributes?.state,
      state2:this.props.viewSingleDetails?.attributes?.shipping_address?.data?.attributes?.state,
      address1:this.props.viewSingleDetails?.attributes?.billing_address?.data?.attributes?.address1,
      address2:this.props.viewSingleDetails?.attributes?.billing_address?.data?.attributes?.address2,
      address11:this.props.viewSingleDetails?.attributes?.shipping_address?.data?.attributes?.address1,
      address12:this.props.viewSingleDetails?.attributes?.shipping_address?.data?.attributes?.address2,
      businessCategory:this.props.viewSingleDetails?.attributes?.business_info?.data?.attributes?.business_category_name || "",
      checked:false,
      error: {
        firstName: "",
        lastName: "",
        newGroup:"",
        email:"",
        phone:"",
        errorbusinessCategory:"",
        vatNumber:"",
      },
      businessCategoryData:[],
      searchTerm:"",
      isSearching:false,
      selectedOptions:[],
      newGroup:"",
      searchedGroup:[],
      groups:[],
      isNewGroup:false,
      countryList:[],
      cityList:[],
      stateList:[],
      businessCategorys: [],
      deleteBCategoryId: "",
      isDeleting: false,
      deleteTitle:"",
    };
    this.currentBusinessCategory = { id: 0 };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.checkBusinessCategoryStateEvent(prevState);
    this.clearError(prevState);
    if (prevState.newGroup !== this.state.newGroup) {
      this.setState({
        error: {
          ...this.state.error,
          newGroup: "",
        },
      });
    } 
    if (
      prevState.country1 !== this.state.country1 ||
      prevState.country2 !== this.state.country2 ||
      prevState.countryList !== this.state.countryList
    ) {
      this.setState({ cityList: [], stateList: [] });
      this.stateListAPICall();
    }
    if (
      prevState.state1 !== this.state.state1 ||
      prevState.state2 !== this.state.state2 ||
      prevState.stateList !== this.state.stateList
    ) {
      this.setState({ cityList: [] });
      this.cityListAPICall();
    } 
    
    if (
      prevState.state1 !== this.state.state1
    ) {
      this.setState({ city1: "" });
    } 
  }

  async componentDidMount() {
    this.getGroupListData();
    this.getBusinessCategoryData();
    const extractedIds = this.props.viewSingleDetails?.attributes?.contact_groups.map((item:any) => String(item?.id)).filter((id:any) => id !== 'undefined');
    this.setState({selectedOptions:extractedIds})
    const { billing_address, shipping_address } = this.props.viewSingleDetails.attributes;
    const isChecked = (
      billing_address.country === shipping_address.country &&
      billing_address.city === shipping_address.city &&
      billing_address.pin_code === shipping_address.pin_code &&
      billing_address.address1 === shipping_address.address1 &&
      billing_address.address2 === shipping_address.address2
    );
    
    this.setState({ checked: isChecked });
    this.countryListDataAPICall();
    const langC = localStorage.getItem("lang") ?? "en"; 
    await i18n.changeLanguage(langC); 
    this.fetchBCategoryListEvent();    
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
     let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
      switch (apiRequestCallId) {
        case this.getBusinessCategoryID:
          this.setState({ businessCategoryData: responseJson });
          break;
        case this.getGroupsApiId:
          this.setState({ groups: responseJson.data });
          break;
        case this.getSearchGroupRequestId:
          this.setState({ searchedGroup: responseJson.data });
          break;
        case this.countryListDataApiCallId:
          this.setState({ countryList: responseJson.data });
          break;
        case this.stateListDataApiCallId:
          this.setState({ stateList: responseJson.data });
          break;
        case this.cityListDataApiCallId:
          this.setState({ cityList: responseJson.data });
          break;
        case this.createGroupRequestID:
          this.createGroupResponseData(responseJson);
          break;
        case this.editContactApiId:
          this.editToastNotificationSuccess(this.t("Contact edited successfully!"));
          localStorage.setItem("isEdit", true.toString());
          this.props.hancleCloseEditContact();
          break;
        case this.getBusinesCategoryRequestID:
            this.setState({ businessCategorys: responseJson });   
            this.setState({
                businessCategory: this.getCurrentBCategory(responseJson),
              });   
            break;
        case this.deleteBCategoryApiCallID:
            if (
              this.state.businessCategory &&
              this.state.deleteBCategoryId == this.state.businessCategory.id
            ) {
              this.setState({ businessCategory: null });
            }
            this.setState({ deleteBCategoryId: "" });
            this.fetchBCategoryListEvent();
            this.handleCloseCategory();
            this.editToastNotificationSuccess(
              this.t(configJSON.deleteBCategoryTxtMsg)
            );
            break;
        case this.createBussinesCategoryRequestID:
            this.createCategorySuccess(responseJson);
            break;
        case this.updateBussinesCategoryRequestID:
            this.updateCategorySuccess(responseJson);
            break;
        default:
          if (responseJson.error) {
            this.errorToastNotification(this.t(responseJson.error[0]));
          }
          break;
      }      
    }
  }

  getCurrentBCategory = (businessCategorys: any) => {
    let businessCategory = this.state.businessCategory;
    
    if (businessCategory === null) {
      const defaultCategoryId = this.props.viewSingleDetails?.attributes?.business_info?.data?.attributes?.business_category_id;
      const defaultCategory = businessCategorys.find((category: any) => category.id === defaultCategoryId);
      
        if (defaultCategory) {
            businessCategory = {
                id: defaultCategory.id,
                title: defaultCategory.name
            };
        }
    }
    return businessCategory;
};

  handleCloseCategory = () => {
    this.setState({
      isDeleting: false,
    });
  };

  checkPrevStateBusinessCategoryEvent = (prevState: S) => {
    if (prevState.businessCategorys !== this.state.businessCategorys) {
      if (this.currentBusinessCategory.id !== 0) {
        const curTitle = this.state.businessCategorys.find(
          (x) => x.id === this.currentBusinessCategory.id
        );
        if (curTitle) {
          this.setState({
            businessCategory: {
              id: curTitle.id,
              title: curTitle.name,
            },
          });
        }
      }
      this.currentBusinessCategory = { id: 0 };
    }
  };

  checkBusinessCategoryStateEvent = (prevState: S) => {
    this.checkPrevStateBusinessCategoryEvent(prevState);
  };
  createCategorySuccess = (responseJson: any) => {
    const errorMsg = responseJson.name[0];
    if (errorMsg && !responseJson.id) {
      this.setErrorCategory(this.t(configJSON.errorMsgForBussinesCategory));
    } else {
      this.currentBusinessCategory.id = responseJson.id;
      this.showDialogCategory(false);
      this.fetchBCategoryListEvent();
    }
  };

  updateCategorySuccess = (responseJson: any) => {
    const errorMsg = responseJson.name[0];
    if (errorMsg && !responseJson.id) {
      this.setErrorCategory(this.t(configJSON.errorMsgForBussinesCategory));
    } else {
      this.currentBusinessCategory.id = responseJson.id;
      this.showDialogCategory(false);
      this.fetchBCategoryListEvent();
    }
  };

  editToastNotificationSuccess = (toastMesssage: string) => {
    toast.success(
      <Box className="toast-notification">
        <Box className="notification-txt">{toastMesssage}</Box>
      </Box>,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  errorToastNotification = (toastMesssage: string) => {
    toast.error(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
      icon: () => (
        <CardMedia component="img" src={warningIcon} alt="emptydata" />
      ),
    });
  };
 t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  handleInputChange = (event:any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  handleChecked = (event:any) => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  clearError = (prevState:S) => {
    let error = { ...this.state.error };    
  
    if (prevState.lastName !== this.state.lastName) {
      error.lastName = "";
    }
    
    if (prevState.firstName !== this.state.firstName) {
      error.firstName = "";
    }
  
    const emailEdit = this.state.email.trim();
    if (prevState.email !== emailEdit) {
      if (emailEdit === "") {
        error.email = "";
      } else if (!configJSON.emailRegex.test(emailEdit)) {
        error.email = this.t("Email should be valid");
      } else {
        error.email = "";
      }
    }
    const phoneEdit = this.state.phone.trim();
    if (prevState.phone !== phoneEdit) {
      if (phoneEdit === "") {
        error.phone = "";
      } else if (!configJSON.phoneRegex.test(phoneEdit)) {
        error.phone = this.t("Phone number should be valid");
      } else {
        error.phone = "";
      }
    }
    const vatNumberEdit = this.state.vatNumber.trim();
    if (prevState.vatNumber !== vatNumberEdit) {
      if (vatNumberEdit === "") {
        error.vatNumber = "";
      } else if (!configJSON.vatNumberRegex.test(vatNumberEdit)) {
        error.vatNumber = this.t(configJSON.vatNumberErrorMsg);
      } else {
        error.vatNumber = "";
      }
    }
    if (JSON.stringify(error) !== JSON.stringify(this.state.error)) {
      this.setState({ error: error });
    }
  };
   getGroupListData = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = configJSON.groups;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

editContact = (editId:any) => {
  let error = this.state.error;
  let hasError = false;
  if (this.state.firstName.trim() === "") {
    error.firstName = this.t("First name should not be blank");
    this.setState({ error: error });
    hasError = true;
  }
  if (this.state.lastName.trim() === "") {
    error.lastName = this.t("Last name should not be blank");
    this.setState({ error: error });
    hasError = true;
  }
  const emailEdit = this.state.email.trim();
  if (emailEdit === "") {
    this.setState({
      error: {
        ...this.state.error,
        email: "",
      },
    });
  } else if (!configJSON.emailRegex.test(emailEdit)) {
    error.email = this.t("Email should be valid");
    this.setState({
      error: error,
    });
    hasError = true;
  }   

  const phoneEdit = this.state.phone.trim();
  if (phoneEdit === "") {
    this.setState({
      error: {
        ...this.state.error,
        phone: "",
      },
    });
  } else if (!configJSON.phoneRegex.test(phoneEdit)) {     
    error.phone = this.t("Phone number should be valid");
    this.setState({
      error: error,
    });
    hasError = true;
  }
  const vatNo1 = this.state.vatNumber.trim();
  if (vatNo1 === "") {
    this.setState({
      error: {
        ...this.state.error,
        vatNumber: "",
      },
    });
  } else if (!configJSON.vatNumberRegex.test(vatNo1)) {
    error.vatNumber = this.t(configJSON.vatNumberErrorMsg);
    this.setState({
      error: error,
    });
    hasError = true;
  }
  if (hasError) {
    return;
  }
  let endPoint = `${configJSON.contactsPath}/${editId}`;

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const body = {
      contact: {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        business_info: {
          company_name: this.state.companyName,
          business_category_id: this.state.businessCategory.id, 
          VAT_number: this.state.vatNumber
        },
        billing_address: {
          country: this.state.country1,
          city: this.state.city1,
          state: this.state.state1,
          pin_code: this.state.postalCode,
          address1: this.state.address1,
          address2: this.state.address2,
        },
        shipping_address: {
          country: this.state.checked===true?this.state.country1:this.state.country2,
          city:  this.state.checked===true?this.state.city1:this.state.city2,
          state:  this.state.checked===true?this.state.state1:this.state.state2,
          pin_code: this.state.checked===true?this.state.postalCode:this.state.postalCode2,
          address1: this.state.checked===true?this.state.address1:this.state.address11,
          address2: this.state.checked===true?this.state.address2:this.state.address12,
        },
        note: {
          id: "1",
          content: ""
        },
        contact_groups: {
          group_ids: this.state.selectedOptions,
        }, 
        contact_products: {
          product_ids: []
        }      
      },
    };

    

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editContactApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
};

getBusinessCategoryData = () => {
  const header = {
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
    );
    
  this.getBusinessCategoryID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.businessCategory
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodGET
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleGroupsSearch = (text:any)=>{
  this.setState({ searchTerm: text , isSearching: true }, () => {
    this.getSearchGroupListData(); 
  });
}

saveSelectedOptions = (event: any, key: string) => {
  const { value } = event.target;
  const { selectedOptions } = this.state;
  if (key === "selectedOptions") {
    this.setState({ selectedOptions: value });
  } else {
    const newSelectedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((option:any) => option !== value)
      : [...selectedOptions, value];

    this.setState({ selectedOptions: newSelectedOptions });
  }
};

getLabels = (value: any, def: string) => {
  const selectedItemCount = value.filter((item: any) => item !== undefined).length;
  let data;
  if (selectedItemCount === 0) {
    data = this.t("Choose Contact");
  } else {
    data = `${selectedItemCount} ${this.t("Group")}${selectedItemCount !== 1 ? 's' : ''} ${this.t("Selected")}`;
  }
  if (selectedItemCount >= 1 && this.state.selectedOptions) {
    return (
      <>
       {data}
      </>
    );
  }
};

getSearchGroupListData = () => {
  const header = {
    token: this.userToken.meta.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getSearchGroupRequestId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.searchApiGroup}?query=${this.state.searchTerm}`,
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodGET
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleNewGroupAction = ()=>{
 this.setState({isNewGroup:true})
}

handleCloseNewGroupAction = ()=>{
  this.setState({newGroup:"", isNewGroup:false})
 }

createGroupResponseData = (response: any) => {
  if(response.errors){
    //@ts-ignore
    this.setState({ error: { newGroup: this.t("Group name already exists") } });
  }
  if(response.data){
    this.getGroupListData()
    this.handleCloseNewGroupAction()
    this.setState({newGroup:""})
  }
}

createGroupAction = () => { 
  const header = {
    "Content-Type": "application/json",
    token: this.userToken.meta.token,
  };

  const body = {
    group :{
      contact_ids: [],
      name:this.state.newGroup,
 }
  }
  let endPoint = configJSON.viewGroupPath;

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.createGroupRequestID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodPOST
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

countryListDataAPICall = () => {
  const header = {
    "Content-Type": "application/json",
    token: this.userToken.meta.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.countryListDataApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.webGetCountryList
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodGET
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

cityListAPICall = () => {
  if (this.state.state1 == ""|| this.state.stateList.length == 0) {
    return;
  }
  let stateVal = this.state.stateList.find(
    (x: any) => x.state_name == this.state.state1
  );

  const header = {
    "Content-Type": "application/json",
    token: this.userToken.meta.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.cityListDataApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.webGetCityList + `?state_id=${stateVal?.id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodGET
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

stateListAPICall = () => {
  if (this.state.country1 == ""|| this.state.countryList.length == 0) {
    return;
  }
  let country = this.state.countryList.find(
    (x: any) => x.country_name == this.state.country1
  );
  
   const header = {
    "Content-Type": "application/json",
    token: this.userToken.meta.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.stateListDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webGetStateList + `?country_id=${country?.id}`
    );
 
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodGET
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
addBCategoryEvent = (data: any, setError?: any, setShowDialog?: any) => {
  this.setErrorCategory = setError;
  this.showDialogCategory = setShowDialog;
  const header = {
    token: this.userToken.meta.token,
    "Content-Type": "application/json",
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  if (data.id != null) {
    this.updateBussinesCategoryRequestID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.bussinessCategoryApiPath}/${data.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        business_category: {
          name: data.title,
        },
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );
  } else {
    this.createBussinesCategoryRequestID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.bussinessCategoryApiPath}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        business_category: {
          name: data.title,
        },
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
  }

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};
categoryChangeEvent = (e: any, newValue: any) => {
  this.setState({
    businessCategory: newValue,
  });
};
fetchBCategoryListEvent = () => {
  const header = {
    token: this.userToken.meta.token,
    "Content-Type": "application/json",
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getBusinesCategoryRequestID = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.bussinessCategoryApiPath
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodGET
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};
deleteBCategoryE = (data: any) => {
  this.setState({ isDeleting: true, deleteBCategoryId: data.id , deleteTitle: data.title});
};
deleteCategoryFromList = () => {
  const header = {
    token: this.userToken.meta.token,
    "Content-Type": "application/json",
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.deleteBCategoryApiCallID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.bussinessCategoryApiPath}/${this.state.deleteBCategoryId}`
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodDELETE
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
}
// Customizable Area End
