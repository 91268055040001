// Customizable Area Start
import React from 'react';
import {
    Button,
} from "@material-ui/core";
import {
    createStyles,
    withStyles,
    Theme as AugmentedTheme,
} from "@material-ui/core/styles";

import { imgGoogle } from "./assets";
import GoogleWebControllerBlock ,{ configJSON } from './GoogleWebController.web';

// @ts-ignore
import CustomModalWeb from '../../../components/src/CustomModal.web';
import { withRouter } from "react-router-dom";
export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    overrides: {
      MuiButton: {
        socialButton: {
        },
      },
    },
    socialButton: {
      fontSize: "14px",
      lineHeight: "19px",
      border: "1px solid rgba(0,0,0,0.12)",
      borderRadius: "7px",
      width: "100%",
      color: "#2b2b2b",
      textTransform: "unset",
      background: "rgba(98,2,238,0.0001)",
      padding: "12px 10px",
      marginBottom: "0",
      fontWeight: 500,
      minHeight: "56px",
      fontFamily: "Expletus Sans",
      transition: "all 0.5s ease-in-out",     
      "&:hover": {
        background: "transparent",
      },
      "& .MuiButton-startIcon": {
        width: "18px",
        height: "18px",
        marginRight: "6px",
      },
    },
  });


export class CustomGoogleLogInButton extends GoogleWebControllerBlock {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
        <>
            <Button
                title="Login with Google"
                data-testid="loginWithGoogle"
                className={classes.socialButton}
                startIcon={<img src={imgGoogle} alt="goggle" />}
                onClick={this.handleGoogleButtonClick}
            >
                {this.t(`${configJSON.continueWithGoodleTxt}`)}
            </Button>
            {this.state.showModal && (
                <CustomModalWeb
                    modalOpen={this.handleModalOpen}
                    modalClose={this.handleClose}
                    createUserAccount={this.handlePopupLogin}
                    checkBoxChecked={this.handleCheckBox}
                    isChecked={this.state.isChecked}
                    email={this.state.googleUser.email}
                    checkBoxErrors={this.state.checkboxError.message}
                    displayName={this.state.googleUser.displayName}
                    userGoogleImageURL={this.state.googleUser.photoURL}
                    isGoogle={true}
                    t={this.t}
                    data-test-id="custom-terms-modal"
                />
            )}
        </>
        
    );
  }
}
//@ts-ignore
export default withStyles(customStyles, { withTheme: true })(( withRouter(CustomGoogleLogInButton) ));
// Customizable Area End