import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
// Customizable Area Start
stock: number;
updateStock: string;
isPopup: boolean;
itemData:{
  "id": string,
    "type": string,
    "attributes": {
      "category": {
        "id": number,
        "name": string,
        "static_id":number
      },
      "sub_category": {
        "id": number,
        "name": string,
        "static_id":number
      },
      "brand": {
        "id": number,
        "name": string
      },
      "target": any,
      "tags": any[],
      "reviews": any[],
      "name": string,
      "sku": any,
      "description": string,
      "manufacture_date": any,
      "length": any,
      "breadth": any,
      "height": any,
      "stock_qty": number,
      "availability": any,
      "weight": any,
      "price": any,
      "recommended": any,
      "on_sale": any,
      "sale_price": any,
      "discount": any,
      "meta_data": {
        "cost": string,
        "risk": string,
        "color": string,
        "lot_no": string,
        "max_dosage": string,
        "expiration_date": string,
        "quantity_unit": string,
        "area_unit": string,
        "n_content":string,
        "p_content":string,
        "k_content":string,
        "material":string,
        "serial_no": string,
        "specification": string,
        "unit_of_measurement":string,
        "volume":string,
        "area":string,

        "grape_variety_percent":string,
        "producer": string,
        "brand": string,
        "quantity": string,
        "name_complement":string,
        "dosage": string,
        "alcohol": string,
        "raw_material":string,
        "raw_material_percent":string,
        "container_with_volume": string,
        "production_process":string,
        "aging_process":string,
        "aging_potential":string,
        "short_description": string,
        "gtin": string,
        "sku": string,
        "ibu": string,
        "og": string,
        "srm": string,
        "fg": string,
        "ebc": string,
        "hop": string,
        "mashing": string,
        "strength": string,
        "fermentation": string,
        "ingredients": string,
        "allergens": string,

        "category": string,
        "style": string,
        "comment": string,
        
        "revision_date": string,
        "description": string,
        "hourly_cost": string,
        "stock_qty": string,
        "google_maps_url":string,
        "google_maps_place":string
        "age":string,
        "vintage":string
      },




      "tasting_notes":{
        "comment":string,
        "eye":string,
        "flavour":string,
        "food_pairing":string,
        "mouth":string,
        "nose":string,
        "opening_time":string,
        "temperature":string,
        "main_aromas": string,
        "flavours":string
      },
      "images": any[],
      "is_available": true,
      "average_rating": number,
      "catalogue_variants": any[],
      "allergens": any[],
      "location": {
        "appellation":string,
        "appellation_type": string,
        "catalogue_id": number,
        "country": string,
        "created_at":string,
        "era":string,
        "id":number,
        "region":string,
        "region_of_origin":string,
        "style":string,
        "sub_region":string,
        "updated_at": string,
      },
      "certificates": any[],
      "trophies": {
        "comment": string,
        "id": number, 
        "image": string,
        "name": string,
        "result": string,
        "year":string,
      }[],
      "prices":{
        "amount": string,
        "id": number
        "tax_included": string,
        "title": string,
      }[]
    }
};
anchorEl:any;
isLoading:boolean;
mapLatLng:{lat:number, lng:number} | null;
currentTrophy:{
  "comment": string,
  "id": number, 
  "image": string,
  "name": string,
  "result": string,
  "year":string,
},
isfavContact:boolean;
isActiveFilter:boolean;
contactLists:any;
selectedContactLists:any[];
isFavouriteProductCreated:boolean;
// Customizable Area End
}
  
interface SS {
id: any;
// Customizable Area Start
// Customizable Area End
}
  
// Customizable Area Start

// Customizable Area End
export default class ViewItemController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionData: any;
  userToken: any;
  itemIDParam:any;
  getCatalogueApiId: string = "";
  updateStockApiId: string ="";
  popoverRef: React.RefObject<any>;
  inputRef:React.RefObject<any>;
  addFavouritesContactsAPID:string="";
  fetchAllContactsAPID:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      // Customizable Area Start
      stock: 50,
      updateStock:"",
      isPopup: false,
      itemData:{
        "id": "",
          "type": "",
          "attributes": {
            "category": {
              "id": 0,
              "name": "",
              "static_id":0
            },
            "sub_category": {
              "id": 0,
              "name": "",
              "static_id":0
            },
            "brand": {
              "id": 0,
              "name": ""
            },
            "target": null,
            "tags": [],
            "reviews": [],
            "name": "",
            "sku": null,
            "description": "",
            "manufacture_date": null,
            "length": null,
            "breadth": null,
            "height": null,
            "stock_qty": 0,
            "availability": null,
            "weight": null,
            "price": null,
            "recommended": null,
            "on_sale": null,
            "sale_price": null,
            "discount": null,
            "meta_data": {
              "cost": "0",
              "risk": "",
              "color": "",
              "lot_no": "",
              "max_dosage": "",
              "expiration_date": "",
              "quantity_unit":"",
              "area_unit":"",
              "n_content":"",
              "p_content":"",
              "k_content":"",
              "material":"",
              "serial_no": "",
              "specification": "",
              "unit_of_measurement":"",
              "volume":"",
              "area":"",
              "google_maps_url":"",
              "google_maps_place":"",
              
              "grape_variety_percent":"",
              "producer": "",
              "brand": "",
              "quantity": "",
              "name_complement":"",
              "dosage": "",
              "alcohol": "",
              "raw_material":'',
              "raw_material_percent":"",
              "container_with_volume":"",
              "production_process": "",
              "aging_process":"",
              "aging_potential": "",
              "short_description":"",
              "gtin": "",
              "sku": "",
              "ibu": "",
              "og": "",
              "srm": "",
              "fg": "",
              "ebc": "",
              "hop": "",
              "mashing": "",
              "strength": "",
              "fermentation": "",
              "ingredients": "",
              "allergens": "",

              "category": "",
              "style": "",
              "comment": "",

              "revision_date": "",
              "description": "",
              "hourly_cost": "",
              "stock_qty": "",
              "age":"",
              "vintage":""
            },
            "tasting_notes":{
              "comment":'',
              "eye":'',
              "flavour":'',
              "food_pairing":'',
              "mouth":'',
              "nose":'',
              "opening_time":'',
              "temperature":'',
              "main_aromas": "",
              "flavours":"",
            },
            "images": [],
            "is_available": true,
            "average_rating": 0,
            "catalogue_variants": [],
            "allergens": [],
            "location": {
              "appellation":'',
              "appellation_type": '',
              "catalogue_id": 0,
              "country": '',
              "created_at":'',
              "era":'',
              "id":0,
              "region":'',
              "region_of_origin":'',
              "style":'',
              "sub_region":'',
              "updated_at": '',
            },
            "certificates": [],
            "trophies": [],
            "prices": []
          }
      },
      anchorEl:null,
      isLoading:true,
      mapLatLng:null,
      currentTrophy:{
        "comment": "",
        "id": 0, 
        "image": "",
        "name": "",
        "result": "",
        "year":"",
      },
      isfavContact:false,
      isActiveFilter:false,
      contactLists:[],
      selectedContactLists:[],
      isFavouriteProductCreated:false,
      // Customizable Area End,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.itemIDParam = this.getLastPartOfURL(window.location.pathname); 
    this.popoverRef = React.createRef();
    this.inputRef=React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          switch (apiRequestCallId) {
            case this.updateStockApiId:
              this.setState({isPopup:false,anchorEl:null,updateStock:"",itemData:responseJson.data})
              break;
              case this.fetchAllContactsAPID:
              this.setState({isLoading:false});
              this.setState({contactLists : responseJson.data })
              break;
              case this.addFavouritesContactsAPID:
              this.setState({isLoading:false});
              this.setState({
                  isFavouriteProductCreated: true,
              });
              break;
            default:
              this.getCatalogueResponse(apiRequestCallId,responseJson)
          }
        }
      }
    }
    // Customizable Area End
  }
  getCatalogueResponse=(apiRequestCallId:any,responseJson:any)=>{
    if(apiRequestCallId == this.getCatalogueApiId){
      this.setState({itemData:responseJson.data,isLoading:false},()=>{
        let itemData = this.state.itemData;
        if(itemData.attributes.prices.length>0){                  
          itemData.attributes.price = itemData.attributes.prices[0].amount;
          this.setState({itemData:itemData},()=>{
            if(itemData.attributes.trophies != null && itemData.attributes.trophies.length>0){
              this.setState({
                currentTrophy:itemData.attributes.trophies[0]
              })
            }
          })
        }
      })
    }
  }
  async componentDidMount(): Promise<void> {
    this.getCatalogue()
    const lang = localStorage.getItem("lang") || "en"; 
    await (i18n as any).changeLanguage(lang);
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if(prevState.itemData != this.state.itemData){
      if(this.state.itemData.attributes.meta_data.google_maps_url){
          this.viewMap(this.state.itemData.attributes.meta_data.google_maps_url)
      }
    }
  }

    backToInventory=()=>{
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "InventoryManagement");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
    openSendToContactModal = () => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "SendToContact");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), {itemsToSend: [this.state.itemData]});
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    }
    handleIsFavContact = ()=>{
      this.setState({isfavContact:true}, () => {this.fetchAllContacts()})
    }
    handleIsFavContactClose = ()=>{
      this.setState({isfavContact:false})
    }
    filterActivityHandler = () => {
      this.setState({ isActiveFilter: true });
    };
    filterModalClose = () => {
      this.setState({ isActiveFilter: false });
    };
    favouriteProductModalClose = () => {
      this.setState({ isFavouriteProductCreated: false });
    };
    favouriteProductCreatedOpen = () => {
      this.setState({
        isFavouriteProductCreated: true,
      });
    };
    handleSelectedLists = (contactId: string) => {
      let changeContacts = this.state.selectedContactLists;
      if (changeContacts.includes(contactId)) {
        changeContacts = changeContacts.filter((x: any) => x !== contactId);
      } else {
        changeContacts.push(contactId);
      }
      this.setState({
        selectedContactLists: changeContacts,
      });
    };
    addProductsAsFavourites = () => {
      const header = {
        token: this.userToken.meta.token,
        "Content-Type": "application/json",
      };
      const body={
        "contact": {
            "contact_products": {
                "products": {
                  id: this.state.itemData.id , 
                  quantity: 1,
                  price: {
                    price_id: 1,
                    amount: 0
                  }
              },
            }
        }
     }   
    
      const requestMessage: Message = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.addFavouritesContactsAPID = requestMessage.messageId;  
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.addAsFavPRoductAPiEndpoint}/${this.state.selectedContactLists[0]}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      
      this.setState({ isLoading: true });
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    fetchAllContacts = () => {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.userToken.meta.token,
      };
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.contactsLists
      );
      this.fetchAllContactsAPID = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      this.setState({ isLoading: true });
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleStock=(event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{
      let value = event.target.value
      if(value){
        value = value.replace(/\D/g, '');
      }
      this.setState({updateStock: value})
    }


    handlePopover = (e:any)=>{
      this.setState({
        anchorEl: this.popoverRef.current,
        isPopup:true
      }, 
      () => {
        setTimeout(() => {
          if (this.inputRef.current) {
            return this.inputRef.current.focus();
          }
    
          return null;
        }, 200);
      });
    }
  
    handlePopoverClose = () => {
      this.setState({ isPopup: false ,anchorEl: null,});
    };



    getCatalogue=()=>{
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.userToken.meta.token,
      };
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCatalogueEndPoint+`/${this.itemIDParam}`
      );
      this.getCatalogueApiId = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getLastPartOfURL = (url: string) => {
      // Split the URL by slashes (/)
      let parts = url.split("/");
  
      // Get the last part of the URL
      let lastPart = parts[parts.length - 1];
  
      return lastPart;
    };

    updateStock = () => {
      if(this.state.updateStock == ""){
        return;
      }
      const header = {
        token: this.userToken.meta.token,
      };
      let formdata = new FormData();
      formdata.append("stock_qty",this.state.updateStock.toString());
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
        this.updateStockApiId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCatalogueEndPoint+`/${this.itemIDParam}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.editApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    replaceNullsWithDash(obj:any) {
      for (const key in obj) {
        
        if (obj[key] !== null && obj[key] !== "" && typeof obj[key] === 'object') {
          obj[key] = this.replaceNullsWithDash(obj[key]);          
        } else if (obj[key] === null || obj[key] == "") {
          obj[key] = configJSON.nullValueDisplay;
        }
      }
      return obj;
    }

    editClick = () => {
      this.props.navigation.navigate("AddItem",{":itemId":this.itemIDParam});
    }

    viewMap=(url:string)=>{
      const regex = /(?:@|q=)(-?\d+\.\d+),(-?\d+\.\d+)/;
      const match = url.match(regex);
      
      if (match) {
        const lat = parseFloat(match[1]);
        const lng = parseFloat(match[2]);
      
        if (!isNaN(lat) && !isNaN(lng)) {
          this.setState({mapLatLng:{lat:lat, lng:lng}})
        } 
      }
    }

    changeCurrentTrophy=(trophy:any)=>{
      this.setState({
        currentTrophy:{
          ...trophy
        }
      })
    }
    
  // Customizable Area End
}
