// Customizable Area Start
import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MemberAddedSuccessController, {
  Props,
} from "./MemberAddedSuccessController.web";
import { customStyles } from "./TeamsStyles.web";
import { memberSuccess } from "../assets";
import AppHeader from "../../../../components/src/AppHeader.web";

class MemberAddedSuccess extends MemberAddedSuccessController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <Box>
          <Box
            className={`wrapper ${classes.wrapper} ${classes.membersuccessWrapper}`}
          >
            <AppHeader />
            <Box className={`inner-wrapper ${classes.innerWrapper}`}>
              <Box className={classes.memberAddedWrapper}>
                <Box className="member-added-inner">
                  <Box className="member-added-row">
                    <Box className="member-added-img">
                      <img src={memberSuccess} alt="member-success" />
                    </Box>
                    <Box className="member-added-content">
                      <Typography className="heading" variant="h1">
                        Members Added Successfully
                      </Typography>
                      <Box component="p" className="sub-txt">
                        You can add more members or you can finish.
                      </Box>
                      <Box className="team-button-wrapper">
                        <Button
                          className={`secondary-btn ${classes.secondaryButton}`}
                          onClick={this.addAnotherMember}
                          data-testid="addAnotherMember"
                        >
                          Add Another Member
                        </Button>
                        <Button
                          className={`primary-btn ${classes.primaryButton}`}
                          onClick={this.returnTeam}
                          data-testid="returnToTeams"
                        >
                          Return to teams
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(
  MemberAddedSuccess
);
// Customizable Area End
