import React from "react";
// Customizable Area Start
import { Box, Typography, List, ListItem, Modal, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import {
  userIcon,
  sunIcon,
  emailIcon,
  downThumbIcon,
  CalenderIcon,
} from "./assets";
// Customizable Area End

import ViewEmailCampaignControllerWeb, {
  Props,
  // Customizable Area Start
  configJSON,
  // Customizable Area End
} from "./ViewEmailCampaignController.web";

// Customizable Area Start
import WebLoader from "../../../components/src/WebLoader.web";
import moment from "moment";
// Customizable Area End

export class ViewEmailCampaign extends ViewEmailCampaignControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, openViewModal, closeViewModal } = this.props;
    const { viewCampaignDetail } = this.state;
    return (
      <>
      {this.state.isLoading && <WebLoader/>}
      <Modal
        className={classes.modalDialog}
        aria-labelledby="modal-modal-title"
        onClose={closeViewModal}
        open={openViewModal}
        aria-describedby="modal-modal-description"
      >
        <Box className={`view-modal-dialog ${classes.modalDialogBox}`}>
          <Box className="modal-heading">
            <Link
              to="#"
              className="close-icon"
              onClick={closeViewModal}
              data-testid="viewTaskClose"
            >
              <CloseIcon />
            </Link>
          </Box>
          <Box className="modal-description-deal">
            <Typography className="modal-title" component="h2">
              {viewCampaignDetail.name}
            </Typography>
            <Typography className="sub-txt" component="p">
              {this.t(`${configJSON.emailCampaignSubTxt}`)}
            </Typography>
            <List className="dealview-list">
              <ListItem>
                <Box className="list-label">
                  {this.t(`${configJSON.statusTxt}`)}
                </Box>
                <Box className="list-description">
                  {viewCampaignDetail.status && (
                     <span className="status-control-sent">
                     {viewCampaignDetail.status.charAt(0).toUpperCase() + viewCampaignDetail.status.slice(1)}
                   </span>)}
                 
                </Box>
              </ListItem>
              <ListItem>
                <Box className="list-label">
                  {this.t(`${configJSON.creatorTxt}`)}
                </Box>
                {viewCampaignDetail.sender !== undefined && (
                  <Box className="list-description">
                    by {viewCampaignDetail.sender.name}
                  </Box>
                )}
              </ListItem>
              <ListItem>
                <Box className="list-label">
                  {this.t(`${configJSON.fromTxt}`)}
                </Box>
                {viewCampaignDetail.sender !== undefined && (
                  <Box className="list-description">
                    {viewCampaignDetail.sender.email}
                  </Box>
                )}
              </ListItem>
              <ListItem>
                <Box className="list-label">
                  {this.t(`${configJSON.toTxt}`)}
                </Box>
                <Box className="list-description">
                  <List className="badges-wrapper">
                    {viewCampaignDetail.recipients !== undefined && (
                      <>
                        {viewCampaignDetail.recipients.lists.map(
                          (list: any) => {
                            return (
                              <ListItem>
                                <span className="gray-badge">{list.name}</span>
                              </ListItem>
                            );
                          }
                        )}
                      </>
                    )}
                  </List>
                </Box>
              </ListItem>
              <ListItem>
                <Box className="list-label">
                  {this.t(`${configJSON.dateTxt}`)}
                </Box>
                <Box className="list-description">
                  <Box className="date-info">
                    {viewCampaignDetail.created_at !== null && (
                      <>
                        <Box className="label">
                          {this.t(`${configJSON.createdOnTxt}`)}:
                        </Box>

                        <Box className="Value">
                          {moment(viewCampaignDetail.created_at).format("ddd MMMM DD, YYYY")}{" "}
                          <img src={CalenderIcon} alt="calneder icon" />{" "}
                        </Box>
                      </>
                    )}
                  </Box>
                  <Box className="date-info">
                    {viewCampaignDetail.scheduled_at !== null && (
                      <>
                        <Box className="label">
                          {this.t(`${configJSON.scheduledTxt}`)}:
                        </Box>

                        <Box className="Value">
                          Mon Mar 30, 2022{" "}
                          <img src={CalenderIcon} alt="calneder icon" />{" "}
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </ListItem>
              <ListItem>
                <Box className="list-label">
                  {this.t(`${configJSON.designTxt}`)}
                </Box>
                {viewCampaignDetail.template !== undefined && (
                  <>
                  {viewCampaignDetail.template !== null && (
                    <Box className="list-description">
                    {viewCampaignDetail.template.name}
                    <Link to="#" className="blue-link">
                      &nbsp;{this.t(`${configJSON.previewTxt}`)}
                    </Link>
                  </Box>
                  )}
                    
                  </>
                )}
              </ListItem>
              <ListItem>
                <Box className="list-label">
                  {this.t(`${configJSON.advanceSettingsTxt}`)}
                </Box>
                <Box className="list-description">
                  <Link to="#" className="blue-link">
                    {this.t(`${configJSON.viewTxt}`)}
                  </Link>
                </Box>
              </ListItem>
            </List>
          </Box>
          <Box className="modal-grid-outer">
            <Box className="modal-grid-main">
              <Box className="modal-grid-item wide delivered">
                <Box className="modal-grid-item-inner">
                  <Box className="desc-block">
                    <Typography className="desc-title">
                      128<span>(100%)</span>{" "}
                    </Typography>
                    <Typography className="desc-text">Delivered</Typography>
                  </Box>
                  <Box className="icon-block">
                    <img src={userIcon} alt="user icon" />
                  </Box>
                </Box>
              </Box>
              <Box className="modal-grid-item thin openers">
                <Box className="modal-grid-item-inner">
                  <Box className="icon-block">
                    <img src={emailIcon} alt="email icon" />
                  </Box>
                  <Box className="desc-block">
                    <Typography className="desc-title">
                      26<span>(21.35%)</span>{" "}
                    </Typography>
                    <Typography className="desc-text">Openers</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="modal-grid-item thin clicks">
                <Box className="modal-grid-item-inner">
                  <Box className="icon-block">
                    <img src={sunIcon} alt="clicks icon" />
                  </Box>
                  <Box className="desc-block">
                    <Typography className="desc-title">
                      1 <span>(1%) </span>{" "}
                    </Typography>
                    <Typography className="desc-text">Clicks</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="modal-grid-item wide unsubscribers">
                <Box className="modal-grid-item-inner">
                  <Box className="desc-block">
                    <Typography className="desc-title">
                      2 <span>(20%)</span>{" "}
                    </Typography>
                    <Typography className="desc-text">unsubscribers</Typography>
                  </Box>
                  <Box className="icon-block">
                    <img src={downThumbIcon} alt="down thumb icon" />
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* <Box className="modal-grid-main"> */}
            <Button className={`primary-btn ${classes.primaryButton}`}>
              {this.t(`${configJSON.reuseCampaignTxt}`)}
          </Button>
            {/* </Box> */}
          </Box>
        </Box>
      </Modal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default ViewEmailCampaign;
// Customizable Area End
