import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
import {
  SubscribedUser,
  ChatMessagesResponse,
  Chat,
  UserToken,
  GroupedMessages,
  chatRoomData,
  Type,
  LiveChatEtohData,
  ExternalChat,
} from "../../../components/src/ReusableEnums";
import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  liveChatId: string;
  // Customizable Area Start
  classes: Record<string, string>;
  isMobile: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isDrawerOpened: boolean;
  openNewChat: boolean;
  isLoading: boolean;
  chatTxtMessage: string;
  chatRoomList: Array<Chat>;
  chatRoomAllList: Array<Chat>;
  allSinglePersonChat: Array<ChatMessagesResponse>;
  messageId: string;
  openExternalChat: boolean;
  email: string;
  error: {
    email: string;
  };
  filesUploaded: File[] | null;
  previews: string[];
  hasNextPage: boolean;
  page: number;
  subscribedUserData: Array<SubscribedUser>;
  userSortBy: string;
  userSearch: string;
  chatRoomSortBy: string;
  chatRoomSearch: string;
  groupedMessages: Array<GroupedMessages>;
  openEmailvarification: boolean;
  totalRecords:number;
  itemData:Chat;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class LiveChat2Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  userSessionData: string | null;
  userToken: UserToken;
  createNewChatRequestId: string = "";
  getAllChatRoomsRequestId: string = "";
  getAllMessageRequestId: string = "";
  createExternalChat: string = "";
  formRef: React.RefObject<HTMLFormElement>;
  fileRef: React.RefObject<HTMLInputElement>;
  totalPage: number = 0;
  getAllUsersRequestId: string = "";
  createExternalInternalChatRequestId: string = "";
  markAsReadAllRequestId: string = "";
  createExternalInternalMessageeRquestId: string = "";
  etohSupportLiveChatRequestId:string="";
  LiveChatEndRef: React.RefObject<HTMLDivElement>;
  inviteMemberRequestId:string="";
  getAllChatRoomsRequestID: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.fetchMoreData = this.fetchMoreData.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isDrawerOpened: false,
      openNewChat: false,
      isLoading: false,
      chatTxtMessage: "",
      chatRoomList: [],
      chatRoomAllList:[],
      allSinglePersonChat: [],
      messageId: "",
      openExternalChat: false,
      email: "",
      error: {
        email: "",
      },
      filesUploaded: null,
      previews: [],
      hasNextPage: false,
      page: 1,
      subscribedUserData: [],
      userSortBy: this.translateChat(`${configJSON.sortByTxt}`),
      userSearch: "",
      chatRoomSortBy: this.translateChat(`${configJSON.sortByTxt}`),
      chatRoomSearch: "",
      groupedMessages: [],
      openEmailvarification: false,
      totalRecords:0,
      itemData:{id:"",attributes:{}},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.formRef = React.createRef();
    this.fileRef = React.createRef();
    this.totalPage = 0;
    this.userSessionData = sessionStorage.getItem("userData") || "";
    this.userToken = JSON.parse(this.userSessionData);
    this.LiveChatEndRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  
       if (responseJson.error) {
        this.setState({ isLoading: false });
        return;
       }
        switch (apiRequestCallId) {
          case this.getAllChatRoomsRequestId:
            this.handleAllChatRoomsResponse(responseJson.data);
            break;
          case this.getAllChatRoomsRequestID:
            this.handleAllChatRoomsResponseData(responseJson.data);                  
            break;            
          case this.getAllMessageRequestId:
            const messages = responseJson.data; 
            const meta = responseJson.meta.pagination;
            this.totalPage = meta.current;        
            this.setState((prevState) => ({
              isLoading: false,
              allSinglePersonChat: meta.current === 1 ? messages : [...prevState.allSinglePersonChat, ...messages],
              page: meta.current,
              totalRecords: meta.records,
              hasNextPage: meta.current < meta.last,
            }));          
            this.groupMessages();
            this.handleChatRooms();    
            break;
          case this.createNewChatRequestId:
            this.setState({
              chatTxtMessage: "",
              isLoading: false,
              previews: [],
              filesUploaded: [],
            });
            this.getAllMessage(this.state.itemData, this.state.page);
            break;
          case this.getAllUsersRequestId:
            this.setState({
              isLoading: false,
              subscribedUserData: responseJson.data,
            });
            break;
          case this.createExternalChat:
            this.handleExternalChatResponse(responseJson);           
            break;
          case this.createExternalInternalChatRequestId:
            responseJson.errors &&
              this.createToastNotification(
                this.translateChat(responseJson.errors.chat.base[0])
              );
            this.setState({ openNewChat: false });
            this.handleChatRooms();
            break;
            case this.createExternalInternalMessageeRquestId: 
            this.setState({
              isLoading: false,
              chatTxtMessage: "",
              filesUploaded: [],
              previews: [],
            });         
            this.getAllMessage(this.state.itemData,this.state.page);
            break;
            case this.etohSupportLiveChatRequestId: 
            this.handleEtohSupportLiveChatResponse(responseJson)
            break;
            case this.inviteMemberRequestId:
              if(responseJson.message){
              this.createToastNotification(responseJson.message);
              this.closeEmailVarification();
              this.closeExternalChat();             
            }else{
              this.createToastNotification(configJSON.errorMessage);
            }
            break;            
          default:
            this.parseApiCatchErrorResponse(errorResponse);
            break;
        }

    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleAllChatRoomsResponse(responseJson: Array<Chat>) {
    this.setState({
      isLoading: false,
      chatRoomList: responseJson,
    });
  }
  handleAllChatRoomsResponseData(responseJson: Array<Chat>) {
    if(responseJson){
      this.setState({
        chatRoomAllList: responseJson
      });
    }else{
      this.setState({
        chatRoomAllList: []
      });
    }    
  }
  handleEtohSupportLiveChatResponse(responseJson:LiveChatEtohData) {
    this.setState({ isLoading: false });
    if (responseJson.errors) {
      this.createToastNotification(configJSON.etohSupportAlreadyCreated);
    } else {
      this.createToastNotification(configJSON.etohSupportChatAdded);
    }
    this.closeNewChat();
    this.handleChatRooms();
  }

handleExternalChatResponse(responseJson: ExternalChat) {
  if (responseJson.message === "Member succesfully found") {
    this.setState({
      isLoading: false,
      openExternalChat: false,
    });
    this.createExternalAndInternalChatEvent(responseJson.account.data, "external");
  } else {
    this.setState({
      isLoading: false,
      error: {
        email: `${this.translateChat(configJSON.errorMessageForUserNotFoud)}`,
      },
    });
  }
}
  
  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.clearError(prevState);
    this.fetchUsersUpdate(prevState);
    if (prevState.allSinglePersonChat.length !== this.state.allSinglePersonChat.length) {
      setTimeout(() => {
        this.scrollToChatBottom();
      }, 0);
    }
  }
  scrollToChatBottom = () => {
    if (this.LiveChatEndRef.current) {
      this.LiveChatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  async componentDidMount(): Promise<void> {
    this.handleChatRooms();
    this.handleGetChatRooms();
    this.getAllSubscribedUsers();
    this.groupMessages();
    const lang = localStorage.getItem("lang") || "en";
    await i18n.changeLanguage(lang);
  }
  createToastNotification = (toastMesssage: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{this.translateChat(toastMesssage)}</div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };
  groupMessages = () => {
    const { allSinglePersonChat } = this.state;
    const groupedMessages: Array<GroupedMessages> = [];

    allSinglePersonChat.forEach((message) => {
      const createdAt = moment(message.attributes.created_at);
      const today = moment().startOf("day");
      const yesterday = moment().subtract(1, "days").startOf("day");

      let dateKey: string;
      switch (true) {
        case createdAt.isSame(today, "d"):
          dateKey = "Today";
          break;
        case createdAt.isSame(yesterday, "d"):
          dateKey = "Yesterday";
          break;
        default:
          dateKey = createdAt.format("MM/DD/YYYY");
      }

      const messageData = groupedMessages.findIndex(
        (group) => group.date === dateKey
      );

      if (messageData === -1) {
        groupedMessages.push({ date: dateKey, messages: [message] });
      } else {
        groupedMessages[messageData].messages.push(message);
      }
    });

    this.setState({ groupedMessages });
  };

  translateChat(keyValue: string) {
    return i18n.t(keyValue, { ns: "translation" });
  }
  toggleProfileDrawer = () => {
    this.setState({
      isDrawerOpened: true,
    });
  };
  closeDrawer = () => {
    this.setState({
      isDrawerOpened: false,
    });
  };
  openNewChat = () => {
    this.setState({
      openNewChat: true,
    });
  };
  closeNewChat = () => {
    this.setState({
      openNewChat: false,
    });
  };
  openExternalChatEvent = () => {
    this.setState({
      openExternalChat: true,
    });
  };
  closeExternalChat = () => {
    this.setState({
      openExternalChat: false,
    });
  };
  closeEmailVarification = () => {
    this.setState({ openEmailvarification: false });
  };
  handleInviteMember = () => {
    this.setState({ openEmailvarification: true });
  };
  handleBackBtn = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "CustomisableUserProfiles"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  
  fetchMoreData() {
    const { allSinglePersonChat, page, totalRecords } = this.state;

    if (allSinglePersonChat.length >= totalRecords) {
      this.setState({ hasNextPage: false });
      return;
    }

    const newPage = page + 1;
    this.getAllMessage(this.state.itemData, newPage);
  }

  fetchUsersUpdate = (prevState: S) => {
    if (
      prevState.userSearch !== this.state.userSearch ||
      prevState.userSortBy !== this.state.userSortBy
    ) {
      this.getAllSubscribedUsers();
    }
    if (
      prevState.chatRoomSearch !== this.state.chatRoomSearch ||
      prevState.chatRoomSortBy !== this.state.chatRoomSortBy
    ) {
      this.handleChatRooms();
    }
  };

  handleAllInputChange = (event: Type) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  clearError = (prevState: S) => {
    let error = { ...this.state.error };
    const email = this.state.email.trim();
    if (prevState.email !== email) {
      if (email !== "") {
        error.email = "";
      } else if (!configJSON.emailRegex.test(email)) {
        error.email = this.translateChat(configJSON.emailErrorMsg);
      } else {
        error.email = "";
      }
    }
    if (JSON.stringify(error) !== JSON.stringify(this.state.error)) {
      this.setState({ error: error });
    }
  };
  createExternalChatEvent = () => {
    let error = this.state.error;
    let hasError = false;

    const email = this.state.email.trim();
    if (email === "") {
      this.setState({
        error: {
          ...this.state.error,
          email: this.translateChat(configJSON.emailEmptyErrorMsg),
        },
      });
    } else if (!configJSON.emailRegex.test(email)) {
      error.email = this.translateChat(configJSON.emailErrorMsg);
      this.setState({
        error: error,
      });
      hasError = true;
    }

    if (hasError) {
      return;
    }
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createExternalChat = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createExternalChatApi}?email=${this.state.email}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createExternalAndInternalChatEvent = (
    dataList: chatRoomData,
    type: string
  ) => {
    const userName =
      dataList.attributes.first_name + " " + dataList.attributes.last_name;
    const formdata = new FormData();
    formdata.append("chat[chat_type]", type);
    formdata.append("chat[name]", userName);
    formdata.append("account_id", dataList.id);

    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createExternalInternalChatRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createChatForExternalAndInternalUser
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createExternalAndInternalChatMessgaeEvent = () => {
    const formdata = new FormData();
    formdata.append("chat_message[message]", this.state.chatTxtMessage);
    formdata.append("chat_message[chat_id]", this.state.messageId);   
    formdata.append("account_id", this.state.itemData.attributes.sender?.id);
    if (this.state.filesUploaded) {
      for (const file of this.state.filesUploaded) {
        const fileType = file && file.type.split("/")[0];
        formdata.append("chat_message[media_file]", file);
        formdata.append("chat_message[media_type]",fileType);
      }
    }

    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.externalAndInternalMessage
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.createExternalInternalMessageeRquestId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );    
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onBrowse = () => {
    if (this.fileRef.current) {
      this.fileRef.current.click();
    }
  };
  handleOnDrop = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = Array.from(event.target.files || []);
    const previews: string[] = [];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        previews.push(reader.result as string);
        if (previews.length === files.length) {
          this.setState({
            filesUploaded: files,
            previews,
          });
        }
      };
      reader.readAsDataURL(file);
    });
  };
  handleCloseAttachment = () => {
    this.setState({ filesUploaded: [], previews: [] });
  };
  handleSubmitMessage = () => {
    const formData = new FormData();
    formData.append("chat_message[message]", this.state.chatTxtMessage);
    formData.append("chat_message[chat_id]", this.state.messageId);
    if (this.state.filesUploaded) {
      for (const file of this.state.filesUploaded) {
        const fileType = file && file.type.split("/")[0];
        formData.append("chat_message[media_file]", file);
        formData.append("chat_message[media_type]",fileType);
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      token: this.userToken.meta.token,
    };

    this.createNewChatRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendChatRoomMsg}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleChatRooms = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const newValue = this.state.chatRoomSortBy;

    let processedValue = "";

    switch (newValue) {
      case "Recent":
        processedValue = "recent";
        break;
      case "Unread":
        processedValue = "unread";
        break;
    }

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    this.getAllChatRoomsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getChatPath}?search_query=${this.state.chatRoomSearch}&sort=${processedValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    // this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleGetChatRooms = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    this.getAllChatRoomsRequestID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getChatPath}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllMessage = (item:Chat,newPage:number) => {
    this.markAsReadAll(item.id);
    this.setState({ itemData: item , messageId:item.id });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    this.getAllMessageRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendChatRoomMsg}?chat_id=${item.id}&page[number]=${newPage}&page[size]=100`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.setState({hasNextPage:true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllSubscribedUsers = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const newValue = this.state.userSortBy;
    let processedValue = "";

    switch (newValue) {
      case "A-Z":
        processedValue = "A-Z";
        break;
      case "Z-A":
        processedValue = "Z-A";
        break;
    }
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    this.getAllUsersRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subscribedUserList}?search_query=${this.state.userSearch}&sort_by=${processedValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  markAsReadAll = (chatID: string) => {
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadAllRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.markAsRead}?chat_id=${chatID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createEtohSupportChat = () => {
    const header = {
      token: this.userToken.meta.token,
    };
    const formData = new FormData();
    formData.append("chat[chat_type]", "etoh_shop_support");
    formData.append("chat[name]", "Etoh Support");    

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.etohSupportLiveChatRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getChatPath
    );

    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  inviteMemberEvent = () => {
    const formdata = new FormData();
    formdata.append("chat[email]", this.state.email);

    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.inviteMemberPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.inviteMemberRequestId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );    
    this.setState({ isLoading: false });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
