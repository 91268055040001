export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const fuelImg = require("../assets/fuel-img.png");
export const otherProducts = require("../assets/otherProducts.png");
export const tractorImg = require("../assets/tractor.png");
export const nailImg = require("../assets/nail.png");
export const assetsImg = require("../assets/shape.png");
export const wineImg = require("../assets/wine-glass.png");
export const testtubeImg = require("../assets/testtube.png");
export const landImg = require("../assets/weather-sunset.png");
export const fuelIcon = require("../assets/fuel.png");
export const toolsImg = require("../assets/hammer-wrench.png");
export const vesselImg = require("../assets/cylinder.png");
export const comsumable = require("../assets/consumable.png");
export const spiritImg = require("../assets/cup-outline.png");
export const beerImg = require("../assets/beer-mug.png");
export const productsImg = require("../assets/alchol-img.png");
