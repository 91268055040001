// Customizable Area Start
import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ForgotPasswordController ,{configJSON} from "./ForgotPasswordController.web";
import Footer from "../../../components/src/Footer.web";
import { customStyles } from "./ForgotPassword.web";
import { imgPasswordReset } from "./assets";
import AppHeader from "../../../components/src/AppHeader.web";

class PasswordResetSuccess extends ForgotPasswordController {
  render() {
    const { classes } = this.props;
    return (
        <Box>
          <Box className={`wrapper ${classes.wrapper}`}>
            <AppHeader />
            <Box className={classes.innerWrapper}>
              <Box className={classes.passwordResetWrapper}>
                <Box className="password-reset-inner">
                  <Box className="password-reset-row">
                    <Box className="password-reset-img">
                      <img src={imgPasswordReset} alt="password-reset" />
                    </Box>
                    <Box className="password-reset-content">
                      <Typography className="heading" variant="h1">                        
                        {this.t(`${configJSON.resetPasswordTxt}`)}
                      </Typography>
                      <Box component="p" className="sub-txt">                        
                        {this.t(`${configJSON.resetSubTxt}`)}
                      </Box>
                      <Button
                        data-testid="btnRedirectLogin"
                        className={classes.primaryBtnResetPwd}
                        title="login"
                        onClick={this.navigateLogin}
                      >
                        {this.t(`${configJSON.loginTxt}`)}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Footer />
        </Box>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(
  PasswordResetSuccess
);

// Customizable Area End
