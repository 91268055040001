//Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Button,
  Slider,
} from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import { customStyles } from "./EquizStyles.web";

import ReTakeQuizControllerWeb, { Props } from "./ReTakeQuizController.web";
import { EquizScoreWeb } from "./QuizScoreScreen.web";
import WebLoader from "../../../components/src/WebLoader.web";
export const configJSON = require("./config");
export class ReTakeQuizWeb extends ReTakeQuizControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <>
      {this.state.isLoading && <WebLoader/>}
        <Box className={classes.stepperRoot}>
          <Box className="stepper-top">
            <Box className="stepper-row">
              <Box className="stepper-col">
                <Box className="back-btn-wrap" onClick={this.handleBackArrow}>
                  <i>
                    <svg
                      className="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                    </svg>
                  </i>
                </Box>
              </Box>
              <Box className="stepper-col stepper-middle">
              </Box>

              <Box className="stepper-col stepper-action">
                <Button
                  className={`secondary-btn ${classes.secondaryButton}`}
                  onClick={this.handleRetakeEditQuiz}
                  data-test-id="retake-edit-quiz-btn"
                >
                  Edit Quiz
                </Button>
              </Box>
            </Box>
          </Box>
          <Box className="step-content-root">
            <Box className="step-content">
              <Box className="member-upload-content">
                {this.state.isQuizSubmitted === true ? (
                  <EquizScoreWeb
                    classes={classes}
                    quizTitle={this.state.reTakeQuizTitle}
                    grade={this.state.quizGrade}
                    reTakeBtn={this.reTakeBtnHandler}
                    dashboardBtnHandler={this.handleDashboardClick}
                    scoreDescriptionType="chooseQuiz"
                    t={this.t}
                  />
                ) : (
                  <>
                    <Box className="quiz-question-wrapper">
                      <Box className="score-heading">
                        <Typography className="heading" variant="h1">
                          {this.state.reTakeQuizTitle}
                        </Typography>
                        <Box component="p" className="sub-txt">
                          {this.state.reTakeQuizCaption}
                        </Box>
                      </Box>

                      {this.state.submittedQuizData.map(
                        (question: any, index: number) => {
                          const {
                            id,
                            title,
                            min_score_value,
                            max_score_value,
                          } = question.question;
                          if (question.submission === null) {
                            return;
                          }
                          const { answer } = question.submission;

                          const interval =
                            (max_score_value - min_score_value) / 4;
                          const marks = Array.from({ length: 5 }, (_, i) => ({
                            value: min_score_value + i * interval,
                            label: (
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  // marginLeft: "10px"
                                }}
                              >
                                <span>{String.fromCharCode(69 - i)}</span>{" "}
                                <span>{min_score_value + i * interval}</span>
                              </Box>
                            ),
                          }));
                          return (
                            <Box key={id} className="quiz-question-box">
                              <Box className="quiz-question">
                                <Box
                                  component="span"
                                  className="serial-no filled-question"
                                >
                                  {index + 1}. {title}
                                </Box>
                              </Box>
                              <Slider
                                value={answer}
                                onChange={(_, newValue) =>
                                  this.saveSlideAnswer(
                                    index,
                                    newValue as number
                                  )
                                }
                                min={min_score_value}
                                max={max_score_value}
                                step={1}
                                valueLabelDisplay="on"
                                marks={marks}
                                className="quiz-slider"
                                data-test-id="reTakeSlider-btn"
                              />
                            </Box>
                          );
                        }
                      )}
                    </Box>
                    <Box className="button-wrapper">
                      <Button
                        className={`secondary-btn ${classes.secondaryButton}`}
                        onClick={this.handleBackArrow}
                        data-test-id="backArrowTakeQuiz"
                      >
                        {this.t("Cancel")}
                      </Button>
                      <Button
                        onClick={this.handleRetakeQuizSubmission}
                        className={`primary-btn ${classes.primaryButton}`}
                        data-test-id="reTakequiz-btn"
                      >
                        {this.t("Save")}
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
}

export default withStyles(customStyles, { withTheme: true })(ReTakeQuizWeb);
//Customizable Area End
