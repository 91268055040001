Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContactsList";
exports.labelBodyText = "ContactsList Body";

exports.btnExampleTitle = "CLICK ME";

exports.methodGET = "GET";
exports.methodPOST = "POST";
exports.methodPUT = "PUT";
exports.methodPATCH = "PATCH";
exports.methodDELETE = "DELETE";

exports.viewContactsPath = "bx_block_contactslist/contacts";
exports.viewGroupPath = "bx_block_contactslist/groups";

exports.RowsPerPage = 10;

exports.createContactApiPath = "bx_block_contactslist/contacts";
exports.groups = "bx_block_contactslist/groups";
exports.baseConfig = require("../../../framework/src/config");
exports.contactsPath = "bx_block_contactslist/contacts";
exports.notesEndpoint = "bx_block_contactslist/notes";
exports.addToGroup = "bx_block_contactslist/add_to_group";
exports.webDownloadCsvTemplateEndPoint ="/bx_block_contactslist/download_csv"
exports.searchApiPath = "bx_block_contactslist/search";
exports.businessCategory ="bx_block_contactslist/business_categories";
exports.searchApiGroup="bx_block_contactslist/groups";
exports.sortApiGroup ="bx_block_contactslist/sort";
exports.undoActionEndPint="bx_block_contactslist/undo";
exports.mergeContcatApiPath="bx_block_contactslist/merge_contact";
exports.mergeContactUndo="/account_block/undo";
exports.removeContactPath="bx_block_contactslist/remove_contact";
exports.brandEndPoint = "/bx_block_catalogue/brands";
exports.importContactApiPath = "/bx_block_contactslist/import_contacts";
exports.nonExistingGroupPath="/bx_block_contactslist/nonexistent_contact_group";
exports.AddExistingContactPath="/bx_block_contactslist/add_contacts"
exports.dealOwnersPath = "/account_block/member_list";
exports.createDealsEndpoint = "/bx_block_contactslist/deals";
exports.viewDealsEndpoint ="/bx_block_contactslist/deals?sort_by=DATE_CREATED";
exports.updateDealStatus = "/bx_block_contactslist/change_status?";
exports.webGetCountryList = "account_block/country";
exports.webGetCityList = "account_block/city";
exports.webGetStateList = "account_block/state";
exports.favProductsEndPoint = "/bx_block_contactslist/product_list";
exports.removeFavProductContactsPath="/bx_block_contactslist/remove_fav_product";
exports.undoFavProductPath="/bx_block_contactslist/undo_remove_fav_product";
exports.addAsFavPRoductAPiEndpoint="/bx_block_contactslist/add_favourite_products";
exports.bussinessCategoryApiPath="/bx_block_contactslist/business_categories"
exports.searchTxtType="Start typing to search...";
exports.group="Group";
exports.company="Company";
exports.businessCategorytxt = "Business Category";
exports.defaultPlacehoder ="Start typing category title";
exports.NoBusinessCategoriesAvailable = "No business categories available"
exports.shippingCountry="Shipping Country";
exports.more="More";
exports.openDeal="Has an Open Deal";
exports.openInteraction="Has Interactions";
exports.openFavProduct="Has Favorite Products";
exports.hasNote = "Has Notes";
exports.columns="Columns";
exports.columnsType="Search Columns Type";
exports.name="Name";
exports.email="Email";
exports.favProduct="Favorite Products";
exports.interactions="Interactions";
exports.openDealTxt="Open Deal";
exports.action="Actions";
exports.view="View";
exports.notFoundTxt="No contacts found.";
exports.contactNotCreated = "No contacts created";
exports.contactDefaultTxt ="Please create a contact to see here.";
exports.newContact="New Contact";
exports.chooseContact="Choose Contact";
exports.selectedMerge="Selected contact will merge with";
exports.continue="Continue";
exports.createContact="Create Contact";
exports.createContactSubTxt="Please fill in the information below.";
exports.addManually="Add Manually";
exports.bulkUpload="Bulk Upload";
exports.addToGroupTxt="Add to Group";
exports.chooseGroup="Choose Group";
exports.searchTxt="Search here";
exports.newGroupTxt="New Group";
exports.cancel="Cancel";
exports.create="Create";
exports.personalInfoTxt="Personal Information";
exports.firstName="First Name";
exports.lastName="Last Name";
exports.phone="Phone";
exports.businessInformation="Business Information";
exports.companyName="Company Name";
exports.vatNumber="VAT Number";
exports.billingAddressTxt="Billing Address";
exports.country="Country";
exports.state="State";
exports.city="City";
exports.pinCode="Postal Code";
exports.address1="Address 1";
exports.address2="Address 2";
exports.shippingAddress="Shipping Address";
exports.sameAddressTxt="Same as billing address";
exports.contactAgreesMarketingTxt="This contact agrees to be contacted for commercial and marketing purpose.";
exports.notes="Notes";
exports.typeHere="Type here...";
exports.favProducts="Favorite Products";
exports.totalAmmount="Total Amount";
exports.businessShippingInfoTxt="Business & Shipping Info";
exports.addNotes="Add Notes";
exports.addFavProductTxt="Add Favorite Product";
exports.addGroupSubText="Choose the contact you want to merge with this contact";
exports.chooseGroupSubTextNew="Choose the group to add this contact to";
exports.deals="Deals";
exports.outreach="Outreach";
exports.groupsTxt="Groups";
exports.createdDate="Created Date";
exports.vatNumberTxt="Vat Number";
exports.bCategoryinSmall="Business category";
exports.attachments="Attachments";
exports.dragDropFiles="Click here to";
exports.browseFiles ="browse";
exports.yourFiles="your files";
exports.createDeal="Create Deal";
exports.dealsSubText="Enter all the details of this deal.";
exports.dealName="Deal Name";
exports.expectedCloseDate="Expected Close Date";
exports.stagetxt="Stage";
exports.description="Description";
exports.dealOwner="Deal Owner";
exports.dealOwnerSubTxt="Choose deal owner you want to add to this deal.";
exports.searchOwner="Search Owner";
exports.selectedOwner="Selected Owner";
exports.unassigned="Unassigned";
exports.wineContainerSell="Wine Container Sell";
exports.outreaches="Outreaches";
exports.addNote="Add Note";
exports.save="Save";
exports.edit="Edit";
exports.delete="Delete";
exports.favoriteProducts ="Favorite Products";
exports.deleteContactTxt="Delete Contact";
exports.deleteGroupTxt="Delete Group";
exports.deleteTxtMsgGroup="Are you sure you want to delete this group?";
exports.deleteTxtMsgContact="Are you sure you want to delete this contact?"
exports.deleteNote="Delete Note";
exports.deleteNoteConfirmationTxt="Are you sure you want to delete this note?";
exports.changeStatus="Change Status";
exports.addANoteTxt ="Add a note";
exports.update="Update";
exports.removeFromGroupTxt="Remove from Group";
exports.remove="Remove";
exports.noContactCreatedTxt="No contacts created";
exports.createContactDefaultMsg="Please create a contact to see here.";
exports.addContact="Add Contact";
exports.addAnExistingContactTxt="Add an Existing Contact";
exports.addNewContactTxt="Add a New Contact";
exports.addAnExistingSubText="Choose contacts you want to add to this group.";
exports.back="Back";
exports.addToAnotherGroup="Add to another group";
exports.removeFromGroup="Remove from group";
exports.deleteContact="Delete contact";
exports.createDeal="Create deal";
exports.editContact="Edit Contact";
exports.rename="Rename";
exports.groupNotFound="No groups created";
exports.groupDefaultMsg="Please create a group to see here.";
exports.createGroup="Create Group";
exports.letsCreateGroupTxt ="Let’s create a group.";
exports.groupName ="Group Name";
exports.chooseContact= "Choose Contact";
exports.contactSelected= "{{count}} Contact Selected";
exports.contactsSelected= "{{count}} Contacts Selected";
exports.renameGroupText="Rename Group";
exports.chooseContactInGroup="Choose contacts you want to add to this group.";
exports.selectContacts="Selected Contacts";
exports.removeAllContacts="Remove all Contacts";
exports.contacts="Contacts";
exports.contact="Contact";
exports.saveGroup="Save Group";
exports.editSubTxt="Below are the contacts in this group.";
exports.editGroupTitle="Edit Group";
exports.bulkUploadTxt="Bulk upload";
exports.preview="Preview";
exports.finish="Finish";
exports.noGroupFound="No groups found";
exports.groupText="group found";
exports.groupsText="groups found";
exports.contactText="contact found";
exports.contactsText="contacts found";
exports.bulkUploadSubText=" Download the template, fill it out, then upload the completed CSV file";
exports.fileDrop="Drop files here to upload";
exports.or="or";
exports.chooseFile="to choose a file";
exports.uploading="Uploading";
exports.secondsRemaining="Seconds remaining";
exports.downloadTemplet="Download Template";
exports.toMakeChangeTxt="To make any changes, reupload a new CSV file.";
exports.filters="Filters";
exports.reset="Reset";
exports.applyFilters="Apply Filters";
exports.reupload="Reupload";
exports.confirm="Confirm";
exports.allStepsCompleted="All steps completed - you're finished";
exports.emailCampaigns="Email Campaigns";
exports.sent="Sent";
exports.drafts="Drafts";
exports.scheduled="Scheduled";
exports.directEmails="Direct Emails";
exports.chooseDealSubTitle="Choose contacts you want to add to this deal.";
exports.emailRegex = /^[A-Z0-9](?!.*\.\.)[A-Z0-9._%+-]*@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
exports.sortByTxt="Sort by";
exports.phoneRegex = /^(?:\d{7,15})$/;
exports.vatNumberRegex = /^[0-9]{1,15}$/;
exports.aToZTxt="A to Z";
exports.zToATxt="Z to A";
exports.aDashZTxt="A-Z";
exports.zDashATxt="Z-A";
exports.firstNameTxt="First Name";
exports.lastNameTxt="Last Name";
exports.recentlyAddedTxt="Rrecently Added";
exports.somethingWentsWrongErrorTxt="Something went wrong";
exports.contactCreateSuccessMsg="Contact creadted successfully!";
exports.emailErrorMsg="Email should be valid";
exports.phoneNumberErrorMsg="Please enter a valid phone number between 7 and 15 digits.";
exports.vatNumberErrorMsg="Invalid VAT number. Please enter up to 15 digits.";
exports.firstNameBlankMsg="First name should not be blank";
exports.lastNameBlankMsg="Last name should not be blank";
exports.groupNameAlreadyExistErrorMsg="Group name already exists";
exports.groupNameEmptyErrorMsg="Group name should not be blank";
exports.totalAmmount="Total Amount";
exports.chooseItemHeading = "Choose Items";
exports.chooseItemSubHeading = "Select products to mark as this contact’s favorites.";
exports.associatedFilters = "Filters";
exports.contactFavclearTxt = "Clear all";
exports.ShowAsOption1 = "Retail Price";
exports.ShowAsOption2 = "Wholesale Price";
exports.ShowAsOption3 = "Custom Price";
exports.itemSelectedTxt="Item Selected";
exports.itemsSelectedTxt="Items Selected";
exports.itemSummaryTxt="Item Summary";
exports.itemsSummaryTxt="Items Summary";
exports.subTxtForSummary="The following items will be added the email.";
exports.addToContactBtnTxt="Add to Contact";
exports.searchSidbarTxt="Search";
exports.ProductsTxt="Products";
exports.ProductTxt="Product";
exports.aboutTxt="About";
exports.contactTxt= "{{count}} Contact";
exports.contactsTxt= "{{count}} Contacts";
exports.groupCountTxt= "{{count}} Group";
exports.groupsCountTxt= "{{count}} Groups";
exports.selectedItemModelTxt="The following items will be added the contact.";
exports.ProducerTxt="Producer";
exports.brandTxt="Brand";
exports.colorTxt="Color";
exports.AppellationTxt="Appellation";
exports.GrapeVarietyTxt="Grape Variety";
exports.VintageTxt="Vintage";
exports.itemTxt="Items";
exports.itemText="Item";
exports.instockTxt="In Stock";
exports.mergeHeadingTxt="Choose the information you want to keep";
exports.mergeContactBtnTxt="Merge Contact";
exports.noteTxt= "{{count}} Note";
exports.notesTxt= "{{count}} Notes";
exports.favProsuctTxt= "{{count}} Favorite Product";
exports.favProsuctsTxt= "{{count}} Favorite Products";
exports.dealTxt= "{{count}} Deal";
exports.dealsTxt= "{{count}} Deals";
exports.addNewCategory="Add New Category";
exports.noCategoryFound="No category found with";
exports.noCategoryCreated="No category to create";
exports.deleteBusinessCategory="Are you sure you want to delete {{categoryName}} category?";
exports.delete = "Delete";
exports.deleteTitle="Remove Category";
exports.txtCancel="Cancel";
exports.errorMsgForBussinesCategory="Business category already exists";
exports.deleteBCategoryTxtMsg="Business Category deleted successfully";
exports.newestToOldest="Newest to oldest";
exports.oldestToNewest="Oldest to newest";
exports.errorMessageChar= "Group name should not exceed 50 characters";
// Customizable Area End