// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { CardMedia } from "@material-ui/core";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "../ContactsListStyles.web";
import { toast } from "react-toastify";
import i18n from "../../../../web/src/utilities/i18n";
import { multipleAccount, warningIcon } from "../assets";

export const configJSON = require("../config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation: any;
  id: string;
  classes: any;
  handleCloseDialog: any;
  handleMinimizeCreateGroup?: () => void;
}

interface S {
 groupNameInput:string;
 error: {
  groupNameInput: string;  
};
contactId:string;
showGroupsContact:boolean;
searchContactKeyword: string;
contactListData:any;
isContactSearching:boolean;
isContactSorting:boolean;
isLoading:boolean;
searchedContacts:any;
sortContactValue:string;
sortedContacts:any;
createGroupId:string;
}

interface SS {
  id: any;
}

export default class CreateGroupController extends BlockComponent<
  Props,
  S,
  SS
> {
  userSessionData: any;
  userToken: any;
  createGroupRequestId:string="";
  getSearchContcatListRequestApiId:string="";
  getSortRequestApiId:string="";
  getContactlistApiRequestID:string="";
  getGroupListRequestApiId:string="";
  undoActionRequestApiId:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];
    this.state = {
      groupNameInput:"",
      error: {
        groupNameInput:"", 
      },
      contactId: "",
      showGroupsContact:false,
      searchContactKeyword: "",
      contactListData:[],
      isContactSearching:false,
      isContactSorting:false,
      isLoading:false,
      searchedContacts:[],
      sortContactValue:"",
      sortedContacts:[],
      createGroupId:""
     };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {    
    const lang = localStorage.getItem("lang") || "en"; 
    await i18n.changeLanguage(lang); 
    this.getAllContacts()
  } 
  
  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.clearError(prevState)    
  }
 
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
     let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.error) {
        this.createToastNotification("Something went wrong");
      } else if (apiRequestCallId === this.createGroupRequestId){
        this.createGroupResponse(responseJson);
      }else if (apiRequestCallId === this.getContactlistApiRequestID){
        this.getContactResponse(responseJson);
      }else if(apiRequestCallId ===this.getSearchContcatListRequestApiId){
        this.setState({isLoading: false})
        this.setState({searchedContacts:responseJson.data})
      }else if(apiRequestCallId ===this.getSortRequestApiId){
        this.setState({isLoading: false})
        this.setState({sortedContacts:responseJson.data})
      }
    }
  }
  t(key:any) {
    return i18n.t(key, { ns: "translation" } )
  }
  getContactResponse = (response: any) => {
    this.setState({contactListData:response.data});
  }   
  handleChangeGroupName = (text: string) => {
    this.setState({ groupNameInput: text });
  };
  handleChangeContact = (contactId: string) => {
    this.setState({ contactId });
  };
  createGroupResponse = (response: any) => {
    if(response.errors){
      this.setState({ error: { groupNameInput: "Group name already exists" } });
    }
    if(response.data){
      this.setState({createGroupId:response.data.attributes.id})
      this.createToastNotificationSuccess("Group Created",multipleAccount,'create')
      this.props.handleCloseDialog()
      this.getGroupList()
      localStorage.removeItem("contactIds")
    }
  }
  clearError = (prevState: S) => {
    if (prevState.groupNameInput !== this.state.groupNameInput) {
      this.setState((prevState) => ({
        error: {
          ...prevState.error,
          groupNameInput: "", 
        },
      }));
    }   
  }
  handleOpenGroupContact = () => {
    this.setState({ showGroupsContact: true });
  };

  handleCloseGroupContact = () => {
    this.setState({ showGroupsContact: false });
  };

  createGroupEvent = () => {
    let error = this.state.error;
    let hasError = false;
    if (this.state.groupNameInput.trim() === "") {
      error.groupNameInput = "Group name should not be blank"; 
      this.setState({ error: error });
      hasError = true;
    }
    if (hasError) {
      return;
    }
    const retrievedArrays = JSON.parse(localStorage.getItem("contactIds") ?? '[]');

    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };

    const body = {
      group :{
        name:this.state.groupNameInput,
        contact_ids: retrievedArrays,
   }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createGroupRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewGroupPath
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createToastNotification = (toastMesssage: string) => {
    toast.error(this.t(`${toastMesssage}`), {
      position: toast.POSITION.TOP_CENTER,
      icon: () => (
        <CardMedia component="img" src={warningIcon} alt="emptydata" />
      ),
    });
  };

 createToastNotificationSuccess = (toastMesssagee: string, toastIconI: string, actionTypes:string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{this.t(`${toastMesssagee}`)}</div>{" "}
        <a href="#" data-test-id="undoApiId" className="toast-link" onClick={()=>this.undoCreateAPI(actionTypes)}>
          {this.t("undo")}
        </a>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        icon: () => <img src={toastIconI} alt="emptydata" />,
      }
    );
  };
  handleSearchContact = (text: string) => {
    this.setState({ searchContactKeyword: text, isContactSearching:true  },()=>{
      this.getContactsSearchtList()
    });
  };
  handleSortContact= (text: string) => {
    this.setState({ sortContactValue: text, isContactSorting:true }, () => {
      this.getSortContacts(); 
    });
  };
  getAllContacts = () => {
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactlistApiRequestID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.contactsPath}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getContactsSearchtList = () => {
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json"
    };
  let endPoint =`${configJSON.searchApiPath}?search_term=${this.state.searchContactKeyword}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getSearchContcatListRequestApiId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint,
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({isLoading: true});
  };
  getSortContacts = () => {
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json"
    };

   let endPoint=`${configJSON.viewContactsPath}?first_name=${this.state.sortContactValue == "A-Z"?"asc":"desc"}`;
   
  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getSortRequestApiId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint,
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({isLoading: true});
  };
  getGroupList = () => {
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json"
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupListRequestApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewGroupPath
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  undoCreateAPI = (actionTypeC:string) => {    
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };
  
    const body = {
      "id": this.state.createGroupId,
      "action_type": actionTypeC
    }

   let endPoint = `${configJSON.undoActionEndPint}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.undoActionRequestApiId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint,
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
      );
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }
}
// Customizable Area End
