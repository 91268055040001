// Customizable Area Start
import React from "react";
import {
  Box,
  Modal,
  Button,
  Typography,
  CardMedia,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ListSubheader,
  InputAdornment,
  ListItemText,
} from "@material-ui/core/";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

import { checkBoxEmpty, checkBoxFilled, editContactAvatarRound } from "./assets";
import { withStyles } from "@material-ui/core/styles";
import EditContactController, { Props ,configJSON } from "./EditContactController.web";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import { customStyles } from "./ContactsListStyles.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FreeSoloCreateOption from "../../../components/src/FreeSoloCreateOption.web";
import ConfirmActionModal from "../../email-account-registration/src/teams-web/ConfirmActionModal.web";
import { restrictKeys } from "../../../components/src/ReusableFunctions";

class EditContact extends EditContactController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      classes,
      isShowEditContact,
      hancleCloseEditContact,
      viewSingleDetails
    } = this.props;
    
    let groupsData;

    if(this.state.isSearching){
      groupsData = this.state.searchedGroup
    }else{
      groupsData = this.state.groups
    }
    return (
      <Modal
        open={isShowEditContact}
        onClose={hancleCloseEditContact}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
      >
        <Box className={classes.editContactModal}>
          <Box className="heading-bar">
            <Typography className="heading-title">{this.t(`${configJSON.editContact}`)}</Typography>
            <CloseIcon
              className="close-icon"
              onClick={hancleCloseEditContact}
            />
          </Box>
          <Box className="heading-avatar">
            <Box className="wrap-avatar">
              <CardMedia
                component="img"
                className="avatar-bg"
                src={editContactAvatarRound}
              />
              <Typography className="shortcut-name">
              {viewSingleDetails?.attributes?.first_name.charAt(0).toUpperCase()}
              {viewSingleDetails?.attributes?.last_name.charAt(0).toUpperCase()}
              </Typography>
            </Box>
          </Box>
            <Box className="profile-name">
             {viewSingleDetails?.attributes?.first_name} {viewSingleDetails?.attributes?.last_name}
            </Box>
            <Box className="profile-email">{viewSingleDetails?.attributes?.email}</Box>
          <Box className="info-group">
            <Typography className="group-title">
              {this.t(`${configJSON.personalInfoTxt}`)}
            </Typography>
            <Box className="group-form" component="form">
              <Box className="form-info-wrapper">
                <Box className="form-row">
                  <Box className="form-col ">
                    <Box className="form-control">
                      <CustomInputWeb
                        type="text"
                        label={this.t(`${configJSON.firstName}`)}
                        value={this.state.firstName}
                        name="firstName"
                        onChange={this.handleInputChange}
                        errors={this.state.error.firstName}
                        data-test-id="FirstName"
                        isRequired={true}
                      />
                    </Box>
                  </Box>
                  <Box className="form-col">
                    <Box className="form-control">
                      <CustomInputWeb
                        type="text"
                        label={this.t(`${configJSON.lastName}`)}
                        value={this.state.lastName}
                        name="lastName"
                        onChange={this.handleInputChange}
                        errors={this.state.error.lastName}
                        data-test-id="LastName"
                        isRequired={true}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className="form-row">
                  <Box className="form-col">
                    <CustomInputWeb
                      type="number"
                      label={this.t(`${configJSON.phone}`)}
                      value={this.state.phone}
                      name="phone"
                      onChange={this.handleInputChange}
                      errors={this.state.error.phone}
                      data-test-id="Phone" 
                      onKeyDown={(e) => restrictKeys(e)}
                    />
                  </Box>
                </Box>
                <Box className="form-row">
                  <Box className="form-col">
                    <CustomInputWeb
                      type="email"
                      label={this.t(`${configJSON.email}`)}
                      value={this.state.email}
                      name="email"
                      onChange={this.handleInputChange}
                      errors={this.state.error.email}
                      rows={4}
                      data-test-id="Email"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="info-group">
            <Typography className="group-title">{this.t(`${configJSON.groupsTxt}`)}</Typography>
            <Box className="group-form" component="form">
              <Box className="form-info-wrapper">
                <Box className="form-row">
                  <Box className="form-col">
                     <FormControl
                      className="select-outer" 
                      variant="outlined" 
                      >                   
                    <InputLabel>
                    {this.t(`${configJSON.group}`)}
                    </InputLabel>
                  <Select
                    labelId="mutiple-checkbox-label"
                    id="mutiple-checkbox"
                    multiple
                    label={this.t(`${configJSON.group}`)}
                    defaultValue={[]}
                    value={this.state.selectedOptions}
                    onChange={(event:any) =>
                      this.saveSelectedOptions(event,"selectedOptions")
                    }
                    renderValue={() =>
                      this.getLabels(
                        this.state.selectedOptions ?? [],
                        "Choose Group"
                      )
                    }
                    data-test-id="ChooseGroup"
                    inputProps={{ IconComponent: () => null }}
                    MenuProps={{
                      anchorOrigin: {
                        horizontal: "left",
                        vertical: "top",
                      },
                      transformOrigin: {
                        horizontal: "left",
                        vertical: "bottom",
                      },
                      classes: { paper: `${classes.dropdownStyle} multiSelect` },
                      getContentAnchorEl: null,
                    }}
                    displayEmpty
                    endAdornment={<SearchIcon />}
                  >
                    <ListSubheader>
                      <CustomInputWeb
                        fullWidth={true}
                        placeholder={this.t(`${configJSON.searchTxt}`)}
                        value={this.state.searchTerm}
                        autoFocus={true}
                        onChange={(e:any) => this.handleGroupsSearch(e.target.value)}
                        onKeyDown={(e:any) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                        data-test-id="filterSearch"
                      />                       
                      {this.state.isNewGroup && 
                            <div className={classes.addOptionWrapper}>
                            <CustomInputWeb
                              name="newGroup"
                              value={this.state.newGroup}
                              onChange={this.handleInputChange}
                              type="text"
                              errors={this.state.error.newGroup}
                              onKeyDown={(e:any) => {
                                if (e.key !== "Escape") {
                                  e.stopPropagation();
                                }
                              }}                
                              placeholder={this.t(`${configJSON.newGroupTxt}`)} 
                              data-test-id="handleGroupAdd" 
                      />
                        <Box className="button-wrapper">
                        <Button className="add-link secondary-link" data-test-id="handleClose" onClick={this.handleCloseNewGroupAction}>
                        {this.t(`${configJSON.cancel}`)} 
                        </Button>
                        <Button className="add-link"  data-test-id="createGroup" onClick={this.createGroupAction}> 
                        {this.t(`${configJSON.create}`)}
                        </Button>
                 </Box>
               </div>
                }
                    </ListSubheader>
                    {groupsData.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        <ListItemText >
                          {item.attributes.name}
                        </ListItemText>
                        <Box className="multiselect-squareCheck">
                          <img
                            src={checkBoxEmpty}
                            className="normal-img"
                            alt="checkBoxEmpty"
                          />
                          <img
                            src={checkBoxFilled}
                            className="selected-img"
                            alt="checkBoxFilled"
                          />
                        </Box>
                      </MenuItem>
                    ))}
           
                   <Button className="new-group" data-test-id="new-group" onClick={this.handleNewGroupAction} >{this.t(`${configJSON.newGroupTxt}`)}</Button>
                  </Select>
                     </FormControl>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="info-group">
            <Typography className="group-title">
              {this.t(`${configJSON.businessInformation}`)}
            </Typography>
            <Box className="group-form" component="form">
              <Box className="form-info-wrapper">
                <Box className="form-row">
                  <Box className="form-col">
                    <CustomInputWeb
                      type="text"
                      label= {this.t(`${configJSON.companyName}`)}
                      value={this.state.companyName}
                      name="companyName"
                      onChange={this.handleInputChange}
                      errors={""}
                      rows={4}
                      data-test-id="CompanyName"
                    />
                  </Box>
                </Box>
                <Box className="form-row">
                  <Box className="form-col">
                    <CustomInputWeb
                      type="number"
                      label={this.t(`${configJSON.vatNumber}`)}
                      value={this.state.vatNumber}
                      name="vatNumber"
                      onChange={this.handleInputChange}
                      errors={this.state.error.vatNumber}                      
                      data-test-id="VatNumber"
                      onKeyDown={(e) => restrictKeys(e)}
                    />
                  </Box>
                </Box>
                <Box className="form-row">
                  <Box className="form-col">                     
                       <FreeSoloCreateOption
                        options={this.state.businessCategorys.map(
                          (val: any) => {
                            return {
                              id: val.id,
                              title: val.name,
                            };
                          }
                        )}
                        addEditCallback={this.addBCategoryEvent}
                        noOptionTitle={this.t(`${configJSON.noCategoryFound}`)}
                        addLinkTitle={this.t(`${configJSON.addNewCategory}`)}
                        id="b-id"
                        label={this.t(`${configJSON.businessCategorytxt}`)}
                        value={this.state.businessCategory}
                        onChange={this.categoryChangeEvent}
                        deleteCallback={this.deleteBCategoryE}
                        errors={this.state.error.errorbusinessCategory}
                        errorMsgNoRecord={this.t(
                          `${configJSON.noCategoryCreated}`
                        )}
                        data-test-id="bussines-category"
                        placeHolder={this.t(`${configJSON.defaultPlacehoder}`)}
                        notRequired
                      />
                      {this.state.isDeleting && (
                        <ConfirmActionModal
                          isOpen={this.state.isDeleting}
                          modalConfirmAction={this.deleteCategoryFromList}
                          handleClose={this.handleCloseCategory}
                          modalMessage= {this.t(configJSON.deleteBusinessCategory, { categoryName: this.state.deleteTitle })}
                          deleteTeamId={this.state.deleteBCategoryId}
                          modalHeading={this.t(`${configJSON.deleteTitle}`)}
                          confirmBtnTxt={this.t(`${configJSON.delete}`)}
                          data-testid="confirmDeleteCategory"
                          cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
                        />
                      )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
					<Box className="info-group">
            <Typography className="group-title shipping-address-txt">
               {this.t(`${configJSON.billingAddressTxt}`)}
            </Typography>
            <Box className="group-form" component="form">
              <Box className="form-info-wrapper">
                <Box className="form-row">
                  <Box className="form-col">
                     <FormControl 
                        className="select-outer" 
                        variant="outlined"               
                        >
                        <InputLabel>
                        {this.t(`${configJSON.country}`)}
                        </InputLabel>
                        <Select
                         value={this.state.country1}
                         name="country1"
                         onChange={this.handleInputChange}
                         IconComponent={(props) => (
                           <ExpandMoreIcon {...props} />
                         )}
                          label={this.t(`${configJSON.country}`)}
                          MenuProps={{
                            anchorOrigin: {
                              horizontal: "left",
                              vertical: "bottom",
                            },
                            transformOrigin: {
                              horizontal: "left",
                              vertical: "top",
                            },
                            getContentAnchorEl: null,
                            classes: { paper: classes.dropdownStyle },
                          }}
                          data-test-id="Country"
                        >
                          {this.state.countryList.map((data:any)=>{
                            return(
                              <MenuItem value={data.country_name} key={data.id}>{data.country_name}</MenuItem>
                            )
                          })} 
                        </Select>                
                      </FormControl>
                  </Box>
                </Box>
                <Box className="form-row">
                  <Box className="form-col col6">
                      <Box className="form-control right-8">
                       <FormControl 
                        variant="outlined"               
                        className="select-outer" 
                        >
                        <InputLabel>
                        {this.t(`${configJSON.state}`)}
                        </InputLabel>
                        <Select
                         label={this.t(`${configJSON.state}`)}
                         value={this.state.state1}
                         onChange={this.handleInputChange}
                         name="state1"
                          IconComponent={(props) => (
                            <ExpandMoreIcon {...props} />
                          )}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            classes: { paper: classes.dropdownStyle },
                          }}
                          data-test-id="State"
                          disabled={this.state.country1 === ""}
                        >
                          {this.state.stateList.map((item:any)=>{
                            return(
                              <MenuItem value={item.state_name} key={item.id}>{item.state_name}</MenuItem>
                            )
                          })}            
                          
                        </Select>                
                      </FormControl>
                    </Box>
                  </Box>
                  <Box className="form-col col6">
                    <Box className="form-control right-8">
                       <FormControl 
                        variant="outlined"               
                        className="select-outer" 
                        >
                        <InputLabel>
                        {this.t(`${configJSON.city}`)}
                        </InputLabel>
                        <Select
                         label={this.t(`${configJSON.city}`)}
                         value={this.state.city1}
                         onChange={this.handleInputChange}
                         name="city1"
                          IconComponent={(props) => (
                            <ExpandMoreIcon {...props} />
                          )}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            classes: { paper: classes.dropdownStyle },
                          }}
                          data-test-id="City"
                          disabled={this.state.country1 === "" || this.state.state1 === "" }
                        >
                          {this.state.cityList.map((item:any)=>{
                            return(
                              <MenuItem value={item.city_name} key={item.id}>{item.city_name}</MenuItem>
                            )
                          })}             
                          
                        </Select>                
                      </FormControl>
                    </Box>
                  </Box>
                </Box> 
                        
                <Box className="form-row">
                  <Box className="form-col">
                    <Box className="form-control">
                      <CustomInputWeb
                        type="number"
                        label={this.t(`${configJSON.pinCode}`)}
                        value={this.state.postalCode}
                        name="postalCode"
                        onChange={this.handleInputChange}
                        errors={""}
                        data-test-id="PostalCode"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className="form-row">
                  <Box className="form-col">
                    <CustomInputWeb
                      type="text"
                      label={this.t(`${configJSON.address1}`)}
                      value={this.state.address1}
                      name="address1"
                      onChange={this.handleInputChange}
                      errors={""}
                      rows={4}
                      data-test-id="Address1"
                    />
                  </Box>
                </Box>
                <Box className="form-row">
                  <Box className="form-col">
                    <CustomInputWeb
                      type="text"
                      label={this.t(`${configJSON.address2}`)}
                      value={this.state.address2}
                      name="address2"
                      onChange={this.handleInputChange}
                      errors={""}
                      rows={4}
                      data-test-id="Address2"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="info-group">
            <Typography className="group-title shipping-address-txt">
              {this.t(`${configJSON.shippingAddress}`)}
            </Typography>
            <Typography className="group-sub-title">
             {this.t(`${configJSON.sameAddressTxt}`)}
             
            <Checkbox className="shipping-address-chkbox" checked={this.state.checked} onChange={this.handleChecked} data-test-id="checkbox" name="checked" />
            </Typography>
            {this.state.checked === false&&(
            <Box className="group-form" component="form">
              <Box className="form-info-wrapper">
                <Box className="form-row">
                  <Box className="form-col">
                    <FormControl 
                        className="select-outer" 
                        variant="outlined"               
                        >
                        <InputLabel>
                        {this.t(`${configJSON.country}`)}
                        </InputLabel>
                        <Select
                          name="country2"
                          value={this.state.country2}
                         onChange={this.handleInputChange}
                         IconComponent={ExpandMoreIcon}
                          label={this.t(`${configJSON.country}`)}
                          MenuProps={{
                            anchorOrigin: {
                              horizontal: "left",
                              vertical: "bottom",
                            },
                            transformOrigin: {
                              horizontal: "left",
                              vertical: "top",
                            },
                            getContentAnchorEl: null,
                            classes: { paper: classes.dropdownStyle },
                          }}
                          data-test-id="Country2"
                        >
                          {this.state.countryList.map((unit:any)=>{
                            return(
                              <MenuItem value={unit.country_name} key={unit.id}>{unit.country_name}</MenuItem>
                            )
                          })} 
                        </Select>                
                      </FormControl>
                  </Box>
                </Box>
                <Box className="form-row">
                  <Box className="form-col col6">
                    <Box className="form-control right-8">
                       <FormControl 
                        className="select-outer" 
                        variant="outlined"               
                        >
                        <InputLabel>
                        {this.t(`${configJSON.state}`)}
                        </InputLabel>
                        <Select
                         label={this.t(`${configJSON.state}`)}
                         name="state2"
                         value={this.state.state2}
                         onChange={this.handleInputChange}
                         IconComponent={ExpandMoreIcon}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            classes: { paper: classes.dropdownStyle },
                          }}
                          data-test-id="State2"
                          disabled={this.state.country2 === ""}
                        >
                          {this.state.stateList.map((unit:any)=>{
                            return(
                              <MenuItem value={unit.state_name} key={unit.id}>{unit.state_name}</MenuItem>
                            )
                          })}  
                        </Select>                
                      </FormControl>
                    </Box>
                  </Box>
                  <Box className="form-col col6">
                    <Box className="form-control right-8">
                       <FormControl 
                        className="select-outer" 
                        variant="outlined"               
                        >
                        <InputLabel>
                        {this.t(`${configJSON.city}`)}
                        </InputLabel>
                        <Select
                         label={this.t(`${configJSON.city}`)}
                         name="city2"
                         value={this.state.city2}
                         onChange={this.handleInputChange}
                         IconComponent={ExpandMoreIcon}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            classes: { paper: classes.dropdownStyle },
                          }}
                          data-test-id="State2"
                          disabled={this.state.country2 === "" || this.state.state2 === "" }
                        >
                          {this.state.cityList.map((unit:any)=>{
                            return(
                              <MenuItem value={unit.city_name} key={unit.id}>{unit.city_name}</MenuItem>
                            )
                          })}            
                          
                        </Select>                
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
                  <Box className="form-col col6">
                    <Box className="form-control left-8">
                      <CustomInputWeb
                        type="number"
                        label={this.t(`${configJSON.pinCode}`)}
                        value={this.state.postalCode2}
                        name="postalCode2"
                        onChange={this.handleInputChange}
                        errors={""}
                        data-test-id="PostalCode2"
                      />
                    </Box>
                  </Box>
                <Box className="form-row">
                  <Box className="form-col">
                    <CustomInputWeb
                      type="text"
                      label={this.t(`${configJSON.address1}`)}
                      value={this.state.address11}
                      name="address11"
                      onChange={this.handleInputChange}
                      errors={""}
                      rows={4}
                      data-test-id="Address11"
                    />
                  </Box>
                </Box>
                <Box className="form-row">
                  <Box className="form-col">
                    <CustomInputWeb
                      type="text"
                      label={this.t(`${configJSON.address2}`)}
                      value={this.state.address12}
                      name="address12"
                      onChange={this.handleInputChange}
                      errors={""}
                      rows={4}
                      data-test-id="Address12"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            )}
          </Box>
          <Button onClick={()=>this.editContact(viewSingleDetails?.attributes?.id)} data-test-id="editContactBtn" className="btn-save">{this.t(`${configJSON.save}`)}</Button>
        </Box>
      </Modal>
    );
  }
}

export default withStyles(customStyles, { withTheme: true })(EditContact);
// Customizable Area End
