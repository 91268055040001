Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ActivityLog";
exports.labelBodyText = "ActivityLog Body";
exports.projectLogEndPoint ="/account_block/project_index?";
exports.taskLogEndPoint ="/account_block/activitylogs?";
exports.dealsLogEndPoint ="/account_block/activitylogs_deal?";
exports.memberListEndPoint = "/account_block/member_list";
exports.activityLogsFilter = "/account_block/log_filter";
exports.downloadActivityLogCsv="/account_block/download_activitylogs.csv"
exports.activityLogsTxt="Activity Log";
exports.activityLogSubTxt="Currently shows 30 days logs.";
exports.filterTxt="Filters";
exports.aductActivityTxt ="Adjust activity log filters";
exports.dateRangeTxt="Date Range";
exports.membersTxt="Members";
exports.txtChooseDate = "Choose Date"
exports.methodGET ="GET";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End