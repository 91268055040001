import React from "react";

import {
  // Customizable Area Start
  Box,
  Grid,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CustomInput from "../../../components/src/CustomInput.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme
} from "@material-ui/core/styles";
// import { customStyles } from "../../../components/src/Inventory/TastingNotesStyles.web";
import { customStyles } from "../../../components/src/AddItemStyles.web";
// Customizable Area End

import TastingNotesController, {
  Props,
  configJSON,
} from "./TastingNotesController.web";

export class TastingNotes extends TastingNotesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes, seletedItem } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box className={classes.basicInfoSection}>
        <Box className="heading-box">
          <Box>
            <Typography className={classes.heading} variant="h1">
              {this.t(`${configJSON.tastingTitle}`)}
            </Typography>
            <Typography className={classes.subtitleText}>
              {this.t(`${configJSON.tastingSubtext.replace('%%', seletedItem.toLocaleLowerCase())}`)}
            </Typography>
          </Box>
          <Box className="item-box" data-testId="itemText">
            {seletedItem}
          </Box>
        </Box>
        <Grid className={classes.formSection} container alignItems="center" justifyContent="center">
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <CustomInput
              value={this.state.openingTime}
              onChange={(event) => this.handleopeningTime(event)}
              placeholder={this.t(`${configJSON.openingTimePlaceholder}`)}
              data-testId={'openingtimeField'}
              errors={this.state.openingTimeErr}
            />
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <CustomInput
              value={this.state.temprature}
              onChange={(event) => this.handletemp(event)}
              placeholder={this.t(`${configJSON.tempTitle}`)}
              data-testId={'temperatureField'}
            />
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <CustomInput
              value={this.state.shortDesc}
              onChange={(event) => this.handledescription(event)}
              placeholder={this.t(`${configJSON.tastingCommentPlaceholder}`)}
              data-testId={'descriptionField'}
              isMultiline={true}
              rows={5}
            />
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12} className="form-row-6">
            <Grid item md={6} lg={6} sm={6} xs={12}>
              <FormControl className="select-outer" variant="outlined">
                <InputLabel>
                  {this.t(`${configJSON.eyeLabel}`)}
                </InputLabel>
                <Select data-testId={"eyetastingField"}
                  label={this.t(`${configJSON.eyeLabel}`)}
                  value={this.state.eyeTasing}
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} />
                  )}
                  onChange={(event) => this.handleyeTasting(event)}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                >
                  {/* {this.state.eyeTastingData.map((data)=>{
                            return <MenuItem value={data.name} key={data.id} data-testId={`eyeDropdown-${data.id}`}>
                              {data.name}</MenuItem>
                          })} */}
                  {this.state.eyeTastingData
                    .sort((a, b) => a.name.localeCompare(b.name)) // Sort the eyeTastingData alphabetically by name
                    .map((data) => (
                      <MenuItem value={data.name} key={data.id} data-testId={`eyeDropdown-${data.id}`}>
                        {data.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} lg={6} sm={6} xs={12}>
              <FormControl className="select-outer" variant="outlined">
                <InputLabel>
                  {this.t(`${configJSON.noseLabel}`)}
                </InputLabel>
                <Select
                  data-testId={"nosetastingField"}
                  label={this.t(`${configJSON.noseLabel}`)}
                  value={this.state.noseTasing}
                  onChange={(event) => this.handlenoseTasting(event)}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} />
                  )}
                >
                  {/* {this.state.noseTastingData.map((data) => {
                    return <MenuItem value={data.name} key={data.id} data-testId={`noseDropdown-${data.id}`}>
                      {data.name}</MenuItem>
                  })} */}
                  {this.state.noseTastingData
                    .sort((a, b) => a.name.localeCompare(b.name)) // Sort the noseTastingData alphabetically by name
                    .map((data) => (
                      <MenuItem value={data.name} key={data.id} data-testId={`noseDropdown-${data.id}`}>
                        {data.name}
                      </MenuItem>
                    ))
                  }

                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12} className="form-row-6">
            {seletedItem === (configJSON.beerText) ?
              <Grid item md={6} lg={6} sm={6} xs={12}>
                <FormControl className="select-outer" variant="outlined">
                  <InputLabel>
                    {this.t(`${configJSON.flavoursLabel}`)}
                  </InputLabel>
                  <Select label={this.t(`${configJSON.flavoursLabel}`)}
                    data-testId={"flavoursField"}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} />
                    )}
                    value={this.state.flavours}
                    onChange={(event) => this.handleflavourTasting(event)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                    }}
                  >
                    {/* {this.state.flavoursData.map((data) => {
                      return <MenuItem value={data.name} key={data.id} data-testId={`flavoursDropdown-${data.id}`}>
                        {data.name}</MenuItem>
                    })} */}
                    {this.state.flavoursData
                      .sort((a, b) => a.name.localeCompare(b.name)) // Sort the flavoursData alphabetically by name
                      .map((data) => (
                        <MenuItem value={data.name} key={data.id} data-testId={`flavoursDropdown-${data.id}`}>
                          {data.name}
                        </MenuItem>
                      ))
                    }

                  </Select>
                </FormControl>
              </Grid>
              :
              <Grid item md={6} lg={6} sm={6} xs={12}>
                <FormControl className="select-outer" variant="outlined">
                  <InputLabel>
                    {this.t(`${configJSON.aromaLabel}`)}
                  </InputLabel>
                  <Select label={this.t(`${configJSON.aromaLabel}`)}
                    data-testId={"aromasField"}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} />
                    )}
                    value={this.state.aromas}
                    onChange={(event) => this.handlearomaTasting(event)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      classes: { paper: classes.dropdownStyle },
                    }}
                  >
                    {/* {this.state.aromasTastingData.map((data) => {
                      return <MenuItem value={data.name} key={data.id} data-testId={`aromasDropdown-${data.id}`}>
                        {data.name}</MenuItem>
                    })} */}
                    {this.state.aromasTastingData
                      .sort((a, b) => a.name.localeCompare(b.name)) // Sort the aromasTastingData alphabetically by name
                      .map((data) => (
                        <MenuItem value={data.name} key={data.id} data-testId={`aromasDropdown-${data.id}`}>
                          {data.name}
                        </MenuItem>
                      ))
                    }

                  </Select>
                </FormControl>
              </Grid>}
            <Grid item md={6} lg={6} sm={6} xs={12}>
              <FormControl className="select-outer" variant="outlined">
                <InputLabel>
                  {this.t(`${configJSON.mouthLabel}`)}
                </InputLabel>
                <Select
                  data-testId={"mouthtastingField"}
                  value={this.state.mouthTasting}
                  onChange={(event) => this.handlemouthTasing(event)}
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} />
                  )}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  label={this.t(`${configJSON.mouthLabel}`)}
                >
                  {/* {this.state.mouthTastingData.map((data) => {
                    return <MenuItem value={data.name} key={data.id} data-testId={`mouthDropdown-${data.id}`}>
                      {data.name}</MenuItem>
                  })} */}
                  {this.state.mouthTastingData
                    .sort((a, b) => a.name.localeCompare(b.name)) // Sort the mouthTastingData alphabetically by name
                    .map((data) => (
                      <MenuItem value={data.name} key={data.id} data-testId={`mouthDropdown-${data.id}`}>
                        {data.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <FormControl className="select-outer" variant="outlined">
              <InputLabel>
                {this.t(`${configJSON.foodLabel}`)}
              </InputLabel>
              <Select
                data-testId={"foodFeild"}
                IconComponent={(props) => (
                  <ExpandMoreIcon {...props} />
                )}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  classes: { paper: classes.dropdownStyle },
                }}
                value={this.state.foodPairing}
                onChange={(event) => this.handlefoodPairing(event)}
                label={this.t(`${configJSON.foodLabel}`)}
              >
                {/* {this.state.foodTastingData.map((data) => {
                  return <MenuItem value={data.name} key={data.id} data-testId={`foodDropdown-${data.id}`}>
                    {data.name}</MenuItem>
                })} */}
                {this.state.foodTastingData
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort the foodTastingData alphabetically by name
                  .map((data) => (
                    <MenuItem value={data.name} key={data.id} data-testId={`foodDropdown-${data.id}`}>
                      {data.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box className={classes.formButtons}>
          <Button className="backBtn" onClick={this.goBackProductStep}
            data-testId={"back-btn"}
          >
            {this.t(`${configJSON.backButton}`)}
          </Button>
          <Button className="next-step-btn" onClick={this.handletastingData}
            data-testId={"nextstep-btn"}
          > 
            {this.t(`${configJSON.nextButton}`)}
          </Button>
        </Box>  
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start


export default withStyles(customStyles, { withTheme: true })(TastingNotes);
// Customizable Area End
