// Customizable Area Start
import React from "react";
import { Box, Typography, Button, FormHelperText } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import { configJSON } from "../MemberMovetoTeamController";

interface MoveMemberTeamProps {
  classes: any;
  modalOpen: boolean;
  modalClose: () => void;
  moveMembertoTeam: () => void;
  addAnotherTeam: () => void;
  allStates: any;
  createTeamTitleBtn: string;
  chooseTeamError: string;
  teamExists: string;
  saveNewTeam: (event: any) => void;
  t:any;
}

const MoveMemberTeam = (props: MoveMemberTeamProps) => {
  const {
    classes,
    modalOpen,
    modalClose,
    moveMembertoTeam,
    addAnotherTeam,
    allStates,
    createTeamTitleBtn,
    chooseTeamError,
    saveNewTeam,
    teamExists,
    t
  } = props;

  return (
    <Modal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalDialog}
    >
      <Box className={classes.modalDialogBox}>
        <Box className="modal-heading">
          <Typography className="modal-title" component="h2">
            {t(`${configJSON.moveToTeamTxt}`)}
          </Typography>
          <Link to="#" className="close-icon" onClick={modalClose}>
            <CloseIcon />
          </Link>
        </Box>
        <Box className="modal-description">
          <Box component="p" className="sub-txt">
            {allStates.selected.length > 1
              ? t(configJSON.chooseTeamCountConfirmationTxt, { count: allStates.selected.length })
              : t(`${configJSON.chooseTeamToAddMemberTxt}`)}
          </Box>
          <Box className="modal-form" component="form">
            <Box className="form-info-wrapper">
              <Typography className="form-heading" variant="h2">
                {t(`${configJSON.currentTeamTxt}`)}
              </Typography>
              <Box className="current-team">
                {allStates.moveTeamData.currentTeams === undefined
                  ? t(`${configJSON.unassignedMembersTxt}`)
                  : allStates.moveTeamData.currentTeams.join(", ")}
              </Box>
            </Box>
            <Box className="form-info-wrapper">
              <Typography className="form-heading" variant="h2">
                {t(`${configJSON.newTeamTxt}`)}
              </Typography>
              <FormControl
                variant="outlined"
                className={`${classes.selectOutline} team-outline`}
              >
                <InputLabel id="select-label">{t(`${configJSON.chooseTeam}`)}</InputLabel>
                <Select
                  labelId="select-team"
                  id="select-team"
                  value={allStates.moveTeamData.teamId}
                  // label="Choose Team"

                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle }
                  }}
                  onChange={event => saveNewTeam(event)}
                >
                  {allStates.excludeTeamsList.map((team: any) => {
                    return (
                      <MenuItem
                        key={team.attributes.id}
                        value={team.attributes.id}
                      >
                        {team.attributes.title}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText className="error-select">
                  {chooseTeamError && chooseTeamError}
                </FormHelperText>
                <FormHelperText className="error-select">
                  {teamExists && teamExists}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box className="modal-footer">
              <Button
                className={`secondary-btn ${classes.secondaryButton}`}
                title={createTeamTitleBtn}
                onClick={addAnotherTeam}
              >
                <AddIcon /> {t(`${configJSON.addNewTeamTxt}`)}
              </Button>
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                title="Add to Team"
                onClick={moveMembertoTeam}
                data-testid="movetoTeam"
              >
               {t(`${configJSON.addToTeam}`)}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default MoveMemberTeam;
// Customizable Area End
