import React, { useEffect } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles, createStyles, Theme as AugmentedTheme } from '@material-ui/core/styles';

import { ReactComponent as Logo } from '../../public/app_logo.svg';
import { withRouter, RouteComponentProps  } from 'react-router-dom';


const useStyles = makeStyles((theme: AugmentedTheme) =>
    createStyles({
        blur: {
            overflow: "hidden",

            "& div": {
                filter: "blur(15px)",
                "-webkit-filter": "blur(15px)",
                transform: "scale(1.1)",
            }
        },
        logo: {
            width: "40rem",
            position: "absolute",
            zIndex: 1,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }
    }),
);

interface SplashScreenProps extends RouteComponentProps {
  // Add any additional props if required
}

const SplashScreen: React.FC<SplashScreenProps> = ({ history}) => {
  // Customizable Area Start
  const styles = useStyles();
  // Customizable Area End

  // will redirect to login page after 2 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      history.push('/login');
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [history]);
  

    return (
        <Box className={styles.blur}>
            <Logo className={styles.logo} />
            <Box className="backgroundImg" />
        </Box>
    );
}

// Customizable Area Start
export default withRouter(SplashScreen);
// Customizable Area End