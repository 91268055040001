Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";
exports.deleteApiMethodType = "DELETE";
exports.createTicketEndPoint = "bx_block_ticketsystem/tickets";

exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TicketSystem";
exports.labelBodyText = "TicketSystem Body";

exports.btnExampleTitle = "CLICK ME";

exports.screenTitle = "Ticket Form";
exports.screenSubtitle = "Please describe the issue you are facing";

exports.labelName = "Name*"
exports.name = "Name"
exports.labelEmail = "Email*"
exports.email = "Email"
exports.labelSubject = "Subject*"
exports.subject = "Subject"
exports.labelDescription = "Description*"
exports.description = "Description"

exports.required = "Required"
exports.submitBtnText = "Submit";
exports.backText = "Back";
exports.liveChatText="Live Chat";
exports.myTicketsText="My Tickets";
exports.myTicketSubText="View all your tickets or create a new ticket";
exports.viewAlltext="View all Tickets";
exports.newTicket="New Ticket";
exports.moreTickets="more Tickets";
exports.searchContent="Search for a ticket";
exports.subContent="Type your question or search keyword";
exports.ticketsText="Tickets";
exports.markAsResolved="Mark as resolved";
exports.goToChat="Go to Chat";
exports.requestSubmited="Request Submitted";
exports.requestSubtxt="Your request has been submitted successfully. Our team will get in touch with you soon.";
exports.backTohelpCenter="Back to Help Center";
exports.adjustFilters="Adjust activity log filters";
exports.filters="Filters";
exports.category="Category";
exports.chooseCategory="Choose Category";
exports.status="Status";
exports.dateSubmited="Date Submitted";
exports.raiseTicket="Raise a ticket";
exports.raiseTicketSubTxt="Please describe the issue you are facing";
exports.categoryList=[
  {id:0,name:"Technical Issue"},
  {id:1,name:"Order from the EtOH Shop"},
  {id:2,name:"Other"}
];
exports.submit="Submit";
exports.statusList=[
  {id:0,name:"Open"},
  {id:1,name:"In Progress"},
  {id:2,name:"Resolved"}
];
exports.ticketPath="/bx_block_ticketsystem/tickets";
exports.markAsResolvedPath="/bx_block_ticketsystem/update_status";
exports.viewSingleTicktePath="/bx_block_ticketsystem/view_ticket";
exports.nameOptionsPath="/bx_block_ticketsystem/name_searched";
exports.patchApiMethodType = "PATCH";
exports.searchPlaceholder="Start typing to search...";
exports.noTickets="No Tickets";
exports.canIHelpYou="How can we help you?";
exports.moreTicket="more ticket";
exports.moreTickets="more tickets";
exports.startSearch="Start typing...";
exports.status="Status";
exports.recentlyAdded="Recently added";
exports.categoryTxt="Category";
exports.downloadEtoh="Download EtOH Coach";
exports.etohDis="EtOH Coach is your super efficient studying coach helping you to micro learn whenever you can with adaptive learning using spaced repetition and active recall specialised for wine, beer and spirits certifications and knowledge.";
exports.ethohIOS="EtOH Coach iOS App";
exports.ethohAndroid=" EtOH Coach Android App";
exports.dropToUpload="Drop files here to upload";
exports.or="or";
exports.browse="browse";
exports.toChooseFile="to choose a file";
exports.addScreenshottxt="Add screenshots or recordings of the issue.";
exports.uploading="Uploading";
exports.secRemaining="Seconds remaining";
exports.reset="Reset";
exports.chooseDate="Choose Date";
exports.applyFilters="Apply Filters";
exports.ticketTxt="Ticket";
exports.sortByTxt="Sort by";
exports.ticketTitleTxt="Ticket Title";
// Customizable Area End