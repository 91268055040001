import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const baseConfig = require("../../../framework/src/config");

// Initialize i18n
i18n.use(initReactI18next).init({
  resources: {},
  fallbackLng: 'en-us',
  interpolation: {
    escapeValue: false
  }
});

const fetchTranslations = async (language: any) => {
  try {
    const cachedTranslations = localStorage.getItem(`translations_${language}`);
    if (cachedTranslations) {
      const parsedTranslations = JSON.parse(cachedTranslations);
      i18n.addResourceBundle(language, 'translation', parsedTranslations);
      return;
    }

    const response = await fetch(
      `${baseConfig.baseURL}/bx_block_language_options/translations?code=${language}`    
    );

    if (!response.ok) {
      throw new Error('Failed to fetch translations');
    }

    const translations = await response.json();

    const result = Object.fromEntries(
      Object.values(translations).map((entry:any) => [entry.key, entry.value])
    );

    i18n.addResourceBundle(language, 'translation', result);
    
    // Cache translations
    localStorage.setItem(`translations_${language}`, JSON.stringify(result));

  } catch (error) {
    console.error('Error fetching translations:', error);
  }
};

export const fetchAllTranslations = async () => {
  const lang = localStorage.getItem("lang") ?? "en";
  await fetchTranslations(lang);
};

fetchAllTranslations();

export default i18n;
