import React from "react";
import { Box, FormControl, Select, MenuItem } from "@material-ui/core";

interface SortQuizWebProps {
  classes: any;
  sortType: string;
  sortHandler: (event: React.ChangeEvent<{ value: unknown }>) => void;
  t:(key:string)=>React.ReactNode;
}

const SortQuizWeb = (props: SortQuizWebProps) => {
  const { classes, sortHandler, sortType ,t } = props;

  const isLibrarySort = sortType === "librarySort";

  return (
    <Box className={isLibrarySort ? "short-by" : "top-right"}>
      <FormControl className="select-control">
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            classes: { paper: classes.dropdownStyle },
          }}
          displayEmpty
          defaultValue="Sort by"
          onChange={sortHandler}
          data-test-id={isLibrarySort ? "quizLibrarySort" : "SbmtQuizSort"}
        >
          <MenuItem value="Sort by">{t("Sort by")}</MenuItem>
          <MenuItem value={1}>A to Z</MenuItem>
          <MenuItem value={2}>Z to A</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default SortQuizWeb;
