// Customizable Area Start
import React from "react";
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";

export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    tasklistWrapper: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      "& .mobile-heading": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          padding: "24px",
          borderBottom: "0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        "& .heading": {
          [theme.breakpoints.down("xs")]: {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
            margin: "0 20px",
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            letterSpacing: "0",
          },
        },
      },
    },
    tasklistRoot: {
      display: "flex",
      flexWrap: "wrap",
      flex: "1",
      "& .tasklist-sidebar": {
        width: "367px",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
        padding: "20px 40px 20px 28px",
        // height: "100%",
        position: "sticky",
        top: "82px",
        maxHeight: "calc(100vh - 82px)",
        overflowY: "auto",
        [theme.breakpoints.down("md")]: {
          width: "320px",
          padding: "20px 32px 14px 28px",
        },
        [theme.breakpoints.down("xs")]: {
          display: "none",
          padding: "20px 28px 14px 24px",
        },
        "&.open": {
          [theme.breakpoints.down("xs")]: {
            display: "block",
            width: "100%",
            position: "fixed",
            top: "0",
            bottom: "0",
            maxHeight: "100%",
            background: "#fff",
            zIndex: "1",
          },
        },
        "& .sidebar-title": {
          display: "none",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "24px",
            display: "flex",
            alignItems: "center",
          },
          "& .back-btn-wrap": {
            [theme.breakpoints.down("xs")]: {
              marginRight: "16px",
            },
          },
          "& .heading": {
            [theme.breakpoints.down("xs")]: {
              fontWeight: 400,
              fontSize: "24px",
              lineHeight: "30px",
              color: "#2b2b2b",
              fontFamily: "Expletus Sans",
            },
          },
        },
        "& .sidebar-accodion": {
          borderRadius: "0",
          boxShadow: "none",
          margin: "0 0 32px",
          "&:before": {
            display: "none",
          },
          "& .MuiAccordionSummary-content": {
            margin: "0",
          },
          "& .MuiIconButton-root": {
            padding: "0",
            marginRight: "-4px",
            "& .MuiTouchRipple-root": {
              display: "none",
            },
            "& .MuiSvgIcon-root": {
              color: "#94A3B8",
            },
          },
        },
        "& .sidebar-details": {
          padding: "0",
        },
        "& .sidebar-heading": {
          color: "#2B2B2B",
          fontSize: "16px",
          lineHeight: "24px",
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          marginBottom: "8px",
          minHeight: "unset",
          padding: "0",
        },
        "& .MuiTabs-root": {
          borderLeft: "1px solid #E8ECF2",
          paddingTop: "16px",
          width: "100%",
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .MuiTab-root": {
            padding: "0",
            opacity: "1",
            color: "#2B2B2B",
            fontSize: "14px",
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            letterSpacing: "0",
            textTransform: "capitalize",
            minHeight: "unset",
            marginBottom: "16px",
            position: "relative",
            width: "100%",
            maxWidth: "100%",
            "&.sub-tab": {
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0.09px",
              marginBottom: "0",
              "&:last-child": {
                marginBottom: "16px",
              },
              "&:before": {
                width: "37px",
              },
              "& .MuiTab-wrapper": {
                "& .tab-label": {
                  padding: "8px",
                },
              },
            },
            "&:before": {
              content: '""',
              width: "17px",
              height: "1px",
              background: "#E8ECF2",
              marginRight: "16px",
            },
            "&:last-child": {
              marginBottom: "0",
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
            "&.Mui-selected": {
              "& .tab-label": {
                background: "#F2F8FC",
              },
            },
            "& .MuiTab-wrapper": {
              display: "flex",
              alignItems: "center",
              flexDirection: "unset",
              justifyContent: "space-between",
              "& .tab-label": {
                padding: "8px 20px",
                borderRadius: "6px",
                background: "#ffffff",
                textAlign: "left",
              },
              "& .tab-no": {
                marginLeft: "10px",
              },
            },
          },
        },
      },
      "& .tasklist-content": {
        flex: "1",
        maxWidth: "calc(100% - 367px)",
        // marginTop: "82px",
        height: "calc(100vh - 82px)",
        [theme.breakpoints.down("md")]: {
          maxWidth: "calc(100% - 320px)",
        },
        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
          height: "unset",
        },
      },
    },
  });
// Customizable Area End
