export const certification1 = require("../../inventorymanagement/assets/Bitmap.png");
export const certification2 = require("../../inventorymanagement/assets/Bitmap2.png");
export const etohlogo = require("../assets/etoh-logo.png");
export const wineAdded = require("../assets/wine-success.png");
export const wineImage = require("../assets/wine-img.png"); 
export const ovalShape = require("../assets/Oval.png");
export const spiritAddedImg = require("../assets/spirit-success.png");
export const mdiLinkImage = require("../assets/mdi_link-variant.png"); 
export const attachment = require("../assets/attachment.png"); 
export const beerAddedImg = require("../assets/cuate.jpg");
export const otherProductAddImg = require("../assets/amico.jpg");
export const hardwareAddImg = require("../assets/hardware-success.png");
export const machineryAddImg = require("../assets/machinery.png");
export const landImg = require("../assets/land.png");
export const chemicalImg = require("../assets/chemical.png");
export const fertilizerImg = require("../assets/fertilizer.png");
export const phytoImg = require("../assets/phyto-product.png");
export const vesselImg = require("../assets/vessel.png");
export const otherProductImg = require("../assets/otherProduct.png");
export const landIcon = require("../assets/landIcon.png");
export const vesselIcon = require("../assets/vesselIcon.png");
export const phytoIcon = require("../assets/phytoIcon.png");
export const chemicalIcon = require("../assets/chemicalIcon.png");
export const fertilizerIcon = require("../assets/fertilizerIcon.png");
export const machineryIcon = require("../assets/machineryIcon.png");
export const hardwareIcon = require("../assets/hardwareIcon.png");
export const wineIcon = require("../assets/wineIcon.png");
export const beerIcon = require("../assets/beerIcon.png");
export const spiritIcon = require("../assets/spiritIcon.png");
export const downloadGray = require("../assets/download-gray.png");
export const copyGray = require("../assets/copy-gray.png");
export const deleteGray = require("../assets/delete-gray.png");
export const warningIcon = require("../assets/warning-red.png");
export const deleteIcon = require("../assets/delete-outline.png");
export const trophyImg = require("../assets/trophy-img.png");
export const trophyImg2 = require("../assets/trophy-img2.png");
export const trophyImg3 = require("../assets/trophy-img3.png");
export const noFilterItem = require("../assets/noFilterItem.png");
export const checkBoxEmpty = require("../assets/check-empty.png");
export const checkBoxFilled = require("../assets/check-filled.png");
export const favouriteProduct = require("../assets/favourite-product.jpg");
export const filterSmIcon = require("../assets/filter-icon.png");
export const plusIcon = require("../assets/plus-icon.svg");
export const minusIcon = require("../assets/minus-icon.svg");
export const closeGray = require("../assets/close-gray.png");
export const editIcon = require("../assets/edit-icon.svg");
export const vectorBack = require("../assets/vector-back.png");
export const emailSuccess = require("../assets/email-success.svg");