// Customizable Area Start
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { Box, Button } from "@material-ui/core/";
import { Link } from "react-router-dom";
import moment from "moment";
import i18n from "../../web/src/utilities/i18n";
import { calendarBlack } from "./assets";
interface CustomDatePickerProps {
  range?: boolean;
  placeholder?: string;
  value?: any;
  render?: any;
  currentDate: string;
  required?: boolean;
  error?: string;
  maxDate?: any;
  onChange: ((
    selectedDates: DateObject | DateObject[] | null
  ) => false | void) &
  ((
    selectedDates: DateObject | DateObject[] | null,
    validatedValue: string | string[],
    input: HTMLElement,
    isTyping: boolean
  ) => false | void);
  onOpenPickNewDate: boolean;
  onOpen?: any;
  getFilterdData: () => void;
}

type CustomDatePickerRef = HTMLDivElement & {
  closeCalendar: () => void
}
const DATE_FORMAT = "YYYY-MM-DD";
const useStyles = makeStyles((theme) => ({
  datepickerWrapper: {
    "&.required": {
      "& .rmdp-container": {
        position: "relative",
        "&:before": {
          content: "'*'",
          display: "inline-block",
          position: "absolute",
          color: "#e86577",
          fontSize: "18px",
          lineHeight: "18px",
          width: "10px",
          height: "10px",
          right: "12px",
          top: "50%",
          transform: "translateY(-50%)",
        }
      }
    },
    "&.rmdp-error": {
      "& .rmdp-container": {
        "& .rmdp-input": {
          border: "1px solid #C7263E",
        }
      }
    },
    "& .rmdp-error-msg": {
      fontSize: "12px",
      fontFamily: "Roboto",
      fontWeight: 400,
      letterSpacing: "0.0075em",
      margin: "3px 14px 0",
      color: "#C7263E",
    },
    "& .rmdp-container": {
      width: "100%",
      "& .rmdp-input": {
        //width: "auto",
        //border: "none",
        marginRight: "-30px",
        backgroundImage: `url(${calendarBlack})`,
        backgroundRepeat: "no-repeat",
        // backgroundPosition: "left center",
        // padding: "0 0 0 24px",
        color: "#2B2B2B",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "normal",
        padding: "20px 55px 20px 24px",
        borderRadius: "6px",
        height: "auto",
        border: "1px solid #E8ECF2",
        width: "100%",
        backgroundPosition: "calc(100% - 24px) center",
        minHeight: "56px",
        "&:focus": {
          boxShadow: "none",
        },
        "&::-webkit-input-placeholder": {
          color: "#2B2B2B",
        },
        "&:-ms-input-placeholder": {
          color: "#2B2B2B",
        },
        "&::placeholder": {
          color: "#2B2B2B",
        },
      },
      "& .rmdp-wrapper": {
        boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
        borderRadius: "24px",
        "& .rmdp-header-values": {
          color: "#2B2B2B",
          fontFamily: "Roboto",
          fontSize: "15.696px",
          fontWeight: 700,
          lineHeight: "19.184px",
          "& span": {
            padding: "0",
          },
        },
        "& .rmdp-arrow": {
          borderColor: "#94A3B8",
          height: "6px",
          padding: "4px",
          width: "6px",
        },
        "& .rmdp-arrow-container": {
          "&:hover": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        },
        "& .rmdp-left": {
          "& i": {
            marginLeft: "6px",
          },
        },
        "& .rmdp-right": {
          "& i": {
            marginRight: "6px",
          },
        },
        "& .rmdp-week-day": {
          color: "#2B2B2B",
          fontSize: "16.773px",
          fontWeight: "500",
          lineHeight: "normal",
          fontFamily: "Roboto",
          height: "40px",
          width: "40px",
        },
        "& .rmdp-calendar": {
          padding: "39px 19px 16px",
        },
        "& .rmdp-day-picker": {
          padding: "0",
        },
        "& .rmdp-day": {
          color: "#2B2B2B",
          height: "40px",
          width: "40px",
          "&.rmdp-selected": {
            "& span": {
              "&:not(.highlight)": {
                backgroundColor: "#4BA3B7",
                color: "#fff",
                boxShadow: "none",
              },
            },
          },
          "&.rmdp-disabled": {
            color: "grey",
          },
          "&:not(.rmdp-disabled)": {
            "&:not(.rmdp-day-hidden)": {
              "& span": {
                "&:hover": {
                  backgroundColor: "#4BA3B7",
                  color: "#fff",
                },
              },
            },
          },
          "& span": {
            fontSize: "16.773px",
            fontWeight: "500",
            lineHeight: "normal",
            fontFamily: "Roboto",
            padding: 0,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
          "&.rmdp-today": {
            "& span": {
              backgroundColor: "transparent",
              color: "#7fdbff",
            },
          },
        },
        "& .rmdp-range": {
          backgroundColor: "#ECF1F4",
          boxShadow: "none",
        },
        "& .rmdp-range, & .rmdp-range-hover": {
          "&.rmdp-today": {
            "&.start, &.end": {
              "& span": {
                backgroundColor: "#4BA3B7",
                color: "#fff",
              },
            },
          },
          "&.start, &.end": {
            color: "#fff",
            "& span": {
              background: "#4BA3B7",
            },
          },
        },
        "& .rmdp-button-wrapper": {
          borderRadius: "0 0 24px 24px",
          background: "rgba(43, 43, 43, 0.87)",
          "& .button-actions": {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "0 19px 19px",
            borderRadius: "0 0 24px 24px",
            background: "#ffffff",
            "& .secondary-btn": {
              minWidth: "unset",
              border: 0,
              padding: "6px 12px",
              minHeight: "30px",
              color: "#4BA3B7",
              marginRight: "12px",
              width: "auto",
            },
            "& .primary-btn": {
              minWidth: "unset",
              border: 0,
              padding: "6px 12px",
              minHeight: "30px",
              width: "auto",
            },
          },
          "& .button-bottom-actions": {
            padding: "24px 15px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            "& .tasklist-link": {
              color: "#FFF",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "26px",
              letterSpacing: "0.105px",
              textDecoration: "none",
              marginRight: "16px",
              "&:last-child": {
                marginRight: "0",
              },
            },
          },
        },
      },
    },
  },
  secondaryButton: {
    background: "transparent",
    border: "1px solid #e8ecf2",
    borderRadius: "7px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#2b2b2b",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    textTransform: "unset",
    maxWidth: "100%",
    minWidth: "198px",
    padding: "12px 10px",
    minHeight: "48px",
    marginBottom: "0",
    transition: "all 0.5s ease-in-out",
    textDecoration: "none",
    "&:hover": {
      background: "transparent",
      color: "#2b2b2b",
    },
  },
  primaryButton: {
    background: "#4ba3b7",
    border: "1px solid #4ba3b7",
    borderRadius: "6px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#f2f8fc",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    maxWidth: "100%",
    minWidth: "170px",
    padding: "12px 10px",
    minHeight: "48px",
    marginBottom: "0",
    transition: "all 0.5s ease-in-out",
    textTransform: "capitalize",
    "&:hover": {
      background: "#4ba3b7",
      color: "#ffffff",
    },
  },
}));

const EScoreDatePicker = (props: CustomDatePickerProps) => {
  const [todayDate, setTodayDate] = useState("");
  const [tomorrowDate, setTomorrowDate] = useState("");
  const [nextWeekDate, setNextWeekDate] = useState("");
  const [nextMonthDate, setNextMonthDate] = useState("");
  const [LastWeekDate, setLastWeekDate] = useState<DateObject[] | null>(null);
  const [LastMonthDate, setLastMonthDate] = useState<DateObject[] | null>(null);
  const [LastYearDate, setLastYearDate] = useState<DateObject[] | null>(null);

  const [tempDate, setTempDate] = useState<Date | undefined>(undefined);
  const datePickerRef = useRef<CustomDatePickerRef>(null);
  const [lastValue, setLastValue] = useState<DateObject | DateObject[] | null>(null);
  let isSavingDate: boolean = false;
  const {
    range,
    placeholder,
    value,
    currentDate,
    render,
    maxDate,
    onChange,
    onOpenPickNewDate,
    onOpen,
    required,
    error,
    getFilterdData
  } = props;
  const classes = useStyles();
  const weekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  useEffect(() => {
    const today = moment();
    setTodayDate(today.format(DATE_FORMAT));

    const tomorrow = moment().add(1, "days");
    setTomorrowDate(tomorrow.format(DATE_FORMAT));

    const nextWeek = moment().add(7, "days");
    setNextWeekDate(nextWeek.format(DATE_FORMAT));

    const nextMonth = moment().add(1, "months");
    setNextMonthDate(nextMonth.format(DATE_FORMAT));

    const lastMonday = moment(today).subtract(1, 'weeks').startOf('isoWeek');
    const lastSunday = moment(today).subtract(1, 'weeks').endOf('isoWeek');

    setLastWeekDate([new DateObject(lastMonday.toDate()), new DateObject(lastSunday.toDate())]);

    // Get the first date of the last month
    const firstOfLastMonth = moment(today).subtract(1, 'months').startOf('month');
    const lastOfLastMonth = moment(today).subtract(1, 'months').endOf('month');

    setLastMonthDate([new DateObject(firstOfLastMonth.toDate()), new DateObject(lastOfLastMonth.toDate())]);

    const firstOfLastYear = moment(today).subtract(1, 'years').startOf('year');
    const lastOfLastYear = moment(today).subtract(1, 'years').endOf('year');
    setLastYearDate([new DateObject(firstOfLastYear.toDate()), new DateObject(lastOfLastYear.toDate())]);

    // setCurrent(myTaskDate)
  }, []);

  useEffect(() => {
    const datePickerInput = datePickerRef.current?.children[0];
    if (datePickerInput) {
      datePickerInput.setAttribute('readonly', 'readonly');
    }

    return () => {
      if (datePickerInput) {
        datePickerInput.removeAttribute('readonly');
      }
    };
  }, []);

  const onDone = () => {
    isSavingDate = true;
    if (range) {
      datePickerRef?.current?.closeCalendar();
      getFilterdData()
    }
    else {
      onChange(new DateObject(
        moment(tempDate).format(DATE_FORMAT)
      ))
    }
    datePickerRef?.current?.closeCalendar();
  }
  const t = (key:any) => {
    return i18n.t(key, { ns: "translation" } )
  }
  return (
    <Box className={`datepicker-wrapper ${classes.datepickerWrapper} ${required ? "required " : ""}  ${error !== "" && required ? "rmdp-error" : ""}`}>
      <DatePicker
        range={range}
        dateSeparator=" - "
        multipleRangeSeparator="&"
        weekDays={weekDays}
        // readOnly={true}
        arrow={false}
        weekStartDayIndex={1}
        format="MMM DD, YY"
        placeholder={placeholder}
        monthYearSeparator=" "
        value={range ? value : tempDate ?? new Date(currentDate)}
        render={render}
        currentDate={range ? value : new DateObject(currentDate)}
        maxDate={maxDate}
        ref={datePickerRef}
        onChange={(date: any) => {
          isSavingDate = true;
          if (!range) {
            const dt = date?.isValid ? new Date(date.format("YYYY-MM-DD")) : undefined;
            setTempDate(dt);
          }
          onChange(date);
        }}
        onOpenPickNewDate={onOpenPickNewDate}
        onOpen={() => {
          isSavingDate = false; if (range) { setLastValue(value) }
          if (onOpen) {
            onOpen()
          }
        }
        }
        onClose={() => {
          if (range) {
            if (value.length == 1) {
              onChange(lastValue)
            }
          }
          else if (!isSavingDate && tempDate !== undefined) {
            setTempDate(new Date(currentDate));
          }

          return true;
        }}
      >
        <Box className="rmdp-button-wrapper">
          <Box className="button-actions">
            <Button
              className={`secondary-btn ${classes.secondaryButton}`}
              onClick={() => { datePickerRef?.current?.closeCalendar(); if (range) { onChange(lastValue) } }}
            >
              {t("Cancel")}
            </Button>
            <Button
              className={`primary-btn ${classes.primaryButton}`}
              onClick={onDone}
            >
              {t("Done")}
            </Button>
          </Box>
          <Box className="button-bottom-actions">
            {range ? <><Link to="#" className="tasklist-link" onClick={() => { onChange(LastWeekDate); datePickerRef?.current?.closeCalendar(); }}>
              {t("Last Week")}
            </Link>
              <Link to="#" className="tasklist-link" onClick={() => { onChange(LastMonthDate); datePickerRef?.current?.closeCalendar(); }}>
                {t("Last Month")}
              </Link>
              <Link to="#" className="tasklist-link" onClick={() => { onChange(LastYearDate); datePickerRef?.current?.closeCalendar(); }}>
                {t("Last Year")}
              </Link></> : <>
              <Link
                to="#"
                className="tasklist-link"
                onClick={() => setTempDate(new Date(todayDate))}
              >
                {t("Today")}
              </Link>
              <Link
                to="#"
                className="tasklist-link"
                onClick={() => setTempDate(new Date(tomorrowDate))}
              >
                {t("Tomorrow")}
              </Link>
              <Link
                to="#"
                className="tasklist-link"
                onClick={() => setTempDate(new Date(nextWeekDate))}
              >
                {t("Next Week")}
              </Link>
              <Link
                to="#"
                className="tasklist-link"
                onClick={() => setTempDate(new Date(nextMonthDate))}
              >
                {t("Next Month")}
              </Link>
            </>
            }
          </Box>
        </Box>
      </DatePicker>
      <Box className="rmdp-error-msg">{error ?? ""}</Box>
    </Box>
  );
};

export default EScoreDatePicker;
// Customizable Area End
