import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";
// Customizable Area Start
import React from "react";
export const configJSON = require("../config");
import i18n from "../../../../web/src/utilities/i18n";
import { producerData , chooseMock } from "../../../../components/src/productData";
export interface CheckedFilters {
  grapeVariety: string[];
  rawMaterial: string[];
  color: string[];
  dosage: string[];
  containerVolume: string[];
  productionProcess: string[];
  trophyName: string[];
  category: string[];
  appellation: string[];
  appellationType: string[];
  country: string[];
  vesselMeasurement: string[];
  quantityUnit: string[];
  material: string[];
  specification: string[];
  brand: string[];
  target: string[];
  areaUnit: string[];
  region: string[];
  subRegion: string[];
  certificationName: string[];
}
export interface UNumberFilters {
  price?: number[];
  quantity?: number[];
  alcohol?: number[];
  ibu?: number[];
  srm?: number[];
  og?: number[];
  fg?: number[];
  ebc?: number[];
}

export interface NumberFilters {
  price: number[];
  quantity: number[];
  alcohol: number[];
  ibu: number[];
  srm: number[];
  og: number[];
  fg: number[];
  ebc: number[];
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  openProductsModal: boolean;
  selectedDealProducts: (
    choosenProducts: string[],
    choosenProductsData: []
  ) => void;
  closeProductsModal: () => void;
  classes: any;
  buttonTxt: string;
  showPriceAs?: string;
  item?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  choosenProducts: any;
  openItemInfo: any;
  allProductsData: any;
  choosenProductsData: any;
  totalAmount: number | null;
  productQuantities: any;
  selectAll: boolean;
  itemList: any;
  isLoading: boolean;
  expanded: boolean;
  searchProducer: any;
  searchBrand: any;
  searchColor: any;
  searchVintage: any;
  searchCountry: any;
  searchRegion: any;
  searchSubRegion: any;
  searchAppellation: any;
  producer: any;
  brand: any;
  color: any;
  vintage: any;
  country: any;
  region: any;
  subRegion: any;
  appellation: any;
  brandListData: any;
  overallProductSearch: any;
  checkedItems: string[];
  currentCategory: string;
  pageNumber: number;
  sortByOrder: string;
  sortBy: string;
  topOffset: number;
  hasMore: boolean;
  showPriceAs: any;
  isProductChecked: boolean;
  checkedFilters: CheckedFilters;
  numberFilters: UNumberFilters;
  minMaxValues: NumberFilters;
  inputValues: NumberFilters;
  priceToShow: number;
  overallProductData:any
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AddInventoryAndAssestsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionStorageData: any;
  userToken: any;
  brandListRequestApiID: string = "";
  filterCatalogueApiId: string = "";
  targetRef: any = React.createRef();
  getAllProductsAPIRequestId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    // Customizable Area Start
    this.userSessionStorageData = sessionStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionStorageData);
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      openItemInfo: false,
      allProductsData: [],
      choosenProducts: [],
      productQuantities: {},
      choosenProductsData: [],
      selectAll: false,
      totalAmount: null,
      expanded: false,
      itemList: producerData,
      isLoading: false,
      searchProducer: "",
      searchBrand: "",
      searchColor: "",
      searchVintage: "",
      searchCountry: "",
      searchRegion: "",
      searchSubRegion: "",
      searchAppellation: "",
      producer: "",
      brand: "",
      color: "",
      vintage: "",
      country: "",
      region: "",
      subRegion: "",
      appellation: "",
      brandListData: [],
      overallProductSearch: "",
      checkedItems: [],
      currentCategory: "1",
      pageNumber: 1,
      sortByOrder: "desc",
      sortBy: "",
      topOffset: 0,
      hasMore: false,
      showPriceAs: "",
      isProductChecked: false,
      checkedFilters: {
        grapeVariety: [],
        rawMaterial: [],
        color: [],
        dosage: [],
        containerVolume: [],
        productionProcess: [],
        trophyName: [],
        category: [],
        appellation: [],
        appellationType: [],
        country: [],
        vesselMeasurement: [],
        quantityUnit: [],
        material: [],
        specification: [],
        brand: [],
        target: [],
        areaUnit: [],
        region: [],
        subRegion: [],
        certificationName: [],
      },
      numberFilters: {
        price: undefined,
        quantity: undefined,
        alcohol: undefined,
        ibu: undefined,
        srm: undefined,
        og: undefined,
        fg: undefined,
        ebc: undefined,
      },
      minMaxValues: {
        price: [0, 0],
        quantity: [0, 0],
        alcohol: [0, 0],
        ibu: [0, 0],
        srm: [0, 0],
        og: [0, 0],
        fg: [0, 0],
        ebc: [0, 0],
      },
      inputValues: {
        price: [0, 0],
        quantity: [0, 0],
        alcohol: [0, 0],
        ibu: [0, 0],
        srm: [0, 0],
        og: [0, 0],
        fg: [0, 0],
        ebc: [0, 0],
      },
      priceToShow: 0,
      overallProductData:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.error) {
        this.setState({ isLoading: false });
        return;
      } else {
        switch (apiRequestCallId) {
          case this.brandListRequestApiID:
            this.setState({ isLoading: false });
            this.setState({ brandListData: responseJson.data });
            break;
          case this.filterCatalogueApiId:
            this.setInventoryData(responseJson);
            break;
          case this.getAllProductsAPIRequestId:      
            this.setState({isLoading: false})
            this.setState({overallProductData : responseJson.data}) 
            break;  
          default:
            this.setState({ isLoading: false });
            break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const lang = localStorage.getItem("lang") ?? "en";
    await i18n.changeLanguage(lang);
    this.getCatalogue();
    this.getBrands();
    this.getAllProducts();
  }

  setInventoryData = (responseJson: any) => {
    if (responseJson.catalogues) {
      this.setState(
        {
          allProductsData:
            this.state.pageNumber == 1
              ? responseJson.catalogues.data
              : [
                  ...this.state.allProductsData,
                  ...responseJson.catalogues.data,
                ],
          hasMore: responseJson.hasMore ?? false,
        },
        () => {
          this.setState({
            isLoading: false,
            checkedItems:
              this.state.pageNumber == 1 ? [] : this.state.checkedItems,
            isProductChecked: false,
          });
        }
      );
    } else {
      this.setState({
        isLoading: false,
        checkedItems: [],
        isProductChecked: false,
        allProductsData: [],
      });
    }
  };
  getAllProducts = () => {
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllProductsAPIRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllProductEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  t(key: any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation", ...variables });
  }
  handleChoose=()=>{
    this.setState({ choosenProductsData: chooseMock });
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    const { choosenProducts, overallProductData } = this.state;
  if (
    prevState.choosenProducts !== choosenProducts ||
    prevState.overallProductData !== overallProductData
  ) {
    const filteredData = overallProductData.filter((item:any) =>
      choosenProducts.includes(item.id)
    );
    this.setState({ choosenProductsData: filteredData });
  }

    if (
      prevState.currentCategory !== this.state.currentCategory ||
      prevState.sortBy !== this.state.sortBy ||
      prevState.sortByOrder !== this.state.sortByOrder ||
      prevState.checkedFilters !== this.state.checkedFilters ||
      prevState.numberFilters !== this.state.numberFilters ||
      this.state.showPriceAs !== prevState.showPriceAs ||
      prevState.overallProductSearch !== this.state.overallProductSearch
    ) {
      this.setState(
        {
          pageNumber: 1,
        },
        this.getCatalogue
      );
    }
  }
  handleToggleEvent = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };
  changeCategory = (event: any) => {
    const { value } = event.target;
    this.setState({
      currentCategory: value,
    });
  };
  openItemsInfoDetailModal = () => {
    this.setState({ openItemInfo: true });
  };
  closeItemsInfoDetailModal = () => {
    this.setState({ openItemInfo: false });
  };
  handleSelectedItemEvent = (
    event: React.ChangeEvent<HTMLInputElement>,
    productId: string
  ) => {
    this.setState((prevState) => {
      let updatedchoosenProducts;
      const { choosenProducts } = prevState;
      if (choosenProducts.includes(productId)) {
        updatedchoosenProducts = choosenProducts.filter(
          (id: any) => id !== productId
        );
      } else {
        updatedchoosenProducts = [...choosenProducts, productId];
      }
      return { choosenProducts: updatedchoosenProducts };
    });
  };
  handleSelectAllItemsEvent = (event: any) => {
    const { allProductsData } = this.state;
    const allProductIds = allProductsData.map((product: any) => product.id);
    this.setState({
      choosenProducts: event.target.checked ? allProductIds : [],
      selectAll: event.target.checked,
    });
  };
  removeChosenProductItemsEvent = (productIdToRemove: any) => {
    this.setState((prevState) => {
      const updatedProductQuantities = { ...prevState.productQuantities };
      updatedProductQuantities[productIdToRemove] = 1;

      return {
        choosenProducts: prevState.choosenProducts.filter(
          (id: any) => id !== productIdToRemove
        ),
        productQuantities: updatedProductQuantities,
      };
    });
  };
  handleAllInputChangeEvent = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  handleCheckedSelection = (id: any, name: any) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [name]: id,
    }));
  };

  changeSortBy = (e: any) => {
    switch (e.target.value) {
      case configJSON.SortOption1:
        this.setState({ sortBy: "", sortByOrder: "desc" });
        break;
      case configJSON.SortOption2:
        this.setState({ sortBy: "price", sortByOrder: "desc" });
        break;
      case configJSON.SortOption3:
        this.setState({ sortBy: "price", sortByOrder: "asc" });
        break;
      case configJSON.SortOption4:
        this.setState({ sortBy: "name", sortByOrder: "asc" });
        break;
      case configJSON.SortOption5:
        this.setState({ sortBy: "name", sortByOrder: "desc" });
        break;
    }
  };

  getCatalogue = () => {
    let formdata = new FormData();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    formdata.append("category_static_id", this.state.currentCategory);
    formdata.append("per_page", "25");
    formdata.append("page_number", String(this.state.pageNumber));
    formdata.append("order", this.state.sortByOrder);
    formdata.append("query", this.state.overallProductSearch);

    if (this.state.sortBy != "") {
      formdata.append("sort_check", this.state.sortBy);
    }
    if (this.state.sortBy == "price") {
      formdata.append("price_type", this.state.showPriceAs);
    }

    const header = {
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.filterCatalogue
    );
    this.filterCatalogueApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  clearAll = () => {
    this.setState({
      searchProducer: "",
      searchBrand: "",
      searchColor: "",
      searchVintage: "",
      searchCountry: "",
      searchRegion: "",
      searchSubRegion: "",
      searchAppellation: "",
      producer: "",
      brand: "",
      color: "",
      vintage: "",
      country: "",
      region: "",
      subRegion: "",
      appellation: "",
    });
  };
  getBrands = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.brandEndPoint
    );
    this.brandListRequestApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
