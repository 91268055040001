// Customizable Area Start
import React from 'react';
import { Box, FormControl, InputLabel, Select, ListSubheader, MenuItem, ListItemText, InputAdornment } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from '@material-ui/icons/Search';
import CustomInputWeb from './CustomInput.web';
import {checkBoxEmpty, checkBoxFilled, arrowDown} from './assets';

interface ConfirmModalProps {
  label:string;
  value:string;
  selectedText:string;
  saveSelectedOption: (event:any) => void;
  searchTerm:string;
  onSearchChange: (event:any) => void;
  data: any;
  displayLabel: any;
  classes:any;
  placeholder:string;
}

const CustomMultiSelect = (props: ConfirmModalProps) => {
  const {
    label,
    value,
    selectedText,
    saveSelectedOption,
    searchTerm,
    onSearchChange,
    data,
    displayLabel,
    classes,
    placeholder,
  } = props;
  return (
    
        <FormControl className="select-outer" variant="outlined">
          <InputLabel>{label}</InputLabel>
          <Select
            labelId={`multiple-${label.toLowerCase()}-label`}
            id={`multiple-${label.toLowerCase()}`}
            data-test-id={`select-${label.toLowerCase()}`}
            multiple
            value={value}
            label={label}
            defaultValue={[]}
            onChange={(event: any) => saveSelectedOption(event)}
            renderValue={() => displayLabel(value ?? [], selectedText)}
            inputProps={{ IconComponent: () => null }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              classes: {
                paper: `${classes.dropdownStyle} multiSelect`,
              },
            }}
            displayEmpty
            endAdornment={<img className="multiselect-arrow" src={arrowDown}/>}
          >
            <ListSubheader>
              <CustomInputWeb
                placeholder={placeholder}
                fullWidth
                autoFocus
                value={searchTerm}
                onChange={(event: any) =>
                  onSearchChange(event.target.value)
                }
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                onKeyDown={(e: any) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
                data-test-id={`${label.toLowerCase()}-search-input`}
              />
            </ListSubheader>
            {data?.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                <ListItemText>{item.title}</ListItemText>
                <Box className="multiselect-squareCheck">
                  <img className="normal-img" src={checkBoxEmpty} alt="checkBoxEmpty" />
                  <img className="selected-img" src={checkBoxFilled} alt="checkBoxFilled" />
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
     
  );
};
export default CustomMultiSelect;
// Customizable Area End
