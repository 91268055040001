// Customizable Area Start
import React from "react";
import {
  Box,
  Modal,
  Typography,
  CardMedia,
  Stepper,
  Step,
  StepLabel,
  Button,
  Slider,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  info,
  timelineScoreA,
  timelineScoreB,
  timelineScoreC,
  timelineScoreD,
  timelineScoreE,
  polygon,
  clipboardTextClock,
  zeroScore,
  star2,
} from "./assets";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBack from "@material-ui/icons/ArrowBack";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CircleOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import QuizList from "./components/QuizList.web";
export const configJSON = require("./config");

interface FullcreenModeProps {
  modalClasses: any;
  classes: any;
  modalOpen: boolean;
  modalClose: () => void;
  simulationList: any;
  quizData: any;
  saveSimulationData: (index: number, newValue: any) => void;
  updateSimulationQuiz: () => void;
  lastEscore: string;
  showInfoContent: () => void;
  currentGrade: any;
  gradeObj: any;
  isActiveInfoContent: boolean;
  hideInfoHandler: () => void;
  t:any;
}

const SimulateFullScreenMode = (props: FullcreenModeProps) => {
  const {
    classes,
    modalClasses,
    modalOpen,
    modalClose,
    simulationList,
    quizData,
    saveSimulationData,
    updateSimulationQuiz,
    lastEscore,
    showInfoContent,
    currentGrade,
    gradeObj,
    isActiveInfoContent,
    hideInfoHandler,
    t
  } = props;

  return (
    <Modal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={`${modalClasses.fullscreenModal} ${modalClasses.modalBoxCenter}`}
    >
      <Box
        className={`simulationQuizModal ${modalClasses.simulationQuizModal}`}
      >
        <Box className="simulationquiz-inner">
          <Box className="quiz-result-heading">
            <Link to="#" className="close-icon" onClick={modalClose} data-test-id="close-fullScreen">
              <KeyboardBackspaceIcon className="arrow-back"/>
            </Link>
          </Box>
          <Box className="quiz-result-content">
            <Box className="left-box">
              <Box className="heading-wrapper">
                <Typography className="heading">
                  {t(`${configJSON.pastSimulations}`)}
                </Typography>
                <Typography className="sub-title">
                  {t(`${configJSON.pastSimulationDis}`)}
                </Typography>
              </Box>
              <Stepper orientation="vertical">
                {simulationList.map((step: any) => (
                    <Step key={step.id}>
                      {step.isDate ? (
                        <StepLabel
                          StepIconComponent={CircleOutlinedIcon}
                          className="simulation-date"
                        >
                          {step.time}
                        </StepLabel>
                      ) : (
                        <StepLabel
                          StepIconComponent={CircleOutlinedIcon}
                          StepIconProps={{ style: { fontSize: "8px" } }}
                          className={classes.inactiveLabel}
                        >
                          <Box className="score-box-timeline">
                            {step.time}
                            {step.grade === "A" && (
                              <CardMedia
                                component="img"
                                className={classes.simulationScoreImg}
                                image={timelineScoreA}
                                title=""
                              />
                            )}
                            {step.grade === "B" && (
                              <CardMedia
                                component="img"
                                className={classes.simulationScoreImg}
                                image={timelineScoreB}
                                title=""
                              />
                            )}
                            {step.grade === "C" && (
                              <CardMedia
                                component="img"
                                className={classes.simulationScoreImg}
                                image={timelineScoreC}
                                title=""
                              />
                            )}
                            {step.grade === "D" && (
                              <CardMedia
                                component="img"
                                className={classes.simulationScoreImg}
                                image={timelineScoreD}
                                title=""
                              />
                            )}
                            {step.grade === "E" && (
                              <CardMedia
                                component="img"
                                className={classes.simulationScoreImg}
                                image={timelineScoreE}
                                title=""
                              />
                            )}
                          </Box>
                        </StepLabel>
                      )}
                    </Step>
                ))}
              </Stepper>
            </Box>
            <Box className="center-box">
              <Box className="center-box-header">
                <Box className="desktop-header">
                  <Typography className="header-title">
                   {t(`${configJSON.txtCompanyValue}`)}
                  </Typography>
                  <Typography className="sub-title">
                    {t(`${configJSON.desSimulationQuiz}`)}
                  </Typography>
                </Box>
                <Box className="mb-header">
                  <Box className="navigation">
                    <Link to="#">
                      <ArrowBack />
                    </Link>
                    <Box className="mb-header-right">
                      <Button>
                        <CardMedia
                          component="img"
                          className="img-icon"
                          image={info}
                        />
                      </Button>
                      <Button>
                        <CardMedia
                          component="img"
                          className="img-icon"
                          image={clipboardTextClock}
                        />
                      </Button>
                    </Box>
                  </Box>
                  <Box className="simu-score-result">
                    <Box className="box-text">
                      <Typography className="txt-title">
                       {t("Official E-Score")}
                      </Typography>
                      <Typography className="txt-sub-title">
                        This is your last E-Quiz result.
                      </Typography>
                    </Box>
                    <Box className="right-box">
                      <CardMedia
                        component="img"
                        className="img-simu-score"
                        image={zeroScore}
                      />
                      <Typography className="score-value">B</Typography>
                    </Box>
                  </Box>
                  <Box className="offical-simu-score-result">
                    <Box className="offical-box-text">
                      <Typography className="txt-title">
                        Simulation E-Score
                      </Typography>
                      <Typography className="txt-sub-title">
                        Here is the result of your adjustments.
                      </Typography>
                    </Box>
                    <Box className="offical-right-box">
                      <CardMedia
                        component="img"
                        className="img-score-outline"
                        image={star2}
                      />
                      <Typography className="score">D</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <QuizList
                  disable={false}
                  quizData={quizData}
                  saveSimulationData={saveSimulationData}
                  data-testId={"quizListComponent"}
                />
              </Box>
              <Box className="button-wrapper">
                <Button
                  className="btn-secondary"
                  data-testId="btn-closeModal"
                  onClick={modalClose}
                >
                  {configJSON.cancelBtn}
                </Button>
                <Button
                  className={`modal-btn ${modalClasses.primaryButton} primaryButton`}
                  data-testId="btn-save-simulation"
                  onClick={updateSimulationQuiz}
                >
                  {configJSON.saveSimulationBtn}
                </Button>
              </Box>
            </Box>
            <Box className="right-box">
              <Box className="right-header-box">
                <Box>
                  <Typography className="heading">
                    {configJSON.officialEScore}
                  </Typography>
                  <Typography className="sub-title">
                    {configJSON.lastEsocreResultTxt}
                  </Typography>
                </Box>
                <Box className="group">
                  <Box className="overlap-group">
                    <Typography className="group-name">{lastEscore}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="right-box-content">
                <Box className="content-top-header">
                  <Box className="content-box">
                    <Typography className="simulation-score">
                      {configJSON.simulationTxt}
                    </Typography>
                    <Typography className="sub-simulation-score">
                      {configJSON.simulationSubTxt}
                    </Typography>
                  </Box>
                  <Box className="info-wrapper">
                    <Link to="#" onClick={showInfoContent}>
                      <CardMedia
                        component="img"
                        className="info-img"
                        image={info}
                        title=""
                      />
                    </Link>
                  </Box>
                </Box>
                <Box className="display-score">
                  <CardMedia
                    component="img"
                    className="disScoreImg"
                    image={gradeObj.simulationImg}
                    title=""
                  />

                  <Typography className="score">{currentGrade}</Typography>
                  <Box className="comment-box">
                    <Typography className="comment">
                      {currentGrade === "E" ? (
                        "Your E-Score "
                      ) : (
                        <>{configJSON.escoreTxt} </>
                      )}

                      <span className={gradeObj.gradeScoreClass}>
                        {gradeObj.gradeScoreText}
                      </span>
                    </Typography>
                  </Box>
                </Box>
                <Box className="imporove-score-wrap">
                  <Button
                    className="btn-improve-score"
                    //onMouseOver={this.handleOverEffect}
                    data-testId={"improve-score-btn"}
                  >
                    {configJSON.scoreImporveBtn}
                  </Button>
                  {/* {this.state.showTooltip === true ? ( */}
                  <Box className="wrap-black-box">
                    <CardMedia
                      component="img"
                      className={classes.polygonImg}
                      image={polygon}
                      title=""
                    />
                    <Box className="black-box">
                      <Typography className="comment-out">
                        {configJSON.scoreImproveDes}
                        <span className="highlight">
                          {configJSON.simulationDes}
                        </span>{" "}
                        {configJSON.scoreImproveDes2}{" "}
                        <span className="highlight">{configJSON.chatTxt}</span>.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            {isActiveInfoContent && (
              <Box className="info-content-box">
                <Box className="info-heading">
                  <Box className="heading">Simulation E-Score</Box>
                  <Link to="#" className="close-icon" onClick={hideInfoHandler}>
                    <CloseIcon />
                  </Link>
                </Box>
                <Box className="para">
                  <Box className="sub-txt">
                    Use this mode to focus your improvements and see how changes
                    to e-questions affect your overall e-score.
                  </Box>
                </Box>
                <Box className="para">
                  <Box className="sub-txt">
                    Your last official e-score is at the top and the slider
                    inputs are in grey.
                  </Box>
                  <Box className="slider-progress">
                    <Box className="progress-wrap">
                      <Box
                        className="progress-inner"
                        style={{ width: "46.44%" }}
                      ></Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="para">
                  <Box className="sub-txt">
                    Adjust the sliders in this simulation to see what your new
                    E-Score would be!
                  </Box>
                  <Box className="slider-progress slider-wrap">
                    <Slider
                      min={60}
                      max={180}
                      valueLabelDisplay="on"
                      value={80}
                      className="quiz-slider-full-details"
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SimulateFullScreenMode;
// Customizable Area End
