// Customizable Area Start
import React from "react";
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";

export const customStyles = (theme: AugmentedTheme) =>
createStyles({
    stepRootWrapper: {
      display: "flex",
      "& .step-content-root": {
        width: 'calc(100% - 380px)',
        maxWidth: "100%",
        marginLeft: "380px",
        padding: "138px 24px",
        [theme.breakpoints.down("md")]:{
          width: 'calc(100% - 348px)',
          marginLeft: "348px",
        },
        [theme.breakpoints.down("sm")]:{
          width: 'calc(100% - 234px)',
          marginLeft: "234px",
        },
        [theme.breakpoints.down("xs")]:{
          width: '100%',
          marginLeft: "0",
          padding: "24px",
        },
        "& .headingSection": {
          marginBottom: "40px",
        },
      }
    },
    categorySection: {
      maxWidth: "812px",
      width: "100%",
      margin: "0 auto",
      [theme.breakpoints.down("xs")]:{
        maxWidth: "100%",
      },
    }, 
    certificateBox:{
      color: "#94A3B8",
      border: "1px solid #E8ECF2",
      cursor: "pointer",
      display: "flex",
      padding: "15px 16px",
      fontSize: "14px",
      lineHeight: "18px",
      alignItems: "center",
      borderRadius: "6px",
      justifyContent: "space-between",
      marginBottom: '24px',
      minHeight: "57px",
      "& .MuiTypography-root": {
        font: "inherit",
        letterSpacing: "inherit",
      },
      "& .MuiSvgIcon-root": {
        width: "16px",
        height: "16px",
        color: "#94A3B8",
      }
    },  
    selectedCertificate: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      "& .certificateImg": {
        height: "24px",
        marginRight: "8px",
      },
      "& .cardBoxName": {
        fontFamily: "Expletus Sans",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "19px",
        letterSpacing: "0em",
        textAlign: "left",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginRight: "5px",
      },
      "& .close": {
        color: "#94A3B8",
        width: "16px",
        marginLeft: "auto",
        "& .MuiSvgIcon-root": {
          width: "inherit",
          height: "inherit",
        }
      }
    },  
    priceTaxBox:{
      width:'100%',
      border: '1px solid #E8ECF2',
      borderRadius:'6px',
      color:'#4BA3B7',
      textAlign: 'center',
      padding:'18px 16px',
      marginBottom:'32px',
      cursor: 'pointer',
      fontFamily: "Expletus Sans",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "18px",
      [theme.breakpoints.down("xs")]:{
        marginBottom: "32px",
        border: "0",
        textAlign: "left",
        padding: "0",
        marginTop: "8px",
      },
    },
    PriceHeading:{
      fontFamily:'Expletus Sans',
      fontWeight:400,
      fontSize:'16px',
      lineHeight:'24px',
      color:'#2B2B2B',
      marginBottom:'17px',
      [theme.breakpoints.down("xs")]:{
        fontSize:'14px',
        lineHeight:'18px',
      },
    },
    priceDetails:{
        border: '1px solid #E8ECF2',
        borderRadius:'6px',
        padding:'8px 24px 0',
        marginBottom:'15px',
        "& .price-items":{
          padding:'16px 0',
          borderBottom: "1px solid #E8ECF2",
          "&:last-child": {
            borderBottom: "0",
          }
        },
        "& .divider":{
          marginTop:'1vw',
        }
    },
    priceSection:{
        width:'100%',
        "& .price-label":{
          flexGrow:1,
          fontFamily:'Roboto',
          fontWeight:400,
          fontSize:'12px',
          lineHeight:'14px',
          color:'rgba(43, 43, 43, 0.87)',
          letterSpacing: "0.4px",
          [theme.breakpoints.down("xs")]:{
            fontSize:'14px',
            lineHeight:'18px',
            color: "#94A3B8",
          },
        },
        "& .price":{
          fontFamily:'Expletus Sans',
          fontWeight:400,
          fontSize:'16px',
          lineHeight:'24px',
          color:'#2B2B2B',
          marginRight:'2px',
          letterSpacing: "0.15px",
        },
        "& .details-box":{
          marginTop:'8px',
          display:'flex',
          alignItems:'center',
          "& .price-label":{
            [theme.breakpoints.down("xs")]:{
              fontSize:'12px',
              lineHeight:'16px',
              letterSpacing: "0.09px",
            },
          },
        },
        "& .icons":{
          color:'rgba(148, 163, 184, 1)',
          marginLeft:'24px',
          cursor:'pointer',
          width: "20px",
          height: "20px",
          [theme.breakpoints.down("xs")]:{
            marginLeft:'20px',
          },
        }
    },
    trophySection:{
      width:'100%',
      "& .trophy-name":{
        fontFamily:'Expletus Sans',
        fontWeight:400,
        fontSize:'16px',
        lineHeight:'24px',
        color:'#2B2B2B',
        flexGrow: 1,
        letterSpacing: "0.15px",
        [theme.breakpoints.down("xs")]:{
          fontSize:'14px',
          lineHeight:'18px',
        },
      },
      "& .sub-text":{
        fontFamily:'Roboto',
        fontWeight:400,
        fontSize:'12px',
        lineHeight:'14px',
        color:'rgba(43, 43, 43, 0.87)',
        letterSpacing: "0.4px",
        [theme.breakpoints.down("xs")]:{
          letterSpacing: "0.09px",
          color: "#94A3B8",
        },
      },
      "& .details-box":{
        marginTop:'7px',
        marginBottom: "6px",
        display:'flex',
        alignItems:'center',
      },
      "& .icons":{
        color:'rgba(148, 163, 184, 1)',
        cursor:'pointer',
        marginLeft:'24px',
        width: "20px",
        height: "20px",
        [theme.breakpoints.down("xs")]:{
          marginLeft:'20px',
        },
      },
      "& .dot-img":{
        padding:'0 8px',        
        marginTop: "-2px",

      }
  },
  trophyDetails:{
    border: '1px solid #E8ECF2',
    borderRadius:'6px',
    padding:'8px 24px 0',
    marginBottom:'15px',
    "& .torphy-items":{
      padding:'11px 0 14px',
      borderBottom: "1px solid #E8ECF2",
      "&:last-child": {
        borderBottom: "0",
      }
    },
    "& .divider":{
      marginTop:'1vw',
    }
  }, 
    drawerStyle:{
        width: '100%',
        maxWidth: '380px',
        height:'100vh',
        [theme.breakpoints.down("md")]:{
          maxWidth: '348px',
        },
        [theme.breakpoints.down("sm")]:{
          maxWidth: '234px',
        },
        [theme.breakpoints.down("xs")]:{
          display: 'none'
        },
        "&.MuiDrawer-paper":{
            overflowY:'hidden',
        }
    },
    inputField:{
      "& .MuiInputBase-input": {
        fontSize: "60px",
        lineHeight: "80px",
        letterSpacing: "0.1px",
        color: "#000000",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        textAlign:'center',
        [theme.breakpoints.down("xs")]:{
          fontSize: "40px",
          lineHeight: "60px",
        },
        "&::-webkit-input-placeholder": {
          opacity: "0.369",
          color: "rgba(43, 43, 43, 0.87)"
        },
        "&:-ms-input-placeholder": {
          opacity: "0.369",
          color: "rgba(43, 43, 43, 0.87)"
        },
        "&::placeholder": {
          opacity: "0.369",
          color: "rgba(43, 43, 43, 0.87)"
        }
      }
    },
    sidebar:{
        height:'100vh',
        borderLeft: '1px solid #E8ECF2',
        "& .back":{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            padding: '34px 24px',
            color: "rgba(0, 0, 0, 0.87)",
        },
    },
    dropdownStyle: {
      maxWidth: "190px",
      width: "100%",
      overflowY:"auto",
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "24px",
            paddingRight: "24px",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          color: "#2B2B2B",
          padding: "14px 24px",
          "& .MuiListItemText-root": {
            margin: "0",
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
          },
          "& .multiselect-check": {
            display: "none",
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        whiteSpace: "normal",
        "&.newPriceText": {
          width: "100%",
          color:'#4BA3B7',
        },
        "& .MuiTypography-root": {
          font: "inherit",
          letterSpacing: "0",
        },
      },
      "& .MuiList-padding": {
        paddingTop: "4px",
        paddingBottom: "8px",
      },
    },
    heading: {
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "28px",
      color: "#2B2B2B",
      fontFamily: "Expletus Sans",
      letterSpacing: "0",
      marginBottom: "14px",
    },
    mobileBackArrow:{
      display: 'none',
      [theme.breakpoints.down("xs")]:{
        display:'flex',
        alignItems:'center',
        justifyContent: 'space-between',
        marginBottom: '24px',
      },
      "& .step-box":{
        display:'flex',
        alignItems:'center',
      },
      "& .itemBox":{
        display:'none',
        borderRadius: '6px',
        background: 'rgba(75, 163, 183, 0.08)',
        padding: '4px 12px',
        color: '#4BA3B7',
        fontFamily: 'Roboto',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 400,
        letterSpacing: "0.09px",
        [theme.breakpoints.down("xs")]:{
          display:'flex',
        },
      },
      "& .adding-steps":{
        display:'none',
        color: '#94A3B8',
        fontFamily: 'Roboto',
        fontSize: '14px',
        lineHeight: '16px',
        [theme.breakpoints.down("xs")]:{
          display:'flex',
          marginLeft: '10px',
        },
      },
    },
    stepper: {
        // minHeight: "100vh",
        // display: "flex",
        // flexDirection: "column",
        padding: '60px',
        overflowY: "auto",
        height: "calc(100% - 92px)",
        [theme.breakpoints.down("sm")]:{
          padding: "20px 47px 20px 60px",
        },        
        "& .MuiStepConnector-vertical": {
          padding: "0",
          margin: "0",
        },
        "& .MuiStepConnector-line": {
          borderColor: "transparent",
          minHeight: "32px",
        },
        "& .stepper-top": {
          padding: "16px 24px",
          [theme.breakpoints.down("xs")]: {
            padding: "21px 24px",
          },
          "& .stepper-row": {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            margin: "0 -10px",
            [theme.breakpoints.down("sm")]: {
              margin: "0 -5px",
            },
            [theme.breakpoints.down("xs")]: {
              margin: "0",
              justifyContent: "space-between",
            },
            "& .stepper-col": {
              flex: "0 0 33.33%",
              maxWidth: "33.33%",
              padding: "0 10px",
              [theme.breakpoints.down("md")]: {
                flex: "0 0 27%",
                maxWidth: "27%",
              },
              [theme.breakpoints.down("sm")]: {
                padding: "0 5px",
                flex: "unset",
                maxWidth: "unset",
              },
              [theme.breakpoints.down("xs")]: {
                padding: "0",
              },
              "&.stepper-middle": {
                [theme.breakpoints.down("md")]: {
                  flex: "0 0 46%",
                  maxWidth: "46%",
                },
                [theme.breakpoints.down("sm")]: {
                  flex: "unset",
                  maxWidth: "unset",
                },
                [theme.breakpoints.down("xs")]: {
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                },
              },
              "&.stepper-action": {
                [theme.breakpoints.down("sm")]: {
                  marginLeft: "auto",
                },
                [theme.breakpoints.down("xs")]: {
                  display: "none",
                },
              },
              "& .MuiMobileStepper-root": {
                [theme.breakpoints.down("xs")]: {
                  padding: "0",
                  background: "transparent",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: "#94A3B8",
                },
              },
              "& .back-btn-wrap": {
                "& i": {
                  display: "block",
                },
              },
              "& .auth-logo": {
                maxWidth: "117px",
                width: "100%",
                "& img": {
                  maxWidth: "100%",
                },
              },
              "& .button-wrapper": {
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "flex-end",
                "& .secondary-btn": {
                  minWidth: "116px",
                  marginRight: "14px",
                  [theme.breakpoints.down("md")]: {
                    minWidth: "90px",
                    marginRight: "10px",
                    padding: "12px 7px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    minWidth: "unset",
                    marginRight: "5px",
                    padding: "9px 3px",
                    fontSize: "12px",
                    minHeight: "38px",
                  },
                },
                "& .primary-btn": {
                  minWidth: "142px",
                  [theme.breakpoints.down("md")]: {
                    minWidth: "130px",
                    padding: "12px 7px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    minWidth: "unset",
                    padding: "9px 3px",
                    fontSize: "12px",
                    minHeight: "38px",
                  },
                },
              },
            },
          },
        },
        "& .sm-visible": {
          display: "none",
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .stepIcon": {
          backgroundColor: "#fafcfe",
          zIndex: 1,
          color: "#94a3b8",
          width: 48,
          height: 48,
          display: "flex",
          borderRadius: "50%",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid transparent",
          [theme.breakpoints.down("sm")]: {
            width: 32,
            height: 32,
          },
          "& .MuiSvgIcon-root": {
            width: 20,
            height: 20,
          },
          "&.active": {
            backgroundColor: "#4ba3b7",
            color: "#ffffff",
          },
          "&.completed": {
            backgroundColor: "transparent",
            borderColor: "#00ba88",
            color: "#00ba88",
          },
        },
        "& .MuiStepper-root": {
          padding: "0",
          maxWidth: "490px",
          width: "100%",
          margin: "0 auto",
          [theme.breakpoints.down("xs")]: {
            maxWidth: "unset",
            width: "auto",
          },
          "& .step-root": {
            [theme.breakpoints.down("xs")]: {
              display: "none",
            },
            "&.active-step-root": {
              [theme.breakpoints.down("xs")]: {
                display: "block",
                padding: "0",
              },
            },
            "& .MuiStepLabel-root": {
              [theme.breakpoints.down("sm")]: {
                alignItems: "flex-start",
                flexDirection: "column",
              },
            }
          },
          "& .MuiStepConnector-root": {
            [theme.breakpoints.down("xs")]: {
              display: "none",
            },
          },
          "& .MuiStepLabel-iconContainer": {
            paddingRight: "16px",
            [theme.breakpoints.down("sm")]: {
              paddingRight: "0",
              marginBottom: "16px",
            },
            [theme.breakpoints.down("xs")]: {
              display: "none",
            },
          },
        },
        "& .MuiStepLabel-label": {
          "&.MuiStepLabel-active": {
            "& .step-no": {
              color: "#4ba3b7",
            },
            "& .step-label": {
              color: "#2b2b2b",
              [theme.breakpoints.down("xs")]: {
                color: "#4BA3B7",
              },
            },
          },
          "&.MuiStepLabel-completed": {
            "& .step-no": {
              color: "#00ba88",
            },
            "& .step-label": {
              color: "#00ba88",
            },
          },
          "& .step-no": {
            fontSize: "12px",
            lineHeight: "16px",
            color: "#94a3b8",
            fontWeight: 400,
            fontFamily: "Roboto",
            letterSpacing: "0",
            [theme.breakpoints.down("xs")]: {
              display: "none",
            },
          },
          "& .step-label": {
            fontSize: "16px",
            lineHeight: "24px",
            color: "#94a3b8",
            fontWeight: 400,
            fontFamily: "Expletus Sans",
            letterSpacing: "0",
            [theme.breakpoints.down("sm")]: {
              fontSize: "13px",
              lineHeight: "17px",
            },
            [theme.breakpoints.down("xs")]: {
              background: "rgba(75, 163, 183, 0.08)",
              borderRadius: "6px",
              padding: "4px 12px",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.0075em",
              color: "#4BA3B7",
              fontFamily: "Roboto",
              marginRight: "12px",
            },
          },
        },
        "& .step-content-root": {
          display: "flex",
          padding: "37px 24px",
          width: "100%",
          flex: "1",
          [theme.breakpoints.down("xs")]: {
            padding: "18px 0 24px",
          },
        },
        "& .step-content": {
          width: "100%",
          display: "flex",
          "& .member-upload-content": {
            maxWidth: "100%",
            width: "812px",
            margin: "auto",
            [theme.breakpoints.down("xs")]: {
              padding: "0 24px",
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
            },
            "& .heading": {
              fontSize: "24px",
              lineHeight: "30px",
              margin: "0 0 9px",
              color: "#000000",
              letterSpacing: "0",
              fontFamily: "Expletus Sans",
              fontWeight: 400,
              [theme.breakpoints.down("xs")]: {
                fontSize: "22px",
                lineHeight: "26px",
                margin: "0 0 9px",
                color: "#2B2B2B",
              },
            },
            "& .sub-txt": {
              fontSize: "14px",
              lineHeight: "24px",
              margin: "0 0 26px",
              color: "#505050",
              fontFamily: "Roboto",
              fontWeight: 400,
              [theme.breakpoints.down("xs")]: {
                fontSize: "12px",
                lineHeight: "20px",
                margin: "0 0 78px",
                color: "#2B2B2B",
              },
            },
            "& .button-wrapper": {
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              marginTop: "25px",
              [theme.breakpoints.down("xs")]: {
                marginTop: "auto",
              },
              "& .secondary-btn": {
                [theme.breakpoints.down("xs")]: {
                  width: "100%",
                  marginBottom: "24px",
                },
              },
              "& .MuiButton-root": {
                [theme.breakpoints.down("xs")]: {
                  width: "100%",
                  marginBottom: "24px",
                },
                "&:last-child": {
                  [theme.breakpoints.down("xs")]: {
                    marginBottom: "0",
                  },
                },
              },
            },
          },
        },
    },
    basicInfoSection:{
        width:'100%',
        maxWidth: "512px",
        margin: '0 auto',
        "& .map-search": {
          position: "relative",
          "& .custom-input-wrapper": {
            marginBottom: "5px",
          },
          "& .searcListUl": {
            paddingTop: "4px",
            paddingBottom: "8px",
            "& li": {
              padding: "10px 17px",
              fontSize: "14px",
              lineHeight: "18px",
              fontFamily: "Expletus Sans",
              listStyle: "none",
              color: "#2b2b2b",
              cursor: 'pointer',
              transition: 'background-color 0.3s',
              "& strong": {
                color: "#4BA3B7",
              },              
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }
          },
          "& .searchDiv":{
            position: 'absolute',
            width: '100%',
            maxHeight: '215px',
            overflowY: 'auto',
            border: '0',
            boxShadow: '0px 5px 5px 2px rgba(0, 0, 0, 0.22)',
            borderRadius: '6',
            backgroundColor: '#fff',
            zIndex: 999,
          },
        },
        
        "& .heading-box":{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "26px",
        },
        "& .item-box":{
            borderRadius: '6px',
            background: 'rgba(75, 163, 183, 0.08)',
            padding: '4px 12px',
            color: '#4BA3B7',
            fontFamily: 'Roboto',
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 400,
            letterSpacing: "0.09px",
            [theme.breakpoints.down("xs")]:{
              display:'none'
            },
        },
        "& .select-outer": {
          width: "100%",
          marginBottom: "32px",
          [theme.breakpoints.down("xs")]:{
            marginBottom: "16px",
          },
           "& svg":{
            fill:'#94A3B8',  
          },
          "& .error-select":{
            color:'#C7263E',  
          },
          "& .Mui-error":{
            "& .MuiOutlinedInput-notchedOutline":{
              borderColor:'#C7263E',  
            }
          },       
          "& .MuiInputLabel-formControl": {
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "19px",
            letterSpacing: "0.1px",
            color: "#94A3B8",
            display: "flex",
            width: "calc(100% - 46px)",
            "&.MuiFormLabel-filled": {
              width: "auto",
              color : "rgba(43,43,43,0.87)",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.0075em",
              fontFamily: "Roboto",
              fontWeight: "400",
            }
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            letterSpacing: "0",
            color: "#2B2B2B",
            fontWeight: 400,
            fontFamily: "Roboto",
            display:'flex',
            justifyContent:'space-between',            
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            },
          },
          "& .MuiFormLabel-root": {
            "&.Mui-focused": {
              width: "auto",
              color : "rgba(43,43,43,0.87)",
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0.0075em",
              fontFamily: "Roboto",
              fontWeight: "400",
            },
            "& .MuiInputLabel-asterisk": {
              color: "#e86577",
              fontSize: "18px",
              lineHeight: "23px",
              marginLeft: "auto",
            }
          }
        },
        "& .MuiFormHelperText-root": {
          fontSize: "12px",
          letterSpacing: "0.0075em",
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontWeight: 400,
          marginTop: "8px",
          marginBottom: "32px",
          [theme.breakpoints.down("xs")]:{
            marginBottom: "16px",
          },
          "&.error-select": {
            color: "#C7263E",
          },
          "&.Mui-error": {
            color: "#C7263E",
            marginBottom: "0",
          },
          "&.Mui-default": {
            color: "#2B2B2B",
          },
        },
      "& .custom-input-wrapper":{
        marginBottom:'32px',
        [theme.breakpoints.down("xs")]:{
          marginBottom:'16px',
        },
      },
      "& .brandWrapper":{
        marginBottom:'32px',
        [theme.breakpoints.down("xs")]:{
          marginBottom:'16px',
        },
      },
    },
    formSection:{
       "& input::placeholder, textarea::placeholder":{
        opacity:'1 !important',
        color:'#94A3B8',
      },
      "& .form-row-6": {
        display: "flex",        
        gap: "16px",
        [theme.breakpoints.down("xs")]:{
          flexWrap: "wrap",
          gap: "0",
        },
      },
      "& .autocomplete-wrapper": {
        //backgroundColor:"red",
        marginBottom:'32px',
        [theme.breakpoints.down("xs")]:{
          marginBottom:'16px',
        },
      }
    },
    rowSpacing:{
      marginBottom:'32px',
      [theme.breakpoints.down("xs")]:{
        marginBottom:'16px',
      },
    },
   
    withHelperText:{
      "& .form-control":{marginBottom:'0px'}
    },     
    subtitleText: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "18px",
      color: "rgba(43, 43, 43, 0.87)",
      fontFamily: "Expletus Sans",
    },
    formButtons:{
      display:'flex',
      alignItems:'center',
      justifyContent:'space-between',
      marginTop:'32px',
      gap: "16px",
      [theme.breakpoints.down("xs")]:{
        marginTop:'18px',
      },
      "& .backBtn": {
          borderRadius: "6px",
          border: "1px solid #ECF1F4",
          fontSize: "14px",
          lineHeight: "18px",
          padding: "8px 14px",
          backgroundColor: "#fff",
          color: "rgba(43, 43, 43, 1)",
          fontFamily: "Expletus Sans",
          textTransform: "capitalize",
          width: "156px",
          minHeight: "56px",
          [theme.breakpoints.down("xs")]: {
            minHeight: "48px",
          },         
      },
      "& .next-step-btn":{
          borderRadius: "6px",
          border: "1px solid #ECF1F4",
          fontSize: "14px",
          lineHeight: "18px",
          padding: "8px 14px",
          backgroundColor: "#4BA3B7",
          color: "#fff",
          fontFamily: "Expletus Sans",
          textTransform: "capitalize",
          width: "156px",
          minHeight: "56px",
          [theme.breakpoints.down("xs")]: {
            minHeight: "48px",
          }, 
      }
  },
    
    subtext:{
      color:'#94A3B8', 
      fontSize:'12px', 
      lineHeight:'16px', 
      marginTop:'-1vw', 
      marginLeft:'1vw'
    },
    descriptionText: {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: "rgba(43, 43, 43, 0.87)",
        fontFamily: "Roboto",
        marginLeft: "32px",
        letterSpacing: "0.4px",
    },       
    arrowIcon:{
      opacity: 0.7,
      color:'rgba(43, 43, 43, 1)',
    },
    divider: {
      margin: "0.5vw 0 0.5vw 32px",
      [theme.breakpoints.down("xs")]: {
        margin: "1vw 0 1vw 32px"
      }
    },
    infoSection:{
      display:'flex',
      flexWrap: "wrap",
      margin: "0 -12px",
      "& .info-list": {
        flex: "0 0 50%",
        maxWidth: "50%",
        padding: "0 12px",
        marginBottom: "24px",
        [theme.breakpoints.down("xs")]:{
          flex: "0 0 100%",
          maxWidth: "100%",
        }
      },
      "& .categoriesInfo": {
        border: "1px solid #E8ECF2",
        borderRadius: "12px",
        padding: "38px 24px 10px",
        [theme.breakpoints.down("xs")]:{
          padding: "24px 24px 10px",
        }
      },
        "& .categoryBox": {
          display: "flex",
          alignItems: "center",
          marginBottom: "3px",
        },
        "& .subCategoryBox": {
          display: "flex",
          alignItems: "center",
          marginLeft: "32px",
          cursor:'pointer',
          padding:'23px 0',
          borderBottom: "1px solid #E8ECF2",
          "&:last-child":{
            borderBottom: "0",
          },
          "& .product-img": {
            width: "20px",
            marginRight: "12px",
          }
        },
        "& .subCategoryBox-chemical": {
          display: "flex",
          alignItems: "center",
          marginLeft: "32px",
          cursor:'pointer',
          padding:'23px 0',
          "& .product-img": {
            width: "20px",
            marginRight: "12px",
          }
        },
        "& .categoryImg": {
        width: "20px",
        height: "20px",
        [theme.breakpoints.down("xs")]: {
            width: "20px",
            height: "20px"
        }
        },
        "& .comsumableImg":{
          width: "auto",
          height: "20px",
        },
        "& .categoryText": {
          fontSize: "16px",
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          lineHeight: "24px",
          marginLeft: "12px",
          fontWeight: 400,
          letterSpacing: "0.15px",
        },
        "& .subCategoryText": {
          fontWeight: 500,
          fontSize: "14px",
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          lineHeight: "20px",
          flex: "1",
          letterSpacing: "0.1px",
        "& .switchStyle": {
        "& .MuiSwitch-track": {
            backgroundColor: "#d3d9e1",
            opacity: 1,
            [theme.breakpoints.down("xs")]: {
            backgroundColor: "#94A3B8",
            width: "25px",
            height: "10px"
            }
        },
        "& .MuiSwitch-switchBase": {
            color: "#fafafa",
            [theme.breakpoints.down("xs")]: {
            color: "#ffffff"
            },
            "&.Mui-checked": {
            color: "#3691a6",
            "& .MuiSwitch-track": {
                backgroundColor: "#F2F8FC",
                transform: "translateX(2px)"
            }
            }
        },
        "& .MuiSwitch-thumb": {
            boxShadow: "none",
            filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.2))",
            [theme.breakpoints.down("xs")]: {
            width: "15px",
            height: "15px"
            }
        }
      },
    }, 
  },
  iconsBox:{
      width:'17%',
      display:'flex',
      alignItems:'center',
      justifyContent:'space-between',
      margin: '16px 0 0 0',
      cursor:'pointer',
    },
    uploadFileBox:{
      display:'flex', 
      alignItems:'center',
      padding:'20px 0',
      marginTop: '10px',
      borderBottom:'1px solid #E8ECF2',
      "& .image-text":{
        marginLeft:'13px',
        flexGrow:1,
        color: "#2B2B2B",
        fontFamily: "Expletus Sans",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "18px",
      },
      "& .file-close-icon":{
        color:'#94A3B8',
        cursor:'pointer',
        width: "20px",
        height: "20px",
      }
    },
    modalDialog: {
      "& .MuiBackdrop-root, & .backdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502) !important",
        [theme.breakpoints.down("xs")]: {
          backgroundColor: "rgba(34, 89, 128, 0.7) !important",
        },
      },
      backdropFilter : 'blur(3px)',
    },
    modalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      padding: "82px 60px",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("md")]: {
        width: 548,
        padding: "45px 60px"
      },
      [theme.breakpoints.down("sm")]: {
        width: 520,
        padding: "50px 40px"
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        maxWidth: "100%",
        top: "197px",
        borderRadius: "24px 24px 0 0",
        height: "auto",
        maxHeight: "100%",
        padding: "30px 25px"
      },
      "&.price-modal-dialogbox, &.trophy-modal-dialogbox, &.certification-modal-dialogbox": {
        "& .modal-heading": {
          marginBottom: "48px",
        },
      },
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "8px",
      },
      "& .modal-title": {
        fontWeight: 400,
        fontSize: "24px",
        lineHeight: "28px",
        fontFamily: "Expletus Sans",
        color: "rgba(43, 43, 43, 0.87)",
      },
      "& .close-icon": {
        color: "#94A3B8",
        lineHeight: "0",
        cursor:'pointer',
      },
      "& .modal-description": {
        "& .sub-txt": {
          fontSize: "14px",
          lineHeight: "24px",
          color: "#2b2b2b",
          fontWeight: 400,
          fontFamily: "Roboto",
          marginTop: "0",
          marginBottom: "27px",
        },
        "& .age-vintage-box":{
          display:'flex',
          alignItems:'center',
          justifyContent: "center",
          gap: "16px",
          margin: '90px 0 32px',
          "& .age-box":{
            padding:'3px 8px',
            backgroundColor:'rgba(236, 241, 244, 5)',
            fontFamily: 'Expletus Sans',
            fontSize: '14px',
            lineHeight: "18px",
            fontWeight: 500,
            textTransform: 'capitalize',
            color:'rgba(43, 43, 43, 1)',
            borderRadius: '6px',
          },
          "& .text-box":{
            fontFamily: 'Expletus Sans',
            fontSize: '14px',
            lineHeight: "18px",
            fontWeight: 500,
            textTransform: 'capitalize',
            color:'#94A3B8',
            cursor:'pointer',
            padding: "3px 8px",
          },
        },
        '& .age-vintage-feild':{
          textAlign:'center',
        },        
        "& .cardSection":{
          display:'flex',
          flexWrap: "wrap",
          margin: '0 -11px',
          "& .cardBoxInner": {
            padding: '0 11px',
            flex: "0 0 50%",
            maxWidth: "50%",
            marginBottom: "16px",
            [theme.breakpoints.down("xs")]: {
              flex: "0 0 100%",
              maxWidth: "100%",
            },
            "& .cardBox":{
              display:'flex',
              alignItems:'center',
              borderRadius: "6px",
              border: "1px solid #E8ECF2",   
              padding: "8px 16px",   
              height: "100%",                    
              "& .cardBoxName": {
                fontSize: "14px",
                lineHeight: "18px",
                color: "#2b2b2b",
                fontWeight: 500,
                fontFamily: "Expletus Sans",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
              "& .MuiCheckbox-root": {
                color: "#E8ECF2",
                padding: "4px",
                marginLeft: "auto",
                "&.Mui-checked": {
                  color: "#4BA3B7",
                }
              }
            },
            "& .certificateImg":{
              width:'52px',
              height: '52px',
              marginRight: "5px",
            },
          },         
        },                
        "& .btn-box":{
          textAlign:'center',
          marginTop: '48px',
        },
        "& .primary-btn":{
          borderRadius: "6px",
          border: "1px solid #ECF1F4",
          minHeight: "56px",
          fontSize: "14px",
          lineHeight: "18px",
          marginBottom: "17px",
          width:'100%',
          padding: "8px 14px",
          backgroundColor: "#4BA3B7",
          color: "#fff",
          fontFamily: "Expletus Sans",
          textTransform: "capitalize",
          [theme.breakpoints.down("xs")]: {
            minHeight: "48px",
          }, 
        }
      },
      "& .select-outer": {
        width: "100%",
        "& .error-select":{
          color:'#C7263E',  
        },
        "& .Mui-error":{
          "& .MuiOutlinedInput-notchedOutline":{
            borderColor:'#C7263E',  
          }
        },  
        "& .MuiInputLabel-formControl": {
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "19px",
          letterSpacing: "0.1px",
          color: "#94A3B8",
          display: "flex",
          width: "calc(100% - 46px)",
          "&.MuiFormLabel-filled": {
            width: "auto",
            color : "rgba(43,43,43,0.87)",
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.0075em",
            fontFamily: "Roboto",
            fontWeight: "400",
          }
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0",
          color: "#2B2B2B",
          fontWeight: 400,
          fontFamily: "Roboto",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
            },
          },
        },
        "& .MuiFormLabel-root": {
          "&.Mui-focused": {
            width: "auto",
            color : "rgba(43,43,43,0.87)",
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.0075em",
            fontFamily: "Roboto",
            fontWeight: "400",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#e86577",
            fontSize: "18px",
            lineHeight: "23px",
            marginLeft: "auto",
          }
        },        
      },
      "& .taxBox":{
        display:'flex',
        alignItems:'center',
        "& .tax-text":{
          fontSize: '14px',
          fontFamily: 'Expletus Sans',
          fontWeight: 500,
        },
      },
    
      "& .text-box":{
          fontFamily: 'Expletus Sans',
          fontSize: '14px',
          fontWeight: 500,
          textTransform: 'uppercase',
          color:'rgba(0, 0, 0, 0.2)',
      },
      "& .switchStyle": {
        "& .MuiSwitch-track": {
          backgroundColor: "#d3d9e1",
          opacity: 1,
          [theme.breakpoints.down("xs")]: {
            backgroundColor: "#94A3B8",
            width: "25px",
            height: "10px"
          }
        },
        "& .MuiSwitch-switchBase": {
          color: "#fafafa",
          [theme.breakpoints.down("xs")]: {
            color: "#ffffff"
          },
          "&.Mui-checked": {
            color: "#3691a6",
            "& .MuiSwitch-track": {
              backgroundColor: "#F2F8FC",
              transform: "translateX(2px)"
            }
          }
        },
        "& .MuiSwitch-thumb": {
          boxShadow: "none",
          filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.2))",
          [theme.breakpoints.down("xs")]: {
            width: "15px",
            height: "15px"
          }
        }
      },
      "& .switchSecondaryStyle": {
        "& .MuiSwitch-track": {
          backgroundColor: "#ECF1F4",
          borderRadius: "9999px",
          opacity: 1,
        },
        "&.MuiSwitch-root": {
          width: "60px",
          height: "34px",
          padding: "4px",
          "& .MuiIconButton-root": {
              padding: "8px",
          },
        },
        "& .MuiSwitch-switchBase": {
          color: "#ffffff",
          "&.Mui-checked": {
            color: "#ffffff",
            transform: "translateX(26px)",
            "& + .MuiSwitch-track": {
              backgroundColor: "#3691a6",
            }
          }
        },
        "& .MuiSwitch-thumb": {
          boxShadow: "none",
          filter: "none",
          width: "17px",
          height: "17px",
        }
      }
    },
    newLabelPopover: {
      boxShadow: "0px 5px 10px 2px rgba(0, 0, 0, 0.12)",
      borderRadius: "12px",
      background: "#FFF",
      top: "218px !important",
      left:'auto !important',
      right: "60px",
      position: "absolute",
      width: "calc(680px - 120px)",
      [theme.breakpoints.down("md")]:{
        width: "calc(548px - 120px)",
        top: "178px !important",
      },
      [theme.breakpoints.down("sm")]:{
        width: "calc(520px - 80px)",
        top: "184px !important",
        right: "40px",
      },
      [theme.breakpoints.down("xs")]:{
        width: "470px",
        maxWidth:" 80%",
        right: "25px",
        top: "164px !important",
      },
      // [theme.breakpoints.down("xs")]:{
      //   top: "31vw !important",
      //   left:'30% !important',
      //   width:'60%',
      // },
      // "@media(min-width: 750px)":{
      //   top: "16vw !important",
      //   left:'42% !important',
      //   width:'50%',
      // },
      // "@media(min-width: 1000px)":{
      //   top: "15vw !important",
      //   left:'60% !important',
      //   width:'35%',
      // },
      // "@media(min-width: 1200px)":{
      //   top: "15.5vw !important",
      //   left:'69% !important',
      //   width:'26%',
      // },
    },
    newLabelBody:{
      padding:'16px 24px 24px',
      "& .button-box":{
        display: 'flex',
        alignItems:'center',
        justifyContent: 'flex-end',
        marginTop:'16px',
        gap: "24px",
        "& .cancel-btn":{
          color:'#94A3B8',
          fontFamily: 'Expletus Sans',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight:'19px',
          textTransform: 'capitalize',
          letterSpacing: "normal",
          padding: "0",
          minWidth: "unset",
        },
        "& .create-btn":{
          fontWeight: 500,
          fontSize: '14px',
          fontFamily: 'Expletus Sans',
          lineHeight:'19px',
          color:'#4BA3B7',
          textTransform: 'capitalize',
          letterSpacing: "normal",
          padding: "0",
          minWidth: "unset",
        },
      },      
      "& .newlabel-feild": {
          borderBottom: '1px solid #E8ECF2',
          [theme.breakpoints.down("xs")]: {
              width: "100%"
          },
          "& .MuiInput-root": {
              width: "100%",
              maxWidth: "100%",
          },
          "& .MuiSvgIcon-root": {
              fontSize: "22px",
              color: "#000000",
              [theme.breakpoints.down("sm")]: {
              fontSize: "20px"
              }
          },
          "& .MuiInputAdornment-positionStart": {
            marginRight: "13px"
          },
          "& .MuiInputBase-input": {
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.1px",
              fontWeight: 500,
              color: "#000000",
              fontFamily: "Expletus Sans",
              "&::-webkit-input-placeholder": {
                color: "#000000",
                opacity: 0,
              },
              "&:-ms-input-placeholder": {
                color: "#000000 !important",
                opacity: 0,
              },
              "& ::placeholder": {
                color: "#000000",
                opacity: 0,
              },
          },
      },
    },
    colorBox:{
      display:'flex',
      alignItems: 'center',
      justifyContent:'space-between !important',
    },
    attachment:{
      color:"#2B2B2B",
      fontFamily: "Expletus Sans",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "18px",
      "& .MuiBox-root":{
        width:'100%',
        color: "rgba(43, 43, 43, 0.87)",
        justifyContent: 'flex-start',
        fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: "0.09px",
        "& p":{
          marginLeft: "5px",
          "& a":{
            color: "#4BA3B7",
            fontWeight: 700,
            textDecoration:'none',  
            cursor: "pointer",     
          },
        },
      },
      "& img":{
        marginLeft:"5px",
        marginRight:"5px",
      }
    },
    // item basic info style
  uploadText:{
    fontFamily: 'Expletus Sans',
    fontWeight: 500,
    fontSize: '14px',
    color:'#2B2B2B',
    lineHeight: '18px',
  },
  browseBox:{
    display:'flex',
    alignItems:'center',    
    width: "100%",    
    justifyContent: "flex-start",
    marginTop: "16px",
  "& .pinImg":{
      width: '16px',
      height:'16px',
  },
  "& .upload-text":{
      marginLeft:'10px',
      color: "rgba(43, 43, 43, 0.87)",
      fontSize: "12px",
      fontFamily: "Roboto",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.09px",
  },
  "& .span-text":{
      fontWeight:700,
      color:'#4BA3B7',
      cursor:"pointer",
  }
  },
  vintageBox:{
  width:'100%',
  display:'flex',
  alignItems:'center',
  justifyContent:'space-between',
  border: '1px solid #E8ECF2',
  borderRadius:'5px',
  color:'#4BA3B7',
  padding:'9px 16px',
  marginBottom:'32px',
  cursor: 'pointer',
  [theme.breakpoints.down("xs")]:{
    marginBottom:'20px',
  },
  "& .age-vintage-box":{
    display:'flex',
    alignItems:'center',
    gap: "16px",
  },
  "& .age-box":{
    padding:'3px 8px',
    backgroundColor:'rgba(236, 241, 244, 5)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: "18px",
    fontWeight: 400,
    textTransform: 'capitalize',
    color:'rgba(43, 43, 43, 1)',
    borderRadius: '6px',
  },
  "& .text-box":{
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: "18px",
    fontWeight: 400,
    textTransform: 'capitalize',
    color:'#94A3B8',
    cursor:'pointer',
    padding: "3px 8px",
  },
  "& .item-age-value":{
    color:'#000000',
    fontSize: '26px',
    fontWeight: 600,
    fontFamily: "Expletus Sans",
    lineHeight:'36px',
    }
  },
  ageErrorText:{
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: "#C7263E",
  },
  modalsubtxt: {
    fontSize: "14px",
    lineHeight: "24px",
    color: "#2b2b2b",
    fontWeight: 400,
    fontFamily: "Roboto",
    marginTop: "0",
    marginBottom: "27px"
  },
  checked: {
    "&:hover": {
      backgroundColor: "transparent"
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      "&:hover": {
        backgroundColor: "transparent"
      }
    }
  },
});

// Customizable Area End