// Customizable Area Start
import React from "react";
import { Box, Typography, Button, InputLabel } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import CreateAccountControllerWeb, {
  Props,
} from "./CreateAccountContoller.Web";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AuthLeft from "../../../components/src/AuthLeft.web";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import Footer from "../../../components/src/Footer.web";
import AppHeader from "../../../components/src/AppHeader.web";
import FormControl from "@material-ui/core/FormControl";
export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    overrides: {
      MuiButton: {
        primaryButton: {},
        socialButton: {},
      },
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "7px",
      // filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "16px",
      lineHeight: "21px",
      color: "#f2f8fc",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      width: "100%",
      padding: "12px 10px",
      minHeight: "56px",
      marginBottom: "30px",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    socialButton: {
      fontSize: "14px",
      lineHeight: "18px",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      width: "100%",
      color: "#2b2b2b",
      textTransform: "unset",
      background: "transparent",
      padding: "12px 10px",
      marginBottom: "42px",
      fontWeight: 500,
      minHeight: "56px",
      fontFamily: "Expletus Sans",
      transition: "all 0.5s ease-in-out",
      [theme.breakpoints.down("md")]: {
        marginBottom: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        minHeight: "46px",
        padding: "8px 6px",
      },
      "&:hover": {
        background: "transparent",
      },
      "& .MuiButton-startIcon": {
        width: "26px",
        height: "26px",
        marginRight: "29px",
        [theme.breakpoints.down("md")]: {
          width: "20px",
          height: "20px",
          marginRight: "15px",
        },
      },
    },
    logoutButton: {
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      width: "100%",
      minWidth: "94px",
      padding: "10px 16px",
      minHeight: "48px",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        padding: "8px 6px",
      },
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    formLink: {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0",
      color: "#4ba3b7",
      fontWeight: 500,
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      fontFamily: "Expletus Sans",
    },
    fieldRequirement: {
      marginTop: "7px",
      marginBottom: "40px",
      [theme.breakpoints.down("md")]: {
        marginBottom: "28px",
      },
      "& .requirement-title": {
        fontSize: "12px",
        lineHeight: "16px",
        marginBottom: "14px",
        letterSpacing: "0",
        color: "#505050",
        fontWeight: "400",
        fontFamily: "Expletus Sans",
      },
      "& .requirement-list": {
        padding: "0",
        display: "flex",
        flexWrap: "wrap",
        margin: "0 -10px",
        "& .list-icon": {
          lineHeight: "0",
          marginRight: "5px",
          "& .MuiSvgIcon-root": {
            width: "10px",
            height: "10px",
            color: "#94a3b8",
            "&.check-icon": {
              color: "#00ba88",
            },
            "&.clear-icon": {
              color: "#bf3e50",
            },
          },
        },
        "& .list-item": {
          width: "50%",
          padding: "0 10px",
          fontSize: "12px",
          lineHeight: "16px",
          marginBottom: "6px",
          letterSpacing: "0",
          color: "#000000",
          fontWeight: "400",
          fontFamily: "Expletus Sans",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
          },
        },
      },
    },
    orTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      fontSize: "16px",
      lineHeight: "20px",
      marginBottom: "60px",
      color: "#94a3b8",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      [theme.breakpoints.down("md")]: {
        marginBottom: "40px",
      },
      "&:before": {
        content: '""',
        position: "absolute",
        width: "100%",
        top: "50%",
        borderBottom: "1px solid #e8ecf2",
      },
      "& span": {
        position: "relative",
        background: "#ffffff",
        padding: "0 10px",
      },
    },
    authWrapper: {
      textAlign: "left",
      "& img": {
        maxWidth: "100%",
      },
      "& .auth-inner-block": {
        display: "flex",
        flexWrap: "wrap",
        "& .auth-right": {
          flex: "1",
          padding: "60px 60px 142px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          [theme.breakpoints.down("md")]: {
            padding: "60px 60px 83px",
          },
          [theme.breakpoints.down("sm")]: {
            padding: "60px 60px 139px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "24px 24px 99px",
          },
          "& .right-inner-content": {
            maxWidth: "510px",
            width: "100%",
            margin: "auto",
            [theme.breakpoints.down("md")]: {
              maxWidth: "400px",
            },
          },
          "& .right-inner": {
            display: "flex",
            flexDirection: "column",
            height: "100%",
          },
          "& .back-logout-wrap": {
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            margin: "0 0 90px",
            [theme.breakpoints.down("md")]: {
              margin: "0 0 50px",
            },
          },
          "& .back-btn-wrap": {
            "& i": {
              display: "block",
            },
          },
        },
      },
      "& .heading": {
        fontSize: "24px",
        lineHeight: "28px",
        margin: "0 0 20px",
        color: "rgba(0, 0, 0, 0.87)",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        margin: "0 0 48px",
        color: "#505050",
        fontFamily: "Roboto",
        fontWeight: 400,
        letterSpacing: "0.25px",
      },
      "& .form-info-wrapper": {
        margin: "0 0 12px",
      },
      "& .form-heading": {
        fontSize: "16px",
        lineHeight: "20px",
        margin: "0 0 20px",
        color: "rgba(0, 0, 0, 0.87)",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        letterSpacing: "0.15px",
      },
      "& .select-outer": {
        width: "100%",
        "& .MuiInputLabel-formControl": {
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "19px",
          letterSpacing: "0.1px",
          color: "#94A3B8",
          display: "flex",
          width: "calc(100% - 46px)",
          "&.MuiFormLabel-filled": {
            width: "auto",
            color : "rgba(43,43,43,0.87)",
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.0075em",
            fontFamily: "Roboto",
            fontWeight: "400",
          }
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0",
          color: "#000000",
          opacity: "0.671",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#e8ecf2",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
            },
          },
        },
        "& .MuiFormLabel-root": {
          "&.Mui-focused": {
            width: "auto",
            color : "rgba(43,43,43,0.87)",
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.0075em",
            fontFamily: "Roboto",
            fontWeight: "400",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#e86577",
            fontSize: "18px",
            lineHeight: "23px",
            marginLeft: "auto",
          }
        }
      },
      "& .form-row": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        margin: "0 -12px",
        "& .form-col": {
          padding: "0 12px",
          flex: "0 0 100%",
          maxWidth: "100%",
        },
        "& .col6": {
          flex: "0 0 50%",
          maxWidth: "50%",
          [theme.breakpoints.down("xs")]: {
            flex: "0 0 100%",
            maxWidth: "100%",
          },
        },
      },
      "& .page-link": {
        textAlign: "right",
        marginBottom: "95px",
        [theme.breakpoints.down("md")]: {
          marginBottom: "60px",
        },
        [theme.breakpoints.down("xs")]: {
          marginBottom: "40px",
        },
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#505050",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        "& .form-link": {
          marginLeft: "10px",
        },
      },
    },
    wrapper: {
      minHeight: "100vh",
      paddingBottom: "142px",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "105px",
      },
    },
    authMainWrapper: {
      "& .wrapper": {
        paddingBottom: "0",
      },
      "& .header-wrapper": {
        display: "none",
        [theme.breakpoints.down("sm")]: {
          display: "flex",
        },
      },
      "& .footer-wrapper": {
        marginLeft: "576px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "384px",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "0",
        },
      },
    },
    innerWrapper: {
      margin: "auto",
      width: "100%",
      "& .form-col.billing-select": {
        marginBottom: "28px",
        "& .error-msg": {
          color: "#C7263E",
          fontSize: "12px",
          letterSpacing: "0.0075em",
          fontFamily: "Roboto",
          margin: "4px 14px 0 14px",
        },
        "&.error-select": {
          "& .css-tlfecz-indicatorContainer": {
            "& .css-6q0nyr-Svg": {
              fill: "#C7263E",
            },
          },
          "& .css-yk16xz-control": {
            borderColor: "#C7263E",
          },
          "& .css-1wa3eu0-placeholder": {
            color: "#C7263E",
          },
        },
      },
    },

    selectWrapper: {
      borderWidth: 1,
      borderColor: "#767676",
      borderRadius: 2,
      "& [class*='-menu']": {
        zIndex: "99",
      },
      "& .css-g1d714-ValueContainer": {
        paddingLeft: "12px",
      },
      "& .css-yk16xz-control": {
        backgroundColor: "#ffffff",
        borderColor: "#e8ecf2",
        borderRadius: "6px",
        minHeight: "56px",
        "&:hover, &:focus": {
          borderColor: "#e8ecf2",
        },
      },
      "& .css-1pahdxg-control": {
        backgroundColor: "#ffffff",
        borderColor: "#000000",
        borderRadius: "6px",
        minHeight: "56px",
        boxShadow: "none",
        "&:hover": {
          borderColor: "#000000",
        },
      },
      "& .css-1wa3eu0-placeholder": {
        fontSize: "14px",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        lineHeight: "18px",
        letterSpacing: "0",
        color: "#94a3b8",
      },
      "& .css-1okebmr-indicatorSeparator": {
        backgroundColor: "transparent",
      },
      "& .css-tlfecz-indicatorContainer": {
        "& .css-6q0nyr-Svg": {
          width: "24px",
          height: "24px",
          fill: "#94a3b8",
        },
      },
      "& .css-1uccc91-singleValue": {
        fontSize: "14px",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        lineHeight: "18px",
        letterSpacing: "0",
        color: "#000000",
      },
    },
    dropdownStyle: {
      maxWidth: "190px",
      width: "100%",
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "24px",
            paddingRight: "24px",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          color: "#2B2B2B",
          padding: "14px 24px",
          "& .MuiListItemText-root": {
            margin: "0",
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
          },
          "& .multiselect-check": {
            display: "none",
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        whiteSpace: "normal",
        "& .MuiTypography-root": {
          font: "inherit",
          letterSpacing: "0",
        },
      },
      "& .MuiList-padding": {
        paddingTop: "4px",
        paddingBottom: "8px",
      },
    },
  });
// Customizable Area End
class CreateProfile extends CreateAccountControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
   // Customizable Area Start
   // Customizable Area End

  render() {
     // Customizable Area Start
    const { classes } = this.props;
    const countryError = this.state.error.errorcountryNotValid.message;
    const stateError = this.state.error.errorstateNotValid.message;
    const cityError = this.state.error.errorcityNotValid.message;
    return (
      <Box className={`${classes.authMainWrapper}`}>
        <Box className={`wrapper ${classes.wrapper}`}>
          <AppHeader />
          <Box className={classes.innerWrapper}>
            <Box className={classes.authWrapper}>
              <Box className="auth-inner-block">
                <AuthLeft />
                <Box className="auth-right">
                  <Box className="right-inner">
                    <Box className="back-logout-wrap">
                      <Box className="back-btn-wrap">
                        {/* <i>
                          <svg
                            className="MuiSvgIcon-root"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                          </svg>
                        </i> */}
                      </Box>
                      <Box className="logout-wrap">
                        <Button
                          className={classes.logoutButton}
                          onClick={this.btnLogout}
                        >
                          Logout
                        </Button>
                      </Box>
                    </Box>
                    <Box className="right-inner-content">
                      <Typography className="heading" variant="h1">
                        {this.createAccountlabelHeader}
                      </Typography>
                      <Box component="p" className="sub-txt">
                        {this.createAccountlabelHeaderHelperText}
                      </Box>
                      <Box component="form">
                        <Box className="form-info-wrapper">
                          <Typography className="form-heading" variant="h2">
                            Personal Information
                          </Typography>
                          <Box className="form-row">
                            <Box className="form-col col6">
                              <CustomInputWeb
                                type="text"
                                isRequired={true}
                                errors={
                                  this.state.error.errorFirstnameNotValid
                                    .message
                                }
                                label={this.labelFirstName}
                                value={this.state.firstName}
                                onChange={(event) =>
                                  this.setState({
                                    firstName: event.target.value,
                                  })
                                }
                              />
                            </Box>
                            <Box className="form-col col6">
                              <CustomInputWeb
                                type="text"
                                isRequired={true}
                                errors={
                                  this.state.error.errorlastNameNotValid.message
                                }
                                label={this.labelLastName}
                                value={this.state.lastName}
                                onChange={(event) =>
                                  this.setState({
                                    lastName: event.target.value,
                                  })
                                }
                              />
                            </Box>
                          </Box>
                        </Box>

                        <Box className="form-info-wrapper">
                          <Typography className="form-heading" variant="h2">
                            Business Information
                          </Typography>
                          <Box className="form-row">
                            <Box className="form-col">
                              <CustomInputWeb
                                type="text"
                                isRequired={true}
                                errors={
                                  this.state.error.errorcompanyNameNotValid
                                    .message
                                }
                                label={this.labelCompanyName}
                                value={this.state.companyName}
                                onChange={(event) =>
                                  this.setState({
                                    companyName: event.target.value,
                                  })
                                }
                              />
                            </Box>
                          </Box>
                        </Box>

                        <Box className="form-info-wrapper">
                          <Typography className="form-heading" variant="h2">
                            Billing Address
                          </Typography>
                          <Box className="form-row">
                            <Box
                              className={`form-col col6 billing-select ${countryError &&
                                "error-select"}`}
                            >
                              <FormControl required className="select-outer" variant="outlined">
                                <InputLabel id="choose-country">
                                  Country
                                </InputLabel>
                                <Select
                                  labelId="choose-country"
                                  id="choose-country"
                                  value={this.state.countryName}
                                  label="Select Country"
                                  name="country"
                                  disabled={this.state.countryData.length === 0 && true}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                    classes: { paper: classes.dropdownStyle },
                                  }}
                                  onChange={this.countriesHandler}
                                >
                                  {this.state.countryData.map(
                                    (country: any) => {
                                      return (
                                        <MenuItem
                                          key={country.value}
                                          value={country.label}
                                        >
                                          {country.label}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                              {countryError ? (
                                <p className="error-msg">{countryError}</p>
                              ) : (
                                ""
                              )}
                            </Box>
                            <Box
                              className={`form-col col6 billing-select ${stateError &&
                                "error-select"}`}
                            >
                              <FormControl required className="select-outer"  variant="outlined">
                                <InputLabel id="choose-state">
                                  State
                                </InputLabel>
                                <Select
                                  labelId="choose-state"
                                  id="choose-state"
                                  value={this.state.stateName}
                                  label="Select State"
                                  name="state"
                                  disabled={this.state.stateData.length === 0 && true}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                    classes: { paper: classes.dropdownStyle },
                                  }}
                                  onChange={this.statesHandler}
                                >
                                  {this.state.stateData.map((state: any) => {
                                    return (
                                      <MenuItem
                                        key={state.value}
                                        value={state.label}
                                      >
                                        {state.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              {stateError && (
                                <p className="error-msg">{stateError}</p>
                              )}
                            </Box>
                            <Box className="form-col col6">
                              <CustomInputWeb
                                type="text"
                                label={this.labelPostalCode}
                                value={this.state.postalcode}
                                errors={this.state.error.errorPostalCode.message}
                                // onChange={(event) =>
                                //   this.setState({
                                //     postalcode: event.target.value,
                                //   })
                                  onChange={(event) => this.postalCodeHandler(event)}
                                  data-test-id="postal-code-input"
                              />
                            </Box>
                            <Box
                              className={`form-col col6 billing-select ${stateError &&
                                "error-select"}`}
                            >
                              <FormControl className="select-outer" variant="outlined">
                                <InputLabel id="choose-city">
                                  City
                                </InputLabel>
                                <Select
                                  labelId="choose-city"
                                  id="choose-city"
                                  //value={this.state.countryName}
                                  disabled={this.state.cityData.length === 0 && true}
                                  label="Select City"
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                    classes: { paper: classes.dropdownStyle },
                                  }}
                                  onChange={this.citiesHandler}
                                >
                                  {this.state.cityData.map((city: any) => {
                                    return (
                                      <MenuItem
                                        key={city.value}
                                        value={city.label}
                                      >
                                        {city.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              {/* {cityError && (
                                <p className="error-msg">{cityError}</p>
                              )} */}
                            </Box>
                            <Box className="form-col col12">
                              <CustomInputWeb
                                type="text"
                                isRequired={true}
                                errors={
                                  this.state.error.errorcompanyaddressNotValid
                                    .message
                                }
                                label={this.labelCompanyAddress}
                                value={this.state.companyAddress}
                                onChange={(event) =>
                                  this.setState({
                                    companyAddress: event.target.value,
                                  })
                                }
                              />
                            </Box>
                          </Box>
                        </Box>

                        <Button
                          className={classes.primaryButton}
                          onClick={this.createAccount}
                          data-testid="createAccountBtn"
                        >
                          {this.createAccountSubmitBtn}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    );
     // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(CreateProfile);
// Customizable Area End
