// Customizable Area Start
import React, { useState, useMemo } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import {
  ListSubheader,
  InputAdornment,
  Box,
  InputLabel,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import { checkBlack } from "../assets";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { configJSON } from "./AccountCreationController.web";

const blankArr: any = [];

const containsText = (text: string, searchTextTemp: string) =>
  text.toLowerCase().indexOf(searchTextTemp.toLowerCase()) > -1;

export default function TeamSort(props: any) {
  const {
    classes,
    selectTeamOptions,
    onSelectedOptions,
    selectedTeam,
    clearAction,
    teamStateName,
    t
  } = props;

  const teamNames = useMemo(
    () =>
      selectTeamOptions.map(
        (item: { attributes: { id: string; title: string } }) => ({
          id: item.attributes.id,
          title: item.attributes.title,
        })
      ),
    [selectTeamOptions]
  );

  const [searchText, setSearchText] = useState("");

  const displayTeamOptions = useMemo(
    () =>
      teamNames.filter((obj: { title: string }) =>
        containsText(obj.title, searchText)
      ),
    [teamNames, searchText]
  );

  const getLabel = (value: any, def: string = "") => {
    const selectedItemCount = value.length;
    if (selectedItemCount === 0 || value == undefined || value == null) {
      return def || t(`${configJSON.ChooseOptionTxt}`);
    } else if (selectedItemCount === 1 && def === "Team") {
      const result = teamNames.find((obj: any) => obj.id === value[0]);
      return result.title;
    } else if (selectedItemCount === 1) {
      return value;
    } else if (selectedItemCount > 1) {
      return <>{selectedItemCount} items selected</>;
    }
  };

  const mediumScreen = useMediaQuery("(max-width:599px)");

  return (
    <Box className="top-middle">
      <Box className="table-selection">
        <FormControl
          className="select-control multi-select"
          variant={mediumScreen ? "outlined" : "standard"}
        >
          {selectedTeam.length > 1 && (
            <ClearIcon onClick={clearAction} className="multiple-clear" />
          )}
          <InputLabel className="multiselect-label" id="team-multiple-checkbox">
            Team
          </InputLabel>
          <Select
            labelId="team-multiple-checkbox-label"
            id="team-multiple-checkbox"
            multiple
            value={selectedTeam}
            defaultValue={blankArr}
            onChange={(event, type) => onSelectedOptions(event, teamStateName)}
            onClose={() => setSearchText("")}
            renderValue={() => getLabel(selectedTeam, "Team")}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: { paper: `${classes.dropdownStyle} multiSelect` },
            }}
            displayEmpty
            data-testid="filterTeam"
          >
            <ListSubheader>
              <CustomInputWeb
                placeholder="Search here"
                fullWidth={true}
                autoFocus={true}
                onChange={(e) => setSearchText(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>

            {displayTeamOptions.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                <ListItemText primary={option.title} />
                <Box className="multiselect-check">
                  <img src={checkBlack} alt="sort-group" />
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}
// Customizable Area End