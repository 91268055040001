import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
import { DateObject } from "react-multi-date-picker";
const baseConfig = require("../../../framework/src/config");
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  openViewModal: boolean;
  closeViewModal: () => void;
  dealId : string;
  duplicateHandler:(dealId: string) => void;
  deleteDealHandler: (deleteDealId: string) => void;
  editDealHAndler: (event:any, dealId: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  viewTabsValue: number;
  ProjectTaskTabsValue: number;
  dealTaskList : any;
  isLoading: boolean;
  singleDealDetail: any;
  dealActivityLogs: any;
  isActivityLogsFilter:boolean;
  filterlogDates: DateObject[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ViewDealsControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  viewSingleDealAPICallId: string = "";
  viewDealActivityAPICallId: string ="";
  filterDealsActivityLogAPI:string = "";
  userSessionData: any;
  userToken: any;
  userRole:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      viewTabsValue: 0,
      ProjectTaskTabsValue: 0,
      dealTaskList: [],
      isLoading: false,
      singleDealDetail: [],
      dealActivityLogs: [],
      isActivityLogsFilter: false,
      filterlogDates:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.userRole = this.userToken.data.attributes.job_type;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
     // Customizable Area Start
     if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          if(apiRequestCallId === this.viewSingleDealAPICallId){
            this.setState({isLoading: false})
             this.setState({singleDealDetail : responseJson.data})
          }

        }
      }
      }
    // Customizable Area End
    }
  
   // Customizable Area Start
   async componentDidMount() {
    await this.viewDealDetail();
   
    
    const lang = localStorage.getItem("lang") ?? "en";
    await i18n.changeLanguage(lang);
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }  
  viewDealDetail = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewSingleDealAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewSingleDeal + `id=${this.props.dealId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGET
    );

    //* Making Network Request
    this.setState({isLoading: true})

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
   


   setViewTabsValue = (obj: any, val: any) => {
    this.setState({
      viewTabsValue: val,
    });
  };
  setProjectTaskTabsValue = (obj: any, val: any) => {
    this.setState({
      ProjectTaskTabsValue: val,
    });
  };
   // Customizable Area End
}
