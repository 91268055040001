// Customizable Area Start
import React from "react";
import { Typography, Slider, Box } from "@material-ui/core";
interface TakeQuizProps {
  quizData: any;
  quizTitle: string;
  quizCaption: string;
  saveData: (id: number, newValue: number) => void;
}

const TakeQuizWeb = (props: TakeQuizProps) => {
  const { quizData, quizTitle, quizCaption, saveData } = props;

  return (
    <Box className="quiz-question-wrapper">
      <Box className="score-heading">
        <Typography className="heading" variant="h1">
          {quizTitle}
        </Typography>
        <Box component="p" className="sub-txt">
          {quizCaption}
        </Box>
      </Box>
      {quizData.map((question: any, index: number) => {
        const {
          id,
          title,
          min_score_value,
          max_score_value,
          answer,
        } = question;
        const interval = (max_score_value - min_score_value) / 4;
        const marks = Array.from({ length: 5 }, (_, i) => ({
          value: min_score_value + i * interval,
          label: (
            <>
              {" "}
              <span>{String.fromCharCode(69 - i)}</span>{" "}
              <span>{min_score_value + i * interval}</span>
            </>
          ),
        }));
        return (
          <Box key={id} className="quiz-question-box">
            <Box className="quiz-question">
              <Box component="span" className="serial-no filled-question">
                {index + 1}. {title}
              </Box>
            </Box>
            <Slider
              value={answer}
              onChange={(_, newValue) => saveData(index, newValue as number)}
              min={min_score_value}
              max={max_score_value}
              step={1}
              valueLabelDisplay="auto"
              marks={marks}
              className="quiz-slider"
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default TakeQuizWeb;
// Customizable Area End
