import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { PriceData, TrophyData, ImageDataType,CertifcateData } from "./InventoryTypes";
import StorageProvider from "../../../framework/src/StorageProvider";
import i18n from "../../../web/src/utilities/i18n";
import React from "react";
import { toast } from "react-toastify";
import CloseIcon from '@material-ui/icons/Close';
import { warningIcon } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  seletedItem: string;
  stepNumber: number;
  backBtn: () => void;
  nextBtn: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  description: string;
  aging: string;
  volume: string | unknown;
  alcohol: string;
  production: string;
  agingProcess: string;
  sku: string;
  gtinNumber: string;
  ispriceModal: boolean;
  isTrophyModal: boolean;
  isCertification: boolean;
  priceData: PriceData[];
  trophyData: TrophyData[];
  ContainerVolumeData: {
    id: number,
    name: string,
  }[];
  ProductionProcessData: {
    id: number,
    name: string,
  }[];
  otherProComment: string;
  error: {
    alcholPercent: string;
  }
  srm: string;
  OGfield: string;
  fGfield: string;
  eBCfield: string;
  ibu: string;
  srmError: string;
  OGError: string;
  fGError: string;
  eBCError: string;
  ibuError: string;
  agingPotentialError: string;
  gtinError: string;
  editPriceData?: PriceData;
  editTrophyData?: TrophyData;
  certificates: CertifcateData[];
  oldCertificates: CertifcateData[];
  deletedPrice:number[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End
export default class ItemDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getContianerVolumeApiCallId: string = "";
  getProductionProApiCallId: string = "";
  userSessionData: any;
  userToken: { meta: { token: string } };
  numberValidation: RegExp;
  percentValidation: RegExp;
  agingValidation: RegExp;
  alcoholValidation: RegExp;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      // Customizable Area Start
      description: '',
      aging: '',
      volume: '',
      alcohol: '',
      production: '',
      agingProcess: '',
      sku: '',
      gtinNumber: '',
      ispriceModal: false,
      isTrophyModal: false,
      isCertification: false,
      priceData: [],
      trophyData: [],
      ContainerVolumeData: [],
      ProductionProcessData: [],
      otherProComment: '',
      error: {
        alcholPercent: ""
      },
      srm: '',
      OGfield: '',
      fGfield: '',
      eBCfield: '',
      ibu: '',
      srmError: '',
      OGError: '',
      fGError: '',
      eBCError: '',
      ibuError: '',
      agingPotentialError: '',
      gtinError: '',
      certificates: [],
      deletedPrice:[],
      oldCertificates:[]
      // Customizable Area End
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    this.numberValidation = new RegExp(/^(\d+)(\d+\.\d{1,2})*$/);
    this.percentValidation = new RegExp(/^\d{0,2}(\.\d{0,1})?$/);
    this.agingValidation = new RegExp(/(?:^\d{1,4}$)/);
    this.alcoholValidation = new RegExp(/^\d{1,2}(\.\d?)?$/);
    //  /^\d{0,2}$/  
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getContianerVolumeApiCallId) {
        this.setState({ ContainerVolumeData: responseJson.options })
      }

      if (apiRequestCallId === this.getProductionProApiCallId) {
        this.setState({ ProductionProcessData: responseJson.options })
      }
    }
    // Customizable Area End
  }
  handleDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ description: event.target.value })
  }
  handleAging = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let agingVal = event.target.value;
    if (this.agingValidation.test(agingVal) || agingVal == '') {
      this.setState({
        aging: agingVal,
        agingPotentialError: '',
      })
    }
  }
  handlesku = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ sku: event.target.value })
  }
  handlegtinNum = (event: React.ChangeEvent<HTMLInputElement>) => {
    const gtinReg = new RegExp(/^\d{0,13}$/)
    const gtinNumber = event.target.value;
    if (gtinReg.test(gtinNumber) || gtinNumber == '') {
      this.setState({ gtinNumber: event.target.value, gtinError: '' })
    }
  }
  handleVolume = (event: React.ChangeEvent<{ value: unknown; }>) => {
    this.setState({ volume: event.target.value })
  }
  handleAlcohol = (event: React.ChangeEvent<HTMLInputElement>) => {
    let alcoholVal = event.target.value
    if (this.alcoholValidation.test(alcoholVal) || alcoholVal == '') {
      this.setState({
        alcohol: alcoholVal,
      })
    }

  }
  handleProduction = (event: React.ChangeEvent<{ value: unknown; }>) => {
    this.setState({ production: String(event.target.value) })
  }
  handleagingProcess = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ agingProcess: event.target.value })
  }
  handleOtherProdComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ otherProComment: event.target.value })
  }
  handleProductionDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ production: event.target.value })
  }
  handleOG = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.numberValidation.test(event.target.value) || event.target.value == '') {
      this.setState({
        OGfield: event.target.value,
        OGError: '',
      })
    } else {
      this.setState({ OGError: configJSON.numberValidationError })
    }
  }
  handleSRM = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.numberValidation.test(event.target.value) || event.target.value == '') {
      this.setState({
        srm: event.target.value,
        srmError: '',
      })
    } else {
      this.setState({ srmError: configJSON.numberValidationError })
    }
  }
  handleEBC = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.numberValidation.test(event.target.value) || event.target.value == '') {
      this.setState({
        eBCfield: event.target.value,
        eBCError: '',
      })
    } else {
      this.setState({ eBCError: configJSON.numberValidationError })
    }
  }
  handleFGField = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.numberValidation.test(event.target.value) || event.target.value == '') {
      this.setState({
        fGfield: event.target.value,
        fGError: '',
      })
    } else {
      this.setState({ fGError: configJSON.numberValidationError })
    }
  }
  handleIBU = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.numberValidation.test(event.target.value) || event.target.value == '') {
      this.setState({
        ibu: event.target.value,
        ibuError: '',
      })
    } else {
      this.setState({ ibuError: configJSON.numberValidationError })
    }
  }

  handlePriceModal = () => {
    this.setState({ ispriceModal: true })
  }
  closePriceModal = () => {
    this.setState({ ispriceModal: false, editPriceData: undefined })
  }

  handleTrophyModal = () => {
    this.setState({ isTrophyModal: true })
  }
  closeTrophyModal = () => {
    this.setState({ isTrophyModal: false, editTrophyData: undefined })
  }
  openCertification = () => {
    this.setState({ isCertification: true })
  }
  closeCertification = (certificates: CertifcateData[]) => {
    this.setState({ isCertification: false, certificates: certificates })
  }

  createdItemPrice = (label: any, item_price: number, is_taxExcluded: boolean) => {
    let priceData = this.state.priceData;
    let curPriceData = priceData.find(x => x.labelId == label.id)
    if (curPriceData) {
      const objIndex = priceData.findIndex(x => x.labelId == curPriceData?.labelId)
      curPriceData = {
        ...curPriceData,
        priceLabel: label.title,
        labelId: label.id,
        itemPrice: item_price,
        isTaxIncluded: !is_taxExcluded,
      }
      priceData[objIndex] = curPriceData;
      this.setState({
        priceData: priceData
      })
    }
    else {
      let obj = {
        priceLabel: label.title,
        labelId: label.id,
        itemPrice: item_price,
        isTaxIncluded: !is_taxExcluded,
      };
      this.setState({ priceData: [...this.state.priceData, obj] })
    }

    this.closePriceModal()
  }

  deleteItemPrice = (labelId: number) => {
    let deletedPrice = this.state.priceData.find((x:PriceData)=>x.labelId==labelId)
    if(deletedPrice?.id){
      this.setState({deletedPrice:[...this.state.deletedPrice,deletedPrice.id]})
    }
    let newPriceData: PriceData[] = this.state.priceData.filter((item) => { return item.labelId !== labelId });
    this.setState({ priceData: newPriceData });
  }

  editItemPrice = (priceData: PriceData) => {
    this.setState({ editPriceData: priceData }, this.handlePriceModal)
  }

  trophyDetails = (name: string, year: number, result: string, comment: string, trophyImage?: ImageDataType[], id?: number) => {
    const trophyData = this.state.trophyData;
    if (id) {
      let curTrophy = trophyData.find(x => x.id == id)
      if (curTrophy) {
        const objIndex = trophyData.findIndex(x => x.id == curTrophy?.id)
        curTrophy = {
          ...curTrophy,
          id: id,
          trophyName: name,
          trophyYear: year,
          trophyResult: result,
          trophyComment: comment,
          // trophyImages:trophyImage
        }
        trophyData[objIndex] = curTrophy;
        this.setState({
          trophyData: trophyData
        })
      }
    }
    else {
      let maxId = trophyData.length > 0 ? Math.max(...trophyData.map(obj => obj.id)) : 0;
      let obj = {
        id: maxId + 1,
        trophyName: name,
        trophyYear: year,
        trophyResult: result,
        trophyComment: comment,
        trophyImages: trophyImage
      };
      this.setState({ trophyData: [...trophyData, obj] })
    }

    this.closeTrophyModal()
  }

  deleteTrophy = (id: number) => {
    let newTrophyData: TrophyData[] = this.state.trophyData.filter((item) => { return item.id !== id });
    this.setState({ trophyData: newTrophyData });
  }

  editTrophy = (trophyData: TrophyData) => {
    this.setState({ editTrophyData: trophyData }, this.handleTrophyModal)
  }

  handleItemdetails = () => {
    let error = false
    if (this.state.aging.length > 0 && this.state.aging.length < 4) {
      this.setState({
        agingPotentialError: configJSON.agingError
      })
      error = true;
    }
    if (this.state.gtinNumber && this.state.gtinNumber.length > 0 && this.state.gtinNumber.length < 13) {
      this.setState({
        gtinError: configJSON.gtinError
      })
      error = true;
    }

    
    if (error) {
      window.scroll(0, 0);
      return
    }
    this.setDataToStorage()
    this.props.nextBtn()

  }

  goBackToLocationPage = () => {
    this.setDataToStorage()
    this.props.backBtn()
  }

  setDataToStorage = () => {
    const removedCertificates = this.state.oldCertificates.filter((x)=>this.state.certificates.find((y)=>y.id==x.id)==undefined).map(x=>x.certification_assignment_id);
    const detailsData = {
      "description": this.state.description,
      "aging": this.state.aging,
      "volume": this.state.volume,
      "alcohol": this.state.alcohol,
      "production": this.state.production,
      "agingProcess": this.state.agingProcess,
      "sku": this.state.sku,
      "gtinNumber": this.state.gtinNumber,
      "trophyData": this.state.trophyData,
      "priceData": this.state.priceData,
      "otherProComment": this.state.otherProComment,
      "srm": this.state.srm,
      "og": this.state.OGfield,
      "fg": this.state.fGfield,
      "ebc": this.state.eBCfield,
      "ibu": this.state.ibu,
      "certificates": this.state.certificates,
      "removedCertificates":removedCertificates,
      "deletedPrices":this.state.deletedPrice
    };
    StorageProvider.set("itemDetailsdata", JSON.stringify(detailsData));
  }

  getCertificateNames = () => {
    let names = "";
    let image = "";
    this.state.certificates.forEach((certificate:any)=>{
      if(names != ""){
        names += ", "
      }
      names += certificate.name;
      if(image == "" && certificate.image != ""){
        image = certificate.image;
      }
    })
    return {
      name: names,
      image: image
    };
  }
  getContianerVolumeData = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getContianerVolumeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsDropdownAPIEndPoint + "container with volume"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProductionProcessData = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductionProApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsDropdownAPIEndPoint + "production process"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount(): Promise<void> {
    const itemDetailsdata = await StorageProvider.get("itemDetailsdata");
    if (itemDetailsdata) {
      const itemDetailsJSON = await JSON.parse(itemDetailsdata)
      this.setState({
        description: itemDetailsJSON.description,
        aging: itemDetailsJSON.aging,
        volume: itemDetailsJSON.volume,
        alcohol: itemDetailsJSON.alcohol,
        production: itemDetailsJSON.production,
        agingProcess: itemDetailsJSON.agingProcess,
        sku: itemDetailsJSON.sku,
        gtinNumber: itemDetailsJSON.gtinNumber,
        otherProComment: itemDetailsJSON.otherProComment,
        trophyData: itemDetailsJSON.trophyData ?? [],
        priceData: itemDetailsJSON.priceData ?? [],
        srm: itemDetailsJSON.srm,
        OGfield: itemDetailsJSON.og,
        fGfield: itemDetailsJSON.fg,
        eBCfield: itemDetailsJSON.ebc,
        ibu: itemDetailsJSON.ibu,
        deletedPrice:itemDetailsJSON.deletedPrices ?? [],
        certificates:itemDetailsJSON.certificates ?? [],
        oldCertificates:itemDetailsJSON.oldCertificates ?? [],        
      })
    }
    this.getContianerVolumeData()
    this.getProductionProcessData();
    const lang = localStorage.getItem("lang") || "en";
    await (i18n as any).changeLanguage(lang);
  }
  t(key: any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation", ...variables })
  }


  createToastNotificationError = (toastMesssage: string) => {
    toast.error(
      <div className="toast-notification">
      <div className="notification-txt">{toastMesssage}</div>
      <div className="toast-link" >
       <CloseIcon />
      </div>
    </div>, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 4000,
      icon: () => <img src={warningIcon} alt="emptydata" />,
    });
  };

  // Customizable Area End
}
