import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./Onboardingguide.web";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isDowloadModal: boolean;
  slideNum: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OnboardingguideController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      isDowloadModal: false,
      slideNum: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizabele Area Start

  handleModal =()=>{
    this.setState({isDowloadModal: !this.state.isDowloadModal})
  }

  handleSlider =(sliderValue:number)=>{
    this.setState({slideNum: sliderValue})
  }

  nextSlide =()=>{
    if(this.state.slideNum <=2){
      this.setState((prevState)=>({slideNum: prevState.slideNum + 1}));
    }
  }

  prevSlide =()=>{
    this.setState((prevState)=>({slideNum: prevState.slideNum - 1}));
  }
  // Customizable Area End
}

// Customizable Area End
