export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgCreateTaskFilter = require("../assets/createTask_filter.png");
export const download = require("../assets/file-download.png");
export const plusCircle = require("../assets/plus-circle.png");
export const editCircle = require("../assets/edit-circle.png");
export const checkCircle = require("../assets/check-circle.png");
export const arrowULeft = require("../assets/arrow-u-left.png");
export const checkBoxEmpty = require("../assets/check-empty.png");
export const checkBoxFilled = require("../assets/check-filled.png");
