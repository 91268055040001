import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getStorageData, removeStorageData, setStorageData } from "../../../../../framework/src/Utilities";

export type InitialStateType = {
    auth: {
        token: string | null,
        role: 'manager' | 'owner' | 'employee' | null
    },
    user: UserDataType,
    isOnAccountCreation: boolean,
    isSignedIn: boolean;
}

export type UserDataType = {
    attributes: {
        company_name: string,
        created_at: string,
        email: string,
        first_name: string,
        hourly_pay: string | number,
        job_role: string,
        job_type: string,
        last_name: string,
        updated_at: string,
        users: number | null
    },
    id: number,
    type: string
  }

const initialState:InitialStateType = {
    auth: {
        token: null,
        role: null
    },
    // @ts-ignore
    user: {},
    isOnAccountCreation: true,
    isSignedIn: false
}

export const removeUser = createAsyncThunk('auth/removeUser', async() => {
    try
    {
        // Remove everything from local storage
        await removeStorageData('token')
        return {
            auth: {
                token: null,
                role: null
            }
        }
    }
    catch(err)
    {
        console.log("Some error occured removing the user.")
        console.log("Error:", err)
    }
})

export const setUser = createAsyncThunk('auth/setUser', async({userData}:any) => {
    try
    {
        let token = await getStorageData('token')
        let role = await getStorageData('role')
        return {
            token: token,
            role: role,
            user: userData ?? {},
        }
    }
    catch (err)
    {
        console.log("error setting user data to global state.")
        return
    }
})

export const getIsOnAccountCreation = createAsyncThunk('auth/getIsOnAccountCreation', async() => {
    try
    {
        let isOnAccountCreationScreen = await getStorageData('isOnAccountCreationScreen')
        return { isOnAccountCreation: isOnAccountCreationScreen === null ? false : true }
    }
    catch (err)
    {
        //? default is just send the user to Dashboard
        return { isOnAccountCreation: false }
    }
})

export const setIsOnAccountCreation = createAsyncThunk('auth/setIsOnAccountCreation', async(set: boolean): Promise<{isOnAccountCreation: boolean}> => {
    //? Please only send 'set' as true or false, other things except empty entities will always qualify as true
    try
    {
        if (set)
            await setStorageData('isOnAccountCreationScreen', JSON.stringify('true'))
        else
            await removeStorageData('isOnAccountCreationScreen')
        return { isOnAccountCreation: set }
    }
    catch (err)
    {
        console.log("Error setting IsOnAccountCreation:", err)
        return { isOnAccountCreation: set }
    }
})

export const removeLoginDataFromStorage = createAsyncThunk('auth/removeLoginDataFromStorage', async():Promise<boolean> => {
    console.log("signing out..")
    try
    {
        // remove login information from local storage
        await removeStorageData('token')
        await removeStorageData('role')
        await removeStorageData('isOnAccountCreationScreen')
        return true
    }
    catch (err)
    {
        console.log("Error removing login information from local storage:", err)
        return false
    }
})

export const setAuthData = createAsyncThunk('auth/setAuthData', async({ token, role }:InitialStateType['auth']) => {
    try
    {
        
    }
    catch (err) {
        
    }
})

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        changeSignedInStatus: (state, action) => {
            state.isSignedIn = action.payload
        },
        updateAuthStatus: (state, action) => {
            //? action.payload should be an object with following: { token: TOKEN, role: ROLE }, otherwise expect the unexpected :)
            state.auth = action.payload
        },
        updateIsOnAccountCreation: (state, action) => {
            state.isOnAccountCreation = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(removeUser.fulfilled, (state, action) => {
                console.log("remove user fulfilled.")
            })
            .addCase(removeUser.pending, (state, action) => {
                console.log("removing user...")
            })
            .addCase(removeUser.rejected, (state, action) => {
                console.log("Unable to remove user. Aborting.")
            })
            .addCase(getIsOnAccountCreation.fulfilled, (state, action) => {
                state.isOnAccountCreation = action.payload.isOnAccountCreation
                console.log("action object for isON is:", action.payload)
            })
            .addCase(setIsOnAccountCreation.fulfilled, (state, action) => {
                state.isOnAccountCreation = action.payload.isOnAccountCreation
            })
            .addCase(removeLoginDataFromStorage.fulfilled, (state, action) => {
                if (action.payload)
                {
                    state.auth.token = null
                    state.auth.role = null
                    state.isOnAccountCreation = false
                    state.isSignedIn = false
                    //@ts-ignore
                    state.user = {}
                }
            })
            .addCase(removeLoginDataFromStorage.rejected, (state, action) => {
                console.log("removeLoginDataFromStorage Rejected.")
            })
            .addCase(setUser.fulfilled, (state, action) => {
                console.log("setting user with payload: ", action.payload)
                state.auth.token = action.payload?.token
                state.auth.role = action.payload?.role
                state.user = action.payload?.user
            })
    },
})

// Export normal reducers here. Async reducers are already exported.
export const { changeSignedInStatus, updateAuthStatus, updateIsOnAccountCreation } = authSlice.actions

export default authSlice.reducer
