// Customizable Area Start
import React from "react";
import { Slider, Box } from "@material-ui/core";

interface QuizListProps {
  quizData: any;
  disable: boolean;
  saveSimulationData?: (id:number, newValue:number) => void;
}

const QuizList = (props: QuizListProps) => {
  const { quizData, saveSimulationData } = props;
 

  return (
    <Box className="quiz-question-wrapper">
      {quizData.map((question: any, index: number) => {
        const {
          id,
          title,
          min_score_value,
          max_score_value,
        } = question.question;
        if (question.submission === null) {
          return;
        }
        const { answer } = question.submission;

        const interval = (max_score_value - min_score_value) / 4;
        const marks = Array.from({ length: 5 }, (_, i) => ({
          value: min_score_value + i * interval,
          label: (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // marginLeft: "10px"
              }}
            >
              <span>{String.fromCharCode(69 - i)}</span>{" "}
              <span>{min_score_value + i * interval}</span>
            </Box>
          ),
        }));
        return (
          <Box key={id} className="quiz-question-box-v2">
            <Box className="txt-equiz-question">
              <Box component="span" className="serial-no">
                {index + 1}. {title}
              </Box>
            </Box>
            <Slider
              value={answer}
              onChange={(_, newValue) => saveSimulationData  && saveSimulationData(index, newValue as number)}
              min={min_score_value}
              max={max_score_value}
              step={1}
              valueLabelDisplay="on"
              marks={marks}
              className="quiz-slider-full-details"
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default QuizList;
// Customizable Area End
