// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./OnboardingWelcome.web";
import i18n from "../../../web/src/utilities/i18n";

const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
    navigation?: any;
    id?: string;
    classes: any;
}

interface S {

}
interface SS {
    id: any;
}

export default class OnboardingWelcomeController extends BlockComponent<
    Props,
    S,
    SS
> {
    welcomeTitle: string;
    welcomeHyperTitle: string;
    welcomeButtonText: string;

    constructor(props: Props) {
        super(props);

        this.welcomeTitle = configJSON.welcomeTitle;
        this.welcomeHyperTitle = configJSON.welcomeHyperTitle;
        this.welcomeButtonText = configJSON.welcomeButtonText;
    }
    returnTeam = () => {
        this.props.navigation.navigate("AccountCreation");
    };
    async componentDidMount() {        
      const langT = localStorage.getItem("lang") ?? "en"; 
      await (i18n as any).changeLanguage(langT);   
    }
    
    t(key:any, variable?: Record<string, any>) {
        return (i18n as any).t(key, { ns: "translation" , ...variable } )
    }
}
// Customizable Area End