// Customizable Area Start
import React, { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import { Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const CustomContactTableItems = (props: any) => {
  const {
    memId,
    contactName,
    email,
    phone,
    group,
    company_name,
    business_category,
    isItemSelected,
    labelId,
    deleteAction,
    editAction,
    addMemberAction,
    hideActionColumn,
    showDeleteInMembers,
    showEditOption,
    userRole,
  } = props;

  let teamTitle: string[] = [];
  if (Array.isArray(group)){
  teamTitle = group.map((item: any) => {
    return item.title;
  });}
  const userSessionData: any = sessionStorage.getItem("userData");
  const userDetails = JSON.parse(userSessionData);
  const showMeLabel =
    userDetails.data.id === memId ? (
      <Box component="span" className="show-me-link">
        {" "}
        Me
      </Box>
    ) : (
      ""
    );

  const [expanded, setExpanded] = useState(false);

  const showMoreTeamsHandler = () => {
    setExpanded(!expanded);
  };

  const renderCheckboxCell = (
    isItemSelected: boolean,
    labelId: string,
    hideActionColumn: any
  ) => {
    if (!hideActionColumn) {
      return (
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
            data-testid="select-checkbox"
          />
        </TableCell>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {renderCheckboxCell(isItemSelected, labelId, hideActionColumn)}
      <TableCell
        className="name-cell"
        component="th"
        id={labelId}
        scope="row"
        padding="none"
      >
        <Box className="name-col">
          <Box className="profile-name">
            {contactName.split(' ').map((namePart:string) => namePart.charAt(0)).join('')}
          </Box>
          {contactName} {showMeLabel}
        </Box>
      </TableCell>
      <TableCell className="email-cell">
        <Typography className="table-link">
          {email}
        </Typography>
      </TableCell>
      <TableCell className="phone-cell">
        <Typography className="table-link">
          {phone}
        </Typography>
      </TableCell>
      <TableCell className="group-cell">
        {teamTitle.length === 0 && "Unassigned Members"}
        {teamTitle.length <= 2 ? (
          teamTitle.join(", ")
        ) : (
          <>
            {teamTitle.slice(0, 2).join(", ")}
            {!expanded && (
              <Box
                component="span"
                onClick={showMoreTeamsHandler}
                className="member-team-link"
              >
                {"+" + (teamTitle.length - 2) + " more"}
              </Box>
            )}
          </>
        )}
        {expanded &&
          ", " + teamTitle.slice(2, teamTitle.length).join(", ")}
      </TableCell>
      <TableCell className="company-cell">
        <Typography  className="table-link">
          {company_name}
        </Typography>
      </TableCell>
      <TableCell className="business-cell">
        <Typography className="table-link">
          {business_category}
        </Typography>
      </TableCell>
      {!hideActionColumn && (
        <TableCell className="action-link">
          {showDeleteInMembers ? (
              <Link
                to="#"
                onClick={() => deleteAction(memId)}
                title="delete"
                className="table-link"
                data-testid="deleteMemberLink"
              >
                Delete
              </Link>
          ) : (
            <>
              {userRole === "owner" && (
                <>
                  {showEditOption && (
                    <Link
                      to="#"
                      onClick={() =>
                        editAction(
                          memId,
                          contactName,
                          email,
                          phone,
                          teamTitle,
                          company_name,
                          business_category,
                        )
                      }
                      title="Edit"
                      className="table-link"
                      data-testid="editMembers-34"
                    >
                      Edit
                    </Link>
                  )}
                  <Link
                    to="#"
                    onClick={() => addMemberAction(memId)}
                    title="Add to Team"
                    className="table-link"
                    data-testid="addtoTeam-link-34"
                  >
                    Add to Team
                  </Link>
                </>
              )}
            </>
          )}
        </TableCell>
      )}
    </>
  );
};

export default CustomContactTableItems;
// Customizable Area End