// Customizable Area Start
import React from "react";
import {
  Modal,
  Box
} from "@material-ui/core";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import CloseIcon from "@material-ui/icons/Close";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { activeDash, inActiveDash } from "../../blocks/ordermanagement/src/assets";

interface CustomInputProps {
    isOpen: boolean;
    onClose: () => void;
    viewDetails: any;
    classes: Record<string, string>;
    currentIndex:number;
    currentId:number;
    handleAfterChange: (currentSlide: number) => void;
}

const ImageModal = (props: CustomInputProps) => {
  const {
    isOpen,
    onClose,
    classes,
    viewDetails,
    currentIndex,
    currentId,
    handleAfterChange    
  } = props;
  
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentIndex,
    adaptiveHeight: true,
    autoplay: false,
    autoplaySpeed: 10000,
    customPaging: (index: number) => {
      return (
        <img
          src={index === currentIndex ? activeDash : inActiveDash}
          alt="dot"
        />
      );
    },
    afterChange: handleAfterChange,
  };
    return (
    <>
 <Modal
        className={classes.modalImageDialog}
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="image-container">
          <Box
            className="close-icon"
            onClick={onClose}
            data-test-id="handleFiltersClose"
          >
            <CloseIcon className="close-icon" />
          </Box>
          <Box className={`image-wrapper-2 ${classes.carouselWrapper}`}>
            {viewDetails?.attributes?.thumbnails?.length > 0 && (
              <Slider key={currentId} {...settings} className="carousel-slider">
                {viewDetails.attributes?.thumbnails?.map((item: any) => (
                  <Box className="slider-item" key={item.id}>
                    <TransformWrapper>
                      <TransformComponent>
                        <div className="slider-img">
                          <img src={item.url} alt="sliderImg1" />
                        </div>
                      </TransformComponent>
                    </TransformWrapper>
                  </Box>
                ))}
              </Slider>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ImageModal;
// Customizable Area End
