import React, { useState, useCallback, useRef, useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import usePlacesAutocomplete, { Suggestion } from "use-places-autocomplete";
import CustomInput from "./CustomInput.web";

interface SearchProps {
  panPlaceId: (place_id:string,description:string) => void;
  placeName?:string;
}
interface MapProps {
  respData?: (url:string,latLng:any,placeName:string) => void;
  isView?:boolean;
  latlng?:any;
  mapPlace?:string;
}

interface WindowWithGoogle extends Window {
  google: typeof google;
}

declare const window: WindowWithGoogle;

const Search: React.FC<SearchProps> = ({panPlaceId,placeName, }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSelect = (description: string,place_id:string) => () => {
    setValue(description, false);
    clearSuggestions();
    panPlaceId(place_id,description);
  };

  const renderSuggestions = () =>
    data.map((suggestion: Suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion.description,place_id)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });
    useEffect(()=>{
    if(placeName){
      setValue(placeName,false)
    }
    },[placeName])


  return (
    <div className="map-search">
      <CustomInput
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="Google Maps URL"
      />
      {status === "OK" && <div className="searchDiv"> <ul className="searcListUl">{renderSuggestions()}</ul></div>}
    </div>
  );
};

const GoogleMapComponent: React.FC<MapProps> = ({respData,isView=false,latlng=null,mapPlace=""}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBguvCcyKxVDHW2Wialc9jJEqOc2M8e7vs",
    libraries: ["places"]
  });
  const mapRef = useRef<google.maps.Map<Element>>();

  const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number }|null>(latlng ?? null);
  const [placeName,setPlaceName] = useState(mapPlace)
  const onMapLoad = useCallback((map: google.maps.Map<Element>) => {
    mapRef.current = map;
    if(selectedLocation!=null){
      if(mapPlace==""){
       getPlaceName(selectedLocation, 1);
      }
      mapRef.current?.setZoom(15)
    }
  }, []);
  
  
  const panPlaceId = (placeId: string,description: string) => {
    const mapInstance = mapRef.current;
  
    if (mapInstance) {
      setPlaceName("");
      const service = new window.google.maps.places.PlacesService(mapInstance);
      service.getDetails(
        { placeId },
        (result, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && result.geometry) {
            const lat = result.geometry.location.lat();
            const lng = result.geometry.location.lng();
            mapInstance.panTo({ lat, lng });
            mapInstance.setZoom(12);
            setSelectedLocation({ lat, lng });
          if(respData){
            respData(`https://www.google.com/maps?q=${lat},${lng}`,JSON.stringify({ lat, lng }),description)
          }
          }
        }
      );
    }
  };
  

  const getPlaceById = (placeId:string,location: google.maps.LatLngLiteral) => {
    const service = new window.google.maps.places.PlacesService(mapRef.current!);
    service.getDetails(
      { placeId },
      (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && result.name) {
          setPlaceName(result.name)
        }
        else{
          getPlaceName(location, 1);
        }
      }
    )
  }
  const getPlaceName = (location: google.maps.LatLngLiteral, radius: number, attempt = 1) => {
    const request = {
      location: location,
      radius: radius
    };
   
    const service = new window.google.maps.places.PlacesService(mapRef.current!);
    service.nearbySearch(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results.length > 0) {
        const placeId = results[results.length - 1].place_id;

        if(placeId){
        const detailsRequest:google.maps.places.PlaceDetailsRequest = { placeId };

        service.getDetails(detailsRequest, (place, detailsStatus) => {
          if (detailsStatus === google.maps.places.PlacesServiceStatus.OK ) {
            let placeName = place.name;
            if(place.address_components){
              place.address_components.forEach((x)=>{                
                if(!placeName.toLowerCase().split(",").map(x=>x.trim()).includes(x.short_name.toLowerCase().replace(",","").trim())){
                  placeName += ", " + x.short_name
                }
              })

            }
            setPlaceName(placeName);
          } else {
            getPlaceName(location, radius * 2, attempt + 1);
          }
        });
        }
      } else  {
        getPlaceName(location, radius * 2, attempt + 1);
      }
    });
    
  };

  const mapClick = (e:any) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setSelectedLocation({ lat: lat, lng: lng });
    if(e.placeId){
      getPlaceById(e.placeId,e.latLng.toJSON())
    }
    else{
      getPlaceName(e.latLng.toJSON(), 1);
    }    
  }

  const center = {
    lat: 43.6532,
    lng: -79.3832
  };
  
  useEffect(()=>{
    if(placeName != ""){
      const lat = selectedLocation?.lat;
      const lng = selectedLocation?.lng;
      if(respData){
        respData(`https://www.google.com/maps?q=${lat},${lng}`,JSON.stringify({ lat, lng }),placeName)
      }
    }
  },[placeName])

  return (
    <div>
      {isLoaded ? (
        <>
          {isView ? 
          <h4 className="place-name">{placeName}</h4>
          : <Search panPlaceId={panPlaceId} placeName={placeName}/>}
          <GoogleMap
            id="map"
            mapContainerStyle={{
              height: "300px",
              width: "100%",
              border: "1px solid #e8ecf2",
              borderRadius: "6px",
              marginBottom: "20px",
            }}
            zoom={isView?25:8}
            center={selectedLocation ?? center}
            options={{
              disableDefaultUI: false,
              zoomControl: true
            }}
            onLoad={onMapLoad}
            onClick={isView?undefined:mapClick}
          >
            {selectedLocation && (
              <Marker
                position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }}
                draggable={!isView}
                onDragEnd={mapClick}
              />
            )}
          </GoogleMap>
        </>
      ) : (
        "Loading"
      )}
    </div>
  );
};

export default GoogleMapComponent;
