import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import i18n from "../../../web/src/utilities/i18n";
import { CertifcateData } from "./InventoryTypes"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  certificates:CertifcateData[];
  openModal: boolean;
  closeModal: (certificates:any[])=>void;
  // Customizable Area End
}

interface S {
// Customizable Area Start
iscertificateSelected: boolean;
certificationData:any[]
certificationsSelected:number[]
// Customizable Area End
}
  
interface SS {
id: any;
// Customizable Area Start
// Customizable Area End
}
  
// Customizable Area Start

// Customizable Area End
export default class CertificationModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCertificationApiCallId:string = "";
  userSessionData: any;
  userToken: {meta:{token:string}};
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      // Customizable Area Start
      iscertificateSelected: false,
      certificationData: [],
      certificationsSelected:[]
      // Customizable Area End
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestCallId === this.getCertificationApiCallId){
      this.setState({certificationData: responseJson.data})
    }
    // Customizable Area End
  }
  
  handleCertification=(event: React.ChangeEvent<HTMLInputElement>,id:number)=>{
    let checked = this.state.certificationsSelected;
    if(event.target.checked){
      checked.push(id)
    }
    else{
      checked = checked.filter(x=>x!=id)
    }
    this.setState({certificationsSelected: checked})
  }

  getCertificationData = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCertificationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.certificationAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  closeCertificateModal = () => {
    let certificates = this.state.certificationData.filter(x=>this.state.certificationsSelected.includes(Number(x.id))).map((x:any)=>{
      let oldCertificate = this.props.certificates.find((y)=>y.id == Number(x.id));
      let obj:CertifcateData = {
        certification_assignment_id : oldCertificate ? oldCertificate.certification_assignment_id : undefined,
        id:Number(x.id),
        name:x.attributes.name,
        image:x.attributes.image?.image ?? ""
      }
      return obj
    })
    this.props.closeModal(certificates)
  }

  async componentDidMount(): Promise<void> {
    this.getCertificationData();
    const lang = localStorage.getItem("lang") || "en"; 
    await (i18n as any).changeLanguage(lang);
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if(prevProps.certificates != this.props.certificates){
      this.setState({certificationsSelected:this.props.certificates.map(item=>item.id)},()=>{
      })
    }
  }

  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  // Customizable Area End
}
