// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./EmailAccountConfirmation.web";
import { toast } from "react-toastify";
import React from "react";
import i18n from "../../../web/src/utilities/i18n";
export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
  classes: any;
}

interface S {
  emailConfirmationerror: string;
  emailConfirmationsuccess: string;
  emailToken: string;
}
interface SS {
  id: any;
}

export default class EmailAccountConfirmationController extends BlockComponent<
  Props,
  S,
  SS
> {
  confirmEmailAPIID: string = "";
  welcomeApiID: string = "";
  userSessionData: any;
  userToken: any;
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    
    this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      emailConfirmationerror: "",
      emailConfirmationsuccess: "",
      emailToken: "",
    };
    this.userToken = JSON.parse(this.userSessionData);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.confirmEmailAPIID) {
        if (responseJson.errors[0].message) {
          this.createToastNotification(responseJson.errors[0].message);
          this.setState({
            emailConfirmationerror: responseJson.errors[0].message,
          });
        } else if (responseJson.errors[0].token) {
          this.createToastNotification(responseJson.errors[0].token);
          this.setState({
            emailConfirmationerror: responseJson.errors[0].token,
          });
        } else {
          this.createToastNotificationSuccess(responseJson.message);
          this.setState({ emailConfirmationsuccess: responseJson.message });
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  createToastNotificationSuccess = (toastMesssage: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMesssage}</div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };
  createToastNotification = (toastMesssage: string) => {
    toast.error(toastMesssage, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  getAllUrlParams = (url: string) => {
    let queryString = url ? url.split("?")[1] : window.location.search.slice(1);

    let obj: any = {};

    if (queryString) {
      queryString = queryString.split("#")[0];

      let arr = queryString.split("&");

      arr.forEach((element) => {
        let a = element.split("=");

        let paramName = a[0];
        let paramValue = typeof a[1] === "undefined" ? true : a[1];

        paramName = paramName.toLowerCase();

        if (!obj[paramName]) {
          obj[paramName] = paramValue;
        }
      });
    }

    return obj;
  };
  async componentDidMount() {
    const lang = localStorage.getItem("lang") ?? "en"; 
    await(i18n as any).changeLanguage(lang);
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    console.log(this.getAllUrlParams(queryString).token);
    if (urlParams.has("token")) {
      this.setState(
        {
          emailToken: this.getAllUrlParams(queryString).token as string,
        },
        () => {
          this.handleEmailChange();
        }
      );
    }
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  handleEmailChange = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: this.state.emailToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.confirmEmailAPIID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailChangeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createPasswordAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}
// Customizable Area End
