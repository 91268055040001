//Customizable Area Start
import React from "react";
import {
  Box,
  Modal,
  Typography,
  CardMedia,
  Stepper,
  Step,
  StepLabel,
  Button,
  Slider,
} from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import {
  openSimulation,
  info,
  timelineScoreA,
  timelineScoreB,
  timelineScoreC,
  timelineScoreD,
  timelineScoreE,
  polygon,
  clipboardTextClock,
  zeroScore,
  star2,
} from "./assets";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CircleOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import QuizList from "./components/QuizList.web";
import WebLoader from "../../../components/src/WebLoader.web";
import SimulationModeScoreController, {
  Props,
} from "./SimulationModeScoreController.web";
import SimulateFullScreenMode from "./SimulateFullScreenMode.web";
export const configJSON = require("./config");
export class SimulationModeScore extends SimulationModeScoreController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, modalClasses, modalOpen, modalClose, t } = this.props;

    return (
      <>
      {this.state.isLoading && <WebLoader/>}
        <Modal
          open={modalOpen}
          onClose={modalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={modalClasses.modalBoxCenter}
        >
          <Box className={modalClasses.simulationQuizModal}>
            <Box className="simulationquiz-inner">
              <Box className="quiz-result-heading">
                <CardMedia
                  component="img"
                  className={classes.cardHeadingImg}
                  image={openSimulation}
                  title="Full Screen Mode"
                  onClick={this.handleSimulateFullScreen}
                  data-test-id="fullScreenMode"
                />
                <Link to="#" className="close-icon" onClick={modalClose}>
                  <CloseIcon className={classes.closeIcon} />
                </Link>
              </Box>
              <Box className="quiz-result-content">
                <Box className="left-box">
                  <Box className="heading-wrapper">
                    <Typography className="heading">
                      {t(`${configJSON.pastSimulations}`)}
                    </Typography>
                    <Typography className="sub-title">
                      {t(`${configJSON.pastSimulationDis}`)}
                    </Typography>
                  </Box>
                  <Stepper orientation="vertical">
                    {this.state.pastSimulationList.map((step) => (
                        <Step key={step.id}>
                          {step.isDate ? (
                            <StepLabel
                              StepIconComponent={CircleOutlinedIcon}
                              className="simulation-date"
                            >
                              {step.time}
                            </StepLabel>
                          ) : (
                            <StepLabel
                              StepIconComponent={CircleOutlinedIcon}
                              StepIconProps={{ style: { fontSize: "8px" } }}
                              className={classes.inactiveLabel}
                            >
                              <Box className="score-box-timeline">
                                {step.time}
                                {step.grade === "A" && (
                                  <CardMedia
                                    component="img"
                                    className={classes.simulationScoreImg}
                                    image={timelineScoreA}
                                    title=""
                                  />
                                )}
                                {step.grade === "B" && (
                                  <CardMedia
                                    component="img"
                                    className={classes.simulationScoreImg}
                                    image={timelineScoreB}
                                    title=""
                                  />
                                )}
                                {step.grade === "C" && (
                                  <CardMedia
                                    component="img"
                                    className={classes.simulationScoreImg}
                                    image={timelineScoreC}
                                    title=""
                                  />
                                )}
                                {step.grade === "D" && (
                                  <CardMedia
                                    component="img"
                                    className={classes.simulationScoreImg}
                                    image={timelineScoreD}
                                    title=""
                                  />
                                )}
                                {step.grade === "E" && (
                                  <CardMedia
                                    component="img"
                                    className={classes.simulationScoreImg}
                                    image={timelineScoreE}
                                    title=""
                                  />
                                )}
                              </Box>
                            </StepLabel>
                          )}
                        </Step>
                    ))}
                  </Stepper>
                </Box>
                <Box className="center-box">
                  <Box className="center-box-header">
                    <Box className="desktop-header">
                      <Typography className="header-title">
                      {t(`${configJSON.txtCompanyValue}`)}
                      </Typography>
                      <Typography className="sub-title">
                      {t(`${configJSON.desSimulationQuiz}`)}
                      </Typography>
                    </Box>
                    <Box className="mb-header">
                      <Box className="navigation">
                        <Link to="#">
                          <ArrowBack />
                        </Link>
                        <Box className="mb-header-right">
                          <Button>
                            <CardMedia
                              component="img"
                              className="img-icon"
                              image={info}
                            />
                          </Button>
                          <Button>
                            <CardMedia
                              component="img"
                              className="img-icon"
                              image={clipboardTextClock}
                            />
                          </Button>
                        </Box>
                      </Box>
                      <Box className="simu-score-result">
                        <Box className="box-text">
                          <Typography className="txt-title">
                            {t("Official E-Score")}
                          </Typography>
                          <Typography className="txt-sub-title">
                            This is your last E-Quiz result.
                          </Typography>
                        </Box>
                        <Box className="right-box">
                          <CardMedia
                            component="img"
                            className="img-simu-score"
                            image={this.state.gradeObj.simulationImg}
                          />
                          <Typography className="score-value">{this.state.currentGrade}</Typography>
                        </Box>
                      </Box>
                      <Box className="offical-simu-score-result">
                        <Box className="offical-box-text">
                          <Typography className="txt-title">
                            {t("Simulation E-Score")}
                          </Typography>
                          <Typography className="txt-sub-title">
                            {t("Here is the result of your adjustments.")}
                          </Typography>
                        </Box>
                        <Box className="offical-right-box">
                          <CardMedia
                            component="img"
                            className="img-score-outline"
                            image={star2}
                          />
                          <Typography className="score">{this.state.lastEscore}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <QuizList
                      disable={false}
                      quizData={this.state.submittedQuizData}
                      saveSimulationData={this.saveSlideAnswer}
                      data-testId={"quizListComponent"}
                    />
                  </Box>
                  <Box className="button-wrapper">
                    <Button
                      className="btn-secondary"
                      data-testId="btn-closeModal"
                      onClick={modalClose}
                    >
                      {t(`${configJSON.cancelBtn}`)}
                    </Button>
                    <Button
                      className={`modal-btn ${modalClasses.primaryButton} primaryButton`}
                      data-testId="btn-save-simulation"
                      onClick={this.updateSimulationQuiz}
                    >
                      {t(`${configJSON.saveSimulationBtn}`)}
                    </Button>
                  </Box>
                </Box>
                <Box className="right-box">
                  <Box className="right-header-box">
                    <Box>
                      <Typography className="heading">
                       {t(`${configJSON.officialEScore}`)}
                      </Typography>
                      <Typography className="sub-title">
                        {t(`${configJSON.lastEsocreResultTxt}`)}
                      </Typography>
                    </Box>
                    <Box className="group">
                      <Box className="overlap-group">
                        <Typography className="group-name">
                          {this.state.lastEscore}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="right-box-content">
                    <Box className="content-top-header">
                      <Box className="content-box">
                        <Typography className="simulation-score">
                          {t(`${configJSON.simulationTxt}`)}
                        </Typography>
                        <Typography className="sub-simulation-score">
                          {t(`${configJSON.simulationSubTxt}`)} 
                        </Typography>
                      </Box>
                      <Box className="info-wrapper">
                        <Link to="#" onClick={this.showInfoContent}>
                          <CardMedia
                            component="img"
                            className="info-img"
                            image={info}
                            title=""
                          />
                        </Link>
                      </Box>
                    </Box>
                    <Box className="display-score">
                      <CardMedia
                        component="img"
                        className="disScoreImg"
                        image={this.state.gradeObj.simulationImg}
                        title=""
                      />

                      <Typography className="score">
                        {this.state.currentGrade}
                      </Typography>
                      <Box className="comment-box">
                        <Typography className="comment">
                          {this.state.currentGrade === "E" ? (
                            <>{t(`${configJSON.escoreTxtE}`)}</>
                          ) : (
                            <>{t(`${configJSON.escoreTxt}`)} </>
                          )}

                          <span className={this.state.gradeObj.gradeScoreClass}>
                            {this.state.gradeObj.gradeScoreText}
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="imporove-score-wrap">
                      <Button
                        className="btn-improve-score"
                        //onMouseOver={this.handleOverEffect}
                        data-testId={"improve-score-btn"}
                      >
                       {t(`${configJSON.scoreImporveBtn}`)}
                      </Button>
                      {/* {this.state.showTooltip === true ? ( */}
                      <Box className="wrap-black-box">
                        <CardMedia
                          component="img"
                          className={classes.polygonImg}
                          image={polygon}
                          title=""
                        />
                        <Box className="black-box">
                          <Typography className="comment-out">
                           {t(`${configJSON.scoreImproveDes}`)}
                            <span className="highlight">
                            <a className="highlight-link" href={configJSON.recommendationLink} target="_blank">{t(`${configJSON.simulationDes}`)}</a>
                            </span>{" "}
                            {t(`${configJSON.scoreImproveDes2}`)}{" "}
                            <span className="highlight">
                            <a className="highlight-link" href={configJSON.recommendationLink} target="_blank">{t(`${configJSON.chatTxt}`)}</a>
                            </span>
                            .
                          </Typography>
                        </Box>
                      </Box>
                      {/* ) : null} */}
                    </Box>
                  </Box>
                </Box>

                {this.state.isActiveInfoContent && (
                  <Box className="info-content-box">
                    <Box className="info-heading">
                      <Box className="heading">Simulation E-Score</Box>
                      <Link
                        to="#"
                        className="close-icon"
                        onClick={this.hideInfoContent}
                      >
                        <CloseIcon />
                      </Link>
                    </Box>
                    <Box className="para">
                      <Box className="sub-txt">
                        {t("Use this mode to focus your improvements and see how changes to e-questions affect your overall e-score.")}
                      </Box>
                    </Box>
                    <Box className="para">
                      <Box className="sub-txt">
                        {t("Your last official e-score is at the top and the slider inputs are in grey.")}
                      </Box>
                      <Box className="slider-progress">
                        <Box className="progress-wrap">
                          <Box
                            className="progress-inner"
                            style={{ width: "46.44%" }}
                          ></Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="para">
                      <Box className="sub-txt">
                        {t("Adjust the sliders in this simulation to see what your new E-Score would be!")}
                      </Box>
                      <Box className="slider-progress slider-wrap">
                        <Slider
                           min={60}
                           max={180}
                           valueLabelDisplay="on"
                           value={80}
                           className="quiz-slider-full-details"
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Modal>
        {this.state.simulateFullScreenMode && (
            <SimulateFullScreenMode
              modalClasses={modalClasses}
              classes={classes}
              modalOpen={this.state.simulateFullScreenMode}
              modalClose={this.handleSimulateFullScreenClose}
              simulationList={this.state.pastSimulationList}
              quizData={this.state.submittedQuizData}
              saveSimulationData={this.saveSlideAnswer}
              updateSimulationQuiz={this.updateSimulationQuiz}
              lastEscore={this.state.lastEscore}
              showInfoContent={this.showInfoContent}
              currentGrade={this.state.currentGrade}
              gradeObj={this.state.gradeObj}
              isActiveInfoContent={this.state.isActiveInfoContent}
              hideInfoHandler={this.hideInfoContent}
              t={t}
            />
        )}
      </>
    );
  }
}

export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    cardHeadingImg: {
      width: "16px",
      height: "16px",
      objectFit: "contain",
      marginRight: "16px",
    },
    scoreImg: {
      width: "50px",
      height: "50px",
      objectFit: "contain",
      marginTop: "16px",
      marginBottom: "16px",
    },
    officalScoreImg: {
      width: "47px",
      height: "47px",
      objectFit: "contain",
    },
    closeIcon: {
      width: "24px",
      height: "24px",
      color: "#94A3B8",
    },
    simulationScoreImg: {
      width: "24px",
      height: "24px",
      objectFit: "contain",
    },
    polygonImg: {
      width: "15px",
      height: "15px",
      objectFit: "contain",
      position: "absolute",
      bottom: "-11px",
      transform: "rotate(180deg)",
    },
  });

export default withStyles(customStyles, { withTheme: true })(
  SimulationModeScore
);
//Customizable Area End
