Object.defineProperty(exports, "__esModule", {
    value: true
});
  
// Customizable Area Start

exports.baseURL = require("../../../framework/src/config");
exports.methodGET = "GET";
exports.methodPOST = "POST";
exports.methodPATCH = "PATCH";
exports.methodDELETE = "DELETE";
exports.methodPUT = "PUT";

// Customizable Area End
  