// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Switch,
  Popover,
  Modal
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import FilterDropDown from "../../blocks/ContactsList/src/components/FilterDropDown.web";
import CustomInputWeb from "./CustomInput.web";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { configJSON } from "../../blocks/ContactsList/src/CreateContactController.web";

interface ContactDetails {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  created_at: string;
  business_info: {
      data: {
          id: string;
          type: string;
          attributes: {
              id: string;
              company_name: string;
              VAT_number: string;
              business_category_name: string | null;
          };
      };
  };
  billing_address: {
      data: {
          id: string;
          type: string;
          attributes: {
              id: string;
              country: string;
              city: string;
              pin_code: string;
              address1: string;
              address2: string;
          };
      };
  };
  shipping_address: {
      data: {
          id: string;
          type: string;
          attributes: {
              id: string;
              country: string;
              city: string;
              pin_code: string;
              address1: string;
              address2: string;
          };
      };
  };
  note: {
      data: {
          id: string;
          type: string;
          attributes: {
              id: string;
              content: string;
          };
      };
  };
  contact_groups: Array<{
      id: string;
      name: string;
      created_at: string;
      updated_at: string;
      account_id: string | null;
      deleted_at: string | null;
  }>;
  contact_products: any[]; 
}
interface MergeContactsProps {
  classes: any;
  anchorEl: any;
  anchorElMore: any;
  handleShowContactDetails: (contactId: string) => void;

  contacts: any;
  closeCreateContact: any;
  handleOpenMenuMore: any;
  handleCloseMenuMore: any;
  searchValue: string;
  handleSearch: (e: any) => void;
  filterGroups: any;
  filterCompany: any;
  filterBusinessCategories: any;
  filterShippingCountry: any;
  onChangeSort:(e: any)=>void;
  sortValue:any;
  hasAnOpenDeal:boolean;
  hasInteractions:boolean;
  hasFavoriteProducts:boolean;
  hasNote:boolean;
  handleChecked:(e:any)=>void;
  handleInputChange:(e:any)=>void;
  groupsData:any;
  businessCategoryList:any;
  isMergeContact:boolean;
  handleMergeContactClose:()=>void;
  handleMergeInfoSelectionOpen:(id:any)=>void;
  contactDefaultDetails:any;
  searchedContacts:any,
  isSearching:boolean;
  sortedAndFilteredData:any;
  isSortAndFiltering:boolean;
  countryDataList:any;
  t:any;
}

const MergeContacts = (props: MergeContactsProps) => {
  const {
    classes,
    anchorElMore,
    handleShowContactDetails,
    handleOpenMenuMore,
    handleCloseMenuMore,
    searchValue,
    handleSearch,
    contacts,
    filterGroups,
    filterCompany,
    filterBusinessCategories,
    filterShippingCountry,
    onChangeSort,
    hasAnOpenDeal,
    hasInteractions,
    hasFavoriteProducts,
    hasNote,
    handleChecked,
    handleInputChange,
    groupsData,
    businessCategoryList,
    isMergeContact,
    handleMergeContactClose,
    handleMergeInfoSelectionOpen,
    contactDefaultDetails,
    isSearching,
    searchedContacts,
    sortedAndFilteredData,
    isSortAndFiltering,
    countryDataList,
    t
  } = props;

  const [filterdData, setFilterdeData] = useState([]);
  const [defaultContactData, setDefaultContactData] = useState<ContactDetails[]>([]);

  const [contactData,setContactData] = useState(filterdData)
  useEffect(() => {     
    if (isSortAndFiltering) {
      setContactData(sortedAndFilteredData);
      } else {
      setContactData(filterdData);
    }
  }, [isSortAndFiltering,sortedAndFilteredData,filterdData]);
  
  useEffect(() => {     
    if (isSearching) {
      setContactData(searchedContacts);
      } else {
      setContactData(filterdData);
    }
  }, [isSearching,searchedContacts,filterdData]);
  
   useEffect(() => {
    const storedContactDetailsString = localStorage.getItem("contactDefaultDetails");
    if (storedContactDetailsString) {
        const storedContactDetails = JSON.parse(storedContactDetailsString);
        setDefaultContactData([storedContactDetails])
      } 
    }, []);
    const firstName = defaultContactData[0]?.first_name;
    const lastName = defaultContactData[0]?.last_name;

    const capitalizedFirstName = firstName ? firstName.charAt(0).toUpperCase() + firstName.slice(1) : '';
    const capitalizedLastName = lastName ? lastName.charAt(0).toUpperCase() + lastName.slice(1) : '';
    useEffect(() => {
      const id = localStorage.getItem("isMergeContcatId");

      const filteredArray = contacts.filter((item:any) => item.id !== id?.toString());
      setFilterdeData(filteredArray);

    }, [contacts]);
    const transformedGroupData = groupsData.map((item:any) => ({
      id: item.attributes.id,
      name: item.attributes.name,
    }));
    const transformedBusinessCategoryData =  businessCategoryList.map((item:any) => ({
      id: item.id,
      name: item.name,
    }));
    const countryList = countryDataList.map((item:any) => ({
      id: item.id,
      name: item.country_name,
    }));
    const alphabet = ['#','A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const uniqueCompanyNames = new Set();
    const uniqueContacts = contacts
    .filter((contact:any) => {
      const companyName = contact?.attributes?.business_info?.data?.attributes?.company_name?.trim();
      if (companyName && !uniqueCompanyNames.has(companyName)) {
        uniqueCompanyNames.add(companyName);
        return true;
      }
      return false;
    });
    const companyNames = Array.from(
      new Set(
        uniqueContacts
          .map((contact: any, index: any) => ({
            id: index,
            name: contact.attributes.business_info.data.attributes.company_name.trim(),
          }))
          .filter((company:any) => company.name !== "")
      )
    );
 
    return (
    <Modal
    open={isMergeContact}
    onClose={handleMergeContactClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className={classes.modalMergeBoxCenter}
  >
    <Box className={classes.MergeContactsWrapper}>
           <Box className="modal-heading">
             <Box className="heading-content" onClick={handleMergeContactClose}>
                <ArrowBackIcon style={{color:"#2B2B2B",cursor:"pointer"}}/>
                <Typography className="back-txt">{t(`${configJSON.back}`)}</Typography>             
              </Box>
            </Box>  
            <Box className="heading-left">
                  <Typography className="modal-title" component="h2">
                   {t(`${configJSON.chooseContact}`)}
                  </Typography>
                  <Box component="p" className="sub-txt">
                  {t(`${configJSON.selectedMerge}`)} {capitalizedFirstName} {capitalizedLastName}
                  </Box>
          </Box>
      <Box className="filter-box">
        <Box className="left-box">
          <CustomInputWeb
            type="search"
            value={searchValue}
            label=""
            placeholder={t(`${configJSON.searchTxtType}`)}
            onChange={(event: any) => handleSearch(event.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon style={{color:"rgb(144 149 157)"}}/>
              </InputAdornment>
            }
            data-test-id="searchfield"
          />
        </Box>
        <Box className="right-box">
          <FilterDropDown
            classes={classes}
            title={t(`${configJSON.group}`)}
            options={transformedGroupData}
            value={filterGroups}
            name="filterGroups"
            onSelectValue={handleInputChange}
            data-test-id="filterDropdown1"
          />
          <FilterDropDown
            classes={classes}
            title={t(`${configJSON.company}`)}
            options={companyNames}
            value={filterCompany}
            name="filterCompany"
            onSelectValue={handleInputChange}
            data-test-id="filterDropdown2"
          />
          <FilterDropDown
            classes={classes}
            title={t(`${configJSON.businessCategorytxt}`)}
            options={transformedBusinessCategoryData}
            value={filterBusinessCategories}
            name="filterBusinessCategories"
            onSelectValue={handleInputChange}
            data-test-id="filterDropdown3"
          />
          <FilterDropDown
            classes={classes}
            title={t(`${configJSON.shippingCountry}`)}
            options={countryList}
            value={filterShippingCountry}
            name="filterShippingCountry"
            onSelectValue={handleInputChange}
            data-test-id="filterDropdown4"
          />
          <Box className="filter-more">
            <Box className="menu-dropdown more" onClick={handleOpenMenuMore}>
              <Typography className="more-txt">{t(`${configJSON.more}`)}</Typography>
              <ArrowDropDownIcon style={{color:"#737070",marginTop:"-3px"}}/>
            </Box>
            <Popover
              open={Boolean(anchorElMore)}
              anchorEl={anchorElMore}
              onClose={handleCloseMenuMore}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
             classes={{ paper: `${classes.popoverStyle}` }}
            >
              <Box className="view-more-dropdown">
                <Box className="dropdown-menu-item">
                  <Typography className="dropdown-menu-item-text">
                    {t(`${configJSON.openDeal}`)}
                  </Typography>
                  <Switch
                    className="dropdown-menu-item-switch"
                    checked={hasAnOpenDeal}
                    onChange={handleChecked} 
                    name="hasAnOpenDeal"               
                   />
                </Box>
                <Box className="dropdown-menu-item">
                  <Typography className="dropdown-menu-item-text">
                    {t(`${configJSON.openInteraction}`)}
                  </Typography>
                  <Switch
                    className="dropdown-menu-item-switch"
                    checked={hasInteractions}
                    onChange={handleChecked} 
                    name="hasInteractions"
                  />
                </Box>
                <Box className="dropdown-menu-item">
                  <Typography className="dropdown-menu-item-text">
                    {t(`${configJSON.openFavProduct}`)}
                  </Typography>
                  <Switch
                    className="dropdown-menu-item-switch"
                    checked={hasFavoriteProducts}
                    onChange={handleChecked} 
                    name="hasFavoritesProduct"
                  />
                </Box>
                <Box className="dropdown-menu-item">
                  <Typography className="dropdown-menu-item-text">
                    {t(`${configJSON.hasNote}`)}
                  </Typography>
                  <Switch
                    className="dropdown-menu-item-switch"
                    checked={hasNote}
                    onChange={handleChecked} 
                    name="hasNotes"
                  />
                </Box>
              </Box>
            </Popover>
          </Box>
        </Box>
        <Box className="sort-box">
        <FormControl className="select-control sortby-control">
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                classes: { paper: classes.dropdownStyle },
              }}
              onChange={(e:any) => onChangeSort(e.target.value)}
              displayEmpty
              defaultValue="Sort by"
              renderValue={(value: any) => {
                return <Box>{value}</Box>;
              }}
              IconComponent={() => (
               <ArrowDropDownIcon style={{color:"rgb(120 118 118)"}}/>
              )}
              data-test-id="sbmt-quiz-dropdown2"
            >
              <MenuItem value="A-Z">A-Z</MenuItem>
              <MenuItem value="Z-A">Z-A</MenuItem>
            </Select>
        </FormControl>
        </Box>
      </Box>

      <Box className="pagination-txt">
      <Typography className="total-contact">{contactData.length} Contacts</Typography>
        <Box className="groups-paging">
              <Typography className="pg-txt pg-index-txt">18</Typography>
              <Typography className=" pg-txt pg-total-txt">of 200</Typography>
              <ChevronLeft style={{color:"#8b8787", marginRight: "20px"}} />
              <ChevronRight />
        </Box>
      </Box>
      <Box className="content-wrapper">
        <Box className="table-header">
          <Box className="hd-column hd-column-1">
            <Typography className="tb-header-title">{t(`${configJSON.name}`)}</Typography>
          </Box>
          <Box className="hd-column hd-column-2">
            <Typography className="tb-header-title">{t(`${configJSON.email}`)}</Typography>
          </Box>
          <Box className="hd-column hd-column-3">
            <Typography className="tb-header-title">{t(`${configJSON.group}`)}</Typography>
          </Box>
          <Box className="hd-column hd-column-3">
            <Typography className="tb-header-title">{t(`${configJSON.company}`)}</Typography>
          </Box>
          <Box className="hd-column hd-column-3">
            <Typography className="tb-header-title">{t(`${configJSON.businessCategorytxt}`)}</Typography>
          </Box>
          <Box className="hd-column hd-column-4">
            <Typography className="tb-header-title">
            {t(`${configJSON.shippingCountry}`)}
            </Typography>
          </Box>
          <Box className="hd-column hd-column-6">
            <Typography className="tb-header-title">{t(`${configJSON.action}`)}</Typography>            
          </Box>
          <Box>
          </Box>
        </Box>

        <Box className="table-content">
         <Typography className="tb-sort-character">A</Typography>
           {contactData.length > 0?
                contactData.map((contact: any) => {
                  const groups: any = contact.attributes.contact_groups;
                  const groupId = groups.map((group: any) => group?.id);
                  const name = `${contact.attributes.first_name}` + " " +`${contact.attributes.last_name}`;
                  const displayedGroups = groups.slice(0, 4);
                  const filteredGroups = displayedGroups.filter((group:any) => group !== null); 
                  const groupNames = filteredGroups.map((group: any) => group?.name).join(', ');
                  const ellipsis = groups.length > 3 ? '...' : '';
        
                  return (
                    <Box key={groupId}>                  
                    <Box className="tb-row">                   
                      <Box className="tb-cell tb-cell-1">
                        <Box className="avatar-wrapper">                       
                          <Typography className="text-eh">
                          {contact.attributes.first_name[0].toUpperCase()}{contact.attributes.last_name[0].toUpperCase()}
                          </Typography>
                        </Box>
                        <Typography className="tb-cell-content">
                          {name}
                        </Typography>
                      </Box>
                      <Box className="tb-cell tb-cell-2">
                        <Typography className="tb-cell-content">
                          {contact.attributes.email}
                        </Typography>
                      </Box>
                      <Box className="tb-cell tb-cell-3">
                        <Typography className="tb-cell-content">
                       {groupNames}{ellipsis}
                        </Typography>
                      </Box>
                      <Box className="tb-cell tb-cell-4">
                        <Typography className="tb-cell-content">{contact?.attributes?.business_info?.data?.attributes?.company_name}</Typography>
                      </Box>
                      <Box className="tb-cell tb-cell-5">
                        <Typography className="tb-cell-content">{contact?.attributes?.business_info?.data?.attributes?.business_category_name}</Typography>
                      </Box> 
                      <Box className="tb-cell tb-cell-6">
                        <Typography className="tb-cell-content">{contact?.attributes?.shipping_address?.data?.attributes?.country}</Typography>
                      </Box>
                      <Box className="tb-cell tb-cell-7">
                        <Button
                          className="btn-view-action"
                          data-test-id="btn-view-action"
                          onClick={() => handleMergeInfoSelectionOpen(contact?.id)}
                        >                          
                          {t(`${configJSON.continue}`)}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  );
                }):(
                  <Box className="error-message">
                    <Typography>                     
                     {t(`${configJSON.notFoundTxt}`)}
                    </Typography>
                  </Box>
              )
              }

          <Box className="alphabets-content">
          <ul className="alphabets">
          {alphabet.map(letter => (
            <li key={letter}>{letter}</li>
            ))}
          </ul>
          </Box>
        </Box>

      </Box>
    </Box>
    </Modal>
  );
};

export default MergeContacts;

// Customizable Area End
