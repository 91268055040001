export const logo = require("../assets/app_logo.svg");
export const EnglishLangIcon = require("../assets/english_lan.svg");
export const FrenchLangIcon = require("../assets/french_lang.svg");
export const GoogleIcon = require("../assets/google_icon.svg");
export const imgGoogle = require("../assets/google.png");
export const imgIphone = require("../assets/image-iphone.png");
export const imgLogo = require("../assets/logo.png");

export const imgAppIcon1 = require("../assets/app-1.png");
export const imgAppIcon2 = require("../assets/app-2.png");
export const imgTranslate = require("../assets/translate.png");
export const imgPasswordVisible = require("../assets/passInvisible.png");
export const imgPasswordInVisible = require("../assets/passInvisible.png");
export const imgMandetaryIcon = require("../assets/mandetaryIcon.png");
export const imgPasswordVisible1 = require("../assets/eyeIcon.png");
export const imgPasswordInVisible1 = require("../assets/eyeIcon.png");
export const connectAccountRadioCheck = require("../../cfgoogleapi/assets/connectAccountRadioCheck.png");
export const connectAccountRadioCheckEmpty = require("../../cfgoogleapi/assets/connectAccountRadioCheckEmpty.png");
export const homeImg = require("../../bulkuploading/assets/homeImg.png");
export const inviteMemSuccess = require("../assets/inviteMemSuccess.png");
export const webemptyImage = require("../assets/empty-data.png");
export const menuIcon = require("../assets/menuIcon.png");
export const memberSuccess = require("../assets/member-success.png");
export const memberRevoked = require("../assets/member-revoked.png");
export const memberReActivated = require("../assets/member-reactivated.png");
export const memberMoved = require("../assets/member-moved.png");
export const checkedIcon = require("../assets/checked-icon.png");
export const amico = require("../assets/amico.jpeg");
export const teamDeleted = require("../assets/member-deleted.png");
export const memberEdited = require("../assets/member-edited.png");
export const teamRename = require("../assets/team-renamed.png");
export const sortIcon = require("../assets/sort-icon.png");
export const filterIcon = require("../assets/filter-icon.png");
export const deleteOutline = require("../assets/delete-outline.svg");
export const teamLists = require("../assets/team-lists.png");
export const checkBlack = require("../assets/check-black.png");
export const checkOutline = require("../assets/checked-outline.png");
export const removeMemberOutline = require("../assets/remove-member.png");
export const addToTeamOutline = require("../assets/add-to-team.png");
export const moveMemberOutline = require("../assets/move-member-team.png");
export const borderCircle = require("../assets/border-circle.svg");