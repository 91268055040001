// Customizable Area Start
import React from "react";
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
export const customStyles = (theme: AugmentedTheme) =>
createStyles({
  primaryButton: {
    background: "#4ba3b7",
    border: "1px solid #4ba3b7",
    borderRadius: "6px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#f2f8fc",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    maxWidth: "100%",
    minWidth: "170px",
    padding: "12px 10px",
    minHeight: "48px",
    marginBottom: "0",
    transition: "all 0.5s ease-in-out",
    textTransform: "capitalize",
    "&:hover": {
      background: "#4ba3b7",
      color: "#ffffff",
    },
  },
  secondaryButton: {
    background: "transparent",
    border: "1px solid #e8ecf2",
    borderRadius: "7px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#2b2b2b",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    textTransform: "unset",
    maxWidth: "100%",
    minWidth: "198px",
    padding: "12px 10px",
    minHeight: "48px",
    marginBottom: "0",
    transition: "all 0.5s ease-in-out",
    textDecoration: "none",
    "&:hover": {
      background: "transparent",
      color: "#2b2b2b",
    },
  },
  headerSection:{
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    padding:'28px 20px',
    [theme.breakpoints.down("xs")]: {
      padding: "24px",
    },
    "&.fixed-header": {
      position: "fixed",
      top: "0",
      left: "0",
      right: "0",
      backgroundColor: "#FFF",
    },
    "& .backStyle":{
      display:'flex',
      alignItems:'center',
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "Expletus Sans",
      cursor:'pointer',
      letterSpacing: "0.15px",
      color: "rgba(43, 43, 43, 0.87)",
    },
    "& .backIcon":{
      marginRight:'14px'
    },
  },
  selectFormControl : {
    marginRight: "0",
    "&:last-child": {
      marginRight: "0",
    },
    "& .MuiSelect-select": {
      letterSpacing: "0.4px",
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.38)",
      fontFamily: "Roboto !important",
      fontWeight: "400",
      paddingTop: "0",
      paddingBottom: "0",
      paddingRight: "16px",
      "&:focus": {
        backgroundColor: "transparent"
      },        
    },
    "& .MuiSelect-icon": {
      color: "rgba(0, 0, 0, 0.38)",
      fontSize: "16px",
      top: "1px",
    }
  },
  sendContactOuter: {
    maxWidth: "1078px",
    width: "100%",
    margin: "0 auto",
    padding: "50px 15px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "1188px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      padding: "50px 60px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 24px 24px",
    },
    "& .main-heading": {
      marginBottom: "48px",
      flex: "0 0 49%",
      maxWidth: "49%",
      [theme.breakpoints.down("md")]: {
        flex: "0 0 48.5%",
        maxWidth: "48.5%",
      },
      [theme.breakpoints.down("sm")]: {
        flex: "0 0 100%",
        maxWidth: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "16px",
      },
      "& .header-section": {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        "& .heading-left": {
          [theme.breakpoints.down("xs")]: {
            width: "100%",
          },           
        },
        "& .heading-box": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "6px",
          },  
          "& .heading": {
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "32px",
            color: "rgba(43, 43, 43, 0.87)",
            fontFamily: "Expletus Sans",
            letterSpacing: "0",        
          },                      
        },
        "& .inventory-buttons": {
          display: "flex",
          gap: "16px",
          marginLeft: "10px",
          [theme.breakpoints.down("xs")]: {
            display: "none"
          }
        },
        "& .inventory-subtitle-txt": {
          fontWeight: 400,
          fontSize: "14px",
          color: "#505050",
          fontFamily: "Roboto",
          lineHeight: "24px",
          letterSpacing: "0.25px",
          [theme.breakpoints.down("xs")]: {
            color: "#94A3B8",
            letterSpacing: "0.105px",
            display: "-webkit-box",
            WebkitLineClamp: 1, 
            WebkitBoxOrient: 'vertical',
            overflow: "hidden",
          }
        },  
      },               
      "& .export-btn": {
        color: "#2b2b2b",
        minWidth: "131px",
        border: "1px solid #ECF1F4",
        padding: "12px 8px",
        fontSize: "14px",
        width: "100%",
        background: "transparent",
        minHeight: "48px",
        textAlign: "center",
        transition: "all 0.5s ease-in-out",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        lineHeight: "18px",
        borderRadius: "6px",
        letterSpacing: "0",
        textTransform: "unset",
        textDecoration: "none",
        [theme.breakpoints.down("sm")]: {
          minWidth: "139px",
        }
      },                   
    },
    "& .MuiCheckbox-root": {
      color: "#94A3B8",
      "&:hover": {
        backgroundColor: "rgba(54, 145, 166, 0.04)",
      },
      "&.Mui-checked": {
        color: "#4ba3b7",
      },
      "& .MuiSvgIcon-root": {
        width: "24px",
        height: "24px",
      },
    },
    "& .MuiRadio-root": {
      color: "#94A3B8",
      padding: "0",
      "&:hover": {
        backgroundColor: "rgba(54, 145, 166, 0.04)",
      },
      "&.Mui-checked": {
        color: "#4ba3b7",
      },
      "& .MuiSvgIcon-root": {
        width: "24px",
        height: "24px",
      },
    },
    "& .select-control": {
      "&.multi-select": {
        "&.outline-control": {
          width: "100%",
          marginTop: "0",
          "& .multiselect-label": {
            display: "block",
            position: "static",
            transform: "none",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "19px",
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            marginBottom: "16px",
            letterSpacing: "0",
          },
          "& .MuiSelect-select": {
            fontFamily: "Roboto",
            fontWeight: 400,
            color: "#2B2B2B",
            padding: "18px 40px 18px 24px",
            lineHeight: "20px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#e8ecf2",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            },
          },
          "& .multiple-clear": {
            position: "absolute",
            fontSize: "15px",
            zIndex: 1,
            color: "#94A3B8",
            top: "calc(50% + 17px)",
            right: "27px",
            transform: "translateY(-50%)",
          },
        },
        "& .multiselect-label": {
          display: "none",
        },
        "& .MuiInput-formControl": {
          marginTop: "0",
        },
        "& .MuiSelect-select": {
          paddingRight: "40px",
          display: "flex",
          alignItems: "center",
          "& .select-icon": {
            marginRight: "6px",
          },
        },
        "& .multiple-clear": {
          position: "absolute",
          fontSize: "15px",
          right: "18px",
          top: "3px",
          zIndex: 1,
          color: "#94A3B8",
        },
      },
      "&.status-control": {
        borderRadius: "12px",
        background: "rgba(18, 174, 241, 0.07)",
        padding: "6px 12px",
        "& .down-arrow": {
          width: "16px",
          height: "16px",
        },
        "& .MuiSelect-select": {
          color: "#12AEF1",
          fontSize: "12px",
          fontFamily: "Roboto",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.09px",
          opacity: "1",
          paddingRight: "5px",
        },
      },
      "& .select-icon": {
        position: "relative",
        marginRight: "6px",
      },
      "& .MuiSelect-select": {
        fontSize: "14px",
        letterSpacing: "0.1px",
        color: "rgba(43, 43, 43, 0.87)",
        opacity: "0.671",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        paddingBottom: "0",
        paddingTop: "0",
        "&:focus": {
          backgroundColor: "transparent",
        },
        "& .clear-icon": {
          width: "50px",
          height: "50px",
          background: "red",
        },
      },
      "& .MuiInput-underline:before": {
        display: "none",
      },
      "& .MuiInput-underline:after": {
        display: "none",
      },
    },
    "& .send-contact-main": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      flexWrap: "wrap",
      "& .send-contact-left": {
        flex: "0 0 49%",
        maxWidth: "49%",
        [theme.breakpoints.down("md")]: {
          flex: "0 0 48.5%",
          maxWidth: "48.5%",
        },
        [theme.breakpoints.down("sm")]: {
          flex: "0 0 47.5%",
          maxWidth: "47.5%",
        },
        [theme.breakpoints.down("xs")]: {
          flex: "0 0 100%",
          maxWidth: "100%",
          paddingBottom: "32px",
        },
      },
      "& .send-contact-right": {
        flex: "0 0 51%",
        maxWidth: "51%",
        paddingLeft: "48px",
        position: "sticky",
        top: "81px",    
        [theme.breakpoints.down("md")]: {
          flex: "0 0 51.5%",
          maxWidth: "51.5%",
          paddingLeft: "32px",
        },
        [theme.breakpoints.down("sm")]: {
          flex: "0 0 52.5%",
          maxWidth: "52.5%",
          paddingLeft: "46px",
        },
        [theme.breakpoints.down("xs")]: {
          flex: "0 0 100%",
          maxWidth: "100%",
          paddingLeft: "0",
        },
        "& .send-right-inner": {
          border: "1px solid #E8ECF2",
          padding: "24px",
          borderRadius: "16px",
          [theme.breakpoints.down("xs")]: {
            border: "0",
            padding: "0",
            borderRadius: "0",
          },
        },        
        "& .heading-block": {
          display: 'flex',
          justifyContent: "space-between",
          alignItems: "center",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "8px",
          },
          "& .title": {
            fontFamily: "Expletus Sans",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.15px",
            color: "#2B2B2B",
            [theme.breakpoints.down("xs")]: {
              fontSize: "22px",
              lineHeight: "26px",
            },
          },          
        },
        "& .inventory-subtitle-txt": {
          fontWeight: 400,
          fontSize: "12px",
          color: "rgba(43, 43, 43, 0.67)",
          fontFamily: "Roboto",
          lineHeight: "17px",
          letterSpacing: "0",
          display: "none",
          [theme.breakpoints.down("xs")]: {
            display: "block",
          }
        },
      },
    },   
    "& .product-list-block": {
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "17px",
      "& .product-col": {
        flex: "0 0 100%",
        maxWidth: "100%",
      },
      "& .product-inner": {
        display: "flex",
        borderBottom: "1px solid #E8ECF2",
        padding: "24px 0 23px", 
        [theme.breakpoints.down("xs")]: {
          padding: "22px 0 25px",
        },       
        "& .product-img": {
          width: "68px",
          minHeight: "68px",
          maxWidth: "100%",
          [theme.breakpoints.down("md")]: {
            marginRight: "20px",
          },
        },
        "& .product-details": {
          flex: "1",
          display: "flex",
          justifyContent: "space-between",
          "& .details-left": {
            flex: 1,
          },
          "& .details-right": {
            marginLeft: "8px",
          },
          "& .product-name": {
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            marginBottom: "4px",
            letterSpacing: "0.15px",
          },
          "& .product-desc": {
            color: "#94A3B8",
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "17px",
            letterSpacing: "0.4px",
            marginBottom: "9px",
          },
          "& .qty-input": {
            display: "flex",
            alignItems: "center",
            "& .qty-count": {
              width: "30px",
              height: "30px",
              minWidth: "unset",
              padding: "5px",
              border: "0.83px solid #E8ECF2",
              background: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "17px",
              lineHeight: "30px",
              fontWeight: 700,
              borderRadius: "10px",
            },          
            "& .product-qty": {
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              letterSpacing: "0.1px",
              margin:" 0 8px",
            }
          },
          "& .price-btn": {
            marginBottom: "0",
          },
          "& .price-txt": {
            color: "#2B2B2B",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px", 
            letterSpacing: "0.15px",
            textAlign: "right",
          },              
        },
      },
    }, 
    "& .sub-total": {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.15px",
      textAlign: "right",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      color: 'rgba(0, 0, 0, 0.87)',
      marginBottom: "29px",
      "& .price": {
        marginLeft: "12px",
      }
    },
    "& .button-wrapper": {
      display: "flex",
      justifyContent: "space-between",
      "&.full-btn": {
        "& .MuiButton-root": {
          width: "100%",
        },
      },
      "& .MuiButton-root": {
        minHeight: "56px",
        [theme.breakpoints.down("md")]: {
          minHeight: "48px",
        },
      }
    },
    "& .form-row": {
      display: "flex",
      flexWrap: "wrap",
      margin: "0 -8px",
      "&:last-child": {
        "& .form-control": {
          marginBottom: "0",
        },
        "& .items-list-wrapper": {
          "& .items-list": {
            marginBottom: "0",
          }
        }
      },
      "& .form-col": {
        padding: "0 8px",
        flex: "0 0 100%",
        maxWidth: "100%",
        "&.col6": {
          flex: "0 0 50%",
          maxWidth: "50%",
        },
        "& .form-control": {
          marginBottom: "28px",
          [theme.breakpoints.down("xs")]: {
            marginBottom: "16px",
          },
        },
      },
    },
    "& .items-list-wrapper": {
      "& .items-list": {
        padding: "16px",
        marginBottom: "28px",
        borderRadius: "6px",
        border: "1px solid #E8ECF2",
        minHeight: "56px",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "16px",
        },
        "& .items-title": {
          display: "flex",
          justifyContent: "space-between",
        },
        "& .title-icon": {
          "& .MuiSvgIcon-root": {
            color: "#94A3B8",
            "&.close-icon": {
              fontSize: "20px",
            }
          }
        },
        "& .primary-txt": {
          color: "#94A3B8",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "20px",
          letterSpacing: "0.1px",
        },
      }
    },
    "& .chip-block-wrapper": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      border: "1px solid #E8ECF2",
      padding: "14px 24px 14px 21px",
      borderRadius: "6px",
      marginBottom: "24px",
      [theme.breakpoints.down("sm")]: {
        padding: "14px 25px 14px 16px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "16px",
      },
      "& .heading-link": {
        lineHeight: "28px",
      }
    },
    "& .chip-wrapper": {
      display: "flex",
      alignItems: "flex-start",
      marginBottom: "-8px",
      flexWrap: "wrap",
      flex: "1",
    },
    '& .heading-link': {
      fontFamily: "Expletus Sans",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0.10px",
      textDecoration: "none",
      color: "#4BA3B7",
      marginLeft: "10px",
    },
    "& .hide-sm": {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    "& .show-sm": {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    }, 
  },
  assigneeMultiSelectList: {
    padding: "0",
    "&.selected-list": {
      "& .MuiListItem-root": {
        background: "#F2F8FC",
      },
    },
    "& .search-filter": {
      display: "flex",
      alignItems: "center",
      gap: "24px",
      paddingBottom: "24px",
      borderBottom: "1px solid #ECF1F4",
      [theme.breakpoints.down("xs")]: {
        gap: "16px",
        paddingBottom: "0",
        borderBottom: "0",
      },
      "& .form-control": {
        width: "100%",
        marginBottom: "0",
        "& .MuiInputAdornment-root": {
          "& .MuiSvgIcon-root": {
            fill: "rgba(0,0,0,0.67)",
          }
        },
        "& .MuiOutlinedInput-root": {
          [theme.breakpoints.down("xs")]: {
            borderRadius: "12px",
          },
        } 
      },
      "& .filter-group": {
        width: "56px",
        height: "56px",
        minWidth: "56px",
        [theme.breakpoints.down("xs")]: {
          width: "48px",
          height: "48px",
          minWidth: "48px",
        },
        "& img": {
          width: "100%",
          height: "100%",
        }
      }
    },
    "& .MuiListSubheader-gutters": {
      position: "static",
      paddingRight: "0",
      paddingLeft: "0",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "24px",
        paddingRight: "24px",
        margin: "0 -24px",
      },
      "& .form-control": {
        marginBottom: "12px",
        "& .MuiOutlinedInput-root": {
          minHeight: "56px",
          [theme.breakpoints.down("xs")]: {
            minHeight: "48px",
          },
        },
        "& .MuiOutlinedInput-input": {
          padding: "16px",
          lineHeight: "17px",
          letterSpacing: "0",
          fontSize: "13px",
          "&.MuiOutlinedInput-inputAdornedStart": {
            paddingLeft: "0",
          },
        },
        "& .MuiInputAdornment-positionStart": {
          marginRight: "16px",
        },
        "& .MuiInputAdornment-root": {
          "& .MuiSvgIcon-root": {
            fontSize: "20px",
            fill: "#225980",
          },
        },
      },
      "&.multiselect-subheader": {
        color: "#2B2B2B",
        marginBottom: "0",
        borderBottom: "1px solid #ECF1F4",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "14px",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        lineHeight: "19px",
        display: "flex",
        padding: "19px 24px",
        [theme.breakpoints.down("xs")]: {
          margin: "0 -24px",
          width: "auto",
        },
        "& .tasklist-link": {
          textDecoration: "none",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "19px",
          whiteSpace: "nowrap",
          marginLeft: "10px",
          color: "#C7263E",
          fontFamily: "Expletus Sans",
        },
      },
      "&.multiselect-subtxt": {
        borderBottom: "1px solid #ECF1F4",
        textTransform: "uppercase",
        fontSize: "10px",
        fontWeight: 400,
        lineHeight: "normal",
        fontFamily: "Roboto",
        padding: "10px 20px",
        color: "#94A3B8",
        [theme.breakpoints.down("xs")]: {
          margin: "0 -24px",
          width: "auto",
        },
      },
    },
    "& .multiselect-membres": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
      padding: "16px 0",
      borderBottom: "1px solid #ECF1F4",
      margin: "0",
      [theme.breakpoints.down("xs")]: {
        width: "auto",
        margin: "0 -24px",
      },
      "& .total-member": {
        color: "#2B2B2B",
        fontFamily: "Expletus Sans",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px",
          lineHeight: "16px",
          color: "rgba(43, 43, 43, 0.67)",
        },
      },
      "& .select-control": {
        "&.sortby-control": {
          "& .MuiSelect-select": {
            paddingRight: "26px",
          },
          "& .MuiSelect-icon": {
            top: "calc(50% - 11px)",
            color: "#2b2b2b",
            fontSize: "20px",
          },
        },
        "& .MuiSelect-select": {
          color: "#2b2b2b",
          opacity: "1",
          fontSize: "14px",
          letterSpacing: "0",
          [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
            color: "rgba(43, 43, 43, 0.67)",
          },
        },
      },
    },
    "& .MuiListItem-root": {
      borderBottom: "1px solid #ECF1F4",
      cursor: "pointer",
      padding: "8px 0 6px",
      minHeight: "80px",
    },
    "& .assignee-detail": {
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
      display: "flex",
      "& .assignee-left": {
        display: "flex",
        flex: "1",
        alignItems: "center",
        "& .assignee-info": {
          marginLeft: "10px",
          [theme.breakpoints.down("md")]: {
            marginLeft: "13px",
          },
        },
        "& .assignee-email": {
          fontWeight: 500,
          lineHeight: "16px",
          fontFamily: "Expletus Sans",
          color: "#94A3B8",
          fontSize: "12px",
        },
        "& .assignee-name": {
          marginBottom: "2px",
          lineHeight: "19px",
          fontWeight: 500,
          color: "#2b2b2b",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
        },
        "& .assignee-profile": {
          letterSpacing: "-0.395px",
          fontWeight: 600,
          display: "flex",
          color: "rgba(43, 43, 43, 0.87)",
          justifyContent: "center",
          background: "#ECF1F4",
          alignItems: "center",
          borderRadius: "100%",
          fontFamily: "Roboto",
          height: "48px",
          fontSize: "15.485px",
          lineHeight: "21.679px",
          minWidth: "48px",
        },
        "& .chip-wrapper": {
          marginTop: "3px",
          "& .MuiChip-root": {
            height: "24px",
            marginBottom: "2px",
          },
        },
      },
      "& .assignee-right": {
        marginLeft: "10px",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        "& .hours-details": {
          color: "#000",
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "16px",
          letterSpacing: "0.09px",
          marginRight: "16px",
          textAlign: "right",
          [theme.breakpoints.down("md")]: {
            marginRight: "8px",
          },
          "& .gray-txt": {
            color: "#94A3B8",
            fontWeight: 400,
            marginTop: "4px",
          },
        },
        "& .assinee-selected": {
          width: "20px",
          height: "20px",
          "& .normal-img": {
            display: "none",
          },
          "& .selected-img": {
            display: "block",
          },
        },
        "& .assinee-unselected": {
          width: "20px",
          height: "20px",
          "& .normal-img": {
            display: "block",
          },
          "& .selected-img": {
            display: "none",
          },
        },
      },
    },
  },
  dropdownStyle: {
    marginTop: "21px",
    maxWidth: "190px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "26px"
    },
    "&.multiSelect": {
      maxWidth: "265px",
      boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
      borderRadius: "12px",
      fontFamily: "Expletus Sans",
      [theme.breakpoints.down("sm")]: {
        marginTop: "26px"
      },
      "& .MuiList-padding": {
        paddingTop: "16px",
        "& .MuiListSubheader-gutters": {
          paddingLeft: "24px",
          paddingRight: "24px",
          "& .form-control": {
            marginBottom: "0",
            "& .MuiInputAdornment-root": {
              "& .MuiSvgIcon-root": {
                fill: "#94A3B8",
                fontSize: "20px",
                opcity:0.6,
              },
            }
          }
        }
      },
      "& .MuiMenuItem-root": {
        color: "#2B2B2B",
        padding: "14px 24px",
        "& .MuiListItemText-root": {
          margin: "0"
        },
        "&.Mui-selected": {
          backgroundColor: "#F2F8FC",
          "&:hover": {
            backgroundColor: "#F2F8FC"
          },
          "& .multiselect-check": {
            display: "block"
          },
        },
        "& .multiselect-check": {
          display: "none",
          marginLeft: "5px",
          flex: "0 0 16px",
          maxWidth: "16px"
        }
      },
      "& .MuiTouchRipple-root": {
        display: "none"
      }
    },
    "& .MuiMenuItem-root": {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0",
      color: "#000000",
      padding: "14px 17px",
      fontWeight: "500",
      fontFamily: "Expletus Sans",
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      whiteSpace: "normal",
      "& .MuiTypography-root": {
        font: "inherit",
        letterSpacing: "0"
      }
    },
    "& .MuiList-padding": {
      paddingTop: "4px",
      paddingBottom: "8px"
    }
  },
  customPricePopover: {
    borderRadius: "30px",
    background: "#ffffff",
    boxShadow: "0px 8px 36px 0px rgba(0, 0, 0, 0.17)",
    margin: "5px 0",
    padding: "24px",
    maxWidth: "384px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "270px",
    },
  },
  customPriceBody:{
    "& .price-wrapper": {
      marginBottom: "36px",
      "& .form-control": {
        marginBottom: "0",
      }
    },    
    "& .button-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "24px",
      "& .MuiButton-root": {
        minWidth: "unset",
        width: "100%",
      }
    }
  },
  chipButton: {
    background: "#ECF1F4",
    borderRadius: "12px",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: "rgba(43, 43, 43, 0.87)",
    padding: "6px 8px",
    cursor: "pointer",
    marginRight: "8px",
    marginBottom: "8px",
    height: "auto",
    letterSpacing: "0.4px",  
    alignItems: "flex-start", 
    "&:hover": {
      background: "rgba(75, 163, 183, 0.3)",
    },
    "&.more-btn": {
      marginRight: "0",
    },
    "& .email": {
      fontSize: "10px",
      lineHeight: "14px",
      letterSpacing: "0.07px",
      marginTop: "2px",
    },
    "& .MuiChip-avatar": {
      width: "12px",
      height: "12px",
      marginLeft: "0",
      marginRight: "5px",
    },
    "& .MuiChip-label": {
      padding: "0",
    },
    "& .MuiChip-deleteIcon": {
      color: "#94A3B8",
      width: "16px",
      height: "16px",
      margin: "0",
    }
  },
});
// Customizable Area End
