// Customizable Area Start
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
interface CustomMenuProps {
  links: any[];
  handleOpenMenu: () => void;
  handleCloseMenu: () => void;
}
const useStyles = makeStyles((theme) => ({
  dropMenu: {},
  dropDropdown: {
    "& .MuiMenu-paper": {
      minWidth: "113px",
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.12)",
      borderRadius: "12px",
      backgroundColor: "#ffffff",
      marginTop: "5px",
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#2B2B2B",
        padding: "16px 23px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
        "& .MuiLink-root": {
          color: "inherit",
        },
      },
      "& .MuiList-padding": {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
  },
}));
const CustomMenu = (props: CustomMenuProps) => {
  const { links, handleOpenMenu, handleCloseMenu } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClose = React.useCallback(() => {
    handleCloseMenu();
    setAnchorEl(null);
  }, [handleCloseMenu]);
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (event) {
        event.stopPropagation();
      }
      handleOpenMenu();
      setAnchorEl(event.currentTarget);
    },
    [handleOpenMenu]
  );

  const handleMenuClick = (e: any, link: any) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    handleClose();
    link.event(link.value);
  };
  const menuClickHandler = (link: any) => {
    return (event: React.MouseEvent<HTMLLIElement>) => {
      handleMenuClick(event, link);
    };
  };
  return (
    <Box className={classes.dropMenu}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        data-testid="moreIcon"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.dropDropdown}
      >
        {links.map((link: any, index: number) => {
          return (
            <MenuItem
              key={`${link}-${index.toString()}`}
              onClick={menuClickHandler(link)}
              data-testid={`menuItem${link.title}`}
            >
              <Link>{link.title}</Link>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default CustomMenu;
// Customizable Area End
