import React from "react";
import { Box } from "@material-ui/core";
import { withRouter, RouteComponentProps  } from 'react-router-dom';

interface MyComponentProps extends RouteComponentProps {}


const BackArrowWeb: React.FC<MyComponentProps> = ({ history }) => {

    const handleGoBack = () => {
        history.goBack();
      }
  return (
    <Box className="auth-back" onClick={handleGoBack}>
      <i>
        <svg
          className="MuiSvgIcon-root"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
        </svg>
      </i>
    </Box>
  );
};

export default withRouter(BackArrowWeb);
