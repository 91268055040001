import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import i18n from "../../../web/src/utilities/i18n";
import { orderApiResponse } from "../../../components/src/ReusableEnums";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  orderId: string;
  // Customizable Area Start
  classes: any;
  isMobile: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isProfileDrawerOpened: boolean;
  orderHistoryData: any;
  orderStatus: number;
  dateOrdered: number;
  orderType: number;
  overallOrderSearch: string;
  isViewDetails: boolean;
  viewDetailsID: any;
  isFillDetails: boolean;
  isLoading: boolean;
  sortValue: string;
  isFilter: boolean;
  orderListData:orderApiResponse[];
  // Customizable Area End
}

interface SS {
  orderId: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionData: any;
  userToken: any;
  getAllOrderRequestId: string = "";
  getAllOrdersListRequestId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      isProfileDrawerOpened: false,
      orderHistoryData: [],
      orderStatus: 0,
      dateOrdered: 0,
      orderType: 0,
      overallOrderSearch: "",
      isViewDetails: false,
      viewDetailsID: "",
      isFillDetails: false,
      isLoading: false,
      sortValue: "",
      isFilter: false,
      orderListData:[],
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ isLoading: false });

      if (responseJson.error) {
        return;
      } 
      
      switch (apiRequestCallId) {
        case this.getAllOrderRequestId:
          if (responseJson.message) {
            this.setState({ orderHistoryData: [] });
          } else {
            this.setState({ orderHistoryData: responseJson.data });
          }
          break;
        case this.getAllOrdersListRequestId:
          if (responseJson.message) {
            this.setState({ orderListData: [] });
          } else {
            this.setState({ orderListData: responseJson.data });
          }
          break;
        default:
          this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    const lang = localStorage.getItem("lang") || "en";
    await (i18n as any).changeLanguage(lang);
    this.getAllOrdersList();
    this.getAllOrders();
    if (new URLSearchParams(window.location.search).get('openPopup') === "true" && new URLSearchParams(window.location.search).get('id')) {
      this.setState({ isFillDetails: true, viewDetailsID: new URLSearchParams(window.location.search).get('id')});
    }
  }

  t(key: any) {
    return (i18n as any).t(key, { ns: "translation" });
  }

  toggleProfileDrawer = () => {
    this.setState({
      isProfileDrawerOpened: true,
    });
  };

  closeProfileDrawer = () => {
    this.setState({
      isProfileDrawerOpened: false,
    });
  };

  handleBackBtn = () => {
    window.history.back();
  };

  goToEtohShop = () => {
    window.location.href = "/etoh-shop";
  };

  handleInputChangeEvent = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }),() => {
      this.getAllOrders();
    }
    );
  };

  handleCheckedSelection = (id: any, name: any) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        [name]: id,
      }),
      () => {
        this.getAllOrders();
      }
    );
  };

  handleOpenViewDetails = (id: string) => {
    this.setState({
      isViewDetails: true,
      viewDetailsID: id,
    });
  };

  handleCloseViewDetails = () => {
    this.setState({
      isViewDetails: false,
    });
  };
  handleOpenFillDetails = (id: string) => {
    this.setState({
      isFillDetails: true,
      viewDetailsID: id,
    });
  };

  handleCloseFillDetails = () => {
    this.setState({
      isFillDetails: false,
    });
    const url = window.location.href.split('?')[0];
    window.history.replaceState({}, document.title, url);
  };

  handleSortByChange = (event: any) => {
    this.setState({ sortValue: event.target.value, isFilter: true }, () => {
      this.getAllOrders();
    });
  };

  handleClearAll = () => {
    this.setState({ orderStatus: 0, dateOrdered: 0, orderType: 0 ,overallOrderSearch:"", sortValue:"" }, () => {
      this.getAllOrders();
      this.getAllOrdersList();
    });
  };

  getAllOrders = () => {
    const { orderStatus, sortValue, dateOrdered, orderType , overallOrderSearch } = this.state;
    let sortByValue;
    let orderStatusValue;
    let dateOrderdState;
    let orderTypeValue;

    switch (sortValue) {
      case "Recently Ordered":
        sortByValue = "recently_ordered";
        break;
      case "Recent Status Change":
        sortByValue = "recent_status_change";
        break;
      default:
        sortByValue = "";
    }

    switch (orderStatus) {
      case 1:
        orderStatusValue = "Order placed";
        break;
      case 2:
        orderStatusValue = "In progress";
        break;
      case 3:
        orderStatusValue = "Shipped";
        break;
      case 4:
        orderStatusValue = "Delivered";
        break;
      case 5:
        orderStatusValue = "On hold";
        break;
      case 6:
        orderStatusValue = "Cancelled";
        break;
      default:
        orderStatusValue = "";
    }

    switch (orderType) {
      case 1:
        orderTypeValue = "Subscription";
        break;
      case 2:
        orderTypeValue = "One Time";
        break;
      default:
        orderTypeValue = "";
    }

    switch (dateOrdered) {
      case 1:
        dateOrderdState = "Past 30 days";
        break;
      case 2:
        dateOrderdState = "Past 3 months";
        break;
      case 3:
        dateOrderdState = "Past 6 months";
        break;
      case 4:
        dateOrderdState = "Past 1 year";
        break;
      case 5:
        dateOrderdState = "Any time";
        break;
      default:
        dateOrderdState = "";
    }
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllOrderRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ordeApiPath}?sort_by=${sortByValue}&status=${orderStatusValue}&date_ordered=${dateOrderdState}&service_type=${orderTypeValue}&query=${overallOrderSearch}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    !overallOrderSearch && this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAllOrdersList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllOrdersListRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ordeApiPath}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
