// Customizable Area Start
import React from "react";
import etoh from "./JSON/EtOH-Suite logo animation.json";
import { useLottie } from "lottie-react";

interface CustomLoaderProps {}
const WebLoader = (props: CustomLoaderProps) => {
  const loaderStyle: React.CSSProperties = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background: "rgba(242, 248, 252, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  };

  const loaderImageStyle = {
    width: "100px", // Adjust the width and height as needed
    // height: "180px",
  };
  const options = {
    animationData: etoh,
    loop: true,
  };
  const { View } = useLottie(options);
  return (
    <div style={loaderStyle}>
      <div style={loaderImageStyle}>{View}</div>
    </div>
  );
};

export default WebLoader;
// Customizable Area End
