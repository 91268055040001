// Customizable Area Start
import React from "react";
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";

export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    ticketSystemRoot: {
      display: "flex",
      flexWrap: "wrap",
      minHeight: "calc(100vh - 88px)",
      flex: "1",   
      "& .header-content":{
        display:"flex",
        justifyContent:"space-between",
        width:"100%",
        padding:"0 24px",
        "& .live-chat-box":{
          display: "flex",
          alignItems: "center",
          justifyContent:" center",
          marginTop:" 24px",
          cursor:"pointer",
          "& .live-btn-txt":{
              color: 'var(--Task-Completed-Color, #2B2B2B)',
              fontFamily: 'Expletus Sans',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              marginLeft:"8px"
          },
          "& .chat-icon":{
            color:"#2B2B2B",
            width:"16px",
            heigth:"16px"
          }
        },
        "& .back-btn-content":{
          display: "flex",
          alignItems: "center",
          justifyContent:" center",
          marginTop:" 24px",
          cursor:"pointer",
          "& .back-txt":{
              color: 'var(--Task-Completed-Color, #2B2B2B)',
              fontFamily: 'Expletus Sans',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              marginLeft:"8px"
          }
        },
      },  
      "& .view-ticket-wrapper": { 
        "& .btn-view-ticket": {
          position: "absolute",
          bottom: "50",
          right: "24px",
          width:"156px",
          borderRadius: "6px",
          background: "var(--brand-color-primary-100, #4BA3B7)",
          boxShadow: "0px 0px 24px 0px rgba(5, 111, 135, 0.47)",
          padding: "14px 24px",
          color: "#ffffff",
          textTransform: "none",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          zIndex:"1",         
        },            
      },
      "& .iframe-view":{
       position:"relative"
      },
      "& .footer-view-top":{
       height:"50px",
       width:"100%",
       background:"#ffff",
      },
      "& .footer-view":{
        position:"relative",
        background:"#C7263E",
        width:"100%",
        maxHeight:"700px",
        "& .mobile-img":{
          maxHeight:"700px",
          marginLeft:"60px",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginLeft:"60px",
          },
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginLeft:"0px",
          },
        },
        "& .bitmap1-img":{
          position: "absolute",
          right: 0,
          bottom: 0,
          [theme.breakpoints.down("sm")]: {
            width: "200px",
          },
          [theme.breakpoints.down("xs")]: {
            display:"none",
          },
        },
        "& .bitmap2-img":{
          position: "absolute",
          right: 0,
          top: "-200px",
          [theme.breakpoints.down("sm")]: {
            width: "200px",
            top: "-50px",
          },
          [theme.breakpoints.down("xs")]: {
            display:"none",
          },
        },
        "& .bitmap3-img":{
          position: "absolute",
          left: 0,
          top: "-100px",
          [theme.breakpoints.down("sm")]: {
            width: "200px",
            top: "-100px",
          },
          [theme.breakpoints.down("xs")]: {
            display:"none",
          },
        },
        "& .footer":{
          maxWidth: "550px",
          display:"flex",
          alignItems:"center",
          "& .footer-content":{    
            "& .download-txt":{
              color: 'var(--White, #FFF)',
              fontFamily: 'Expletus Sans',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '30px',
              marginBottom:"24px"
            },
            "& .download-sub-txt":{
              color: 'var(--White, #FFF)',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '26px',
              letterSpacing: '0.105px',
            },
            "& .button-content":{
              display:"flex",
              marginTop:"32px",
              "& .app-store-img":{
                width:"24px",
                height:"24px",
                [theme.breakpoints.down("sm")]: {
                  width:"20px",
                  height:"20px",
                },
                [theme.breakpoints.down("xs")]: {
                  width:"16px",
                  height:"16px",
                },
              },
              "& .footer-google-btn":{
               marginLeft:"20px"
              }
            }
          }
        },
      },
     
    },
   
    viewTicketBox: {
      width: "560px",
      flexDirection: "column",
      alignItems: "center",
      flexShrink: 0,
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "#ffffff",
      padding: "40px",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      borderRadius: "12px",
      background: "var(--white, #FFF)",
      boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.22)",
      "& .primary-btn":{
        width:"100% !important",
        marginTop:"24px !important",
        marginBottom:"24px",
       },
      "& .search-wrapper":{
        position:"relative",
        "& .filter-image":{
         cursor:"pointer",
        },
      "& .search-box":{
        width:"85%",
        "& .MuiOutlinedInput-root":{
          borderRadius: "12px",
          marginLeft: "-4px",
        },
        "& .MuiOutlinedInput-input":{
          marginLeft: "6px",
        },
      },
      "& .sort-by":{
        position: 'absolute',
        right: 0,
        top: '0px',
        borderRadius: '12px',
        border: '1px solid var(--Stroke, #E8ECF2)',
        padding: '16px',
      }
      },
      "& .main-container":{
        paddingBottom: "24px",
        paddingTop: "24px",
        borderBottom: "1px solid #ECF1F4",
        "& .title":{
         color: 'rgba(0, 0, 0, 0.87)',
         fontFamily: 'Expletus Sans',
         fontSize: '20px',
         fontStyle: 'normal',
         fontWeight: 500,
         lineHeight: 'normal',
         letterSpacing: '0.25px',
       },
       "& .content":{
        color: '#797878',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0.25px',
        marginTop:"8px",
      },
      },
      "& .main-content-info":{
        display:"flex",
        justifyContent:"space-between",
        "& .content-sub-txt":{
          fontSize: "12px",
          lineHeight: "normal",
          color: "#797878",
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.4px",
          marginTop: "4px",
          marginBottom:"24px"
        },
        "& .ticket-system-status": {
          color: '#4BA3B7',
          fontFamily: 'Roboto',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
          letterSpacing: '0.4px',
          display: 'flex',
          padding: '7px 12px',
          alignItems: 'center',
          borderRadius: "6px",
          height:"30px",
          // background:"rgba(75, 163, 183, 0.08)",
          "&.Open": {
            color: "#4BA3B7",
            background: "rgba(75, 163, 183, 0.08)",
            "&:before": {
              background: "#4BA3B7",
            },
          },
          "&.progress": {
            color:"#F6A319",
            background: "#fdf6e9",
            "&:before": {
              background: "#F6A319",
            },
          },
          "&.Resolved": {
            color:"#00BA88",
            background: "#eaf9f5",
            "&:before": {
              background: "#00BA88",
            },
          },
          "&.cancelled": {
            "&:before": {
              background: "#F7E223",
            },
          },
          "&.offtrack": {
            "&:before": {
              background: "#C7263E",
            },
          },
          "&:before": {
            content: '""',
            width: "8px",
            height: "8px",
            borderRadius: "100%",
            background: "#2B2B2B",
            display: "inline-block",
            marginRight: "8px",
          },
        },
      },
      "& .user-name": { 
        display: "flex", 
        justifyContent:"space-between",     
        "& .avatar":{
          display: "flex",
          alignItems: "center",           
        },       
        "& .name":{
          color: '#797878',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
          letterSpacing: '0.25px',
          marginTop: '20px',
          marginLeft:"12px",
          textTransform: "capitalize",
        },
        "& .technical-team":{
          display: "flex",
          alignItems: "center",    
          "& .name":{
            color: '#797878',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.25px',
            marginTop: '20px',
            marginLeft:"12px"
          },
          "& .attachment-icon":{
            height:"16px"
          }
        },
        "& .short-name": {
          color: "var(--gray, rgba(43, 43, 43, 0.87))",
          fontSize: "10px",
          fontFamily: "Roboto",
          fontStyle: "normal",
          lineHeight: "21.679px",
          fontWeight: "400",
          letterSpacing: "-0.395px",
          width: "24px",
          height: "24px",
          borderRadius: "19.5px",
          backgroundColor: "var(--background-background-medium, #ECF1F4)",
          marginTop:"20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center", 
        },
      },
      "& .more-ticket-txt":{
        color: 'var(--Task-Completed-Color, #2B2B2B)',
        fontFamily: 'Expletus Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        textAlign:"center",
        marginTop:"8px",
        cursor:"pointer"
       },
       "& .frame-img":{
        textAlign: "center",
      },
      "& .ticket-empty-view ":{
        "& .ticket-headding":{
          color: '#363636',
          textAlign: 'center',
          fontFamily: 'Expletus Sans',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
          marginTop:"40px"
        },
        "& .ticket-sub-headding":{
          color: '#7A7A7A',
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '19px',
        letterSpacing: '0.42px',
        marginTop:"16px",
        },
      },
     
      "& .view-ticket-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
        marginBottom: "0",       
        "& .modal-title": {
          color: "#2B2B2B",
          fontSize: "18px",
          fontWeight: 400,
          lineHeight: "24px",
          fontFamily: "Expletus Sans",
          letterSpacing: "0",
        },
        "& .sub-txt": {
          fontSize: "12px",
          lineHeight: "16px",
          color: "#94A3B8",
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.09px",
          marginTop: "4px",
          marginBottom:"24px"
        },
        "& .heading-right": {
          display: "flex",
          marginLeft: "10px",
          "& .heading-icon": {
            height: "16px",
            width: "16px",
            marginRight: "24px",
            cursor:"pointer",
            "&:last-child": {
              marginRight: "0",
              height: "24px",
              width: "24px",
            },
          },
          "& .view-all-txt": {
            color: 'var(--brand-color-primary-100, #4BA3B7)',
            fontFamily: 'Expletus Sans',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            marginRight:"24px",
            cursor:"pointer",
            whiteSpace: "nowrap",
          },
        },
      },
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "6px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#f2f8fc",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      maxWidth: "100%",
      minWidth: "170px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    footerButton: {
      background: "#ffffff",
      border: "1px solid #ffffff",
      borderRadius: "6px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2B2B2B",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      maxWidth: "100%",
      minWidth: "170px",
      padding: "24px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "none",
      "&:hover": {
        background: "#ffffff",
        // color: "#ffffff",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "14px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "14px",
        fontSize: "12px",
      },
    },
    modalDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      backgroundColor: "#ffffff",
      padding: "0",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },

      "&.view-modal-dialog": {
        "& .header-content":{
          display:"flex",
          justifyContent:"space-between",
          width:"100%",
          padding:"0 24px",
          "& .live-chat-box":{
            display: "flex",
            alignItems: "center",
            justifyContent:" center",
            marginTop:" 24px",
            cursor:"pointer",
            "& .live-btn-txt":{
                color: 'var(--Task-Completed-Color, #2B2B2B)',
                fontFamily: 'Expletus Sans',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                marginLeft:"8px"
            },
            "& .chat-icon":{
              color:"#2B2B2B",
              width:"16px",
              heigth:"16px"
            }
          },
          "& .back-btn-content":{
            display: "flex",
            alignItems: "center",
            justifyContent:" center",
            marginTop:" 24px",
            cursor:"pointer",
            "& .back-txt":{
                color: 'var(--Task-Completed-Color, #2B2B2B)',
                fontFamily: 'Expletus Sans',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                marginLeft:"8px"
            }
          },
        }, 
        "& .main-container":{
          paddingBottom: "24px",
          paddingTop: "24px",
          background: "#FFF",
          padding: "40px",
          marginTop: "24px",
          border: "1px solid #ECF1F4",
          borderRadius: "12px",
          "& .title":{
           color: 'rgba(0, 0, 0, 0.87)',
           fontFamily: 'Expletus Sans',
           fontSize: '20px',
           fontStyle: 'normal',
           fontWeight: 500,
           lineHeight: 'normal',
           letterSpacing: '0.25px',
         },
         "& .content":{
          color: '#797878',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
          letterSpacing: '0.25px',
          marginTop:"8px",
        },
        },
        "& .main-content-info":{
          display:"flex",
          justifyContent:"space-between",
          "& .content-sub-txt":{
            fontSize: "12px",
            lineHeight: "normal",
            color: "#797878",
            fontFamily: "Roboto",
            fontWeight: 400,
            letterSpacing: "0.4px",
            marginTop: "4px",
            marginBottom:"24px"
          },
          "& .ticket-system-status": {
            color: '#4BA3B7',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            letterSpacing: '0.4px',
            display: 'flex',
            padding: '7px 12px',
            alignItems: 'center',
            borderRadius: "6px",
            height:"30px",
            // background:"rgba(75, 163, 183, 0.08)",
            "&.open": {
              color: "#4BA3B7",
              background: "rgba(75, 163, 183, 0.08)",
              "&:before": {
                background: "#4BA3B7",
              },
            },
            "&.progress": {
              color:"#F6A319",
              background: "#fdf6e9",
              "&:before": {
                background: "#F6A319",
              },
            },
            "&.resolved": {
              color:"#00BA88",
              background: "#eaf9f5",
              "&:before": {
                background: "#00BA88",
              },
            },
            "&.cancelled": {
              "&:before": {
                background: "#F7E223",
              },
            },
            "&.offtrack": {
              "&:before": {
                background: "#C7263E",
              },
            },
            "&:before": {
              content: '""',
              width: "8px",
              height: "8px",
              borderRadius: "100%",
              background: "#2B2B2B",
              display: "inline-block",
              marginRight: "8px",
            },
          },
        },
        "& .user-name": { 
          display: "flex", 
          justifyContent:"space-between",     
          "& .avatar":{
            display: "flex",
            alignItems: "center",           
          },       
          "& .name":{
            color: '#797878',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.25px',
            marginTop: '20px',
            marginLeft:"12px",
            textTransform: "capitalize",
          },
          "& .technical-team":{
            display: "flex",
            alignItems: "center",    
            "& .name":{
              color: '#797878',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '0.25px',
              marginTop: '20px',
              marginLeft:"12px"
            },
            "& .attachment-icon":{
              height:"16px"
            }
          },
          "& .short-name": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontSize: "10px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            lineHeight: "21.679px",
            fontWeight: "400",
            letterSpacing: "-0.395px",
            width: "24px",
            height: "24px",
            borderRadius: "19.5px",
            backgroundColor: "var(--background-background-medium, #ECF1F4)",
            marginTop:"20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center", 
          },
        },
        "& .modal-content":{
          padding:"40px 160px 24px 160px",          
          "& .modal-headding":{
            color: 'rgba(0, 0, 0, 0.87)',
            fontFamily: 'Expletus Sans',
            fontSize: '34px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            letterSpacing: '0.25px',
            borderBottom: "1px solid #ECF1F4",
            paddingBottom:"40px"
          },
          "& .headding-right":{
            
          },
          "& .sort-by-block":{
           display:"flex",
           justifyContent:"space-between",
           alignItems:"center",
           "& .sortby-control": {
            "& .MuiSelect-select": {
              paddingRight: "0",
            },
            "& .select-icon": {
              top: "3px",
            },
          },
          },
          "& .ticket-content-box":{
             marginTop:"24px",
            "& .search-txt":{
              color: 'rgba(0, 0, 0, 0.87)',
              fontFamily: 'Expletus Sans',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
            },
            "& .search-sub-txt":{
              color: "#94A3B8",
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              letterSpacing: '0.4px',
              marginTop:"8px",

            },
            "& .search-content-textfield":{
              marginTop:"24px",              
              "& .search-select": {
                "& .MuiChip-root": {
                  backgroundColor: "#ECF1F4",
                  borderRadius: "6px",
                  padding: "6px 8px",
                  height: "auto",
                  color: "#4F4F4F",
                  fontFamily: "Roboto",
                  fontSize: "13px",
                  fontWeight: 400,
                  lineHeight: "17px",
                  margin: "0 5px 5px 0",
                  "& .MuiChip-label": {
                    paddingLeft: "0",
                  },
                  "& .MuiChip-deleteIcon": {
                    width: "17px",
                    height: "17px",
                    margin: "0",
                  },
                },
              },
            },
            "& .primary-btn-new":{
             width:"124px",
             marginTop:"24px",
            }
          },
          "& .select-control": {
            margin: "0 12px",
            "&.multi-select": {
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                fontSize: "15px",
                position: "absolute",
                top: "3px",
                right: "18px",
                zIndex: 1,
                color: "#94A3B8",
              },
            },
            "& .MuiSelect-select": {
              fontSize: "14px",
              color: "#2b2b2b",
              letterSpacing: "0.1px",
              opacity: "0.671",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              paddingTop: "0",
              paddingBottom: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                height: "50px",
                width: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
        }         
      },    
    },
    modalDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
    },
    modalViewDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      padding: "0",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: 570,
      },
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },

      "&.view-modal-dialog": {
        "& .header-content":{
          display:"flex",
          justifyContent:"space-between",
          width:"100%",
          padding:"0 24px",
          "& .live-chat-box":{
            display: "flex",
            alignItems: "center",
            justifyContent:" center",
            marginTop:" 24px",
            cursor:"pointer",
            "& .live-btn-txt":{
                color: 'var(--Task-Completed-Color, #2B2B2B)',
                fontFamily: 'Expletus Sans',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                marginLeft:"8px"
            },
            "& .chat-icon":{
              color:"#2B2B2B",
              width:"16px",
              heigth:"16px"
            }
          },
          "& .back-btn-content":{
            display: "flex",
            alignItems: "center",
            justifyContent:" center",
            marginTop:" 24px",
            cursor:"pointer",
            "& .back-txt":{
                color: 'var(--Task-Completed-Color, #2B2B2B)',
                fontFamily: 'Expletus Sans',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                marginLeft:"8px"
            }
          },
        }, 
        "& .main-container":{
          paddingBottom: "24px",
          paddingTop: "24px",
          background: "#FFF",
          padding: "40px",
          marginTop: "24px",
          "& .title":{
           color: 'rgba(0, 0, 0, 0.87)',
           fontFamily: 'Expletus Sans',
           fontSize: '20px',
           fontStyle: 'normal',
           fontWeight: 500,
           lineHeight: 'normal',
           letterSpacing: '0.25px',
         },
         "& .content":{
          color: '#797878',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
          letterSpacing: '0.25px',
          marginTop:"8px",
        },
        "& .button-action":{
         display:"flex",
         justifyContent:"space-between",
         marginTop:"80px",
         "& .primary-btn, & .secondary-btn ": {
          width: "auto",
          minWidth: "268px",
          [theme.breakpoints.down("xs")]: {
            minWidth: "150px",
            padding: "4px 6px",
          },
        },
        }
        },
        "& .user-name": { 
          display: "flex", 
          justifyContent:"space-between",     
          "& .avatar":{
            display: "flex",
            alignItems: "center",           
          },       
          "& .name":{
            color: '#797878',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.25px',
            marginTop: '20px',
            marginLeft:"12px",
            textTransform: "capitalize",
          },
          "& .technical-team":{
            display: "flex",
            alignItems: "center",    
            "& .name":{
              color: '#797878',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '0.25px',
              marginTop: '20px',
              marginLeft:"12px",
              textTransform: "capitalize",
            },
            "& .attachment-icon":{
              height:"16px"
            }
          },
          "& .short-name": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontSize: "10px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            lineHeight: "21.679px",
            fontWeight: "400",
            letterSpacing: "-0.395px",
            width: "24px",
            height: "24px",
            borderRadius: "19.5px",
            backgroundColor: "var(--background-background-medium, #ECF1F4)",
            marginTop:"20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center", 
          },
        },
        "& .main-content-txt":{
           display:"flex",
          "& .content-sub-txt":{
            fontSize: "12px",
            lineHeight: "normal",
            color: "#797878",
            fontFamily: "Roboto",
            fontWeight: 400,
            letterSpacing: "0.4px",
            marginTop: "4px",
            marginBottom:"24px",
            textTransform: "capitalize",         
            "&.content-dots": {
              color: "#797878",
              marginLeft:"12px",
              "&:before": {
                content: '""',
                width: "8px",
                height: "8px",
                borderRadius: "100%",
                background: "#ECF1F4",
                display: "inline-block",
                marginRight: "8px",
              },
            },            
          },
        },
        "& .main-content-info":{
          display:"flex",
          justifyContent:"space-between",
         
          "& .ticket-system-status": {
            color: '#4BA3B7',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            letterSpacing: '0.4px',
            display: 'flex',
            padding: '7px 12px',
            alignItems: 'center',
            borderRadius: "6px",
            height:"30px",
            // background:"rgba(75, 163, 183, 0.08)",
            "&.open": {
              color: "#4BA3B7",
              background: "rgba(75, 163, 183, 0.08)",
              "&:before": {
                background: "#4BA3B7",
              },
            },
            "&.progress": {
              color:"#F6A319",
              background: "#fdf6e9",
              "&:before": {
                background: "#F6A319",
              },
            },
            "&.resolved": {
              color:"#00BA88",
              background: "#eaf9f5",
              "&:before": {
                background: "#00BA88",
              },
            },
            "&.cancelled": {
              "&:before": {
                background: "#F7E223",
              },
            },
            "&.offtrack": {
              "&:before": {
                background: "#C7263E",
              },
            },
            "&:before": {
              content: '""',
              width: "8px",
              height: "8px",
              borderRadius: "100%",
              background: "#2B2B2B",
              display: "inline-block",
              marginRight: "8px",
            },
          },
        },
        "& .modal-content":{
          // padding:"40px 160px 24px 160px",          
          "& .modal-headding":{
            color: 'rgba(0, 0, 0, 0.87)',
            fontFamily: 'Expletus Sans',
            fontSize: '34px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            letterSpacing: '0.25px',
            borderBottom: "1px solid #ECF1F4",
            paddingBottom:"40px"
          },
          "& .container":{
            position:"relative",
            "& .download-action":{
              position:"absolute",
              top:"60px",
              right:"60px",
              display:"flex"
            },
            "& .download-icon":{
              marginRight:"13px",
              cursor:"pointer",
            },
            "& .close-icon":{
              cursor:"pointer",
            },
          },
          "& .sort-by-block":{
           display:"flex",
           justifyContent:"space-between",
           alignItems:"center",
           "& .sortby-control": {
            "& .MuiSelect-select": {
              paddingRight: "0",
            },
            "& .select-icon": {
              top: "3px",
            },
          },
          },
          "& .ticket-content-box":{
            //  marginTop:"24px",
            "& .search-txt":{
              color: 'rgba(0, 0, 0, 0.87)',
              fontFamily: 'Expletus Sans',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
            },
            "& .search-sub-txt":{
              color: "#94A3B8",
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              letterSpacing: '0.4px',
              marginTop:"8px",

            },
            "& .search-content-textfield":{
              marginTop:"24px",            
            },
            "& .primary-btn-new":{
             width:"124px",
             marginTop:"0px",
            }
          },
          "& .select-control": {
            margin: "0 12px",
            "&.multi-select": {
              "& .MuiInput-formControl": {
                marginTop: "0",
              },
              "& .multiselect-label": {
                display: "none",
              },
              "& .MuiSelect-select": {
                paddingRight: "40px",
              },
              "& .multiple-clear": {
                fontSize: "15px",
                position: "absolute",
                top: "3px",
                right: "18px",
                zIndex: 1,
                color: "#94A3B8",
              },
            },
            "& .MuiSelect-select": {
              fontSize: "14px",
              color: "#2b2b2b",
              letterSpacing: "0.1px",
              opacity: "0.671",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              paddingTop: "0",
              paddingBottom: "0",
              "&:focus": {
                backgroundColor: "transparent",
              },
              "& .clear-icon": {
                height: "50px",
                width: "50px",
                background: "red",
              },
              "& .MuiBox-root": {
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "& .select-icon": {
                  marginRight: "6px",
                },
              },
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
        }         
      },    
    },
    modalViewDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
    },
    modalCreateDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      padding: "0",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: 570,
      },
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },

      "&.view-modal-dialog": {
        "& .header-content":{
          display:"flex",
          justifyContent:"space-between",
          width:"100%",
          padding:"0 24px",
          "& .live-chat-box":{
            display: "flex",
            alignItems: "center",
            justifyContent:" center",
            marginTop:" 24px",
            cursor:"pointer",
            "& .live-btn-txt":{
                color: 'var(--Task-Completed-Color, #2B2B2B)',
                fontFamily: 'Expletus Sans',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                marginLeft:"8px"
            },
            "& .chat-icon":{
              color:"#2B2B2B",
              width:"16px",
              heigth:"16px"
            }
          },
          "& .back-btn-content":{
            display: "flex",
            alignItems: "center",
            justifyContent:" center",
            marginTop:" 24px",
            cursor:"pointer",
            "& .back-txt":{
                color: 'var(--Task-Completed-Color, #2B2B2B)',
                fontFamily: 'Expletus Sans',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                marginLeft:"8px"
            }
          },
        }, 
        "& .main-container":{
          paddingBottom: "24px",
          paddingTop: "24px",
          background: "#FFF",
          padding: "40px",
          marginTop: "24px",
          "& .title":{
           color: 'rgba(0, 0, 0, 0.87)',
           fontFamily: 'Expletus Sans',
           fontSize: '20px',
           fontStyle: 'normal',
           fontWeight: 500,
           lineHeight: 'normal',
           letterSpacing: '0.25px',
         },
         "& .content":{
          color: '#797878',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
          letterSpacing: '0.25px',
          marginTop:"8px",
        },
        "& .button-action":{
         display:"flex",
         justifyContent:"space-between",
         marginTop:"80px",
         "& .primary-btn, & .secondary-btn ": {
          width: "auto",
          minWidth: "268px",
          [theme.breakpoints.down("xs")]: {
            minWidth: "150px",
            padding: "4px 6px",
          },
        },
        }
        },
        "& .user-name": { 
          display: "flex", 
          justifyContent:"space-between",     
          "& .avatar":{
            display: "flex",
            alignItems: "center",           
          },       
          "& .name":{
            color: '#797878',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.25px',
            marginTop: '20px',
            marginLeft:"12px"
          },
          "& .technical-team":{
            display: "flex",
            alignItems: "center",    
            "& .name":{
              color: '#797878',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '0.25px',
              marginTop: '20px',
              marginLeft:"12px"
            },
            "& .attachment-icon":{
              height:"16px"
            }
          },
          "& .short-name": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontSize: "10px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            lineHeight: "21.679px",
            fontWeight: "400",
            letterSpacing: "-0.395px",
            width: "24px",
            height: "24px",
            borderRadius: "19.5px",
            backgroundColor: "var(--background-background-medium, #ECF1F4)",
            marginTop:"20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center", 
          },
        },
        "& .main-content-txt":{
           display:"flex",
          "& .content-sub-txt":{
            fontSize: "12px",
            lineHeight: "normal",
            color: "#797878",
            fontFamily: "Roboto",
            fontWeight: 400,
            letterSpacing: "0.4px",
            marginTop: "4px",
            marginBottom:"24px",           
            "&.content-dots": {
              color: "#797878",
              marginLeft:"12px",
              "&:before": {
                content: '""',
                width: "8px",
                height: "8px",
                borderRadius: "100%",
                background: "#ECF1F4",
                display: "inline-block",
                marginRight: "8px",
              },
            },            
          },
        },
        "& .main-content-info":{
          display:"flex",
          justifyContent:"space-between",
         
          "& .ticket-system-status": {
            color: '#4BA3B7',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            letterSpacing: '0.4px',
            display: 'flex',
            padding: '7px 12px',
            alignItems: 'center',
            borderRadius: "6px",
            height:"30px",
            // background:"rgba(75, 163, 183, 0.08)",
            "&.open": {
              color: "#4BA3B7",
              background: "rgba(75, 163, 183, 0.08)",
              "&:before": {
                background: "#4BA3B7",
              },
            },
            "&.progress": {
              color:"#F6A319",
              background: "#fdf6e9",
              "&:before": {
                background: "#F6A319",
              },
            },
            "&.resolved": {
              color:"#00BA88",
              background: "#eaf9f5",
              "&:before": {
                background: "#00BA88",
              },
            },
            "&.cancelled": {
              "&:before": {
                background: "#F7E223",
              },
            },
            "&.offtrack": {
              "&:before": {
                background: "#C7263E",
              },
            },
            "&:before": {
              content: '""',
              width: "8px",
              height: "8px",
              borderRadius: "100%",
              background: "#2B2B2B",
              display: "inline-block",
              marginRight: "8px",
            },
          },
        },
        "& .modal-content":{  
          padding:"60px",
          "& .select-outer": {
            width: "100%",
            // marginTop:'-1.3vw',
            marginBottom: "16px",
            "& .error-select":{
              color:'#C7263E',  
            },
            "& svg":{
             fill:'#94A3B8',  
           },
            "& .Mui-error":{
              "& .MuiOutlinedInput-notchedOutline":{
                borderColor:'#C7263E',  
              }
            },       
            "& .MuiInputLabel-formControl": {
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 500,
              letterSpacing: "0.1px",
              lineHeight: "19px",
              color: "#94A3B8",
              width: "calc(100% - 46px)",
              display: "flex",
              "&.MuiFormLabel-filled": {
                width: "auto",
                fontSize: "12px",
                color : "rgba(43,43,43,0.87)",
                lineHeight: "16px",
                fontFamily: "Roboto",
                letterSpacing: "0.0075em",
                fontWeight: "400",
              }
            },
            "& .MuiSelect-select": {
              letterSpacing: "0",
              fontSize: "14px",
              color: "#000000",
              fontWeight: "500",
              opacity: "0.671",
              fontFamily: "Expletus Sans",
              justifyContent:'space-between',
              display:'flex',
              "&:focus": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
              },
            },
            "& .MuiFormLabel-root": {
              "&.Mui-focused": {
                color : "rgba(43,43,43,0.87)",
                fontSize: "12px",
                width: "auto",
                letterSpacing: "0.0075em",
                lineHeight: "16px",
                fontWeight: "400",
                fontFamily: "Roboto",
              },
              "& .MuiInputLabel-asterisk": {
                lineHeight: "23px",
                marginLeft: "auto",
                color: "#e86577",
                fontSize: "18px",
              }
            }
          },
          "& .primary-btn":{
            width:"100% !important",
            marginTop:"45px !important"
           },
          "& .desc":{
            color: '#2B2B2B',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.25px',
            marginBottom:"60px"
          },         
          "& .modal-wrapper":{
              display:"flex",
              justifyContent:"space-between",
              alignItems:"center",             
            "& .modal-headding":{
              color: 'rgba(0, 0, 0, 0.87)',
              fontFamily: 'Expletus Sans',
              fontSize: '34px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              letterSpacing: '0.25px',
            },
            "& .close-icon":{
              cursor:"pointer",
            },
          },   
        }         
      },    
    },
    modalCreateDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
    },
    modalFilterDialogBox: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      width: 680,
      backgroundColor: "#ffffff",
      padding: "0",
      borderRadius: "0",
      overflowY: "auto",
      height: "100%",
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        width: 570,
      },
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
      "& .primary-btn2":{
        width:"100% !important",
        marginTop:"45px !important"
       },
      "&.view-modal-dialog": {
        "& .header-content":{
          display:"flex",
          justifyContent:"space-between",
          width:"100%",
          padding:"0 24px",        
          "& .live-chat-box":{
            display: "flex",
            alignItems: "center",
            justifyContent:" center",
            marginTop:" 24px",
            cursor:"pointer",
            "& .live-btn-txt":{
                color: 'var(--Task-Completed-Color, #2B2B2B)',
                fontFamily: 'Expletus Sans',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                marginLeft:"8px"
            },
            "& .chat-icon":{
              color:"#2B2B2B",
              width:"16px",
              heigth:"16px"
            }
          },
          "& .back-btn-content":{
            display: "flex",
            alignItems: "center",
            justifyContent:" center",
            marginTop:" 24px",
            cursor:"pointer",
            "& .back-txt":{
                color: 'var(--Task-Completed-Color, #2B2B2B)',
                fontFamily: 'Expletus Sans',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                marginLeft:"8px"
            }
          },
        }, 
        "& .main-container":{
          paddingBottom: "24px",
          paddingTop: "24px",
          background: "#FFF",
          padding: "40px",
          marginTop: "24px",
          "& .title":{
           color: 'rgba(0, 0, 0, 0.87)',
           fontFamily: 'Expletus Sans',
           fontSize: '20px',
           fontStyle: 'normal',
           fontWeight: 500,
           lineHeight: 'normal',
           letterSpacing: '0.25px',
         },
         "& .content":{
          color: '#797878',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
          letterSpacing: '0.25px',
          marginTop:"8px",
        },
        "& .button-action":{
         display:"flex",
         justifyContent:"space-between",
         marginTop:"80px",
         "& .primary-btn, & .secondary-btn ": {
          width: "auto",
          minWidth: "268px",
          [theme.breakpoints.down("xs")]: {
            minWidth: "150px",
            padding: "4px 6px",
          },
        },
        }
        },
        "& .user-name": { 
          display: "flex", 
          justifyContent:"space-between",     
          "& .avatar":{
            display: "flex",
            alignItems: "center",           
          },       
          "& .name":{
            color: '#797878',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.25px',
            marginTop: '20px',
            marginLeft:"12px"
          },
          "& .technical-team":{
            display: "flex",
            alignItems: "center",    
            "& .name":{
              color: '#797878',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '0.25px',
              marginTop: '20px',
              marginLeft:"12px"
            },
            "& .attachment-icon":{
              height:"16px"
            }
          },
          "& .short-name": {
            color: "var(--gray, rgba(43, 43, 43, 0.87))",
            fontSize: "10px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            lineHeight: "21.679px",
            fontWeight: "400",
            letterSpacing: "-0.395px",
            width: "24px",
            height: "24px",
            borderRadius: "19.5px",
            backgroundColor: "var(--background-background-medium, #ECF1F4)",
            marginTop:"20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center", 
          },
        },
        "& .main-content-txt":{
           display:"flex",
          "& .content-sub-txt":{
            fontSize: "12px",
            lineHeight: "normal",
            color: "#797878",
            fontFamily: "Roboto",
            fontWeight: 400,
            letterSpacing: "0.4px",
            marginTop: "4px",
            marginBottom:"24px",           
            "&.content-dots": {
              color: "#797878",
              marginLeft:"12px",
              "&:before": {
                content: '""',
                width: "8px",
                height: "8px",
                borderRadius: "100%",
                background: "#ECF1F4",
                display: "inline-block",
                marginRight: "8px",
              },
            },            
          },
        },
        "& .main-content-info":{
          display:"flex",
          justifyContent:"space-between",
         
          "& .ticket-system-status": {
            color: '#4BA3B7',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            letterSpacing: '0.4px',
            display: 'flex',
            padding: '7px 12px',
            alignItems: 'center',
            borderRadius: "6px",
            height:"30px",
            // background:"rgba(75, 163, 183, 0.08)",
            "&.open": {
              color: "#4BA3B7",
              background: "rgba(75, 163, 183, 0.08)",
              "&:before": {
                background: "#4BA3B7",
              },
            },
            "&.progress": {
              color:"#F6A319",
              background: "#fdf6e9",
              "&:before": {
                background: "#F6A319",
              },
            },
            "&.resolved": {
              color:"#00BA88",
              background: "#eaf9f5",
              "&:before": {
                background: "#00BA88",
              },
            },
            "&.cancelled": {
              "&:before": {
                background: "#F7E223",
              },
            },
            "&.offtrack": {
              "&:before": {
                background: "#C7263E",
              },
            },
            "&:before": {
              content: '""',
              width: "8px",
              height: "8px",
              borderRadius: "100%",
              background: "#2B2B2B",
              display: "inline-block",
              marginRight: "8px",
            },
          },
        },
        "& .modal-content":{  
          padding:"60px",
          "& .select-outer": {
            width: "100%",
            // marginTop:'-1.3vw',
            marginBottom: "16px",
            "& .error-select":{
              color:'#C7263E',  
            },
            "& svg":{
             fill:'#94A3B8',  
           },
            "& .Mui-error":{
              "& .MuiOutlinedInput-notchedOutline":{
                borderColor:'#C7263E',  
              }
            },       
            "& .MuiInputLabel-formControl": {
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 500,
              letterSpacing: "0.1px",
              lineHeight: "19px",
              color: "#94A3B8",
              width: "calc(100% - 46px)",
              display: "flex",
              "&.MuiFormLabel-filled": {
                width: "auto",
                fontSize: "12px",
                color : "rgba(43,43,43,0.87)",
                lineHeight: "16px",
                fontFamily: "Roboto",
                letterSpacing: "0.0075em",
                fontWeight: "400",
              }
            },
            "& .MuiSelect-select": {
              letterSpacing: "0",
              fontSize: "14px",
              color: "#000000",
              fontWeight: "500",
              opacity: "0.671",
              fontFamily: "Expletus Sans",
              justifyContent:'space-between',
              display:'flex',
              "&:focus": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e8ecf2",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
              },
            },
            "& .MuiFormLabel-root": {
              "&.Mui-focused": {
                color : "rgba(43,43,43,0.87)",
                fontSize: "12px",
                width: "auto",
                letterSpacing: "0.0075em",
                lineHeight: "16px",
                fontWeight: "400",
                fontFamily: "Roboto",
              },
              "& .MuiInputLabel-asterisk": {
                lineHeight: "23px",
                marginLeft: "auto",
                color: "#e86577",
                fontSize: "18px",
              }
            }
          },
         
          "& .desc":{
            color: '#2B2B2B',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0.25px',
            marginBottom:"60px"
          },
          "& .filter-headding":{
            color: '#2B2B2B',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '0.25px',
            marginTop:"8px",
            marginBottom:"10px"
          },
          "& .modal-wrapper":{
              display:"flex",
              justifyContent:"space-between",
              alignItems:"center", 
              "& .reset-cross":{
                display:"flex",
                "& .reset-txt":{
                  color: 'var(--brand-color-primary-100, #4BA3B7)',
                  fontFamily: 'Expletus Sans',
                  fontSize: '14px',
                  textAlign: 'right',                
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  marginRight:"16px",
                  cursor:"pointer",
                },
                "& .close-icon":{
                  cursor:"pointer",
                },
               },            
            "& .modal-headding":{
              color: 'rgba(0, 0, 0, 0.87)',
              fontFamily: 'Expletus Sans',
              fontSize: '34px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              letterSpacing: '0.25px',
            },
          }, 
          "& .modal-wrapper-success":{
            display:"flex",
            justifyContent:"flex-end",
            alignItems:"center",             
            "& .close-icon":{
              cursor:"pointer",
            },
          }, 
          "& .headding":{
            color: 'rgba(0, 0, 0, 0.87)',
            fontFamily: 'Expletus Sans',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            letterSpacing: '0.25px',
            textAlign:"center",
            marginTop:"60px",
            marginBottom:"20px",
          },
          "& .primary-btn-back":{
          width:"100%",
          marginTop:"40px"
          },
          "& .success-desc":{
            color: 'rgba(0, 0, 0, 0.87)',
            textAlign: 'center',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '32px', 
            letterSpacing: '0.5px',
          }
        }         
      },    
    },
    modalFilterDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
    },
    dropdownStyle: {
      marginTop: "16px",
      maxWidth: "135px",
      width: "100%",
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingLeft: "24px",
            paddingRight: "24px",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fill: "#94A3B8",
                  fontSize: "20px",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                lineHeight: "17px",
                letterSpacing: "0",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          color: "#2B2B2B",
          padding: "14px 24px",
          "& .MuiListItemText-root": {
            margin: "0",
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
          },
          "& .multiselect-check": {
            display: "none",
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
          },
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#000000",
        padding: "14px 17px",
        fontWeight: "500",
        fontFamily: "Expletus Sans",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        whiteSpace: "normal",
        "& .MuiTypography-root": {
          font: "inherit",
          letterSpacing: "0",
        },
      },
      "& .MuiList-padding": {
        paddingTop: "4px",
        paddingBottom: "8px",
      },
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      minWidth: "198px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    fileUploadWrapper: {
      border: "2px dashed #e8ecf2",
      padding: "62px 30px 30px 30px",
      borderRadius: "12px",
      textAlign: "center",
      transition: "all 0.5s ease-in-out",
      cursor: "pointer",
      // "&:hover": {
      //   filter: "drop-shadow(0px 16px 10px rgba(0,0,0,0.05))",
      //   backgroundColor: "#fafcfe",
      //   borderColor: "transparent",
      // },
      "& .upload-icon": {
        marginBottom: "30px",
      },
      "& .file-upload-sub-headding":{
        color: '#94A3B8',
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        letterSpacing: '0.4px',
        marginTop:"24px"
      },
      "& .file-heading": {
        fontSize: "16px",
        lineHeight: "21px",
        margin: "0 0 7px",
        color: "rgba(0,0,0,0.87)",
        letterSpacing: "0.15px",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
      },
      "& .file-subtxt": {
        fontSize: "12px",
        lineHeight: "16px",
        color: "rgba(0,0,0,0.87)",
        letterSpacing: "0.4px",
        fontFamily: "Roboto",
        fontWeight: 400,
        "& .browse-link": {
          color: "#4ba3b7",
          cursor: "pointer",
          margin: "0 5px",
        },
      },
    },
    fileDropDrag: {
      filter: "drop-shadow(0px 16px 10px rgba(0,0,0,0.05))",
      backgroundColor: "#fafcfe",
      borderColor: "transparent",
    },
    uploadProgress: {
      borderRadius: "12px",
      border: "1px solid #e8ecf2",
      marginTop: "24px",
      position: "relative",
      overflow: "hidden",
      [theme.breakpoints.down("xs")]: {
        marginTop: "0",
        marginBottom: "46px",
      },
      "& .progress-status": {
        width: "56%",
        position: "absolute",
        height: "100%",
        opacity: "0.169",
        backgroundImage: "linear-gradient(270deg, #82d0dc 0%, #08748c 100%)",
      },
      "& .progress-row": {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "29px 24px",
        [theme.breakpoints.down("xs")]: {
          padding: "15px 16px",
        },
        "& .progress-heading": {
          fontSize: "16px",
          lineHeight: "24px",
          margin: "0 0 8px",
          color: "#000000",
          letterSpacing: "0",
          fontFamily: "Roboto",
          fontWeight: 400,
          [theme.breakpoints.down("xs")]: {
            color: "rgba(0, 0, 0, 0.87)",
            letterSpacing: "0.15px",
          },
        },
        "& .progress-subtxt": {
          fontSize: "12px",
          lineHeight: "16px",
          color: "#000000",
          letterSpacing: "0",
          fontFamily: "Roboto",
          fontWeight: 400,
          [theme.breakpoints.down("xs")]: {
            color: "rgba(0, 0, 0, 0.87)",
            letterSpacing: "0.4px",
          },
          "& .remaining": {
            position: "relative",
            marginLeft: "9px",
            paddingLeft: "14px",
            "&:before": {
              content: "''",
              width: "6px",
              height: "6px",
              borderRadius: "3px",
              backgroundColor: "#2b2b2b",
              position: "absolute",
              left: "0",
              top: "4px",
            },
          },
        },
        "& .right-col": {
          marginLeft: "15px",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          "& >a": {
            marginRight: "23px",
            "&:last-child": {
              marginRight: "0",
            },
            "&.play-pause": {
              color: "#000000",
            },
            "&.close": {
              color: "#bf3e50",
              cursor:"pointer",
            },
          },
        },
      },
    },
    uploadFileList: {
      marginTop: "24px",
      padding: "0",
      [theme.breakpoints.down("xs")]: {
        marginTop: "0px",
        marginBottom: "10px",
      },
      "& .MuiListItem-root": {
        marginBottom: "24px",
        borderRadius: "12px",
        backgroundColor: "#ffffff",
        border: "1px solid #e8ecf2",
        padding: "15px 24px",
        [theme.breakpoints.down("xs")]: {
          padding: "14px 24px",
        },
        "&:last-child": {
          marginBottom: "0",
        },
        "& .file-info": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          "& .file-name": {
            fontSize: "16px",
            lineHeight: "24px",
            color: "rgba(0, 0, 0, 0.87)",
            letterSpacing: "0.15px",
            fontFamily: "Roboto",
            fontWeight: 400,
          },
          "& .close": {
            color: "#959595",
            cursor:"pointer",
          },
        },
      },
    },
    carouselWrapper: {
      maxWidth: "100%",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        margin: "0 auto",
      },
      "& .slider-item": {
       height:"350px",
      },
      "& .slider-img": {
        display: "flex",
        height: "350px",
        alignItems: "center",
        justifyContent: "center",
        flexDirection:"column",
        "& .file-name":{
          color: 'var(--text-Secondary, #94A3B8)',
          fontFamily: 'Expletus Sans, sans-serif',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          marginTop:"16px",
        },
        "& img": {
          objectFit: "contain",
          marginTop:"-40px"
        },
      },
      "& .slick-slide": {
        height: "inherit",
      },
      "& .slick-dots":{
        position:"absolute",
        bottom:"45px",
       },
      "& .slick-dots li.slick-active button:before":{
       color:"rgba(0, 0, 0, 0.87)"
      },
      "& .slick-dots li button:before":{
        color:"#565454",
        visibility:"hidden"
      },
      "& .slick-dots li":{
        color:"#FFFF", 
        margin:"0 2px",    
      },
      "& .slick-dots li img":{
        width:"10px",
        height:"5px",
        borderRadius:"2px",       
      },
      "& .slick-dots li.slick-active img":{
        width:"20px",
      },     
    },
  });
// Customizable Area End
