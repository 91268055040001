Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfEtohApi6";
exports.labelBodyText = "CfEtohApi6 Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.getAllCategory="/bx_block_categories/etoh_shop_categories";
exports.getSubCategory="/bx_block_categories/etoh_shop_sub_categories";
exports.filtersApiPath ="/bx_block_catalogue/filter_and_search_products";
exports.searchEtohProductsPath="/bx_block_catalogue/etoh_product_search";
exports.getAllProductsApiPath="/bx_block_catalogue/services";
exports.searchTypeTxt="Start typing to search...";
exports.serviesesTxt="Services";
exports.servieseTxt="Service";
exports.categoriesTxt="Categories";
exports.categorieTxt="Category";
exports.sortByTxt="Sort by";
exports.cardsTxt="Cards";
exports.graphicDesignTaxt="Graphic Design";
exports.brochuresTxt="Brochures";
exports.scialMediaPostTxt ="Social Media Post";
exports.oneTimeTxt="One Time";
exports.subscriptionTxt="Subscription";
exports.noServicesFoundTxt="No services found.";
exports.sortByApiPath="/bx_block_catalogue/etoh_category_sort_by";
exports.searchApiPath="/bx_block_catalogue/etoh_category_search"
// Customizable Area End