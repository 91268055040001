import { createSlice } from "@reduxjs/toolkit";
export type InitialStateType = {
    isMemberAdded: boolean
}
const initialState:InitialStateType = {
    isMemberAdded: false
}
const stateSlice2 = createSlice({
    name: 'stateManager',
    initialState,
    reducers: {
        updateIsMemberAdded: (state, action) => {
            state.isMemberAdded = action.payload
        },
    }
})
export const { updateIsMemberAdded } = stateSlice2.actions
export default stateSlice2.reducer