import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import StorageProvider from "../../../framework/src/StorageProvider";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  seletedItem: string;
  stepNumber: number;
  backBtn: ()=>void;
  nextBtn: ()=>void;
  // Customizable Area End
}

interface S {
// Customizable Area Start
hop: string;
mashing: string;
strength: string;
fermentation: string;
ingredients: string;
error: {
  hop: string;
  mashing: string;
  strength: string;
  fermentation: string;
  ingredients: string;
}
// Customizable Area End
}
  
interface SS {
id: any;
// Customizable Area Start
// Customizable Area End
}
  
// Customizable Area Start

// Customizable Area End
export default class ItemMoreDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      // Customizable Area Start
      hop: '',
      mashing: '',
      strength: '',
      fermentation: '',
      ingredients: '',
      error: {
        hop: '',
        mashing: '',
        strength: '',
        fermentation: '',
        ingredients: '',
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  handleHop = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ hop: event.target.value })
  }
  handleMashing = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ mashing: event.target.value })
  }
  handleStrength = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ strength: event.target.value })
  }
  handleFermentation = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ fermentation: event.target.value })
  }
  handleIngredients = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ ingredients: event.target.value })
  }

  handelValidation =()=>{
    let error = this.state.error;
    let errorFlag = false;
    if (this.state.hop=="") {
      error.hop = "Please enter hop";
      errorFlag = true;
    }
    if (this.state.mashing=="") {
      error.mashing = "Please enter mashing";
      errorFlag = true;
    }

    if (this.state.strength == "") {
      error.strength = "Please enter strength";
      errorFlag = true;
    }
    if (this.state.fermentation == "") {
      error.fermentation = "Please enter fermentation";
      errorFlag = true;
    }

    if (this.state.ingredients == '') {
      error.ingredients = "Please enter ingredients";
      errorFlag = true;
    }
  
    if(errorFlag){
      this.setState({error: error})
    }
    return errorFlag
  }
   
  handleNextStep =()=>{
      this.setMoreDataToStorage();
      this.props.nextBtn()
  }

  setMoreDataToStorage = () => {
    const moreDetailsData = {
      "hop": this.state.hop,
      "mashing": this.state.mashing,
      "strength": this.state.strength,
      "fermentation":this.state.fermentation,
      "ingredients": this.state.ingredients,
    };
    StorageProvider.set("moreDetailsdata", JSON.stringify(moreDetailsData));
  }

  backToDetailsStep = () => {
    this.setMoreDataToStorage();
    this.props.backBtn();
  }

  async componentDidMount(): Promise<void> {
    const moreDetailsData = await StorageProvider.get("moreDetailsdata");
      if(moreDetailsData){
        const moreDetailsJSON = await JSON.parse(moreDetailsData)
        this.setState({
          hop: moreDetailsJSON.hop,
          mashing: moreDetailsJSON.mashing,
          strength: moreDetailsJSON.strength,
          fermentation:moreDetailsJSON.fermentation,
          ingredients: moreDetailsJSON.ingredients,
        })
      }
      const lang = localStorage.getItem("lang") || "en"; 
      await (i18n as any).changeLanguage(lang);
  }

  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
 
  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.clearError(prevState)  
  }

  clearError = (prevState:S) => {
    let moreDetailsError = this.state.error;
    let clearError = false;
  
   


    if (prevState.hop !== this.state.hop) {
      moreDetailsError.hop = "";
      clearError=true;
    }
    if (prevState.mashing !== this.state.mashing) {
      moreDetailsError.mashing = "";
      clearError=true;
    }
    if (prevState.strength !== this.state.strength) {
      moreDetailsError.strength = "";
      clearError=true;
    }
    if (prevState.fermentation !== this.state.fermentation) {
      moreDetailsError.fermentation = "";
      clearError=true;
    }
    if (prevState.ingredients !== this.state.ingredients) {
      moreDetailsError.ingredients = "";
      clearError=true;
    }
    
    if (clearError) {
      this.setState({ error: moreDetailsError });
    }
  };
  // Customizable Area End
}
