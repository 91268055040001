// export const imgPasswordVisible = require("../assets/ic_password_visible.png");
// export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
// export { ReactComponent as Logo } from "../assets/app_logo.svg";
// export { ReactComponent as EnglishLangIcon } from "../assets/english_lan.svg";
// export { ReactComponent as FrenchLangIcon } from "../assets/french_lang.svg";
// export { ReactComponent as GoogleIcon } from "../assets/google_icon.svg";

// Customizable Area Start
export const imgGoogle = require("../assets/google.png");
export const imgIphone = require("../assets/image-iphone.png");
export const imgLogo = require("../assets/logo.png");
export const imgAppIcon1 = require("../assets/app-1.png");
export const imgAppIcon2 = require("../assets/app-2.png");
export const imgTranslate = require("../assets/translate.png");
export const imgEmailChecked = require("../assets/email-checked.svg");
// Customizable Area End

// export const imgPasswordVisible = require("../assets/ic_password_visible.png");
// export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
// export { ReactComponent as Logo } from "../assets/app_logo.png";
export const EnglishLangIcon = require("../assets/english_lan.png");
export const FrenchLangIcon = require("../assets/french_lang.png");
export const Logo = require("../assets/app_logo.png");
export const GoogleIcon = require("../assets/google_icon.png");
export const onBoardingOne = require("../assets/backgroundImg.jpg");
export const onBoardingTwo = require("../assets/backgroundImg.jpg");
export const onboardingThree = require("../assets/backgroundImg.jpg");
export const imgPasswordVisible = require("../assets/passInvisible.png");
export const imgPasswordInVisible = require("../assets/passInvisible.png");
