export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const minusGray = require("../assets/minus-circle.svg");
export const menu = require("../assets/menu.png");
export const plusCircle = require("../assets/plus-circle.svg");
export const arrowGreenUp = require("../assets/arrow-down-circle.svg");
export const checkBoxEmpty = require("../assets/check-empty.png");
export const checkBoxFilled = require("../assets/check-filled.png");
export const bellBadge = require("../assets/bell-badge.svg");
export const menuIcon = require("../assets/menu.svg");
export const chatBubble = require("../assets/chat-bubble.svg");
export const cartIcon = require("../assets/shopping-cart.svg");
export const memberRevoked = require("../assets/member-revoked.png");
export const checkedIcon = require("../assets/account-multiple-plus-outline.png");
export const memberEdited = require("../assets/member-edited.png");
export const thumb = require("../assets/thumb.png");
export const borderIcon = require("../assets/icon-border.svg");
export const backArrowIcon = require("../assets/back-arrow-icon.svg");