import React from "react";
// Customizable Area Start
import {
  Box,
  FormControl,
  Select,
  Typography,
  Checkbox,
  Card,
  MenuItem,
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProductItemController, { Props } from "./ProductItemController.web";
export const configJSON = require("./config");
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { customStyles } from "./ContactsListStyles.web";
import { checkedIcon } from "../../catalogue/src/assets";
import { extractItemDetails, getImageForSubCategory ,shouldShowVolume ,shouldShowBrandOnTop} from "../../../components/src/ReusableFunctions";
// Customizable Area End

export class ProductItem extends ProductItemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, isItemChecked, item, handleItemCheckbox } = this.props;
    const {
      category,
      subCategory,
      targetName,
      brandName,
      materialName,
      colorName,
      style,
      appellation,
      grapeVariety,
      vintage,
      volume,
      area,
      unitOfMeasurement,
      stock_qty,
      itemName,
      price
    } = extractItemDetails(item);
    const showVolume = shouldShowVolume(subCategory);
    const showBrandOnTop = shouldShowBrandOnTop(
      category,
      subCategory,
      brandName
    );

    return (
      <Card className={`${classes.productItemsSection} inventory-checkbox`}>
        <Box className="infoBox">
          {getImageForSubCategory(subCategory, item.attributes)}
          <Box className="info-box-right">
            <Typography className="in-stock-badge">
              <span className="badge-name">
                {this.t(`${configJSON.instockTxt}`)}:{" "}
              </span>
              <span className="counter">{stock_qty}</span>
            </Typography>
            <Checkbox
              checked={isItemChecked}
              onChange={(event) => handleItemCheckbox(event, item.id)}
              className={classes.checkedItem}
              data-test-id={`itemCheckbox-${item.id}`}
              icon={<CheckBoxOutlineBlankIcon />}
              checkedIcon={
                <img src={checkedIcon} alt="checkbox" />
              }
            />
          </Box>
        </Box>
        <Box className="itemDesc titleBox">
          <Box className="desc-left">
            <Box className="date" component="span">
              {vintage} {showBrandOnTop}
            </Box>
            {category === 6 && volume && (
              <Box className="task-about">
                {(vintage || showBrandOnTop) && <span className="dot"></span>}
                {volume}
              </Box>
            )}
          </Box>
        </Box>
        <Box className="infoBox">
          <Box className="infoBoxLeft">
            <Typography className="itemTitle">{itemName}</Typography>
            <Typography className="infoText">
              {brandName}
              {style}
              {targetName !== "" && brandName !== ""
                ? `, ${targetName}`
                : targetName}
              {materialName !== "" && brandName !== ""
                ? `, ${materialName}`
                : materialName}
              {colorName !== "" && (brandName !== "" || style !== "")
                ? `, ${colorName}`
                : colorName}
              {appellation}
              {grapeVariety !== "" && appellation !== ""
                ? `, ${grapeVariety}`
                : grapeVariety}
            </Typography>
          </Box>
          {category === 6 && (
            <Box className="infoBoxRight">
              <FormControl className={classes.productsFormControl}>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.dropdownStyle },
                  }}
                  IconComponent={(props) => <ExpandMoreIcon {...props} />}
                  displayEmpty
                  defaultValue=""
                  data-test-id="PriceDropdown"
                  disableUnderline
                  value={this.state.showPriceAs}
                  onChange={this.changePrice}
                >
                  <MenuItem value={1}>
                    {this.t(`${configJSON.ShowAsOption1}`)}
                  </MenuItem>
                  <MenuItem value={2}>
                    {this.t(`${configJSON.ShowAsOption2}`)}
                  </MenuItem>
                  <MenuItem value={3}>
                    {this.t(`${configJSON.ShowAsOption3}`)}
                  </MenuItem>
                </Select>
              </FormControl>
              <Typography className="itemPrice">${(price || 0).toFixed(2)}</Typography>
            </Box>
          )}
          {category === 10 && (
            <Box className="infoBoxRight">
              <Typography className="itemHeading">
                {showVolume ? "Volume" : "Total Area"}
              </Typography>
              <Typography className="itemPrice">
                {showVolume ? volume : area}
                {unitOfMeasurement}
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
    );
  }

  // Customizable Area End
}

// Customizable Area Start

export default withStyles(customStyles, { withTheme: true })(ProductItem);
// Customizable Area End
