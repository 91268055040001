import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ResponseDataType } from "./InventoryTypes";
import StorageProvider from "../../../framework/src/StorageProvider";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  seletedItem: string;
  stepNumber: number;
  backBtn: ()=>void;
  nextBtn: ()=>void;
  // Customizable Area End
}

interface S {
// Customizable Area Start
foodPairing: string | unknown;
mouthTasting: string | unknown;
aromas: string | unknown;
noseTasing: string | unknown;
eyeTasing: string | unknown;
openingTime: string;
temprature: string;
shortDesc: string;
flavours:string | unknown;
eyeTastingData: {
  id: number,
  name: string,
}[];
noseTastingData: {
  id: number,
  name: string,
}[];
aromasTastingData: {
  id: number,
  name: string,
}[];
flavoursData:{
  id: number,
  name: string,
}[];
mouthTastingData:{
  id: number,
  name: string,
}[];
foodTastingData:{
  id: number,
  name: string,
}[];
openingTimeErr: string;
// Customizable Area End
}
  
interface SS {
id: any;
// Customizable Area Start
// Customizable Area End
}
  
// Customizable Area Start

// Customizable Area End
export default class TastingNotesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEyeTastingApiCallId:string = "";
  getNoseTastingApiCallId:string = "";
  getAromasTastingApiCallId:string = "";
  getFlavoursApiCallId:string = "";
  getMouthTastingApiCallId: string = "";
  getFoodTastingApiCallId:string = "";
  userSessionData: any;
  userToken: {meta:{token:string}};
  openingTimeValidation: RegExp;
  tempValidation:RegExp;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      // Customizable Area Start
      openingTime: '',
      foodPairing: '',
      mouthTasting: '',
      aromas: '',
      noseTasing: '',
      eyeTasing: '',
      temprature: '',
      shortDesc: '',
      flavours:'',
      eyeTastingData: [],
      noseTastingData: [],
      aromasTastingData: [],
      flavoursData:[],
      mouthTastingData: [],
      foodTastingData: [],
      openingTimeErr: '',
      // Customizable Area End
    };

   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData); 
    this.openingTimeValidation = new RegExp(/^(\d+(\.\d{0,1})?)?$/);
    this.tempValidation = new RegExp(/^\d{0,2}$/)
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestCallId && responseJson){
      switch (apiRequestCallId){
        case this.getEyeTastingApiCallId:
          this.eyeDataResponse(responseJson);
          break;
        case this.getNoseTastingApiCallId:
          this.noseDataResponse(responseJson);
          break;
        case this.getAromasTastingApiCallId:
          this.aromasDataResponse(responseJson)
          break;
        case this.getFlavoursApiCallId:
          this.flavoursDataResponse(responseJson)
          break;
        case this.getMouthTastingApiCallId:
          this.mouthDataResponse(responseJson);
          break;
        case this.getFoodTastingApiCallId:
          this.foodPairingDataResponse(responseJson);
          break;
      }
    }
    // Customizable Area End
  }

  eyeDataResponse =(response:ResponseDataType)=>{
    this.setState({eyeTastingData: response.options});
  }
  noseDataResponse=(response:ResponseDataType)=>{
    this.setState({noseTastingData: response.options});
  }
  aromasDataResponse =(response:ResponseDataType)=>{
    this.setState({aromasTastingData: response.options});
  }
  flavoursDataResponse =(response:ResponseDataType)=>{
    this.setState({flavoursData: response.options});
  }
  mouthDataResponse=(response:ResponseDataType)=>{
    this.setState({mouthTastingData: response.options});
  }
  foodPairingDataResponse=(response:ResponseDataType)=>{
    this.setState({foodTastingData: response.options});
  }

  handleopeningTime=(event:React.ChangeEvent<HTMLInputElement>)=>{
    let value= event.target.value
    const isValid = this.openingTimeValidation.test(value) && parseFloat(value) <= 24;
    if(isValid || value==''){
      this.setState({openingTime: value,
        openingTimeErr: ''
      })
    }
  }
  handletemp=(event:React.ChangeEvent<HTMLInputElement>)=>{
    let value= event.target.value
    const isValid = this.tempValidation.test(value);
    if(isValid || value==''){
      this.setState({
        temprature: value=='' ? '': String(Number(value)),
      })
    }
  }
  handledescription=(event:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({shortDesc: event.target.value})
  }
  handleyeTasting=(event:React.ChangeEvent<{value: unknown;}>)=>{
    this.setState({eyeTasing: event.target.value})
  }
  handlenoseTasting=(event:React.ChangeEvent<{value: unknown;}>)=>{
    this.setState({noseTasing: event.target.value})
  }
  handlearomaTasting=(event:React.ChangeEvent<{value: unknown;}>)=>{
    this.setState({aromas: event.target.value})
  }
  handleflavourTasting=(event:React.ChangeEvent<{value: unknown;}>)=>{
    this.setState({flavours: event.target.value})
  }
  handlemouthTasing=(event:React.ChangeEvent<{value: unknown;}>)=>{
    this.setState({mouthTasting: event.target.value})
  }
  handlefoodPairing=(event:React.ChangeEvent<{value: unknown;}>)=>{
    this.setState({foodPairing: event.target.value})
  }

  handletastingData=()=>{
    const value = this.state.openingTime
    if(value!=''){      
      const isValid = this.openingTimeValidation.test(value) && parseFloat(value) >= 0.5 && parseFloat(value) <= 24;
      if(!isValid){
        this.setState({
          openingTimeErr: configJSON.openingTimeError
        })
        return
      }
    }
    this.setTastingNotesData();
    this.props.nextBtn();
  }

  goBackProductStep = () => {
    this.setTastingNotesData();
    this.props.backBtn();
  }

  setTastingNotesData = () => {
    const tastingData ={
      "openingTime": this.state.openingTime,
        "temperature": this.state.temprature,
        "comment": this.state.shortDesc,
        "eye":String(this.state.eyeTasing),
        "nose": String(this.state.noseTasing),
        "mainAroma": String(this.state.aromas),
        "mouth":String(this.state.mouthTasting),
        "foodPairing":String(this.state.foodPairing),
        "flavours": String(this.state.flavours),
    }
    StorageProvider.set("tastingdata", JSON.stringify(tastingData));
  }
  getEyeTasting = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEyeTastingApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsDropdownAPIEndPoint + "eye"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getNoseTasting = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNoseTastingApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsDropdownAPIEndPoint + "nose"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAromasTasting = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAromasTastingApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsDropdownAPIEndPoint + "main_aromas"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFlavoursData = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFlavoursApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsDropdownAPIEndPoint + "flavours"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getMouthTasting = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMouthTastingApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsDropdownAPIEndPoint + "mouth"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  getFoodPairingTasting = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFoodTastingApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productsDropdownAPIEndPoint + "food_pairing"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async componentDidMount(): Promise<void> {
    const tastingdata = await StorageProvider.get("tastingdata");
    if(tastingdata){
      const tastingJSON = await JSON.parse(tastingdata)
      this.setState({
        openingTime: tastingJSON.openingTime,
        temprature: tastingJSON.temperature,
        shortDesc: tastingJSON.comment,
        eyeTasing: tastingJSON.eye,
        noseTasing: tastingJSON.nose,
        aromas: tastingJSON.mainAroma,
        mouthTasting: tastingJSON.mouth,
        foodPairing: tastingJSON.foodPairing,
        flavours: tastingJSON.flavours,
      })
    }
    this.getEyeTasting()
    this.getNoseTasting()
    this.getAromasTasting()
    this.getFlavoursData()
    this.getMouthTasting()
    this.getFoodPairingTasting()
    const lang = localStorage.getItem("lang") || "en"; 
    await (i18n as any).changeLanguage(lang);
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  // Customizable Area End
}
