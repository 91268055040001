// Customizable Area Start
import React from "react";
import { withRouter } from "react-router-dom";

interface NavigationProps {
  history: any;
}

export const withHistory = (Component: any) => {
  const Wrapper: React.FC<NavigationProps> = ({ history, ...props }) => {
    return <Component navigation={{ push: history.push ,replace: history.replace}} {...props} />;
  };

  return withRouter(Wrapper);
};
// Customizable Area End