Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.title1="Sell or Buy products";
exports.title2="Sell / Buy products";
exports.title3=" Buy products";
exports.title4="Sell products";

exports.subtitle1="Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";
exports.subtitle2="Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native";
exports.subtitle3="Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";
exports.subtitle4="Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";

exports.EtOHSite = "EtOH 2023";
exports.EtOHSiteUrl = "https://etoh.digital/";
exports.copyright = "©Copyright";
exports.language1 = "English";
exports.language2 = "Arabic";
exports.sliderTitle1 = "My Teams";
exports.sliderTitle2 = "Tasklist";
exports.sliderTitle3 = "E-Score";
exports.sliderSubTitle1 = "Find all of your organisation's teams and members structured as they are in reality.";
exports.sliderSubTitle2 = "Add and assign tasks you and your teammates are working on to keep visibility on what needs to be done and by when.";
exports.sliderSubTitle3 = "Take an E-Quiz we've made or create your own to measure your company's performance. Use this to benchmark, progress, and meet your goals.";
exports.prevBtnText = "Prev Step";
exports.nextBtnText = "Next Step";
exports.modalTypeText = "downloadApp";
exports.downloadModalHeading = "Download EtOH Suite on the App Store and Google Play.";
exports.downloadText = "Download App";

// Customizable Area End