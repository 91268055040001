//Customizable Area Start
import React from "react";
import { Box, Typography, CardMedia, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "./EquizStyles.web";
import EquizScoreControllerWeb, {
  Props,
} from "./QuizScoreScreenController.web";
import {
  imgGradeA,
  imgGradeB,
  imgGradeC,
  imgGradeD,
  imgGradeE,
} from "./assets";
import { Link } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
export const configJSON = require("./config");
export class EquizScoreWeb extends EquizScoreControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes,t } = this.props;
    const grade: string = this.props.grade;
    let gradeImg = "";
    let gradeHeaderTxt = "";

    if (grade === "A") {
      gradeImg = imgGradeA;
      gradeHeaderTxt = configJSON.gradeAText;
    } else if (grade === "B") {
      gradeImg = imgGradeB;
      gradeHeaderTxt = configJSON.gradeBText;
    } else if (grade === "C") {
      gradeImg = imgGradeC;
      gradeHeaderTxt = configJSON.gradeCText;
    } else if (grade === "D") {
      gradeImg = imgGradeD;
      gradeHeaderTxt = configJSON.gradeDText;
    } else if (grade === "E") {
      gradeImg = imgGradeE;
      gradeHeaderTxt = configJSON.gradeEText;
    }

    const choseQuizDesctiption = () => {
      return (
        <Typography className="grade-score-sub-title-wrapper">
          EtOH offers expert consulting services that can help enhance your
          E-Score. Get in touch with us through{" "}
          <a
            href={configJSON.recommendationLink}
            target="_blank"
            className="grade-score-sub-title"
          >
            chat{" "}
          </a>
          for more information.
          .
        </Typography>
      );
    };

    const createQuizDescription = () => {
      return (
        <Typography className="grade-score-sub-title-wrapper">
          To improve your {this.props.quizTitle} E-Score, you can refer to our
          <a
            href={configJSON.recommendationLink}
            target="_blank"
            className="grade-score-sub-title"
          >
            Online Tools
          </a>
          or seek assistance from an expert through the
          <a
            href={configJSON.recommendationLink}
            className="grade-score-sub-title"
            target="_blank"
          >
            economic consultation services
          </a>
          available at the EtOH Shop. For any questions, don’t hesitate to reach
          out to EtOH over
          <a
            className="grade-score-sub-title"
            href={configJSON.recommendationLink}
            target="_blank"
          >
            {" "}
            chat
          </a>
          .
        </Typography>
      );
    };

    return (
      <>
      {!this.props.isLoadingState && <>
        <Box className={classes.quizScore}>
          <Typography className="congratulations-text">
            {gradeHeaderTxt}
          </Typography>
          <Typography className="economic-text">
            Your {this.props.quizTitle} E-Score is
          </Typography>
          <CardMedia component="img" image={gradeImg} title="Grade A" />
          <Typography className="grade-score-title">
            {t("Our Recommendation")}
          </Typography>
          {this.props.scoreDescriptionType === "chooseQuiz"
            ? createQuizDescription()
            : choseQuizDesctiption()}

          <Box className="bottom-view">
            <Button
              className={`secondary-btn ${classes.secondaryButton}`}
              onClick={this.handleRetakeQuizModal}
            >
              {t("Retake E-Quiz")}
            </Button>
            <Button
              className={`primary-btn ${classes.primaryButton} secondary-bottom-btn`}
              data-testid="nextBtn"
              onClick={this.props.dashboardBtnHandler}
            >
              {t("Go to Dashboard")}
            </Button>
            {/* <Link
              to="/dashboard"
              className={`primary-btn ${classes.primaryButton}`}
              onClick={this.handleDashboardClick}
            >
              Go to Dashboard
            </Link> */}
          </Box>
        </Box>
        <Modal
          open={this.state.reTakeQuizModal}
          onClose={this.handleCloseReTakeQuizModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalBoxCenter}
        >
          <Box className={classes.modalreTakeQuiz}>
            <Box className="modal-heading">
              <Typography className="modal-title" component="h2">
                {t("Retake E-Quiz")}
              </Typography>
              <Link
                to="#"
                className="close-icon"
                onClick={this.handleCloseReTakeQuizModal}
              >
                <CloseIcon style={{ fontSize: "24px" }} />
              </Link>
            </Box>
            <Box className="modal-description">
              <Typography className="modal-desc">
                {t("Are you sure you want to retake this E-Quiz? Your previous E-Score will be replaced by the new score.")}
              </Typography>
              <Box className="modal-footer">
                <Button
                  className={`secondary-btn ${classes.secondaryButton}`}
                  title="Cancel"
                  data-test-id="btn-cancel-retake"
                  onClick={this.handleCloseReTakeQuizModal}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  title="Knowledge Base"
                  data-test-id="btn-retake-quiz"
                  onClick={this.props.reTakeBtn}
                >
                  {t("Retake E-quiz")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </>}
       
      </>
    );
  }
}
//@ts-ignore
export default withStyles(customStyles, { withTheme: true })(EquizScoreWeb);
//Customizable Area End
