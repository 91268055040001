import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  withStyles
} from "@material-ui/core/styles";
import { etohlogo, emailSuccess
} from "./assets";
import { customStyles } from "./ItemSuccessPage.web";
// Customizable Area End

import SendEmailSuccessController, {
  Props,
  configJSON,
} from "./SendEmailSuccessController.web";

export class SendEmailSuccess extends SendEmailSuccessController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {classes} = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      
        <Box>
            <Box className={classes.logoBox}>
                <img src={etohlogo} alt="logo" />
            </Box>
            <Box className={classes.mainContainer}>
                <Box data-testId={"image-box"} className='content-img'>
                <img src={emailSuccess} className="logo"/>
                </Box>
                <Box className='content-box'>
                  <Typography className={classes.heading} variant="h1">
                  Email Sent Successfully
                  </Typography>
                  <Typography className={classes.subtitleText}>
                  View your email activities in the marketing tab.
                  </Typography>
                  <Box className={classes.buttonBox}>
                    <Button className="back-btn" onClick={this.backToInventory}
                    data-testId={"back-btn"}
                    >
                        {this.t(`${configJSON.backInvetoryButton}`)}
                    </Button>
                    <Button className="add-next-item" onClick={this.addNewItem}
                    data-testId={"add-another-btn"}
                    >
                        {this.t(`${configJSON.addAnotherButton}`)}
                    </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(SendEmailSuccess);
// Customizable Area End
