// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  ListItemText,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  Modal,
} from "@material-ui/core";
import { close, sortVariant } from "./assets";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import CreateDealController, { Props } from "./CreateDealController.web";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import { customStyles } from "./ContactsListStyles.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  attachGray,
  checkGreen,
  searchDeals,
} from "../../LeadManagement/src/assets";
import { FileDrop } from "react-file-drop";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { configJSON } from "./ContactsListController.web";

class CreateDeal extends CreateDealController {
  constructor(props: Props) {
    super(props);
  }
  renderDealsStatusItems = () => {
    const dealsStatusItems = [
      { value: 0, name: "Initiated", className: "sky-blue" },
      { value: 1, name: "Sent Proposal", className: "orange" },
      { value: 2, name: "Follow Up", className: "yellow" },
      { value: 3, name: "Won", className: "green" },
      { value: 4, name: "Lost", className: "black" },
    ];

    return dealsStatusItems.map((i) => (
      <MenuItem key={i.value} value={i.value} className="status-item">
        <Box className="status-name">{i.name}</Box>
        <Box className={`status ${i.className}`}></Box>
      </MenuItem>
    ));
  };
  render() {
    const { classes , handleCloseDialog , handleSelectContactsOpen} = this.props;

    console.log("test function",handleCloseDialog)

    const fileUpoladInput = () => {
      return (
        <Box className="form-info-wrapper attachment-deals">
          <Typography className="form-heading" variant="h2">
            {this.t(`${configJSON.attachments}`)}
          </Typography>
          <Box className={classes.fileUploadWrapper}>
            <form ref={this.formRef}>
              <input
                type="file"
                name="upload-photo"
                ref={this.fileRef}
                hidden
              />
            </form>
            <FileDrop>
              <Box className="file-upload-block">
                <Typography className="file-heading" variant="h2">
                  <Box className="attach-icon-deals">
                    <img src={attachGray} alt="attachGray" />
                  </Box>
                  {this.t(`${configJSON.dragDropFiles}`)}
                  <form ref={this.formRef}>
                    <input
                      accept="*"
                      name="upload-photo"
                      type="file"
                      onChange={(e) => {
                        if (e.preventDefault) {
                          e.preventDefault();
                        }
                        this.handleOnDrop(e.target.files);
                      }}
                      ref={this.fileRef}
                      hidden
                      data-test-id="deal-file-attachment"
                    />
                  </form>
                  <Box
                    component="span"
                    onClick={this.onBrowse}
                    className="browse-link-file"
                    data-test-id="attachment-link"
                  >
                    {this.t(`${configJSON.browseFiles}`)} 
                  </Box>
                    {this.t(`${configJSON.yourFiles}`)}
                </Typography>
              </Box>
            </FileDrop>
          </Box>
          {this.state.filesUploaded && (
            <List className="attachlist-ul">
              <ListItem
                className="attachlist-li"
                disableRipple
                dense
                role={undefined}
                button
              >
                <ListItemIcon className="attachlist-icon">
                  <img src={checkGreen} alt="checkGreen" />
                </ListItemIcon>
                <ListItemText
                  id="checkbox-list-label"
                  className="attachlist-label"
                  primary={this.state.filesUploaded.name}
                />
                <ListItemSecondaryAction>
                  <Link to="#" className="tasklist-link link-gray">
                    <img src={close} alt="closeGray" />
                  </Link>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          )}
        </Box>
      );
    };
    const chooseContactInput = () => {
      const retrievedArrayLength = JSON.parse(
        localStorage.getItem("contactIds") ?? "[]"
      ).length;

      let message;
      if (retrievedArrayLength === 0) {
        message = "Contact";
      } else {
        message = `${retrievedArrayLength} Contact${
          retrievedArrayLength !== 1 ? "s" : ""
        }`;
      }
      return (
        <Box className="form-row">
          <Box className="form-col">
            <CustomInputWeb             
              name="Contact"
              label="Contact"
              isRequired={true}
              disabled={true}
              errors={this.state.createDealError.dealContactError}
              data-test-id="choose-contact-input"
              value=""
              startAdornment={
                <Typography className="count-selected">{message}</Typography>
              }
              endAdornment={
                <InputAdornment
                onClick={handleSelectContactsOpen}
                position="end"
                data-test-id="openContactModal"
                >
                  <ChevronRightIcon style={{fontSize: "20px"}} />
                </InputAdornment>
              }
            />
          </Box>
        </Box>
      );
    };
    const sortByModal = () => {
      return (
        <FormControl className="select-control">
          <Select
            inputProps={{ IconComponent: () => null }}
            // onChange={() => console.log("sort a-z")}
            defaultValue="Sort by"
            displayEmpty
            MenuProps={{
              anchorOrigin: {
                horizontal: "left",
                vertical: "bottom",
              },
              transformOrigin: {
                horizontal: "left",
                vertical: "top",
              },
              classes: { paper: classes.dropdownStyle },
              getContentAnchorEl: null,
            }}
            renderValue={(value) => {
              return (
                <Box>
                  <img
                    src={sortVariant}
                    className="select-icon"
                    alt="sortVariantDeal"
                  />
                  {value}
                </Box>
              );
            }}
          >
            <MenuItem value="A-Z">A-Z</MenuItem>
            <MenuItem value="Z-A">Z-A</MenuItem>
          </Select>
        </FormControl>
      );
    };

    let ownersfirstName, ownerLastName, ownerEmail;
    const { selectedOwner, dealOwners } = this.state;
    if (selectedOwner?.attributes) {
      ownerLastName = selectedOwner.attributes.last_name;
      ownersfirstName = selectedOwner.attributes.first_name;
      ownerEmail = selectedOwner.attributes.email;
    }

    const dealOwnersCount = this.state.dealOwners.length - 1;
    let memberMessage;
      if (dealOwnersCount === 0) {
        memberMessage = `${dealOwnersCount} Member`;
      } else {
        memberMessage = `${dealOwnersCount} Member${
          dealOwnersCount !== 1 ? "s" : ""
        }`;
    }
  
    return (
      <Box>
        <Box className="deals-heading">
          <Box className="heading-left-deals">
            <Typography className="modal-title" component="h2">
              {this.t(`${configJSON.createDeal}`)}
            </Typography>
            <Box component="p" className="sub-txt">
            {this.t(`${configJSON.dealsSubText}`)}              
            </Box>
          </Box>
          <Box className="heading-right">
            {/* <Link
              to="#"
              className="heading-icon"
              onClick={this.props.handleMinimizeDealContact}
              data-test-id="minimMizeCreateDeal"
            >
              <img src={new_page} alt="minimizeGray" />
            </Link> */}
            <Link
              to="#"
              onClick={handleCloseDialog}
              className="heading-icon"
              data-test-id="new-deal-btn-close"
            >
              <img src={close} alt="closeGray" />
            </Link>
          </Box>
        </Box>
        <Box className="task-description">
          <Box className="task-form" component="form">
            <Box className="form-info-wrapper">
              <Box className="form-row">
                <Box className="form-col">
                  <CustomInputWeb
                    type="text"
                    value={this.state.dealName}
                    label={this.t(`${configJSON.dealName}`)} 
                    isRequired={true}
                    name="dealName"
                    errors={this.state.createDealError.dealNameError}
                    onChange={this.handleAllInputChange}
                    fullWidth
                    data-test-id="dealName"
                  />
                </Box>
              </Box>
              <Box className="form-row">
                <Box className="form-col">
                  <Box className="form-control">
                    <CustomDatePicker
                      range={false}
                      placeholder={this.t(`${configJSON.expectedCloseDate}`)}
                      value={this.state.dealCloseDate}
                      onChange={this.handleCloseDateChange}
                      currentDate=""
                      data-test-id="dealCloseDate"
                      onOpenPickNewDate={false}
                      minDate={new Date()}
                      calendarPosition="bottom-right"
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="form-row">
                <Box className="form-col">
                  <FormControl className="select-outer" variant="outlined">
                    <InputLabel id="stage">{this.t(`${configJSON.stagetxt}`)}</InputLabel>
                    <Select
                      labelId="stage"
                      name="dealStage"
                      id="stage"
                      label={this.t(`${configJSON.stagetxt}`)}
                      value={this.state.dealStage}
                      onChange={this.handleAllInputChange}
                      MenuProps={{
                        anchorOrigin: {
                          horizontal: "left",
                          vertical: "bottom",
                        },
                        transformOrigin: {
                          horizontal: "left",
                          vertical: "top",
                        },
                        classes: {
                          paper: `${classes.dropdownStyleDeals}`,
                        },
                        getContentAnchorEl: null,
                      }}
                      IconComponent={ExpandMoreIcon}
                      data-test-id="dealStage"
                    >
                      {this.renderDealsStatusItems()}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box className="form-row">
                <Box className="form-col">
                  <CustomInputWeb
                    type="text"
                    isMultiline
                    label={this.t(`${configJSON.description}`)}
                    value={this.state.dealDescription}
                    rows={4}
                    onChange={this.handleAllInputChange}
                    name="dealDescription"
                    data-test-id="dealDescription"
                  />
                </Box>
              </Box>
              <Box className="form-row">
                <Box className="form-col">
                  <CustomInputWeb
                    label={this.t(`${configJSON.dealOwner}`)}
                    type="text"
                    disabled={true}
                    name="Deal Owner"
                    data-test-id="chooseDealOwnersModal"
                    isRequired={true}
                    // errors={this.state.createDealError.dealOwnerError}
                    value=""
                    startAdornment={
                      <InputAdornment position="start">
                        <Typography className="count-selected">
                          {ownersfirstName} {ownerLastName}
                        </Typography>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment
                        position="end"
                        data-test-id="openDealOwnersModal"
                        onClick={this.openChooseDealModal}
                      >
                      <ExpandMoreIcon style={{fontSize: "20px"}}/>
                      </InputAdornment>
                    }
                  />
                </Box>
              </Box>
              {chooseContactInput()}
            </Box>
            {fileUpoladInput()}
          </Box>
        </Box>
        <Box className="createtask-bottom">
          <Box className="full-btn">
            <Button
              onClick={this.createDealHandler}
              className={`primary-btn ${classes.primaryButton}`}
              data-test-id="create-deal-btn"
            >
              {this.t(`${configJSON.createDeal}`)}
            </Button>
          </Box>
        </Box>
        {this.state.isActiveChooseDeal && (
          <Modal
            open={this.state.isActiveChooseDeal}
            onClose={this.closeChooseDealOwner}
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            className={classes.chooseModalBox}
          >
            <Box className={classes.dealOwnersModal}>
              <Box className="modal-heading">
                <Box className="heading-left">
                  <Typography className="modal-title" component="h2">
                   {this.t(`${configJSON.dealOwner}`)}
                  </Typography>
                  <Box component="p" className="sub-txt">                    
                    {this.t(`${configJSON.dealOwnerSubTxt}`)}
                  </Box>
                </Box>
                <Box className="heading-right">
                  <Link
                    to="#"
                    onClick={this.closeChooseDealOwner}
                    className="close-icon"
                    data-test-id="closeDealOwnerModal"
                  >
                    <CloseIcon />
                  </Link>
                </Box>
              </Box>
              <Box className="search-box">
                <CustomInputWeb
                  label=""
                  type="select"
                  data-test-id="select"
                  //value={searchContactKeyword}
                  placeholder={this.t(`${configJSON.searchOwner}`)}
                  //onChange={(event: any) => handleSearchContact(event.target.value)}
                  errors={""}
                  startAdornment={
                    <InputAdornment position="start">
                      <img src={searchDeals} alt="search" />
                    </InputAdornment>
                  }
                />
              </Box>
              <Box className="assign-owner">
                <Box className="selected-owner-header">
                  <Typography className="selected-owner-txt" component="h2">
                    {this.t(`${configJSON.selectedOwner}`)}
                  </Typography>
                </Box>
                <Box className="selected-owner">
                  <Box className="select-owner-item">
                    <Box className="avatar">
                      <Typography className="short-name">
                        {ownersfirstName?.charAt(0).toUpperCase()}
                        { ownerLastName?.charAt(0).toUpperCase()}
                      </Typography>
                    </Box>
                    <Box className="owner-info">
                      <Typography className="name">
                        {ownersfirstName} {ownerLastName}
                      </Typography>
                      <Typography className="email">{ownerEmail}</Typography>
                      <Box className="departments">                       
                      </Box>
                    </Box>
                    <CheckCircleIcon className="checkedCirlce" />
                  </Box>
                </Box>
                <Box className="un-assign-owner">
                  <Typography className="top-title">{this.t(`${configJSON.unassigned}`)}</Typography>
                  <Box className="top-list">
                    <Typography className="count-owner">
                    {memberMessage}
                    </Typography>
                    {sortByModal()}
                  </Box>
                  {dealOwners.length > 0 && (
                    <Box className="un-assign-owner-list">
                      {dealOwners
                        .filter(
                          (x: any) => this.state.selectedOwner.id !== x.id
                        )
                        .map((owner: any) => {
                          return (
                            <Box
                              className="owner-item"
                              key={owner.id}
                              onClick={() => this.handleOwnerClick(owner.id)}
                              data-test-id={`owner-item-${owner.id}`}
                            >
                              <Box className="avatar">
                                <Typography className="short-name">
                                  {owner.attributes.first_name.charAt(0).toUpperCase()}
                                  {owner.attributes.last_name.charAt(0).toUpperCase()}
                                </Typography>
                              </Box>
                              <Box className="owner-info">
                                <Typography className="name">
                                  {owner.attributes.first_name}
                                  {owner.attributes.last_name}
                                </Typography>
                                <Typography className="email">
                                  {owner.attributes.email}
                                </Typography>
                                {owner.attributes.team !== null && (
                                  <Box className="departments">
                                    {owner.attributes.team.map(
                                      (temName: {
                                        id: number;
                                        title: string;
                                      }) => {
                                        return (
                                          <Box
                                          key={temName.id}
                                          className="dep-item"
                                          >
                                            <Typography className="dep-name">
                                              {temName.title}
                                            </Typography>
                                          </Box>
                                        );
                                      }
                                    )}
                                  </Box>
                                )}
                              </Box>
                              <RadioButtonUnchecked className="circleCheck" />
                            </Box>
                          );
                        })}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Modal>
        )}
      </Box>
    );
  }
}

export default withStyles(customStyles, { withTheme: true })(CreateDeal);
// Customizable Area End
