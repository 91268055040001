//Customizable Area Start
import React from "react";
import { StepIconProps } from "@material-ui/core/StepIcon";
import { Box } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import CreateIcon from "@material-ui/icons/Create";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { license } from "./assets";
import { takeQuiz } from "./assets";
import { createQuiz } from "./assets";
const icons: { [index: string]: React.ReactElement } = {
  1: <CreateIcon />,
  2: <CreateIcon />,
  3: <LockOutlinedIcon />,
  4: <LockOutlinedIcon />,
};
export const LicenseImage = () => {
  return (
    <>
      <img src={license} />
    </>
  );
};

export const TakeQuizImage = () => {
  return (
    <>
      <img src={takeQuiz} />
    </>
  );
};

export const CreateQuizImage = () => {
  return (
    <>
      <img src={createQuiz} />
    </>
  );
};

export function getSteps(activeStep: number, stepsLabel: any) {
  const entries = Object.entries(stepsLabel);
  const result = entries.map(([stepKey, stepLabel], index) => {
    return (
      <>
        <Box className="step-no">
          {activeStep > index ? "Completed" : `Step ${index + 1}`}{" "}
        </Box>
        <Box className="step-label">{`${stepLabel}`}</Box>
      </>
    );
  });

  return result;
}

export function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, icon } = props;
  const propsLength = Object.keys(props).length;
  let stepIconClassName = "stepIcon";
  if (completed) {
    stepIconClassName += " completed";
  } else if (active) {
    stepIconClassName += " active";
  }
  
  const setIcons = () => {
    switch (icon) {
      case 2:
        return propsLength === 4 ? <CreateQuizImage /> : <TakeQuizImage />;
      case 3:
        return <TakeQuizImage />;
      case 4:
        return <LicenseImage />;
      default:
        return icons[String(icon)];
    }
    
  };

  return (
    <Box className={stepIconClassName}>
      {completed ? (
        <Check className="completed" />
      ) : active ? (
        <>{setIcons()}</>
      ) : (
        <>{icons[String(props.icon)]}</>
      )}
    </Box>
  );
}
//Customizable Area End
