// Customizable Area Start
import React from "react";
import Box from "@material-ui/core/Box";
import { Typography,Accordion,AccordionSummary,AccordionDetails,InputAdornment,Checkbox,Button} from "@material-ui/core";
import CustomInputContent from "./CustomInput.web";
import { configJSON } from "../../blocks/ContactsList/src/ProductListController.web";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { checked } from "../../blocks/ContactsList/src/assets";

interface SectionProps {
  title: string;
  searchValue: string;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  itemList: any;
  handleChecked: (id: string, name?: any) => void;
  selectedCheckBox: any;
  expanded: any;
  handleExpandToggle: (e: any) => void;
  name: any;
  t: any;
}

const Section: React.FC<SectionProps> = ({
  name,
  t,
  title,
  searchValue,
  handleSearch,
  itemList,
  handleChecked,
  selectedCheckBox,
  expanded,
  handleExpandToggle,
}) => {
  const visibleItems = expanded ? itemList : itemList.slice(0, 5);
  return (
    <Box className="section-header">
      <Accordion data-test-id="accordian">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#94A3B8" }} />}
          id="panel1a-header"
          aria-controls="panel1a-content"
        >
          <Typography className="section-title">{t(`${title}`)}</Typography>
        </AccordionSummary>
        <AccordionDetails
          className="accordion-details"
          data-test-id="accordionDetails"
        >
          <CustomInputContent
            type="search"
            value={searchValue}
            name={name}
            label=""
            placeholder={`${t(configJSON.searchSidbarTxt)} ${t(title)}`}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon style={{ color: "rgb(131 128 128)" }} />
              </InputAdornment>
            }
            data-test-id={`handle-search-${title.toLowerCase()}`}
          />
          <Box className="wrap-items">
            {visibleItems
              .filter((item: any) => item.attributes.name !== null)
              .map((item: any) => {
                const { name, id } = item.attributes;
                return (
                  <Box className="product-item" key={id}>
                    <Checkbox
                      className="chk-item"
                      checked={selectedCheckBox === id}
                      onChange={() => handleChecked(id)}
                      checkedIcon={<img src={checked} alt="checkbox" />}
                      data-test-id="checkbox-selected"
                    />
                    <Typography className="item-txt">{name}</Typography>
                  </Box>
                );
              })}
          </Box>
          {itemList.length > 5 && (
            <Box
              className="wrap-expand"
              data-test-id="expand"
              onClick={handleExpandToggle}
            >
              <Button className="expand-list-btn">
                {expanded
                  ? `- ${itemList.length - 5} ${t("less")}`
                  : `+ ${itemList.length - 5} ${t("more")}`}
              </Button>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Section;
// Customizable Area End
