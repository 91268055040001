// Customizable Area Start
import React from "react";
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";

export const customStyles = (theme: AugmentedTheme) =>
createStyles({
  overrides: {
    MuiButton: {
      primaryButton: {},
    },
  },
  langWrapper: {
    "& .inner-wrapper": {
      width: "100%",
      padding: "78px 0 194px",
      [theme.breakpoints.down("sm")]: {
        padding: "21px 0 129px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "24px 0",
      },
    },
    "& .back-btn-wrap": {
      display: "flex",
      margin:"28px",     
      "& .back-btn":{
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: 'Expletus Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px', 
        letterSpacing: '0.15px',
        marginLeft:"8px",       
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
    },
  },
  langInnerWrapper: {
    textAlign: "left",
    // maxWidth: "1640px",
    //padding: "0 20px",
    maxWidth: "100%",
    width: "100%",
    padding: "0 160px",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      // maxWidth: "1088px",
      padding: "0 116px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 24px",
    },
    "& .main-heading": {
      marginBottom: "48px",
      paddingBottom: "48px",
      borderBottom: "1px solid #E8ECF2",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "32px",
        marginBottom: "44px",
      },
      [theme.breakpoints.down("xs")]: {
        paddingBottom: "0",
        marginBottom: "48px",
        borderBottom: "0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      "& .setting-menu": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          display: "block",
        },
      },
      "& .heading": {
        fontWeight: 600,
        fontSize: "32px",
        lineHeight: "42px",
        color: "#2B2B2B",
        fontFamily: "Expletus Sans",
        letterSpacing: "0",
        [theme.breakpoints.down("xs")]: {
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "18px",
          margin: "0 20px",
        },
      },
    },
  },
  formInfoWrapper: {
    margin: "0 0 12px",
    "& .form-title": {
      fontSize: "24px",
      lineHeight: "30px",
      margin: "0 0 16px",
      color: "rgba(43, 43, 43, 0.87)",
      letterSpacing: 0,
      fontFamily: "Expletus Sans",
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: "22px",
        lineHeight: "26px",
      },
      "&.verify-title": {
        margin: "0 0 9px",
      }
    },
    "& .sub-txt": {
      fontSize: "14px",
      lineHeight: "26px",
      margin: "0 0 16px",
      color: "#94A3B8",
      letterSpacing: "0.105px",
      fontFamily: "Roboto",
      fontWeight: 400,
    },
    "& .form-label": {
      fontSize: "14px",
      lineHeight: "26px",
      margin: "0 0 8px",
      color: "rgba(43, 43, 43, 0.87)",
      letterSpacing: "0.105px",
      fontFamily: "Roboto",
      fontWeight: 400,
    },
    "& .MuiFormHelperText-root": {
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.0075em",
      color: "#94A3B8",
      marginTop: "6px",
      fontFamily: "Roboto",
      fontWeight: 400,
      "&.error-select": {
        color: "#C7263E",
      },
      "&.Mui-error": {
        color: "#C7263E",
      },
      "&.Mui-default": {
        color: "#2B2B2B",
      },
    },
    "& .form-heading": {
      fontSize: "16px",
      lineHeight: "24px",
      margin: "0 0 32px",
      color: "#2b2b2b",
      letterSpacing: 0,
      fontFamily: "Expletus Sans",
      fontWeight: 500,
    },
    "& .form-row": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      margin: "0 -12px",
      [theme.breakpoints.down("xs")]: {
        margin: "0 -10px",
      },
      "&:last-child": {
        "& .form-control": {
          marginBottom: "0",
        },
      },
      "& .form-col": {
        padding: "0 12px",
        flex: "0 0 100%",
        maxWidth: "100%",
        [theme.breakpoints.down("xs")]: {
          padding: "0 10px",
        },
      },
      "& .col6": {
        flex: "0 0 50%",
        maxWidth: "50%",
        [theme.breakpoints.down("xs")]: {
          flex: "0 0 100%",
          maxWidth: "100%",
        },
      },
    },
    "& .form-control": {
      marginBottom: "24px",
    },
    "& .description-box": {
      borderRadius: "12px",
      background: "#F2F8FC",
      padding: "16px 24px",
      color: "#2B2B2B",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "26px",
      letterSpacing: "0.105px",
      fontFamily: "Roboto",
      "& .label": {
        fontWeight: 700,
      },
    },
  },
  langDrawerWrapper: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      paddingTop:"40px",
    },
  },
  langDrawerRootWrapper: {
    "& .MuiBackdrop-root": {
      [theme.breakpoints.down("xs")]: {
        background: "rgba(34, 89, 128, 0.70)",
      },
    },
    "& .drawer-inner": {
      [theme.breakpoints.down("xs")]: {
        padding: "56px 24px",
      },
    },
  },
  drawer: {
    minWidth: 302,
    [theme.breakpoints.down("md")]: {
      minWidth: 123,
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  langDrawerPaper: {
    width: "100%",
    position: "static",
    borderRight: "0",
    overflow:"hidden",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      position: "fixed",
    },
    "& .sidebar-heading": {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "48px",
        display: "flex",
        alignItems: "center",
      },
      "& .back-btn-wrap": {
        [theme.breakpoints.down("xs")]: {
          marginRight: "16px",
        },
      },
      "& .heading": {
        [theme.breakpoints.down("xs")]: {
          fontWeight: 400,
          fontSize: "24px",
          lineHeight: "30px",
          color: "#2b2b2b",
          fontFamily: "Expletus Sans",
        },
      },
    },
  },
  langDrawerContent: {
    flex: 1,
  },
  langBlockOuter: {
    maxWidth: "680px",
    width: "100%",
    padding: "0 15px",
    margin: "0",
    "& .lang-headding":{
      color: 'var(--Task-Completed-Color, #2B2B2B)',
      fontFamily: 'Expletus Sans',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '30px', 
      marginBottom:"50px",
    },
    "& .radio-content":{     
      "& .lang-title":{
        color: 'var(--Task-Completed-Color, #2B2B2B)',
        fontFamily: 'Expletus Sans',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px', 
        letterSpacing: '0.15px',
        marginTop:"32px",
        borderBottom: "1px solid #E8ECF2",
        paddingBottom: "32px",
        "&:last-child": {
           borderBottom:"none" ,
           paddingBottom: "0",
         },
      },
    },
    "& .primary-btn": {
      minWidth: "156px",
      marginTop:"80px",
      [theme.breakpoints.down("xs")]: {
        padding: "7px",
        fontSize: "12px",
        minHeight: "36px",
        borderRadius: "6px",
        minWidth: "100px",
        marginTop:"60px"
      },
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "543px",
      margin: "0 100px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },   
  },
  formControl: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft:"0",
    marginRight:"-150px",
    "& .MuiRadio-colorSecondary.Mui-checked":{
      color:"#4BA3B7",
    },
    "& .MuiRadio-root":{
      color:"#E8ECF2"
    },   
    [theme.breakpoints.down("sm")]: {
      marginRight:"-11px",
    }, 
  }, 
  wrapper: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  primaryButton: {
    background: "#4ba3b7",
    border: "1px solid #4ba3b7",
    borderRadius: "6px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#f2f8fc",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    maxWidth: "100%",
    minWidth: "170px",
    padding: "12px 10px",
    minHeight: "48px",
    marginBottom: "0",
    // transition: "all 0.5s ease-in-out",
    textTransform: "capitalize",
    "&:hover": {
      background: "#4ba3b7",
      color: "#ffffff",
    },
  },
  secondaryButton: {
    background: "transparent",
    border: "1px solid #e8ecf2",
    borderRadius: "7px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#2b2b2b",
    fontWeight: 500,
    fontFamily: "Expletus Sans",
    textAlign: "center",
    textTransform: "unset",
    maxWidth: "100%",
    minWidth: "198px",
    padding: "12px 10px",
    minHeight: "48px",
    marginBottom: "0",
    transition: "all 0.5s ease-in-out",
    textDecoration: "none",
    "&:hover": {
      background: "transparent",
      color: "#2b2b2b",
    },
  },
});
// Customizable Area End
