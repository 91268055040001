// Customizable Area Start
import React from "react";
import {
  Chip,
  Box,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Select,
  MenuItem,
  Modal,
  Button,
  Typography,
  Menu,
  CardMedia,
  Popover,
  InputAdornment,
  InputLabel
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  deleteGray,
  callMerge,
  accountMultiplePlus,
  handshake,
  emailFast,
  fileEdit,
  bottle,
  pencil,
  plus,
  paperClip,
  editContactAvatarRound
} from "./assets";
import { Link } from "react-router-dom";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import EditContactWeb from "./EditContact.web";
import DeleteContact from "./DeleteContact.web";
import AddIcon from '@material-ui/icons/Add';
import CreateDealWeb from "./CreateDeal.web";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { configJSON } from "./ContactsListController.web";
import { ProductList } from "./ProductList.web";
import { getImageForSubCategory ,extractItemDetails, showLettersFromName } from "../../../components/src/ReusableFunctions";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function viewContactProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface ViewContactProps {
  classes: any;
  navigation: any
  contactDetails: any;
  isShowViewContactModal: boolean;
  handleCloseViewContactModal: any;
  handleOpenAddGroupToContact?: () => void;
  handleMergeContactOpen: () => void;
  deleteContactEvent: () => void;
  notesData: any;
  onEditContact: () => void;
  viewContactTabsValue: any;
  setViewContactTabsValue: (obj: any, val: any) => void;
  isDeleteContact: boolean;
  delModalOpen: boolean;
  closeDeleteModal: () => void;
  delContactDetails: any;
  deleteContactAction: () => void;
  formatCreatedDate: (date: Date) => React.ReactNode;
  handleShowAddNote: () => void;
  isShowAddNote: boolean;
  notes: any;
  handleInputChange: (e: any) => void;
  handleHideAddNote: () => void;
  createNotes: () => void;
  isShowEditNote: any;
  noteUpdateId: any;
  formatNoteDate: (date: Date) => React.ReactNode;
  onUpdateNote: (e: any) => void;
  updateNoteAction: () => void;
  handleOpenNoteMenu: (e: any, id: any, content: any) => void;
  noteMenuAnchor: any;
  handleCloseMenuAnchor: () => void;
  onShowEditNote: (id: any, txt: any) => void;
  onShowDeleteNoteConfirmation: () => void;
  onHideEditNote: () => void;
  selectedNote: any;
  isShowDeleteNoteConfirmation: any;
  onHideDeleteNoteConfirmation: () => void;
  deleteNoteAction: () => void;
  isShowEditContact: any;
  onCloseEditContact: () => void;
  viewSingleDetail: any;
  showMoreGroups: boolean;
  toggleShowGroups: () => void;
  createDealPopoverShown: any;
  handleOpenDeal: (e: any) => void;
  handleCloseDeal: () => void;
  anchorElDeal: any;
  handleSelectContactsOpen: () => void;
  chooseStageHandler: (dealId: string, dealStage: string, dealNote: string) => void;
  isActiveChooseStage: boolean;
  chooseStageModalClose: () => void;
  newStatus: any;
  handleStageChange: (e: any) => void;
  currentDealStage: any;
  updateDealStage: () => void;
  dealNoteHandler: (e: any) => void;
  addDealNote: any;
  t: any;
  handleRemoveProduct: (id: any) => void;
  handleOpenFavProduct: () => void;
  handleCloseFavPRoduct: () => void;
  favProductState: any;
  handleSortChange: (e: any) => void;
  sortValueFavPRoduct: string;
  handleSortChangeNotes: (e: any) => void;
  sortValueNotes: string;
  handleSortChangeDeals: (e: any) => void;
  sortValueDeals: string;
  addAsFavProduct:any;
}
const ViewContact = (props: ViewContactProps) => {
  const {
    classes,
    navigation,
    isShowViewContactModal,
    handleCloseViewContactModal,
    contactDetails,
    handleMergeContactOpen,
    deleteContactEvent,
    notesData,
    onEditContact,
    viewContactTabsValue,
    setViewContactTabsValue,
    isDeleteContact,
    delModalOpen,
    closeDeleteModal,
    delContactDetails,
    deleteContactAction,
    handleOpenAddGroupToContact,
    formatCreatedDate,
    handleShowAddNote,
    isShowAddNote,
    notes,
    handleInputChange,
    handleHideAddNote,
    createNotes,
    isShowEditNote,
    noteUpdateId,
    formatNoteDate,
    onUpdateNote,
    updateNoteAction,
    handleOpenNoteMenu,
    noteMenuAnchor,
    handleCloseMenuAnchor,
    onShowDeleteNoteConfirmation,
    onHideEditNote,
    onShowEditNote,
    selectedNote,
    isShowDeleteNoteConfirmation,
    onHideDeleteNoteConfirmation,
    deleteNoteAction,
    isShowEditContact,
    onCloseEditContact,
    viewSingleDetail,
    showMoreGroups,
    toggleShowGroups,
    createDealPopoverShown,
    handleOpenDeal,
    handleCloseDeal,
    anchorElDeal,
    handleSelectContactsOpen,
    chooseStageHandler,
    isActiveChooseStage,
    chooseStageModalClose,
    newStatus,
    handleStageChange,
    currentDealStage,
    updateDealStage,
    dealNoteHandler,
    addDealNote,
    t,
    handleRemoveProduct,
    handleOpenFavProduct,
    handleCloseFavPRoduct,
    favProductState,
    handleSortChange,
    sortValueFavPRoduct,
    sortValueNotes,
    handleSortChangeNotes,
    sortValueDeals,
    handleSortChangeDeals,
    addAsFavProduct
  } = props;

  const sortFavProductData = () => {
    return contactDetails.contact_products.slice().sort((a: any, b: any) => {
      if (sortValueFavPRoduct === "A-Z") {
        return  a.product.data.attributes.name.localeCompare(b.product.data.attributes.name);
      } else if (sortValueFavPRoduct === "Z-A") {
        return b.product.data.attributes.name.localeCompare( a.product.data.attributes.name);
      }
      return 0;
    });
  };
  const sortedProducts = sortFavProductData();

  const sortNotesData = () => {
    return notesData.slice().sort((a: any, b: any) => {
      if (sortValueNotes === "Newest to oldest") {
        return new Date(b.attributes.created_at).getTime() - new Date(a.attributes.created_at).getTime();
      }  else if (sortValueNotes === "Oldest to newest") {
        return new Date(a.attributes.created_at).getTime() - new Date(b.attributes.created_at).getTime();
      }
      return 0;
    });
  };
  const sortedNotes = sortNotesData();

  const sortDealsData = () => {
    return contactDetails.contact_interactions.data.slice().sort((a: any, b: any) => {
      if (sortValueDeals === "A-Z") {
        return a.attributes.name.localeCompare(b.attributes.name);
      } else if (sortValueDeals === "Z-A") {
        return b.attributes.name.localeCompare(a.attributes.name);
      }
      return 0;
    });
  };
  const sortedDeals = sortDealsData();

  const getMyGroups = () => {
    const groupLength = contactDetails.contact_groups.length;
    const userGroups = contactDetails.contact_groups;
    const filteredGroups = userGroups.filter((group: any) => group !== null);
    const defGroupLength: number = 2;
    const displayGroups = showMoreGroups
      ? filteredGroups
      : filteredGroups.slice(0, defGroupLength);

    return (
      <>
        {displayGroups.map((group: any) => {
          return (
            <Chip
              key={group?.name}
              className={classes.chipButton}
              label={group?.name}
            />
          );
        })}
        {filteredGroups.length > defGroupLength && (
          <>
            {showMoreGroups ? (
              <Chip
                className={`secondary-chip ${classes.chipButton}`}
                label="Show Less"
                onClick={toggleShowGroups}
                data-test-id="showLessBtn"
              />
            ) : (
              <Chip
                className={`more-btn ${classes.chipButton}`}
                label={`+${groupLength - displayGroups.length} more`}
                onClick={toggleShowGroups}
                data-test-id="showMoreBtn"
              />
            )}
          </>
        )}
      </>
    );
  };

  const formatDate = (inputDate: any) => {
    const options: any = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = new Date(inputDate).toLocaleDateString('en-US', options);

    return formattedDate;
  };
  const stageStatusClassMaping = (dealStageStatus: string) => {
    if (dealStageStatus === "Initiated") {
      return <Box className="status sky-blue"></Box>;
    } else if (dealStageStatus === "sent proposal") {
      return <Box className="status orange"></Box>;
    } else if (dealStageStatus === "follow up") {
      return <Box className="status yellow"></Box>;
    } else if (dealStageStatus === "won") {
      return <Box className="status green"></Box>;
    } else if (dealStageStatus === "lost") {
      return <Box className="status black"></Box>;
    }
  };
  const renderStatusItems = () => {
    const statusItemss = [
      { value: 0, name: "Initiated", className: "sky-blue" },
      { value: 1, name: "Sent Proposal", className: "orange" },
      { value: 2, name: "Follow Up", className: "yellow" },
      { value: 3, name: "Won", className: "green" },
      { value: 4, name: "Lost", className: "black" },
    ];

    return statusItemss.map((it) => (
      <MenuItem key={it.value} value={it.value} className="status-item">
        <Box className="status-name">{t(`${it.name}`)}</Box>
        <Box className={`status ${it.className}`}></Box>
      </MenuItem>
    ));
  };

  let noteMessage;
  const filteredNotes = notesData?.filter((note: any) => note.attributes.content !== "");
  const filteredNotesLength = filteredNotes ? filteredNotes.length : 0;
  const noteText = filteredNotesLength >= 2 ? t(`${configJSON.notesTxt}`) : t(`${configJSON.noteTxt}`);
  noteMessage = noteText?.replace('{{count}}', filteredNotesLength);



  let favProductMessage;
  const favProductMessageText = contactDetails.contact_products.length >= 2 ? t(`${configJSON.favProsuctsTxt}`) : t(`${configJSON.favProsuctTxt}`);
  favProductMessage = favProductMessageText?.replace('{{count}}', contactDetails.contact_products.length);

  let dealsMessage;
  const dealMessageText = contactDetails.contact_interactions.data.length >= 2 ? t(`${configJSON.dealsTxt}`) : t(`${configJSON.dealTxt}`);
  dealsMessage = dealMessageText?.replace('{{count}}', contactDetails.contact_interactions.data.length);

  const userData = sessionStorage.getItem("userData");
  const parseData = userData && JSON.parse(userData);
  const userRole= parseData.data.attributes.job_type;
    
  return (
    <>
      {/* view contact modal start */}

      <Modal
        open={isShowViewContactModal}
        onClose={handleCloseViewContactModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
      >
        <Box className={`view-modal-dialog ${classes.modalDialogBox}`}>
          <Box className="modal-heading">
            <Box className="heading-links">
            {userRole !== "employee" && 
              <>
              <Box className="heading-link">
                <Link to="#" className="icon" onClick={handleMergeContactOpen}>
                  <img src={callMerge} alt="callMerge" />
                </Link>
              </Box>            
              <Box className="heading-link">
                <Link to="#" className="icon" data-test-id="deleteContactEvent" onClick={deleteContactEvent}>
                  <img src={deleteGray} alt="deleteGray" />
                </Link>
              </Box>
              </>
             }
              <Box className="heading-link">
                <Link to="#" className="icon" data-test-id="handleOpenAddGroupToContact" onClick={handleOpenAddGroupToContact}>
                  <img src={accountMultiplePlus} alt="accountMultiplePlus" />
                </Link>
              </Box>
            </Box>
            <Link
              to="#"
              className="close-icon"
              onClick={handleCloseViewContactModal}
              data-testid="viewTaskClose"
            >
              <CloseIcon />
            </Link>
          </Box>
          <Box className="modal-description">
            <Box className="profile-block-wrapper">
              <Box className="profile-info">
                <Box className="profile-row">
                  <Box className="left-col">
                    <Box className="heading-avatar">
                      <Box className="wrap-avatar">
                        <CardMedia
                          component="img"
                          className="avatar-bg"
                          src={editContactAvatarRound}
                        />
                        <Typography className="shortcut-name">
                          {contactDetails.first_name.charAt(0).toUpperCase()}
                          {contactDetails.last_name.charAt(0).toUpperCase()}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="right-col">
                    <Box className="contact-statistics">
                      <Box className="statistics-col">
                        <Box className="content">{t(`${configJSON.deals}`)}</Box>
                        <Box className="value">{contactDetails.contact_deals_count}</Box>
                        <Box className="icon">
                          <img src={handshake} alt="handshake" />
                        </Box>
                      </Box>
                      <Box className="statistics-col">
                        <Box className="content">{t(`${configJSON.outreach}`)}</Box>
                        <Box className="value">1</Box>
                        <Box className="icon">
                          <img src={emailFast} alt="emailFast" />
                        </Box>
                      </Box>
                      <Box className="statistics-col">
                        <Box className="content">{t(`${configJSON.notes}`)}</Box>
                        <Box className="value">{notesData?.filter((note: any) => note.attributes.content !== "").length}</Box>
                        <Box className="icon">
                          <img src={fileEdit} alt="fileEdit" />
                        </Box>
                      </Box>
                      <Box className="statistics-col">
                        <Box className="content">{t(`${configJSON.favProducts}`)}</Box>
                        <Box className="value">{contactDetails.contact_products.length}</Box>
                        <Box className="icon">
                          <img src={bottle} alt="bottle" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="profile-name">
                  {`${contactDetails.first_name} ${contactDetails.last_name}`}
                  <CardMedia
                    component="img"
                    className="edit-icon"
                    src={pencil}
                    alt="pencil"
                    onClick={onEditContact}
                    data-test-id="oncontacteditclick"
                  />
                </Box>
                <Box className="job-info">
                  {contactDetails?.business_info?.data?.attributes?.company_name ?? ""}
                </Box>
                <Box className="profile-team">
                  <Box className="team-label">{t(`${configJSON.groupsTxt}`)}</Box>
                  <Box className="chip-wrapper" data-test-id="groupChips">
                    {getMyGroups}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="tasklist-tabwrapper">
              <Box>
                <Tabs
                  aria-label="simple tabs example"
                  value={viewContactTabsValue}
                  onChange={setViewContactTabsValue}
                  data-test-id="viewTabs"
                >
                  <Tab label={t(`${configJSON.aboutTxt}`)} {...viewContactProps(0)} />
                  <Tab label={t(`${configJSON.interactions}`)} {...viewContactProps(1)} />
                  <Tab label={t(`${configJSON.notes}`)} {...viewContactProps(2)} />
                  <Tab label={t(`${configJSON.favProducts}`)} {...viewContactProps(3)} />
                </Tabs>
              </Box>
              <TabPanel value={viewContactTabsValue} index={0}>
                <Box className="contact-info-wrapper">
                  <Box className="info-block">
                    <Box className="info-heading">{t(`${configJSON.personalInfoTxt}`)}</Box>
                    <Box className="about-info-row">
                      <Box className="about-col">
                        <Box className="label">{t(`${configJSON.phone}`)}</Box>
                        <Box className="value">{contactDetails.phone}</Box>
                      </Box>
                      <Box className="about-col">
                        <Box className="label">{t(`${configJSON.email}`)}</Box>
                        <Box className="value">{contactDetails.email}</Box>
                      </Box>
                      <Box className="about-col">
                        <Box className="label">{t(`${configJSON.createdDate}`)}</Box>
                        <Box className="value">
                          {formatCreatedDate(contactDetails.created_at)}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="info-block">
                    <Box className="info-heading">{t(`${configJSON.businessInformation}`)}</Box>
                    <Box className="about-info-row">
                      <Box className="about-col">
                        <Box className="label">{t(`${configJSON.companyName}`)}</Box>
                        <Box className="value">
                          {
                            contactDetails?.business_info?.data?.attributes?.company_name ?? ""
                          }
                        </Box>
                      </Box>
                      <Box className="about-col">
                        <Box className="label">{t(`${configJSON.vatNumberTxt}`)}</Box>
                        <Box className="value">
                          {
                            contactDetails?.business_info?.data?.attributes?.VAT_number ?? ""
                          }
                        </Box>
                      </Box>
                      <Box className="about-col">
                        <Box className="label">{t(`${configJSON.bCategoryinSmall}`)}</Box>
                        <Box className="value">
                          {
                            contactDetails?.business_info?.data?.attributes?.business_category_name ?? ""
                          }
                        </Box>
                      </Box>
                      <Box className="about-col">
                        <Box className="label">{t(`${configJSON.billingAddressTxt}`)}</Box>
                        <Box className="value">
                          {`${contactDetails?.billing_address?.data?.attributes?.address1 ?? ""} ${contactDetails?.billing_address?.data?.attributes?.address2 ?? ""} ${contactDetails?.billing_address?.data?.attributes?.city ?? ""} ${contactDetails?.billing_address?.data?.attributes?.pin_code ?? ""} ${contactDetails?.billing_address?.data?.attributes?.country ?? ""}`}
                        </Box>
                      </Box>
                      <Box className="about-col">
                        <Box className="label">{t(`${configJSON.shippingAddress}`)}</Box>
                        <Box className="value">
                          {`${contactDetails?.shipping_address?.data?.attributes?.address1 ?? ""} ${contactDetails?.shipping_address?.data?.attributes?.address2 ?? ""} ${contactDetails?.shipping_address?.data?.attributes?.city ?? ""} ${contactDetails?.shipping_address?.data?.attributes?.pin_code ?? ""} ${contactDetails?.shipping_address?.data?.attributes?.country ?? ""}`}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={viewContactTabsValue} index={1}>
                <Box className="contact-accodion">
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box className="accodion-heading">{t(`${configJSON.deals}`)}</Box>
                    </AccordionSummary>
                    <AccordionDetails className="accodion-content">
                      <Box className="content-heading">
                        <Box className="title">{dealsMessage}</Box>
                        <Box className="content-filter">
                          <FormControl className="select-control sortby-control border-hr">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  horizontal: "left",
                                  vertical: "bottom",
                                },
                                transformOrigin: {
                                  horizontal: "left",
                                  vertical: "top",
                                },
                                classes: { paper: classes.dropdownStyle },
                                getContentAnchorEl: null,
                              }}
                              onChange={(e: any) => handleSortChangeDeals(e)}
                              displayEmpty
                              defaultValue={t(`${configJSON.sortByTxt}`)}
                              data-test-id="handleSortChangeDeals"
                              renderValue={(value: any) => {
                                return <Box>{value}</Box>;
                              }}
                              IconComponent={() => (
                                <ExpandMoreIcon className="down-arrow" />
                              )}
                            >
                              <MenuItem value="A-Z">A-Z</MenuItem>
                              <MenuItem value="Z-A">Z-A</MenuItem>
                            </Select>
                          </FormControl>
                          <Box className="content-icon"
                            onClick={(event: any) => handleOpenDeal(event)}
                            data-test-id="handleOpenDeal"
                          >
                            <img src={plus} alt="plus" />
                            {createDealPopoverShown && (
                              <Popover
                                open={Boolean(anchorElDeal)}
                                anchorEl={anchorElDeal}
                                onClose={handleCloseDeal}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                classes={{ paper: `${classes.createDealsBox}` }}
                              >
                                <CreateDealWeb
                                  handleCloseDialog={handleCloseDeal}
                                  classes={classes}
                                  handleSelectContactsOpen={handleSelectContactsOpen}
                                />
                              </Popover>
                            )}
                          </Box>
                        </Box>
                      </Box>
                      {sortedDeals?.map((deal: any) => {
                        const first_name = deal.attributes.contact[0].first_name;
                        const last_name = deal.attributes.contact[0].last_name;
                        const fullName = `${first_name} ${last_name}`;
                        return <Box className="content-details" key={deal.id}>
                          <Box className="content-inner">
                            <Box className="assignee-profile">
                            {showLettersFromName(fullName)}
                            </Box>
                            <Box className="assignee-info">
                              <Box className="top-info">
                                <Box className="info-left">
                                  <Box className="name">{fullName}</Box>
                                  <Box className="form-col select-modal-field">
                                    <CustomInputWeb
                                      label=""
                                      disabled={true}
                                      startAdornment={
                                        <InputAdornment position="start">
                                          <Box className="status-item">
                                            <Box className="status-name">
                                              {t(`${deal.attributes.stage}`)}
                                            </Box>
                                            {stageStatusClassMaping(
                                              deal.attributes.stage
                                            )}
                                          </Box>
                                        </InputAdornment>
                                      }
                                      endAdornment={
                                        <InputAdornment
                                          position="end"
                                          onClick={() =>
                                            chooseStageHandler(
                                              deal.id,
                                              deal.attributes.stage,
                                              deal.attributes.note
                                            )
                                          }
                                          data-test-id={`chooseStageModal-${deal.id}`}
                                        >
                                          <ExpandMoreIcon />
                                        </InputAdornment>
                                      }
                                      data-test-id={`stageInput-${deal.id}`}
                                      isRequired={true}
                                    />
                                  </Box>
                                </Box>
                                <Box className="date">
                                  <img
                                    alt="paperClip"
                                    src={paperClip}
                                    className="attach-icon"
                                  />
                                  {formatDate(deal.attributes.close_date)}
                                </Box>
                              </Box>
                              <Box className="primary-txt">
                                {(deal.attributes.name)}
                              </Box>
                              <Box className="assignee-desc">
                                {deal.attributes.description}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      })}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Box className="accodion-heading">{t(`${configJSON.outreach}`)}</Box>
                    </AccordionSummary>
                    <AccordionDetails className="accodion-content">
                      <Box className="content-heading">
                        <Box className="title">1 {t(`${configJSON.outreaches}`)}</Box>
                        <Box className="content-filter">
                          <FormControl className="select-control sortby-control border-hr">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  horizontal: "left",
                                  vertical: "bottom",
                                },
                                transformOrigin: {
                                  horizontal: "left",
                                  vertical: "top",
                                },
                                getContentAnchorEl: null,
                                classes: { paper: classes.dropdownStyle },
                              }}
                              displayEmpty
                              // onChange={(event, type) =>
                              //   onSelectedOptions(event, "selectedSortByVal")
                              // }
                              defaultValue={t(`${configJSON.sortByTxt}`)}
                              data-testid="sortTasks"
                              renderValue={(value: any) => {
                                return <Box>{value}</Box>;
                              }}
                              //value={selectedSortBy}
                              IconComponent={() => (
                                <ExpandMoreIcon className="down-arrow" />
                              )}
                            >
                              <MenuItem value="A-Z">A-Z</MenuItem>
                              <MenuItem value="Z-A">Z-A</MenuItem>
                            </Select>
                          </FormControl>
                          <Box className="content-icon">
                            <img src={plus} alt="plus" />
                          </Box>
                        </Box>
                      </Box>
                      <Box className="content-details">
                        <Box className="content-inner">
                          <Box className="assignee-profile">AC</Box>
                          <Box className="assignee-info">
                            <Box className="top-info">
                              <Box className="info-left">
                                <Box className="name">Andrew Ceno</Box>
                              </Box>
                              <Box className="date">
                                <img
                                  src={paperClip}
                                  className="attach-icon"
                                  alt="paperClip"
                                />
                                10:32 AM
                              </Box>
                            </Box>
                            <Box className="primary-txt">
                              Report - Chatto project
                            </Box>
                            <Box className="assignee-desc">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has...
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </TabPanel>
              <TabPanel value={viewContactTabsValue} index={2}>
                <Box className="contact-accodion">
                  <Accordion defaultExpanded={true} data-test-id="accordian">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      id="panel1a-header"
                      aria-controls="panel1a-content"
                    >
                      <Box className="accodion-heading">{t(`${configJSON.notes}`)}</Box>
                    </AccordionSummary>
                    <AccordionDetails className="accodion-content" data-test-id="accordionDetails">
                      <Box className="content-heading">
                        <Box className="title">{noteMessage}</Box>
                        <Box className="content-filter">
                          <FormControl className="select-control sortby-control border-hr">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                classes: { paper: classes.dropdownStyle },
                              }}
                              onChange={(e: any) => handleSortChangeNotes(e)}
                              defaultValue={t(`${configJSON.sortByTxt}`)}
                              displayEmpty
                              data-test-id="handleSortChangeNotes"
                              renderValue={(value: any) => {
                                return <Box>{value}</Box>;
                              }}
                              IconComponent={() => (
                                <ExpandMoreIcon className="down-arrow" />
                              )}
                            >
                              <MenuItem value={t(`${configJSON.newestToOldest}`)}>{t(`${configJSON.newestToOldest}`)}</MenuItem>
                              <MenuItem value={t(`${configJSON.oldestToNewest}`)}>{t(`${configJSON.oldestToNewest}`)}</MenuItem>
                            </Select>
                          </FormControl>
                          <Box
                            className="content-icon"
                            onClick={handleShowAddNote}
                            data-test-id="add-note-plus-icon"
                          >
                            <AddIcon fontSize="small" style={{ color: "#727171" }} />
                          </Box>
                        </Box>
                      </Box>
                      {isShowAddNote && (
                        <>
                          <CustomInputWeb
                            type="text"
                            label=""
                            value={notes}
                            name="notes"
                            placeholder={t(`${configJSON.typeHere}`)}
                            onChange={handleInputChange}
                            errors={""}
                            rows={4}
                            isMultiline
                            data-test-id="notes"
                          />
                          <Box className="wrap-add-note-btn">
                            <Button
                              className="cancel-add-note-btn"
                              onClick={handleHideAddNote}
                              data-test-id="handleHideAddNote"
                            >
                              {t(`${configJSON.cancel}`)}
                            </Button>
                            <Button
                              data-test-id="add-note-btn-create"
                              onClick={createNotes}
                              className={notes.trim() === "" ? "add-note-btn-disable" : "add-note-btn"}
                              disabled={notes.trim() === ""}
                            >{t(`${configJSON.addNote}`)}</Button>
                          </Box>
                        </>
                      )}

                      <Box className="content-details">
                      {sortedNotes?.map((note: any) => {
                        const {
                          first_name,
                          last_name,
                        } = note.attributes.contact.data.attributes;
                        
                        const profileInitials = `${first_name.charAt(0).toUpperCase()}${last_name.charAt(0).toUpperCase()}`;
                        const noteContent = note.attributes.content;
                        const noteId = note.id;

                        const contentClassName = noteUpdateId
                          ? noteUpdateId === noteId
                            ? "no-opacity"
                            : "grey-color"
                          : "no-opacity";

                        return (
                          <>
                            {noteContent !== "" && (
                              <Box key={noteId}>
                                {isShowEditNote && noteUpdateId === noteId ? (
                                  // Edit Mode
                                  <Box className="content-inner">
                                    <Box className="assignee-profile">
                                      {profileInitials}
                                    </Box>
                                    <Box className="assignee-info">
                                      <Box className="top-info">
                                        <Box className="info-left">
                                          <Box className="name">
                                            {first_name} {last_name}
                                          </Box>
                                        </Box>
                                        <Box className="date">
                                          {formatNoteDate(note.attributes.created_at)}
                                        </Box>
                                      </Box>
                                      <Box className="edit-note-wrapper">
                                        <CustomInputWeb
                                          type="text"
                                          label=""
                                          value={selectedNote}
                                          name=""
                                          onChange={(event: any) =>
                                            onUpdateNote(event.target.value)
                                          }
                                          errors={""}
                                          isMultiline
                                          data-test-id="edit-note-textfield"
                                        />
                                        <Box className="wrap-button">
                                          <Button
                                            className="btn-cancel"
                                            onClick={onHideEditNote}
                                          >
                                            {t(`${configJSON.cancel}`)}
                                          </Button>
                                          <Button className="btn-save" onClick={updateNoteAction}>
                                            {t(`${configJSON.save}`)}
                                          </Button>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                ) : (
                                  // View Mode
                                  <Box className={`content-inner ${contentClassName}`} key={noteId}>
                                    <Box className="assignee-profile">
                                      {profileInitials}
                                    </Box>
                                    <Box className="assignee-info">
                                      <Box className="top-info">
                                        <Box className="info-left">
                                          <Box className="name">
                                            {first_name} {last_name}
                                          </Box>
                                        </Box>
                                        <Box className="date">
                                          {formatNoteDate(note.attributes.created_at)}
                                          <MoreVertIcon
                                            onClick={(event: any) =>
                                              handleOpenNoteMenu(event, noteId, noteContent)
                                            }
                                            data-test-id="handleMenuOpen"
                                          />
                                          <Menu
                                            id="note-update-menu"
                                            anchorEl={noteMenuAnchor}
                                            onClose={handleCloseMenuAnchor}
                                            open={Boolean(noteMenuAnchor)}
                                            transformOrigin={{
                                              horizontal: "right",
                                              vertical: "top",
                                            }}
                                            anchorOrigin={{
                                              horizontal: "left",
                                              vertical: "bottom",
                                            }}
                                            className={`${classes.dropDropdownMenuNote}`}
                                          >
                                            <MenuItem
                                              onClick={() => onShowEditNote(noteUpdateId, selectedNote)}
                                              data-test-id="onEditOpen"
                                            >
                                              {t(`${configJSON.edit}`)}
                                            </MenuItem>
                                            <MenuItem
                                              onClick={onShowDeleteNoteConfirmation}
                                              data-test-id="onDeleteOpen"
                                            >
                                              {t(`${configJSON.delete}`)}
                                            </MenuItem>
                                          </Menu>
                                        </Box>
                                      </Box>
                                      <Box className={noteUpdateId === noteId ? "assignee-desc-dark" : "assignee-desc"}>
                                        {noteContent}
                                      </Box>
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                            )}
                          </>
                        );
                      })}

                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </TabPanel>
              <TabPanel value={viewContactTabsValue} index={3}>
                <Box className="contact-accodion">
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Box className="accodion-heading">
                        {t(`${configJSON.favoriteProducts}`)}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails className="accodion-content">
                      <Box className="content-heading">
                        <Box className="title">{favProductMessage}</Box>
                        <Box className="content-filter">
                          <FormControl className="select-control sortby-control border-hr">
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  horizontal: "left",
                                  vertical: "top",
                                },
                                classes: { paper: classes.dropdownStyle },
                                getContentAnchorEl: null,
                              }}
                              defaultValue={t(`${configJSON.sortByTxt}`)}
                              displayEmpty
                              onChange={(e: any) => handleSortChange(e)}
                              data-test-id="handleSortChange"
                              renderValue={(value: any) => {
                                return <Box>{value}</Box>;
                              }}
                              IconComponent={() => (
                                <ExpandMoreIcon className="down-arrow" />
                              )}
                            >
                              <MenuItem value="A-Z">A-Z</MenuItem>
                              <MenuItem value="Z-A">Z-A</MenuItem>
                            </Select>
                          </FormControl>
                          <Box className="content-icon" onClick={handleOpenFavProduct}>
                            <img src={plus} alt="plus" />
                          </Box>
                        </Box>
                      </Box>
                      <Box className="content-details">
                        <Box className="product-list-row">
                          {sortedProducts?.map((item: any) => {  
                            const {
                              targetName,
                              brandName,
                              materialName,
                              colorName,
                              style,
                              appellation,
                              grapeVariety,
                              vintage,
                              itemName, 
                              price
                            } = extractItemDetails(item.product.data);                         
                            return <Box className="product-col col4" key={item.product.data.id}>
                              <Box className="product-inner">
                                <Link to="#" className="close-icon" data-test-id="handleRemoveProduct" onClick={() => handleRemoveProduct(item.product.data.id)}>
                                  <CloseIcon />
                                </Link>
                                <Box className="product-img">
                                {getImageForSubCategory(item.product.data.attributes.sub_category.id, item.product.data.attributes)}
                                </Box>
                                <Box className="product-details">
                                  <Box className="product-info">
                                    <Box className="year">2024</Box>
                                    <Box className="rating">
                                    {vintage} ml
                                    </Box>
                                  </Box>
                                  <Box className="product-name">
                                  {itemName}
                                  </Box>
                                  <Box className="product-desc">
                                    {brandName}
                                    {style}
                                    {targetName !== "" && brandName !== ""
                                      ? `, ${targetName}`
                                      : targetName}
                                    {materialName !== "" && brandName !== ""
                                      ? `, ${materialName}`
                                      : materialName}
                                    {colorName !== "" && (brandName !== "" || style !== "")
                                      ? `, ${colorName}`
                                      : colorName}
                                    {appellation}
                                    {grapeVariety !== "" && appellation !== ""
                                      ? `, ${grapeVariety}`
                                      : grapeVariety}
                                  </Box>
                                  <Typography className="price-txt">
                                  ${((price))?.toFixed(2)}
                                </Typography>
                                </Box>
                              </Box>
                            </Box>
                          })}
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* view contact modal end */}

      {/* delete contact modal start */}
      {isDeleteContact && (
        <DeleteContact
          modalOpen={delModalOpen}
          modalClose={closeDeleteModal}
          delContactDetails={delContactDetails}
          deleteContactAction={deleteContactAction}
          headding={t(`${configJSON.deleteContactTxt}`)}
          cancleBtntxt={t(`${configJSON.cancel}`)}
          deleteBtntxt={t(`${configJSON.delete}`)}
          confirmationMsg={t(`${configJSON.deleteTxtMsgContact}`)}
          data-test-id="deleteContact"
        />
      )}
      {/* delete contact modal start */}

      {/* edit contact modal*/}
      {isShowEditContact && (
        <EditContactWeb
          navigation={navigation}
          isShowEditContact={isShowEditContact}
          handleOpenEditContact={onEditContact}
          hancleCloseEditContact={onCloseEditContact}
          viewSingleDetails={viewSingleDetail}
          data-test-id="editContactWeb"
        />
      )}
      {/* edit contact modal*/}
      {isShowDeleteNoteConfirmation && (
        <Modal
          open={isShowDeleteNoteConfirmation}
          onClose={onHideDeleteNoteConfirmation}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalDialog}
        >
          <Box className={classes.deleteNoteModal}>
            <Box className="heading">
              <Typography className="delete-note-txt">{t(`${configJSON.deleteNote}`)}</Typography>
              <CloseIcon
                className="close-icon"
                onClick={onHideDeleteNoteConfirmation}
              />
            </Box>
            <Typography className="confirm-txt">
              {t(`${configJSON.deleteNoteConfirmationTxt}`)}
            </Typography>
            <Box className="wrap-button">
              <Button
                className="cancel-btn"
                onClick={onHideDeleteNoteConfirmation}
              >
                {t(`${configJSON.cancel}`)}
              </Button>
              <Button
                className="delete-btn"
                onClick={deleteNoteAction}
                data-test-id="deleteAction"
              >
                {t(`${configJSON.delete}`)}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      {isActiveChooseStage && (
        <Modal
          open={isActiveChooseStage}
          onClose={chooseStageModalClose}
          aria-describedby="modal-modal-description"
          aria-labelledby="modal-modal-title"
          BackdropProps={{ className: "backdrop-root" }}
          className={`${classes.modalDialog}`}
          data-test-id="activeStageModal"
        >
          <Box
            className={`${classes.modalCenterDialogBox} modalCenterDialogBox`}
          >
            <Box className="modal-heading">
              <Typography className="modal-title" component="h2">
                {t(`${configJSON.changeStatus}`)}
              </Typography>
              <Link
                to="#"
                data-test-id="stage-modal-close"
                className="close-icon"
                onClick={chooseStageModalClose}
              >
                <CloseOutlinedIcon />
              </Link>
            </Box>
            <Box className="modal-description-stage">
              <Box className="modal-form-stage" component="form">
                <Box className="form-info-wrapper">
                  <Box className="form-row">
                    <Box className="form-col">
                      <FormControl
                        variant="outlined"
                        className="select-outer"
                      >
                        <InputLabel id="stage">{t(`${configJSON.stagetxt}`)}</InputLabel>
                        <Select
                          labelId="stage"
                          value={newStatus ?? 0}
                          defaultValue={
                            currentDealStage?.currentdealStage
                          }
                          name="newStage"
                          onChange={handleStageChange}
                          label="Current Stage"
                          MenuProps={{
                            anchorOrigin: {
                              horizontal: "left",
                              vertical: "bottom",
                            },
                            transformOrigin: {
                              horizontal: "left",
                              vertical: "top",
                            },
                            classes: {
                              paper: `${classes.dropdownStyleDeals} modal-dropdown`,
                            },
                            getContentAnchorEl: null,
                          }}
                          IconComponent={ExpandMoreIcon}
                          data-test-id="modalChangeStatus"
                        >
                          {renderStatusItems()}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box className="form-row">
                    <Box className="form-col">
                      <CustomInputWeb
                        type="text"
                        value={addDealNote}
                        label={t(`${configJSON.addANoteTxt}`)}
                        name="addNote"
                        onChange={(event) =>
                          dealNoteHandler(event.target.value)
                        }
                        data-test-id="dealNote-input"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="modal-footer">
                <Button
                  className={`secondary-btn ${classes.secondaryButton}`}
                  title="Cancel"
                  onClick={chooseStageModalClose}
                >
                  {t(`${configJSON.cancel}`)}
                </Button>
                <Button
                  title="Update"
                  className={`primary-btn ${classes.primaryButton}`}
                  data-test-id="updateDealStage"
                  onClick={updateDealStage}
                >
                  {t(`${configJSON.update}`)}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}

      {favProductState && (
        //@ts-ignore
        <ProductList
          openProductsModal={favProductState}
          closeProductsModal={handleCloseFavPRoduct}
          classes={classes}
          buttonTxt={t("Add as Favorite")}
          data-test-id="is-fav-product"
          addAsFavProduct={addAsFavProduct}
        />
      )

      }
    </>
  );
};

export default ViewContact;