import moment, { Moment } from "moment"

type NormalizeHoursType = {
    hours: string,
    minutes: string
}
// to convert moment date string to Year-Month-Date format by default
export const normalizeDate = (date: string | Moment, formatString: string = 'YYYY-MM-DD') => moment(date).format(formatString)

// to convert hours string to { hours, minutes } object
/*
    This function converts "12:34" format to { hours: 12, minutes: 34 } format

    cases:
    1. "00:00"  =>  { hours: '00', minutes: '00'}
    2. ":"      =>  { hours: '00', minutes: '00'}
    3. "1:2"    =>  { hours: '01', minutes: '02'}
    4. "1:20"   =>  { hours: '01', minutes: '20'}
    5. "12:34"  =>  { hours: '12', minutes: '34'}
    6. "00:00"  =>  { hours: '00', minutes: '00'}
    7. ":1"     =>  { hours: '00', minutes: '01'}
    8. "1:"     =>  { hours: '01', minutes: '00'}
*/
export const normalizeHours = (hours: string): NormalizeHoursType => {
    let hrMin = hours.split(':')
    return {
        hours: 
            (hrMin[0] && hrMin[0].length)
            ?   parseInt(hrMin[0]) < 10
                ? `0${parseInt(hrMin[0])}`
                : parseInt(hrMin[0]).toString()
            : '00',
        minutes:
            (hrMin[1] && hrMin[1].length)
            ?   parseInt(hrMin[1]) < 10
                ? `0${parseInt(hrMin[1])}`
                : parseInt(hrMin[1]).toString()
            : '00',
    }
}

// to convert hours object to xhrs ymin string
/*
    This function takes { hours, minutes } object and convert to readable format

    cases:
    1. { hours: '0', minutes: '0' }    =>  '0min'
    2. { hours: '0', minutes: '01' }   =>  '01min'
    3. { hours: '01', minutes: '0' }   =>  '01hrs'
    4. { hours: '01', minutes: '02' }  =>  '01hrs 02min'
    5. { hours: '12', minutes: '34' }  =>  '12hrs 34min'
*/
export const getHoursMinutesFormatted = (totalHours: NormalizeHoursType) => {
    if (!parseInt(totalHours.hours))
        return `${totalHours.minutes}min`
    if (!parseInt(totalHours.minutes))
        return `${totalHours.hours}hrs`
    return `${totalHours.hours}hrs ${totalHours.minutes}min`
}

export const convertHoursObjectToTotalHours = (totalHours: NormalizeHoursType): number => {
    let hr = parseInt(totalHours.hours)
    let min = parseInt(totalHours.minutes)
    return parseFloat(((hr*60 + min)/60).toFixed(2))
}

export const capitalizeText = (text: string): string => {
    // Text Sanitization
    if (typeof text === 'string')
        return `${JSON.stringify(text.charAt(0).toUpperCase()+text.slice(1))}`.trim().slice(1, -1)
    return `${JSON.stringify(text)}`
}
