//Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { PriceData } from "./InventoryTypes";
import i18n from "../../../web/src/utilities/i18n";


export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  classes?: any;
  openPriceModal: boolean;
  onclose: ()=>void;
  onAddingPrice: (label: any, item_price: number, is_taxExcluded: boolean)=>void;
  editPriceData?:PriceData
}

export interface S {
  // Customizable Area Start
  itemPrice: string;
  pricelabel: any;
  isTaxExcluded: boolean;
  error:{
    price: string;
  },
  pricelabelList:any[];
  productPrice:any;
  priceId:number
  // Customizable Area End
}
export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddPriceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  priceValidation: RegExp;
  userSessionData: any;
  userToken: any;
  getPriceLabelApiID: string = "";
  addPriceLabelApiID:string="";
  priceError:any;
  priceDialog:any;
  currentPrice : {id:number,title:string};
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
        itemPrice: '',
        pricelabel: null,
        isTaxExcluded: false,
        error:{
          price: '',
        },
        pricelabelList:[],
        productPrice:null,
        priceId:0
    };
    this.priceValidation = new RegExp(/^([1-9]\d*)(\.\d{0,2})?$/);
    this.currentPrice = {id:0,title:""};
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData); 
  }

  async componentDidMount() {
    this.getPriceLabels();
    const lang = localStorage.getItem("lang") || "en"; 
    await (i18n as any).changeLanguage(lang);
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.checkPrevStatePrice(prevState)
    if(this.props.openPriceModal != prevProps.openPriceModal && this.props.openPriceModal){
      
      if(this.props.editPriceData!=undefined){
        this.setState({
          itemPrice:String(this.props.editPriceData.itemPrice),
          pricelabel:{id:this.props.editPriceData.labelId,title:this.props.editPriceData.priceLabel},
          isTaxExcluded:!this.props.editPriceData.isTaxIncluded,
          error:{
            price: '',
          },
          priceId:this.props.editPriceData.id ?? 0
        })
      }
      else{
        this.clearValues();
      }
    }    
  }

  async receive(from: string, message: Message) {
   // Customizable Area Start
 
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (!responseJson.errors) {
         if(apiRequestCallId === this.getPriceLabelApiID){
          this.setState({pricelabelList:responseJson});
          }
          if(apiRequestCallId === this.addPriceLabelApiID){
            this.getPriceLabels()
          }
      }
    }
  }
   // Customizable Area End
  }

  handlePriceLabel=(e: any, newValue: any) => {
    this.setState({     
        pricelabel: newValue,
    });
  };
  handlePrice=(event:React.ChangeEvent<HTMLInputElement>)=>{
    const value = event.target.value;
    if(this.priceValidation.test(value) || value === ''){
      this.setState({itemPrice: value,error: {price: ""} })
    }
  }

  handleTax=(event:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({isTaxExcluded: event.target.checked })
  }

  addPriceLabel = (data: any, setError?: any, setShowDialog?: any) => {
    setShowDialog(false)
    this.addPriceLabels(data.title)
    this.currentPrice= {id:0, title:data.title}
  }

  addPrice =()=>{
    if(Number(this.state.itemPrice)<=0){
      this.setState({error: {price: configJSON.priceError} })
      return
    }
    this.props.onAddingPrice(this.state.pricelabel,Number(this.state.itemPrice),this.state.isTaxExcluded)
    this.clearValues();
  }
  clearValues = () => {
    this.setState({
      itemPrice:"",
      pricelabel:null,
      isTaxExcluded:false,
      priceId:0,
      error:{
        price: '',
      }
    })
  }
  getPriceLabels = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPriceLabelApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPriceLabelAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addPriceLabels = (priceLabel:string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    const body = {
      title: priceLabel,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addPriceLabelApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPriceLabelAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  checkPrevStatePrice = (prevState: S) => {
    
    if (prevState.pricelabelList !== this.state.pricelabelList) {
      if (this.currentPrice.title !== "") {
        const currentPrice = this.state.pricelabelList.find(
          (x) => x.title == this.currentPrice.title
        );
        this.setState({
          pricelabel: {
            id: currentPrice.id,
            title: currentPrice.title,
          },
        });
      }
      this.currentPrice = { id: 0,title:"" };
    
  }
}
  
}
//Customizable Area End
