// Customizable Area Start
import React, { useState } from "react";
import { Box, Typography, Button, InputAdornment } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { configJSON } from "../../../customisableuserprofiles/src/CustomisableUserProfilesController.web";

interface EditPasswordProps {
  classes: any;
  modalOpen: boolean;
  modalClose: () => void;
  backToEdit: () => void;
  saveNewPassword: (value: string) => void;
  newPasswordVal: string;
  passwordValError: string;
  updateMemberEmail: () => void;
  pageType?: string;
  oldPasswordVal?: string;
  oldPasswordValError?: string;
  saveOldPassword?: (value: string) => void;
  t?:any;
}

const EditPassword = (props: EditPasswordProps) => {
  const {
    classes,
    modalOpen,
    modalClose,
    backToEdit,
    saveNewPassword,
    newPasswordVal,
    passwordValError,
    updateMemberEmail,
    pageType,
    oldPasswordVal,
    oldPasswordValError,
    saveOldPassword,
    t
  } = props;
  const [isPasswordVisible, setisPasswordVisible] = useState(false)
  const [isPasswordValid, setisPasswordValid] = useState(false)
  const [isOldPasswordVisible, setisOldPasswordVisible] = useState(false)
  const togglePasswordVisibility = () => {
    setisPasswordVisible(!isPasswordVisible);
  }
  const toggleOldPasswordVisibility = () => {
    setisOldPasswordVisible(!isOldPasswordVisible);
  }
  const isValidPassword = (isValid: boolean) => {
    setisPasswordValid(isValid)
  };

  const enterOldPassword = (value: string) => {
    if (saveOldPassword !== undefined) {
      saveOldPassword(value)
    }
  };
  const message = pageType === "account_page"
  ? t(configJSON.sendConfirmation)
  : t(configJSON.changePassword);
  return (
    <Modal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalDialog}
    >
      <Box className={classes.modalDialogBox}>
        <Box className="modal-heading">
          <Typography className="modal-title" component="h2">
            {t(`${configJSON.changePassword}`)}
          </Typography>
          <Link to="#" className="close-icon" onClick={modalClose} data-test-id="closePassModal">

            <CloseIcon />
          </Link>
        </Box>
        <Box className="modal-description">
          <Box component="p" className="sub-txt">
          {t(`${configJSON.createPassword}`)}
          </Box>
          <Box className="modal-form" component="form">
            {pageType === "profile_page" && (
              <CustomInputWeb
                errors={oldPasswordValError}
                label={t(`${configJSON.oldPassword}`)}
                inputID="txtInputOldPassword"
                value={oldPasswordVal}
                onChange={(event) => enterOldPassword(event.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    {isOldPasswordVisible ? (
                      <VisibilityOffIcon
                        className="visibility-icon"
                        onClick={toggleOldPasswordVisibility}
                      />
                    ) : (
                      <VisibilityIcon
                        className="visibility-icon"
                        onClick={toggleOldPasswordVisibility}
                        data-testid="emailToggleOldPwd"
                      />
                    )}
                  </InputAdornment>
                }
                type={isOldPasswordVisible ? "text" : "password"}
                data-test-id="oldPass"
              />
            )}
            <CustomInputWeb
              errors={passwordValError}
              label={t(`${configJSON.newPassWord}`)}
              inputID="txtInputPassword"
              endAdornment={
                <InputAdornment position="end">
                  {isPasswordVisible ? (
                    <VisibilityOffIcon
                      className="visibility-icon"
                      onClick={togglePasswordVisibility}
                    />
                  ) : (
                    <VisibilityIcon
                      className="visibility-icon"
                      onClick={togglePasswordVisibility}
                      data-testid="emailTogglePwd"
                    />
                  )}
                </InputAdornment>
              }
              type={isPasswordVisible ? "text" : "password"}
              value={newPasswordVal}
              onChange={(event) => saveNewPassword(event.target.value)}
              showRequirements={true}
              onPassChange={isValidPassword}
              data-test-id="newPass"
            />

            <Box className="modal-footer">
              <Box className={pageType === "account_page" ? "half-btn" : ""}>
                {pageType === "account_page" && (
                  <Button
                    className={`secondary-btn ${classes.secondaryButton}`}
                    title="Back to Edit"
                    onClick={backToEdit}
                  >
                    {t(`${configJSON.backToEdit}`)}
                  </Button>
                )}
                <Button
                  className={`primary-btn ${classes.primaryButton}`}
                  title="Send Confirmation"
                  onClick={updateMemberEmail}
                  data-testid="sendPwdCnfirm"
                >
                  {message}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditPassword;
// Customizable Area End
