// Customizable Area Start
import React from "react";
import { Box, Typography, Button, Link } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";
import ForgotPasswordController from "./ForgotPasswordController.web";
import Footer from "../../../components/src/Footer.web";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import AuthLeft from "../../../components/src/AuthLeft.web";
import CustomHavingTrobleModal from "../../../components/src/CustomHavingTrobleModal.web";
export const configJSON = require("./config");
export const customStyles = (theme: AugmentedTheme) =>
  createStyles({
    overrides: {
      MuiButton: {
        primaryButton: {},
        socialButton: {},
      },
    },
    primaryButton: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "6px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "16px",
      lineHeight: "21px",
      color: "#f2f8fc",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      width: "100%",
      padding: "12px 10px",
      minHeight: "56px",
      marginBottom: "30px",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
      "&.Mui-disabled": {
        background: "#92C7D3",
        color: "#F2F8FC",
        borderColor: "#92C7D3",
      },
    },
    secondaryButton: {
      background: "transparent",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "19px",
      lineHeight: "23px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      width: "100%",
      padding: "12px 10px",
      minHeight: "56px",
      marginBottom: "30px",
      transition: "all 0.5s ease-in-out",
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
    socialButton: {
      fontSize: "14px",
      lineHeight: "18px",
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      width: "100%",
      color: "#2b2b2b",
      textTransform: "unset",
      background: "transparent",
      padding: "12px 10px",
      marginBottom: "42px",
      fontWeight: 500,
      minHeight: "56px",
      fontFamily: "Expletus Sans",
      transition: "all 0.5s ease-in-out",
      [theme.breakpoints.down("md")]: {
        marginBottom: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        minHeight: "46px",
        padding: "8px 6px",
      },
      "&:hover": {
        background: "transparent",
      },
      "& .MuiButton-startIcon": {
        width: "26px",
        height: "26px",
        marginRight: "29px",
        [theme.breakpoints.down("md")]: {
          width: "20px",
          height: "20px",
          marginRight: "15px",
        },
      },
    },
    formLink: {
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0",
      color: "#4ba3b7",
      fontWeight: 500,
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      fontFamily: "Roboto",
    },
    fieldRequirement: {
      marginTop: "7px",
      marginBottom: "40px",
      [theme.breakpoints.down("md")]: {
        marginBottom: "28px",
      },
      "& .requirement-title": {
        fontSize: "12px",
        lineHeight: "16px",
        marginBottom: "14px",
        letterSpacing: "0",
        color: "#505050",
        fontWeight: 400,
        fontFamily: "Expletus Sans",
      },
      "& .requirement-list": {
        padding: "0",
        display: "flex",
        flexWrap: "wrap",
        margin: "0 -10px",
        "& .list-icon": {
          lineHeight: "0",
          marginRight: "5px",
          "& .MuiSvgIcon-root": {
            width: "10px",
            height: "10px",
            color: "#94a3b8",
            "&.check-icon": {
              color: "#00ba88",
            },
            "&.clear-icon": {
              color: "#bf3e50",
            },
          },
        },
        "& .list-item": {
          width: "50%",
          padding: "0 10px",
          fontSize: "12px",
          lineHeight: "16px",
          marginBottom: "6px",
          letterSpacing: "0",
          color: "#000000",
          fontWeight: 400,
          fontFamily: "Expletus Sans",
          [theme.breakpoints.down("xs")]: {
            width: "100%",
          },
        },
      },
    },
    orTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      fontSize: "16px",
      lineHeight: "20px",
      marginBottom: "60px",
      color: "#94a3b8",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      [theme.breakpoints.down("md")]: {
        marginBottom: "40px",
      },
      "&:before": {
        content: '""',
        position: "absolute",
        width: "100%",
        top: "50%",
        borderBottom: "1px solid #e8ecf2",
      },
      "& span": {
        position: "relative",
        background: "#ffffff",
        padding: "0 10px",
      },
    },
    authWrapper: {
      textAlign: "left",
      "& img": {
        maxWidth: "100%",
      },
      "& .auth-inner-block": {
        display: "flex",
        flexWrap: "wrap",
        "& .auth-right": {
          flex: "1",
          padding: "60px 60px 142px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          [theme.breakpoints.down("md")]: {
            padding: "60px 60px 83px",
          },
          [theme.breakpoints.down("sm")]: {
            padding: "60px 60px 139px",
          },
          [theme.breakpoints.down("xs")]: {
            padding: "24px 24px 99px",
          },
          "& .right-inner-content": {
            maxWidth: "510px",
            width: "100%",
            margin: "auto",
            // [theme.breakpoints.down("md")]: {
            //   maxWidth: "400px",
            // },
          },
          "& .right-inner": {
            display: "flex",
            flexDirection: "column",
            height: "100%",
          },
          "& .back-logout-wrap": {
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            margin: "0 0 90px",
            [theme.breakpoints.down("md")]: {
              margin: "0 0 50px",
            },
          },
          "& .back-btn-wrap": {
            "& i": {
              display: "block",
            },
          },
        },
      },
      "& .heading-forgot": {
        fontSize: "24px",
        lineHeight: "30px",
        margin: "0 0 9px",
        color: "rgba(0,0,0,0.87)",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        margin: "0 0 24px",
        color: "#505050",
        fontFamily: "Roboto",
        letterSpacing: "0.25px",
        [theme.breakpoints.down("md")]: {
          margin: "0 0 30px",
        },
      },
      "& .page-link": {
        textAlign: "right",
        marginBottom: "95px",
        [theme.breakpoints.down("md")]: {
          marginBottom: "60px",
        },
        [theme.breakpoints.down("xs")]: {
          marginBottom: "40px",
        },
      },
      "& .bottom-link": {
        textAlign: "center",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0",
        color: "#505050",
        fontFamily: "Expletus Sans",
        fontWeight: 500,
        "& .form-link": {
          marginLeft: "10px",
        },
      },
    },
    wrapper: {
      minHeight: "100vh",
      paddingBottom: "142px",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "105px",
      },
    },
    authMainWrapper: {
      "& .wrapper": {
        paddingBottom: "0",
      },
      "& .header-wrapper": {
        display: "none",
      },
      "& .footer-wrapper": {
        marginLeft: "576px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "470px",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "0",
        },
      },
    },
    innerWrapper: {
      margin: "auto",
      width: "100%",
    },
    modalDialogBox: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 580,
      backgroundColor: "#ffffff",
      padding: "50px 47px 24px",
      borderRadius: "24px",
      "& .modal-heading": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "27px",
      },
      "& .modal-title": {
        fontSize: "24px",
        lineHeight: "28px",
        color: "#000000",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
      },
      "& .modal-desc": {
        fontSize: "14px",
        letterSpacing: "0px",
        lineHeight: "24px",
        color: "#2b2b2b",
        fontWeight: 400,
        fontFamily: "Expletus Sans",
      },
      "& .close-icon": {
        color: "rgba(43, 43, 43, 0.87)",
        lineHeight: "0",
      },
      "& .modal-footer": {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "47px",
        "& .primary-btn, & .secondary-btn ": {
          width: "auto",
          minWidth: "157px",
        },
        "& .MuiButton-root": {
          marginRight: "23px",
          "&:last-child": {
            marginRight: "0",
          },
        },
      },
      "& .primary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "0",
      },
      "& .secondary-btn": {
        minHeight: "48px",
        fontSize: "14px",
        lineHeight: "19px",
        marginBottom: "0",
      },
    },
    havingTroubleModal: {
      flex: "0 0 51%",
      maxWidth: "51%",
      padding: "0 15px",
      "& .auth-logo": {
        maxWidth: "162px",
        marginBottom: "90px",
      },
      "& .auth-inner": {
        paddingTop: "129px",
        "& .auth-left-row": {
          display: "flex",
          alignItems: "flex-end",
          flexWrap: "wrap",
          margin: "0 -10px",
          "& .col": {
            padding: "0 10px",
          },
          "& .col-left": {
            flex: "0 0 54%",
            maxWidth: "54%",
          },
          "& .col-right": {
            flex: "0 0 46%",
            maxWidth: "46%",
            paddingRight: "206px",
            "@media (max-width: 1536px)": {
              paddingRight: "95px",
            },
          },
        },
      },
      "& .auth-desc": {
        fontSize: "24px",
        lineHeight: "28px",
        color: "#000000",
        fontWeight: 400,
        marginBottom: "19px",
      },
      "& .auth-name": {
        fontSize: "14px",
        lineHeight: "24px",
        letterSpacing: "0",
        color: "#94a3b8",
        fontWeight: 400,
      },
      "& .auth-img": {
        margin: "61px auto 0",
        maxWidth: "380px",
        width: "100%",
        height: "630px",
        overflow: "hidden",
        position: "relative",
        marginBottom: "-50px",
      },
      "& .copyright-txt": {
        fontSize: "12px",
        letterSpacing: "0",
        color: "#94a3b8",
        fontWeight: 400,
        fontFamily: "Expletus Sans",
        textAlign: "right",
        "& span": {
          color: "#2b2b2b",
        },
      },
    },
    modalDialog: {
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
    },
    primaryBtnResetPwd: {
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      borderRadius: "7px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "16px",
      lineHeight: "20px",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      padding: "12px 10px",
      minHeight: "56px",
      marginBottom: "0",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      width: "auto",
      minWidth: "165px",
      "&:hover": {
        background: "#4ba3b7",
        color: "#ffffff",
      },
    },
    passwordResetWrapper: {
      padding: "60px",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        padding: "40px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "24px 60px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "24px",
      },
      "& .password-reset-row": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "934px",
        margin: "0 auto",
        [theme.breakpoints.down("md")]: {
          maxWidth: "740px",
        },
        [theme.breakpoints.down("xs")]: {
          flexWrap: "wrap",
          justifyContent: "center",
          textAlign: "center",
          maxWidth: "320px",
        },
        "& .password-reset-img": {
          maxWidth: "456px",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            maxWidth: "325px",
          },
          [theme.breakpoints.down("xs")]: {
            maxWidth: "235px",
          },
        },
      },
      "& .password-reset-content": {
        marginLeft: "160px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "100px",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "70px",
        },
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          marginTop: "20px",
        },
      },
      "& .heading": {
        fontSize: "24px",
        lineHeight: "30px",
        margin: "0 0 9px",
        color: "#000000",
        letterSpacing: "0",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
      },
      "& .sub-txt": {
        fontSize: "14px",
        lineHeight: "24px",
        margin: "0 0 48px",
        color: "#505050",
        fontFamily: "Roboto",
        fontWeight: 400,
      },
    },
    helperClass: {
      color: "#2B2B2B",
    },
    logoutButton: {
      border: "1px solid #e8ecf2",
      borderRadius: "7px",
      filter: "drop-shadow(0px 1px 3.5px rgba(0,0,0,0.2))",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#2b2b2b",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      width: "100%",
      minWidth: "94px",
      padding: "10px 16px",
      minHeight: "48px",
      transition: "all 0.5s ease-in-out",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        padding: "8px 6px",
      },
      "&:hover": {
        background: "transparent",
        color: "#2b2b2b",
      },
    },
  });

class ForgotPassword extends ForgotPasswordController {
  render() {
    const { classes } = this.props;
    return (
      <>
        <Box className={`${classes.authMainWrapper} `}>
          <Box className={`wrapper ${classes.wrapper}`}>
            <Box className={classes.innerWrapper}>
              <Box className={classes.authWrapper}>
                <Box className="auth-inner-block">
                  <AuthLeft />
                  <Box className="auth-right">
                    <Box className="right-inner">
                      <Box className="back-logout-wrap">
                        <Box className="back-btn-wrap" />
                        <Box className="logout-wrap">
                          <Button
                            className={classes.logoutButton}
                            onClick={this.handleOpen}
                          >
                           {this.t(`${configJSON.webLinkContactSupport}`)}
                          </Button>
                        </Box>
                      </Box>
                      <Box className="right-inner-content">
                        <Typography className="heading-forgot" variant="h1">
                          {this.t(`${configJSON.webForgotPwdTxt}`)}
                        </Typography>
                        <Box component="p" className="sub-txt">
                          {this.t(`${configJSON.webForgotSubTxt1}`)}
                        </Box>
                        <Box component="form">
                          <CustomInputWeb
                            errors={
                              this.state.error ? (
                                <>
                                  {this.state.error}{" "}
                                  {this.state.error.includes("Support") && (
                                    <Link
                                      className={classes.formLink}
                                      onClick={this.handleOpen}
                                    >
                                      {this.t(`${configJSON.webLinkContactSupport}`)}
                                    </Link>
                                  )}
                                </>
                              ) : (
                                ""
                              )
                            }
                            success={this.state.emailSuccessMsg}
                            label={this.t(`${configJSON.webLabelEmail}`)}
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                            helperTextClass={this.state.helperTextClass}
                            disabled={this.state.emailCheked}
                          />
                          {this.state.isResend ? (
                            <Button
                              className={classes.primaryButton}
                              onClick={this.handleForgot}
                              data-testid="emailBtn"
                              disabled={this.state.disable}
                              type="submit"
                            >
                              {this.state.resendSec > 0
                                ? `${this.t(configJSON.webResendTxt)} 00:${this.zeroPad(
                                    this.state.resendSec,
                                    2
                                  )} `
                                : this.t(`${configJSON.webBtnResendEmailTxt}`)}
                            </Button>
                          ) : (
                            <Button
                              className={classes.primaryButton}
                              onClick={this.handleForgot}
                              data-testid="emailBtn"
                              type="submit"
                            >
                              {this.t(`${configJSON.webBtnSend}`)}
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Footer />
        </Box>

        <CustomHavingTrobleModal
          isOpen={this.state.open}
          handleClose={this.handleClose}
          t={this.t}
        />
      </>
    );
  }
}
export default withStyles(customStyles, { withTheme: true })(ForgotPassword);
// Customizable Area End
