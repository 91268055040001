// Customizable Area Start
import {
  createStyles,
  Theme as AugmentedTheme,
} from "@material-ui/core/styles";

export const etohShopStyle = (theme: AugmentedTheme) =>
  createStyles({
    mainEtohShopWrapper: {
      display: "flex",
      fontFamily: "Roboto",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: "30px",
      background: "#fff",
      position: "absolute",
      top: '82px',
      zIndex: 1,
      "& .mobile-heading": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "48px",
          paddingBottom: "0",
          display: "flex",
          borderBottom: "0",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "24px 24px 0",
          width: "100%",
        },
        "& .marketing-menu": {
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .back-btn-wrap": {
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .heading": {
          [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "18px",
            color: "#2B2B2B",
            margin: "0 20px",
            letterSpacing: "0",
            fontFamily: "Expletus Sans",
          },
        },
      },
      "& .page-wrapper": {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        borderTop: "1px solid #E8ECF2",
        height: "100vh",
        "& .right-side-info": {
          // paddingLeft:"35px",
          paddingTop: "40px",
          "& .error-message": {
            margin: "20% auto",
            display: "flex",
            justifyContent: "center",
            fontFamily: "Expletus Sans",
            color: "#2B2B2B",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            width: "100%",
            height: "20px",
            marginRight: "16px",
            verticalAlign: "center",
            textAlign: "center",
            marginLeft: "16px",
            whiteSpace: "nowrap",
          },
        },
        "& .product-list-item": {
          display: "flex",
          flexDirection: "column",
          flex: 1,
          border: "1px solid #E8ECF2",
          borderRadius: "12px",
          padding: "24px",
          minHeight: "203px",
          "& .bottom-content": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& .secondary-btn": {
              width: "48px",
              marginLeft: "8px",
            },
            "& .quantity-dropdown": {
              display: "flex",
              alignItems: "center",
            },
            "& .cart-weekly-text":{
              fontFamily: "Expletus Sans",
              color: "#94A3B8",
              fontSize: "12px",
              fontWeight: "500",
              fontStyle: "normal",
              marginLeft: "10px",    
            },
            "& .cart-price-dash-text": {
              color: "#94A3B8",
              fontFamily: "Expletus Sans",
              fontStyle: "normal",
              fontSize: "12px",
              fontWeight: "500",
              marginLeft: "4px",
              textDecoration: "line-through",
            },
            "& .select-control": {
              "& .MuiSelect-select": {
                color: "black",
                fontSize: "14px",
                letterSpacing: "0.1px",
                fontFamily: "Expletus Sans",
                opacity: "0.671",
                fontWeight: "600",
                display: "flex",
                border: "1px solid #E8ECF2",
                borderRadius: "12px",
                padding: "0px 0px 0 16px",
                alignItems: "center",
                width: "48px",
                height: "46px",
                "&:focus": {
                  backgroundColor: "transparent",
                },
              },
            },
            "& .price-control": {
              "& .MuiSelect-select": {
                fontSize: "14px",
                letterSpacing: "0.1px",
                color: "#94A3B8",
                marginLeft: "4px",
                fontFamily: "Expletus Sans",
                opacity: "0.671",
                fontWeight: "500",
                display: "flex",
                borderRadius: "12px",
                alignItems: "center",
                "&:focus": {
                  backgroundColor: "transparent",
                },
              },
            },
          },
          "& .item-top": {
            display: "flex",
            flexDirection: "column",
            "& .image-content": {
              marginBottom: "10px",
              "& .product-img": {
                height: "287px",
                minWidth: "100%",
                marginBottom: "24px",
                cursor: "pointer",
              },
            },
            "& .content-section": {
              "& .price-txt": {
                fontFamily: "Expletus Sans",
                color: "#2B2B2B",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.15px",
                margin: "6px 0",
                display: "flex",
                alignItems: "center",
              },
              "& .description-txt": {
                fontFamily: "Roboto",
                color: "#94A3B8",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.15px",
                width: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                margin: "6px 0",
              },
            },
          },
          "& .item-bottom-info": {
            marginTop: "25px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",

            "& .wrap-name": {
              flexDirection: "column",
              display: "flex",

              "& .price-txt": {
                fontFamily: "Expletus Sans",
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.15px",
                margin: "6px 0",
              },
              "& .product-additional-info": {
                color: "rgba(0, 0, 0, 0.38)",
                fontFamily: "Roboto",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "0.4px",
              },
            },
          },
        },
      },
      "& .etohShop-top": {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: "11px 32px",
        borderBottom: "1px solid #E8ECF2",
        width: "100%",
        height: "55px",
        "& .top-left": {
          display: "flex",
          alignItems: "center",
          "& .total-services": {
            color: "var(--task-completed-color, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            letterSpacing: "0.1px",
            verticalAlign: "center",
            textAlign: "center",
            marginRight: "16px",
            whiteSpace: "nowrap",
          },
          "& .line": {
            height: "20px",
            borderLeft: "2px solid #E8ECF2",
            marginRight: "6px",
          },
          "& .search-wrapper": {
            [theme.breakpoints.down("xs")]: {
              width: "100%",
            },
            "& .MuiSvgIcon-root": {
              color: "rgba(43, 43, 43, 0.67)",
              fontSize: "20px",
            },
            "& .MuiInputAdornment-positionStart": {
              marginRight: "12px",
            },
            "& .MuiInput-underline": {
              "&:before, &:after": {
                display: "none",
              },
            },
            "& .MuiInput-root": {
              maxWidth: "100%",
              width: "413px",
              [theme.breakpoints.down("xs")]: {
                width: "100%",
              },
              [theme.breakpoints.down("md")]: {
                width: "308px",
              },
              [theme.breakpoints.down("sm")]: {
                width: "234px",
              },
            },
            "& .MuiInputBase-input": {
              fontFamily: "Expletus Sans",
              color: "#000000",
              lineHeight: "20px",
              letterSpacing: "0.1px",
              fontSize: "14px",
              fontWeight: "500",
              "&::placeholder": {
                opacity: "0.369",
                color: "rgba(43, 43, 43, 0.87)",
              },
              "&::-webkit-input-placeholder": {
                opacity: "0.369",
                color: "rgba(43, 43, 43, 0.87)",
              },
              "&:-ms-input-placeholder": {
                opacity: "0.369",
                color: "rgba(43, 43, 43, 0.87)",
              },
            },
          },
        },
        "& .top-right": {
          marginLeft: "15px",
          display: "flex",
          alignItems: "center",
          "& .primary-btn": {
            minWidth: "45px",
            minHeight: "20px",
            fontSize: "12px",
            padding: 0,
          },
          "& .line": {
            height: "20px",
            borderLeft: "2px solid #E8ECF2",
            margin: "0 16px",
          },
        },
        "& .oneTimeFormControl": {
          display: "block",
          "& .MuiFormControlLabel-root": {
            marginLeft: "-22px",
            "& .MuiFormControlLabel-label": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0.25px",
            },
          },
          "& .MuiRadio-root": {
            color: "#94A3B8",
            padding: "7px",
            marginLeft: "12px",
            "&:hover": {
              backgroundColor: "rgba(54, 145, 166, 0.04)",
            },
            "&.Mui-checked": {
              color: "#4ba3b7",
            },
            "& .MuiSvgIcon-root": {
              width: "20px",
              height: "20px",
            },
          },
        },
        "& .select-control": {
          "&.multi-select": {
            flexDirection: "row-reverse",
            display: "flex",
            "&.assignee-select, &.project-lead-select": {
              "& .multiple-clear": {
                top: "4px",
              },
            },
            "& .MuiSelect-select": {
              alignItems: "center",
              display: "flex",
              paddingRight: "0",
              "& .select-icon": {
                marginRight: "6px",
              },
              "& .assignee-list": {
                marginLeft: "6px",
                alignItems: "center",
                display: "flex",
                "& .assignee-bubble": {
                  background: "#ECF1F4",
                  letterSpacing: "-0.244px",
                  border: "2px solid #FFF",
                  height: "24px",
                  display: "flex",
                  position: "relative",
                  fontSize: "9.586px",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Roboto",
                  fontWeight: 600,
                  lineHeight: "13.42px",
                  marginLeft: "-4px",
                  borderRadius: "100%",
                  color: "rgba(43, 43, 43, 0.87)",
                  width: "24px",
                  "&:first-child": {
                    marginLeft: 0,
                  },
                },
              },
            },
            "& .multiselect-label": {
              display: "none",
            },
            "& .MuiInput-formControl": {
              marginTop: "0",
            },

            "& .multiple-clear": {
              position: "relative",
              fontSize: "15px",
              right: "0",
              top: "2px",
              zIndex: 1,
              color: "#94A3B8",
              marginLeft: "5px",
              cursor: "pointer",
            },
          },
          "&.sortby-control": {
            "& .select-icon": {
              top: "3px",
            },
            "& .MuiSelect-select": {
              paddingRight: "0",
            },
          },
          "& .select-icon": {
            position: "relative",
            marginRight: "6px",
            lineHeight: "normal",
          },
          "& .MuiSelect-select": {
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0",
            color: "#2B2B2B",
            opacity: "0.671",
            fontWeight: "500",
            fontFamily: "Expletus Sans",
            paddingBottom: "0",
            paddingTop: "0",
            "&:focus": {
              backgroundColor: "transparent",
            },
            "& .clear-icon": {
              width: "50px",
              height: "50px",
              background: "red",
            },
          },
          "& .MuiInput-underline:before": {
            display: "none",
          },
          "& .MuiInput-underline:after": {
            display: "none",
          },
        },
      },
    },
    etohShopdrawer: {
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.12)",
      minWidth: "367px",
      top: "88px",
      position: "sticky",
      maxHeight: "calc(100vh - 88px)",
      [theme.breakpoints.down("md")]: {
        minWidth: "320px",
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    etohShopPaper: {
      borderRight: "0",
      width: "100%",
      position: "static",
      height: "100vh",
      padding: "18px 40px 18px 28px",
      overflowY: "auto",
      [theme.breakpoints.down("sm")]: {
        padding: "18px 28px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "80%",
        position: "fixed",
      },
    },
    dropdownStyle: {
      maxWidth: "130px",
      width: "100%",
      borderRadius: "12px",
      marginTop: "16px",
      marginLeft: "-50px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "26px",
      },
      "& .MuiPopover-paper ": {
        borderRadius: "12px",
        boxShadow: "0px 5px 12px 0px rgb(191 186 186 / 22%)",
      },
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingRight: "24px",
            paddingLeft: "24px",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  fill: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                letterSpacing: "0",
                lineHeight: "17px",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          padding: "14px 24px",
          color: "#2B2B2B",
          "& .MuiListItemText-root": {
            margin: "0",
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
            "& .multiselect-squareCheck": {
              "& .normal-img": {
                display: "none",
              },
              "& .selected-img": {
                display: "block",
              },
            },
          },
          "& .multiselect-check": {
            marginLeft: "5px",
            display: "none",
            maxWidth: "16px",
            flex: "0 0 16px",
          },
          "& .multiselect-squareCheck": {
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
            "& .selected-img": {
              display: "none",
            },
          },
        },
        "& .new-group": {
          color: "#4BA3B7",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          textTransform: "capitalize",
          alignItems: "center",
          textAlign: "center",
          marginLeft: "12px",
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        letterSpacing: "0",
        lineHeight: "20px",
        color: "#000000",
        padding: "14px 17px",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        whiteSpace: "normal",
        "& .MuiTypography-root": {
          letterSpacing: "0",
          font: "inherit",
        },
      },
      "& .MuiList-padding": {
        paddingBottom: "8px",
        paddingTop: "4px",
      },
    },
    dropdownStyle2: {
      maxWidth: "177px",
      width: "100%",
      borderRadius: "12px",
      marginTop: "16px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "26px",
      },
      "& .MuiPopover-paper ": {
        borderRadius: "12px",
        boxShadow: "0px 5px 12px 0px rgb(191 186 186 / 22%)",
      },
      "&.multiSelect": {
        maxWidth: "265px",
        boxShadow: "0px 16px 48px rgba(0, 0, 0, 0.22)",
        borderRadius: "12px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "26px",
        },
        "& .MuiList-padding": {
          paddingTop: "16px",
          "& .MuiListSubheader-gutters": {
            paddingRight: "24px",
            paddingLeft: "24px",
            "& .form-control": {
              marginBottom: "0",
              "& .MuiInputAdornment-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  fill: "#94A3B8",
                },
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "13px",
                letterSpacing: "0",
                lineHeight: "17px",
                padding: "16px",
                "&.MuiOutlinedInput-inputAdornedStart": {
                  paddingLeft: "0",
                },
              },
            },
          },
        },
        "& .MuiMenuItem-root": {
          padding: "14px 24px",
          color: "#2B2B2B",
          "& .MuiListItemText-root": {
            margin: "0",
          },
          "&.Mui-selected": {
            backgroundColor: "#F2F8FC",
            "&:hover": {
              backgroundColor: "#F2F8FC",
            },
            "& .multiselect-check": {
              display: "block",
            },
            "& .multiselect-squareCheck": {
              "& .normal-img": {
                display: "none",
              },
              "& .selected-img": {
                display: "block",
              },
            },
          },
          "& .multiselect-check": {
            marginLeft: "5px",
            display: "none",
            maxWidth: "16px",
            flex: "0 0 16px",
          },
          "& .multiselect-squareCheck": {
            marginLeft: "5px",
            flex: "0 0 16px",
            maxWidth: "16px",
            "& .selected-img": {
              display: "none",
            },
          },
        },
        "& .new-group": {
          color: "#4BA3B7",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          textTransform: "capitalize",
          alignItems: "center",
          textAlign: "center",
          marginLeft: "12px",
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        letterSpacing: "0",
        lineHeight: "20px",
        color: "#000000",
        padding: "14px 17px",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        whiteSpace: "normal",
        "& .MuiTypography-root": {
          letterSpacing: "0",
          font: "inherit",
        },
      },
      "& .MuiList-padding": {
        paddingBottom: "8px",
        paddingTop: "4px",
      },
    },
    primaryButton: {
      borderRadius: "12px",
      background: "#4ba3b7",
      border: "1px solid #4ba3b7",
      fontSize: "24px",
      lineHeight: "18px",
      color: "#FFFFFF",
      textAlign: "center",
      fontWeight: 500,
      maxWidth: "100%",
      fontFamily: "Expletus Sans",
      padding: "12px 10px",
      minHeight: "48px",
      transition: "all 0.5s ease-in-out",
      marginBottom: "0",
      textTransform: "capitalize",
      cursor: "pointer",
      "&:hover": {
        color: "#ffffff",
        background: "#4ba3b7",
      },
    },
    secondaryButton: {
      background: "transparent",
      borderRadius: "12px",
      fontSize: "24px",
      border: "1px solid #4BA3B7",
      color: "#4BA3B7",
      fontWeight: 500,
      lineHeight: "18px",
      textTransform: "unset",
      fontFamily: "Expletus Sans",
      textAlign: "center",
      maxWidth: "100%",
      // minWidth: "48px",
      padding: "12px 10px",
      transition: "all 0.5s ease-in-out",
      minHeight: "48px",
      marginBottom: "0",
      textDecoration: "none",
      "&:hover": {
        background: "transparent",
        color: "#4BA3B7",
      },
    },
    marketingSidebar: {
      "& .sidebar-title": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "48px",
          display: "flex",
          alignItems: "center",
        },
        "& .back-btn-wrap": {
          [theme.breakpoints.down("xs")]: {
            marginRight: "16px",
          },
        },
        "& .heading": {
          [theme.breakpoints.down("xs")]: {
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "22px",
            color: "#2b2b2b",
            fontFamily: "Expletus Sans",
          },
        },
      },
      "& .sidebar-group": {
        "&.activeGroup": {
          "& .sidebar-heading": {
            background: "#F2F8FC",
            display: "inline-block",
            padding: "8px 15px",
            borderRadius: "4px",
          },
        },
        marginBottom: "32px",
        "& .hide-underline": {
          textDecoration: "none",
        },
        "& .header": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
          "& .header-heading": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "18.5px",
            fontStyle: "normal",
          },
        },
      },
      "& .sidbar-accordion": {
        boxShadow: "none",
        margin: "32px 0",
        "&:before": {
          display: "none",
        },
        "& .accordionTitle": {
          fontSize: "14px",
          lineHeight: "20px",
          fontFamily: "Expletus Sans",
          fontWeight: 500,
          color: "rgba(43, 43, 43, 0.87)",
          letterSpacing: "0.1px",
          padding: "0",
          minHeight: "unset",
          "& .MuiAccordionSummary-content": {
            margin: "0",
          },
          "& .MuiIconButton-root": {
            padding: "0",
            color: "#94A3B8",
            marginRight: "0",
            "& .MuiSvgIcon-root": {
              width: "20px",
              height: "20px",
            },
          },
        },
        "& .MuiAccordionDetails-root": {
          padding: "3px 0 0",
          flexDirection: "column",
        },
        "& .expandIcon": {
          color: "#94A3B8",
        },
        "& .category-options-more": {
          fontSize: "14px",
          lineHeight: "18px",
          color: "#4BA3B7",
          fontFamily: "Roboto",
          fontWeight: 400,
          letterSpacing: "0.25px",
          paddingLeft: "27px",
        },
        "& .search-wrapper": {
          borderBottom: "1px solid #E8ECF2",
          marginBottom: "6px",
          "& .MuiInput-root": {
            width: "413px",
            maxWidth: "100%",
            [theme.breakpoints.down("sm")]: {
              width: "234px",
            },
            [theme.breakpoints.down("xs")]: {
              width: "100%",
            },
          },
          "& .MuiInputAdornment-positionStart": {
            marginRight: "6px",
          },
          "& .MuiSvgIcon-root": {
            color: "#94A3B8",
            fontSize: "16px",
          },
          "& .MuiInputBase-input": {
            fontSize: "12px",
            lineHeight: "16px",
            color: "#000000",
            fontWeight: "400",
            fontFamily: "Roboto",
            letterSpacing: "0.4px",
            padding: "11px 0",
            "&::-webkit-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)",
            },
            "&:-ms-input-placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)",
            },
            "&::placeholder": {
              opacity: "0.369",
              color: "rgba(43, 43, 43, 0.87)",
            },
          },
        },
      },
      "& .line": {
        borderTop: "1px solid #E8ECF2",
        marginBottom: "24px",
      },
      "& .sidebar-heading": {
        color: "#2B2B2B",
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Expletus Sans",
        fontWeight: 400,
        marginBottom: "8px",
      },
      "& .sidebar-ul": {
        borderLeft: "1px solid #E8ECF2",
        padding: "17px 0 0",
        "& .sidebar-li": {
          padding: "0",
          opacity: "1",
          minHeight: "unset",
          marginBottom: "16px",
          position: "relative",
          "&:before": {
            content: '""',
            width: "17px",
            height: "1px",
            background: "#E8ECF2",
            marginRight: "16px",
          },
          "&:last-child": {
            marginBottom: "0",
          },
          "&.label-selected": {
            "& .tab-label": {
              background: "#F2F8FC",
            },
            "& .tab-no": {
              color: "#2B2B2B",
            },
          },
          "& .label-wrapper": {
            display: "flex",
            alignItems: "center",
            flexDirection: "unset",
            justifyContent: "space-between",
            width: "100%",
            color: "#2B2B2B",
            fontSize: "14px",
            lineHeight: "19px",
            fontFamily: "Expletus Sans",
            fontWeight: 500,
            letterSpacing: "0",
            textTransform: "capitalize",
            textDecoration: "none",
            "& .tab-label": {
              padding: "8px 20px",
              borderRadius: "6px",
              background: "#ffffff",
            },
            "& .tab-no": {
              color: "#94A3B8",
            },
          },
        },
      },
      "& .select-control": {
        "&.multi-select": {
          "& .multiselect-label": {
            display: "none",
          },
          "& .MuiInput-formControl": {
            marginTop: "0",
          },
          "& .MuiSelect-select": {
            paddingRight: "40px",
          },
          "& .multiple-clear": {
            position: "absolute",
            fontSize: "15px",
            right: "18px",
            color: "#94A3B8",
            top: "3px",
            zIndex: 1,
          },
        },
        "& .MuiSelect-select": {
          fontSize: "14px",
          letterSpacing: "0.1px",
          color: "#2b2b2b",
          fontFamily: "Expletus Sans",
          opacity: "0.671",
          fontWeight: "500",
          paddingBottom: "0",
          paddingTop: "0",
          "&:focus": {
            backgroundColor: "transparent",
          },
          "& .clear-icon": {
            width: "50px",
            height: "50px",
            background: "red",
          },
          "& .MuiBox-root": {
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            "& .select-icon": {
              marginRight: "6px",
            },
          },
        },
        "& .MuiInput-underline:before": {
          display: "none",
        },
        "& .MuiInput-underline:after": {
          display: "none",
        },
      },
      "& .sortby-control": {
        "& .MuiSelect-select": {
          paddingRight: "0",
        },
        "& .select-icon": {
          top: "3px",
        },
      },
    },
    orderDetailsRoot: {
      display: "flex",
      flexWrap: "wrap",
      flex: "1",
      "& .header-content": {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        padding: "0 24px",
        "& .live-chat-box": {
          display: "flex",
          alignItems: "center",
          justifyContent: " center",
          marginTop: " 24px",
          cursor: "pointer",
          "& .live-btn-txt": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            marginLeft: "8px",
          },
          "& .chat-icon": {
            color: "#2B2B2B",
            width: "16px",
            heigth: "16px",
          },
        },
        "& .back-btn-content": {
          display: "flex",
          alignItems: "center",
          justifyContent: " center",
          marginTop: " 24px",
          cursor: "pointer",
          "& .back-txt": {
            color: " #2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            marginLeft: "8px",
          },
        },
      },
      "& .main-container": {
        padding: "100px 80px",
        margin: "0",
        "& .image-wrapper": {
          display: "block",
          "& .thumbnail": {
            cursor: "pointer",
            padding: "12px 80px 50px",
            height: "400px",
            [theme.breakpoints.down("sm")]: {
              padding: "16px 25px 25px",
            },
          },
        },
        "& .second-grid": {
          "& .main-heading": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "34px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          },
          "& .short-description": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            marginTop: "8px",
          },
          "& .price-txt": {
            color: "#94A3B8",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            marginTop: "16px",
            marginBottom: "6px",
          },
          "& .card-quantity-container": {
            display: "flex",
            alignItems: "center",
            marginTop: "24px",
            "& .cart-quantity": {
              margin: "24px",
            },
            "& .secondary-btn": {
              width: "32px",
              height: "32px",
              fontSize: "24px",
              borderRadius: "20px",
              lineHeight: "19px",
            },
          },
          "& .price-container": {
            display: "flex",
            alignItems: "center",
            "& .cart-price-text": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "400",
              textTransform: "capitalize",
            },
            "& .price-dash-txt": {
              color: "#94A3B8",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontStyle: "normal",
              fontWeight: "500",
              marginLeft: "10px",
              textDecoration: "line-through",
            },
            "& .price-control": {
              "& .MuiSelect-select": {
                fontSize: "14px",
                letterSpacing: "0.1px",
                fontFamily: "Expletus Sans",
                fontWeight: "500",
                opacity: "0.671",
                display: "flex",
                borderRadius: "12px",
                alignItems: "center",
                marginLeft: "8px",
                color: "#94A3B8",
                "&:focus": {
                  backgroundColor: "transparent",
                },
              },
            },
          },
          "& .primary-btn": {
            width: "100%",
            fontSize: "14px",
            marginTop: "48px",
            borderRadius: "10px",
          },
          "& .secondary-btn-2": {
            width: "100%",
            fontSize: "14px",
            marginTop: "48px",
            borderRadius: "10px",
          },
          "& .cart-description": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "400",
            marginTop: "48px",
            marginBottom: "6px",
          },
          "& .cart-description-txt": {
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            marginTop: "16px",
            marginBottom: "6px",
          },
        },
      },
    },
    modalImageDialog: {
      overflow: "auto",
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(57,57,57,0.502)",
      },
      "& .image-container": {
        "& .image-wrapper-2": {
          width: "600px",
          margin: "0 auto",
        },
        "& .close-icon": {
          color: "#fff",
          margin: "10px",
          textAlign: "end",
          cursor: "pointer",
        },
      },
    },
    carouselWrapper: {
      maxWidth: "100%",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        margin: "0 auto",
      },
      "& .slider-item": {
        // height: "350px",
      },
      "& .slick-next:before": {
        content: "none",
      },
      "& .slick-prev:before": {
        content: "none",
      },
      "& .slider-img": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        // width: "500px",
        // height: "500px",
        "& img": {
          objectFit: "contain",
          marginTop: "-40px",
        },
      },
      "& .slick-slide": {
        height: "inherit",
      },
      "& .slick-dots": {
        position: "absolute",
        bottom: "-33px",
      },
      "& .slick-dots li.slick-active button:before": {
        color: "rgba(0, 0, 0, 0.87)",
      },
      "& .slick-dots li button:before": {
        color: "#565454",
        visibility: "hidden",
      },
      "& .slick-dots li": {
        color: "#FFFF",
        margin: "0 2px",
      },
      "& .slick-dots li img": {
        width: "10px",
        height: "5px",
        borderRadius: "2px",
      },
      "& .slick-dots li.slick-active img": {
        width: "28px",
      },
    },
    modalBoxCenter: {
      display: "flex",
      // alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    mergeInfoGroupsModal: {
      width: "550px",
      position: "relative",
      display: "inline-block",
      overflowY: "auto",
      backgroundColor: "#ffffff",
      // padding: "0px 30px",
      "& .default-cart": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .default-cart-img": {
          width: "380px",
          marginBottom: "24px",
        },
      },
      "& .primary-btn-2": {
        width: "75%",
        fontSize: "14px",
        marginTop: "48px",
        marginBottom: "48px",
        borderRadius: "10px",
      },
      "& .cart-empty-text": {
        color: " #2B2B2B",
        fontFamily: "Expletus Sans",
        fontStyle: "normal",
        fontSize: "26px",
        fontWeight: "500",
        marginBottom: "8px",
        lineHeight: "26px",
        textAlign: "center",
      },
      "& .header-container": {
        display: "flex",
        justifyContent: "space-between",
        padding: "0px 30px",
        "& .modal-title-box": {
          margin: "45px 0px",
          "& .title-txt": {
            color: "var(--brand-color-brand-color-dark, #2B2B2B)",
            fontFamily: "Expletus Sans",
            fontStyle: "normal",
            fontSize: "22px",
            fontWeight: "400",
            marginBottom: "8px",
            lineHeight: "26px",
          },
          "& .sub-title-txt": {
            color: "#505050",
            fontSize: "14px",
            fontFamily: "Expletus Sans",
            fontStyle: "normal",
            lineHeight: "16px",
            fontWeight: "500",
            letterSpacing: "0.09px",
          },
        },
        "& .heading-bar": {
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          // margin: "20px 20px 0px 0px",
          justifyContent: "end",
          cursor: "pointer",
          "& .close-icon": {
            height: "24px",
            width: "24px",
            color: "#959595",
            cursor: "pointer",
            marginTop: "-30px",
          },
        },
      },
      "& .headder": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 30px",
        "& .checkboxes": {
          "& .MuiFormControlLabel-root": {
            marginRight: "0",
            marginLeft: "-7px",
            "& .MuiFormControlLabel-label": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              letterSpacing: "0.1px",
            },
          },
          "& .MuiCheckbox-root": {
            color: "#94A3B8",
            padding: "7px",
            "&:hover": {
              backgroundColor: "rgba(54, 145, 166, 0.04)",
            },
            "&.Mui-checked": {
              color: "#4ba3b7",
            },
            "& .MuiSvgIcon-root": {
              width: "20px",
              height: "20px",
            },
          },
        },
        "& .remove-txt": {
          color: "#4BA3B7",
          fontFamily: "Expletus Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          marginRight: "24px",
          cursor: "pointer",
          whiteSpace: "nowrap",
          textTransform: "capitalize",
        },
      },
      "& .card-container-flex": {
        // display:"flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 40px",
        "& .card-container": {
          marginTop: "20px",
          alignItems: "center",
          display: "flex",
          "& .cart-img": {
            width: "78px",
            height: "78px",
          },
          "& .checkboxes": {
            marginRight: "10px",
            "& .MuiFormControlLabel-root": {
              marginRight: "0",
              marginLeft: "-7px",
              "& .MuiFormControlLabel-label": {
                color: "#2B2B2B",
                fontSize: "14px",
                fontFamily: "Expletus Sans",
                fontWeight: 500,
                letterSpacing: "0.1px",
                lineHeight: "20px",
              },
            },
            "& .MuiCheckbox-root": {
              color: "#94A3B8",
              padding: "7px",
              "&:hover": {
                backgroundColor: "rgba(54, 145, 166, 0.04)",
              },
              "&.Mui-checked": {
                color: "#4ba3b7",
              },
              "& .MuiSvgIcon-root": {
                height: "20px",
                width: "20px",
              },
            },
          },
          "& .card-content": {
            marginLeft: "16px",
            "& .cart-heading": {
              fontFamily: "Expletus Sans",
              color: "#2B2B2B",
              fontStyle: "normal",
              fontSize: "16px",
              fontWeight: "400",
            },
            "& .cart-sub-heading": {
              fontFamily: "Roboto",
              color: "#2B2B2B",
              fontSize: "12px",
              fontWeight: "400",
              fontStyle: "normal",
              marginTop: "8px",
              width: "180px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow:"ellipsis",
            },
            "& .price-container": {
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
              "& .cart-price-dash-text": {
                fontFamily: "Expletus Sans",
                color: "#94A3B8",
                fontSize: "12px",
                fontWeight: "500",
                fontStyle: "normal",
                marginLeft: "10px",
                marginTop: "10px",
                textDecoration: "line-through",
              },             
              "& .cart-price-text": {
                fontFamily: "Expletus Sans",
                color: "#94A3B8",
                fontStyle: "normal",
                fontSize: "12px",
                marginTop: "10px",
                fontWeight: "500",
                marginLeft: "10px",
                textTransform: "capitalize",
              },
            },
            "& .cart-price": {
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontStyle: "normal",
              marginTop: "10px",
              fontWeight: "500",
            },
          },
        },
        "& .card-quantity-container": {
          display: "flex",
          right: "50px",
          position: "absolute",
          marginTop: "-15px",
          alignItems: "center",         
          "& .form-control": {
            marginBottom: "0",
            "& .MuiOutlinedInput-root":{
              borderRadius: "20px",
            },
            "& .MuiInputAdornment-root": {
              "& .MuiSvgIcon-root": {
                fontSize: "20px",
                fill: "#94A3B8",
              },
            },
            "& .MuiOutlinedInput-input": {
              width: "32px",
              height: "8px",
              fontSize: "12px",
              borderRadius: "20px",
              fontFamily: "Roboto",
              color: "#333333",
              fontWeight: "600",
            },
          },
          "& .secondary-btn": {
            width: "32px",
            height: "32px",
            fontSize: "24px",
            borderRadius: "20px",
            lineHeight: "19px",
            marginLeft:"10px",
          },
          "& .cart-quantity": {
            margin: "12px",
          },
        },
      },
      "& .line": {
        marginTop: "20px",
        borderBottom: "1px solid #E8ECF2",
      },
      "& .cart-subtotal-text": {
        fontFamily: "Roboto",
        color: "#000000DE",
        fontSize: "16px",
        fontWeight: "400",
        fontStyle: "normal",
        marginTop: "16px",
        textAlign: "end",
        padding: "0px 30px",
        paddingBottom: "200px",
      },
      "& .cart-end-section": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "16px",
        padding: "0px 30px",
        "& .cart-end-text": {
          color: "#000000DE",
          fontSize: "16px",
          fontFamily: "Expletus Sans",
          fontWeight: "400",
          fontStyle: "normal",
        },
        "& .cart-end-price-text": {
          fontFamily: "Expletus Sans",
          color: "#000000DE",
          fontStyle: "normal",
          fontSize: "16px",
          fontWeight: "600",
        },
      },
      "& .cart-end-section-hide":{
        display:"none"
      },
      "& .note-section": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#F6F8DE",
        padding: "12px",
        borderRadius: "12px",
        margin: "30px",
        "& .note-section-txt": {
          color: "#000000DE",
          fontSize: "14px",
          fontFamily: "Roboto",
          fontWeight: "600",
          fontStyle: "normal",
        },
        "& .note-section-des-txt": {
          color: "#000000DE",
          fontSize: "14px",
          fontFamily: "Roboto",
          fontWeight: "400",
          fontStyle: "normal",
        },
      },
      "& .button-section": {
        // position: "absolute",
        background: "#F2F8FC",
        // height: "80px",
        padding: "12px 30px",
        width:"100%",
        // position:" relative",
        display:"inline-block",
        bottom: 0,
        zIndex: 1000,
        position: "sticky",    
        "& .button-section-total-txt": {
          color: "#000000DE",
          fontSize: "10px",
          fontFamily: "Roboto",
          fontStyle: "normal",
          textTransform: "uppercase",
          fontWeight: "400",
          marginBottom: "16px",
        },
        "& .button-section-price-txt": {
          color: "#000000DE",
          fontSize: "22px",
          fontFamily: "Expletus Sans",
          fontWeight: "400",
          fontStyle: "normal",
          marginBottom: "16px",
          "& .span": {
            color: "#000000DE",
            fontSize: "10px",
            fontFamily: "Roboto",
            fontWeight: "400",
            fontStyle: "normal",
            marginBottom: "16px",
          },
        },
        "& .primary-btn": {
          top: "0px",
          position: "absolute",
          height: "100%",
          right: "0px",
          borderRadius: 0,
        },
      },
    },
    secondaryButtonn: {
      border: "1px solid #E8ECF2",
      fontSize: "14px",
      background: "transparent",
      color: "#4BA3B7",
      borderRadius: "7px",
      lineHeight: "18px",
      fontWeight: 500,
      fontFamily: "Expletus Sans",
      textAlign: "center",
      textTransform: "unset",
      maxWidth: "100%",
      // minWidth: "198px",
      padding: "12px 10px",
      minHeight: "48px",
      marginBottom: "0",
      cursor: "pointer",
      textDecoration: "none",
      // transition: "all 0.5s ease-in-out",
      "&:hover": {
        background: "transparent",
        color: "#4BA3B7",
      },
    },
    mainSuccessContainer: {
      width: "100%",
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      "& .image-container": {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        "& .image": {
          width: "500px",
        },
      },
      "& .success-headding": {
        color: "#2B2B2B",
        fontSize: "24px",
        fontFamily: "Expletus Sans",
        fontWeight: "400",
        fontStyle: "normal",
        marginBottom: "16px",
      },
      "& .success-sub-txt": {
        width: "350px",
        color: "#2B2B2B",
        fontSize: "14px",
        fontFamily: "Roboto",
        fontWeight: "400",
        fontStyle: "normal",
        marginBottom: "36px",
      },
      "& .button-container": {
        display: "flex",
        alignItems: "center",
        "& .secondary-btn": {
          width: "200px",
          color: "#2B2B2B",
          fontSize: "14px",
          fontFamily: "Expletus Sans",
          fontWeight: "500",
          fontStyle: "normal",
          marginBottom: "16px",
          border: "1px solid #E8ECF2",
        },
        "& .primary-btn": {
          width: "223px",
          fontSize: "14px",
          fontFamily: "Expletus Sans",
          fontWeight: "500",
          fontStyle: "normal",
          marginBottom: "16px",
          marginLeft: "8px",
        },
      },
    },
    OrderDetailsWrapper: {
      "& .inner-wrapper": {
        width: "100%",
        padding: "73px 0 194px",
        [theme.breakpoints.down("sm")]: {
          padding: "21px 0 129px",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "24px 0",
        },
      },
    },
    secondaryHeader: {
      padding: "25px 0",
      borderBottom: "1px solid #E8ECF2",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      "& .container": {
        maxWidth: "100%",
        padding: "0 25px",
        margin: "0 auto",
      },
      "& .image-wrapper": {
        display: "flex",
        alignItems: "center",
        "& .back-text": {
          display: "inline-block",
          color: "#000000de",
          fontFamily: "Expletus Sans",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: 1.5,
          textDecoration: "none",
          marginLeft: "10px",
        },
      },
    },
    profileInnerWrapper: {
      textAlign: "left",
      maxWidth: "100%",
      width: "100%",
      padding: "0 160px",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        padding: "0 116px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0 24px",
      },
      "& .main-heading": {
        paddingBottom: "48px",
        marginBottom: "48px",
        borderBottom: "1px solid #E8ECF2",
        [theme.breakpoints.down("sm")]: {
          paddingBottom: "32px",
          marginBottom: "44px",
        },
        [theme.breakpoints.down("xs")]: {
          paddingBottom: "0",
          marginBottom: "48px",
          borderBottom: "0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
        "& .profile-menu": {
          display: "none",
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .back-btn-wrap": {
          display: "none",
          [theme.breakpoints.down("xs")]: {
            display: "block",
          },
        },
        "& .heading": {
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "42px",
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          letterSpacing: "0",
          [theme.breakpoints.down("xs")]: {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
            margin: "0 20px",
          },
        },
      },
      "& .main-heading-2": {
        "& .heading": {
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "42px",
          color: "#2B2B2B",
          fontFamily: "Expletus Sans",
          letterSpacing: "0",
        },
        "& .sub-title-heading": {
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "42px",
          color: "#2B2B2B",
          fontFamily: "Roboto",
          letterSpacing: "0",
        },
      },
      "& .main-wrapper": {
        // padding: "0 90px",
        [theme.breakpoints.down("xs")]: {
          // padding: "0 30px",
        },
        "& .top-main-wrapper": {
          display: "flex",
          flexDirection: "column",
          "& .title-section": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            "& .primary-btn": {
              width: "124px",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontWeight: "500",
              fontStyle: "normal",
              marginBottom: "16px",
            },
            "& .choose-item-txt": {
              color: "var(--brand-color-brand-color-dark, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontSize: "22px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "26px",
            },
            "& .choose-item-sub-txt": {
              color: "#505050",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "24px",
              letterSpacing: "0.25px",
              marginTop: "16px",
            },
          },
          "& .filter-section": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "16px",
            borderBottom: "1px solid #E8ECF2",
            "& .left-box": {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: "20px",
              "& .filter-txt": {
                color: "#505050",
                fontFamily: "Expletus Sans",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.25px",
              },
              "& .clear-all-btn": {
                color: "var(--brand-color-primary-100, #4BA3B7)",
                textAlign: "right",
                fontFamily: "Expletus Sans",
                fontStyle: "normal",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                textTransform: "none",
                letterSpacing: "0.1px",
                whiteSpace: "nowrap",
              },
            },
            "& .right-box": {
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingRight: "12px",

              "& .check-box": {
                flexDirection: "row",
                display: "flex",
                alignItems: "center",

                "& .chk-select-all": {
                  color: "#94A3B8",
                  height: "24px",
                  width: "24px",
                  marginRight: "6px",
                },
                "& .selected-txt": {
                  color: "rgba(0, 0, 0, 0.60)",
                  fontFamily: "Expletus Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "24px",
                  letterSpacing: "0.1px",
                  whiteSpace: "nowrap",
                },
              },
              "& .search-sort-box": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                "& .form-control": {
                  marginBottom: 0,
                  "& .MuiTextField-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    // height: "48px",
                    // marginRight: "68px",
                  },
                },
                "& .top-right": {
                  "& .select-control": {
                    "& .MuiInput-underline:before": {
                      display: "none",
                    },
                    "& .MuiInput-underline:after": {
                      display: "none",
                    },
                    "& .MuiInputBase-root": {
                      color: "rgb(109 106 106 / 87%)",
                      fontFamily: "Expletus Sans",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "24px",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  },
                },
              },
              "& .select-control": {
                "&.multi-select": {
                  "& .MuiInput-formControl": {
                    marginTop: "0",
                  },
                  "& .multiselect-label": {
                    display: "none",
                  },
                  "& .MuiSelect-select": {
                    paddingRight: "40px",
                  },
                  "& .multiple-clear": {
                    position: "absolute",
                    right: "18px",
                    fontSize: "15px",
                    zIndex: 1,
                    top: "3px",
                    color: "#94A3B8",
                  },
                },
                "& .MuiSelect-select": {
                  letterSpacing: "0.1px",
                  fontSize: "14px",
                  color: "#2b2b2b",
                  opacity: "0.671",
                  fontFamily: "Expletus Sans",
                  paddingBottom: "0",
                  fontWeight: "500",
                  paddingTop: "0",
                  paddingRight: "2px",
                  "&:focus": {
                    backgroundColor: "transparent",
                  },
                  "& .clear-icon": {
                    height: "50px",
                    background: "red",
                    width: "50px",
                  },
                  "& .MuiBox-root": {
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    "& .select-icon": {
                      marginRight: "6px",
                    },
                  },
                },
                "& .MuiInput-underline:after": {
                  display: "none",
                },
                "& .MuiInput-underline:before": {
                  display: "none",
                },
              },
              "& .filter-control": {
                "&.multi-select": {
                  "& .MuiInput-formControl": {
                    marginTop: "0",
                  },
                  "& .multiselect-label": {
                    display: "none",
                  },
                  "& .MuiSelect-select": {
                    paddingRight: "40px",
                  },
                  "& .multiple-clear": {
                    position: "absolute",
                    right: "18px",
                    fontSize: "15px",
                    zIndex: 1,
                    top: "3px",
                    color: "#94A3B8",
                  },
                },
                "& .MuiSelect-select": {
                  letterSpacing: "0.1px",
                  fontSize: "14px",
                  color: "#2b2b2b",
                  opacity: "0.671",
                  fontFamily: "Expletus Sans",
                  paddingBottom: "0",
                  fontWeight: "500",
                  paddingTop: "0",
                  paddingRight: "2px",
                  "&:focus": {
                    backgroundColor: "transparent",
                  },
                  "& .MuiBox-root": {
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "15px",
                    flexDirection: "row",
                    "& .select-icon": {
                      marginRight: "6px",
                    },
                  },
                },
                "& .MuiInput-underline:after": {
                  display: "none",
                },
                "& .MuiInput-underline:before": {
                  display: "none",
                },
              },
            },
          },
        },
        "& .main-content-wrapper": {
          display: "flex",
          flexDirection: "row",
          "& .left-wrapper": {
            display: "flex",
            flexDirection: "column",
            // width: "580px",
            // height: "50vh",

            "& .section-header": {
              display: "block",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottom: "1px solid #E8ECF2",
              marginBottom: "30px",
              "& .select-control": {
                "& .MuiSelect-select": {
                  paddingRight: "0",
                  "& .down-arrow": {
                    color: "rgba(43, 43, 43, 0.67)",
                  },
                },
              },
              "& .MuiAccordion-root": {
                borderRadius: "0",
                boxShadow: "none",
                // margin: "0 0 10px",
                "&:before": {
                  display: "none",
                },
                "& .MuiAccordionSummary-root": {
                  marginBottom: "28px",
                  padding: "0",
                  minHeight: "unset",
                  [theme.breakpoints.down("sm")]: {
                    // marginBottom: "16px",
                  },
                  "& .MuiAccordionSummary-content": {
                    margin: 0,
                  },
                  "& .MuiIconButton-root": {
                    marginRight: "0",
                    padding: "0",
                    "& .MuiSvgIcon-root": {
                      width: "20px",
                      height: "20px",
                      fontSize: "20px",
                      color: "#94A3B8",
                    },
                  },
                },
                "& .MuiAccordionDetails-root": {
                  flexDirection: "column",
                  padding: "0",
                  display: "flex",
                },
                "& .form-control": {
                  margin: "-16px 0px 8px 0",
                },
              },
              "& .section-title": {
                color: "var(--gray, rgba(43, 43, 43, 0.87))",
                fontFamily: "Expletus Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "0.1px",
              },
              "& .hide-content-section-btn": {
                margin: 0,
                minWidth: 24,
              },
            },
            "& .form-control": {
              "& .MuiTextField-root": {
                "& fieldset": { border: "none" },
                borderBottom: "1px solid #E8ECF2",
                height: "48px",
              },
            },
            "& .wrap-items": {
              "& .product-item": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // marginBottom: "8px",

                "& .chk-item": {
                  color: "#94A3B8",
                  marginRight: "8px",
                },
                "& .item-txt": {
                  color: " #2B2B2B",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "24px" /* 171.429% */,
                  letterSpacing: "0.25px",
                },
              },
            },
            "& .wrap-expand": {
              paddingLeft: "18px",
              "& .expand-list-btn": {
                textTransform: "none",
                color: "var(--brand-color-primary-100, #4BA3B7)",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "0.25px",
              },
            },
          },
          "& .right-wrapper": {
            display: "flex",
            flexDirection: "row",
            columns: 3,
            width: "calc(100vw - 580px)",
            height: "50vh",
            flex: 1,
            padding: "10px 0px 0px 0px",
            "& .grid-container": {
              "& .left-side-info": {
                paddingTop: "35px",
                borderRight: "1px solid #E8ECF2",
              },
              "& .right-side-info": {
                paddingLeft: "25px",
                // paddingTop: "40px",
              },
            },

            "& .product-list-item": {
              display: "flex",
              flexDirection: "column",
              flex: 1,
              border: "1px solid #E8ECF2",
              borderRadius: "12px",
              padding: "16px",
              minHeight: "203px",

              "& .item-top": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                "& .check-section": {
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "& .instock-quantity": {
                    color: "var(--Gray, rgba(43, 43, 43, 0.87))",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "16px",
                    letterSpacing: "0.09px",
                    background: "#F2F8FC",
                    borderRadius: "6px",
                    padding: "2px 8px",
                    whiteSpace: "nowrap",
                  },
                },
                "& .left-top": {
                  display: "flex",
                  flexDirection: "column",

                  "& .product-img": {
                    height: "68px",
                    width: "68px",
                    marginBottom: "25px",
                  },

                  "& .wrap-under-img": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    "& .dot-img": {
                      width: "6px",
                      height: "6px",
                      margin: "0 6px",
                    },
                    "& .star-icon": {
                      width: "12px",
                      height: "11.4px",
                      marginLeft: "6px",
                    },
                  },
                },

                "& .right-top-chk": {
                  color: "#94A3B8",
                },
              },
              "& .item-bottom-info": {
                marginTop: "25px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",

                "& .wrap-name": {
                  flexDirection: "column",
                  display: "flex",
                  "& .dot-section": {
                    display: "flex",
                    alignItems: "center",
                    "& .product-info": {
                      fontFamily: "Roboto",
                      color: "rgba(0, 0, 0, 0.38)",
                      fontSize: "12px",
                      fontWeight: "400",
                      fontStyle: "normal",
                      letterSpacing: "0.4px",
                      lineHeight: "normal",
                    },
                    "& .product-dot-info": {
                      color: "rgba(0, 0, 0, 0.38)",
                      fontSize: "12px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      letterSpacing: "0.4px",
                      marginLeft: "6px",
                      "&:before": {
                        content: '""',
                        width: "8px",
                        borderRadius: "100%",
                        height: "8px",
                        display: "inline-block",
                        background: "#ECF1F4",
                        marginRight: "8px",
                      },
                    },
                  },
                  "& .product-name-txt": {
                    fontFamily: "Roboto",
                    color: "var(--gray, rgba(43, 43, 43, 0.87))",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    margin: "6px 0",
                  },
                  "& .product-additional-info": {
                    color: "rgba(0, 0, 0, 0.38)",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    letterSpacing: "0.4px",
                  },
                },
                "& .wrap-price": {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  "& .price-btn": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    textTransform: "none",
                    marginTop: "20px",
                    "& .retail-price-txt": {
                      color: "rgba(0, 0, 0, 0.38)",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      letterSpacing: "0.4px",
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center",
                    },
                    "& .icon-down": {
                      width: "24px",
                      height: "24px",
                      color: "#94A3B8",
                    },
                  },
                  "& .price-txt": {
                    color: "var(--gray, rgba(43, 43, 43, 0.87))",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px" /* 150% */,
                    letterSpacing: "0.15px,",
                    marginRight: "8px",
                  },
                },
              },
            },
          },
        },
      },
    },

    drawerWrapper: {
      display: "flex",
      flexWrap: "wrap",
    },
    drawerRootWrapper: {
      "& .MuiBackdrop-root": {
        [theme.breakpoints.down("xs")]: {
          background: "rgba(34, 89, 128, 0.70)",
        },
      },
      "& .drawer-inner": {
        [theme.breakpoints.down("xs")]: {
          padding: "56px 24px",
        },
      },
    },
    drawer: {
      minWidth: 302,
      [theme.breakpoints.down("md")]: {
        minWidth: 123,
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    drawerPaper: {
      width: "100%",
      position: "static",
      borderRight: "0",
      [theme.breakpoints.down("xs")]: {
        width: "80%",
        position: "fixed",
      },
      "& .sidebar-heading": {
        display: "none",
        [theme.breakpoints.down("xs")]: {
          marginBottom: "48px",
          display: "flex",
          alignItems: "center",
        },
        "& .back-btn-wrap": {
          [theme.breakpoints.down("xs")]: {
            marginRight: "16px",
          },
        },
        "& .heading": {
          [theme.breakpoints.down("xs")]: {
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "30px",
            color: "#2b2b2b",
            fontFamily: "Expletus Sans",
          },
        },
      },
    },
    drawerContent: {
      flex: 1,
    },
    profileBlockOuter: {
      // maxWidth: "622px",
      width: "100%",
      margin: "0 auto",
      padding: "0 15px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0",
      },
      "&.mvc-profile-outer": {
        // minHeight: "85vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        flexWrap: "wrap",
        [theme.breakpoints.down("md")]: {
          // minHeight: "49vh",
        },
        [theme.breakpoints.down("sm")]: {
          // minHeight: "66.5vh",
        },
      },
      "& .profile-block-wrapper": {
        display: "flex",
        alignItems: "center",
        padding: "23px 40px",
        margin: "70px auto",
        "& .container-2": {
          marginLeft: "80px",
          "& .primary-btn": {
            width: "223px",
            fontSize: "14px",
            fontFamily: "Expletus Sans",
            fontWeight: "500",
            fontStyle: "normal",
            marginBottom: "16px",
          },
          "& .heading": {
            fontWeight: 400,
            fontSize: "20px",
            color: "#2B2B2B",
            fontFamily: "Expletus Sans",
            marginBottom: "12px",
          },
          "& .sub-heading": {
            fontWeight: 400,
            fontSize: "14px",
            color: "#94A3B8",
            fontFamily: "Roboto",
            marginBottom: "48px",
            width: "375px",
          },
        },
        "& .default-image": {
          width: "327px",
        },
        [theme.breakpoints.down("md")]: {
          marginBottom: "16px",
        },
        [theme.breakpoints.down("xs")]: {
          border: "0",
          borderRadius: "0",
          padding: "0 0 32px",
          borderBottom: "1px solid #E8ECF2",
          marginBottom: "32px",
        },
      },
    },
    status: {
      height: "8px",
      width: "8px",
      background: "#94A3B8",
      borderRadius: "100%",
    },
    inProgress: {
      background: "#F6A318",
    },
    orderPlaced: {
      background: "#2B2B2B",
    },
    shipped: {
      background: "#12AEF1",
    },
    delivered: {
      background: "#00BA88",
    },
    onHold: {
      background: "#C7263E",
    },
    viewOrderDetailsModal: {
      width: "560px",
      position: "relative",
      backgroundColor: "#ffffff",
      overflow: "auto",
      "& .view-container": {
        padding: "0px 40px",
        "& .header-container": {
          display: "flex",
          justifyContent: "space-between",
          "& .modal-title-box": {
            margin: "45px 0px",
            "& .title-txt": {
              color: "var(--brand-color-brand-color-dark, #2B2B2B)",
              fontFamily: "Expletus Sans",
              fontStyle: "normal",
              fontSize: "22px",
              fontWeight: "400",
              marginBottom: "8px",
              lineHeight: "26px",
            },
            "& .sub-title-txt": {
              color: "#505050",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontStyle: "normal",
              lineHeight: "16px",
              fontWeight: "500",
              letterSpacing: "0.09px",
            },
          },
          "& .heading-bar": {
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            // margin: "20px 20px 0px 0px",
            justifyContent: "end",
            cursor: "pointer",
            "& .close-icon": {
              height: "24px",
              width: "24px",
              color: "#959595",
              cursor: "pointer",
              marginTop: "-30px",
            },
          },
        },
        "& .order-heading-bar": {
          "& .order-details": {
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            "& .order-id": {
              color: "#94A3B8",
              fontSize: "12px",
              fontFamily: "Roboto",
              fontStyle: "normal",
              lineHeight: "16px",
              fontWeight: "400",
              letterSpacing: "0.09px",
            },
          },
          "& .status-item": {
            display: "flex",
            alignItems: "center",
            "& .status-name": {
              marginLeft: "10px",
              whiteSpace: "nowrap",
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontStyle: "normal",
              lineHeight: "16px",
              fontWeight: "500",
              letterSpacing: "0.09px",
              textTransform:"capitalize",
            },
          },
        },
        "& .time-date": {
          margin: "20px 0px",
          color: " #2B2B2B",
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontSize: "12px",
          fontWeight: "400",
        },
        "& .line": {
          margin: "20px 0",
          borderBottom: "1px solid #E8ECF2",
        },
        "& .quantity-text": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "& .item": {
            marginTop: "20px",
            color: " #2B2B2B",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontSize: "12px",
            fontWeight: "400",
          },
          "& .price": {
            marginTop: "20px",
            color: " #2B2B2B",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontSize: "12px",
            fontWeight: "600",
          },
        },
        "& .cancle-order-txt": {
          border: "1px solid #E8ECF2",
          borderRadius: "12px",
          marginBottom: "40px",
          "& .contact-support": {
            color: " #2B2B2B",
            fontFamily: "Expletus Sans",
            fontStyle: "normal",
            fontSize: "14px",
            fontWeight: "500",
            padding: "15px",
            "& .link-txt": {
              fontSize: "14px",
              color: "#4BA3B7",
              fontFamily: "Expletus Sans",
              paddingBottom: "0",
              fontWeight: "500",
              whiteSpace: "nowrap",
              // borderBottom: "1px solid #4BA3B7",
              width: "70px",
              cursor: "pointer",
            },
          },
        },
        "& .primary-btn": {
          width: "100%",
          fontSize: "14px",         
          borderRadius: "10px",
          marginBottom: "20px",
        },
        "& .secondary-btn": {
          width: "100%",
          fontSize: "14px",
          fontFamily: "Expletus Sans",
          fontWeight: "500",
          fontStyle: "normal",
          marginBottom: "24px",
          marginTop: "48px",
        },
        "& .section-header": {
          display: "block",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #E8ECF2",
          padding: "20px",
          "& .select-control": {
            "& .MuiSelect-select": {
              paddingRight: "0",
              "& .down-arrow": {
                color: "rgba(43, 43, 43, 0.67)",
              },
            },
          },
          "& .MuiAccordion-root": {
            borderRadius: "0",
            boxShadow: "none",
            // margin: "0 0 10px",
            "&:before": {
              display: "none",
            },
            "& .MuiAccordionSummary-root": {
              marginBottom: "28px",
              padding: "0",
              minHeight: "unset",
              [theme.breakpoints.down("sm")]: {
                // marginBottom: "16px",
              },
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
              "& .MuiIconButton-root": {
                marginRight: "0",
                padding: "0",
                "& .MuiSvgIcon-root": {
                  width: "20px",
                  height: "20px",
                  fontSize: "20px",
                  color: "#94A3B8",
                },
              },
            },
            "& .MuiAccordionDetails-root": {
              flexDirection: "column",
              padding: "0",
              display: "flex",
            },
            "& .form-control": {
              margin: "-16px 0px 8px 0",
            },
          },
          "& .accordion-details": {
            "& .quantity-text": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              "& .item": {
                marginTop: "10px",
                color: " #94A3B8",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "12px",
                fontWeight: "400",
              },
              "& .price": {
                marginTop: "10px",
                color: " #2B2B2B",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "12px",
                fontWeight: "600",
              },
            },
            "& .margin-bottom": {
              margin: "20px",
            },

            "& .wrap-items": {
              display: "flex",
              "& .margin-bottom": {
                marginBottom: "20px",
              },
              "& .check-line": {
                display: "block",
                "& .line": {
                  borderRight: "2px dashed #4ba3b7",
                  width: "8px",
                  height: "29px",
                },
              },
              "& .color": {
                color: "#4ba3b7",
                width: "16px",
                height: "16px",
              },
              "& .order-container": {
                marginLeft: "30px",
                display: "block",
                "& .order-process": {
                  color: " #2B2B2B",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontSize: "12px",
                  fontWeight: "400",
                },
                "& .date": {
                  color: "#94A3B8",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontSize: "10px",
                  fontWeight: "400",
                },
              },
            },
          },
        },
      },
    },
    fillOrderDetailsModal: {
      width: "560px",
      backgroundColor: "#ffffff",
      position: "relative",
      overflow: "auto",
      "& .button-container":{
        padding: "0px 40px",
        position: "absolute",
        bottom: 0,
        top:"100%",
        width: "100%",
        "& .primary-btn": {
          width: "100%",
          fontSize: "14px",
          fontFamily: "Expletus Sans",
          fontWeight: "500",
          fontStyle: "normal",
          marginBottom: "24px",
          marginTop: "24px",          
        },      
      },
      "& .view-container": {
        padding: "0px 40px",
        "& .line": {
          marginTop: "20px",
          borderBottom: "1px solid #E8ECF2",
        },
        "& .product-container":{
          display:"flex",
          alignItems:"center",
          margin: "20px 0",         
          "& .title-container":{
            marginLeft:"20px",
            "& .title":{
              fontSize: "14px",
              lineHeight: "24px",
              color: "#2B2B2B",
              letterSpacing: "0.15px",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
            },
            "& .sub-title":{
              fontSize: "10px",
              lineHeight: "24px",
              color: "#94A3B8",
              letterSpacing: "0.15px",
              fontFamily: "Roboto",
              fontWeight: 400,
            },
          },
          "& .price-container": {
            "& .price-item-txt": {
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontStyle: "normal",
              lineHeight: "16px",
              fontWeight: "500",
              letterSpacing: "0.09px",
              marginBottom: "10px",
              position: "absolute",
              right: "52px",
              marginTop: "-20px",
            },
          },
          "& .image": {
            width: "56px",
            height: "56px",
          },
        },
        "& .product-container-2":{
          display:"flex",
          alignItems:"center",
          margin: "20px 0",
          border: "1px solid #E8ECF2",  
          borderRadius:"12px", 
          padding:"20px",      
          "& .title-container":{
            marginLeft:"20px",
            "& .title":{
              fontSize: "14px",
              lineHeight: "24px",
              color: "#2B2B2B",
              letterSpacing: "0.15px",
              fontFamily: "Expletus Sans",
              fontWeight: 500,
            },
            "& .sub-title":{
              fontSize: "10px",
              lineHeight: "24px",
              color: "#94A3B8",
              letterSpacing: "0.15px",
              fontFamily: "Roboto",
              fontWeight: 400,
            },
          },
          "& .price-container": {
            "& .price-item-txt": {
              color: "#2B2B2B",
              fontSize: "14px",
              fontFamily: "Expletus Sans",
              fontStyle: "normal",
              lineHeight: "16px",
              fontWeight: "500",
              letterSpacing: "0.09px",
              marginBottom: "10px",
              position: "absolute",
              right: "52px",
              marginRight: "15px",
            },
          },
          "& .image": {
            width: "56px",
            height: "56px",
          },
        },
        "& .file-info": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          "& .file-name": {
            fontSize: "16px",
            lineHeight: "24px",
            color: "rgba(0, 0, 0, 0.87)",
            letterSpacing: "0.15px",
            fontFamily: "Roboto",
            fontWeight: 400,
          },
          "& .close": {
            color: "#959595",
            cursor: "pointer",
          },
        },
        "& .primary-btn": {
          width: "100%",
          fontSize: "14px",
          fontFamily: "Expletus Sans",
          fontWeight: "500",
          fontStyle: "normal",
          marginBottom: "24px",
          marginTop: "24px",
        },
        "& .primary-btn-block":{
          display:"none",            
        },
        "& .secondary-btn": {
          width: "100%",
          fontSize: "14px",
          fontWeight: "500",
          fontFamily: "Expletus Sans",
          fontStyle: "normal",
          marginTop: "48px",
        },
        "& .attachment-img": {
          width: "20px",
          height: "20px",
          cursor: "pointer",
        },
        "& .header-container": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& .modal-title-box": {
            display: "flex",
            margin: "45px 0px",
            "& .back-image": {
              width: "24px",
              height: "24px",
              cursor:"pointer",
            },
            "& .title-txt": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontStyle: "normal",
              fontSize: "14px",
              fontWeight: "500",
              marginLeft: "8px",
              lineHeight: "26px",
            },
          },
          "& .heading-bar": {
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            // margin: "20px 20px 0px 0px",
            justifyContent: "end",
            cursor: "pointer",
            "& .stepper-count":{
              color: "#94A3B8",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontStyle: "normal",
              marginRight: "8px",
              fontWeight: "400",
              lineHeight: "26px",
            },
            "& .close-icon": {
              height: "24px",
              width: "24px",
              color: "#959595",
              cursor: "pointer",
            },
          },
        },
        "& .search-box": {
          margin: "15px 0",
          "& .MuiOutlinedInput-root":{
            borderRadius: "12px",
            marginLeft: "-4px",
          },
          "& .MuiOutlinedInput-input":{
            marginLeft: "16px",
          },
  
        },
        "& .header-container-2": {
          display: "flex",
          justifyContent: "space-between",
          marginTop: "30px",         
          "& .heading-bar": {
            alignItems: "center",
            display: "flex",
            flexDirection: "row",           
            justifyContent: "end",
            cursor: "pointer",           
            "& .close-icon": {
              height: "24px",
              width: "24px",
              color: "#959595",
              cursor: "pointer",
              marginTop: "-30px",
            },
          },
          "& .modal-title-box": {
            "& .title-txt": {
              color: "#2B2B2B",
              fontFamily: "Expletus Sans",
              fontSize: "24px",
              fontStyle: "normal",
              marginBottom: "8px",
              fontWeight: "400",
              lineHeight: "26px",
            },
            "& .title-sub-txt": {
              color: "#94A3B8",
              fontSize: "12px",
              fontFamily: "Roboto",
              fontStyle: "normal",
              lineHeight: "16px",
              fontWeight: "400",
              letterSpacing: "0.09px",
              marginBottom: "20px",
            },
          },
        },
        "& .title-sub-txt": {
          color: "#2B2B2B",
          fontSize: "12px",
          fontFamily: "Roboto",
          fontStyle: "normal",
          lineHeight: "16px",
          fontWeight: "400",
          letterSpacing: "0.09px",
        },
        "& .radioFormControl": {
          display: "block",
          marginBottom: "15px",
          "& .MuiFormControlLabel-root": {
            marginLeft: "-22px",
            "& .MuiFormControlLabel-label": {
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0.25px",
              textTransform: "capitalize",
            },
          },
          "& .MuiRadio-root": {
            color: "#94A3B8",
            padding: "7px",
            marginLeft: "12px",
            "&:hover": {
              backgroundColor: "rgba(54, 145, 166, 0.04)",
            },
            "&.Mui-checked": {
              color: "#4ba3b7",
            },
            "& .MuiSvgIcon-root": {
              width: "20px",
              height: "20px",
            },
          },
        },
        "& .checkbox-container": {
          display: "flex",
          marginBottom: "15px",
          "& .checkboxFormControl": {
            display: "block",
            marginBottom: "15px",
            "& .MuiFormControlLabel-label": {
              color: "#2B2B2B",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0.25px",
              textTransform: "capitalize",
            },
            "& .MuiFormControlLabel-root": {
              marginLeft: "-22px",
              "& .MuiFormControlLabel-label": {
                color: "#2B2B2B",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "24px",
                letterSpacing: "0.25px",
              },
            },
            "& .MuiCheckbox-root": {
              color: "#94A3B8",
              padding: "7px",
              // marginLeft: "12px",
              "&:hover": {
                backgroundColor: "rgba(54, 145, 166, 0.04)",
              },
              "&.Mui-checked": {
                color: "#4ba3b7",
              },
              "& .MuiSvgIcon-root": {
                width: "20px",
                height: "20px",
              },
            },
          },
        },
        "& .questions-txt": {
          color: "#2B2B2B",
          fontSize: "14px",
          fontFamily: "Roboto",
          fontStyle: "normal",
          lineHeight: "16px",
          fontWeight: "400",
          letterSpacing: "0.09px",
          marginBottom: "20px",
        },
        "& .price-container": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& .price-item-txt": {
            "& .sortby-control": {
              "& .MuiSelect-select": {
                paddingRight: "0",
              },
              "& .select-icon": {
                top: "3px",
              },
            },
            "& .select-control": {
              "&.multi-select": {
                "& .multiselect-label": {
                  display: "none",
                },
                "& .MuiInput-formControl": {
                  marginTop: "0",
                },
                "& .MuiSelect-select": {
                  paddingRight: "40px",
                },
                "& .multiple-clear": {
                  position: "absolute",
                  fontSize: "15px",
                  right: "18px",
                  color: "#94A3B8",
                  top: "3px",
                  zIndex: 1,
                },
              },
              "& .MuiSelect-select": {
                fontSize: "14px",
                letterSpacing: "0.1px",
                color: "#2b2b2b",
                fontFamily: "Expletus Sans",
                opacity: "0.671",
                fontWeight: "500",
                paddingBottom: "0",
                paddingTop: "0",
                "&:focus": {
                  backgroundColor: "transparent",
                },
                "& .clear-icon": {
                  width: "50px",
                  height: "50px",
                  background: "red",
                },
                "& .MuiBox-root": {
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  "& .select-icon": {
                    marginRight: "6px",
                  },
                },
              },
              "& .MuiInput-underline:before": {
                display: "none",
              },
              "& .MuiInput-underline:after": {
                display: "none",
              },
            },
            "& .dropdown-sort":{
              marginTop: 0,
              marginLeft:"-34px", 
              [theme.breakpoints.down("sm")]: {
                marginTop: "0",
                marginLeft:"-34px", 
              },
            },
            color: "#2B2B2B",
            fontSize: "12px",
            fontFamily: "Expletus Sans",
            fontStyle: "normal",
            lineHeight: "16px",
            fontWeight: "500",
            letterSpacing: "0.09px",
            marginBottom: "10px",
          },
        },
      },
    },
    popoverStyle: {
      maxWidth: "150px",
      width: "100%",
      borderRadius: "12px",      
      boxShadow: "0px 1px 2px 0px rgb(191 186 186 / 22%)",
      margin: "-12px 33px",     
      "& .MuiMenuItem-root": {
        fontSize: "14px",
        letterSpacing: "0",
        lineHeight: "20px",
        color: "#2B2B2B",
        padding: "16px 16px 12px 25px",
        fontFamily: "Expletus Sans",
        fontWeight: "500",
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        whiteSpace: "normal",
        "& .MuiTypography-root": {
          letterSpacing: "0",
          font: "inherit",
        },
      },     
    },
  });
// Customizable Area End
