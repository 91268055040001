// Customizable Area Start
import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import {
  useStyles,
  createData,
  GroupContactHead,
} from "./CustomContactsTable.web";
import CustomContactTableItems from "./CustomContactTableItems.web";
import { deleteOutline } from "./assets";



export const configJSON = require("./config");


export default function ContactsList(props: any) {
  const {
    renderMembers,
    hideActionColumn,
    showDeleteInMembers,
    deleteMember,
    getSelectedRecords,
    multipleSelected,
    userRole,
    multipleDelete,
    showDifferentLabel,
  } = props;

  let rows: any;
  rows = renderMembers.map((member: any) => {
    const name = member.attributes.first_name + " " + member.attributes.last_name
    return createData(
      member.id,
      name,
      member.attributes.email,
      member.attributes.phone,
      member.attributes.group,
      member.attributes.company_name,
      member.attributes.business_category,
      5.4
    );
  });
  const classes = useStyles();
  const [selected, setSelected] = React.useState<any[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(configJSON.RowsPerPage);

  useEffect(() => {
    if (
      getSelectedRecords !== null &&
      typeof getSelectedRecords === "function"
    ) {
      getSelectedRecords(selected);
    }
    if (multipleSelected) {
      multipleSelected(selected);
    }
  }, [selected]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => (n.memId ? n.memId : n.name));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.find((x: any) => x == name);

    let newSelected: any[] = [];

    if (selectedIndex == null) {
      newSelected = [...selected, name];
    } else {
      newSelected = [...selected.filter((x: any) => x != name)];
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };


  const selectionMessage =  selected.length > 0
    ? selected.length === 1
      ? "1 Contact Selected"
      : `${selected.length} Contacts Selected`
    : `${rows.length} ${rows.length === 1 ? "Contact" : "Contacts"}`

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: any) => selected.indexOf(name) !== -1;


  let tableClass;
  if (userRole === "manager") {
    tableClass = "mem-list-manager"
  } else if (userRole === "employee") {
    tableClass = "mem-list-employee"
  }

  return (
    <div className={classes.root}>
      <Box>
        <Box className={classes.paginationToolbar}>
          <Box className="total-member">
            <Box className="member-count">
              {!hideActionColumn && (
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < rows.length
                  }
                  checked={rows.length > 0 && selected.length === rows.length}
                  onChange={handleSelectAllClick}
                />
              )}
              {selectionMessage}
              {selected.length > 0 && (
                <Box
                  className="delete-icon"
                  onClick={() => {multipleDelete("contacts")}}
                  data-testid="delete-icon"
                >
                  <img src={deleteOutline} alt="deleteOutline" />
                </Box>
              )}
            </Box>
          </Box>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.paginationTable}
            {...(showDifferentLabel && {
              labelDisplayedRows: ({ count }) => `${count} of ${count}`,
            })}
          />
        </Box>
        <TableContainer>
          <Table
            className={`${`view-memberList ${tableClass}`} ${classes.table}`}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <GroupContactHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              hideActionColumn={hideActionColumn}
              userRole={userRole} />
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => {
                  const isItemSelected = isSelected(
                    row.memId ? row.memId : row.name
                  );
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event: any) =>
                        handleClick(event, row.memId ? row.memId : row.name)
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.memId}
                      selected={isItemSelected}
                    >
                      <CustomContactTableItems
                        classes={classes}
                        memId={row.memId}
                        contactName={row.name}
                        email={row.email}
                        phone={row.phone}
                        group={row.group}
                        company_name={row.company_name}
                        business_category={row.business_category}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        hideActionColumn={hideActionColumn}
                        showDeleteInMembers={showDeleteInMembers}
                        deleteAction={deleteMember}
                        userRole={userRole}
                      />
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
// Customizable Area End