Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "BulkUploading";
exports.labelBodyText = "BulkUploading Body";

exports.txtBulkUploadTitle = "Bulk upload";
exports.txtBulkUploadTitle1 = "Bulk Upload";
exports.txtBulkUploadBody = "Download the template, fill it out, then upload\nthe completed CSV file";
exports.btnDownloadTemplate = "Download Template";
exports.txtSelectFile = "Select File"
// exports.modalDefaultErrorMessage = "File type is not correct. Please upload only a .csv file"
exports.modalDefaultErrorMessage = "Incorrect file type. Please download the template provided"

exports.baseURL = require("../../../framework/src/config"); 
exports.importCsvEndPoint = "/account_block/import_csv"
exports.exportCsvEndPoint = "/account_block/export_csv"
exports.createTeamsEndPoint = "/account_block/multiple_teams"
exports.createJobTitlesEndPoint = "/account_block/multiple_titles"
exports.uploadApiMethod = "POST"

exports.exportCsvDriveLink = "https://drive.google.com/uc?id=1hgvLjcJdV0l_IivTByc4_HfIdIdi-Wth&export=download"

exports.totalLicenses = "350 Licenses | 10 unused"
exports.buyLicenses = "Buy Licenses"

exports.accentColor = '#4BA3B7'
exports.txtHeaderCostNotice = "Cost is dependent on the number of users."
exports.txtReupload = "Reupload"
exports.txtConfirmAndPay = "Confirm & Pay"
exports.txtFilters = "Filters"
exports.txtReset = "Reset"
exports.txtChooseRole = "Choose Role"
exports.txtOwner = "Owner"
exports.txtManager = "Manager"
exports.txtEmployee = "Employee"
exports.txtJobTitle= "Job Title"
exports.txtShowDeactivated= "Show only deactivated members"
exports.txtApplyFilters= "Apply Filters"
exports.txtPlaceholderSearchJobTitle = "Search Job Title"

exports.btnExampleTitle = "CLICK ME";

//web
exports.webBulkDownloadEndPoint = "account_block/export_csv";
exports.webBulkImportCsvEndPoint = "account_block/import_csv";
exports.webCreateJobTitlesEndPoint = "/account_block/multiple_titles";
exports.webCreateTeamsEndPoint = "/account_block/multiple_teams";
exports.webDownloadCsvTemplateEndPoint = "/account_block/download_template";
exports.webFilterEmailBulkUpload = "/account_block/filter_emails_bulkupload";
exports.webDownloadInventoryTemplateEndPoint = "/bx_block_catalogue/download_inventory_template";
exports.webDownloadInventoryMasterTemplateEndPoint = "/bx_block_catalogue/download_sample_input_data";
exports.webInventoryBulkUpload ="/bx_block_catalogue/bulk_upload";
exports.validateBulkUploadFile ="/bx_block_catalogue/validate_import_file";

exports.completeTxt="Complete";
exports.previewTxt="Preview";
exports.finishTxt="Finish";
exports.stepTxt="Step";
exports.noTeamsFound="No teams found";
exports.noMembersFoundTxt="No members found";
exports.downloadTemplateTxt="Download the template, fill it out, then upload the completed CSV file";
exports.fileDropTxt="Drop files here to upload";
exports.orTxt="or";
exports.browseTxt="browse";
exports.chooseFileTxt="to choose a file";
exports.uploadingTxt="Uploading";
exports.secondsRemainingTxt="Seconds remaining";
exports.downloadTemplatesTxt="Download Template";
exports.continoueTxt="Continue";
exports.contactFoundTxt="contacts found";
exports.constIsDependantTxt="Cost is dependent on the number of users.";
exports.searchHearTxt="Search here1";
exports.searchHereToTypeTxt="Start typing to search...";
exports.sortByTxt="Sort By";
exports.reuploadTxt="Reupload";
exports.confirmAndPayTxt="Confirm and Pay";
exports.unknownStepTxt="Unknown step";
exports.deleteMsg="Are you sure you want to delete members?";
exports.deleteMemberTxt="Delete member";
exports.deleteFile="Delete File";
exports.confirmFileRemoval= "Selecting a new file will remove the current file {{fileName}}. Continue?";
exports.finishMsg="All steps completed - you're finished";
exports.errorMsg="Upload complete. Only new members added. Existing email IDs have been skipped.";
exports.emailInvalidMsg="Email already exists. Please choose other file";
exports.somethingWentWrongMsg="Something went wrong"
// Customizable Area End