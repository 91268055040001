import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { WithStyles } from "@material-ui/core/styles";
import { styles } from "./EmailAccountLoginBlock.web";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof styles> {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  password: string;
  isPasswordVisible: boolean;
  error: {
    emailError: {
      message: string;
      visible: boolean;
    };
    passwordError: {
      message: string;
      visible: boolean;
    };
  };
  forgotPasswordStatus: {
    reset_password_token: string;
    email: string;
    isPasswordReset: boolean;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginApiID: string = "";
  forgotPasswordApiID: string = "";
  resetPasswordApiID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      email: "",
      password: "",
      isPasswordVisible: false,
      error: {
        emailError: {
          message: "",
          visible: false,
        },
        passwordError: {
          message: "",
          visible: false,
        },
      },
      forgotPasswordStatus: {
        reset_password_token: "",
        email: "",
        isPasswordReset: false,
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId != null) {
        // user is logged in
        if (apiRequestCallId === this.loginApiID) {
          if (responseJson.errors) {
            const msg = responseJson.errors[0].failed_login;
            if (msg.toLowerCase().includes("password")) {
              this.setState({
                error: {
                  ...this.state.error,
                  passwordError: {
                    message: msg,
                    visible: true,
                  },
                },
              });
            } else {
              this.setState({
                error: {
                  ...this.state.error,
                  emailError: {
                    message: responseJson.errors[0].failed_login,
                    visible: true,
                  },
                },
              });
            }
          } else {
            sessionStorage.setItem("userData", JSON.stringify(responseJson));
            localStorage.setItem("userData", JSON.stringify(responseJson));
            this.props.navigation.navigate("/dashboard");
          }
        }
        // forgot password
        else if (apiRequestCallId === this.forgotPasswordApiID) {
          if (responseJson.error) {
            this.setState({
              error: {
                ...this.state.error,
                emailError: {
                  message: responseJson.error,
                  visible: true,
                },
              },
            });
          } else {
            this.setState({
              forgotPasswordStatus: {
                reset_password_token: responseJson.account.reset_password_token,
                email: responseJson.account.email,
                isPasswordReset: false,
              },
            });
          }
        } else if (apiRequestCallId === this.resetPasswordApiID) {
          if (responseJson.status === "ok") {
            this.setState({
              forgotPasswordStatus: {
                ...this.state.forgotPasswordStatus,
                isPasswordReset: true,
              },
            });
          } else {
            this.setState({
              error: {
                ...this.state.error,
                passwordError: {
                  message: responseJson.error,
                  visible: true,
                },
              },
            });
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (
      prevState.email !== this.state.email ||
      prevState.password !== this.state.password
    ) {
      this.resetErrors();
    }
  }

  togglePasswordVisibility = () => {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
  };

  validateEmail = () => {
    return this.state.email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  resetErrors = () => {
    this.setState({
      error: {
        emailError: {
          message: "",
          visible: false,
        },
        passwordError: {
          message: "",
          visible: false,
        },
      },
    });
  };

  isContainsUpperCase = () => /[A-Z]/.test(this.state.password);
  isContainsLowerCase = () => /[a-z]/.test(this.state.password);
  isContainsNumber = () => /[0-9]/.test(this.state.password);

  // Customizable Area End

  // Customizable Area Start
  handleLogin = () => {
    if (this.state.email.trim() === "" && this.state.password.trim() === "") {
      this.setState({
        error: {
          emailError: { message: "Email is required", visible: true },
          passwordError: { message: "Password is required", visible: true },
        },
      });
      return;
    }
    if (!this.validateEmail()) {
      this.setState({
        error: {
          emailError: { message: "Email is not valid", visible: true },
          passwordError: { message: "", visible: false },
        },
      });
      return;
    }
    if (this.state.password.trim() === "") {
      this.setState({
        error: {
          emailError: { message: "", visible: false },
          passwordError: { message: "Password is required", visible: true },
        },
      });
      return;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    let deviceToken = localStorage.getItem("device_token");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          type: "email_account",
          attributes: {
            email: this.state.email,
            password: this.state.password,
            device_token: deviceToken,
          },
        },
      })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleRecoverPassword = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();

    if (
      !this.state.forgotPasswordStatus.reset_password_token &&
      !this.validateEmail()
    ) {
      this.setState({
        error: {
          emailError: { message: "Invalid Email", visible: true },
          passwordError: { message: "", visible: false },
        },
      });
      return;
    }

    if (
      this.state.forgotPasswordStatus.reset_password_token &&
      (!this.isContainsLowerCase() ||
        !this.isContainsUpperCase() ||
        !this.isContainsNumber() ||
        this.state.password.length < 8)
    ) {
      this.setState({
        error: {
          emailError: { message: "", visible: false },
          passwordError: { message: "Invalid Password", visible: true },
        },
      });
      return;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (this.state.forgotPasswordStatus.reset_password_token) {
      this.resetPasswordApiID = requestMessage.messageId;
    } else {
      this.forgotPasswordApiID = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.forgotPasswordStatus.reset_password_token
        ? configJSON.resetPasswordApiEndpoint
        : configJSON.forgotPasswordApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    const body = this.state.forgotPasswordStatus.reset_password_token
      ? JSON.stringify({
          data: {
            attributes: {
              email: this.state.email,
              token: this.state.forgotPasswordStatus.reset_password_token,
              password: this.state.password,
            },
          },
        })
      : JSON.stringify({
          data: {
            attributes: {
              email: this.state.email,
            },
          },
        });

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
