export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const noTimesheetImage = require("../assets/no-timesheet.png");
export const menu = require("../assets/menu.png");
export const closeGray = require("../assets/close-gray.png");
export const minimizeGray = require("../assets/minimize-gray.png");
export const checkBoxEmpty = require("../assets/check-empty.png");
export const checkBoxFilled = require("../assets/check-filled.png");
export const minimizeTimesheet = require("../assets/minimizeTimesheet.svg");
export const arrowDropdown = require("../assets/arrow-down.svg");
