// Customizable Area Start
import React from "react";
import { Box, Typography, Button, CardMedia } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import {
  logoEtOHSuite,
  appStore,
  googlePlay,
  openInNew,
  shareScoreAWithLotus,
  shareScoreB,
  shareScoreC,
  shareScoreD,
  shareScoreE,
} from "./assets";
import * as htmlToImage from 'html-to-image';


interface ShareQuizScoreProps {
  classes: any; 
  modalOpen: boolean;
  modalClose: () => void;
  gradeScore: string;
  fullDetails:any;
  t:(key:string) => React.ReactNode;
}

const ShareQuizScore = (props: ShareQuizScoreProps) => {
  const { classes, modalOpen, modalClose, gradeScore , fullDetails,t} = props;
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  let shareGradeImg;
  if (gradeScore === "A") {
    shareGradeImg = shareScoreAWithLotus;
  } else if (gradeScore === "B") {
    shareGradeImg = shareScoreB;
  } else if (gradeScore === "C") {
    shareGradeImg = shareScoreC;
  } else if (gradeScore === "D") {
    shareGradeImg = shareScoreD;
  } else if (gradeScore === "E") {
    shareGradeImg = shareScoreE;
  }

 const handleDownload = () => {
  const sharingBox = document.getElementById('sharing-box');
  if(sharingBox){
  htmlToImage.toPng(sharingBox, { quality: 0.95 })
  .then(function (dataUrl:any) {
    let link = document.createElement('a');
    link.download = 'myscore.png';
    link.href = dataUrl;
    link.click();
  });
 }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modalDialog}
    >
      <Box className={classes.modalSharingScoreBox}>
        <Box className="modal-heading">
          <Link to="#" className="close-icon" onClick={modalClose}>
            <CloseIcon className="icon-close" />
          </Link>
        </Box>

        <Box className="sharing-content">
            <Box className="sharing-box" id="sharing-box">
            <Box className="top-box">
              <CardMedia
                component="img"
                image={logoEtOHSuite}
                className="logo"
              />
              <div className="sub-txt-container">
              <Typography className="sub-txt">I took this E-Quiz</Typography>
              <Typography className="primary-txt">
               {fullDetails?.quiz?.data?.attributes?.title}
              </Typography>
              <Typography className="sub-txt">& I got this E-Score</Typography>
              </div>
              <Box className="box-score">
                {shareGradeImg && shareGradeImg === shareScoreAWithLotus ? (
                  <CardMedia
                    component="img"
                    image={shareGradeImg}
                    className="lotus-img"
                  />
                ) : (
                  <CardMedia
                    component="img"
                    image={shareGradeImg}
                    className="grade-img"
                  />
                )}
              </Box>
            </Box>
            <Box className="score-info">
              <Typography className="score-date">{formatDate(fullDetails.quiz.data.attributes.updated_at)}</Typography>
              <Typography className="heading">
              {fullDetails?.quiz?.data?.attributes?.title}
              </Typography>
              <Typography className="para">
              {fullDetails?.quiz?.data?.attributes?.caption}
              </Typography>
            </Box>
            <Box className="refer-box">
              <Typography className="main-text">
                {t("What’s your company’s E-Score?")}
              </Typography>
              <Box className="download-app">
                <Typography className="sub-text">{t("Download app")}</Typography>
                <CardMedia
                  component="img"
                  image={appStore}
                  className="img-appstore"
                />
                <CardMedia
                  component="img"
                  image={googlePlay}
                  className="img-appstore"
                />
                <Typography className="sub-text ml-15">OR</Typography>
                <CardMedia
                  component="img"
                  image={openInNew}
                  className="img-appstore"
                />
                <Typography className="sub-text ml-15">
                  {t("etoh.digital/suite")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="share-box">
            <Typography className="share-title">{t("Share it on Social")}</Typography>
            <Typography className="share-sub-title">
              Simply download your E-Score and share it on your social channels or email it directly to your audience.
            </Typography>
            <Typography className="mb-text-score-infor">
              Simply download your E-Score and share it on your social channels or email it directly to your audience.
            </Typography>
          </Box>
          <Box className="sharing-bottom-view">
            <Button className={`secondary-btn ${classes.secondaryButton}`}>
              {t("Share via Email")}
            </Button>
            <Button
              className={`primary-btn ${classes.primaryButton} secondary-bottom-btn`}
              data-testid="nextBtn"
              onClick={handleDownload}
            >
              {t("Download & Share")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ShareQuizScore;
// Customizable Area End
