import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {getCurrentTime} from "../../../components/src/ReusableFunctions";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes: any;
  taskDetails: any;
  viewTaskHandler: (taskId:number) => void;
  type: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  time: any;
  timerRunning: boolean;
  totalTime: any;
  timer:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class TimetrackingbillingControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateTaskProgressAPICallId: string = "";
  timer:any;
  userSessionData:any;
  userToken: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];
    
    this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");

    this.state = {
      // Customizable Area Start
      time:this.props.taskDetails?.attributes?.duration,
      timerRunning: false,
      totalTime: this.props.taskDetails?.attributes?.hours,
      timer: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorResponse || !responseJson || !apiRequestCallId) {
        return;
      }
      if(apiRequestCallId === this.updateTaskProgressAPICallId){
        console.log(responseJson);
        this.handleStartPause()
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    let durations, difference, formatDuration;
    const {taskDetails} = this.props;
    let currentTime = getCurrentTime();
    if(taskDetails?.attributes?.current_status === "started" ){
      this.setState({timerRunning : true})
      let convertCurrentTime:any = new Date(currentTime)
      let convertStartedTime:any= new Date(taskDetails.attributes.started_at)
      const timeDifferenceInMilliseconds:any = convertCurrentTime - convertStartedTime;
      durations = taskDetails.attributes.duration + (timeDifferenceInMilliseconds/1000);
      //formatDuration = durations.toFixed();
      this.setState({
        time : durations
      })
      this.handleStartPause();
    }
    
  }
  hoursToSeconds =  (hours:any) => {
    const [h, m, s] = hours.split(':').map(Number);
    const totalSeconds = (h * 3600) + (m * 60) + (s || 0);
    return totalSeconds;
   
  }
  startTaskTimerAPI = () => {
    const { timerRunning } = this.state;
    const timestamp = getCurrentTime();
    let actionType;
    if(timerRunning){
      actionType = 'stopped'
    }else{
      actionType = 'started'
    }
    
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
      const body = {
        "data":{
           "task_id": this.props.taskDetails.id,
           "status_updated_at": timestamp,
           "action_type": actionType // stopped //started
      
       }
    } 
    
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateTaskProgressAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTaskProgress
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    //* Making Network Request
    //this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleStartPause = () => {
    const { timerRunning } = this.state;
    let interval;
    if (timerRunning) {
      clearInterval(this.timer);
    } else {
      this.timer = setInterval(() => {
        this.setState((prevState) => ({ time: prevState.time + 1 }));
      }, 1000);
    }
    this.setState({ timerRunning: !timerRunning });
  }

  // Customizable Area End
}
