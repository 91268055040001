// Customizable Area Start
import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  Checkbox,
  Button,
} from "@material-ui/core";
import CustomInputContent from "./CustomInput.web";
import { configJSON } from "../../blocks/ContactsList/src/ProductListController.web";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { checked } from "../../blocks/ContactsList/src/assets";

interface SectionProps {
  title: string;
  itemList: any;
  handleChecked: (id: string, name?: any) => void;
  selectedCheckBox: any;
  name: any;
  t: any;
}

const Section: React.FC<SectionProps> = ({
  name,
  t,
  title,
  itemList,
  handleChecked,
  selectedCheckBox
}) => {
  const [expanded, setExpanded] = useState(true);

  const handleExpandToggle = () => {
    setExpanded(!expanded);
  };
  return (
    <Box className="section-header">
      <Accordion data-test-id="accordian" expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#94A3B8" }} />}
          id="panel1a-header"
          aria-controls="panel1a-content"
          onClick={handleExpandToggle}
        >
          <Typography className="section-title">{t(`${title}`)}</Typography>
        </AccordionSummary>
        <AccordionDetails
          className="accordion-details"
          data-test-id="accordionDetails"
        >
          <Box className="wrap-items">
            {itemList
              .filter((item: any) => item.name !== null)
              .map((item: any) => {
                const { name, id } = item;
                return (
                  <Box className="product-item" key={id}>
                    <Checkbox
                      className="chk-item"
                      checked={selectedCheckBox === id}
                      onChange={() => handleChecked(id)}
                      checkedIcon={<img src={checked} alt="checkbox" />}
                      data-test-id="checkbox-selected"
                    />
                    <Typography className="item-txt">{name}</Typography>
                  </Box>
                );
              })}
          </Box>         
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Section;
// Customizable Area End
